<template>
  <Popper
    v-model:show="showTasksModal"
    class="task-popper"
    :config="popperConfig"
    :appendToBody="true"
    :trigger="'click'"
    :ignoreClickOutside="['.task-menu.click-container', '#resume-upload-modal']"
  >
    <template #trigger>
      <div
        ref="trigger"
        class="task-icon"
        :class="{ '_active' : inProgress }"
      >
        <div class="inline-loader">
          <div v-if="inProgress" class="loader-component" />
          <div class="loader-inner">
            <IconButton
              :icon="'import_export'"
              :showHover="false"
              :width="'32px'"
              :height="'32px'"
            />
            <span class="subtitle">Tasks</span>
          </div>
        </div>
      </div>
    </template>
    <BaseCard
      class="tasks-modal"
    >
      <template #body>
        <div class="mode-selector">
          <input
            id="option-local"
            v-model="mode"
            type="radio"
            name="mode-selector"
            :value="'local'"
          >
          <label for="option-local">Local ({{ numLocalTasks }})</label>
          <input
            id="option-in_progress"
            v-model="mode"
            type="radio"
            name="mode-selector"
            :value="'in_progress'"
          >
          <label for="option-in_progress">In Progress ({{ numInProgressTasks }})</label>
          <input
            id="option-completed"
            v-model="mode"
            type="radio"
            name="mode-selector"
            :value="'completed'"
          >
          <label for="option-completed">Completed</label>
        </div>
        <div class="mt-2 tasks-box scrollbar">
          <p v-if="mode==='local'" class="disclaimer"><strong>Warning:</strong> These tasks will be terminated if you exit the website or close your browser.</p>
          <div>
            <template v-if="mode==='local'">
              <div v-for="(t, taskID) in localTasks" :key="taskID" class="task">
                <component
                  :is="getStatusComponent(t.type)"
                  :task="t"
                  @cancel-task="handleCancelDockerTask"
                />
              </div>
            </template>
            <template v-if="mode==='in_progress'">
              <div v-for="(t, taskID) in inProgressTasks" :key="taskID" class="task">
                <component
                  :is="getStatusComponent(t.type)"
                  :task="t"
                  @cancel-task="handleCancelDockerTask"
                />
              </div>
            </template>
            <template v-if="mode==='completed'">
              <div v-for="t in completeTasks" :key="t.docker_task_id" class="task">
                <component
                  :is="getStatusComponent(t.type)"
                  :task="t"
                />
              </div>
            </template>
          </div>
        </div>
      </template>
    </BaseCard>
  </Popper>
</template>

<script>
import Popper from '@/components/Popper.vue';
import IconButton from '@/components/IconButton.vue';
import BaseCard from '@/components/BaseCard.vue';
import UploadStatusBar from '@/components/TasksStatusComponent/UploadStatusBar.vue';
import DatastoreConnect from '@/assets/js/DatastoreFunctions/datastore-interface';

export default {
  name: "TasksModal",
  components: {
    Popper,
    IconButton,
    BaseCard,
    UploadStatusBar,
  },
  props: {
  },
  emits: [
  ],
  data() {
    return {
      dsConn: new DatastoreConnect(this.$store.state.enterpriseServerUrl),
      mode: 'in_progress',
      showTasksModal: false,
    };
  },
  computed: {
    numUploadTasks() {
      return Object.keys(this.uploadTasks).length;
    },
    uploadTasks() {
      return this.$store.state.tasks.uploadTasks;
    },
    inProgressUploadTasks() {
      return Object.fromEntries(
        Object.entries(this.uploadTasks).filter(([key, value]) => ['pending', 'running', 'terminated', 'error'].includes(value.status)),
      );
    },
    completeUploadTasks() {
      return Object.fromEntries(
        Object.entries(this.uploadTasks).filter(([key, value]) => ['complete'].includes(value.status)),
      );
    },
    localTasks() {
      return Object.fromEntries(
        Object.entries(this.uploadTasks).filter(([key, value]) => value.isLocal),
      );
    },
    inProgressTasks() {
      return { ...this.inProgressUploadTasks };
    },
    completeTasks() {
      return { ...this.completeUploadTasks };
    },
    numLocalTasks() {
      return Object.keys(this.localTasks).length;
    },
    numInProgressTasks() {
      return Object.keys(this.inProgressTasks).length;
    },
    numRunningTasks() {
      return Object.entries(this.inProgressTasks).filter(([key, value]) => ['running'].includes(value.status)).length;
    },
    isDebugMode() {
      return this.$store.state.debugMode;
    },
    hasTasks() {
      return Object.keys(this.uploadTasks).length > 0;
    },
    inProgress() {
      return this.numRunningTasks > 0;
    },
    popperConfig() {
      return {
        placement: 'bottom-start',
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [75, -15],
            },
          },
          {
            name: 'preventOverflow',
            options: {
              padding: 0,
            },
          },
          {
            name: 'flip',
            enabled: true,
          },
        ],
      };
    },
  },
  watch: {
    numLocalTasks: {
      handler(newVal, oldVal) {
        if (newVal > oldVal) {
          this.mode = 'local';
          this.showTasksModal = true;
        }
      },
    },
  },
  methods: {
    async handleCancelDockerTask(taskID) {
      await this.dsConn.deleteDockerTask({ docker_task_id: taskID })
        .catch((e) => { console.log(e); });
    },
    getStatusComponent(type) {
      if (type === 'upload') {
        return 'UploadStatusBar';
      }
      return null;
    },
  },
};
</script>

<style lang="scss" scoped>
.tasks-modal {
  width: 500px;
  height: 400px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.3);
}

.icon-container {
  display: flex;
  align-items: center;
  button + button {
    margin-left: 10px;
  }
  .icon {
    visibility: visible;
    @include themify() {
      color: themed('icon-color-inactive');
    }
  }

  .icon:hover {
    visibility: visible;
    @include themify() {
      color: themed('icon-color-primary');
    }
  }
}

.task-icon {
  position : absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 60px;
  height: 60px;
  bottom: 8px;
  left: 10px;
  border-radius: 50%;
  color: var(--color-primary);
  background: var(--color-white-500);
  box-shadow: 1px 1px 4px 1px rgba(0, 0, 0, 0.2), 0 0 1px 1px rgba(0, 0, 0, 0.05);

  &._active {
    color: var(--color-primary);
  }

  & button {
    pointer-events: none;
  }
}

.subtitle {
  font-size: 0.65rem;
  font-weight: bold;
}

.mode-selector {
  display: flex;
  flex-direction: row;
}

.mode-selector input[type="radio"] {
  display: none;
}

.mode-selector label {
  padding: 2px 5px;
  width: fit-content;
  font-size: 0.9rem;
  font-weight: bold;
  cursor: pointer;
}

.mode-selector input[type="radio"]:checked+label {
  @include themify() {
    color: themed('color-primary-500');
    border-bottom: 2px solid themed('color-primary-500');
  }
}

.tasks-box {
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 0 1 100%;
  align-items: flex-end;
  overflow-y: scroll;

  & > div {
    display: flex;
    flex-direction: column-reverse;
    width: 100%;
    gap: 12px;
  }

  .task {
    display: flex;
    flex-direction: row;
    padding: 4px 4px;
  }

  .disclaimer {
    margin: 4px 0 8px 0;
    font-weight: 600;
    font-style: italic;
    line-height: 1.15;
    font-size: 0.875rem;
    color: var(--body-text-color-secondary);
  }
}

.inline-loader {
  position: relative;
  .loader-component {
    width: 60px;
    height: 60px;
    @include themify() {
      border: 3px solid themed('loader-background');
      border-top: 3px solid themed('loader-main-color');;
    }
    border-radius: 50%;
    animation: spin 2s linear infinite;
  }

  .loader-inner {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

  }
}

@keyframes spin {
 0% { transform: rotate(0deg); }
 100% { transform: rotate(360deg); }
}
</style>
