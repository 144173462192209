<template>
  <div class="main-page scrollbar">
    <div class="settings">
      <span class="header">Add New User</span>
      <div class="row mt-3">
        <label class="label required">First Name</label>
        <div class="value">
          <input
            v-model="firstname"
            class="select"
            type="text"
            :class="{ 'error' : errorFirstName }"
          >
        </div>
      </div>
      <div class="row mt-3">
        <label class="label required">Last Name</label>
        <div class="value">
          <input
            v-model="lastname"
            class="select"
            type="text"
            :class="{ 'error' : errorLastName }"
          >
        </div>
      </div>
      <div class="row mt-3">
        <label class="label required">New Username</label>
        <div class="value">
          <input
            v-model="username"
            class="select"
            type="text"
            :class="{ 'error' : errorUsername }"
          >
        </div>
      </div>
      <div class="row mt-3">
        <label class="label required">Password</label>
        <div class="value">
          <input
            v-model="password"
            class="select"
            type="password"
            :class="{ 'error' : errorPassword }"
          >
        </div>
      </div>
      <div class="row mt-3">
        <label class="label required">Confirm Password</label>
        <div class="value">
          <input
            v-model="confirmPassword"
            class="select"
            type="password"
            :class="{ 'error' : errorConfirmPassword }"
          >
        </div>
      </div>
      <div class="row mt-3">
        <label class="label required">Email</label>
        <div class="value">
          <input
            v-model="email"
            class="select"
            type="text"
            :class="{ 'error' : errorEmail }"
          >
        </div>
      </div>
      <div class="row mt-3">
        <label class="label required">Role</label>
        <div class="value permission-list">
          <div class="role-grid-single-row">
            <div class="chkbx-li">
              <v-checkbox
                v-model="presetRole"
                :hide-details="true"
                density="compact"
                value="admin"
                :false-icon="'mdi-checkbox-blank-circle-outline'"
                :true-icon="'mdi-circle-slice-8'"
              >
                <template #label>
                  <div class="label-text">Admin</div>
                </template>
              </v-checkbox>
              <span class="chkbx-li__subtitle">Full access to Deep View Enterprise. Can read and write to projects, datasets, trainer, validation, automation, and task board.</span>
            </div>
            <div class="chkbx-li">
              <v-checkbox
                v-model="presetRole"
                :hide-details="true"
                density="compact"
                value="auditor"
                :false-icon="'mdi-checkbox-blank-circle-outline'"
                :true-icon="'mdi-circle-slice-8'"
              >
                <template #label>
                  <div class="label-text">Audit & Label</div>
                </template>
              </v-checkbox>
              <span class="chkbx-li__subtitle">Limited access to Deep View Enterprise. Can only perform auditing and labelling in task board.</span>
            </div>
            <div class="chkbx-li">
              <v-checkbox
                v-model="presetRole"
                :hide-details="true"
                density="compact"
                value="user"
                :false-icon="'mdi-checkbox-blank-circle-outline'"
                :true-icon="'mdi-circle-slice-8'"
              >
                <template #label>
                  <div class="label-text">Custom</div>
                </template>
              </v-checkbox>
              <span class="chkbx-li__subtitle">Customize access to Deep View Enterprise for specific usage.</span>
            </div>
          </div>
        </div>
      </div>
      <template v-if="presetRole === 'user'">
        <div class="row mt-3">
          <label class="label">Project</label>
          <div class="value permission-list">
            <div class="role-grid">
              <div class="chkbx-li">
                <v-checkbox
                  v-model="permissions.project_read"
                  :hide-details="true"
                  density="compact"
                >
                  <template #label>
                    <div class="label-text">Read</div>
                  </template>
                </v-checkbox>
              </div>
              <div class="chkbx-li">
                <v-checkbox
                  v-model="permissions.project_write"
                  :hide-details="true"
                  density="compact"
                >
                  <template #label>
                    <div class="label-text">Write</div>
                  </template>
                </v-checkbox>
              </div>
            </div>
          </div>
        </div>
        <div class="row mt-3">
          <label class="label">Dataset</label>
          <div class="value permission-list">
            <div class="role-grid">
              <div class="chkbx-li">
                <v-checkbox
                  v-model="permissions.dataset_read"
                  :hide-details="true"
                  density="compact"
                >
                  <template #label>
                    <div class="label-text">Read</div>
                  </template>
                </v-checkbox>
              </div>
              <div class="chkbx-li">
                <v-checkbox
                  v-model="permissions.dataset_write"
                  :hide-details="true"
                  density="compact"
                >
                  <template #label>
                    <div class="label-text">Write</div>
                  </template>
                </v-checkbox>
              </div>
            </div>
          </div>
        </div>
        <div class="row mt-3">
          <label class="label">Trainer</label>
          <div class="value permission-list">
            <div class="role-grid">
              <div class="chkbx-li">
                <v-checkbox
                  v-model="permissions.trainer_read"
                  :hide-details="true"
                  density="compact"
                >
                  <template #label>
                    <div class="label-text">Read</div>
                  </template>
                </v-checkbox>
              </div>
              <div class="chkbx-li">
                <v-checkbox
                  v-model="permissions.trainer_write"
                  :hide-details="true"
                  density="compact"
                >
                  <template #label>
                    <div class="label-text">Write</div>
                  </template>
                </v-checkbox>
              </div>
            </div>
          </div>
        </div>
        <div class="row mt-3">
          <label class="label">Task Board</label>
          <div class="value permission-list">
            <div class="role-grid">
              <div class="chkbx-li">
                <v-checkbox
                  v-model="permissions.annotator_read"
                  :hide-details="true"
                  density="compact"
                >
                  <template #label>
                    <div class="label-text">Read</div>
                  </template>
                </v-checkbox>
              </div>
              <div class="chkbx-li">
                <v-checkbox
                  v-model="permissions.annotator_write"
                  :hide-details="true"
                  density="compact"
                >
                  <template #label>
                    <div class="label-text">Write</div>
                  </template>
                </v-checkbox>
              </div>
            </div>
          </div>
        </div>
      </template>
      <div v-if="message" class="row mt-3">
        <label class="label" />
        <div class="value" :class="{ 'error-text': isError, 'success-text' : !isError }">{{ message }}</div>
      </div>
      <div class="row mt-3 footer">
        <button class="button button-sm rounded" @click.stop="createUser">Add</button>
        <span @click.stop="$router.go(-1)">Back</span>
      </div>
    </div>
  </div>
</template>

<script>
import DatastoreConnect from '@/assets/js/DatastoreFunctions/datastore-interface';

export default {
  name: 'CreateSecondaryUser',
  components: {
  },
  data() {
    return {
      dsConn: new DatastoreConnect(this.$store.state.enterpriseServerUrl),
      password: '',
      errorPassword: false,
      confirmPassword: '',
      errorConfirmPassword: false,
      username: '',
      errorUsername: false,
      firstname: '',
      errorFirstName: false,
      lastname: '',
      errorLastName: false,
      allRoles: [],
      message: '',
      errorRole: false,
      email: '',
      errorEmail: false,
      backendError: false,
      organization_id: 0,
      presetRole: 'admin',
      permissions: {
        project_read: true,
        project_write: false,
        dataset_read: true,
        dataset_write: false,
        trainer_read: true,
        trainer_write: false,
        annotator_read: true,
        annotator_write: false,
      },
    };
  },
  computed: {
    isError() {
      return this.errorPassword || this.errorUsername || this.errorFirstName || this.errorLastName || this.errorRole || this.errorEmail || this.backendError || this.errorConfirmPassword;
    },
    user() {
      return this.$store.state.user.user;
    },
    isSuper() {
      return this.user.role === 'super';
    },
    isAdmin() {
      return this.user.role === 'admin';
    },
  },
  watch: {
    permissions: {
      deep: true,
      handler() {
        Object.values(this.permissions).forEach((type) => {
          if (type.canWrite) {
            Object.keys(type).forEach((key) => {
              type[key] = true;
            });
          }
        });
      },
    },
  },
  created() {
    if (this.$route.query.org) {
      this.organization_id = parseInt(this.$route.query.org);
    } else {
      this.$router.go(-1);
    }
    if (!this.isAdmin && !this.isSuper) {
      this.$router.push('/login');
    }
  },
  methods: {
    resetErrors() {
      this.errorUsername = false;
      this.errorLastName = false;
      this.errorLastName = false;
      this.errorRole = false;
      this.errorConfirmPassword = false;
      this.errorPassword = false;
      this.errorEmail = false;
      this.backendError = false;
    },
    async createUser() {
      this.resetErrors();
      this.message = "";
      if (!this.username) {
        this.errorUsername = true;
        this.message = 'Missing username.';
        return;
      }
      if (!this.firstname) {
        this.errorFirstName = true;
        this.message = 'Missing first name.';
        return;
      }
      if (!this.lastname) {
        this.errorLastName = true;
        this.message = 'Missing last name.';
        return;
      }
      if (!this.password) {
        this.errorPassword = true;
        this.message = 'Missing password.';
        return;
      }
      if (!this.confirmPassword) {
        this.errorConfirmPassword = true;
        this.message = 'Missing confirm password.';
        return;
      }

      if (this.password !== this.confirmPassword) {
        this.errorPassword = true;
        this.errorConfirmPassword = true;
        this.message = 'Confirm password does not match password.';
        return;
      }
      if (!this.email) {
        this.errorEmail = true;
        this.message = 'Missing email.';
        return;
      }

      const params = {
        organization_id: this.organization_id,
        username: this.username,
        last_name: this.lastname,
        first_name: this.firstname,
        password: this.password,
        email: this.email,
        role: this.presetRole,
      };

      if (this.presetRole === 'admin') {
        params.permissions = {
          project_read: true,
          project_write: true,
          dataset_read: true,
          dataset_write: true,
          trainer_read: true,
          trainer_write: true,
          annotator_read: true,
          annotator_write: true,
        };
      } else if (this.presetRole === 'auditor') {
        params.permissions = {
          project_read: true,
          project_write: false,
          dataset_read: false,
          dataset_write: false,
          trainer_read: false,
          trainer_write: false,
          annotator_read: true,
          annotator_write: true,
        };
      } else if (this.presetRole === 'user') {
        params.permissions = this.permissions;
      }

      let message = '';

      await this.dsConn.createUser(params)
        .then(async (data) => {
          if (!data.error) {
            message = `User ${this.username} added successfully.`;
          } else {
            this.backendError = true;
            message = data.error.message;
          }
        })
        .catch((e) => {
          this.backendError = true;
          message = e;
        });
      this.message = message;
    },
  },
};
</script>

<style lang="scss" scoped>
.main-page {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  margin-bottom: 10px;
}

.settings {
  display: flex;
  flex-direction: column;
  width: 730px;
  height: 100%;
  padding: 30px;
}

.header {
  font-size: 1.2rem;
  font-weight: 700;
}

.row {
  flex-wrap: nowrap !important;
}

.row:nth-child(n + 8) > .label {
  align-items: start !important;
  margin-top: 10px !important;
}

.row > .label {
  display: flex;
  justify-content: end;
  align-items: center;
  width: 200px;
  text-align: right;
  font-size: 0.9rem;
  font-weight: 600;
  color: rgba(grey, 0.9);

  &.required::after {
    content: '*';
    font-size: 14px;
    font-weight: 700;
    padding-left: 1px;
    @include themify() {
      color: themed('color-primary');
    }
  }
}

.row > .value {
  display: flex;
  flex-direction: row;
  flex: 1 1 auto;
  font-size: 0.9rem;
  margin-left: 20px;
}
.role-grid {
  display: grid;
  grid-gap: 10px;
  grid-template-columns: 1fr 1fr;
  grid-auto-rows: auto;
  width: 100%;
}

.role-grid-single-row {
  display: grid;
  grid-gap: 10px;
  grid-template-columns: 1fr;
  grid-auto-rows: auto;
}

.select {
  width: 100%;
  height: 30px;
  border-radius: 5px;
  font-size: 1rem;
  padding: 3px 10px;
  align-self: center;
  -webkit-appearance: auto;
  background: rgba(#ffffff, 0.8);
  @include themify() {
    border: 1px solid themed('form-input-border');
  }

}

.select:focus {
  @include themify() {
    border: 1px solid themed('color-primary');
    box-shadow:  0 0 0 1px themed('color-primary'), 0 0 3px themed('color-primary-300');
  }
  outline: 2px solid transparent;
  outline-offset: 2px;
}

input.error , select.error {
  @include themify() {
    border: 1px solid themed('color-red') !important;
    box-shadow:  0 0 0 1px themed('color-red'), 0 0 3px themed('color-red') !important;
  }

  &:focus, &:focus-visible {
    @include themify() {
      border: 1px solid themed('color-red') !important;
      box-shadow:  0 0 0 1px themed('color-red'), 0 0 3px themed('color-red') !important;
    }
  }
}

.error-text {
  color: $color-red-500;
}

.success-text {
  color: $color-success;
}

.footer {
  display: flex;
  justify-content: flex-end;
  button {
    width: fit-content
  }
  span {
    color: $color-link;
    text-decoration: underline;
    cursor: pointer;
    width: fit-content;
  }
}

.permission-list {
  display: flex;
  flex-direction: column !important;
  width: 100%;

  &__title {
    margin: 5px 0 5px 0;
    font-size: 1rem;
    font-weight: 600;
    color: rgba(rgb(0, 0, 0), 0.7);
  }
}

.chkbx-li {
  display: flex;
  flex-direction: column;
  align-items: start;

  &__subtitle {
    margin-left: 30px;
    color: rgba(grey, 0.95);
  }
}

.label-text {
  display: flex;
  flex-direction: row;
  cursor: pointer;
  align-items: center;
  color: var(--color-black) !important;
  font-size: 0.9rem;
  font-weight: 600;
  margin-left: 2px;
  width: 100px;
}

:deep(.v-label) {
  opacity: 1 !important;
}

.separator {
  display: flex;
  width: 100%;
  align-items: center;
  text-align: center;
  font-weight: 700;
  font-size: 0.75rem;
  @include themify() {
    color: themed('form-separator-color');
    border-color: themed('form-separator-color');
  }
  &::before, &::after {
    content: '';
    flex: 1;
    border-bottom: 1px solid;
  }
  &:not(:empty)::before {
    margin-right: 1.5em;
    margin-left: .25em;
  }
  &:not(:empty)::after {
    margin-right: .25em;
    margin-left: 1.5em;
  }
}
</style>
