<template>
  <FilterPopper>
    <template #default="{ menuOpen }">
      <button class="button filter-button" :class="{'_menuOpen': menuOpen}" @keyup.esc="handleCloseFilter">
        <span>More Filters</span>
        <SVGIcon
          :iconName="'add'"
          :width="'18px'"
        />
      </button>
    </template>
    <template #popper>
      <div class="filter-settings scrollbar">
        <div v-for="(filter, i) in filters" :key="i" class="filter-settings__row">
          <input
            :id="`moreFilters-filters-${i}`"
            type="checkbox"
            :name="`${filter.name}-${i}`"
            :value="filter.name"
          >
          <label :for="`filters-${i}`">{{ filter.name }}</label><br>
        </div>
      </div>
    </template>
  </FilterPopper>
</template>

<script>
import FilterPopper from '@/components/FilterPopper.vue';
import SVGIcon from '@/components/SVGIcon.vue';

export default {
  name: "MoreFilters",
  components: {
    FilterPopper,
    SVGIcon,
  },
  props: {
  },
  data() {
    return {
      filters: [
        { name: 'Additional Filter 1' },
        { name: 'Additional Filter 2' },
        { name: 'Additional Filter 3' },
      ],
    };
  },
  methods: {
  },
};
</script>

<style lang="scss" scoped>
.filter-button {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 30px;
  border-radius: 6px;
  padding: 2px 10px;
  box-shadow: 0 1px 3px 0 rgb(0 0 0 / 20%);
  gap: 2px;
  background: transparent;
  white-space: nowrap;
  @include themify() {
    border: 2px solid themed('button-secondary-outlined-border');
    background: themed('button-secondary-outlined');
    color: themed('button-secondary-text');
  }

  &._active {
    @include themify() {
      background: themed('button-active');
    }
  }

  &._menuOpen {
    @include themify() {
      background: themed('filter-button-menu-open-background');
      color: themed('filter-button-menu-open-text');
      border: 1px solid themed('filter-button-menu-open-border');
    }
  }
}

.filter-settings {
  display: flex;
  flex-direction: column;
  padding: 8px;
  border-radius: inherit;
  gap: 6px;
  min-width: 200px;
  overflow: auto;

  &__row {
    display: flex;
    align-items: center;
  }

  & input[type=checkbox] {
    margin-right: 12px;
  }
}
</style>
