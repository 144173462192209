<template>
  <div id="drawingToolModeControls" class="ribbon">
    <div class="ribbon__group">
      <IconButton
        id="selectToolBtn"
        class="ribbon__button"
        aria-label="Pointer Tool (q)"
        :icon="'cursor'"
        :showHover="false"
        :type="''"
        :title="'Pointer Tool (q)'"
        :width="22"
        :height="22"
        :class="{ 'selected': mode === 'pointer' }"
        @click="() => {drawMode = 'pointer'}"
      />
      <IconButton
        id="pointToolBtn"
        class="ribbon__button"
        aria-label="Point Tool (p)"
        :icon="'dotted_line'"
        :showHover="false"
        :type="''"
        :title="'Point Tool (p)'"
        :width="22"
        :height="22"
        :class="{ 'selected': mode === 'line' }"
        @click="() => {drawMode = 'line'}"
      />
      <IconButton
        id="brushToolBtn"
        class="ribbon__button"
        aria-label="Brush Tool (w)"
        :icon="'brush_icon'"
        :showHover="false"
        :type="''"
        :title="'Brush Tool (w)'"
        :width="22"
        :height="22"
        :class="{ 'selected': mode === 'brush' }"
        @click="() => {drawMode = 'brush'}"
      />
      <IconButton
        v-if="shouldEnableEraser"
        id="eraserToolBtn"
        class="ribbon__button"
        aria-label="Eraser Tool (e)"
        :icon="'eraser_icon'"
        :showHover="false"
        :type="''"
        :title="'Eraser Tool (e)'"
        :width="22"
        :height="22"
        :class="{ 'selected': mode === 'eraser' }"
        @click="() => {drawMode = 'eraser'}"
      />
      <IconButton
        id="boxToolBtn"
        class="ribbon__button"
        :class="{ 'selected': mode === 'box' }"
        aria-label="2D Bounding Box Tool (b)"
        :icon="'box-tool'"
        :type="''"
        :width="22"
        :height="22"
        :title="'Box Tool (b)'"
        @click="() => {drawMode = 'box'}"
      />
      <IconButton
        id="samToolBtn"
        class="ribbon__button"
        :class="{ 'selected': mode === 'sam' }"
        aria-label="AI Segment Tool (a)"
        :icon="'sam'"
        :type="''"
        :width="22"
        :height="22"
        :title="'AI Segment Tool (a)'"
        @click="() => {drawMode = 'sam'}"
      />
      <IconButton
        id="colorToolBtn"
        class="ribbon__button"
        :class="{ 'selected': mode === 'color' }"
        aria-label="Edit Label (l)"
        :icon="'tag'"
        :type="''"
        :width="22"
        :height="22"
        :title="'Edit Label (l)'"
        @click="() => {drawMode = 'color'}"
      />
      <hr v-if="mode === 'brush' || mode === 'eraser'" class="ribbon__vr">
      <IconButton
        v-if="mode === 'brush' || mode === 'eraser'"
        id="increaseSizeBtn"
        class="ribbon__button"
        aria-label="Increase Cursor Size (=)"
        :icon="'add_box_outlined'"
        :showHover="false"
        :type="''"
        :title="'Increase Cursor Size (=)'"
        :width="22"
        :height="22"
        @click="increaseCursorSize"
      />
      <IconButton
        v-if="mode === 'brush' || mode === 'eraser'"
        id="decreaseSizeBtn"
        class="ribbon__button"
        aria-label="Decrease Cursor Size (-)"
        :icon="'minus_box_outlined'"
        :showHover="false"
        :type="''"
        :title="'Decrease Cursor Size (-)'"
        :width="22"
        :height="22"
        @click="decreaseCursorSize"
      />
    </div>
  </div>
  <div v-if="mode==='sam'" class="sam-ribbon">
    <SAMToolbar
      v-if="samOptions?.enabled"
      v-model:samOptions="samOptions"
      :hasSAMImageEmbedding="hasSAMImageEmbedding"
    />
  </div>
</template>

<script setup>
import IconButton from '@/components/IconButton.vue';
import { storeToRefs } from 'pinia';
import { useViewerVisualizationsStore } from '@/stores/useViewerVisualizationsStore.js';
import {
  ref, toRefs, watch, onMounted,
} from 'vue';
import SAMToolbar from '@/components/DatasetComponent/AnnotationTool/SAMToolbar.vue';

const props = defineProps({
  mode: { type: String, default: '' },
  size: { type: Number, default: 0 },
  imageDimensions: { type: Object, default: () => {} },
  shouldEnableEraser: { type: Boolean, default: false },
  zoom: { type: Number, default: null },
  hasSAMImageEmbedding: { type: Boolean, default: false },
  samOptions: { type: Object, default: null },
});

const {
  mode,
  imageDimensions,
  shouldEnableEraser,
  zoom,
  hasSAMImageEmbedding,
  samOptions,
} = toRefs(props);

const emit = defineEmits([
  'update:mode', 'toggle-sam-enabled', 'update-cursor-size',
]);

const visualizationStore = useViewerVisualizationsStore();
const {
  frame,
} = storeToRefs(visualizationStore);

const drawMode = ref('pointer');
const drawCursorSize = ref(0);

const samActive = ref(false);

watch(samActive, (isSamActive) => {
  emit('toggle-sam-enabled', isSamActive);
});

onMounted(() => {
  if (drawMode.value === 'sam') {
    samActive.value = true;
  } else {
    samActive.value = false;
  }
});

watch(frame, () => {
  samActive.value = false;
});

watch(mode, () => {
  drawMode.value = mode.value;
});

watch(drawMode, () => {
  if (drawMode.value === 'sam') {
    samActive.value = true;
  } else {
    samActive.value = false;
  }
  emit('update:mode', drawMode.value);
});

watch(drawCursorSize, () => {
  emit('update-cursor-size', drawCursorSize.value);
});

watch(imageDimensions, (dimensions) => {
  if (Object.keys(dimensions).length > 0) {
    drawCursorSize.value = 0.04 * dimensions.width;
  }
}, { deep: true });

function increaseCursorSize() {
  if (drawCursorSize.value < (0.2 * imageDimensions.value.width)) {
    drawCursorSize.value *= 1.3;
  }
}
function decreaseCursorSize() {
  if (drawCursorSize.value > (0.001 * imageDimensions.value.width)) {
    drawCursorSize.value /= 1.3;
  }
}

</script>

<style lang="scss" scoped>
.ribbon {
  position: absolute;
  top: 8px;
  left: 8px;
  display: flex;
  flex-direction: column;
  width: fit-content;
  width: 40px;
  padding: 16px 2px;
  justify-content: space-between;
  font-weight: 500;
  box-shadow: 0 0 3px gray, 0 0 10px gray;
  border-radius: 4px;
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  z-index: 5;
  align-items: center;
  @include themify() {
    color: themed('ribbon-icons-color');
    background: themed('ribbon-bg-transparent');
  }

  &__group {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
  }

  &__vr {
    display: flex;
    width: 100%;
    height: 1px;
    margin-top: 5px;
  }

  &__button {
    display: flex;
    min-width: 22px;
    height: 22px;
    color: inherit;
    background: none;
    border: none;
    font-size: 0.875rem;
    line-height: 1rem;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: .05em;
    align-items: center;
    cursor: pointer;

    &:hover:not(:disabled), &.active:not(:disabled) {
      @include themify() {
        box-shadow: 0 0 0 4px themed('icon-hover-color');
        background-color: themed('icon-hover-color');
      }
      border-radius: 4px;
    }

    &:disabled {
      cursor: default;
      @include themify() {
        color: themed('color-disabled');
      }
    }
  }

  &__button + &__button {
      margin-top: 5px;
  }

  .icon-button + .icon-button {
      margin-left: 0px !important;
  }

  .selected {
      @include themify() {
        box-shadow: 0 0 0 4px hsl(0, 1%, 66%);
        background-color: hsl(0, 1%, 66%);
      }
      border-radius: 4px;
    }
}

.sam-ribbon {
  position: absolute;
  top: 8px;
  left: 65px;
  display: flex;
  flex-direction: row;
  width: fit-content;
  height: 40px;
  padding: 2px 16px;
  justify-content: space-between;
  font-weight: 500;
  box-shadow: 0 0 3px gray, 0 0 10px gray;
  border-radius: 4px;
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  z-index: 10;
  @include themify() {
    color: themed('ribbon-icons-color');
    background: themed('ribbon-bg-transparent');
  }
}

:deep(.filter-button) {
  height: 22px;
}

</style>
