import { createRouter, createWebHashHistory } from 'vue-router';
import store from "@/store";

// ----------------------------------------   User Profile   ----------------------------------------------
import UserPage from '@/views/UserPage.vue';
import UserUsageLogs from '@/components/UserProfileComponent/UserUsageLogs.vue';
import Transactions from '@/components/UserProfileComponent/Transactions.vue';
import AdminPage from '@/views/AdminPage.vue';
import SuperAdminPage from '@/views/SuperAdminPage.vue';

// ------------------------------   User Profile  / User  -------------------------------------
import AccountSettings from '@/components/UserProfileComponent/AccountSettings/AccountSettings.vue';
import EditUser from '@/components/UserProfileComponent/AccountSettings/EditUser.vue';

// ------------------------------   User Profile  / Admin  -------------------------------------
import OrganizationSettingsLanding from '@/components/UserProfileComponent/OrganizationSettings/Landing.vue';
import EditOrganization from '@/components/UserProfileComponent/OrganizationSettings/EditOrganization.vue';
import OrganizationSecuritySettings from '@/components/UserProfileComponent/OrganizationSettings/OrganizationSecuritySettings.vue';
import UserManagement from '@/components/UserProfileComponent/OrganizationSettings/UserManagement.vue';
import OrganizationSettings from '@/components/UserProfileComponent/OrganizationSettings/OrganizationSettings.vue';
import CreateSecondaryUser from '@/components/UserProfileComponent/CreateSecondaryUser.vue';

// ------------------------------   User Profile  / Super Admin  -------------------------------------
import OrganizationManagement from '@/components/UserProfileComponent/OrganizationSettings/OrganizationManagement.vue';
import AddNewOrganization from '@/components/UserProfileComponent/OrganizationSettings/AddNewOrganization.vue';
import UserAddCredits from '@/components/UserProfileComponent/UserAddCredits.vue';
import EmulateUser from '@/components/UserProfileComponent/EmulateUser.vue';
import ShowDetailedOrganization from '@/components/UserProfileComponent/OrganizationSettings/ShowDetailedOrganization.vue';

// ----------------------------------------   Projects   ----------------------------------------------
import Projects from '@/views/Projects.vue';
import ProjectManagement from '@/components/ProjectsComponent/ProjectManagement.vue';

// ----------------------------------------   Trainer   ----------------------------------------------
import Trainer from '@/views/Trainer.vue';
import TrainerManagement from '@/components/TrainerComponent/TrainerExperimentAndSessions/TrainerManagement.vue';

// ----------------------------------------   Dataset   ----------------------------------------------
import Datasets from '@/views/Datasets.vue';
import AnnotationViewer from '@/components/DatasetComponent/AnnotationTool/AnnotationViewer.vue';
import DatasetManagement from '@/components/DatasetComponent/DatasetManagement/DatasetManagement.vue';
import Gallery from '@/components/DatasetComponent/GalleryComponent/Gallery.vue';
import DatasetStats from '@/components/DatasetComponent/DatasetStats/DatasetStats.vue';
import Map from '@/components/DatasetComponent/Map.vue';

// ----------------------------------------   Validation   ----------------------------------------------
import Validator from '@/views/Validator.vue';
import ValidatorManagement from '@/components/ValidationPage/ValidatorManagement.vue';
import ValidatorSessionDetails from '@/components/ValidationPage/ValidateSessionDetails.vue';

// ----------------------------------------   Automation   ----------------------------------------------
import Automation from '@/views/Automation.vue';

// ----------------------------------------   Task Board   ----------------------------------------------
import TaskBoard from '@/views/TaskBoard.vue';
import GalleryLandingPage from '@/components/DatasetComponent/GalleryComponent/GalleryLandingPage.vue';

// ----------------------------------------   Recycle Bin   ----------------------------------------------
import RecycleBin from '@/views/RecycleBin.vue';

// ----------------------------------------   Home   ----------------------------------------------
import Welcome from '@/views/Welcome.vue';
import WelcomeLanding from '@/views/WelcomeLanding.vue';
import Pricing from '@/views/Pricing.vue';
import Login from '@/views/Login.vue';

const routes = [
  {
    path: '/',
    component: WelcomeLanding,
    beforeEnter: (to, from, next) => {
      if (to.path === '/') {
        next({ name: 'home' });
      } else {
        next();
      }
    },
    children: [
      {
        path: 'home',
        name: 'home',
        component: Welcome,
      },
      {
        path: 'pricing',
        name: 'pricing',
        component: Pricing,
      },
    ],
  },
  {
    path: '/login',
    component: Login,
  },
  {
    path: '/user',
    component: UserPage,
    redirect: {
      name: 'user.profile',
    },
    meta: {
      breadCrumb: 'User',
    },
    children: [
      {
        path: 'profile',
        name: 'user.profile',
        component: AccountSettings,
        meta: {
          breadCrumb: 'Profile',
          icon: 'account_circle',
        },
      },
      {
        path: 'edit',
        name: 'user.edit',
        props: true,
        component: EditUser,
        meta: {
          breadCrumb: 'Edit Info',
          icon: 'edit',
        },
      },
    ],
  },
  {
    path: '/admin',
    component: AdminPage,
    meta: {
      breadCrumb: 'Admin',
    },
    redirect: {
      name: 'admin.organization.details',
    },
    // if the breadcrumb needs to navigate between siblings, add name && icon to each child
    children: [
      {
        path: 'organization',
        name: 'admin.organization',
        component: OrganizationSettingsLanding,
        meta: {
          breadCrumb: 'Organization',
          icon: 'info',
        },
        redirect: {
          name: 'admin.organization.details',
        },
        children: [
          {
            path: 'details',
            name: 'admin.organization.details',
            component: OrganizationSettings,
            meta: {
              breadCrumb: 'Information',
              icon: 'info',
            },
          },
          {
            path: 'editorg',
            name: 'admin.organization.editorg',
            permissions: ['admin', 'super'],
            component: EditOrganization,
            meta: {
              breadCrumb: 'Edit Info',
              icon: 'edit',
            },
          },
          {
            path: 'security',
            name: 'admin.organization.security',
            permissions: ['admin', 'super'],
            component: OrganizationSecuritySettings,
            meta: {
              breadCrumb: 'Security',
              icon: 'security',
            },
          },
        ],
      },
      {
        path: 'people',
        name: 'admin.people',
        component: OrganizationSettingsLanding,
        meta: {
          breadCrumb: 'Users',
          icon: 'people',
        },
        redirect: {
          name: 'admin.people.list',
        },
        children: [
          {
            path: 'list',
            name: 'admin.people.list',
            component: UserManagement,
            meta: {
              breadCrumb: 'Manager',
              icon: 'people',
            },
          },
          {
            path: 'newuser',
            name: 'admin.people.newuser',
            component: CreateSecondaryUser,
            meta: {
              breadCrumb: 'New User',
              icon: 'person_add',
            },
          },
          {
            path: 'edituser',
            name: 'admin.people.edituser',
            component: EditUser,
            meta: {
              breadCrumb: 'Edit User',
              icon: 'edit',
            },
          },
        ],
      },
      {
        path: 'usagelogs',
        name: 'admin.usagelogs',
        component: UserUsageLogs,
        meta: {
          breadCrumb: 'Usage & Billing',
          icon: 'usage',
        },
      },
      {
        path: 'transactions',
        name: 'admin.transactions',
        component: Transactions,
        meta: {
          breadCrumb: 'Transactions',
          icon: 'usage',
        },
      },
    ],
  },
  {
    path: '/super',
    component: SuperAdminPage,
    meta: {
      breadCrumb: 'Super Admin Console',
    },
    redirect: {
      name: 'super.list',
    },
    // if the breadcrumb needs to navigate between siblings, add name && icon to each child
    children: [

      {
        path: 'list',
        name: 'super.list',
        permissions: ['super'],
        component: OrganizationManagement,
        meta: {
          breadCrumb: 'List',
          icon: 'organizations',
        },
      },
      {
        path: 'neworg',
        name: 'super.neworg',
        permissions: ['super'],
        component: AddNewOrganization,
        meta: {
          breadCrumb: 'New Organization',
          icon: 'people_add',
        },
      },
      {
        path: 'editorg',
        name: 'super.editorg',
        permissions: ['admin', 'super'],
        component: EditOrganization,
      },
      {
        path: 'addcredits',
        name: 'super.addcredits',
        permissions: ['super'],
        component: UserAddCredits,
      },
      {
        path: 'emulate',
        name: 'super.emulate',
        permissions: ['super'],
        component: EmulateUser,
      },
      {
        path: 'usagelogs',
        name: 'super.usagelogs',
        permissions: ['super'],
        component: UserUsageLogs,
      },
      {
        path: 'transactions',
        name: 'super.transactions',
        component: Transactions,
        permissions: ['super'],
      },
      {
        path: 'orgdetail',
        name: 'super.orgdetail',
        permissions: ['super'],
        component: ShowDetailedOrganization,
      },
      {
        path: 'createuser',
        name: 'super.createuser',
        permissions: ['super'],
        component: CreateSecondaryUser,
      },
      {
        path: 'edituser',
        name: 'super.edituser',
        component: EditUser,
      },

    ],
  },
  {
    path: '/trainer',
    meta: {
      breadCrumb: 'Trainer',
    },
    component: Trainer,
    redirect: {
      name: 'trainer.sessions',
    },
    children: [
      {
        path: 'sessions',
        name: 'trainer.sessions',
        component: TrainerManagement,
      },
    ],
  },
  {
    path: '/validator',
    props: true,
    meta: {
      breadCrumb: 'Validator',
    },
    component: Validator,
    redirect: {
      name: 'validator.manager',
    },
    children: [
      {
        path: 'manager',
        name: 'validator.manager',
        component: ValidatorManagement,
      },
      {
        path: 'details',
        name: 'validator.details',
        component: ValidatorSessionDetails,
        props: true,
        meta: {
          breadCrumb: 'Details',
          icon: 'analytics',
        },
      },
    ],
  },
  {
    path: '/projects',
    name: 'Projects',
    component: Projects,
    meta: {
      breadCrumb: 'Projects',
    },
    redirect: {
      name: 'projects.manager',
    },
    children: [
      {
        path: 'manager',
        name: 'projects.manager',
        component: ProjectManagement,
      },
    ],
  },
  {
    path: '/datasets',
    name: 'Datasets',
    component: Datasets,
    meta: {
      breadCrumb: 'Datasets',
    },
    props: true,
    redirect: {
      name: 'datasets.manager',
    },
    children: [
      {
        path: 'manager',
        name: 'datasets.manager',
        component: DatasetManagement,
      },
      {
        path: 'gallery',
        name: 'datasets.gallery.landing',
        component: GalleryLandingPage,
        meta: {
          breadCrumb: 'Gallery',
          icon: 'gallery_thumbnail',
        },
        redirect: {
          name: 'datasets.gallery.main',
        },
        children: [
          {
            path: 'main',
            name: 'datasets.gallery.main',
            component: Gallery,
            props: true,
          },
          {
            path: 'viewer',
            name: 'datasets.gallery.viewer',
            props: true,
            component: AnnotationViewer,
            meta: {
              breadCrumb: 'Image',
              icon: 'image',
            },
          },
        ],
      },
      {
        path: 'stats',
        name: 'datasets.statistics',
        component: DatasetStats,
        meta: {
          breadCrumb: 'Analytics',
          icon: 'insights',
        },
      },
      {
        path: 'map',
        name: 'datasets.map',
        component: Map,
        meta: {
          breadCrumb: 'Map',
          icon: 'map',
        },
      },
    ],
  },
  {
    path: '/about',
    name: 'About',
    component: () => import('@/views/About.vue'),
  },
  {
    path: '/taskboard',
    component: TaskBoard,
    meta: {
      breadCrumb: 'Task Board',
    },
  },
  {
    path: '/automation',
    component: Automation,
    meta: {
      breadCrumb: 'Automation',
    },
  },
  {
    path: '/recycle',
    component: RecycleBin,
    meta: {
      breadCrumb: 'Recycle Bin',
    },
  },
  {
    path: '/logout',
    component: {
      beforeRouteEnter(to, from, next) {
        next({ path: "/" });
        store.commit('projects/resetState');
        store.commit('datasets/resetState');
        store.commit('statistics/resetState');
        store.commit('annotationTool/resetState');
        store.commit('reviews/resetState');
        store.commit('user/removeToken');
      },
    },
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  // history: createWebHistory(process.env.BASE_URL),
  routes,
});

// navigation guard after log out
router.beforeEach((to, from) => {
  const currentToken = store.state.user.token;
  if ((
    !currentToken
    && to.path !== '/'
    && to.path !== '/login'
    && to.path !== '/logout'
    && to.path !== '/home'
    && to.path !== '/pricing'
  )) {
    return false;
  }
  store.commit('notifications/updateNotifications');
  return true;
});

export default router;
