import cv from "@techstark/opencv-js";

const polygonClipping = require('polygon-clipping');

export default function useBrushToPolygon() {
  function processImage(canvas, rectX, rectY, width, height, polygon = "[]", mode = 'brush') {
    let simplifiedPolygon = [[]];
    if (canvas && width > 0 && height > 0) {
      const ctx = canvas.getContext('2d', { willReadFrequently: true });
      const imgData = ctx.getImageData(rectX, rectY, width, height);

      const src = cv.matFromImageData(imgData);
      const gray = new cv.Mat();
      const binary = new cv.Mat();
      const contours = new cv.MatVector();
      const hierarchy = new cv.Mat();

      // Convert to grayscale
      cv.cvtColor(src, gray, cv.COLOR_RGBA2GRAY);

      // Apply binary threshold
      cv.threshold(gray, binary, 1, 255, cv.THRESH_BINARY);

      try {
        cv.findContours(binary, contours, hierarchy, cv.RETR_EXTERNAL, cv.CHAIN_APPROX_SIMPLE);
      } catch {
        throw Error('Failed to find contours');
      }

      // Find the largest contour
      let maxLen = 0;
      let index = 0;
      for (let i = 0; i < contours.size(); i++) {
        const contour = contours.get(i);
        if (contour.size().height > maxLen) {
          maxLen = contour.size().height;
          index = i;
        }
      }

      // Get the largest contour
      const largestContour = contours.get(index);

      // Simplify the largest contour using approxPolyDP
      const epsilon = 0.005 * cv.arcLength(largestContour, true);
      // const epsilon = 3;
      const approx = new cv.Mat();
      try {
        cv.approxPolyDP(largestContour, approx, epsilon, true);
      } catch {
        throw Error('Failed to simplify polygon');
      }

      // Convert simplified contour to normalized polygon
      const tempPolygon = [];
      if (width > 0 && height > 0) {
        for (let i = 0; i < approx.rows; i++) {
          const x = (approx.intPtr(i, 0)[0] / width);
          const y = (approx.intPtr(i, 0)[1] / height);
          tempPolygon.push([x, y]);
        }
      }

      simplifiedPolygon = tempPolygon;

      // Clean up
      try {
        src.delete();
        gray.delete();
        binary.delete();
        contours.delete();
        hierarchy.delete();
        approx.delete();
      } catch {
        throw Error('Failed to clean up');
      }
    }
    // Output of clipping union is a 4D array
    const originalPoly = JSON.parse(polygon);
    return mergePolygons(originalPoly, [simplifiedPolygon], mode);
  }

  function mergePolygons(polygons, targetPolygon, mode) {
    const formatted = new Array(polygons.length);
    for (let i = 0; i < polygons.length; i += 1) {
      formatted[i] = [polygons[i]];
    }
    let poly4D = null;
    if (mode === 'brush' || mode === 'line') {
      // if no target, try to union all orignal polygons
      if (targetPolygon[0][0].length === 0) {
        poly4D = polygonClipping.union(...formatted);
      } else {
        poly4D = polygonClipping.union(...formatted, targetPolygon);
      }
    } else if (mode === 'eraser') {
      // if no target, return orignal polygons
      if (targetPolygon[0][0].length === 0) {
        return polygons;
      } else {
        poly4D = [];
        formatted.forEach((poly) => {
          poly4D.push(...polygonClipping.difference(poly, targetPolygon));
        });
      }
    }
    return combineResult(poly4D);
  }

  function combineResult(poly4D) {
    const result = [];
    for (let i = 0; i < poly4D.length; i += 1) {
      result.push(poly4D[i][0]);
    }
    return result;
  }

  return {
    processImage,
  };
}
