import { getFileContents } from '@/assets/js/utils.js';

const txtFormat5 = ['label_index', 'center_x', 'center_y', 'width', 'height'];
const txtFormat6 = ['label_index', 'score', 'center_x', 'center_y', 'width', 'height'];
const txtFormatSegment = ['label_index', 'polygon'];

function parseLabelIndex(format, columns, annotation) {
  const i = format.indexOf('label_index');
  annotation.label_index = parseInt(columns[i]);
}
function parseScore(format, columns, annotation) {
  const i = format.indexOf('score');
  annotation.score = parseFloat(columns[i]);
}
function parseCenterX(format, columns, annotation) {
  const cx = columns[format.indexOf('center_x')];
  const w = columns[format.indexOf('width')];
  annotation.x = parseFloat(cx) - (parseFloat(w) / 2);
}
function parseCenterY(format, columns, annotation) {
  const cy = columns[format.indexOf('center_y')];
  const h = columns[format.indexOf('height')];
  annotation.y = parseFloat(cy) - (parseFloat(h) / 2);
}
function parseWidth(format, columns, annotation) {
  const w_i = format.indexOf('width');
  annotation.w = parseFloat(columns[w_i]);
}
function parseHeight(format, columns, annotation) {
  const h_i = format.indexOf('height');
  annotation.h = parseFloat(columns[h_i]);
}
function parsePolygon(format, columns, annotation) {
  const polygon_i = format.indexOf('polygon');
  const polygonColumns = columns.slice(polygon_i);
  // Group elements as pairs [x, y]
  const points = [];
  for (let i = 0; i < polygonColumns.length; i += 2) {
    points.push([parseFloat(polygonColumns[i]), parseFloat(polygonColumns[i + 1])]);
  }
  annotation.polygon = JSON.stringify([points]);
}

const formatMap = {
  label_index: parseLabelIndex,
  score: parseScore,
  center_x: parseCenterX,
  center_y: parseCenterY,
  width: parseWidth,
  height: parseHeight,
  polygon: parsePolygon,
};

async function parseAnnotationTextFile(file) {
  const annotations = [];
  const fileContents = await getFileContents(file);
  fileContents.split('\n').forEach((line) => {
    // Check that line is valid
    const columns = line.split(' ');
    if (line === '' || columns.length === 0) { return; }

    const annotation = {};
    annotation.polygon = '';
    annotation.type = 'box';
    // Determine txt format
    let format = txtFormat5;
    if (columns.length === 6) {
      format = txtFormat6;
    } else if (columns.length > 6) {
      format = txtFormatSegment;
      annotation.type = 'seg';
    }

    // Parse each column into the annotation
    format.forEach((col) => {
      formatMap[col](format, columns, annotation);
    });
    annotations.push(annotation);
  });
  return annotations;
}

export default {
  parseAnnotationTextFile,
};
