<template>
  <v-rect
    ref="annotationBoxRect"
    :config="{
      stroke: stroke,
      strokeWidth: strokeWidth,
      visible: false
    }"
  />
</template>

<script>
import { debounce, roundNumber } from '@/assets/js/utils.js';
import { v4 as uuidv4 } from 'uuid';
import useColorParser from '@/composables/useColorParser.js';

export default {
  name: 'AnnotationBoxTool',
  props: {
    stageScale: {
      type: Object,
      default: () => ({ x: 1, y: 1 }),
    },
    stageNode: {
      type: Object,
      default: () => {},
    },
    selectedLabel: {
      type: Object,
      default: null,
    },
    imageDimensions: {
      type: Object,
      default: () => ({ width: 100, height: 100 }),
    },
    editingEnabled: {
      type: Boolean,
      default: false,
    },
    labelColorMap: {
      type: Object,
      default: null,
    },
  },
  emits: ['created-annotation-box', 'annotation-box-tool-dimensions-changed'],
  setup() {
    const {
      parseColor,
    } = useColorParser();
    return {
      parseColor,
    };
  },
  data() {
    return {
      annotationBoxRectNode: null,
    };
  },
  computed: {
    boxDimensions() {
      if (this.annotationBoxRectNode && this.annotationBoxRectNode.getAttrs().visible) {
        return {
          x1: this.annotationBoxRectNode.x(),
          y1: this.annotationBoxRectNode.y(),
          x2: this.annotationBoxRectNode.x() + this.annotationBoxRectNode.width(),
          y2: this.annotationBoxRectNode.y() + this.annotationBoxRectNode.height(),
        };
      }
      return null;
    },
    debounceHandleBoxDimensionsChanged() {
      return debounce(() => { this.handleBoxDimensionsChanged(); }, 50);
    },
    stroke() {
      return this.getAnnotationColor();
    },
    strokeWidth() {
      return 4 / this.stageScale.x;
    },
  },
  watch: {
    boxDimensions() {
      this.debounceHandleBoxDimensionsChanged();
    },
  },
  mounted() {
    this.annotationBoxRectNode = this.$refs.annotationBoxRect.getNode();
    this.setUpStageMouseEvents();
  },
  unmounted() {
    const stage = this.stageNode;
    stage.off('mousedown touchstart');
    stage.off('mousemove touchmove');
    stage.off('mouseup touchend');
  },
  methods: {
    getAnnotationColor() {
      if (this.selectedLabel && this.selectedLabel.color) {
        return this.parseColor(this.selectedLabel.color);
      } else if (this.selectedLabel && this.labelColorMap) {
        return this.labelColorMap[this.selectedLabel['index']];
      } else {
        return '#EFC15F';
      }
    },
    handleBoxDimensionsChanged() {
      this.$emit('annotation-box-tool-dimensions-changed', this.boxDimensions);
    },
    setUpStageMouseEvents() {
      const stage = this.stageNode;
      const annotationBoxRectangle = this.annotationBoxRectNode;
      let mousedown = false;
      let annotating = false;
      let x1; let y1; let x2; let y2;

      stage.on('mousedown touchstart', (e) => {
        mousedown = true;
        // Only do selection if left mouse button pressed with ctrl key not held
        if (e.evt.button === 0 && !e.evt.ctrlKey) {
          this.stageNode.setAttr('draggable', false);
        } else {
          return;
        }

        e.evt.preventDefault();
        x1 = stage.getRelativePointerPosition().x;
        y1 = stage.getRelativePointerPosition().y;
        x2 = stage.getRelativePointerPosition().x;
        y2 = stage.getRelativePointerPosition().y;
        if (x1 < 0) return;
        if (x1 > this.imageDimensions.width) return;
        if (y1 < 0) return;
        if (y1 > this.imageDimensions.height) return;

        annotationBoxRectangle.visible(true);
        annotationBoxRectangle.width(0);
        annotationBoxRectangle.height(0);
        annotating = true;
      });

      stage.on('mousemove touchmove', (e) => {
        if (mousedown && annotating) {
          e.evt.preventDefault();
          x2 = stage.getRelativePointerPosition().x;
          y2 = stage.getRelativePointerPosition().y;

          if (x2 < 0) { x2 = 0; }
          if (x2 > this.imageDimensions.width) { x2 = this.imageDimensions.width; }
          if (y2 < 0) { y2 = 0; }
          if (y2 > this.imageDimensions.height) { y2 = this.imageDimensions.height; }
          const x = Math.min(x1, x2);
          const y = Math.min(y1, y2);
          const width = Math.abs(x2 - x1);
          const height = Math.abs(y2 - y1);

          annotationBoxRectangle.setAttrs({
            x, y, width, height,
          });
        }
      });

      stage.on('mouseup touchend', (e) => {
        e.evt.preventDefault();
        mousedown = false;

        if (!annotating) {
          return;
        }

        // update visibility in timeout, so we can check it in click event
        setTimeout(() => {
          annotationBoxRectangle.visible(false);
        });

        const annotationBoxRectangleDimensions = annotationBoxRectangle.getAttrs();
        if (annotationBoxRectangleDimensions.width !== 0 && annotationBoxRectangleDimensions.height !== 0) {
          let newAnnotationBox = {
            id: uuidv4(),
            label_name: this.selectedLabel.name,
            label_index: this.selectedLabel.index,
            label_id: this.selectedLabel.id,
            type: "box",
            x: annotationBoxRectangleDimensions.x,
            y: annotationBoxRectangleDimensions.y,
            w: annotationBoxRectangleDimensions.width,
            h: annotationBoxRectangleDimensions.height,
            score: 1,
          };
          newAnnotationBox = this.convertPixelsToNormalized(newAnnotationBox);
          this.$emit('created-annotation-box', newAnnotationBox);
        }

        // Allow image to be dragged again now that selection is complete
        this.stageNode.setAttr('draggable', true);
        annotating = false;
      });
    },
    convertPixelsToNormalized(annotation) {
      const newX = roundNumber(annotation.x / this.imageDimensions.width, 3);
      const newY = roundNumber(annotation.y / this.imageDimensions.height, 3);
      const newWidth = roundNumber(annotation.w / this.imageDimensions.width, 3);
      const newHeight = roundNumber(annotation.h / this.imageDimensions.height, 3);
      const convertedAnnotation = { ...annotation };
      convertedAnnotation.x = newX;
      convertedAnnotation.y = newY;
      convertedAnnotation.w = newWidth;
      convertedAnnotation.h = newHeight;
      return convertedAnnotation;
    },
    reset() {
      this.annotationBoxRectNode.visible(false);
      this.annotationBoxRectNode.width(0);
      this.annotationBoxRectNode.height(0);
    },
  },
};
</script>

<style>

</style>
