<template>
  <Modal
    ref="trainerCompareTrainedModelsModal"
    :title="'Select Experiments to Compare'"
  >
    <!-- <template #modal-header /> -->
    <template #modal-main>
      <div class="compare-trained-models">
        <template v-if="trainers && trainers.length > 0">
          <ul class="trainers scrollbar">
            <li
              v-for="(trainer, i) in trainers"
              :key="i"
              class="trainers__list-item"
            >
              <label>
                <input
                  v-model="selectedTrainers"
                  type="checkbox"
                  :value="trainer.id"
                >
                {{ trainer.name }}
              </label>
            </li>
          </ul>
        </template>
        <div v-else class="trained-models-empty">
          <span>No Trained Models</span>
        </div>
      </div>
    </template>
    <template #modal-footer>
      <div class="footer">
        <button
          class="button modal-action-button"
          :disabled="selectedTrainers.length < 2"
          @click="launchGraph"
        >
          Compare Selected Experiments
        </button>
      </div>
    </template>
  </Modal>
</template>

<script>
import DatastoreConnect from '@/assets/js/DatastoreFunctions/datastore-interface';
import Modal from '@/components/Modal.vue';

export default {
  name: 'TrainerCompareTrainedModelsModal',
  components: {
    Modal,
  },
  props: {
    trainers: {
      type: Array,
      default: () => [],
    },
  },
  emits: ['launch-trained-model-compare'],
  data() {
    return {
      selectedTrainers: [],
    };
  },
  computed: {
    currentProject() {
      return this.$store.state.projects.currentProject;
    },
  },
  created() {
    this.dataConnect = new DatastoreConnect(this.$store.state.enterpriseServerUrl);
  },
  methods: {
    showModal() {
      this.$refs.trainerCompareTrainedModelsModal.showModal();
    },
    async launchGraph() {
      this.$emit('launch-trained-model-compare', this.selectedTrainers);
      this.$refs.trainerCompareTrainedModelsModal.closeModal();
    },
  },
};

</script>

<style lang="scss" scoped>
.compare-trained-models {
  display: flex;
  flex-direction: column;
  min-width: 440px;
  min-height: 0px;
  flex: 1 1 auto;
  padding: 16px;

  h3 {
    margin: 0;
    font-size: 1rem;
    text-align: left;
    @include themify() {
      color: themed('body-text-color-secondary');
    }
  }
}

.trained-models-empty {
  display: flex;
  flex-direction: column;
  height: 100%;
  align-items: center;
  justify-content: center;
  font-size: 1.25rem;
  font-weight: bold;
  border: solid 1px black;
  border-radius: 8px;
  @include themify() {
    background: radial-gradient(circle, rgba($color-primary-300, 0.075) 50%, rgba(var(--color-primary-rgb), 0.125) 100%);
  }

  span {
    padding: 16px;
    text-align: center;
  }
}

.trainers {
  display: flex;
  flex-direction: column;
  gap: 6px;
  list-style: none;
  padding: 2px 4px;
  margin: -2px -4px;
  overflow-y: auto;

  &__list-item {
    display: flex;
    flex-direction: row;
    position: relative;
    background: rgb(231, 224, 224);
    border-radius: 4px;
    gap: 16px;
    align-items: center;
    cursor: pointer;
    @include themify() {
      background: themed('color-white-700');
    }

    &:hover {
      box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.375), 0 0 1px 1px rgba(0, 0, 0, 0.125);
    }

    label {
      display: flex;
      align-items: center;
      width: 100%;
      min-height: 32px;
      // overflow-wrap: anywhere;
      font-weight: 600;
      font-size: 1.25rem;
      padding: 8px;
      // letter-spacing: 0.05em;
      // white-space: nowrap;
      cursor: pointer;
      @include themify() {
        color: themed('body-text-color-secondary');
      }

      input {
        width: 16px;
        height: 16px;
        margin-right: 16px;
      }
    }
  }
}
.footer {
  display: flex;
  flex-direction: row;
  padding: 16px;
  justify-content: flex-end;
}

</style>
