import { defineStore } from 'pinia';
import { ref, watch, computed } from 'vue';

export const useEditorControlsStore = defineStore('editorControls', () => {
  const selectedLabel = ref(null);
  const currentCanvasTool = ref('pointer');
  const current3DCanvasTool = ref('3d-pointer');

  function $reset() {
    selectedLabel.value = null;
  }

  return {
    selectedLabel,
    currentCanvasTool,
    current3DCanvasTool,
    $reset,
  };
});
