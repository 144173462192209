let timeofLastErrorMessage = 0;

function showError(title, message) {
  const timeNow = Date.now();
  const msSinceLastMessage = timeNow - timeofLastErrorMessage;
  if (msSinceLastMessage > 500) { // 500ms between error messages
    timeofLastErrorMessage = timeNow;
    console.log(`${title} ${message}`);
  }
}

export default showError;
