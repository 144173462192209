<template>
  <!-- <AnnotationToolViewingModeControls
    @zoom-to-fit="handleZoomToFit"
  /> -->
  <div
    id="konva-container"
    ref="konvaContainer"
    class="konva-container"
  >
    <v-stage
      v-show="showDepthMap"
      ref="stage"
      :config="configKonva"
      @pointermove="debounceHandleMouseover"
    >
      <v-layer>
        <KonvaImage
          ref="konvaImage"
          :imagePath="imagePath"
          :imageId="imageObj?.id"
          :showPointerAxes="true"
          :useImageCacheOnly="true"
          :imageCacheElem="imageCacheElem"
          @image-loaded="handleImageLoaded"
        />
      </v-layer>
      <v-layer>
        <v-rect ref="textBox" :config="configTextBox" />
        <v-text ref="textNode" :config="configText" />
      </v-layer>
    </v-stage>
  </div>
</template>

<script>
import { debounce, roundNumber } from '@/assets/js/utils.js';
import {
  ref, toRefs, onMounted, computed,
} from 'vue';
import { useResizeObserver, useDebounceFn } from '@vueuse/core';
import useZoom from '@/composables/canvas/useZoom.js';
import DatastoreConnect from '@/assets/js/DatastoreFunctions/datastore-interface';
import { useViewerVisualizationsStore } from '@/stores/useViewerVisualizationsStore.js';
import { decode as fastPNGDecode } from 'fast-png';
import { storeToRefs } from 'pinia';
import KonvaImage from './KonvaImage.vue';

export default {
  name: "DepthMapViewer",
  components: {
    KonvaImage,
  },
  props: {
    shouldZoomToFit: {
      type: Boolean,
      default: true,
    },
    currentTool: {
      type: String,
      default: 'pointer',
    },
    showDepthMap: {
      type: Boolean,
      default: false,
    },
  },
  emits: [
    'new-coordinates',
  ],
  setup(props, ctx) {
    const visualizationStore = useViewerVisualizationsStore();

    const {
      currentAnimationSample,
      mouse,
    } = storeToRefs(visualizationStore);

    let expose = {};

    // Setup canvas
    const konvaContainer = ref(null);
    const imageDimensions = ref(null);
    const stage = ref(null);
    const stageNode = ref(null);
    const stageScale = ref({ x: 1, y: 1 });
    const configKonva = ref({
      width: 100,
      height: 100,
    });
    const configText = ref({
      text: "",
      x: 100,
      y: 100,
      fontSize: 24,
      fontFamily: 'Open Sans',
      fill: 'white',
    });
    const configTextBox = ref({
      x: 100,
      y: 100,
      height: 1,
      width: 1,
      fill: 'red',
    });
    onMounted(() => {
      stageNode.value = stage.value.getStage();
    });
    const imageComponent = computed(() => 'KonvaImage');

    // Setup canvas objects
    const canvasObjects = ref({});
    const selectedObjects = ref([]);
    const selectedNodes = computed(() => Object.entries(canvasObjects.value)
      .map(([key, val]) => { if ((selectedObjects.value.map((v) => v.toString())).includes(key)) return val.node; })
      .filter((node) => node));

    // Setup canvas zoom and resize
    const {
      zoomToFitImage, zoomToSelection: baseZoomToSelection, handleCanvasZoomScroll,
    } = useZoom(konvaContainer, imageDimensions, stageNode, stageScale);
    function zoomToSelection() {
      baseZoomToSelection(selectedNodes);
    }
    function handleCanvasResize() {
      configKonva.value.width = konvaContainer.value.clientWidth;
      configKonva.value.height = konvaContainer.value.clientHeight;
      zoomToFitImage();
    }
    useResizeObserver(konvaContainer, useDebounceFn(handleCanvasResize, 15));
    // useResizeObserver(konvaContainer, () => setTimeout(handleCanvasResize, 0));
    expose = {
      ...expose,
      zoomToFitImage,
      zoomToSelection,
    };

    ctx.expose(expose);

    return {
      currentAnimationSample,
      konvaContainer,
      stage,
      imageDimensions,
      stageNode,
      stageScale,
      configKonva,
      imageComponent,
      canvasObjects,
      selectedObjects,
      selectedNodes,
      zoomToFitImage,
      zoomToSelection,
      handleCanvasZoomScroll,
      handleCanvasResize,
      configText,
      configTextBox,
      mouse,
    };
  },
  data() {
    return {
      isSelectionMousedown: false,
      imageNode: null,
      textNode: null,
      imagePointerCoordinates: null,
      internalAnnotations: [],
      mouseoverObjectWhileAnnotating: null,
      decodedImage: null,
      imagePath: null,
    };
  },
  computed: {
    debounceHandleMouseover() {
      return debounce(() => { this.handleMouseover(); }, 2);
    },
    stageReady() {
      return Boolean(this.stageNode && this.imageNode && this.imageDimensions && this.stageScale);
    },
    imageCacheElem() {
      return this.currentAnimationSample?.depthmap ? this.currentAnimationSample.depthmap[0] : null;
    },
    imageObj() {
      return this.currentAnimationSample?.imageObj ? this.currentAnimationSample.imageObj : null;
    },
    depthmapAnn() {
      const anns = this.currentAnimationSample?.annotations ? this.currentAnimationSample.annotations : null;
      return anns.find((e) => e.type === 'depthmap');
    },
  },
  watch: {
    mouse: {
      deep: true,
      immediate: true,
      handler() {
        this.handleMouseCoordinates(this.mouse);
      },
    },
  },
  mounted() {
    this.imageNode = this.$refs.konvaImage.getImageNode();
    this.textNode = this.$refs.textNode.getNode();
  },
  methods: {
    async getImageBlob() {
      if (!this.imageCacheElem && this.depthmapAnn) {
        const dataConnect = new DatastoreConnect();
        const blob = await dataConnect.getAnnotationFile(this.depthmapAnn.id, true);
        this.imagePath = URL.createObjectURL(blob);
        return blob;
      } else if (this.imageCacheElem && this.depthmapAnn) {
        const dataConnect = new DatastoreConnect();
        const blob = await dataConnect.getAnnotationFile(this.depthmapAnn.id, true);
        this.imagePath = null;
        return blob;
      } else {
        this.imagePath = null;
        return null;
      }
    },
    async handleImageLoaded(data) {
      const blob = await this.getImageBlob();
      this.imageDimensions = { width: data.width, height: data.height };
      await this.$nextTick();
      this.stageNode.scale(
        { x: 1, y: 1 },
      );
      if (this.shouldZoomToFit) { await this.$nextTick(this.zoomToFitImage); }
      if (data.url && blob) {
        try {
          const arrayBuffer = await blob.arrayBuffer();
          this.decodedImage = (await fastPNGDecode(arrayBuffer));
        } catch (err) {
          console.log(err);
        }
      }
    },
    get16BitValueAt(x, y) {
      if (this.decodedImage.depth !== 16) {
        console.log("Image is not 16 bit depth");
        return;
      }

      if (this.decodedImage.channels !== 1) {
        console.log("Image is not grayscale");
        return;
      }

      const index = (y * this.decodedImage.width + x);
      const values = [];

      for (let i = 0; i < this.decodedImage.channels; i++) {
        // uint16 to kitti
        const value = (this.decodedImage.data[index + i * 2] << 8) | this.decodedImage.data[index + i * 2 + 1];

        // kitti to meter
        values.push(Math.round(value / 256));
      }

      return values;
    },
    handleMouseCoordinates(mouse) {
      if (mouse.offsetX && mouse.offsetY && this.decodedImage) {
        const elemWidth = this.textNode.textWidth;
        const elemHeight = this.textNode.textHeight;
        const relPtr = {
          x: mouse.offsetX * this.imageDimensions.width,
          y: mouse.offsetY * this.imageDimensions.height,
        };
        this.configText.x = relPtr.x + 8;
        this.configText.y = relPtr.y - 48;
        this.configTextBox.x = relPtr.x + 8;
        this.configTextBox.y = relPtr.y - 48;

        if (relPtr.x + elemWidth + 10 >= this.imageDimensions.width) {
          // hitting right border
          this.configText.x = relPtr.x - 5 - elemWidth;
          this.configTextBox.x = relPtr.x - 5 - elemWidth;
        }
        if (relPtr.y - elemHeight - 10 <= 0) {
          // hitting top border
          this.configText.y = relPtr.y + 5;
          this.configTextBox.y = relPtr.y + 5;
        }
        this.configText.text = `(${Math.round(relPtr.x)}, ${Math.round(relPtr.y)}, ${this.get16BitValueAt(Math.round(relPtr.x), Math.round(relPtr.y))}cm)`;
        this.configTextBox.width = elemWidth;
        this.configTextBox.height = elemHeight;
        this.configText.fontSize = 0.022 * this.imageNode.attrs.width;
      } else {
        this.configText.text = '';
        this.configTextBox.width = 0;
        this.configTextBox.height = 0;
      }
    },
    handleMouseover() {
      // Tell image to get new pointer position now that it has been registered
      this.$refs.konvaImage?.handleImagePointerMove();
    },
    handleZoomToFit() {
      this.zoomToFitImage();
    },
    handleZoomToSelection() {
      this.zoomToSelection();
    },
  },
};
</script>

<style lang="scss" scoped>
.konva-container {
  width: 100%;
  height: 100%;
  z-index: 2;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100' height='100' viewBox='0 0 100 100' %3E%3Cg fill-rule='evenodd'%3E%3Cg fill='%239C92AC' fill-opacity='0.125'%3E%3Cpath opacity='.5' d='M96 95h4v1h-4v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4H0v-1h15v-9H0v-1h15v-9H0v-1h15v-9H0v-1h15v-9H0v-1h15v-9H0v-1h15v-9H0v-1h15v-9H0v-1h15v-9H0v-1h15V0h1v15h9V0h1v15h9V0h1v15h9V0h1v15h9V0h1v15h9V0h1v15h9V0h1v15h9V0h1v15h9V0h1v15h4v1h-4v9h4v1h-4v9h4v1h-4v9h4v1h-4v9h4v1h-4v9h4v1h-4v9h4v1h-4v9h4v1h-4v9zm-1 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-9-10h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm9-10v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-9-10h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm9-10v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-9-10h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm9-10v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-9-10h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9z'/%3E%3Cpath d='M6 5V0H5v5H0v1h5v94h1V6h94V5H6z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
  background-repeat: repeat;
  background-color: var(--canvas-background);

}
</style>
