<template>
  <template v-for="(arg, i) in importType.args" :key="`importType-arg-${i}`">
    <div v-if="arg.type === 'text'" class="row">
      <div class="settings__control-group">
        <label :class="{'required' : !arg.optional}">{{ arg.label }}</label>
        <div class="source-row">
          <input
            v-model="textInput[arg.label]"
            class="source-row__text-input input"
            type="text"
          >
        </div>
      </div>
    </div>
    <div v-if="arg.type === 'folder'" class="row">
      <div class="settings__control-group">
        <label>{{ arg.label }} <span v-if="files_parsed > 0" class="files-parsed-label">{{ files_parsed }} Files Found</span></label>
        <div class="source-row">
          <input
            v-model="sourceDir"
            class="source-row__folder-input input"
            type="text"
          >
          <button class="source-row__folder-btn button-secondary" :class="{'button-spinner': parsingFiles}" @click="chooseSourceFolder">SELECT FOLDER</button>
          <input
            v-show="false"
            id="import-script-folder-input"
            ref="importFolder"
            type="file"
            webkitdirectory
            mozdirectory
            msdirectory
            odirectory
            directory
            multiple
            @change="sourceSelected"
          >
        </div>
        <div v-if="importType.type !== 'annotations'" class="source-row__group-checkbox">
          <v-checkbox
            v-model="shouldCreateImageGroups"
            density="compact"
            hide-details="true"
            label="Create image groups from folders"
          />
        </div>
      </div>
    </div>
    <div v-if="arg.type === 'file'" class="row">
      <div class="settings__control-group">
        <label :class="{'required' : !arg.optional}">{{ arg.label }}</label>
        <div class="source-row">
          <input
            v-model="sourceFile"
            class="source-row__folder-input input"
            type="text"
          >
          <button class="source-row__folder-btn button-secondary" @click="chooseSourceFile">SELECT FILE</button>
          <input
            v-show="false"
            id="import-script-file-input"
            ref="importFile"
            type="file"
            @change="sourceFileSelected"
          >
        </div>
      </div>
    </div>
    <div v-if="arg.type === 'fps'" class="row">
      <div class="settings__control-group">
        <label :class="{'required' : !arg.optional}">FPS</label>
        <div class="source-row">
          <input
            v-model="videoFPS"
            class="input"
            type="number"
          >
        </div>
      </div>
    </div>
    <div v-if="arg.type === 'dest_annotation_set'" class="row">
      <div class="col-6 settings__control-group align-items-start">
        <label :class="{'required' : !arg.optional}">Destination Annotation Set</label>
        <AnnotationSetsRadioSelect
          v-model="selectedAnnotationSet"
          :annotationSets="annotationSets"
        />
      </div>
    </div>
  </template>
  <div v-if="message" class="row">
    <div class="col-12 settings__control-group">
      <div class="result error">
        <span>{{ message }}</span>
      </div>
    </div>
  </div>
  <div v-if="groups && groups.length > 0 && importType.type !== 'annotations'" class="row">
    <div class="col-12 settings__control-group">
      <label>Destination Group</label>
      <select
        id="type-select"
        v-model="selectedGroup"
        class="select"
        required
      >
        <option value="">Select a Group</option>
        <option v-for="(option, i) in groups" :key="`import-group-${i}`" :value="option">
          {{ option.name }}
        </option>
      </select>
    </div>
  </div>

  <div class="row">
    <div class="col-4" />
    <div class="col-8 action-buttons">
      <button
        class="button button-sm button-secondary"
        @click="handleCancel"
      >
        Cancel
      </button>
      <button
        class="button button-sm"
        :class="{'button-spinner': startingImport}"
        :disabled="startingImport || parsingFiles"
        @click="handleStartImport"
      >
        Start Import
      </button>
    </div>
  </div>
</template>

<script>
import { showDirectoryPicker, showOpenFilePicker } from 'file-system-access';
import DatastoreConnect from '../../../assets/js/DatastoreFunctions/datastore-interface';
import { inputSanitizer, changeSanitizer } from '../../../assets/js/utils';
import AnnotationSetsRadioSelect from '../AnnotationSetsRadioSelect.vue';

export default {
  name: 'DefaultImportScriptForm',
  components: {
    AnnotationSetsRadioSelect,
  },
  props: {
    modelValue: {
      type: Object,
      default: () => {},
    },
    datasetID: {
      type: Number,
      default: null,
    },
    annotationSets: {
      type: Array,
      default: () => [],
    },
    importType: {
      type: Object,
      default: () => {},
    },
    isFilesParsed: {
      type: Boolean,
      default: false,
    },
  },
  emits: [
    'cancel', 'upload-started', 'upload-complete', 'upload-error', 'update:modelValue', 'update:isFilesParsed',
  ],
  data() {
    return {
      message: "",
      name: "",
      sourceDir: "",
      sourceFile: "",
      textInput: {},
      videoFPS: 10,
      selectedAnnotationSet: null,
      files: null,
      startingImport: false,
      files_parsed: 0,
      parsingFiles: false,
      shouldCancelFileParsing: false,
      groups: null,
      selectedGroup: '',
      shouldCreateImageGroups: false,
      imageGroupsToCreate: [],
    };
  },
  computed: {
    isDebugMode() {
      return this.$store.state.debugMode;
    },
    uploadFolderTasks() {
      return this.$store.state.tasks.uploadTasks;
    },
    user() {
      return this.$store.state.user.user;
    },
    currentProject() {
      return this.$store.state.projects.currentProject;
    },
    datasetName() {
      const dataset = this.$store.state?.datasets?.datasetList.find((d) => d.id === this.datasetID);
      return dataset.name;
    },
    formValue() {
      const params = {};
      if (this.selectedAnnotationSet) {
        params.annotation_set_id = this.selectedAnnotationSet.id;
      }
      if (this.videoFPS) {
        params.fps = this.videoFPS;
      }
      return params;
    },
    importParams() {
      const importParams = {
        import_type: this.importType.type,
        dataset_id: this.datasetID,
        dataset_name: this.datasetName,
        project_id: this.currentProject.id,
        group_id: this.selectedGroup.id,
        ...this.formValue,
      };
      if (this.shouldCreateImageGroups) {
        importParams.create_image_groups = this.imageGroupsToCreate;
      }
      if (this.selectedGroup) {
        importParams.group_id = this.selectedGroup.id;
      }
      return importParams;
    },
  },
  watch: {
    formValue() {
      this.$emit('update:modelValue', this.formValue);
    },
    files_parsed() {
      if (this.files_parsed > 0) {
        this.$emit('update:isFilesParsed', true);
      } else {
        this.$emit('update:isFilesParsed', false);
      }
    },
  },
  async mounted() {
    this.dataConnect = new DatastoreConnect();
    this.groups = await this.getDatasetGroups();
  },
  unmounted() {
    this.shouldCancelFileParsing = true;
  },
  methods: {
    inputSanitizer,
    changeSanitizer,
    clearInput() {
      this.name = "";
      this.source = null;
      this.selectedAnnotationSet = null;
      this.sourceDir = "";
      this.sourceFile = "";
      this.videoFPS = 1;
      this.message = "";
      this.textInput = {};
    },
    handleCancel() {
      this.$emit('cancel');
    },
    async getDatasetGroups() {
      return this.dataConnect.getGroupListForDataset({
        dataset_id: this.datasetID,
      })
        .then((resp) => {
          if (resp.error !== undefined) {
            throw Error(resp.error);
          }
          return resp.result;
        })
        .catch((error) => {
          throw error;
        });
    },
    validateForm() {
      if (this.importType.args.some((item) => item.type === "folder")) {
        if (!this.sourceDir) {
          return "Please select a folder";
        }

        if (this.files === null || !this.files.length) {
          return "No files found in folder";
        }
      }

      if (this.importType.args.some((item) => item.type === "file")) {
        if (!this.sourceFile) {
          return "No files found";
        }

        if (this.files === null || !this.files.length) {
          return "No files found";
        }
      }

      if (Object.values(this.importType.args).includes('fps')) {
        if (this.videoFPS === 0) {
          return "Please enter a non-zero frame number";
        }
      }

      if (Object.values(this.importType.args).includes('text')) {
        if (Object.values(this.textInput).includes('')) {
          return "Text Input can not be blank.";
        }
      }

      return null;
    },
    handleStartImport() {
      if (this.importType.type === 'video_frames') {
        this.handleVideoImport();
        return;
      }

      this.handleStartImportScript();
    },
    async handleVideoImport() {
      if (!this.files || this.files.length === 0) {
        this.message = "Please select a file";
        return;
      }

      this.startingImport = true;

      const formData = new FormData();
      for (let i = 0; i < this.files.length; i++) {
        formData.append('video', this.files[i]);
      }

      const paramObj = {
        fps: this.videoFPS,
        dataset_id: this.datasetID,
      };

      if (this.selectedGroup) {
        paramObj["group_id"] = this.selectedGroup.id;
      }
      formData.append('params', new Blob([JSON.stringify(paramObj)], { type: 'application/json' }));

      await this.dataConnect.uploadVideo(formData)
        .catch((error) => {
          console.error(error);
          this.startingImport = false;
        });
      this.startingImport = false;
      this.$emit('upload-complete');
    },
    async handleStartImportScript() {
      const errorMessage = this.validateForm();
      if (errorMessage) {
        this.message = errorMessage;
        return;
      }

      this.startingImport = true;

      let resp = null;
      if (this.importType.type === 'annotations') {
        if (!this.selectedAnnotationSet) {
          this.$emit("upload-error", {
            confirmMessage: `No annotation set selected.`,
            confirmMessageHeader: 'Upload Error',
          });
          return;
        }
        resp = await this.s3MultipleAnnotationFileUpload({ fileList: this.files, params: this.importParams })
          .catch((error) => {
            console.error(error);
            alert(error);
          });
      } else if (this.importType.type === 'dropbox') {
        resp = await this.dataConnect.startImport({
          dataset_id: this.datasetID,
          dataset_name: this.datasetName,
          import_type: this.importType.type,
          annotation_set_id: this.selectedAnnotationSet?.id,
          project_id: this.currentProject.id,
          import_data: JSON.stringify({
            dropbox_access_code: this.textInput["Authorization Code"],
            dropbox_root: this.textInput["Folder"],
          }),
        });

        if (resp.result) {
          this.$store.commit('tasks/addImportTask', {
            taskID: resp.result.id, docker_task_id: resp.result.id, type: resp.result.name, dataset_id: this.datasetID,
          });
        }
      } else {
        resp = await this.s3MultipleFileUpload({ fileList: this.files, params: this.importParams })
          .catch((error) => {
            console.error(error);
            alert(error);
          });
      }

      this.startingImport = false;

      if (resp) {
        this.$emit('upload-complete');
      }
    },
    async chooseSourceFolder() {
      try {
        const directoryHandle = await showDirectoryPicker({ _preferPolyfill: false });
        this.parsingFiles = true;
        const tree = { [directoryHandle.name]: {} };
        this.sourceDir = directoryHandle.name;
        this.files_parsed = 0;
        this.files = [];
        await this.handleDirectory(directoryHandle, tree, directoryHandle.name);
        Promise.all(this.files).then((files) => {
          this.files = files;
          this.parsingFiles = false;
        });
      } catch (error) {
        console.error(error);
      }
    },
    async handleDirectory(dirhandle, tree, relativePath) {
      const subtree = {};
      tree[dirhandle.name] = subtree;
      for await (const [name, handle] of dirhandle) {
        if (this.shouldCancelFileParsing) { break; }
        if (handle.kind === "directory") {
          // Get first directory level group names
          if (dirhandle.name === this.sourceDir) {
            this.imageGroupsToCreate.push(name);
          }
          const newRelativePath = relativePath ? `${relativePath}/${name}` : name;
          await this.handleDirectory(handle, subtree, newRelativePath);
        } else {
          this.files_parsed++;
          subtree[name] = "[file]";
          this.files.push(new Promise((resolve, reject) => {
            handle.getFile().then((file) => {
              file.relativePath = `${relativePath}/${name}`;
              resolve(file);
            });
          }));
        }
      }
    },
    chooseSourceFile() {
      this.$refs['importFile'][0].click();
    },
    sourceSelected() {
      this.files = this.$refs['importFolder'][0].files;
      if (this.files.length > 0) {
        const relativePath = this.files[0].webkitRelativePath;
        const folder = relativePath.split("/");
        this.sourceDir = folder[0];
      }
    },
    sourceFileSelected() {
      this.files = this.$refs['importFile'][0].files;
      if (this.files.length > 0) {
        this.sourceFile = this.files[0].name;
      }
    },
    async s3MultipleFileUpload({ fileList, params }) {
      const keys = [];

      // Validate file count and sizes, also trim paths
      const maxFileSize = await this.getMaxImageSize();
      let imgCount = 0;
      let imgTooLargeErrCount = 0;
      for (const file of fileList) {
        let trimmedPath;
        const relativePath = file.relativePath || file.webkitRelativePath;
        if (relativePath) {
          // From folder select
          trimmedPath = relativePath.split('/').slice(1);
        } else {
          // From file select
          trimmedPath = [file.name];
        }
        const isImage = file.type.includes("image/");
        const isVideo = file.type.includes("video/");

        if ((isImage || isVideo) && (file.size <= maxFileSize || maxFileSize === -1)) {
          keys.push(trimmedPath.join('/'));
          imgCount += 1;
        } else if (isImage || isVideo) {
          imgTooLargeErrCount += 1;
        } else {
          keys.push(trimmedPath.join('/'));
        }
      }

      // Check image upload limit
      const imageLimit = await this.checkImageUploadLimit(imgCount)
        .catch((error) => {
          throw error;
        });
      if (imageLimit < 0) {
        this.$emit("upload-error", {
          confirmMessage: `Not enough credits to perform this operation.`,
          confirmMessageHeader: 'Insufficient Credits',
        });
        return null;
      }

      // How many files were too large to upload
      if (imgTooLargeErrCount > 0) {
        this.$emit("upload-error", {
          confirmMessage: `${imgTooLargeErrCount} image(s) were discard due to file size limit.`,
          confirmMessageHeader: 'Large File Size',
        });
      }

      // Get s3 presigned urls
      const presignedResponse = await this.s3CreatePresignedUrl({
        dataset_id: params.dataset_id,
        keys,
      })
        .catch((error) => {
          throw error;
        });

      const newFileList = [];
      if (presignedResponse) {
        const {
          importerSource, indexes, presignedUrls, tnPresignedUrls,
        } = presignedResponse;

        if (importerSource) {
          params.importer_source = importerSource;
        }

        presignedUrls.forEach((url, i) => {
          newFileList.push({
            url,
            thumbnail_url: tnPresignedUrls[i],
            file: fileList[indexes[i]],
          });
        });
      }

      this.startingImport = false;
      this.$emit('upload-started');

      const importResponse = await this.$store.dispatch('importer/importByType', {
        urls: newFileList,
        importParams: params,
      });

      return importResponse;
    },
    async s3MultipleAnnotationFileUpload({ fileList, params }) {
      // TODO: Check annotation upload limit

      this.startingImport = false;
      this.$emit('upload-started');
      const importResponse = await this.$store.dispatch('annotations/importAnnotationsDarknet', {
        files: fileList,
        importParams: params,
      });

      return importResponse;
    },
    async getMaxImageSize() {
      return this.dataConnect.getAccountingMaxImageSize()
        .then((resp) => {
          if (resp.error !== undefined) {
            throw Error(resp.error);
          }
          return resp.result;
        })
        .catch((error) => {
          throw error;
        });
    },
    async checkImageUploadLimit(imgCount) {
      return this.dataConnect.checkImageLimitForAccounting({
        image_count: imgCount,
      })
        .then((resp) => {
          if (resp.error !== undefined) {
            throw Error(resp.error);
          }
          return resp.result;
        })
        .catch((error) => {
          throw error;
        });
    },
    async s3CreatePresignedUrl({ dataset_id, keys }) {
      return this.dataConnect.s3CreatePresignedUrl({
        dataset_id,
        keys,
      })
        .then((resp) => {
          if (resp.error !== undefined) {
            throw Error(resp.error);
          }
          return resp.result;
        })
        .catch((error) => {
          throw error;
        });
    },
  },
};
</script>

<style lang="scss" scoped>

.row + .row {
  margin-top: 1rem;
}

.source-row {
  position: relative;
  display: flex;
  flex-direction: row;
  width: 100%;

  &__folder-input {
    width: unset;
    flex: 1 1 auto;
    border-radius: 6px 0 0 6px;
    border-right: 1px solid transparent !important;

    &:focus {
      margin-right: 1px;
    }
  }

  &__folder-btn {
    flex: 0 1 auto;
    padding: 2px 8px;
    height: 100%;
    border-radius: 0 6px 6px 0;
    font-size: 0.875rem;
    font-weight: 600;
    color: var(--body-text-color-secondary);
  }

  &__group-checkbox {
    display: flex;
    flex-direction: row;
    width: 100%;
  }
}

.result {
  display: flex;
  width: 100%;
  height: 100%;
  border-radius: 5px;
  font-size: 1rem;
  font-weight:600;
  padding: 10px;
  align-items: center;
  justify-content: center;
}

.error {
  @include themify() {
    background: rgba(182, 94, 94, 0.5) ;
    color: themed('color-error')
  }
}

.action-buttons {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  gap: 8px;
}

.files-parsed-label {
  float: right;
}
</style>
