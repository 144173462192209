export default function useColorParser() {
  function parseColor(value) {
    if (typeof value === 'number' && value > 0 && value < 0xFFFFFFFF && Number.isInteger(value)) {
      // uint32
      const hexString = value.toString(16);
      return `#${hexString.padStart(8, '0').toUpperCase()}`;
    } else if (value.startsWith('0x') || value.startsWith('#')) {
      // hex
      let hexString = value;
      if (hexString.startsWith('0x')) {
        hexString = `#${hexString.slice(2)}`;
      }
      return hexString;
    } else if (value.startsWith('hsl')) {
      let [h, s, l] = value.match(/\d+/g).map(Number);
      s /= 100;
      l /= 100;

      function hueToRgb(p, q, t) {
        if (t < 0) t += 1;
        if (t > 1) t -= 1;
        if (t < 1 / 6) return p + (q - p) * 6 * t;
        if (t < 1 / 2) return q;
        if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
        return p;
      }

      let r; let g; let
        b;

      if (s === 0) {
        r = g = b = l; // achromatic (gray)
      } else {
        const q = l < 0.5 ? l * (1 + s) : l + s - l * s;
        const p = 2 * l - q;
        r = hueToRgb(p, q, h / 360 + 1 / 3);
        g = hueToRgb(p, q, h / 360);
        b = hueToRgb(p, q, h / 360 - 1 / 3);
      }

      // Convert to HEX and return
      const toHex = (x) => {
        const hex = Math.round(x * 255).toString(16);
        return hex.length === 1 ? `0${hex}` : hex;
      };

      return `#${toHex(r)}${toHex(g)}${toHex(b)}`;
    } else {
      return value.toString();
    }
  }

  return {
    parseColor,
  };
}
