import { createApp } from 'vue';
import { createPinia } from 'pinia';
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate';
import VueKonva from 'vue-konva';
import Tres from '@tresjs/core';

// Vuetify
import 'vuetify/styles';
import { createVuetify } from 'vuetify';
import * as components from 'vuetify/components';
import * as directives from 'vuetify/directives';
import '@mdi/font/css/materialdesignicons.css';
import { VNumberInput } from 'vuetify/labs/VNumberInput';

import App from './App.vue';
import router from './router';
import store from './store';

const clickOutside = {
  beforeMount: (el, binding) => {
    el.clickOutsideEvent = (event) => {
      // here I check that click was outside the el and his children
      if (!(el === event.target || el.contains(event.target))) {
        // and if it did, call method provided in attribute value
        binding.value(event);
      }
    };
    document?.addEventListener("click", el.clickOutsideEvent);
    document.querySelector('.modal')?.addEventListener("click", el.clickOutsideEvent);
  },
  unmounted: (el) => {
    document?.removeEventListener("click", el.clickOutsideEvent);
    document.querySelector('.modal')?.removeEventListener("click", el.clickOutsideEvent);
  },
};

const visibleToggle = (el, binding) => {
  el.style.visibility = binding.value ? 'visible' : 'hidden';
};

const vuetify = createVuetify({
  icons: {
    defaultSet: 'mdi',
  },
  components: {
    ...components,
    VNumberInput,
  },
  directives,
});

const pinia = createPinia();
pinia.use(piniaPluginPersistedstate);

createApp(App)
  .use(store)
  .use(router)
  .use(VueKonva)
  .use(vuetify)
  .use(Tres)
  .use(pinia)
  .directive('click-outside', clickOutside)
  .directive('visible', visibleToggle)
  .mount('#app');
