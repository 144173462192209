<template>
  <!-- eslint-disable max-len -->
  <Popper
    :trigger="trigger"
    :appendToBody="true"
    :config="tooltipPopperConfig"
    :virtualReferenceElement="virtualReferenceElement"
    :show="show"
  >
    <template #trigger>
      <slot />
    </template>
    <div ref="tooltip" class="tooltip">
      <slot name="body" />
    </div>
  </Popper>
</template>

<script>
import Popper from '@/components/Popper.vue';
import { detectOverflow } from '@popperjs/core';

const overflowPadding = 20;

export default {
  name: 'Tooltip',
  components: {
    Popper,
  },
  props: {
    placement: {
      type: String,
      default: 'right',
    },
    flip: {
      type: Boolean,
      default: true,
    },
    virtualReferenceElement: {
      type: Object,
      default: null,
    },
    trigger: {
      type: String,
      default: 'hover',
    },
    show: {
      type: Boolean,
      default: false,
    },
    background: {
      type: String,
      default: '',
    },
  },
  computed: {
    detectOverflowModifier() {
      return {
        name: 'detectOverflowModifier',
        enabled: !this.flip,
        phase: 'main',
        requiresIfExists: ['offset'],
        fn({ state }) {
          const overflow = detectOverflow(state);
          if (state.placement === 'right') {
            state.styles.popper.maxWidth = `${state.elements.popper.offsetWidth - overflow.right - overflowPadding}px`;
          }
          if (state.placement === 'left') {
            state.styles.popper.maxWidth = `${state.elements.popper.offsetWidth - overflow.left - overflowPadding}px`;
          }
        },
      };
    },
    tooltipPopperConfig() {
      return {
        placement: this.placement,
        modifiers: [
          this.detectOverflowModifier,
          {
            name: 'offset',
            options: {
              offset: [0, 8],
            },
          },
          {
            name: 'preventOverflow',
            options: {
              padding: overflowPadding,
            },
          },
          {
            name: 'flip',
            enabled: this.flip,
          },
        ],
      };
    },
  },
};
</script>

<style lang="scss" scoped>

.tooltip {

  background: #fbfbfb;
  border: 1px solid #ffffff;
  color: #000000;
  border-radius: 8px;
  margin: 0;
  padding: 8px;
  z-index: 999;
  box-shadow: 0 2px 5px 0 rgb(0 0 0 / 30%);
  pointer-events: none;
  white-space: nowrap;
}

.tooltip p {
  margin: 0;
  pointer-events: none;
}

</style>
