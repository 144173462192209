<template>
  <Modal
    ref="confusionMatrixModal"
    :title="'Confusion Matrix'"
    :width="'100%'"
    :height="'100%'"
    @closed="resetData"
  >
    <template #modal-main>
      <template v-if="currentDataset !== null">
        <div class="dataset-controls">
          <ComparisonFilter
            v-model="filters"
            :labels="currentLabels"
            :annotationSets="annotationSets"
            :loadingValidations="loadingValidation"
            @apply="applyFilters"
          />
        </div>
        <div v-if="loadingValidation" class="empty-state">
          <InlineLoader
            :width="'60px'"
            :height="'60px'"
            :border="'12px'"
          />
          <button class="button mt-3" @click="abortAllSignals">Cancel</button>
        </div>
        <div
          v-if="!loadingValidation && (hasValidations)"
          class="stats-container scrollbar"
        >
          <div class="charts">
            <div class="flex-col col-large">
              <ComparisonTableDynamic
                :validations="validations"
                :annotationSets="annotationSets"
                @select="handleAnnotationSetSummarySelected"
              />
              <ConfusionMatrixCardV2
                :matrix="confusionMatrixData"
                class="conf-matrix"
              />
            </div>
          </div>
        </div>
      </template>
    </template>
  </Modal>
</template>

<script>
import DatastoreConnect from '@/assets/js/DatastoreFunctions/datastore-interface';
import Modal from '@/components/Modal.vue';
import ComparisonFilter from '@/components/ValidationPage/ComparisonFilter.vue';
import InlineLoader from '@/components/InlineLoader.vue';
import ComparisonTableDynamic from '@/components/ValidationPage/ComparisonTableDynamic.vue';
import ConfusionMatrixCardV2 from '@/components/ValidationPage/ConfusionMatrixCardV2.vue';

export default {
  name: 'ConfusionMatrixModal',
  components: {
    Modal,
    ComparisonFilter,
    InlineLoader,
    ComparisonTableDynamic,
    ConfusionMatrixCardV2,
  },
  data() {
    return {
      filters: {
        iou: 0.5,
        confidence: 0.25,
      },
      annotationsForDisplay: [],
      confMatrixChartProperties: null,
      dataConnect: new DatastoreConnect(),
      loadingValidation: false,
      validations: [],
      controllers: [],
      confusionMatrixData: null,
      shouldClearValidations: false,
    };
  },
  computed: {
    targetSet() {
      return this.$store.state.datasets.confMatrixTargetSet;
    },
    hasValidations() {
      return this.validations.length > 0;
    },
    currentDataset() {
      return this.$store.state.datasets.currentDataset;
    },
    currentLabels() {
      if (this.currentDataset) {
        return this.currentDataset.labels;
      }
      return [];
    },
    annotationSets() {
      if (this.currentDataset) {
        return this.currentDataset.annotation_sets;
      }
      return [];
    },
  },
  watch: {
    currentDataset: {
      deep: true,
      immediate: true,
      handler(d) {
        this.resetList();
      },
    },
  },

  unmounted() {
    this.abortAllSignals();
  },
  methods: {
    resetData() {
      this.filters = {
        iou: 0.5,
        confidence: 0.25,
      };
    },
    applyFilters() {
      if (this.filters.ground_truth_annotation_set_id && this.filters.predicted_annotation_set_id) {
        this.compare();
      }
    },
    abortAllSignals() {
      this.controllers.forEach((controller) => {
        controller.abort();
      });
    },
    compare() {
      this.compareSets();
    },
    async compareSets() {
      this.loadingValidation = true;

      const controller = new AbortController();
      this.controllers.push(controller);
      const result = await this.dataConnect.statsCompare(this.filters, controller.signal)
        .then((resp) => {
          if (resp.error !== undefined) {
            throw Error(resp.error);
          }
          return resp.result;
        })
        .catch((error) => {
          console.log(error);
        });
      this.validations.push({
        ...result,
        target_set_id: this.filters.predicted_annotation_set_id,
      });
      this.confusionMatrixData = {
        confusion_matrix_data: result?.confusion_matrix_data,
        index_name_map: result?.index_name_map,
      };

      this.loadingValidation = false;
    },
    handleAnnotationSetSummarySelected(validation) {
      this.confusionMatrixData = {
        confusion_matrix_data: validation.confusion_matrix_data,
        index_name_map: validation.index_name_map,
      };
    },
    showModal() {
      if (this.targetSet) {
        this.filters.predicted_annotation_set_id = this.targetSet.id;
      }
      this.$refs.confusionMatrixModal.showModal();
    },
    closeModal() {
      this.$refs.confusionMatrixModal.closeModal();
    },
    resetList() {
      this.validations = [];
      this.confusionMatrixData = null;
    },
  },
};
</script>

<style lang="scss" scoped>

.dataset-controls {
  display: flex;
  flex-direction: row;
  padding: 10px 20px 10px 20px;
  align-items: center;
  border-bottom: 1px solid #c9c9c9;
  @include themify() {
    background: themed('color-ribbon');
  }
}

.stats-container {
  margin: 10px;
  width: calc(100% - 20px);
  overflow-y: scroll;
  overflow-x: scroll;
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
}

.flex-col {
  display: flex;
  flex-direction: column;
}

.charts {
  display: flex;
  flex-direction: row;
  flex: 1 1 auto;
  height: 100%;
}

.col-large {
  flex: 1 1 65%;
}

.empty-state {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  height: 100%;
}

.conf-matrix {
  margin: 10px;
}
</style>
