<template>
  <div class="app-page">
    <Header ref="header">
      <template #header-slot>
        <BreadcrumbNavigation />
      </template>
    </Header>
    <div class="main-page-div">
      <div class="user-router">
        <div class="user-router__container">
          <ul>
            <li :class="{ 'active': currentTab.includes('profile') }" @click="navigateToAccount">
              <SVGIcon
                :iconName="'account_circle'"
                :width="'20px'"
                :height="'20px'"
              />
              <span>Profile</span>
            </li>
            <li :class="{ 'active': currentTab.includes('edit') }" @click="navigateToEditUser">
              <SVGIcon
                :iconName="'edit'"
                :width="'20px'"
                :height="'20px'"
              />
              <span>Edit Info</span>
            </li>
          </ul>
        </div>
      </div>
      <main class="main-display-area">
        <router-view />
      </main>
    </div>
  </div>
</template>

<script>
import DatastoreConnect from '@/assets/js/DatastoreFunctions/datastore-interface';
import SVGIcon from '@/components/SVGIcon.vue';
import Header from '@/components/Header.vue';
import BreadcrumbNavigation from '@/components/BreadcrumbNavigation.vue';

export default {
  name: 'UserProfile',
  components: {
    Header,
    BreadcrumbNavigation,
    SVGIcon,
  },
  data() {
    return {
      dsConn: new DatastoreConnect(this.$store.state.enterpriseServerUrl),
      currentTab: 'account',
    };
  },
  computed: {
    currentProject() {
      return this.$store.state.projects.currentProject;
    },
    user() {
      return this.$store.state.user.user;
    },
  },
  watch: {
    $route(to, from) {
      if (to && to !== '') {
        this.currentTab = to.fullPath;
      }
    },
  },

  methods: {
    navigateToAccount() {
      this.$router.push({ name: 'user.profile' });
    },
    navigateToEditUser() {
      this.$router.push({ name: 'user.edit', query: { editUser: this.user.username } });
    },
  },
};
</script>

<style lang="scss" scoped>

.user-router {
  min-width: 250px;
  width: 250px;
  @include themify() {
    background-color: themed('background-light-gray');
  };
  padding: 30px 0px 30px 30px;

  &__container {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    height: 100%;
    padding-right: 30px;

    ul {
      list-style-type: none;
    }

    ul + ul {
      margin-top: 20px;
    }

    li {
      display: flex;
      flex-direction: row;
      margin-top: 2px;
      margin-bottom: 2px;
      font-size: 0.9rem;
      padding: 4px;
      color: $color-link;
      cursor: pointer;
      border-radius: 4px;

      span {
        margin-left: 5px;
      }
    }

    li:hover {
      background-color: rgba(140, 140, 140, 0.1);

    }

    li:focus-visible {
      background-color: rgba(140, 140, 140, 0.1);
    }

    li.active {
      background-color: rgba(140, 140, 140, 0.2);
      color: inherit;
    }
  }
}
</style>
