<template>
  <v-rect
    ref="brushCanvas"
    :config="rectConfig"
    class="brush-canvas"
  />
  <template v-if="drawMode === 'brush' || drawMode === 'eraser'">
    <component
      :is="item.type"
      v-for="item, idx in items"
      :key="idx"
      :ref="item.ref"
      :config="item.config"
      :listening="item.listening"
    />
  </template>
  <template v-else-if="drawMode === 'line'">
    <v-line
      ref="freeDrawLine"
      :config="freeDrawLineConfig"
      :listening="false"
    />
    <!-- draw this circle to represent the first vertex -->
    <v-circle
      v-if="freeDrawLinePoints.length === 2"
      :config="{
        x: freeDrawLinePoints[0],
        y: freeDrawLinePoints[1],
        radius: 1,
        fill: '#DCDCDC',
        stroke: '#DCDCDC',
        strokeWidth: 1,
      }"
      :listening="false"
    />
  </template>
</template>

<script>
import useBrushToPolygon from '@/composables/annotationTool/useBrushToPolygon.js';
import useColorParser from '@/composables/useColorParser.js';

export default {
  name: 'KonvaBrush',
  props: {
    stageNode: {
      type: Object,
      default: () => {},
    },
    imageDimensions: {
      type: Object,
      default: () => {},
    },
    drawMode: {
      type: String,
      default: '',
    },
    fill: {
      type: String,
      default: '',
    },
    drawCursorSize: {
      type: Number,
      default: 0,
    },
    polygon: {
      type: String,
      default: '',
    },
    selectedLabel: {
      type: Object,
      default: null,
    },
    labels: {
      type: Array,
      default: () => [],
    },
    labelColorMap: {
      type: Object,
      default: null,
    },
    scale: {
      type: Number,
      default: 1,
    },
    enableBrush: {
      // this means the annotation is not a segment, or should not add segment
      type: Boolean,
      default: false,
    },
    doneVertexDraw: {
      type: Boolean,
      default: false,
    },
  },
  emits: [
    'edit-polygon', 'new-polygon',
  ],
  setup() {
    const {
      processImage,
    } = useBrushToPolygon();
    const {
      parseColor,
    } = useColorParser();
    return {
      processImage,
      parseColor,
    };
  },
  data() {
    return {
      lineObjects: [],
      pointer: null,
      brushCanvas: null,
      brushCursor: null,
      isDrawing: false,
      freeDrawLinePoints: [],
    };
  },
  computed: {
    rectConfig() {
      return {
        x: 0,
        y: 0,
        width: this.imageDimensions?.width,
        height: this.imageDimensions?.height,
        draggable: false,
        opacity: 0,
      };
    },
    cursorConfig() {
      return {
        type: 'v-circle',
        ref: 'brushCursor',
        listening: false,
        config: {
          x: this.pointer?.x,
          y: this.pointer?.y,
          radius: this.drawCursorSize / this.scale,
          stroke: this.drawMode === 'brush' ? 'black' : '#F6F3F3',
          fill: this.toolColor,
          fillEnabled: this.drawMode === 'brush',
          opacity: 0.8,
          strokeWidth: 1.5 / this.scale,
        },
      };
    },
    items() {
      return [...this.lineObjects, this.cursorConfig];
    },
    freeDrawLineConfig() {
      return {
        stroke: '#DCDCDC',
        strokeWidth: 1.5 / this.scale,
        opacity: 0.8,
        points: this.freeDrawLinePoints,
        closed: true,
        fill: this.toolColor,
      };
    },
    toolColor() {
      let color = '';
      if (this.fill) {
        if (this.drawMode === 'brush' || this.drawMode === 'line') {
          color = this.fill;
        } else {
          color = '#F6F3F3';
        }
      } else {
        color = this.getAnnotationColor();
      }
      return color;
    },
    freeDrawCircleCoordinates() {
      const newArr = [];
      const points1D = [...this.freeDrawLinePoints];
      while (points1D.length) { newArr.push(points1D.splice(0, 2)); }
      return newArr;
    },
  },
  watch: {
    drawMode: {
      immediate: true,
      handler() {
        if ((this.drawMode === 'brush' || this.drawMode === 'eraser')) {
          this.$nextTick(() => {
            this.stageNode.container().style.cursor = 'none';
            this.brushCursor = this.$refs.brushCursor[this.$refs.brushCursor.length - 1]?.getNode();
            this.clearBrushCanvas();
            this.setUpBrushEvents();
          });
        } else if (this.drawMode === 'line') {
          this.$nextTick(() => {
            this.stageNode.container().style.cursor = 'crosshair';
            this.clearBrushCanvas();
            this.setUpVertexDrawEvents();
          });
        } else {
          this.$nextTick(() => {
            this.stageNode.container().style.cursor = 'default';
            this.clearBrushCanvas();
          });
        }
      },
    },
    doneVertexDraw: {
      immediate: true,
      handler(newVal, oldVal) {
        if (!oldVal && newVal && this.freeDrawLinePoints.length >= 6) {
          this.processPolygon();
        }
      },
    },
  },
  mounted() {
    this.brushCanvas = this.$refs.brushCanvas.getNode();
    this.pointer = this.brushCanvas.getRelativePointerPosition();
    this.stageNode.container().style.cursor = 'none';
  },
  beforeUnmount() {
    if (!this.doneVertexDraw && this.freeDrawLinePoints.length >= 6) {
      this.processPolygon();
    }
    this.stageNode.container().style.cursor = 'default';
    this.clearBrushCanvas();
  },
  methods: {
    getAnnotationColor() {
      if (this.selectedLabel && this.selectedLabel.color) {
        return this.parseColor(this.selectedLabel.color);
      } else if (this.selectedLabel && this.labelColorMap) {
        return this.labelColorMap[this.selectedLabel['index']];
      } else {
        return '#F6F3F3';
      }
    },
    clearAllLines() {
      this.freeDrawLinePoints = [];
      this.lineObjects = [];
    },
    clearPreviousLine() {
      this.lineObjects.pop();
    },
    clearBrushCanvas() {
      this.brushCanvas?.off('mousedown touchstart');
      this.brushCanvas?.off('mousemove touchmove');
      this.brushCanvas?.off('mouseup touchend');
      this.brushCanvas?.off('pointerclick');
      this.brushCanvas?.off('mouseout');
      this.brushCanvas?.off('mouseover');
    },
    processPolygon() {
      if (!this.enableBrush) {
        this.clearAllLines();
        return;
      }
      const browserZoom = window.devicePixelRatio;
      const canvas = this.brushCanvas.getLayer().getCanvas();
      const clientRect = this.brushCanvas.getClientRect();
      const originalPolygon = this.polygon ? this.polygon : "[]";
      const processed = this.processImage(
        canvas,
        clientRect.x * browserZoom,
        clientRect.y * browserZoom,
        this.brushCanvas.attrs.width * this.stageNode.attrs.scaleX * browserZoom,
        this.brushCanvas.attrs.height * this.stageNode.attrs.scaleY * browserZoom,
        originalPolygon,
        this.drawMode,
      );
      if (this.polygon) {
        this.$emit('edit-polygon', processed);
      } else {
        this.$emit('new-polygon', processed);
      }
      this.clearAllLines();
    },
    setUpBrushEvents() {
      this.brushCanvas?.on('pointerclick', (e) => {
        e.cancelBubble = true;
      });

      this.brushCanvas?.on('mouseout', (e) => {
        this.stageNode.container().style.cursor = 'default';
        this.brushCursor.hide();
      });

      this.brushCanvas?.on('mouseover', (e) => {
        this.stageNode.container().style.cursor = 'none';
        this.brushCursor.show();
      });

      this.brushCanvas?.on('mousedown touchstart', (e) => {
        this.isDrawing = true;
        this.brushCursor.show();
        this.pointer = this.brushCanvas.getRelativePointerPosition();
        const lastLine = {
          type: 'v-line',
          ref: `line_${this.lineObjects.length + 1}`,
          listening: false,
          config: {
            stroke: this.toolColor,
            strokeWidth: (this.drawCursorSize * 2) / this.scale,
            globalCompositeOperation: 'source-over',
            // round cap for smoother lines
            lineCap: 'round',
            lineJoin: 'round',
            opacity: 0.8,
            // add point twice, so we have some drawings even on a simple click
            points: [this.pointer.x, this.pointer.y, this.pointer.x, this.pointer.y],
          },
        };

        this.lineObjects.push(lastLine);
        e.cancelBubble = true;
      });
      this.brushCanvas?.on('mouseup touchend', (e) => {
        this.brushCursor = this.$refs.brushCursor[this.$refs.brushCursor.length - 1]?.getNode();
        this.isDrawing = false;
        if (this.lineObjects.length === 0) {
          return;
        }

        e.cancelBubble = true;
        this.processPolygon();
      });

      // and core function - drawing
      this.brushCanvas?.on('mousemove touchmove', (e) => {
        this.pointer = this.brushCanvas.getRelativePointerPosition();
        if (!this.isDrawing) {
          return;
        }
        // prevent scrolling on touch devices
        e.evt.preventDefault();
        const newPoints = this.lineObjects[this.lineObjects.length - 1].config.points.concat([this.pointer.x, this.pointer.y]);
        this.lineObjects[this.lineObjects.length - 1].config.points = newPoints;
        e.cancelBubble = true;
      });
    },
    setUpVertexDrawEvents() {
      this.brushCanvas?.on('pointerclick', (e) => {
        this.pointer = this.brushCanvas.getRelativePointerPosition();
        e.cancelBubble = true;

        this.freeDrawLinePoints = this.freeDrawLinePoints.concat([this.pointer.x, this.pointer.y]);
      });

      this.brushCanvas?.on('mouseout', (e) => {
        this.stageNode.container().style.cursor = 'default';
      });

      this.brushCanvas?.on('mouseover', (e) => {
        this.stageNode.container().style.cursor = 'crosshair';
      });

      // and core function - drawing
      this.brushCanvas?.on('mousemove touchmove', (e) => {
        this.pointer = this.brushCanvas.getRelativePointerPosition();
        // prevent scrolling on touch devices
        e.evt.preventDefault();
      });
    },
  },
};
</script>

<style>
</style>
