<template>
  <Modal
    ref="trainingSessionAnalyticsModal"
    :title="'Training Analytics'"
    :fullScreen="true"
    :closeWithEscapeKey="false"
    @closed="handleModalClosed"
  >
    <template #modal-main>
      <div id="wrapper" class="wrapper">
        <div class="loader-dual-ring" />
        <iframe
          v-if="tensorboardReady"
          ref="tensorboard"
          :src="tensorboardURL"
          title="Trainer Charts"
          scrolling="no"
          frameborder="0"
        />
      </div>
    </template>
  </Modal>
</template>

<script>
import DatastoreConnect from '@/assets/js/DatastoreFunctions/datastore-interface';
import Modal from '@/components/Modal.vue';

export default {
  name: 'TrainingSessionAnalyticsModal',
  components: {
    Modal,
  },
  props: {
    trainerParam: {
      type: Object,
      default: () => {},
    },
    projectID: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      tensorboardReady: false,
      tensorboardPort: 0,
      dockerTask: null,
    };
  },
  computed: {
    tensorboardURL() {
      return `tensorboard/${this.tensorboardPort}/`;
    },
  },
  created() {
    this.dataConnect = new DatastoreConnect(this.$store.state.enterpriseServerUrl);
    window.addEventListener("beforeunload", async (e) => {
      e.preventDefault();
      this.closeAnalytics();
      e.returnValue = '';
    });
  },
  unmounted() {
    this.closeAnalytics();
  },
  methods: {
    async showModal() {
      this.launchAnalytics().then(async (resp) => {
        this.tensorboardPort = resp.port;
        this.dockerTask = resp.docker_task;
        await this.retryFetch(this.tensorboardURL, { method: 'HEAD' }, 60, 500)
          .then(() => {
            // Was able to connect to Tensorboard
            this.tensorboardReady = true;
          })
          .catch(() => {
            // Unable to connect to Tensorboard
            alert('Unable to connect to Tensorboard');
          });
      });
      this.$refs.trainingSessionAnalyticsModal.showModal();
    },
    async launchAnalytics() {
      const params = { project_id: this.projectID };
      if (this.trainerParam?.trainer_id) {
        params.trainer_ids = [this.trainerParam.trainer_id];
      } else if (this.trainerParam?.trainer_ids) {
        params.trainer_ids = this.trainerParam.trainer_ids;
      }
      const resp = await this.dataConnect.startGraph(params)
        .catch((error) => {
          console.log('Failed to launch analytics:', error);
        });
      if (!resp || resp.error || !resp.result) {
        return [];
      }
      return resp.result;
    },
    async closeAnalytics() {
      if (this.dockerTask) {
        const params = { docker_task_id: this.dockerTask.id };
        const resp = await this.dataConnect.stopGraph(params)
          .catch((error) => {
            console.log('Failed to shutdown analytics:', error);
          });
        if (!resp || resp.error || !resp.result) {
          return [];
        }
        return resp.result;
      }
      return null;
    },
    handleModalClosed() {
      this.closeAnalytics();
      this.tensorboardReady = false;
    },
    async retryFetch(url, options, maxRetries, delay) {
      let retries = 0;

      while (retries < maxRetries) {
        try {
          const response = await fetch(url, options);
          if (response.ok) {
            return response;
          } else {
            console.warn(`Error in Tensorboard fetch request after ${retries} attempts`);
          }
        } catch (error) {
          console.warn(`Error in Tensorboard fetch request after ${retries} attempts : ${error}`);
        }

        retries++;
        await new Promise((resolve) => setTimeout(resolve, delay));
      }

      throw new Error(`Failed to fetch Tensorboard after ${maxRetries} attempts.`);
    },
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;

  iframe {
    position: relative;
    width: 100%;
    height: 100%;
  }
}

.loader-dual-ring {
  position: absolute;
  display: inline-block;
  width: 80px;
  height: 80px;
}
.loader-dual-ring:after {
  content: " ";
  display: block;
  width: 64px;
  height: 64px;
  margin: 8px;
  border-radius: 50%;
  @include themify() {
    border: 6px solid themed('color-primary');
    border-color: themed('color-primary') transparent themed('color-primary') transparent;
  }
  animation: loader-dual-ring 1.2s linear infinite;
}
@keyframes loader-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
