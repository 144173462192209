<template>
  <div class="content scrollbar">
    <div class="main-content">
      <div class="welcome">
        <div class="welcome__paragraph">
          <span class="welcome__prelude">Welcome to Au-Zone Technologies</span>
          <div class="welcome__brand-name">
            <span>Deep View</span><span>Enterprise</span>
          </div>
          <p class="welcome__description">A complete set of tools for Vision based Machine Learning model development and edge deployment</p>
          <router-link class="button welcome__button login-btn" :to="{ path: '/login', query: { initialMode: 'new-user' } }">Get Started</router-link>
          <br>
          <p class="welcome__offer">Sign up for a trial and get free credits</p>
        </div>
        <!-- <img class="pricing" :src="require('@/assets/img/welcome/pricing.png')" alt=""> -->
      </div>
      <div class="welcome-bento">
        <!-- <div class="welcome__paragraph"> -->
        <div>
          <div class="pane audit">
            <div>
              <h2>AI Assisted Annotation</h2>
              <ul>
                <li><span>Annotate 10,000 images in 24 hours</span></li>
                <li><span>4X faster auditing</span></li>
                <li><span>5X cost saving in generating detection datasets</span></li>
                <li><span>20X cost saving in generating Segmentation datasets</span></li>
              </ul>
            </div>
            <div>
              <h2>Dataset Audit</h2>
              <ul>
                <li><span>Allow distributed teams to work on datasets simulations</span></li>
                <li><span>Share dataset across uses</span></li>
                <li><span>Track progress of audit and labelling tasks</span></li>
              </ul>
            </div>
          </div>
          <div class="pane train">
            <div>
              <h2>Train Models</h2>
              <p>Train and optimize your AI models for an edge deployment, visualize results and optimize model convergence.</p>
            </div>
            <!-- <img :src="require('@/assets/img/welcome/train.png')" alt=""> -->
          </div>
        </div>
        <div class="pane dataset-managment">
          <h2>Dataset Management</h2>
          <p>Enrich, refine and manage datasets iteratively</p>
          <img class="dataset-managment__image" :src="require('@/assets/img/welcome/smart-ag.png')" alt="">
        </div>
      </div>
    </div>
    <!-- <br> -->
    <div class="secondary-content">
      <div class="secondary-content-item segmentation">
        <div class="secondary-content-item__text">
          <div class="secondary-content-item__text-inner">
            <h2>AI Assisted Segmentation</h2>
            <p>Generate segmentations automatically from detection boxes<br>Create segmentations simply by point and click</p>
          </div>
        </div>
        <div class="pane">
          <div class="pane__inner">
            <img class="secondary-content__image" :src="require('@/assets/img/welcome/segmentation2.png')" alt="">
          </div>
        </div>
      </div>
      <div class="secondary-content-item">
        <div class="secondary-content-item__text">
          <div class="secondary-content-item__text-inner">
            <h2>Edge Deployment</h2>
            <p>No Code deployment target hardware<br>Pre-built workflows for Maivin, Jetson AGX and Raspberry PI</p>
          </div>
        </div>
        <div class="pane">
          <div class="pane__inner">
            <img class="secondary-content__image" :src="require('@/assets/img/welcome/edge_deployment.png')" alt="">
          </div>
        </div>
      </div>
      <div class="secondary-content-item">
        <div class="secondary-content-item__text">
          <div class="secondary-content-item__text-inner">
            <h2>3D Boxes and Radar</h2>
            <p>Visualize, Analyze, and Edit 3D boxes and radar data<br>Stream data from multiple devices directly to the platform</p>
          </div>
        </div>
        <div class="pane">
          <div class="pane__inner">
            <img class="secondary-content__image" :src="require('@/assets/img/welcome/radar.png')" alt="">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { auzone_logo } from "@/assets/js/icons";

export default {
  name: 'Welcome',
  data() {
    return {
      auzone_logo,
    };
  },
  mounted() {
    this.$store.commit('setTheme', "theme-auzone-light");
  },
  methods: {
    goto(route) {
      this.$router.push({ path: `/${route}` });
    },

  },
};
</script>
<style lang="scss" scoped>

.content {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  width: 100%;
  overflow-y: auto;
  background-color: var(--color-primary);
  background-image: url("@/assets/img/blu-stripes.png");
  background-repeat: repeat;
  color: var(--primary-text-color);

  h2 {
    color: var(--color-accent);
    font-size: 30px;
    font-weight: 500;
    line-height: 1;
    margin-bottom: 8px;
    font-weight: 300;
    letter-spacing: 0.04em;
  }
}

.main-content {
  display: flex;
  flex-direction: column;
  // height: calc(100vh - #{$header-height});
  min-height: fit-content;
}

@media (max-width: $breakpoint-md) {
  .main-content {
    height: fit-content;
  }
}

.secondary-content {
  display: flex;
  flex-direction: column;
  // min-height: calc(100vh - #{$header-height});
  padding: 16px;
  gap: 16px;

  &__image {
    object-fit: contain;
    width: 100%;
    height: 100%;
    border-radius: 8px;
  }
}

.welcome {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  min-height: 450px;
  align-items: center;
  justify-content: center;

  &__paragraph {
    display: flex;
    flex-direction: column;
    width: 500px;
    margin-left: 64px;
    margin-right: 64px;
    margin-top: 75px;
    margin-bottom: 50px;
    justify-content: center;
  }

  &__prelude {
    color: var(--color-white-900);
    color: hsl(hue($primary-text-color), saturation($primary-text-color), 65%);
    font-size: 1rem;
    font-weight: 600;
    text-align: left;
  }

  &__brand-name {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    line-height: 1;
    margin-bottom: 0.5rem;

    span {
      color: var(--color-accent);
      font-weight: 700;
      font-size: 3rem;
      text-wrap: nowrap;
    }

    span + span {
      color: var(--primary-text-color);
      font-size: 3rem;
      font-weight: 300;
      margin-left: 8px;
    }
  }

  &__description {
    text-align: left;
    margin-bottom: 1rem;
  }

  &__button {
    width: 100%;
  }

  &__offer {
    color: var(--color-accent)
  }

}

@media (max-width: $breakpoint-lg) {
  .welcome {
    flex-direction: column;

    &__paragraph {
      max-width: 100%;
      padding-left: 16px;
      padding-right: 16px;
      margin-left: auto;
      margin-right: auto;
      margin-top: 25px;
      margin-bottom: 25px;
    }

    &__brand-name {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      line-height: 1;
      margin-bottom: 1rem;
      width: 100%;

      span {
        font-size: 1.75rem;
      }

      span + span {
        font-size: 1.75rem;

      }
    }
  }
}

.welcome-bento {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 16px;
  padding: 16px;
  flex: 1 1 auto;
  width: 100%;
  grid-template-rows: 100%;
  min-height: fit-content;

  &> div:first-child {
    display: grid;
    grid-template-rows: auto 1fr;
    grid-gap: 16px;
  }

  .dataset-managment {
    display: flex;
    flex-direction: column;
    overflow: clip;

    &__image {
      width: 100%;
      margin-top: auto;
      margin-bottom: auto;
      margin: 16px 0 0;
      border-radius: 8px;
    }
  }
}

@media (max-width: $breakpoint-md) {
  .welcome-bento {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    grid-gap: 16px;
  }
}

.pane {
  background: rgba(255, 255, 255, 0.075);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(1px);
  -webkit-backdrop-filter: blur(1px);
  border: 1px solid rgba(255, 255, 255, 0.3);
  padding: 24px;
  text-align: left;
  justify-content: center;
  align-items: center;
  display: flex;

  &__inner {
    display: flex;
    height: 100%;
    border-radius: 8px;
  }

  ul {
    width: fit-content;
    font-size: 0.75rem;
    padding-left: 20px;

    li span {
      left: -4px;
      position: relative;
    }
  }
}

.secondary-content-item {
  display: grid;
  grid-template-rows: 400px;
  grid-template-columns: auto 45%;

  &__text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: left;
    padding: 16px;
  }

  &__text-inner {
    max-width: 500px;
  }

  .pane {
    img {
      object-fit: contain;
      width: 100%;
      height: 100%;
      border-radius: 8px;
    }
  }

  &:nth-child(even) {
    grid-template-columns: 45% auto;

    .pane {
      order: -1;
    }
  }
}

@media (max-width: $breakpoint-md) {
  .secondary-content-item {
    display: grid;
    grid-template-rows: auto auto;
    grid-template-columns: auto;

    &__text {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 16px;
    }

    &:nth-child(even) {
      grid-template-rows: auto auto;
      grid-template-columns: auto;

      .pane {
        order: 1;
      }
    }
  }
}

.login-btn {
  background-color: var(--color-primary-400);
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  text-decoration: none;

  &:hover {
    background-color: var(--color-primary-300);
  }
}

.train {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  img {
    height: 100%;
    flex: 1 1 auto;
    width: 50%;
    object-fit: contain;
  }
}

.audit {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  gap: 24px;

  div {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
  }
}
</style>
