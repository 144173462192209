<template>
  <!-- eslint-disable max-len -->
  <BaseGalleryGridItem
    :imageObj="imageObj"
    :annotations="annotations"
    :annotationSets="annotationSets"
    :crop="imageCrop"
    :cropPadding="0.02"
    :annotationDisplayType="'label'"
    :imageQuality="'image'"
    @click="handleImageClicked"
    @image-mouseenter="handleMouseEnter"
    @image-mouseleave="handleMouseOut"
  >
    <template #header>
      <div
        class="card-header"
        @click="handleImageClicked"
        @mouseenter="handleMouseEnter"
        @mouseleave="handleMouseOut"
      >
        <h3
          class="card-header__title"
          @mouseenter.stop="handleMouseOut"
        >
          <BaseMenu
            :placement="'left-start'"
            :appendToBody="true"
            @closed="show_menu = false"
          >
            <button>{{ title }}</button>
            <template #menu>
              <ul class="label-menu scrollbar">
                <li v-for="(label, i) in labels" :key="i">
                  <BaseMenuButton @click="handleLabelChanged(label)">
                    <span>{{ label.name }}</span>
                  </BaseMenuButton>
                </li>
              </ul>
            </template>
          </BaseMenu>
        </h3>
        <IconButton
          v-model="verified"
          class="verified-icon"
          :icon="verifiedIcon"
          :width="20"
          :height="20"
          @click.stop="handleVerifiedClicked"
        />
        <!-- <IconButton
          v-model="flagged"
          class="flag-icon"
          :icon="flagIcon"
          :width="20"
          :height="20"
          @click.stop="handleFlaggedClicked"
        /> -->
        <IconButton
          class="edit-annotations-icon"
          :icon="'edit'"
          :width="20"
          :height="20"
          :type="''"
          @click.stop="handleEditClicked"
        />
      </div>
    </template>
    <template #content>
      <div
        v-if="(flagged || verified || pendingFlagged || pendingVerified)"
        class="card-backdrop"
        :class="{'flagged': flagged, 'verified': verified, 'pending-verified': pendingVerified, 'pending-flagged': pendingFlagged}"
      />
    </template>
  </BaseGalleryGridItem>
</template>

<script>
import IconButton from '@/components/IconButton.vue';
import BaseGalleryGridItem from '@/components/DatasetComponent/BaseGalleryGridItem.vue';
import BaseMenu from '@/components/BaseMenu.vue';
import BaseMenuButton from '@/components/BaseMenuButton.vue';

const Statuses = {
  Verified: "verified",
  Flagged: "flagged",
  Unreviewed: "unreviewed",
};

export default {
  name: 'GalleryGridAnnotationReviewItem',
  components: {
    IconButton,
    BaseGalleryGridItem,
    BaseMenu,
    BaseMenuButton,
  },
  props: {
    imageObj: {
      type: Object,
      default: () => {},
    },
    reviewStatus: {
      type: String,
      default: Statuses.Unreviewed,
    },
    labels: {
      type: Array,
      default: () => [],
    },
    modelValue: {
      type: Object,
      default: () => {},
    },
    annotationSets: {
      type: Array,
      default: () => [],
    },
    annotationSet: {
      type: Object,
      default: () => {},
    },
    annotation: {
      type: Object,
      default: () => {},
    },
    originX: {
      type: Number,
      default: 0,
    },
    originY: {
      type: Number,
      default: 0,
    },
  },
  emits: [
    'grid-item-clicked',
    'image-deleted',
    'view-clicked',
    'edit-clicked',
    'update:reviewStatus',
  ],
  data() {
    return {
      verified: false,
      flagged: false,
      pendingVerified: false,
      pendingFlagged: false,
      localReviewStatus: Statuses.Unreviewed,
    };
  },
  computed: {
    flagIcon() {
      if (this.flagged) {
        return 'flag';
      }
      return 'outlined_flag';
    },
    verifiedIcon() {
      if (this.verified) {
        return 'verified';
      }
      return 'done_outline';
    },
    unreviewed() {
      return !this.verified && !this.flagged;
    },
    imageCrop() {
      return {
        x: this.annotation.x - (this.originX * this.annotation.w),
        y: this.annotation.y - (this.originY * this.annotation.h),
        w: this.annotation.w,
        h: this.annotation.h,
      };
    },
    annotations() {
      if (this.annotation) {
        return [this.annotation];
      }
      return [];
    },
    title() {
      let title = '';
      let label = this.annotation.label_index;
      let score = this.annotation.score;
      if (this.labels.length > 0) {
        label = this.labels[this.annotation.label_index]?.name;
      }
      title = label;
      if (this.annotation.score) {
        score = this.annotation.score;
        title = `${title} [${score.toPrecision(2)}]`;
      }
      return title;
    },
  },
  watch: {
    verified(verified) {
      if (verified) {
        this.localReviewStatus = Statuses.Verified;
        this.$emit('update:reviewStatus', Statuses.Verified);
      }
    },
    flagged(flagged) {
      if (flagged) {
        this.localReviewStatus = Statuses.Flagged;
        this.$emit('update:reviewStatus', Statuses.Flagged);
      }
    },
    unreviewed(unreviewed) {
      if (unreviewed) {
        this.localReviewStatus = Statuses.Unreviewed;
        this.$emit('update:reviewStatus', Statuses.Unreviewed);
      }
    },
    reviewStatus(status) {
      this.localReviewStatus = status;
    },
    localReviewStatus(status) {
      if (status === Statuses.Verified) {
        this.verified = true;
        this.flagged = false;
      }
      if (status === Statuses.Flagged) {
        this.verified = false;
        this.flagged = true;
      }
      if (status === Statuses.Unreviewed) {
        this.verified = false;
        this.flagged = false;
      }
    },
  },
  async mounted() {
    this.localReviewStatus = this.reviewStatus;
  },
  methods: {
    handleImageClicked() {
      this.$emit('grid-item-clicked', this.imageObj);
      if (this.pendingVerified) {
        this.verified = true;
        this.pendingVerified = false;
      }
      if (this.pendingFlagged) {
        this.flagged = true;
        this.pendingFlagged = false;
      }
    },
    handleVerifiedClicked() {
      this.flagged = false;
    },
    handleFlaggedClicked() {
      this.verified = false;
    },
    handleEditClicked() {
      this.$emit('edit-clicked', this.annotation);
    },
    handleMouseEnter(e) {
      document.body.addEventListener('keydown', this.handleKeydown);
      document.body.addEventListener('keyup', this.handleKeyup);
      if (e.shiftKey && !this.flagged) {
        this.pendingFlagged = true;
      } else if (!this.verified) {
        this.pendingVerified = true;
      }
    },
    handleMouseOut(e) {
      document.body.removeEventListener('keydown', this.handleKeydown);
      document.body.removeEventListener('keyup', this.handleKeyup);
      this.pendingVerified = false;
      this.pendingFlagged = false;
    },
    handleKeydown(e) {
      if (e.key === 'Shift') {
        if (this.verified) {
          this.pendingFlagged = true;
        } else if (this.flagged) {
          this.pendingVerified = false;
          this.pendingFlagged = false;
        } else {
          this.pendingVerified = false;
          this.pendingFlagged = true;
        }
      }
    },
    handleKeyup(e) {
      if (e.key === 'Shift') {
        if (this.pendingFlagged) {
          this.pendingFlagged = false;
          if (!this.verified) {
            this.pendingVerified = true;
          }
        }
      }
    },
    handleLabelChanged(label) {
      this.annotation.label_index = label.index;
    },
  },
};
</script>

<style lang="scss" scoped>

.grid-item-container {
  height: 100%;
  width: 100%;
}

.reviewed.grid-item-container:hover {
  outline: none;
}

.card-header {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  min-height: 42px;
  border-bottom: none;
  padding: 10px 10px 10px 10px;
  gap: 4px;

  &__title {
    margin: 0;
    margin-right: 8px;
    font-size: 1.25rem;
    width: 100%;

    button {
      font-size: inherit;
      background: transparent;
      border: none;
      width: 100%;
      cursor: pointer;

      @include themify() {
        box-shadow: 0 0 5px themed('icon-hover-color-primary'), 0 0 1px rgb(0 0 0 / 60%);
      }

      &:hover {
        @include themify() {
          box-shadow: 0 0 8px 3px themed('icon-hover-color-primary'), 0 0 0 1px themed('color-primary');
        }
      }
    }
  }
}

.flag-icon, .verified-icon, .edit-annotations-icon {
  z-index: 3;
}
.flag-icon, .verified-icon {
  @include themify() {
    color: themed('icon-color-inactive') !important;
  }
}

.flag-icon.active {
  @include themify() {
    color: themed('color-red') !important;
  }
}

.verified-icon.active {
  @include themify() {
    color: themed('icon-color-primary') !important;
  }
}

.reviewed .flag-icon.icon-button-hover:hover,
.reviewed .verified-icon.icon-button-hover:hover,
.reviewed .edit-annotations-icon.icon-button-hover:hover {
  @include themify() {
    background-color: themed('icon-hover-color-primary');
    box-shadow: 0 0 0 4px themed('icon-hover-color-primary');
  }
}

.card-backdrop {
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  width: 100%;
  height: 100%;
  opacity: 0.25;
  outline: none;
  z-index: 2;
  border-radius: inherit;
  pointer-events: none;
}
.card-backdrop.flagged {
  @include themify() {
    background: themed('color-red')
  }
}
.card-backdrop.verified {
  @include themify() {
    background: themed('color-primary')
  }
}
.card-backdrop.pending-flagged {
  opacity: 0.125;
  @include themify() {
    background: themed('color-red')
  }
}
.card-backdrop.pending-verified {
  opacity: 0.125;
  @include themify() {
    background: themed('color-primary')
  }
}

.label-menu {
  min-width: 100px;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}
</style>
