<template>
  <div class="annset-li">
    <div class="annset-name-desc">
      <span class="set-name">{{ isDebugMode ? `(${annotationSet.id}) ${annotationSet.name}` : `${annotationSet.name}` }}</span>
      <br>
      <div class="annset-desc">
        <SVGIcon
          :iconName="'image'"
          :width="'18px'"
          :height="'18px'"
        />
        <span>{{ imgCount }}</span>
        <SVGIcon
          :iconName="'tag'"
          :width="'18px'"
          :height="'18px'"
        />
        <span>{{ annotationCount }}</span>
      </div>
    </div>
    <div class="icon-container">
      <IconButton
        class="edit-set-icon"
        :icon="'info'"
        :width="22"
        :height="22"
        :title="`Annotation Set Description`"
        :type="''"
        @click.stop="$emit('open-description')"
      />
      <IconButton
        class="edit-set-icon"
        :icon="'conf_matrix'"
        :width="22"
        :height="22"
        :title="`View Confusion Matrix`"
        :type="''"
        @click.stop="$emit('open-conf-matrix')"
      />
      <IconButton
        class="edit-set-icon"
        :icon="'compare'"
        :width="22"
        :height="22"
        :title="`Validate Annotation Set`"
        :type="''"
        @click.stop="navigateToValidator"
      />
      <IconButton
        class="delete-annset-icon"
        :icon="'close'"
        :width="22"
        :height="22"
        :title="`Delete Annotation Set ${annotationSet.name}`"
        :type="''"
        :disabled="isFrozen"
        @click.stop="$emit('delete-annset')"
      />
    </div>
  </div>
</template>

<script>
import SVGIcon from '@/components/SVGIcon.vue';
import IconButton from '@/components/IconButton.vue';
import DatastoreConnect from '@/assets/js/DatastoreFunctions/datastore-interface.js';

export default {
  name: "AnnotationSetListItem",
  components: {
    SVGIcon,
    IconButton,
  },
  props: {
    annotationSet: {
      type: Object,
      default: () => {},
    },
    numImg: {
      type: Number,
      default: 0,
    },
    numAnnotations: {
      type: Number,
      default: 0,
    },
    refresh: {
      type: Boolean,
      default: false,
    },
    isFrozen: {
      type: Boolean,
      default: false,
    },
  },
  emits: {
    'delete-annset': null, 'copy-annset': null, 'update:numImg': null, 'update:numAnnotations': null, 'open-conf-matrix': null, 'open-description': null,
  },
  data() {
    return {
      dataConnect: new DatastoreConnect(this.$store.state.enterpriseServerUrl),
      imgTotal: 0,
    };
  },
  computed: {
    isDebugMode() {
      return this.$store.state.debugMode;
    },
    imgCount: {
      get() { return this.numImg; },
      set(v) { this.$emit('update:numImg', v); },
    },
    annotationCount: {
      get() { return this.numAnnotations; },
      set(v) { this.$emit('update:numAnnotations', v); },
    },
  },
  methods: {
    navigateToValidator() {
      this.$router.push({ path: '/validator', query: { dataset_id: this.annotationSet.dataset_id, target: this.annotationSet.id } });
    },
  },
};
</script>

<style lang="scss" scoped>

.annset-li-container {
  overflow-y: auto;
  font-size: 0.875rem;
  flex: 1 1;
}

.annset-li {
  display: flex;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 4px;

  .annset-name-desc {
    flex: 1 1 auto;
  }

  .set-name {
    font-weight: 600;
  }

  .annset-desc {
    display: flex;
    align-items: center;
    @include themify() {
      color: themed('body-text-color-secondary');
    }

    span {
      margin-left: 2px;
      font-size: 0.9rem;
    }

    svg:not(:first-of-type) {
      margin-left: 15px;
    }
  }
}

.annset-li:hover {
  background-color: rgb(237, 232, 232);
}

.annset-li .copy-annset-icon,
.annset-li .delete-annset-icon,
.annset-li .edit-set-icon {
  visibility: hidden;
}
.annset-li:hover .copy-annset-icon,
.annset-li:hover .delete-annset-icon,
.annset-li:hover .edit-set-icon {
  z-index: 3;
  visibility: visible;
  @include themify() {
    color: themed('icon-color-inactive');
  }
}

.annset-li:hover .copy-annset-icon:hover,
.annset-li:hover .edit-set-icon:hover {
  @include themify() {
    color: themed('icon-color-primary');
  }
}

.annset-li:hover .delete-annset-icon:hover {
  @include themify() {
    color: themed('color-red');
  }
}

.annset-li + .annset-li {
  margin-top: 2px;
}

.icon-container {
  display: flex;
  align-items: center;
  margin-left: 12px;
  button + button {
    margin-left: 10px;
  }
  @include themify() {
    color: themed('icon-color-inactive');
  }
}

</style>
