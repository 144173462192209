<template>
  <Modal
    ref="addGroupsModal"
    :title="'Add Groups'"
    width="500px"
    @closed="resetData"
  >
    <!-- <template #modal-header /> -->
    <template #modal-main>
      <div class="settings container scrollbar">
        <div class="row mt-3">
          <div class="col settings__control-group icon-label">
            <label class="required">Groups</label>
            <IconButton
              class="add-group-icon"
              :icon="'add'"
              :width="18"
              :height="18"
              :title="`Add a Group`"
              :type="''"
              @click="add"
            />
          </div>
        </div>
        <div
          v-for="(group, i) in groups"
          :key="i"
          class="row mt-2"
        >
          <div class="col settings__control-group">
            <div class="group-row">
              <input
                v-model="group.name"
                type="text"
                class="group-input"
              >
              <div class="percent-input">
                <input
                  v-model="group.percent"
                  :min="0"
                  :max="100"
                  type="number"
                  class="group-input"
                  @change="changeSanitizer"
                  @input="inputSanitizer"
                >
                %
              </div>
              <IconButton
                class="remove-button"
                :icon="'close'"
                :width="20"
                :height="20"
                @click="remove(i)"
              />
            </div>
          </div>
        </div>
        <div class="row mt-1">
          <span class="note"><i>Total percentage must be less than or equal to 100%</i></span>
        </div>
        <div v-if="message" class="row mt-3">
          <div class="col-12 settings__control-group">
            <div class="result error">
              <span>{{ message }}</span>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template #modal-footer>
      <div class="footer">
        <button
          class="button modal-action-button"
          @click="createGroups"
        >
          Add Groups
        </button>
      </div>
    </template>
  </Modal>
</template>

<script>
import DatastoreConnect from '@/assets/js/DatastoreFunctions/datastore-interface';
import Modal from '@/components/Modal.vue';
import IconButton from '@/components/IconButton.vue';
import { inputSanitizer, changeSanitizer } from '@/assets/js/utils';

export default {
  name: 'CreateGroupsModal',
  components: {
    Modal,
    IconButton,
  },
  props: {
    dataset: {
      type: Object,
      default: null,
    },
  },
  emits: ['annotation-set-created', 'annotation-set-created-error', 'created-groups'],
  data() {
    return {
      message: '',
      totalPercentage: 0,
      groups: [
        {
          name: '',
          percent: 0,
        },
      ],
    };
  },
  created() {
    this.dataConnect = new DatastoreConnect(this.$store.state.enterpriseServerUrl);
  },
  methods: {
    inputSanitizer,
    changeSanitizer,
    showModal() {
      if (this.dataset && this.dataset.groups && this.dataset.groups.length > 0) {
        this.totalPercentage = 0;
        for (let i = 0; i < this.dataset.groups.length; i += 1) {
          this.totalPercentage += Math.round(Number((this.dataset.groups[i].count / this.dataset.numImages) * 100));
        }
      }
      this.$nextTick(() => {
        this.$refs.addGroupsModal.showModal();
      });
    },
    resetData() {
      this.message = "";
      this.totalPercentage = 0;
      this.groups = [
        {
          name: '',
          percent: 0,
        },
      ];
    },
    closeModal() {
      this.resetData();
      this.$refs.addGroupsModal.closeModal();
    },
    async createGroups() {
      this.message = "";
      if (this.groups.length === 0) {
        this.message = "Must have at least 1 group";
      } else {
        let totalPercent = this.totalPercentage;
        for (let i = 0; i < this.groups.length; i++) {
          if (this.groups[i].name === "") {
            this.message = "Group names must not be empty";
            break;
          }
          totalPercent += this.groups[i].percent;
          if (totalPercent > 100) {
            this.message = `Error: Total percentage can not exceed 100`;
            break;
          }
        }
      }
      if (this.message !== "") {
        return;
      }
      await this.dataConnect.createGroupsForDataset({
        dataset_id: this.dataset.id,
        group_names: this.groups.map((e) => e.name),
        group_splits: this.groups.map((e) => e.percent),
      })
        .then((data) => {
          this.$emit('created-groups');
          this.closeModal();
        }).catch((e) => {
          this.message = e;
        });
    },
    add() {
      this.groups.push(
        {
          name: '',
          percent: 0,
        },
      );
    },
    remove(index) {
      this.groups.splice(index, 1);
    },
  },
};

</script>

<style lang="scss" scoped>
.settings {
  padding: 0.75rem 3rem;
  min-width: 500px;
  overflow-y: auto;
}
.group-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 5px;
  border-radius: 4px;
  width: 100%;

  .percent-input {
    margin-left: auto;
    width: 120px;
    min-width: 120px;
  }

  .remove-button {
    margin-right: 10px;
    visibility: hidden;
  }

  &:hover {
    background-color: rgb(237, 232, 232);
  }

  &:hover .remove-button {
      z-index: 3;
    visibility: visible;
    @include themify() {
      color: themed('icon-color-inactive');
    }
  }

  &:hover .remove-button:hover {
      z-index: 3;
    visibility: visible;
    @include themify() {
      color: themed('icon-color-primary');
    }
  }

  .group-input {
    width: 70px;
    height: 30px;
    background: #ffffff;
    border-radius: 5px;
    font-size: 1rem;
    padding: 3px 10px;
    margin-left: 5px;
    margin-right: 5px;
    align-self: center;
    -webkit-appearance: auto;
    @include themify() {
      border: 1px solid themed('text-input-border-color');
    }
  }

  input:first-child[type="text"] {
    width: 200px;
  }
}

.icon-label {
  flex-direction: row;

  label {
    width: fit-content;
  }
}

.add-group-icon {
  z-index: 3;
  visibility: visible;
  margin-left: 12px;
  @include themify() {
    color: themed('icon-color-inactive');
  }
}

.add-group-icon:hover {
  @include themify() {
    color: themed('icon-color-primary');
  }
}

.result {
  display: flex;
  width: 100%;
  height: 100%;
  border-radius: 5px;
  font-size: 1rem;
  font-weight:600;
  padding: 10px;
  align-items: center;
  justify-content: center;

  span {
    width: 100%;
    line-break: anywhere;
  }
}

.error {
  @include themify() {
    background: rgba(182, 94, 94, 0.5) ;
    color: themed('color-error')
  }
}

.footer {
  display: flex;
  flex-direction: row;
  padding: 0.75rem 3rem;
  justify-content: flex-end;
}

.note {
  text-align: left;
  font-size: 0.8rem;
  font-weight: 500;
}

</style>
