<template>
  <!-- eslint-disable max-len -->
  <BaseCard
    :id="`dataset-${dataset.id}`"
    ref="card"
    :title="dataset.name"
    class="dataset-card"
  >
    <template #header>
      <BaseMenu
        v-if="permissions && permissions.project_write"
        :placement="'bottom-end'"
        @closed="show_menu = false"
      >
        <IconButton
          v-model="show_menu"
          class="header-icon"
          :icon="'more_vert'"
          :width="24"
          :height="24"
        />
        <template #menu="{closeMenu}">
          <ul class="dataset-menu">
            <li>
              <BaseMenuButton @click.stop="handleAutomatedTaskStart(), closeMenu()">
                <SVGIcon
                  :iconName="'play'"
                  :width="'22px'"
                  :height="'22px'"
                />
                <span>New Automated Task</span>
              </BaseMenuButton>
            </li>
          </ul>
        </template>
      </BaseMenu>
    </template>
    <template #body>
      <div class="dataset-card__body mt-2">
        <BaseTextExpander v-if="dataset.description" class="mb-2" :text="dataset.description" />
        <template v-if="dataset && dataset.tasks && dataset.tasks.length > 0">
          <ul class="automated-tasks scrollbar">
            <li
              v-for="(task, i) in dataset.tasks"
              :key="i"
              class="automated-tasks__list-item2"
            >
              <div class="header">
                <div class="left">
                  <SVGIcon
                    v-if="task.docker_task.type === 'auto-ann'"
                    :iconName="'detection2'"
                    class="task-type-icon"
                    :width="'20px'"
                    :height="'20px'"
                  />
                  <SVGIcon
                    v-else-if="task.docker_task.type === 'auto-seg'"
                    :iconName="'segments'"
                    class="task-type-icon"
                    :width="'20px'"
                    :height="'20px'"
                  />
                  <span>{{ getString(task.docker_task.type) }}</span>
                </div>
                <div class="center">
                  <span>{{ task.docker_task.name }}</span>
                </div>
                <div class="right timer">
                  <span>{{ formatTime(task.docker_task.date) }}</span>

                  <div
                    v-if="getStatus(task) === 'pending' || getStatus(task) === 'running'"
                    class="icon-container"
                  >
                    <div class="icon" title="Terminate Task">
                      <SVGIcon
                        v-if="permissions && permissions.project_write"
                        :iconName="'pause'"
                        :width="18"
                        :height="18"
                        @click="terminateTask(task)"
                      />
                    </div>
                  </div>
                  <div v-else class="icon-container">
                    <div class="icon" title="Delete Task">
                      <SVGIcon
                        v-if="permissions && permissions.project_write"
                        :iconName="'trash'"
                        :width="18"
                        :height="18"
                        @click.stop="deleteTask(task)"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="body">
                <div val-status class="status-col">
                  <span v-if="getStatus(task.docker_task) === 'complete'" class="status-tag _complete"><div class="status-icon">&#x2713;</div>Complete</span>
                  <span v-else-if="getStatus(task.docker_task) === 'pending'" class="status-tag _running"><div class="running-icon" />Initializing</span>
                  <span v-else-if="getStatus(task.docker_task) === 'running'" class="status-tag _running"><div class="running-icon" />Inference</span>
                  <span v-else-if="getStatus(task.docker_task) === 'terminated'" class="status-tag _warning"><div class="status-icon">&#x21;</div>Terminated</span>
                  <span v-else :title="getStatus(task.docker_task)" class="status-tag _error"><div class="status-icon">&#x2716;</div>Error</span>
                  <span v-if="getEndTime(task.docker_task)" class="duration">Duration: {{ getEndTime(task.docker_task) }} hr</span>
                </div>

                <hr class="vr">
                <div val-name class="name-col">
                  <div class="name-col__main">
                    <div class="item">
                      <label>Annotation Set</label>
                      <span>{{ getAnnotationSetNameFromId(task['parsed_data']['dest']) }}</span>
                    </div>
                    <div class="item">
                      <label>Experiment</label>
                      <span>{{ task['parsed_data']['trainer'] ? task['parsed_data']['trainer']['name'] : 'Unknown' }}</span>
                    </div>
                    <div class="item">
                      <label>Session</label>
                      <span>{{ task['parsed_data']['training_session'] ? task['parsed_data']['training_session']['name'] : 'Unknown' }}</span>
                    </div>
                    <div class="item model">
                      <label>Model</label>
                      <span>{{ task['parsed_data']['model_file'] ? task['parsed_data']['model_file'] : 'Unknown' }}</span>
                    </div>
                  </div>
                </div>
                <hr class="vr">
                <div val-epoch class="task-col">
                  <label>PROGRESS</label>
                  <span v-if="getTotal(task.docker_task) && getStatus(task.docker_task) !== 'error'">{{ `${Math.floor((getCount(task.docker_task) / getTotal(task.docker_task)) * 100).toFixed(1)} %` }}</span>
                  <span v-else>n/a</span>
                </div>
              </div>
            </li>
          </ul>
        </template>
        <div v-else class="automated-tasks-empty">
          <span>No Automated Tasks Created</span>
        </div>
      </div>
    </template>
  </BaseCard>
</template>
<script>
import BaseCard from '@/components/BaseCard.vue';
import IconButton from '@/components/IconButton.vue';
import SVGIcon from '@/components/SVGIcon.vue';
import BaseMenu from '@/components/BaseMenu.vue';
import BaseMenuButton from '@/components/BaseMenuButton.vue';
import BaseTextExpander from '@/components/BaseTextExpander.vue';
import DatastoreConnect from '@/assets/js/DatastoreFunctions/datastore-interface';

export default {
  name: "AutoAnnotationCardV2",
  components: {
    BaseCard,
    IconButton,
    SVGIcon,
    BaseMenu,
    BaseMenuButton,
    BaseTextExpander,
  },
  props: {
    dataset: {
      type: Object,
      default: () => {},
    },
    permissions: {
      type: Object,
      default: () => {},
    },
  },
  emits: ['create-automated-task', 'terminate-task', 'delete-task'],
  data() {
    return {
      dsConn: new DatastoreConnect(this.$store.state.enterpriseServerUrl),
      show_menu: false,
    };
  },
  methods: {
    handleAutomatedTaskStart() {
      this.$emit('create-automated-task');
    },
    getStatus(task) {
      if (task && task.status) {
        return task.status;
      }
      return null;
    },
    getMessage(task) {
      if (task && task.message) {
        return task.message;
      }
      return null;
    },
    getCount(task) {
      if (task && task.count) {
        return task.count;
      }
      return null;
    },
    getTotal(task) {
      if (task && task.total) {
        return task.total;
      }
      return null;
    },
    formatTime(str) {
      return `${new Date(str).toLocaleTimeString('default', {
        year: 'numeric', month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric', hour12: true,
      })}`;
    },
    getEndTime(docker_task) {
      if (docker_task.end_date !== "0001-01-01T00:00:00Z" && docker_task.date !== "0001-01-01T00:00:00Z") {
        return Number(Math.abs(new Date(docker_task.end_date) - new Date(docker_task.date)) / 3.6e6).toFixed(2);
      } else if (docker_task.end_date === "0001-01-01T00:00:00Z" && docker_task.date !== "0001-01-01T00:00:00Z") {
        return Number(Math.abs(Date.now() - new Date(docker_task.date)) / 3.6e6).toFixed(2);
      } else {
        return null;
      }
    },
    getAnnotationSetNameFromId(id) {
      if (id) {
        const set = this.dataset.annotation_sets.find((e) => e.id === id);
        if (set) {
          return set.name;
        } else {
          return 'Unknown';
        }
      } else {
        return 'Unknown';
      }
    },
    getStyle(type) {
      switch (type) {
      case 'auto-ann':
        return 'auto-ann';
      case 'auto-seg':
        return 'auto-seg';
      default:
        return '';
      }
    },
    getString(type) {
      switch (type) {
      case 'auto-ann':
        return 'Auto Annotation';
      case 'auto-seg':
        return 'Auto Segmentation';
      default:
        return '';
      }
    },
    terminateTask(task) {
      this.$emit('terminate-task', task);
    },
    deleteTask(task) {
      this.$emit('delete-task', task);
    },
  },
};
</script>

<style lang="scss" scoped>

.dataset-card {
  display: flex;
  height: 360px;
  flex-direction: column;
  position: relative;
  padding: 16px;
  text-align: left;
  border-radius: 8px;

  @include themify() {
    background: themed('card-color');
    color: themed('body-text-color');
  }

  &__header {
    width: 100%;
    display: flex;
  }

  &__titles {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    line-height: 1;
  }

  &__title {
    font-size: 1.25rem;
    font-weight: 700;
    @include themify() {
      color: themed('body-text-color');
    }
  }

  &__subtitle {
    font-size: 0.75rem;
    font-weight: 700;
    margin-top: 2px;
    @include themify() {
      color: themed('body-text-color-secondary');
    }
  }

  &__body {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 0px;
    flex: 1 1 auto;

    h3 {
      margin: 0;
      font-size: 1rem;
      @include themify() {
        color: themed('body-text-color-secondary');
      }
    }
  }

  &__body-row {
    display: flex;
    flex: 1 1 auto;
    margin-top: 5px;
    align-items: center;
  }

}

.dataset-menu {
  list-style-type: none;
  margin: 0;
  padding: 0;
  min-width: 100px;
}

.automated-tasks {
  display: flex;
  flex-direction: column;
  gap: 6px;
  list-style: none;
  padding: 2px 4px;
  margin: -2px -4px;
  overflow-y: auto;

  &__list-item2 {
    display: flex;
    flex-direction: column;
    @include themify() {
        background: themed('color-white-700');
      }
    border-radius: 4px;
    &:hover {
      box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.375), 0 0 1px 1px rgba(0, 0, 0, 0.125);
    }

    .header {
      padding: 8px 0 8px 8px;
      display: grid;
      grid-template-columns:0.9fr minmax(0px, 1.2fr) 0.9fr;
      gap: 15px;
      font-weight: 700;
      font-size: 0.85rem;
      @include themify() {
        color: themed('color-primary');
      }

      .left {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        .task-type-icon {
          margin: 0 8px 0 0;
          @include themify() {
            color: themed('color-primary');
          }
        }
      }

      .center {
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .right {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        overflow-x: hidden;
        overflow-y: hidden;
      }

      .icon-container {
        display: flex;
        align-items: center;
        margin-left: 10px;
          @include themify() {
            color: themed('body-text-color-secondary');
          }
          .icon + .icon {
            margin-left: 8px;
          }
        }

      .timer {
        margin-right: 8px;
        font-weight: 600;
        font-size: 0.695rem;
        white-space: nowrap;
        @include themify() {
          color: themed('body-text-color-secondary');
        }
      }
    }
    .body {
      display: flex;
      flex-direction: row;
      position: relative;
      padding: 0 8px 8px 8px;
      gap: 16px;
      align-items: center;

      > div {
        flex: 1 1 100%;
      }

      .task-col {
        display: flex;
        flex-direction: column;
        width: fit-content;

        label, span {
          text-align: center;
          overflow-wrap: anywhere;
          pointer-events: none;
        }
        label {
          font-weight: 600;
          font-size: 0.625rem;
          letter-spacing: 0.05em;
          white-space: nowrap;
          @include themify() {
            color: themed('body-text-color-secondary');
          }
        }
        span {
          font-weight: 700;
          font-size: 0.75rem;
          @include themify() {
            color: themed('color-primary');
          }
        }
      }

      .status-col {
        display: flex;
        flex-direction: column;
        align-items: center;

        .duration {
          font-weight: 600;
          font-size: 0.625rem;
          letter-spacing: 0.05em;
          white-space: nowrap;
          @include themify() {
            color: themed('body-text-color-secondary');
          }
        }
      }

      .name-col {
        display: flex;
        flex-direction: column;

        &__main {
          display: flex;
          flex-direction: row;

          .item {
            display: flex;
            flex-direction: column;
            max-width: calc(80% / 3);
            width: calc(80% / 3);
          }

          .model {
            max-width: 20%;
            width: 20%;
          }
        }

        label, span {
          text-align: center;
          overflow-wrap: anywhere;
          pointer-events: none;
        }
        label {
          font-weight: 600;
          font-size: 0.625rem;
          letter-spacing: 0.05em;
          white-space: nowrap;
          @include themify() {
            color: themed('body-text-color-secondary');
          }
        }
        span {
          font-weight: 700;
          font-size: 0.75rem;
          padding-left: 10px;
          padding-right: 10px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          @include themify() {
            color: themed('color-primary');
          }
        }
      }
    }
  }
}

.automated-tasks__list-item2 div[val-epoch] {
  max-width: 60px !important;
  min-width: 60px !important;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.automated-tasks__list-item2 div[val-status] {
  min-width: 120px !important;
  flex: 0 1 auto;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.automated-tasks__list-item2 div[val-name] {
  min-width: 180px !important;
  flex: 1 1 auto;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.automated-tasks-empty {
  display: flex;
  flex-direction: column;
  height: 100%;
  align-items: center;
  justify-content: center;
  font-size: 1.25rem;
  font-weight: bold;
  border: solid 1px black;
  border-radius: 8px;
  @include themify() {
    background: radial-gradient(circle, rgba($color-primary-300, 0.075) 50%, rgba(var(--color-primary-rgb), 0.125) 100%);
  }

  span {
    padding: 16px;
    text-align: center;
  }
}

.icon, .header-icon {
  cursor: pointer;
  @include themify() {
    color: themed('body-text-color-secondary');
  }
}

.icon:hover, .header-icon:hover {
  cursor: pointer;
  @include themify() {
    color: themed('color-primary');
  }
}

.status-tag {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  min-width: 100px;
  border-radius: 18px;
  padding: 4px 6px;
  line-height: 1;
  font-size: 0.625rem;
  font-weight: 700;
  white-space: nowrap;
  border: 2px solid;
  box-shadow: 0px 0px 1px rgb(0 0 0 / 25%), 0px 1px 4px rgb(0 0 0 / 25%);
  @include themify() {
    color: themed('color-black');
  }

  &._complete {
    @include themify() {
      color: themed('color-status-complete');
      background: themed('color-status-complete-background');
    }
  }
  &._running {
    @include themify() {
      color: themed('color-status-running');
      background: themed('color-status-running-background');
    }
  }
  &._error {
    @include themify() {
      color: themed('color-status-error');
      background: themed('color-status-error-background');
    }
  }

  &._warning {
    @include themify() {
      color: themed('color-status-warning');
      background: themed('color-status-warning-background');
    }
  }

  .running-icon {
    color: inherit;
    width: 4px;
    height: 4px;
    border: solid 4px;
    border-radius: 4px;
    margin-right: 4px;
  }

  .status-icon {
    color: inherit;
    margin-right: 4px;
  }
}

hr.vr {
  display: flex;
  width: 1px;
  height: 60%;
  margin: 0;
}

</style>
