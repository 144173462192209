import {
  ref, computed, watch, onUnmounted,
} from 'vue';
import { storeToRefs } from 'pinia';
import { usePCDStore } from '@/stores/usePCDStore.js';

export default function usePCD() {
  const {
    pointArray,
    pcdAnnotations,
    header,
    fields,
    scale,
    enablePCD,
  } = storeToRefs(usePCDStore());
  const {
    $reset,
  } = usePCDStore();

  onUnmounted(() => {
    $reset();
  });

  function parseFields(line) {
    const lineSplit = line.split(' ');
    if (lineSplit.length > 2) {
      lineSplit.shift();
      fields.value = lineSplit;
    }
  }

  function parsePoints(data) {
    const points = [];

    const labelIndex = fields.value.indexOf('label');

    data.forEach((line) => {
      const lineSplit = line.split(' ');
      if (lineSplit.length > 2) {
        const px = parseFloat(lineSplit[0]);
        if (Number.isNaN(px)) return;
        const py = parseFloat(lineSplit[1]);
        if (Number.isNaN(py)) return;
        const pz = parseFloat(lineSplit[2]);
        if (Number.isNaN(pz)) return;

        const label = parseInt(lineSplit[labelIndex]);

        points.push({
          raw: lineSplit,
          position: [px * scale.value, py * scale.value, pz * scale.value],
          label,
          selected: false,
        });
      }
    });
    return points;
  }

  function parsePCDFile(pcdFile) {
    const lines = pcdFile.split(/\r?\n/);

    header.value = lines.slice(0, 11);
    const data = lines.slice(11);

    header.value.forEach((line) => {
      if (line.startsWith('FIELDS')) {
        parseFields(line);
      }
    });

    const points = parsePoints(data);

    return points;
  }

  function createPCDFile() {
    const labelIndex = fields.value.indexOf('label');

    let newPCDFile = header.value.join('\n');
    newPCDFile += '\n';

    pointArray.value.forEach((point) => {
      const newPoint = point.raw;
      newPoint[0] = point.position[0] / scale.value;
      newPoint[1] = point.position[1] / scale.value;
      newPoint[2] = point.position[2] / scale.value;
      newPoint[labelIndex] = point.label;
      newPCDFile += `${newPoint.join(' ')}\n`;
    });

    return newPCDFile;
  }

  return {
    pointArray,
    pcdAnnotations,
    header,
    fields,
    scale,
    enablePCD,
    parsePCDFile,
    createPCDFile,
  };
}
