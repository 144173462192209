<template>
  <v-image
    ref="image"
    :config="configImg"
    @dragend="$emit('dragend')"
    @pointermove="$emit('pointermove', $event)"
    @pointerout="updateImagePointerLeave"
    @pointerclick="handlePointerClick"
  />
  <KonvaTransformer
    ref="transformer"
    :nodes="isSelected && node ? [node] : []"
    :rotateEnabled="true"
  />
  <v-line v-if="showPointerAxes" :config="configAxisX" />
  <v-line v-if="showPointerAxes" :config="configAxisY" />
</template>

<script>
import { roundNumber } from '@/assets/js/utils.js';
import Konva from 'konva';
import { useVideoPlayerStore } from '@/stores/useVideoPlayerStore.js';
import { storeToRefs } from 'pinia';
import KonvaTransformer from './KonvaTransformer.vue';

export default {
  name: 'KonvaVideo',
  components: {
    KonvaTransformer,
  },
  props: {
    imagePath: {
      type: String,
      default: '',
    },
    isSelected: {
      type: Boolean,
      default: false,
    },
    opacity: {
      type: Number,
      default: 1,
    },
    stageReady: {
      type: Boolean,
      default: false,
    },
    showPointerAxes: {
      type: Boolean,
      default: false,
    },
  },
  emits: [
    'new-coordinates', 'shape-selected', 'image-loaded', 'dragend', 'pointermove', 'pointerclick',
  ],
  setup() {
    const videoPlayerStore = useVideoPlayerStore();
    const { playing } = storeToRefs(videoPlayerStore);
    return {
      playing,
      loadVideo: videoPlayerStore.loadVideo,
    };
  },
  data() {
    return {
      node: null,
      width: 0,
      height: 0,
      video: null,
      animation: null,
      preview: null,
      videoReady: false,
      played: false,
      configAxisX: {
        stroke: 'red',
        strokeWidth: 1,
        points: [],
      },
      configAxisY: {
        stroke: 'red',
        strokeWidth: 1,
        points: [],
      },
    };
  },
  computed: {
    configImg() {
      return {
        type: 'map',
        fill: 'black',
        x: 0,
        y: 0,
        image: this.played ? this.video : this.preview,
        width: this.width,
        height: this.height,
        draggable: false,
        opacity: this.opacity,
      };
    },
    stageAndVideoReady() {
      return this.stageReady && this.videoReady;
    },
  },
  watch: {
    imagePath: {
      immediate: true,
      handler(path) {
        if (path) {
          this.loadImage(path);
        }
      },
    },
    stageAndVideoReady() {
      if (this.stageReady && !this.animation) {
        this.animation = new Konva.Animation(() => {
          // do nothing, animation just need to update the layer
        }, this.node.getLayer());
      }
      this.animation.start();
    },
    playing: {
      handler() {
        if (!this.played) {
          this.played = true;
        }
      },
    },
  },
  mounted() {
    this.node = this.$refs.image.getNode();
  },
  unmounted() {
    this.animation.stop();
  },
  methods: {
    loadImage(path) {
      // Load image path
      this.video = document.createElement('video');
      this.video.onerror = (e) => { console.log(e); };
      this.video.oncanplay = () => {
        this.videoReady = true;
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");
        canvas.width = this.video.videoWidth;
        canvas.height = this.video.videoHeight;
        ctx.drawImage(this.video, 0, 0);
        this.preview = canvas;
      };
      this.video.onloadedmetadata = () => {
        this.width = this.video.videoWidth;
        this.height = this.video.videoHeight;
        this.$emit('image-loaded', { width: this.width, height: this.height });
      };
      this.video.autoplay = true;
      this.video.muted = true;
      this.loadVideo(this.video, path);
    },
    handleImagePointerMove() {
      const pointerPos = this.node.getRelativePointerPosition();
      if (!pointerPos) {
        return;
      }
      if (pointerPos.x < 0 || pointerPos.x > this.width || pointerPos.y < 0 || pointerPos.y > this.height) {
        this.updateImagePointerLeave();
        return;
      }
      const ptrX = parseInt(pointerPos.x);
      const ptrY = parseInt(pointerPos.y);
      const calcX = ptrX / this.width;
      const calcY = Math.abs((ptrY - this.height) / this.height);

      this.configAxisX.points = [pointerPos.x, 0, pointerPos.x, this.height];
      this.configAxisY.points = [0, pointerPos.y, this.width, pointerPos.y];

      this.$emit('new-coordinates', {
        offsetX: roundNumber(calcX, 3),
        offsetY: roundNumber(calcY, 3),
      });
    },
    updateImagePointerLeave() {
      this.configAxisX.points = [];
      this.configAxisY.points = [];

      this.$emit('new-coordinates', {
        offsetX: null,
        offsetY: null,
      });
    },
    getImageNode() {
      return this.$refs.image.getNode();
    },
    handlePointerClick(event) {
      this.$emit('pointerclick', event);
      if (this.playing) {
        this.pause();
      } else {
        this.play();
      }
    },
    play() {
      this.video.play();
    },
    pause() {
      this.video.pause();
    },
  },
};
</script>

<style>

</style>
