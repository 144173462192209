<template>
  <div v-if="hasResults" class="main scrollbar">
    <div class="sessions">
      <div class="session-header">
        <div />
        <div v-if="getStatus(internalSession) !== 'error' && getStatus(internalSession) !== 'complete' && getStatus(internalSession) !== 'terminated'" class="icon-container">
          <IconButton
            class="icon"
            :icon="'stop'"
            :width="22"
            :height="22"
            :title="`Stop Session`"
            :type="''"
            @click.stop="openConfirmStopValidateSessionModal"
          />
        </div>
      </div>
      <div id="validate-sessions-container" class="validate-sessions">
        <div
          class="validate-sessions__list-item2"
        >
          <div val-status class="status-col">
            <span v-if="getStatus(internalSession) === 'complete'" class="status-tag _complete"><div class="status-icon">&#x2713;</div>Complete</span>
            <span v-else-if="getStatus(internalSession) === 'running' || getStatus(internalSession) === 'pending'" class="status-tag _running"><div class="running-icon" />Init. Validator</span>
            <span v-else-if="getStatus(internalSession) === 'evaluating'" class="status-tag _running"><div class="running-icon" />Evaluating</span>
            <span v-else-if="getStatus(internalSession) === 'concluding'" class="status-tag _running"><div class="running-icon" />Concluding</span>
            <span v-else-if="getStatus(internalSession) === 'terminated'" class="status-tag _warning"><div class="status-icon">&#x21;</div>Terminated</span>
            <span v-else :title="getStatus(internalSession)" class="status-tag _error"><div class="status-icon">&#x2716;</div>Error</span>
            <span v-if="internalSession?.docker_task" class="date">{{ formatTime(internalSession?.docker_task.date) }}</span>
          </div>

          <hr class="vr">
          <div val-name class="name-col">
            <div class="name-col__main">
              <div class="item">
                <label>GROUND TRUTH</label>
                <span>{{ getName(internalSession?.gt_annotation_set_id) }}</span>
              </div>
              <div class="item">
                <label>TARGET</label>
                <span>{{ getName(internalSession?.target_annotation_set_id) }}</span>
              </div>
            </div>
          </div>
          <hr class="vr">
          <div val-epoch class="session-col">
            <label>IMAGE</label>
            <span v-if="getTotal(internalSession) && getStatus(internalSession) !== 'error'">{{ getCount(internalSession) }} / {{ getTotal(internalSession) }}</span>
            <span v-else>n/a</span>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="type === 'detection'"
      class="stats-container"
    >
      <div class="flex-col">
        <div class="col-40">
          <ComparisonTableStatic
            :validation="internalSession"
          />
        </div>
        <div class="col-60">
          <ClassHistogramData
            :validation="internalSession"
          />
        </div>
      </div>
      <br>
      <div class="flex-col">
        <div class="col-40">
          <PrecisionRecallChart
            :validation="internalSession"
          />
        </div>
        <div class="col-60">
          <ConfusionMatrixCardV2
            :matrix="internalSession?.results"
          />
        </div>
      </div>
    </div>
    <div
      v-else-if="type === 'segmentation'"
      class="stats-container"
    >
      <div class="flex-col">
        <div class="col-50">
          <ComparisonTableStaticSegmentation
            :validation="internalSession"
          />
        </div>
      </div>
    </div>
  </div>
  <div v-else class="empty-state">
    <InlineLoader
      :width="'60px'"
      :height="'60px'"
      :border="'12px'"
    />
  </div>
  <ConfirmModal
    ref="confirmModal"
    :messageHeader="confirmMessageHeader"
    :message="confirmMessage"
    :buttonClass="'button-delete'"
    :buttonText="buttonText"
    @confirmed="confirmFunction"
  />
</template>

<script>
import DatastoreConnect from '@/assets/js/DatastoreFunctions/datastore-interface';
import IconButton from "@/components/IconButton.vue";
import ComparisonTableStatic from "@/components/ValidationPage/ComparisonTableStatic.vue";
import ClassHistogramData from "@/components/ValidationPage/ClassHistogramData.vue";
import PrecisionRecallChart from '@/components/ValidationPage/PrecisionRecallChart.vue';
import ConfusionMatrixCardV2 from '@/components/ValidationPage/ConfusionMatrixCardV2.vue';
import InlineLoader from '@/components/InlineLoader.vue';
import ComparisonTableStaticSegmentation from "@/components/ValidationPage/ComparisonTableStaticSegmentation.vue";
import ConfirmModal from '@/components/ConfirmModal.vue';

export default {
  name: 'ValidateSessionDetails',
  components: {
    IconButton,
    ComparisonTableStatic,
    ClassHistogramData,
    PrecisionRecallChart,
    InlineLoader,
    ConfusionMatrixCardV2,
    ComparisonTableStaticSegmentation,
    ConfirmModal,
  },
  emits: ['stop-validate', 'refresh'],
  data() {
    return {
      tab: 'params',
      dataset: null,
      internalSession: null,
      logs: null,
      converterOptionsMP: null,
      converterOptionsYL: null,
      selectedConvertType: null,
      startingConvert: false,
      selectedSessionAvailableFiles: [],
      modelExtensionMap: {
        'yolov8': '.pt',
        'modelpack': '.h5',
      },
      convertorDockerTasks: [],
      interval: null,
      ec2Interval: null,
      show_menu: false,
      refreshTimeout: false,
      confirmMessage: null,
      confirmMessageHeader: null,
      toDelete: null,
      confirmFunction: null,
      buttonText: 'Delete',
    };
  },
  computed: {
    hasResults() {
      return this.internalSession && this.internalSession.results && Object.keys(this.internalSession.results).length > 0;
    },
    type() {
      return this.internalSession && this.internalSession.params ? this.internalSession.params.type : null;
    },
  },
  beforeUnmount() {
    this.clearData();
    this.stopInterval();
    this.stopEC2StatusInterval();
  },
  async created() {
    this.dataConnect = new DatastoreConnect(this.$store.state.enterpriseServerUrl);
    if (this.$route.query.dataset_id && this.$route.query.session_id) {
      this.dataset = await this.getDataset(parseInt(this.$route.query.dataset_id));
      this.internalSession = await this.getValidateSession(parseInt(this.$route.query.session_id));
      this.startInterval(this.$route.query.session_id);
      this.startEC2StatusInterval();
    } else {
      this.$router.push({ path: '/validator' });
    }
  },
  methods: {

    async getDataset(id) {
      const params = {
        dataset_id: id,
        get_annotation_sets: true,
      };
      const resp = await this.dataConnect.getDatasetById(params)
        .catch((error) => {
          // TODO: handle error
          console.log(error);
        });
      if (resp.error) {
        return null;
      } else {
        return resp.result;
      }
    },
    clearData() {
      this.selectedSessionAvailableFiles = [];
      this.dataset = null;
      clearInterval(this.interval);
      this.interval = null;
      clearInterval(this.logsInterval);
      this.logsInterval = null;
      this.refreshTimeout = false;
    },
    getName(id) {
      const result = this.dataset?.annotation_sets.find((e) => e.id === id);
      return result?.name || 'Unknown';
    },
    getEpoch(session) {
      if (session.docker_task) {
        return session.docker_task.count;
      }
      return 0;
    },
    getTotalEpochs(session) {
      let params = {};
      if (session && session.params) {
        params = session.params;
      }
      if (params.epochs) {
        return params.epochs;
      }
      return null;
    },
    getStatus(session) {
      if (session?.docker_task) {
        return session.docker_task.status;
      }
      return "Failed to start docker task";
    },

    getCount(session) {
      if (session?.docker_task) {
        return session.docker_task.count;
      }
      return "Failed to get docker task";
    },
    getTotal(session) {
      if (session?.docker_task) {
        return session.docker_task.total;
      }
      return null;
    },
    formatTime(str) {
      return `${new Date(str).toLocaleTimeString('default', {
        year: 'numeric', month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric', hour12: true,
      })}`;
    },
    openConfirmStopValidateSessionModal() {
      this.confirmMessage = `Are you sure you want to stop this validate session? You will lose all current progress.`;
      this.confirmMessageHeader = 'Stop Validate Session';
      this.toDelete = this.internalSession;
      this.buttonText = 'Stop';
      this.$refs.confirmModal.showModal();
      this.confirmFunction = () => {
        this.handleStopValidateSessions([this.internalSession.id]);
      };
    },
    async handleStopValidateSessions(ids) {
      await this.dataConnect.terminateValidateSessions({
        session_ids: ids,
      })
        .then(() => {
          this.updateValidatorEC2InstanceStatus();
        })
        .catch((error) => {
          console.log(error);
        });

      this.toDelete = null;
    },
    async updateValidatorEC2InstanceStatus() {
      await this.dataConnect.validateUpdateEC2({
        dataset_ids: [this.internalSession.dataset_id],
      })
        .catch((e) => console.log(e));
    },
    stopInterval() {
      clearInterval(this.interval);
      this.interval = null;
    },
    startInterval(session_id) {
      this.interval = setInterval(async () => {
        this.internalSession = await this.getValidateSession(parseInt(session_id));
      }, 30000);
    },
    startEC2StatusInterval() {
      this.ec2Interval = setInterval(this.updateValidatorEC2InstanceStatus, 60000);
    },
    stopEC2StatusInterval() {
      clearInterval(this.ec2Interval);
      this.ec2Interval = null;
    },
    async getValidateSession(id) {
      const resp = await this.dataConnect.getValidateSession({
        validate_session_id: id,
      });
      if (resp.error) {
        return null;
      } else {
        return resp.result;
      }
    },
    handleRefreshClicked() {
      this.refreshTimeout = true;
      setTimeout(() => {
        this.refreshTimeout = false;
      }, 5000);
      this.internalSession = this.getValidateSession(this.internalSession.id);
    },

  },
};

</script>

<style lang="scss" scoped>

.main {
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
}

.sessions {
  display: flex;
  flex-direction: column;
  width: 50%;
  padding: 16px;
  @media (max-width: $breakpoint-sm) {
    width: 100%;
  }
  @media (max-width: $breakpoint-md) {
    width: 100%;
  }

  ul {
    list-style-type: none;
  }
  .session-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

   .icon-container {
    display: flex;
    align-items: center;
      @include themify() {
        color: themed('body-text-color-secondary');
      }
      .icon + .icon {
        margin-left: 10px;
      }
      .icon:hover {
        @include themify() {
          color: themed('icon-color-primary');
        }
      }
    }
  }

  h3 {
    margin: 0;
    margin-bottom: 10px;
    font-size: 1rem;
    text-align: left;
    @include themify() {
      color: themed('body-text-color-secondary');
    }
  }

  &__body-row {
    display: flex;
    flex: 1 1 auto;
    margin-top: 5px;
    align-items: center;
  }
}

._disabled {
  @include themify() {
    background: themed('color-disabled-200') !important;
    color: white;
    cursor: not-allowed !important;
    pointer-events: none;
    border: 1px solid transparent !important;
  }
}

.validate-sessions {
  display: flex;
  flex-direction: column;
  gap: 6px;
  list-style: none;
  padding: 2px 4px;
  margin: 2px -4px;

  &__list-item2 {
    display: flex;
    flex-direction: row;
    position: relative;
    background: rgb(231, 224, 224);
    padding: 8px;
    border-radius: 4px;
    gap: 16px;
    align-items: center;
    @include themify() {
      background: themed('color-white-700');
    }
    height: 100%;
    border: 1px solid grey;

    .session-col {
      display: flex;
      flex-direction: column;
      width: fit-content;

      label, span {
        text-align: center;
        overflow-wrap: anywhere;
        pointer-events: none;
      }
      label {
        font-weight: 600;
        font-size: 0.625rem;
        letter-spacing: 0.05em;
        white-space: nowrap;
        @include themify() {
          color: themed('body-text-color-secondary');
        }
      }
      span {
        font-weight: 700;
        font-size: 0.75rem;
        @include themify() {
          color: themed('color-primary');
        }
      }
    }

    .status-col {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;

      .date {
        margin-top: 5px;
        font-weight: 600;
        font-size: 0.625rem;
        letter-spacing: 0.05em;
        white-space: nowrap;
        @include themify() {
          color: themed('body-text-color-secondary');
        }
      }
    }

    .name-col {
      display: flex;
      flex-direction: column;

      &__main {
        display: flex;
        flex-direction: row;
          justify-content: space-around;
        .item {
          display: flex;
          flex-direction: column;
          width: 50%;
        }

      }

      label, span {
        text-align: center;
        overflow-wrap: anywhere;
        pointer-events: none;
      }
      label {
        font-weight: 600;
        font-size: 0.625rem;
        letter-spacing: 0.05em;
        white-space: nowrap;
        @include themify() {
          color: themed('body-text-color-secondary');
        }
      }
      span {
        font-weight: 700;
        font-size: 0.75rem;
        padding-left: 10px;
        padding-right: 10px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        @include themify() {
          color: themed('color-primary');
        }
      }
    }
  }
}
._selected {
  @include themify() {
    border: solid 1px themed('color-primary');
    background: rgba(themed('color-primary'), 0.2);
  }
}

.validate-sessions__list-item2 div[val-epoch] {
  flex: 0 1 100px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.validate-sessions__list-item2 div[val-status] {
  min-width: 130px !important;
  flex: 0 1 auto;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.validate-sessions__list-item2 div[val-name] {
  flex: 1 1 120px;
  width: 120px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.status-tag {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  min-width: 100px;
  border-radius: 18px;
  padding: 4px 6px;
  line-height: 1;
  font-size: 0.625rem;
  font-weight: 700;
  white-space: nowrap;
  border: 2px solid;
  box-shadow: 0px 0px 1px rgb(0 0 0 / 25%), 0px 1px 4px rgb(0 0 0 / 25%);
  @include themify() {
    color: themed('color-black');
  }

  &._complete {
    @include themify() {
      color: themed('color-status-complete');
      background: themed('color-status-complete-background');
    }
  }
  &._running {
    @include themify() {
      color: themed('color-status-running');
      background: themed('color-status-running-background');
    }
  }
  &._error {
    @include themify() {
      color: themed('color-status-error');
      background: themed('color-status-error-background');
    }
  }
  &._warning {
    @include themify() {
      color: themed('color-status-warning');
      background: themed('color-status-warning-background');
    }
  }

  .running-icon {
    color: inherit;
    width: 4px;
    height: 4px;
    border: solid 4px;
    border-radius: 4px;
    margin-right: 4px;
  }

  .status-icon {
    color: inherit;
    margin-right: 4px;
  }
}

hr.vr {
  display: flex;
  width: 1px;
  height: 24px;
  margin: 0;
}

a {
  text-decoration:none;
}
a + button {
  margin-left: 10px;
}

.stats-container {
  margin: 10px;
  width: 100%;
}

.flex-col {
  display: flex;
  flex-direction: row;
  padding: 0 20px 0 10px;

  div + div {
    margin-left: 20px;
  }

  @media (max-width: $breakpoint-sm) {
    flex-direction: column;
    div + div {
      margin-left: 0;
      margin-top: 20px;
    }
  }
  @media (max-width: $breakpoint-md) {
    flex-direction: column;
    div + div {
      margin-left: 0;
      margin-top: 20px;
    }
  }
}

.col-40 {
  width: 40%;
}

.col-50 {
  width: 50%;
}

.col-60 {
  width: 60%;
}

@media (max-width: $breakpoint-sm) {
  .col-40 {
  width: 100%;
  }
  .col-50 {
  width: 100%;
  }
  .col-60 {
  width: 100%;
  }
}

@media (max-width: $breakpoint-md) {
  .col-40 {
  width: 100%;
  }
  .col-50 {
  width: 100%;
  }
  .col-60 {
  width: 100%;
  }
}
.empty-state {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  height: 100%;
}
</style>
