<template>
  <button class="button filter-button" :class="{'_active': active, '_menuOpen': menuOpen, '_error': error}" @keyup.esc="handleCloseFilter">
    <slot name="button-start" />
    <span>{{ name }}</span>
    <div v-if="active" class="filter-tag"><span>{{ tagValue }}</span></div>
    <SVGIcon
      v-else
      :iconName="'expand_more'"
      :width="'20px'"
      :height="'20px'"
    />
  </button>
</template>

<script>
import SVGIcon from '@/components/SVGIcon.vue';

export default {
  name: "BaseFilterButton",
  components: {
    SVGIcon,
  },
  props: {
    name: {
      type: String,
      default: '',
    },
    active: {
      type: Boolean,
      default: false,
    },
    tagValue: {
      type: String,
      default: '',
    },
    menuOpen: {
      type: Boolean,
      default: false,
    },
    error: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.filter-button {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 30px;
  gap: 4px;
  border-radius: 6px;
  padding: 2px 10px;
  box-shadow: 0 1px 3px 0 rgb(0 0 0 / 20%);
  line-height: 20px;
  width: fit-content;
  @include themify() {
    border: 1px solid themed('filter-button-border');
    background: themed('filter-button-background');
    color: themed('button-secondary-text');
  }

  &:hover {
    @include themify() {
      background: themed('filter-button-background-hover');
    }
  }

  > span {
    white-space: nowrap;
  }

  .filter-tag {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 18px;
    padding: 0 6px;
    height: 18px;
    max-width: 100px;
    min-width: 0;
    white-space: nowrap;
    @include themify() {
      background: themed('color-accent');
      color: themed('accent-text-color');
    }

    > span {
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }

  &._menuOpen {
    @include themify() {
      background: themed('filter-button-menu-open-background');
      color: themed('filter-button-menu-open-text');
      border: 1px solid themed('filter-button-menu-open-border');
    }
  }

  &._active {
    @include themify() {
      background: themed('button-active');
      color: themed('primary-text-color');
    }
  }

  &._error {
    @include themify() {
      background: themed('filter-button-background');
      border: 2px solid themed('color-red-400');
    }
  }
}
</style>
