<template>
  <Modal
    ref="createTrainerModal"
    :title="'Training Sessions'"
    width="650px"
    @closed="resetData"
  >
    <!-- <template #modal-header /> -->
    <template #modal-main>
      <div class="settings container scrollbar">
        <v-expansion-panels
          v-model="panels"
          multiple
          class="mt-2"
        >
          <v-expansion-panel
            value="description"
          >
            <v-expansion-panel-title>
              <h3>Description</h3>
            </v-expansion-panel-title>
            <v-expansion-panel-text>
              <div class="row mt-3">
                <div class="col-12 settings__control-group">
                  <label class="required">Name</label>
                  <input
                    v-model="name"
                    class="select"
                    type="text"
                  >
                </div>
              </div>
              <div class="row mt-3">
                <div class="col-12 settings__control-group">
                  <label>Description (Optional)</label>
                  <textarea
                    v-model="description"
                    rows="2"
                  />
                </div>
              </div>
            </v-expansion-panel-text>
          </v-expansion-panel>
          <v-expansion-panel
            value="data"
          >
            <v-expansion-panel-title>
              <h3>Training Data</h3>
            </v-expansion-panel-title>
            <v-expansion-panel-text>
              <div class="row mt-3">
                <div class="col-md-auto">
                  <input
                    id="percent-split-radio"
                    v-model="groupSplit"
                    type="radio"
                    name="percent_group_split"
                    value="percent"
                    class="split-radio"
                  >
                </div>
                <div class="col settings__control-group">
                  <label class="clickable" @click="() => groupSplit='percent'">New Training Group Splits</label>
                  <template v-if="groupSplit ==='percent'">
                    <div class="row mt-3 full-width">
                      <div class="settings__control-group dataset-selector">
                        <label class="required">Dataset</label>
                        <select v-model="dataset" class="select" required>
                          <option disabled :value="''">Select a Dataset</option>
                          <option v-for="(d, i) in datasetList" :key="i" :value="d">
                            {{ d.name }}
                          </option>
                        </select>
                      </div>
                    </div>
                    <div v-if="dataset" class="row mt-3 full-width">
                      <div class="settings__control-group dataset-selector">
                        <label class="required">Annotation Set</label>
                        <select v-model="annset" class="select" required>
                          <option disabled :value="''">Select Annotation Set</option>
                          <option v-for="(a, i) in dataset.annotation_sets" :key="i" :value="a">
                            {{ a.name }}
                          </option>
                        </select>
                      </div>
                    </div>

                    <div v-if="dataset" class="row mt-3 full-width">
                      <div class="col settings__control-group custom-split-card _train">
                        <label class="font-weight-bold">Train</label>
                        <p>{{ custom_split_params.train.count ? `${Number(custom_split_params.train.count).toFixed()} imgs` : '0 img' }}</p>
                        <p>{{ `${Number(custom_split_params.train.percent).toFixed()} %` }}</p>
                      </div>
                      <div class="col settings__control-group custom-split-card _validation">
                        <label class="font-weight-bold">Validation</label>
                        <p>{{ custom_split_params.val.count ? `${Number(custom_split_params.val.count).toFixed()} imgs` : '0 img' }}</p>
                        <p>{{ `${Number(custom_split_params.val.percent).toFixed()} %` }}</p>
                      </div>

                      <div class="col settings__control-group custom-split-card _test">
                        <label class="font-weight-bold">Test</label>
                        <p>{{ custom_split_params.test.count ? `${Number(custom_split_params.test.count).toFixed()} imgs` : '0 img' }}</p>
                        <p>{{ `${Number(custom_split_params.test.percent).toFixed()} %` }}</p>
                      </div>
                    </div>
                    <div v-if="dataset" class="row">
                      <div class="col slider-settings">
                        <TrainValTestSplitSlider v-model="custom_split_slider_values" />
                      </div>
                    </div>
                  </template>
                </div>
              </div>
              <div class="row mt-3">
                <div class="col-md-auto">
                  <input
                    id="from_dataset-split-radio"
                    v-model="groupSplit"
                    type="radio"
                    name="from_dataset_group_split"
                    value="group"
                    class="split-radio"
                  >
                </div>
                <div class="col settings__control-group">
                  <label class="clickable" @click="() => groupSplit='group'">Use Groups from Dataset</label>
                  <template v-if="groupSplit==='group'">
                    <div class="row mt-3 full-width">
                      <div class="settings__control-group dataset-selector">
                        <label class="required">Train Dataset</label>
                        <select v-model="dataset_split_params.train.d" class="select" required>
                          <option :value="''">Select Train Dataset</option>
                          <option v-for="(d, i) in datasetList" :key="i" :value="d">
                            {{ d.name }}
                          </option>
                        </select>
                      </div>
                    </div>
                    <div v-if="dataset_split_params.train.d" class="row mt-3 full-width">
                      <div class="settings__control-group dataset-selector">
                        <label class="required">Train Annotation Set</label>
                        <select v-model="dataset_split_params.train.set" class="select" required>
                          <option disabled :value="''">Select Train Annotation Set</option>
                          <option v-for="(a, i) in dataset_split_params.train.d.annotation_sets" :key="i" :value="a">
                            {{ a.name }}
                          </option>
                        </select>
                      </div>
                    </div>
                    <div v-if="dataset_split_params.train.d" class="row mt-3 full-width">
                      <div class="settings__control-group dataset-selector">
                        <label class="required">Train Group</label>
                        <select v-model="dataset_split_params.train.group" class="select" required>
                          <option disabled :value="''">Select Train Group</option>
                          <option v-for="(a, i) in dataset_split_params.train.d.groups" :key="i" :value="a.id">
                            {{ a.name }}
                          </option>
                        </select>
                      </div>
                    </div>
                    <div class="row mt-3 full-width">
                      <div class="settings__control-group dataset-selector">
                        <label class="required">Val Dataset</label>
                        <select v-model="dataset_split_params.val.d" class="select" required>
                          <option :value="''">Select Val Dataset</option>
                          <option v-for="(d, i) in datasetList" :key="i" :value="d">
                            {{ d.name }}
                          </option>
                        </select>
                      </div>
                    </div>
                    <div v-if="dataset_split_params.val.d" class="row mt-3 full-width">
                      <div class="settings__control-group dataset-selector">
                        <label class="required">Val Annotation Set</label>
                        <select v-model="dataset_split_params.val.set" class="select" required>
                          <option disabled :value="''">Select Val Annotation Set</option>
                          <option v-for="(a, i) in dataset_split_params.val.d.annotation_sets" :key="i" :value="a">
                            {{ a.name }}
                          </option>
                        </select>
                      </div>
                    </div>
                    <div v-if="dataset_split_params.val.d" class="row mt-3 full-width">
                      <div class="settings__control-group dataset-selector">
                        <label class="required">Val Group</label>
                        <select v-model="dataset_split_params.val.group" class="select" required>
                          <option disabled :value="''">Select Val Group</option>
                          <option v-for="(a, i) in dataset_split_params.val.d.groups" :key="i" :value="a.id">
                            {{ a.name }}
                          </option>
                        </select>
                      </div>
                    </div>
                    <div class="row mt-3 full-width">
                      <div class="settings__control-group dataset-selector">
                        <label>Test Dataset (Optional)</label>
                        <select v-model="dataset_split_params.test.d" class="select" required>
                          <option :value="''">Select Test Dataset</option>
                          <option v-for="(d, i) in datasetList" :key="i" :value="d">
                            {{ d.name }}
                          </option>
                        </select>
                      </div>
                    </div>
                    <div v-if="dataset_split_params.test.d" class="row mt-3 full-width">
                      <div class="settings__control-group dataset-selector">
                        <label>Test Ann Set (Optional)</label>
                        <select v-model="dataset_split_params.test.set" class="select" required>
                          <option disabled :value="''">Select Test Annotation Set</option>
                          <option v-for="(a, i) in dataset_split_params.test.d.annotation_sets" :key="i" :value="a">
                            {{ a.name }}
                          </option>
                        </select>
                      </div>
                    </div>
                    <div v-if="dataset_split_params.test.d" class="row mt-3 full-width">
                      <div class="settings__control-group dataset-selector">
                        <label>Test Group (Optional)</label>
                        <select v-model="dataset_split_params.test.group" class="select" required>
                          <option disabled :value="''">Select Test Group</option>
                          <option v-for="(a, i) in dataset_split_params.test.d.groups" :key="i" :value="a.id">
                            {{ a.name }}
                          </option>
                        </select>
                      </div>
                    </div>
                  </template>
                </div>
              </div>
            </v-expansion-panel-text>
          </v-expansion-panel>

          <v-expansion-panel
            value="model"
          >
            <v-expansion-panel-title>
              <h3>Model Settings</h3>
            </v-expansion-panel-title>
            <v-expansion-panel-text>
              <div class="row mt-3">
                <div class="col-6 settings__control-group">
                  <label class="required">Model</label>
                  <select v-model="selectedModel" class="select">
                    <option v-for="(model, i) in modelList" :key="i" :value="model">
                      {{ model.name }}
                    </option>
                  </select>
                </div>
                <div class="col-6 settings__control-group">
                  <label class="required">Task</label>
                  <select v-model="trainMode" class="select">
                    <option value="detect">Detect</option>
                    <option value="segment">Segmentation</option>
                  </select>
                </div>
              </div>
              <div class="row mt-3">
                <div class="col-sm settings__control-group">
                  <label>Initial Weights</label>
                  <select v-model="selectedTrainingSessionWeights" class="select">
                    <option value="default">Use Default Weights</option>
                    <template v-if="trainer">
                      <option
                        v-for="(session, i) in trainer.training_sessions"
                        :key="i"
                        :value="session.id"
                      >
                        {{ getSessionName(session) }}
                      </option>
                    </template>
                  </select>
                </div>
              </div>
              <template v-if="selectedModel.value === 'yolov8'">
                <div class="row mt-3">
                  <div class="col-4 settings__control-group">
                    <label class="required">Model Size</label>
                    <template v-if="trainMode === 'detect'">
                      <select v-model="yoloModel" class="select">
                        <option v-for="m, i in yoloModelList.detection" :key="i" :value="m">{{ m.name }}</option>
                      </select>
                    </template>
                    <template v-else-if="trainMode === 'segment'">
                      <select v-model="yoloModel" class="select">
                        <option v-for="m, i in yoloModelList.segmentation" :key="i" :value="m">{{ m.name }}</option>
                      </select>
                    </template>
                  </div>
                  <div class="col-4 settings__control-group">
                    <label class="required">Epochs</label>
                    <input
                      v-model="epochs"
                      type="number"
                      min="0"
                    >
                  </div>
                  <div class="col-4 settings__control-group">
                    <label>Batch Size</label>
                    <input
                      v-model="batch_size"
                      type="number"
                      min="0"
                      :max="batch_max"
                      @change="changeSanitizer"
                      @input="inputSanitizer"
                    >
                  </div>
                </div>
              </template>
              <template v-if="selectedModel.value === 'modelpack'">
                <div class="row mt-3">
                  <div class="settings__control-group trainer-session-row-switch">
                    <label>Use Default Learning Rates</label>
                    <BaseSwitch v-model:active="mpk_params.default_lr" />
                  </div>
                </div>
                <template v-if="mpk_params.default_lr">
                  <div class="row mt-3">
                    <div class="col-4 settings__control-group">
                      <label class="required">Epochs</label>
                      <input
                        v-model="epochs"
                        type="number"
                        min="0"
                      >
                    </div>
                    <div class="col-4 settings__control-group">
                      <label>Batch Size</label>
                      <input
                        v-model="batch_size"
                        type="number"
                        min="0"
                        :max="batch_max"
                        @change="changeSanitizer"
                        @input="inputSanitizer"
                      >
                    </div>
                  </div>
                </template>
                <template v-else>
                  <div class="row mt-3">
                    <div class="col-8 settings__control-group">
                      <label class="required">Learning Rate Scheduler</label>
                      <select v-model="mpk_params.lr_scheduler" class="select">
                        <option value="WLS">Warmup Learning Scheduler</option>
                        <option value="ELS">Exponential Learning Scheduler</option>
                      </select>
                    </div>
                  </div>
                  <template v-if="mpk_params.lr_scheduler === 'WLS'">
                    <div class="row mt-3">
                      <div class="col-4 settings__control-group">
                        <label class="required">Warmup Epochs</label>
                        <input
                          v-model="mpk_params.wls_params['warmup-epochs']"
                          type="number"
                          min="0"
                        >
                      </div>
                      <div class="col-4 settings__control-group">
                        <label class="required">Warmup LR</label>
                        <input
                          v-model="mpk_params.wls_params.warmup_lr"
                          type="number"
                          min="0"
                        >
                      </div>
                      <div class="col-4 settings__control-group">
                        <label class="required">Initial LR</label>
                        <input
                          v-model="mpk_params.wls_params.initial_lr"
                          type="number"
                          min="0"
                        >
                      </div>
                    </div>
                  </template>
                  <template v-else-if="mpk_params.lr_scheduler === 'ELS'">
                    <div class="row mt-3">
                      <div class="col-4 settings__control-group">
                        <label class="required">Decay</label>
                        <input
                          v-model="mpk_params.els_params.decay"
                          type="number"
                          min="0"
                        >
                      </div>
                      <div class="col-4 settings__control-group">
                        <label class="required">Initial LR</label>
                        <input
                          v-model="mpk_params.els_params.initial_lr"
                          type="number"
                          min="0"
                        >
                      </div>
                    </div>
                  </template>
                  <div class="row mt-3">
                    <div class="col-4 settings__control-group">
                      <label class="required">Epochs</label>
                      <input
                        v-model="epochs"
                        type="number"
                        min="0"
                      >
                    </div>
                    <div class="col-4 settings__control-group">
                      <label>Batch Size</label>
                      <input
                        v-model="batch_size"
                        type="number"
                        min="0"
                        :max="batch_max"
                        @change="changeSanitizer"
                        @input="inputSanitizer"
                      >
                    </div>
                  </div>
                </template>
              </template>
              <div class="row mt-3">
                <div class="col-3 settings__control-group">
                  <label class="required">Image Width</label>
                  <input
                    v-model="image_width"
                    type="number"
                    min="0"
                  >
                </div>
                <div class="col-3 settings__control-group">
                  <label class="required">Image Height</label>
                  <input
                    v-model="image_height"
                    type="number"
                    min="0"
                  >
                </div>
              </div>
              <div v-if="hasAdvancedModelSettings" class="row mt-3">
                <div class="col-12">
                  <TrainerSessionAdvancedSettings v-model="advancedSettings" />
                </div>
              </div>
            </v-expansion-panel-text>
          </v-expansion-panel>
        </v-expansion-panels>
        <div v-if="message" class="row mt-3">
          <div class="col-12 settings__control-group">
            <div class="result error">
              <span>{{ message }}</span>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template #modal-footer>
      <div class="footer">
        <button
          class="button modal-action-button"
          :class="{'button-spinner': startingTrainer}"
          :disabled="startingTrainer"
          @click="startSession"
        >
          Start Session
        </button>
      </div>
    </template>
  </Modal>
</template>

<script>
import DatastoreConnect from '@/assets/js/DatastoreFunctions/datastore-interface';
import Modal from '@/components/Modal.vue';
import BaseSwitch from '@/components/BaseSwitch.vue';
import TrainValTestSplitSlider from '@/components/TrainerComponent/TrainerExperimentAndSessions/TrainValTestSplitSlider';
import TrainerSessionAdvancedSettings from '@/components/TrainerComponent/TrainerExperimentAndSessions/TrainerSessionAdvancedSettings.vue';
import { inputSanitizer, changeSanitizer } from '@/assets/js/utils';

export default {
  name: 'CreateTrainerSessionModal',
  components: {
    Modal,
    BaseSwitch,
    TrainValTestSplitSlider,
    TrainerSessionAdvancedSettings,
  },
  props: {
    trainer: {
      type: Object,
      default: () => {},
    },
    trainerSessionList: {
      type: Array,
      default: () => [],
    },
  },
  emits: ['create-trainer-session'],
  data() {
    return {
      message: '',
      name: '',
      description: "",
      dataset: '',
      annset: '',
      groupSplit: 'percent',
      custom_split_slider_values: [80, 20, 0],
      custom_split_train_input: 0,
      custom_split_val_input: 0,
      dataset_split_params: {
        train: {
          d: '',
          set: '',
          group: '',
        },
        val: {
          d: '',
          set: '',
          group: '',
        },
        test: {
          d: '',
          set: '',
          group: '',
        },
      },
      default_split_params: {
        train: {
          d: '',
          set: '',
        },
        val: {
          d: '',
          set: '',
        },
        test: {
          d: '',
          set: '',
        },
      },
      train_split: 70,
      val_split: 20,
      test_split: 10,
      image_width: 640,
      image_height: 640,
      modelList: [
        { name: 'ModelPack', value: 'modelpack' },
        { name: 'Yolo v8', value: 'yolov8' },
      ],
      yoloModelList: {
        detection: [
          { name: 'Nano', value: 'yolov8n.pt' },
          { name: 'Small', value: 'yolov8s.pt' },
          { name: 'Medium', value: 'yolov8m.pt' },
          { name: 'Large', value: 'yolov8l.pt' },
          { name: 'X-Large', value: 'yolov8x.pt' },
        ],
        segmentation: [
          { name: 'Nano', value: 'yolov8n-seg.pt' },
          { name: 'Small', value: 'yolov8s-seg.pt' },
          { name: 'Medium', value: 'yolov8m-seg.pt' },
          { name: 'Large', value: 'yolov8l-seg.pt' },
          { name: 'X-Large', value: 'yolov8x-seg.pt' },
        ],
      },
      yoloModel: null,
      selectedModel: null,
      epochs: 10,
      batch_size: 16,
      batch_max: 1000000,
      device: 'gpu',
      selectedTrainingSessionWeights: 'default',
      weightsFilePath: '',
      trainMode: 'detect',
      startingTrainer: false,
      panels: ['description'],
      mpk_params: {
        default_lr: true,
        lr_scheduler: 'WLS',
        wls_params: {
          "warmup-epochs": 2,
          warmup_lr: 1e-6,
          initial_lr: 1e-3,
        },
        els_params: {
          decay: 0,
          initial_lr: 1e-3,
        },
      },
      advancedSettings: {
        upsample: 'resize',
      },
    };
  },
  computed: {
    isDebugMode() {
      return this.$store.state.debugMode;
    },
    datasetList() {
      return this.$store.state.datasets.datasetList;
    },
    custom_split_params() {
      return {
        train: {
          percent: this.custom_split_slider_values[0],
          count: (this.custom_split_slider_values[0] / 100) * this.total,
        },
        val: {
          percent: this.custom_split_slider_values[1],
          count: (this.custom_split_slider_values[1] / 100) * this.total,
        },
        test: {
          percent: this.custom_split_slider_values[2],
          count: (this.custom_split_slider_values[2] / 100) * this.total,
        },
      };
    },
    total() {
      if (this.dataset) {
        return this.dataset.numImages;
      }
      return 0;
    },
    hasAdvancedModelSettings() {
      return this.selectedModel?.value === 'modelpack';
    },
    sizeWatcher() {
      return { image_width: this.image_height, image_height: this.image_width };
    },
  },
  watch: {
    sizeWatcher() {
      if (this.image_width * this.image_height >= 1000000) {
        this.batch_max = 4;
        if (this.batch_size > 4) {
          this.batch_size = 4;
        }
      } else if (this.image_width * this.image_height < 1000000 && this.image_width * this.image_height >= 250000) {
        this.batch_max = 10;
        if (this.batch_size > 10) {
          this.batch_size = 10;
        }
      } else {
        this.batch_max = 16;
      }
    },
    selectedModel(m) {
      if (m.value === 'yolov8') {
        if (this.trainMode === 'detect') {
          this.yoloModel = this.yoloModelList.detection[0];
        } else if (this.trainMode === 'segment') {
          this.yoloModel = this.yoloModelList.segmentation[0];
        }
      }
    },
    trainMode(m) {
      if (this.selectedModel.value === 'yolov8') {
        if (m === 'detect') {
          this.yoloModel = this.yoloModelList.detection[0];
        } else if (m === 'segment') {
          this.yoloModel = this.yoloModelList.segmentation[0];
        }
      }
    },
    selectedTrainingSessionWeights(weights) {
      if (weights === 'default') {
        this.mpk_params.default_lr = true;
        this.mpk_params.wls_params.initial_lr = 1e-3;
      } else {
        this.mpk_params.default_lr = false;
        this.mpk_params.wls_params.initial_lr = 1e-5;
      }
    },
    datasetList(l) {
      if (l && l.length > 0) {
        this.dataset = l[0];
      }
    },
    dataset(d) {
      this.annset = '';
    },
  },
  created() {
    this.dataConnect = new DatastoreConnect(this.$store.state.enterpriseServerUrl);
    if (this.datasetList && this.datasetList.length > 0) {
      this.dataset = this.datasetList[0];
    }
  },
  methods: {
    inputSanitizer,
    changeSanitizer,
    showModal() {
      this.$refs.createTrainerModal.showModal();
      this.resetData();
    },
    resetData() {
      this.name = '';
      this.description = '';
      this.dataset = '';
      this.annset = '';
      this.epochs = 10;
      this.batch_size = 16;
      this.image_width = 640;
      this.image_height = 640;
      this.selectedModel = this.modelList[0];
      this.trainMode = 'detect';
      this.yoloModel = this.yoloModelList.detection[0];
      this.selectedTrainingSet = '';
      this.device = 'gpu';
      this.message = '';
      this.startingTrainer = false;
      this.selectedTrainingSessionWeights = 'default';
      this.panels = ['description'];
      this.mpk_params = {
        default_lr: true,
        lr_scheduler: 'WLS',
        wls_params: {
          "warmup-epochs": 2,
          warmup_lr: 1e-6,
          initial_lr: 1e-3,
        },
        els_params: {
          decay: 0,
          initial_lr: 1e-3,
        },
      };
      this.groupSplit = 'percent';
      this.dataset_split_params = {
        train: {
          d: '',
          set: '',
          group: 0,
        },
        val: {
          d: '',
          set: '',
          group: 0,
        },
        test: {
          d: '',
          set: '',
          group: 0,
        },
      };
      this.default_split_params = {
        train: {
          d: '',
          set: '',
        },
        val: {
          d: '',
          set: '',
        },
        test: {
          d: '',
          set: '',
        },
      };
    },
    async startSession() {
      if (!this.name) {
        this.message = `Missing Name`;
        return;
      }

      if (!this.selectedModel) {
        this.message = `Missing Model Type`;
        return;
      }

      if (!this.image_width || this.image_width === 0) {
        this.message = `Missing Image Width`;
        return;
      }
      if (!this.image_height || this.image_height === 0) {
        this.message = `Missing Image Height`;
        return;
      }

      if ((this.image_width >= 1000 || this.image_height > 1000) && this.batch_size > 4) {
        this.message = `Batch size too large for current image size (Max: 4)`;
        return;
      }

      const params = {
        trainer_id: this.trainer.id,
        auto_remove: true,
        device: this.device,
        epochs: this.epochs,
        model: this.selectedModel.value,
        image_width: this.image_width,
        image_height: this.image_height,
        task_type: this.trainMode,
        split_mode: this.groupSplit,
      };

      if (this.groupSplit === 'percent') {
        if (!this.dataset) {
          this.message = `Missing Dataset`;
          return;
        }

        if (!this.annset) {
          this.message = `Missing Annotation Set`;
          return;
        }
        params['dataset_id'] = this.dataset.id;
        params['annset_id'] = this.annset.id;
        params['train_split'] = this.custom_split_slider_values[0];
        params['val_split'] = this.custom_split_slider_values[1];
        params['test_split'] = this.custom_split_slider_values[2];
      }
      if (this.groupSplit === 'group') {
        if (!this.dataset_split_params.train.d) {
          this.message = `Missing Train Dataset`;
          return;
        }
        params['train_dataset_id'] = this.dataset_split_params.train.d.id;

        if (!this.dataset_split_params.train.set) {
          this.message = `Missing Train Annotation Set`;
          return;
        }
        params['train_set_id'] = this.dataset_split_params.train.set.id;

        if (this.dataset_split_params.train.group <= 0) {
          this.message = `Missing Train Group`;
          return;
        }
        params['train_group'] = this.dataset_split_params.train.group;

        if (!this.dataset_split_params.val.d) {
          this.message = `Missing Val Dataset`;
          return;
        }
        params['val_dataset_id'] = this.dataset_split_params.val.d.id;

        if (!this.dataset_split_params.val.set) {
          this.message = `Missing Val Annotation Set`;
          return;
        }
        params['val_set_id'] = this.dataset_split_params.val.set.id;

        if (this.dataset_split_params.val.group <= 0) {
          this.message = `Missing Val Group`;
          return;
        }
        params['val_group'] = this.dataset_split_params.val.group;

        if (this.dataset_split_params.test.set && this.dataset_split_params.test.group > 0) {
          params['test_dataset_id'] = this.dataset_split_params.test.d.id;
          params['test_set_id'] = this.dataset_split_params.test.set.id;
          params['test_group'] = this.dataset_split_params.test.group;
        }
      }
      if (this.groupSplit === 'default') {
        if (!this.default_split_params.train.d) {
          this.message = `Missing Train Dataset`;
          return;
        }
        params['train_dataset_id'] = this.default_split_params.train.d.id;

        if (!this.default_split_params.train.set) {
          this.message = `Missing Train Annotation Set`;
          return;
        }
        params['train_set_id'] = this.default_split_params.train.set.id;

        if (!this.default_split_params.val.d) {
          this.message = `Missing Val Dataset`;
          return;
        }
        params['val_dataset_id'] = this.default_split_params.val.d.id;

        if (!this.default_split_params.val.set) {
          this.message = `Missing Val Annotation Set`;
          return;
        }
        params['val_set_id'] = this.default_split_params.val.set.id;

        if (this.default_split_params.test.set) {
          params['test_dataset_id'] = this.default_split_params.test.d.id;
          params['test_set_id'] = this.default_split_params.test.set.id;
        }
      }

      if (this.isDebugMode) {
        params['auto_remove'] = false;
      }

      if (this.name) {
        params.session_name = this.name;
      }
      if (this.description) {
        params.session_description = this.description;
      }
      if (this.batch_size) {
        params.batch_size = this.batch_size;
      }
      if (this.selectedTrainingSessionWeights && this.selectedTrainingSessionWeights === 'weights-from-file-path') {
        params.weights_file_path = this.weightsFilePath;
      } else if (this.selectedTrainingSessionWeights === 'default') {
        params.resume_from_session = "";
      } else if (this.selectedTrainingSessionWeights) {
        params.resume_from_session = this.selectedTrainingSessionWeights;
      }
      if (this.selectedModel.value === 'modelpack' && this.mpk_params.default_lr === false) {
        // use mpk hyperparameters lr
        if (this.mpk_params.lr_scheduler === 'WLS') {
          params.mpk_params = this.mpk_params.wls_params;
        } else if (this.mpk_params.lr_scheduler === 'ELS') {
          params.mpk_params = this.mpk_params.els_params;
        }
      }

      if (this.selectedModel.value === 'yolov8') {
        params['yolo_model'] = this.yoloModel.value;
      }

      if (this.hasAdvancedModelSettings && this.advancedSettings) {
        params.advanced_settings = JSON.stringify(this.advancedSettings);
      }

      this.startingTrainer = true;
      await this.dataConnect.startTrainer(params)
        .then((data) => {
          if (data.result) {
            this.$refs.createTrainerModal.closeModal();
            this.$emit('create-trainer-session', { docker_data: data.result });
          } else if (data.error) {
            this.message = data.error.message;
          }
        })
        .catch((error) => {
          console.log(error);
        });
      this.startingTrainer = false;
    },
    getSessionName(session) {
      if (session.name) {
        return session.name;
      } else if (session.docker_task) {
        return session.docker_task.date;
      }
      return 'Unknown Session';
    },
  },
};

</script>

<style lang="scss" scoped>

.settings {
  padding: 0.75rem 3rem;
  min-width: 540px;
  overflow-y: scroll;

  span {
    width: 100%;
    font-size: 1rem;
    font-weight: 700;
    text-align: left;
    @include themify() {
      color: themed('color-primary');
    }
  }
}

.footer {
  display: flex;
  flex-direction: row;
  padding: 0.75rem 3rem;
  justify-content: flex-end;
}

.result {
  display: flex;
  width: 100%;
  height: 100%;
  border-radius: 5px;
  font-size: 1rem;
  font-weight:600;
  padding: 10px;
  align-items: center;
  justify-content: center;

  span {
    width: 100%;
    line-break: anywhere;
  }
}

.error {
  @include themify() {
    background: rgba(182, 94, 94, 0.5) ;
    color: themed('color-error')
  }
}

.radio-group {
  display: inline-block;
  margin: 20px;
  border-radius: 5px;
  overflow: hidden;
  @include themify() {
    border: solid 1px themed('color-primary');
  }
}

.radio-group:focus-within, .radio-group:active {
  @include themify() {
    box-shadow:0 0 5px themed('color-primary');
  }
}

.radio-group input[type="radio"] {
  position: absolute;
  opacity: 0;
  pointer-events: none;
}

.radio-group input[type="radio"] + label {
  min-width: 6rem;
  max-width: 50%;
  text-align: center;
  display: inline-block;
  cursor: pointer;
  font-weight: bold;
  font-size: 1rem;
  line-height: 1.25;
  padding: 5px 20px;
  transition: all 0.4s ease;
  box-shadow: 0 0 3px 1px rgba(0, 0, 0, 0.25) inset;
  @include themify() {
    color: themed('color-primary');
    background: themed('color-white-100');
  }
}

.radio-group input[type="radio"]:not(:disabled):hover + label {
  @include themify() {
    background: themed('color-primary-200');
    color: themed('primary-text-color');
  }
}

.radio-group input[type="radio"]:checked + label,
.radio-group input[type="radio"]:checked:hover + label {
  opacity: 1;
  z-index: 1;
  box-shadow: 0 0 6px -2px rgba(0, 0, 0, 0.25), 0 0 6px 2px rgba(0, 0, 0, 0.25);
  @include themify() {
    background: themed('color-primary');
    color: themed('primary-text-color');
  }
}

.radio-group.full-width-selector {
  display: flex;
  width: 100%;
  margin: 0;
}

.radio-group.full-width-selector input[type="radio"] + label {
  min-width: 1px;
  padding: 3px 10px;
  flex: 1 1 auto;
}

.dataset-selector{
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: auto;

  :first-child {
    flex: 1 1 40%;
  }

  :not(:first-child) {
    flex: 1 1 60%;
  }
}

.accordion-title {
  @include themify() {
    background: themed('accordion-header-background');
    color: themed('accordion-header-text-color');
  }
}

.v-expansion-panel {
  border-radius: 4px !important;
}

// .v-expansion-panel-title {
//   @include themify() {
//     background-color: themed('accordion-header-background');
//     color: themed('accordion-header-text-color');
//     caret-color: themed('accordion-header-text-color');
//   }

//   h3 {
//     @include themify() {
//     color: themed('accordion-header-text-color');
//     }
//   }
// }

.v-expansion-panel-title--active {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.v-expansion-panel:not(:first-child), .v-expansion-panel + .v-expansion-panel {
    margin-top: 16px;
}

.trainer-session-row-switch {
  flex-direction: row !important;
  overflow: visible;
  align-items: center;

  label:first-child {
    flex: 1 1 40%;
    max-width: 40%;
  }
}

.full-width {
  width: 100%;
}

.custom-split-card {
  border-radius: 6px;
  padding: 10px;

  p {
    font-size: 0.9rem;
    font-weight: 700;
  }
}

.custom-split-card + .custom-split-card {
  margin-left: 15px;
}

.slider-settings {
  display: flex;
  flex-direction: row;
  padding: 12px;
  border-radius: inherit;
  gap: 6px;
  min-width: 350px;
  min-height: 50px;
  justify-content: center;
  align-items: center;
  overflow: auto;

  &__row {
    display: flex;
    align-items: center;
  }

  & input[type=checkbox] {
    margin-right: 12px;
  }

  & span {
    width: 40px;
    text-align: center;
  }
}

._train{
    border: 2px solid rgb(44, 145, 246);
}

._validation{
  @include themify {
    border: 2px solid themed('range-slider-selected-color');
  }
}

._test{
  @include themify {
    border: 2px solid themed('range-slider-selected-color-right');
  }
}

.clickable {
  cursor: pointer;
}

.split-radio[type="radio"] {
  height: 18px;
  width: 18px;
  @include themify() {
    accent-color: themed('color-primary');
  }
}

</style>
