<template>
  <Modal
    ref="uploadImagesModal"
    :title="'Import Dataset'"
    :persistent="importTypeSelected"
  >
    <template #modal-main>
      <div class="upload-images scrollbar">
        <div class="upload-images__drag-drop form">
          <input
            id="file-input"
            ref="imageFiles"
            class="d-none"
            type="file"
            multiple
            accept="image/*"
            @change="fileSelected"
          >
          <button
            v-if="uploadStatus !== 'uploading'"
            id="file-upload-btn"
            class="file-upload-btn"
            :class="{_highlight: dropHighlighted}"
            @click="chooseFiles"
            @dragenter.prevent="handleDragEnter"
            @dragover.prevent=""
            @dragleave="handleDragLeave"
            @drop="dropHighlighted = false"
            @drop.stop.prevent="handleDrop"
          >
            <SVGIcon
              id="upload-icon"
              class="file-upload-btn__upload-icon"
              :iconName="'cloud_upload'"
              :width="'100%'"
              :height="'200px'"
            />
            <div id="file-upload-btn-text" class="file-upload-btn__text">
              <span>Click to select images</span>
              <span v-if="!isMobileDevice">or drag and drop your images here</span>
            </div>
          </button>
          <!-- <div v-if="uploadStatus === 'uploading'" id="upload-progress">
            <span><strong>{{ uploadMessage }}...</strong></span>
            <span>Block {{ blockNo + 1 }} of {{ blockCount }}</span>
            <progress id="upload-progress-bar" :value="blockNo + 1" :max="blockCount" />
          </div>
          <div v-if="uploadStatus === 'completed'" id="upload-progress" class="completed">
            <span><strong>{{ uploadMessage }}</strong></span>
            <div id="upload-complete-icon" v-html="success_check_icon" />
          </div>
          <div v-if="uploadStatus === 'error'" id="upload-progress" class="error">
            <div>
              <span><strong>{{ uploadMessage }}</strong></span>
              <div id="upload-error-icon" v-html="problem_icon" />
            </div>
            <div v-if="uploadErrorMessage" id="upload-error-msg">
              <span>{{ uploadErrorMessage }}</span>
            </div>
          </div> -->
        </div>
        <div class="separator my-3">Or Custom Import</div>
        <div class="form">
          <div class="row">
            <div class="col-12 settings__control-group">
              <label>Import Type</label>
              <select
                id="type-select"
                v-model="selectedImportType"
                class="select"
                required
              >
                <option disabled :value="''">Select an Import Type</option>
                <option v-for="(option, i) in importTypes" :key="`import-type-${i}`" :value="option">
                  {{ option.label }}
                </option>
              </select>
            </div>
          </div>
          <DefaultImportScriptForm
            v-if="importTypeSelected"
            :datasetID="datasetID"
            :annotationSets="annotationSets"
            :importType="selectedImportType"
            @cancel="selectedImportType = ''"
            @upload-started="closeModal"
            @upload-complete="closeModal"
            @upload-error="(params) => $emit('upload-error', params)"
          />
        </div>
      </div>
    </template>
  </Modal>
</template>

<script>
import Modal from '@/components/Modal.vue';
import SVGIcon from '@/components/SVGIcon.vue';
import DefaultImportScriptForm from '@/components/DatasetComponent/ImportForms/DefaultImportScriptForm.vue';
import DatastoreConnect from '@/assets/js/DatastoreFunctions/datastore-interface';

export default {
  name: 'UploadImagesModal',
  components: {
    Modal,
    SVGIcon,
    DefaultImportScriptForm,
  },
  props: {
    datasetID: {
      type: Number,
      default: null,
    },
    annotationSets: {
      type: Array,
      default: () => [],
    },
  },
  emits: ['upload-complete', 'refresh', 'upload-error'],
  data() {
    return {
      isMobileDevice: window.matchMedia('(pointer: coarse)').matches || window.matchMedia('(pointer: none)').matches,
      dataConnect: new DatastoreConnect(this.$store.state.enterpriseServerUrl),
      files: null,
      blockSize: 0,
      blockCount: 0,
      blockNo: -1,
      uploadStatus: 'idle',
      uploadInProgress: false,
      uploadMessage: '',
      uploadErrorMessage: '',
      dropHighlighted: false,
      dragCounter: 0,
      selectedImportType: "",
      importTypes: [],
    };
  },
  computed: {
    user() {
      return this.$store.state.user.user;
    },
    uploadFolderTasks() {
      return this.$store.state.tasks.uploadTasks;
    },
    isDebugMode() {
      return this.$store.state.debugMode;
    },
    importTypeSelected() {
      if (this.selectedImportType) {
        return Object.keys(this.selectedImportType).length > 0;
      }
      return false;
    },
    datasetName() {
      const dataset = this.$store.state?.datasets?.datasetList.find((d) => d.id === this.datasetID);
      return dataset.name;
    },
    currentProject() {
      return this.$store.state.projects.currentProject;
    },
    importParams() {
      const importParams = {
        import_type: 'images-folder',
        dataset_id: this.datasetID,
        dataset_name: this.datasetName,
        project_id: this.currentProject.id,
        // group_id: this.selectedGroup.id,
        // ...this.formValue,
      };
      // if (this.shouldCreateImageGroups) {
      //   importParams.create_image_groups = this.imageGroupsToCreate;
      // }
      // if (this.selectedGroup) {
      //   importParams.group_id = this.selectedGroup.id;
      // }
      return importParams;
    },
  },
  watch: {
    dropHighlighted() {

    },
  },
  async mounted() {
    await this.getSupportedTypes();
  },
  methods: {
    async getSupportedTypes() {
      this.dataConnect.getSupportedImportTypes()
        .then((data) => {
          this.importTypes = data.result;
        })
        .catch((e) => console.log(e));
    },
    showModal() {
      this.$refs.uploadImagesModal.showModal();
    },
    closeModal() {
      this.$refs.uploadImagesModal.closeModal();
    },
    chooseFiles() {
      document.getElementById("file-input").click();
    },
    handleDragEnter() {
      this.dragCounter += 1;
      this.dropHighlighted = true;
    },
    handleDragLeave() {
      this.dragCounter -= 1;
      if (this.dragCounter === 0) {
        this.dropHighlighted = false;
      }
    },
    handleDrop(e) {
      this.files = e.dataTransfer.files;
      this.uploadFiles(this.files);
    },
    fileSelected() {
      this.files = structuredClone(this.$refs.imageFiles.files);
      this.$refs.imageFiles.value = null;
      this.uploadFiles(this.files);
    },
    async uploadFiles(files) {
      if (files == null || files.length === 0) return;

      const resp = await this.s3MultipleImageUpload({ fileList: files, params: this.importParams });
      if (resp && !resp.error) {
        this.closeModal();
      }
    },
    async s3MultipleImageUpload({ fileList, params }) {
      const keys = [];

      // Validate file count and sizes, also trim paths
      const maxFileSize = await this.getMaxImageSize();
      let imgCount = 0;
      let imgTooLargeErrCount = 0;
      for (const file of fileList) {
        const isImage = file.type.includes("image/");
        const isVideo = file.type.includes("video/");
        if ((isImage || isVideo) && (file.size <= maxFileSize || maxFileSize === -1)) {
          keys.push(file.name);
          imgCount += 1;
        } else if (isImage || isVideo) {
          imgTooLargeErrCount += 1;
        }
      }

      // Check image upload limit
      const imageLimit = await this.checkImageUploadLimit(imgCount)
        .catch((error) => {
          throw error;
        });
      if (imageLimit < 0) {
        this.$emit("upload-error", {
          confirmMessage: `Not enough credits to perform this operation.`,
          confirmMessageHeader: 'Insufficient Credits',
        });
        return null;
      }

      // How many files were too large to upload
      if (imgTooLargeErrCount > 0) {
        this.$emit("upload-error", {
          confirmMessage: `${imgTooLargeErrCount} image(s) were discard due to file size limit.`,
          confirmMessageHeader: 'Large File Size',
        });
      }

      // Get s3 presigned urls
      const presignedResponse = await this.s3CreatePresignedUrl({
        dataset_id: params.dataset_id,
        keys,
      })
        .catch((error) => {
          throw error;
        });

      const newFileList = [];
      if (presignedResponse) {
        const {
          importerSource, indexes, presignedUrls, tnPresignedUrls,
        } = presignedResponse;

        if (importerSource) {
          params.importer_source = importerSource;
        }

        presignedUrls.forEach((url, i) => {
          newFileList.push({
            url,
            thumbnail_url: tnPresignedUrls[i],
            file: fileList[indexes[i]],
          });
        });
      }

      // this.startingImport = false;

      const importResponse = await this.$store.dispatch('importer/importByType', {
        urls: newFileList,
        importParams: params,
      });

      return importResponse;
    },
    async getMaxImageSize() {
      return this.dataConnect.getAccountingMaxImageSize()
        .then((resp) => {
          if (resp.error !== undefined) {
            throw Error(resp.error);
          }
          return resp.result;
        })
        .catch((error) => {
          throw error;
        });
    },
    async checkImageUploadLimit(imgCount) {
      return this.dataConnect.checkImageLimitForAccounting({
        image_count: imgCount,
      })
        .then((resp) => {
          if (resp.error !== undefined) {
            throw Error(resp.error);
          }
          return resp.result;
        })
        .catch((error) => {
          throw error;
        });
    },
    async s3CreatePresignedUrl({ dataset_id, keys }) {
      return this.dataConnect.s3CreatePresignedUrl({
        dataset_id,
        keys,
      })
        .then((resp) => {
          if (resp.error !== undefined) {
            throw Error(resp.error);
          }
          return resp.result;
        })
        .catch((error) => {
          throw error;
        });
    },
  },
};
</script>

<style lang="scss" scoped>

.upload-images {
  display: flex;
  flex-direction: column;
  width: 500px;
  padding: 1rem 1.5rem;
  overflow: auto;

  &__drag-drop {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
}

.separator {
  display: flex;
  align-items: center;
  text-align: center;
  font-weight: 700;
  font-size: 0.75rem;
  @include themify() {
    color: themed('form-separator-color');
    border-color: themed('form-separator-color');
  }
  &::before, &::after {
    content: '';
    flex: 1;
    border-bottom: 1px solid;
  }
  &:not(:empty)::before {
    margin-right: 1.5em;
    margin-left: .25em;
  }
  &:not(:empty)::after {
    margin-right: .25em;
    margin-left: 1.5em;
  }
}

.file-upload-btn {
  position: relative;
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  padding: 32px;
  align-items: center;
  justify-content: center;
  min-height: 150px;
  max-height: 200px;
  border: 2px dashed grey;
  border-radius: 8px;
  cursor: pointer;

  &__upload-icon {
    position: absolute;
    bottom: 50%;
    width: 48px;
    height: 48px;
    @include themify() {
      color: themed('color-primary');
    }
  }

  &__text {
    position: absolute;
    top: 50%;
    display: flex;
    flex-direction: column;

    & span {
      padding-top: 0.25rem;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      direction: rtl;
      color: #250E81;
      font-size: 16px;
      font-weight: bold;
    }

    & span + span {
      color: grey;
      font-size: 12px;
    }
  }

  &._highlight {
    padding: 27px;
    border: 3px dashed #250E81;
    background: #250e8125;
    animation-name: fill-upload-btn;
    animation-duration: 0.5s;

    & .file-upload-btn__upload-icon {
      width: 56px;
      height: 56px;
      animation-name: grow-upload-icon;
      animation-duration: 0.05s;
    }
    @keyframes grow-upload-icon {
      from {  width: 48px; height: 48px;}
      to {  width: 56px; height: 56px;}
    }

    @keyframes fill-upload-btn {
      from {  background: transparent;}
      to {  background: #250e8125;}
    }
  }
}

#upload-progress {
  display: flex;
  flex-direction: column;
  border: 1px solid grey;
  border-radius: 4px;
  padding: 16px;
  margin-top: 16px;
  width: 100%;
}

#upload-progress span {
  width: fit-content;
  text-align: left;
}

#upload-progress span + span {
  margin-top: 4px;
}

#upload-progress-bar {
  width: 100%;
  border-radius: 0;
  height: 6px;
  margin-top: 4px;
}

#upload-progress-bar::-webkit-progress-bar {
  background-color: #eaedf3;
}
#upload-progress-bar::-webkit-progress-value {
  background-color: #0075ff;
}

#upload-progress.completed {
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

#upload-progress.completed #upload-complete-icon {
  display: flex;
  align-items: center;
}

#upload-progress.error {
  flex-direction: column;
}

#upload-progress.error > div:first-child {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

#upload-progress.error #upload-error-msg {
  display: flex;
  align-items: center;
  text-align: left;
  padding-top: 8px;
}

#upload-progress.error #upload-error-icon {
  display: flex;
  align-items: center;
}

.upload-title {
  display: flex;
  flex-direction: row;
  justify-content: space-between
}

.note {
  font-size: 0.8rem;
  font-weight: 400;
}

</style>
