<template>
  <div
    class="base-sidebar"
    :class="[`_${placement}`, { _hide: !localShow }]"
    :style="styles"
  >
    <div v-if="title" class="base-sidebar__header">
      <IconButton
        v-if="closeable"
        class="close-sidebar-btn"
        :icon="'close'"
        :width="24"
        :height="24"
        :type="''"
        @click="closeSidebar"
      />
      <h2 class="mt-0 mb-0">{{ title }}</h2>
      <slot name="sidebar-header" />
    </div>
    <div class="base-sidebar__content">
      <div class="base-sidebar__content-inner" :style="styles">
        <slot />
      </div>
    </div>
    <button
      v-if="collapsible"
      class="base-sidebar__toggle-btn"
      @click="localShow = !localShow"
    >
      <SVGIcon
        :width="24"
        :height="24"
        :iconName="'chevron_left'"
      />
    </button>
  </div>
</template>

<script>
import SVGIcon from '@/components/SVGIcon.vue';
import IconButton from '@/components/IconButton.vue';

export default {
  name: 'BaseSidebar',
  components: {
    SVGIcon,
    IconButton,
  },
  props: {
    collapsible: {
      type: Boolean,
      default: true,
    },
    closeable: {
      type: Boolean,
      default: false,
    },
    placement: {
      type: String,
      default: 'left',
    },
    width: {
      type: Number,
      default: null,
    },
    show: {
      type: Boolean,
      default: true,
    },
    title: {
      type: String,
      default: null,
    },
  },
  emits: ['closed', 'update:show'],
  data() {
    return {
      localShow: true,
    };
  },
  computed: {
    styles() {
      let width = this.width;
      if (typeof this.width === 'number') {
        width = `${width}px`;
      }
      return {
        'minWidth': width,
        'maxWidth': width,
      };
    },
  },
  watch: {
    show(show) {
      this.localShow = show;
    },
  },
  mounted() {
    this.localShow = this.show;
  },
  methods: {
    closeSidebar() {
      this.localShow = false;
      this.$emit('update:show', false);
      this.$emit('closed');
    },
  },
};
</script>

<style lang="scss" scoped>
$sidebar-toogle-btn-width: 16px;
$sidebar-toogle-btn-svg-width: 24px;

.base-sidebar {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  box-shadow: 0 0 3px gray, 0 0 10px gray;
  z-index: 10;
  transition: min-width 0.3s ease-in-out, max-width 0.3s ease-in-out;
  @include themify() {
    background: themed('sidebar-background');
  }

  &__header {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 16px;

    .close-sidebar-btn {
      margin-right: 16px;
    }

    h2 {
      line-height: 1;
      font-size: 1.25rem;
      letter-spacing: 0;
      font-weight: 500;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    overflow: hidden;
  }

  &__content-inner {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    overflow: hidden;
  }

  &__toggle-btn {
    position: absolute;
    top: 40px;
    left: 100%;
    width: 10px;
    height: 50px;
    padding: 0;
    background: transparent;
    z-index: 2;
    cursor: pointer;
    @include themify() {
      color: themed('sidebar-toggle-btn-color');
      border-bottom: 10px solid transparent;
      border-left: $sidebar-toogle-btn-width solid themed('sidebar-toggle-btn-bg');
      border-right: 0 solid transparent;
      border-top: 10px solid transparent;
    }

    & svg {
      position: absolute;
      top: 0;
      bottom: 0;
      left: calc(-#{$sidebar-toogle-btn-width / 2} - #{$sidebar-toogle-btn-svg-width / 2});
      right: 0;
      margin: auto;
      transition: transform 0.1s ease-in-out;
      pointer-events: none;
    }

    &.right {
      top: 40px;
      left: calc(0% - #{$sidebar-toogle-btn-width});
      @include themify() {
        color: themed('sidebar-toggle-btn-color');
        border-left: 0 solid transparent;
        border-right: $sidebar-toogle-btn-width solid themed('sidebar-toggle-btn-bg');
      }

      & svg {
        left: calc(#{$sidebar-toogle-btn-width / 2} - #{$sidebar-toogle-btn-svg-width / 2});
        transform: rotate(-180deg);
      }
    }
  }

  &._hide {
    min-width: 0px !important;
    max-width: 0px !important;

    & .base-sidebar__toggle-btn svg {
      transform: rotate(-180deg);
    }
  }

  &._right {
    .base-sidebar__toggle-btn {
      top: 40px;
      left: calc(0% - #{$sidebar-toogle-btn-width});
      @include themify() {
        color: themed('sidebar-toggle-btn-color');
        border-left: 0 solid transparent;
        border-right: $sidebar-toogle-btn-width solid themed('sidebar-toggle-btn-bg');
      }

      & svg {
        left: calc(#{$sidebar-toogle-btn-width / 2} - #{$sidebar-toogle-btn-svg-width / 2});
        transform: rotate(-180deg);
      }
    }

    &._hide {
      & .base-sidebar__toggle-btn svg {
        transform: rotate(-360deg);
      }
    }
  }
}

</style>
