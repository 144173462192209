<template>
  <div v-if="doneFetch" class="settings scrollbar">
    <div class="row">
      <div class="col-9 flex-col">
        <span class="header">Current Usage</span>
        <span class="subtitle">Summary of your organization total usage this month. Usage information may take some time to update.</span>
      </div>
      <div class="col-3 flex-col">
        <span class="header">Funds Remaining</span>
        <h3>USD  {{ Number(totalDollars).toLocaleString(userLocale, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) }}</h3>
      </div>
    </div>
    <div class="static-summary">
      <div class="static-summary__item">
        <div class="icon">
          <SVGIcon
            :iconName="'folder2'"
            :width="'36px'"
            :height="'36px'"
          />
        </div>
        <div class="progress">
          <div class="progress__title">
            <span>Projects</span>
          </div>
          <div class="progress__subtitle">
            <span>{{ `${numProject}` }}</span>
          </div>
        </div>
      </div>
      <div class="static-summary__item">
        <div class="icon">
          <SVGIcon
            :iconName="'database2'"
            :width="'36px'"
            :height="'36px'"
          />
        </div>
        <div class="progress">
          <div class="progress__title">
            <span>Datasets</span>
            <SVGIcon
              :iconName="'info'"
              class="detail-icon"
              :width="'20px'"
              :height="'20px'"
              @click="() => showDetailedDatasets = !showDetailedDatasets"
            />
          </div>
          <div class="progress__subtitle">
            <template v-if="showDetailedDatasets">
              <span>{{ numActiveDataset }} <span class="unit">active</span> / {{ numParkedDataset }}
                <span class="unit">parked</span> / {{ numDeletedDataset }} <span class="unit">recycled</span></span>
            </template>
            <template v-else>
              <span>{{ numDataset }}</span>
            </template>
          </div>
        </div>
      </div>
      <div class="static-summary__item">
        <div class="icon">
          <SVGIcon
            :iconName="'image'"
            :width="'36px'"
            :height="'36px'"
          />
        </div>
        <div class="progress">
          <div class="progress__title">
            <span>Images</span>
            <SVGIcon
              :iconName="'info'"
              class="detail-icon"
              :width="'20px'"
              :height="'20px'"
              @click="() => showDetailedImages = !showDetailedImages"
            />
          </div>
          <div class="progress__subtitle">
            <template v-if="showDetailedImages">
              <span>{{ numActiveImages }} <span class="unit">active</span> / {{ numParkedImages }}
                <span class="unit">parked</span> / {{ numDeletedImages }} <span class="unit">recycled</span></span>
            </template>
            <template v-else>
              <span>{{ numImages }}</span>
            </template>
          </div>
        </div>
      </div>
      <div class="static-summary__item">
        <div class="icon">
          <SVGIcon
            :iconName="'set'"
            :width="'36px'"
            :height="'36px'"
          />
        </div>
        <div class="progress">
          <div class="progress__title">
            <span>Annotations (boxes)</span>
          </div>
          <div class="progress__subtitle">
            <span>{{ `${numAnnotations}` }}</span>
          </div>
        </div>
      </div>
      <div class="static-summary__item">
        <div class="icon">
          <SVGIcon
            :iconName="'train'"
            :width="'36px'"
            :height="'36px'"
          />
        </div>
        <div class="progress">
          <div class="progress__title">
            <span>Training Time (hour)</span>
          </div>
          <div class="progress__subtitle">
            <span>{{ `${numTrainingTime}` }}</span>
          </div>
        </div>
      </div>
      <div class="static-summary__item">
        <div class="icon">
          <SVGIcon
            :iconName="'detection2'"
            :width="'36px'"
            :height="'36px'"
          />
        </div>
        <div class="progress">
          <div class="progress__title">
            <span>Auto Annotations (hours)</span>
          </div>
          <div class="progress__subtitle">
            <span>{{ `${numAutoAnnotations}` }}</span>
          </div>
        </div>
      </div>
      <div class="static-summary__item">
        <div class="icon">
          <SVGIcon
            :iconName="'segments'"
            :width="'36px'"
            :height="'36px'"
          />
        </div>
        <div class="progress">
          <div class="progress__title">
            <span>Auto Segmentations (hours)</span>
          </div>
          <div class="progress__subtitle">
            <span>{{ `${numAutoSegmentations}` }}</span>
          </div>
        </div>
      </div>
    </div>
    <br>
    <div class="header">
      <span>Billing Details</span>
      <div class="date-selector settings__control-group">
        <select v-model="selectMonth" class="select">
          <option v-for="(month, i) in months" :key="i" :value="month">
            {{ month }}
          </option>
        </select>
        <select v-model="selectYear" class="select">
          <option v-for="(year, i) in years" :key="i" :value="year">
            {{ year }}
          </option>
        </select>
        <button class="button button-rounded" @click="retrieveMonthlyBill">Retrieve</button>
      </div>
    </div>
    <div class="usage-monitor">
      <div class="table-container">
        <v-data-table
          :headers="headers"
          :items="mainTableRows"
          item-value="name"
          :group-by="groupBy"
          :items-per-page="-1"
          density="comfortable"
        >
          <template #top>
            <div class="table-flex-end">
              <span>{{ `Net Usage (approx.): $ ${Number(Math.abs(totalCreditUsage)).toLocaleString(userLocale, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}` }}</span>
            </div>
          </template>
          <template #group-header="{ item, columns, toggleGroup, isGroupOpen }">
            <tr>
              <td :colspan="columns.length - 2">
                <v-btn
                  size="small"
                  variant="text"
                  :icon="isGroupOpen(item) ? 'mdi-chevron-down' : 'mdi-chevron-up'"
                  @click="toggleGroup(item)"
                />
                {{ item.value }}
              </td>
              <td :colspan="2" class="total-credit-group">
                <span :class="{ 'added-fund': getCreditForKey(item.value) > 0 }">
                  {{ `$ ${Number(Math.abs(getCreditForKey(item.value))).toLocaleString(userLocale, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}` }}
                </span>
              </td>
            </tr>
          </template>
          <template #[`header.date`]>
            <div class="date-col">
              <span>Date</span>
              <div class="tz-selector">
                <input
                  id="option-local"
                  v-model="selectTimeZone"
                  type="radio"
                  name
                  :value="'local'"
                >
                <label for="option-local">Local</label>
                <input
                  id="option-utc"
                  v-model="selectTimeZone"
                  type="radio"
                  :value="'utc'"
                >
                <label for="option-utc">UTC</label>
              </div>
            </div>
          </template>
          <template v-for="header in headers" #[`item.${header.key}`]="{ item }">
            <template v-if="header.key !=='dollars'">
              {{ `${item[header.key]}` }}
            </template>
            <template v-else>
              {{ `${Number(item[header.key]).toLocaleString(userLocale, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}` }}
            </template>
          </template>
          <template #bottom />
        </v-data-table>
      </div>
    </div>
  </div>
  <div v-else class="empty-state">
    <InlineLoader
      :width="'60px'"
      :height="'60px'"
      :border="'12px'"
    />
  </div>
</template>

<script>
import DatastoreConnect from '@/assets/js/DatastoreFunctions/datastore-interface';
import SVGIcon from '@/components/SVGIcon.vue';
import InlineLoader from '@/components/InlineLoader.vue';

export default {
  name: 'UserUsageLogs',
  components: {
    SVGIcon,
    InlineLoader,
  },
  data() {
    return {
      dsConn: new DatastoreConnect(this.$store.state.enterpriseServerUrl),
      dollars: 0,
      numProject: 0,
      numDataset: 0,
      showDetailedDatasets: false,
      hasDetailedDatasets: false,
      numActiveDataset: 0,
      numParkedDataset: 0,
      numDeletedDataset: 0,
      numImages: 0,
      showDetailedImages: false,
      hasDetailedImages: false,
      numActiveImages: 0,
      numParkedImages: 0,

      numDeletedImages: 0,
      numAnnotations: 0,
      numTrainingTime: 0,
      numAutoAnnotations: 0,
      numAutoSegmentations: 0,
      numTasks: 0,
      refreshTimeOut: false,
      headers: [
        {
          title: 'Description', key: 'description', sortable: false, width: '40%',
        },
        {
          title: 'Date', key: 'date', width: '22%', sortable: false, align: 'center',
        },
        {
          title: 'Amount', key: 'amount', sortable: false, width: '10%', align: 'center',
        },
        {
          title: 'Rate', key: 'rate', width: '10%', sortable: false, align: 'center',
        },
        {
          title: 'Duration', key: 'duration', sortable: false, width: '10%', align: 'center',
        },
        {
          title: 'Usage', key: 'dollars', sortable: false, width: '10%', align: 'end',
        },
      ],
      groupBy: [
        {
          key: 'type',
          order: 'asc',
        },
      ],
      orgId: 0,
      numImagesRecord: [],
      numParkedImagesRecord: [],
      numAnnotationsRecord: [],
      bulkRecord: [],
      trainingTimeRecord: [],
      validationTimeRecord: [],
      autoAnnRecord: [],
      autoSegRecord: [],
      usersRecord: [],
      serviceRecord: [],
      costPackage: null,
      selectMonth: '',
      selectYear: 0,
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      currentDateDollarCost: 0,
      selectTimeZone: 'local',
      doneSummaryFetch: false,
      doneDetailsFetch: false,
      userLocale: navigator.language || 'en-US',
    };
  },
  computed: {
    totalDollars() {
      if (!this.doneFetch) {
        return 0;
      } else {
        return this.dollars + this.parseLocaleNumber(this.currentDateDollarCost, this.userLocale);
      }
    },
    doneFetch() {
      return this.doneDetailsFetch && this.doneSummaryFetch;
    },
    user() {
      return this.$store.state.user.user;
    },
    months() {
      return [
        'January', 'February', 'March', 'April', 'May', 'June',
        'July', 'August', 'September', 'October', 'November', 'December',
      ];
    },
    years() {
      const currentYear = new Date().getFullYear();
      const startingYear = 2023;
      const years = [];
      for (let year = startingYear; year <= currentYear; year++) {
        years.push(year);
      }

      return years;
    },
    totalCreditUsage() {
      const count = Number.parseFloat(this.totalAnnotationCredit) + Number.parseFloat(this.totalImageCredit) + Number.parseFloat(this.totalParkedImageCredit)
      + Number.parseFloat(this.totalAutoAnnCredit) + Number.parseFloat(this.totalAutoSegCredit) + Number.parseFloat(this.totalTrainingCredit)
      + Number.parseFloat(this.totalUsersCredit) + Number.parseFloat(this.totalValidationCredit)
      + Number.parseFloat(this.totalBulkCredit) + Number.parseFloat(this.totalServiceCharge);
      return count;
    },
    totalTrainingCredit() {
      let count = 0;
      this.trainingTimeRecord.forEach((record) => {
        count += Number.parseFloat(record.dollars);
      });
      return count;
    },
    totalAutoAnnCredit() {
      let count = 0;
      this.autoAnnRecord.forEach((record) => {
        count += Number.parseFloat(record.dollars);
      });
      return count;
    },
    totalAutoSegCredit() {
      let count = 0;
      this.autoSegRecord.forEach((record) => {
        count += Number.parseFloat(record.dollars);
      });
      return count;
    },
    totalImageCredit() {
      let count = 0;
      this.numImagesRecord.forEach((record) => {
        count += Number.parseFloat(record.dollars);
      });
      return count;
    },
    totalParkedImageCredit() {
      let count = 0;
      this.numParkedImagesRecord.forEach((record) => {
        count += Number.parseFloat(record.dollars);
      });
      return count;
    },
    totalAnnotationCredit() {
      let count = 0;
      this.numAnnotationsRecord.forEach((record) => {
        count += Number.parseFloat(record.dollars);
      });
      return count;
    },
    totalValidationCredit() {
      let count = 0;
      this.validationTimeRecord.forEach((record) => {
        count += Number.parseFloat(record.dollars);
      });
      return count;
    },
    totalBulkCredit() {
      let count = 0;
      this.bulkRecord.forEach((record) => {
        count += Number.parseFloat(record.dollars);
      });
      return count;
    },
    totalUsersCredit() {
      let count = 0;
      this.usersRecord.forEach((record) => {
        count += Number.parseFloat(record.dollars);
      });
      return count;
    },
    totalServiceCharge() {
      let count = 0;
      this.serviceRecord.forEach((record) => {
        count += Number.parseFloat(record.dollars);
      });
      return count;
    },
    mainTableRows() {
      return [...this.numImagesRecord, ...this.numAnnotationsRecord, ...this.trainingTimeRecord,
        ...this.autoAnnRecord, ...this.autoSegRecord, ...this.validationTimeRecord,
        ...this.usersRecord, ...this.serviceRecord, ...this.bulkRecord, ...this.numParkedImagesRecord];
    },
  },
  watch: {
    showDetailedDatasets(v) {
      if (v) {
        this.getDetailedDatasets();
      }
    },
    showDetailedImages(v) {
      if (v) {
        this.getDetailedImages();
      }
    },
    selectTimeZone(val) {
      if (val === 'utc') {
        this.timeZone = 'UTC';
      } else if (val === 'local') {
        this.timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      }
      this.retrieveMonthlyBill();
    },
  },
  created() {
    if (this.$route.query.org) {
      this.orgId = parseInt(this.$route.query.org);
    }
    this.selectMonth = this.months[new Date().getMonth()];
    this.selectYear = new Date().getFullYear();
  },
  async mounted() {
    await this.getCostPackage();
    await this.getUsagePercentage();
    await this.retrieveMonthlyBill();
  },
  methods: {
    parseLocaleNumber(stringNumber, locale) {
    // Create a NumberFormat object for the specific locale
      const formatter = new Intl.NumberFormat(locale);

      // Extract the parts from the formatted number
      const parts = formatter.formatToParts(1234.56);
      const groupSeparator = parts.find((part) => part.type === 'group').value;
      const decimalSeparator = parts.find((part) => part.type === 'decimal').value;

      // Remove group separators and replace the decimal separator with a dot
      const normalizedNumber = stringNumber
        .replace(new RegExp(`\\${groupSeparator}`, 'g'), '')
        .replace(decimalSeparator, '.');

      // Parse the normalized number using parseFloat
      return parseFloat(normalizedNumber);
    },
    async retrieveMonthlyBill() {
      this.doneDetailsFetch = false;
      await this.dsConn.retrieveMonthlyBill({
        organization_id: this.orgId,
        month: this.selectMonth,
        year: this.selectYear,
      })
        .then((resp) => {
          if (resp.result && Object.keys(resp.result).length > 0 && this.costPackage) {
            this.parseReport(resp.result);
          } else {
            this.parseReport([]);
          }
          this.doneDetailsFetch = true;
        })
        .catch((e) => {
          console.log(e);
          this.doneDetailsFetch = true;
        });
    },
    async getCostPackage() {
      await this.dsConn.getCostPackage({ organization_id: this.orgId })
        .then((resp) => {
          if (resp.result) {
            this.costPackage = resp.result;
          } else {
            this.costPackage = null;
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    getCreditForKey(key) {
      switch (key) {
      case "Images (Duration in Days)":
        return this.totalImageCredit;
      case "Parked Images (Duration in Days)":
        return this.totalParkedImageCredit;
      case "Annotations (Duration in Days)":
        return this.totalAnnotationCredit;
      case "Training Time (Duration in Hours)":
        return this.totalTrainingCredit;
      case "Auto Annotations (Duration in Hours)":
        return this.totalAutoAnnCredit;
      case "Auto Segmentations (Duration in Hours)":
        return this.totalAutoSegCredit;
      case "Video & Binary (Duration in Day)":
        return this.totalBulkCredit;
      case "Validations (Duration in Hours)":
        return this.totalValidationCredit;
      case "Users (Duration in Days)":
        return this.totalUsersCredit;
      case "Minimum Usage":
        return this.totalServiceCharge;
      default:
        return 0;
      }
    },
    async getUsagePercentage() {
      this.doneSummaryFetch = false;
      await this.dsConn.getUsageSummaryForOrganization({ organization_id: this.orgId })
        .then((resp) => {
          if (resp.result) {
            this.numProject = resp.result.project;
            this.numDataset = resp.result.dataset;
            this.numImages = resp.result.images;
            this.numAnnotations = resp.result.annotations;
            this.numTrainingTime = resp.result.train_time;
            this.numAutoAnnotations = resp.result.autoanns;
            this.numAutoSegmentations = resp.result.autosegs;
            this.dollars = (resp.result.credits * this.costPackage['dve_credits_cost_usd']);
          }
          this.doneSummaryFetch = true;
        })
        .catch((e) => {
          console.log(e);
          this.doneSummaryFetch = true;
        });
    },
    async getDetailedDatasets() {
      if (!this.hasDetailedDatasets) {
        await this.dsConn.getDetailedDatasets({ organization_id: this.orgId })
          .then((resp) => {
            if (resp.result) {
              this.numActiveDataset = resp.result.dataset;
              this.numParkedDataset = resp.result.parked_dataset;
              this.numDeletedDataset = resp.result.deleted_dataset;
            }
          });
      }
    },
    async getDetailedImages() {
      if (!this.hasDetailedImages) {
        await this.dsConn.getDetailedImages({ organization_id: this.orgId })
          .then((resp) => {
            if (resp.result) {
              this.numActiveImages = resp.result.images;
              this.numParkedImages = resp.result.parked_images;
              this.numDeletedImages = resp.result.deleted_images;
            }
          });
      }
    },
    parseReport(data) {
      // Images
      if (this.selectMonth === this.months[new Date().getMonth()] && this.selectYear === new Date().getFullYear()) {
        this.currentDateDollarCost = 0;
      }
      const num_images_array = data.filter((e) => e.type === 'num_images');
      let reduced_num_images_array = num_images_array.reduce((acc, curr) => {
        const obj = {
          type: "Images (Duration in Days)",
          description: curr.comments,
          date: new Date(curr.date).toLocaleString('default', {
            year: 'numeric', month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric', hour12: true, timeZone: this.timeZone,
          }),
          rate: this.costPackage['images'][0]['crpu'] * this.costPackage['dve_credits_cost_usd'] > 1 ? `$${this.costPackage['images'][0]['crpu'] * this.costPackage['dve_credits_cost_usd']}/image/month`
            : `\u00A2${this.costPackage['images'][0]['crpu'] * this.costPackage['dve_credits_cost_usd'] * 100}/image/month`,
          amount: curr.amount,
          duration: `${curr.duration}d`,
          dollars: this.getCreditCostForCount('images', curr.amount, curr.duration, 1),
        };
        if (Number(obj.dollars) !== 0) {
          acc.push(obj);
        }
        return acc;
      }, []);

      // [0] is always the latest record
      reduced_num_images_array = this.appendCurrentDateRecord(reduced_num_images_array, num_images_array[0], num_images_array[num_images_array.length - 1]);
      this.numImagesRecord = reduced_num_images_array;

      const num_parked_images_array = data.filter((e) => e.type === 'parked_images' || e.type === 'parked_images_fee');
      let reduced_num_parked_images_array = num_parked_images_array.reduce((acc, curr) => {
        const obj = {
          type: "Parked Images (Duration in Days)",
          description: curr.comments,
          date: new Date(curr.date).toLocaleString('default', {
            year: 'numeric', month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric', hour12: true, timeZone: this.timeZone,
          }),
          rate: this.costPackage['images'][0]['crpu'] * this.costPackage['dve_credits_cost_usd'] > 1 ? `$${(this.costPackage['images'][0]['crpu'] * this.costPackage['dve_credits_cost_usd']) / 2}/image/month`
            : `\u00A2${((this.costPackage['images'][0]['crpu'] * this.costPackage['dve_credits_cost_usd']) / 2) * 100}/image/month`,
          amount: curr.amount,
          duration: `${curr.duration}d`,
          dollars: this.getCreditCostForCount('images', curr.amount, curr.duration, 0.5),
        };
        if (Number(obj.dollars) !== 0) {
          acc.push(obj);
        }
        return acc;
      }, []);
      reduced_num_parked_images_array = this.appendCurrentDateRecord(reduced_num_parked_images_array, num_parked_images_array[0], num_parked_images_array[num_parked_images_array.length - 1]);
      this.numParkedImagesRecord = reduced_num_parked_images_array;
      // annotations
      const num_annotations_array = data.filter((e) => e.type === 'num_annotations');
      let reduced_num_annotations_array = num_annotations_array.reduce((acc, curr) => {
        const obj = {
          type: "Annotations (Duration in Days)",
          description: curr.comments,
          date: new Date(curr.date).toLocaleString('default', {
            year: 'numeric', month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric', hour12: true, timeZone: this.timeZone,
          }),
          rate: this.costPackage['annotations'][0]['crpu'] * this.costPackage['dve_credits_cost_usd'] > 1 ? `$${this.costPackage['annotations'][0]['crpu'] * this.costPackage['dve_credits_cost_usd']}/ann./month`
            : `\u00A2${this.costPackage['annotations'][0]['crpu'] * this.costPackage['dve_credits_cost_usd'] * 100}/ann./month`,
          amount: curr.amount,
          duration: `${curr.duration}d`,
          dollars: this.getCreditCostForCount('annotations', curr.amount, curr.duration, 1),
        };
        if (Number(obj.dollars) !== 0) {
          acc.push(obj);
        }
        return acc;
      }, []);
      reduced_num_annotations_array = this.appendCurrentDateRecord(reduced_num_annotations_array, num_annotations_array[0], num_parked_images_array[num_parked_images_array.length - 1]);
      this.numAnnotationsRecord = reduced_num_annotations_array;

      // training
      const training_array = data.filter((e) => e.type === 'training_time');
      const reduced_training_array = training_array.reduce((acc, curr) => {
        const obj = {
          type: "Training Time (Duration in Hours)",
          description: curr.comments,
          date: new Date(curr.date).toLocaleString('default', {
            year: 'numeric', month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric', hour12: true, timeZone: this.timeZone,
          }),
          rate: this.costPackage['training'][0]['crpu'] * this.costPackage['dve_credits_cost_usd'] > 1 ? `$${this.costPackage['training'][0]['crpu'] * this.costPackage['dve_credits_cost_usd']}/hour`
            : `\u00A2${this.costPackage['training'][0]['crpu'] * this.costPackage['dve_credits_cost_usd'] * 100}/hour`,
          duration: `${Number(curr.duration).toFixed(3).toLocaleString()}h`,
          amount: '-',
          dollars: this.getCreditCostForCount('train', curr.duration, 0, 1),
        };
        if (Number(obj.dollars) !== 0.0) {
          acc.push(obj);
        }
        return acc;
      }, []);
      this.trainingTimeRecord = reduced_training_array;

      // autoann
      const autoann_array = data.filter((e) => e.type === 'auto_annotations');
      const reduced_autoann_array = autoann_array.reduce((acc, curr) => {
        const obj = {
          type: "Auto Annotations (Duration in Hours)",
          description: curr.comments,
          date: new Date(curr.date).toLocaleString('default', {
            year: 'numeric', month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric', hour12: true, timeZone: this.timeZone,
          }),
          rate: this.costPackage['auto_ann'][0]['crpu'] * this.costPackage['dve_credits_cost_usd'] > 1 ? `$${this.costPackage['auto_ann'][0]['crpu'] * this.costPackage['dve_credits_cost_usd']}/hour`
            : `\u00A2${this.costPackage['auto_ann'][0]['crpu'] * this.costPackage['dve_credits_cost_usd'] * 100}/hour`,
          duration: `${Number(curr.duration).toFixed(3).toLocaleString()}h`,
          amount: '-',
          dollars: this.getCreditCostForCount('auto_ann', curr.duration, 0, 1),
        };
        if (Number(obj.dollars) !== 0) {
          acc.push(obj);
        }
        return acc;
      }, []);
      this.autoAnnRecord = reduced_autoann_array;

      // autoseg
      const autoseg_array = data.filter((e) => e.type === 'auto_segmentations');
      const reduced_autoseg_array = autoseg_array.reduce((acc, curr) => {
        const obj = {
          type: "Auto Segmentations (Duration in Hours)",
          description: curr.comments,
          date: new Date(curr.date).toLocaleString('default', {
            year: 'numeric', month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric', hour12: true, timeZone: this.timeZone,
          }),
          rate: this.costPackage['auto_seg'][0]['crpu'] * this.costPackage['dve_credits_cost_usd'] > 1 ? `$${this.costPackage['auto_seg'][0]['crpu'] * this.costPackage['dve_credits_cost_usd']}/hour`
            : `\u00A2${this.costPackage['auto_seg'][0]['crpu'] * this.costPackage['dve_credits_cost_usd'] * 100}/hour`,
          duration: `${Number(curr.duration).toFixed(3).toLocaleString()}h`,
          amount: '-',
          dollars: this.getCreditCostForCount('auto_seg', curr.duration, 0, 1),
        };
        if (Number(obj.dollars) !== 0) {
          acc.push(obj);
        }
        return acc;
      }, []);
      this.autoSegRecord = reduced_autoseg_array;

      // validation
      const validation_array = data.filter((e) => e.type === 'validation');
      const reduced_validation_array = validation_array.reduce((acc, curr) => {
        const obj = {
          type: "Validations (Duration in Hours)",
          description: curr.comments,
          date: new Date(curr.date).toLocaleString('default', {
            year: 'numeric', month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric', hour12: true, timeZone: this.timeZone,
          }),
          rate: this.costPackage['validation'][0]['crpu'] * this.costPackage['dve_credits_cost_usd'] > 1 ? `$${this.costPackage['validation'][0]['crpu'] * this.costPackage['dve_credits_cost_usd']}/hour`
            : `\u00A2${this.costPackage['validation'][0]['crpu'] * this.costPackage['dve_credits_cost_usd'] * 100}/hour`,
          duration: `${Number(curr.duration).toFixed(3).toLocaleString()}h`,
          amount: '-',
          dollars: this.getCreditCostForCount('validation', curr.duration, 0, 1),
        };
        if (Number(obj.dollars) !== 0) {
          acc.push(obj);
        }
        return acc;
      }, []);
      this.validationTimeRecord = reduced_validation_array;

      // seats
      const seat_array = data.filter((e) => e.type === 'num_seats');
      const reduced_seat_array = seat_array.reduce((acc, curr) => {
        const obj = {
          type: "Users (Duration in Days)",
          description: curr.comments,
          date: new Date(curr.date).toLocaleString('default', {
            year: 'numeric', month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric', hour12: true, timeZone: this.timeZone,
          }),
          rate: this.costPackage['seats'][0]['crpu'] * this.costPackage['dve_credits_cost_usd'] > 1 ? `$${this.costPackage['seats'][0]['crpu'] * this.costPackage['dve_credits_cost_usd']}/seat/month`
            : `\u00A2${this.costPackage['seats'][0]['crpu'] * this.costPackage['dve_credits_cost_usd'] * 100}/seat/month`,
          amount: curr.amount,
          duration: `${curr.duration}d`,
          dollars: this.getCreditCostForCount('seats', curr.amount, curr.duration, 1),
        };
        if (Number(obj.dollars) !== 0) {
          acc.push(obj);
        }
        return acc;
      }, []);
      this.usersRecord = reduced_seat_array;

      // seats
      const bulk_array = data.filter((e) => e.type === 'size_bulkdata');
      const reduced_bulk_array = bulk_array.reduce((acc, curr) => {
        const obj = {
          type: "Video & Binary (Duration in Day)",
          description: curr.comments,
          date: new Date(curr.date).toLocaleString('default', {
            year: 'numeric', month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric', hour12: true, timeZone: this.timeZone,
          }),
          rate: this.costPackage['bulk_video'][0]['crpu'] * this.costPackage['dve_credits_cost_usd'] > 1 ? `$${this.costPackage['bulk_video'][0]['crpu'] * this.costPackage['dve_credits_cost_usd']}/MB/month`
            : `\u00A2${this.costPackage['bulk_video'][0]['crpu'] * this.costPackage['dve_credits_cost_usd'] * 100}/MB/month`,
          amount: curr.amount,
          duration: `${curr.duration}d`,
          dollars: this.getCreditCostForCount('video', curr.amount, curr.duration, 1),
        };
        if (Number(obj.dollars) !== 0) {
          acc.push(obj);
        }
        return acc;
      }, []);
      this.bulkRecord = reduced_bulk_array;

      // service
      const service_array = data.filter((e) => e.type === 'service');
      const reduced_service_array = service_array.reduce((acc, curr) => {
        const obj = {
          type: "Minimum Usage",
          description: curr.comments,
          date: new Date(curr.date).toLocaleString('default', {
            year: 'numeric', month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric', hour12: true, timeZone: this.timeZone,
          }),
          amount: curr.amount,
          duration: `${curr.duration}d`,
          dollars: (curr.credit_change * this.costPackage['dve_credits_cost_usd']),
        };
        if (curr.credit_change !== 0) {
          acc.push(obj);
        }
        return acc;
      }, []);
      this.serviceRecord = reduced_service_array;
    },
    appendCurrentDateRecord(array, latest, first) {
      if (!latest || !first) {
        return array;
      }
      const now = new Date();

      // latest is the most recent entry
      const latestDate = new Date(latest.date);
      // first is first entry of the month, used to get which month the current record is from
      const firstDate = new Date(first.date);

      if (now.getUTCFullYear() !== firstDate.getUTCFullYear() || now.getUTCMonth() !== firstDate.getUTCMonth()) {
        return array;
      }

      const diff = this.getDateDifferenceInDays(latestDate, now) + 1;
      let parsedEndDate = "";
      if (latest.comments) {
        parsedEndDate = latest.comments.split(' ')[latest.comments.split(' ').length - 1];
      } else {
        parsedEndDate = this.parseToYYYMMDD(latestDate);
      }
      let type = null;
      let costPkgKey = null;
      let modifier = 1;
      let unit = null;
      switch (latest.type) {
      case "num_images":
        type = "Images (Duration in Days)";
        costPkgKey = 'images';
        unit = "/image/month";
        break;
      case "num_annotations":
        type = "Annotations (Duration in Days)";
        costPkgKey = 'annotations';
        unit = "/ann./month";
        break;
      case "parked_images_fee":
      case "parked_images":
        type = "Parked Images (Duration in Days)";
        costPkgKey = 'images';
        modifier = 0.5;
        unit = "/image/month";
        break;
      default:
        break;
      }
      if (diff < 1.0) {
        return array;
      } else {
        const newRecord = {
          type,
          description: `Storage from ${parsedEndDate} to  ${this.parseToYYYMMDD(now)}`,
          date: now.toLocaleString('default', {
            year: 'numeric', month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric', hour12: true, timeZone: this.timeZone,
          }),
          rate: this.costPackage[costPkgKey][0]['crpu'] * this.costPackage['dve_credits_cost_usd'] > 1 ? `$${this.costPackage[costPkgKey][0]['crpu'] * this.costPackage['dve_credits_cost_usd'] * modifier}${unit}`
            : `\u00A2${this.costPackage[costPkgKey][0]['crpu'] * this.costPackage['dve_credits_cost_usd'] * 100 * modifier}${unit}`,
          amount: latest.amount,
          duration: `${diff}d`,
          dollars: this.getCreditCostForCount(costPkgKey, latest.amount, diff, modifier),
        };
        if (Number(newRecord.dollars) !== 0) {
          array.unshift(newRecord);
          if (this.selectMonth === this.months[new Date().getMonth()] && this.selectYear === new Date().getFullYear()) {
            this.currentDateDollarCost += newRecord.dollars;
          }
        }
        return array;
      }
    },
    parseToYYYMMDD(dateString) {
      const date = new Date(dateString);
      // Extract the year, month (0-based, so we add 1), and day
      const year = date.getUTCFullYear();
      const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // Pad single digit months with a leading zero
      const day = String(date.getUTCDate()).padStart(2, '0'); // Pad single digit days with a leading zero

      // Format the date as 'yyyy-mm-dd'
      const formattedDate = `${year}-${month}-${day}`;

      return formattedDate;
    },
    getDateDifferenceInDays(date1, date2) {
    // Parse the input dates
      const startDate = new Date(date1);
      const endDate = new Date(date2);

      // Ensure that the date objects are valid
      if (Number.isNaN(startDate) || Number.isNaN(endDate)) {
        throw new Error("Invalid date format");
      }

      // Get the time in milliseconds for both dates
      const startTime = startDate.getTime();
      const endTime = endDate.getTime();

      // Calculate the difference in milliseconds
      const differenceInMilliseconds = endTime - startTime;

      // Convert milliseconds to full days (1000 milliseconds * 60 seconds * 60 minutes * 24 hours)
      const differenceInDays = Math.floor(differenceInMilliseconds / (1000 * 60 * 60 * 24));

      return differenceInDays;
    },
    timeOut() {
      this.refreshTimeOut = true;
      setTimeout(() => { this.refreshTimeOut = false; }, 10000);
    },
    getCreditCostForCount(key, count, duration, modifier) {
      let credit_range = null;
      let prorated = 1;
      switch (key) {
      case "seats":
        credit_range = this.costPackage['seats'];
        prorated = duration / 30.0;
        break;
      case "images":
        credit_range = this.costPackage['images'];
        prorated = duration / 30.0;
        break;
      case "annotations":
        credit_range = this.costPackage['annotations'];
        prorated = duration / 30.0;
        break;
      case "video":
        credit_range = this.costPackage['bulk_video'];
        prorated = duration / 30.0;
        break;
      case "train":
        credit_range = this.costPackage['training'];
        break;
      case "auto_ann":
        credit_range = this.costPackage['auto_ann'];
        break;
      case "auto_seg":
        credit_range = this.costPackage['auto_seg'];
        break;
      case "validation":
        credit_range = this.costPackage['validation'];
        break;
      default:
        break;
      }

      return Number(-1 * count * credit_range[0]['crpu'] * this.costPackage['dve_credits_cost_usd'] * prorated * modifier).toLocaleString();
    },
  },
};
</script>

<style lang="scss" scoped>
.flex-col {
  display: flex;
  flex-direction: column;
}

.refresh-usage {
  width: fit-content;
}
.settings {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: 400px;
  height: 100%;
  padding: 30px;
  overflow-y: auto;
}

.empty-state {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  height: 100%;
}

.date-selector {
  width: 380px;
  display: flex;
  flex-direction: row;
  margin-left: 20px;
  font-size: 1rem;
  font-weight: 500;

  select + select {
    margin-left: 20px;
  }

  button {
    margin-left: 20px;
  }
}

.static-summary {
  width: 100%;
  display: grid;
  grid-gap: 30px;
  padding: 15px;
  grid-template-columns: repeat(auto-fill, 370px);
  grid-auto-rows: auto;
  border-radius: 8px;
  margin-top: 20px;
  box-shadow: 1px 1px 4px 1px rgba(0, 0, 0, 0.2), 0 0 1px 1px rgba(0, 0, 0, 0.05);
  @include themify() {
    background: themed('card-color');
    color: themed('body-text-color');
  }

  &__item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .icon +.progress {
      margin-left: 20px;
    }
  }

  &__item > .label {
    width: 200px;
    text-align: right;
    font-size: 0.9rem;
    font-weight: 600;
    color: rgba(grey, 0.9);

    &.required::after {
      content: '*';
      font-size: 14px;
      font-weight: 700;
      padding-left: 1px;
      @include themify() {
        color: themed('color-primary');
      }
    }
  }

  &__item > .value {
    flex: 1 1 auto;
    font-size: 0.9rem;
    margin-left: 20px;
  }
}

.header {
  font-size: 1.2rem;
  font-weight: 700;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.subtitle {
  font-size: 0.9rem;
}

.icon {
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
}

.progress {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;

  &__title {
    display: flex;
    width: 100%;
    font-size: 1.0rem;
    font-weight: 600;
    @include themify() {
    color: themed('body-text-color');
    }
    span {
      margin-right: 5px
    }
  }

  &__subtitle {
    display: flex;
    width: 100%;
    font-size: 1.1rem;
    font-weight: 600;
    justify-content: space-between;
    @include themify() {
    color: themed('body-text-color-secondary');
    }

    div:first-child {
    margin-bottom: 5px;
    }
  }

  &__bar {
      display: flex;
      flex-direction: column;
      width: 100%;
      margin-top: 1px;

      progress {
      width: 100%;
      border-radius: 0;
      height: 10px;

        &::-webkit-progress-bar {
            @include themify() {
            background-color: themed('progress-bar');
            }
        }
        &::-webkit-progress-value {
            @include themify() {
            background-color: themed('progress-value');
            }
        }
      }
  }

  &__estimate {
      display: flex;
      font-weight: 600;
      font-size: 0.875rem;
      @include themify() {
      color: themed('body-text-color-secondary');
      }
      margin-top: 5px;
      justify-content: space-between;
  }
}
.usage-monitor {
  .radio-group {
    display: inline-block;
    margin: 10px 0 20px 0;
    border-radius: 5px;
    overflow: hidden;
    border: solid 1px black
  }

  .radio-group input[type="radio"] {
    position: absolute;
    opacity: 0;
    pointer-events: none;
  }

  .radio-group input[type="radio"] + label {
    min-width: 6rem;
    max-width: 50%;
    text-align: center;
    display: inline-block;
    cursor: pointer;
    font-size: 0.9rem;
    line-height: 1.5;
    padding: 5px 20px;
    transition: all 0s ease;
    @include themify() {
      background: themed('card-color');
      color: themed('body-text-color');
    }
  }

  .radio-group input[type="radio"]:not(:disabled):hover + label {
    @include themify() {
      background: themed('color-primary-300');
      color: themed('primary-text-color');
    }
  }

  .radio-group input[type="radio"]:checked + label,
  .radio-group input[type="radio"]:checked:hover + label {
    opacity: 1;
    z-index: 1;
    @include themify() {
      background: themed('color-primary');
      color: themed('primary-text-color');
    }
  }

  .table-container {
    width: 100%;
    padding: 15px;
    border-radius: 8px;
    margin-top: 20px;
    box-shadow: 1px 1px 4px 1px rgba(0, 0, 0, 0.2), 0 0 1px 1px rgba(0, 0, 0, 0.05);
    @include themify() {
      background: themed('card-color');
      color: themed('body-text-color');

    }
  }

  :deep(tbody tr:nth-of-type(odd)) {
    background-color: rgba(0, 0, 0, .05);
  }

  :deep(thead > tr > th:nth-child(1)) {
    color: transparent;
    max-width: 60px;
  }
  .table-flex-end {
    display: flex;
    justify-content: end;

      span {
      font-size: 1rem;
      font-weight: 600;
    }
  }
  .total-credit-group{
    text-align: end;
    font-weight: 550;

    span {
      margin-left: auto;
      font-size: 1.0rem;
    }
  }

  .radio-group {
    display: inline-block;
    margin: 10px 0 20px 0;
    border-radius: 5px;
    overflow: hidden;
    border: solid 1px black
  }

  .radio-group input[type="radio"] {
    position: absolute;
    opacity: 0;
    pointer-events: none;
  }

  .radio-group input[type="radio"] + label {
    min-width: 6rem;
    max-width: 50%;
    text-align: center;
    display: inline-block;
    cursor: pointer;
    font-size: 0.9rem;
    line-height: 1.5;
    padding: 5px 20px;
    transition: all 0s ease;
    @include themify() {
      background: themed('card-color');
      color: themed('body-text-color');
    }
  }

  .radio-group input[type="radio"]:not(:disabled):hover + label {
    @include themify() {
      background: themed('color-primary-300');
      color: themed('primary-text-color');
    }
  }

  .radio-group input[type="radio"]:checked + label,
  .radio-group input[type="radio"]:checked:hover + label {
    opacity: 1;
    z-index: 1;
    @include themify() {
      background: themed('color-primary');
      color: themed('primary-text-color');
    }
  }
}

.added-fund {
  color: var(--color-success)
}

.unit {
  font-size: 0.85rem !important;
  font-weight: 500 !important;
}

.detail-icon {
  cursor: pointer;
  @include themify() {
    color: themed('icon-color-primary');
  }
  &:hover {
    @include themify{
      background-color: themed('icon-hover-color');
      border-radius: 100%;
      box-shadow: 0 0 0 4px themed('icon-hover-color');
    }
  }
}

.date-col {
  display: flex;
  flex-direction: row;
  justify-content: center;

  .tz-selector {
    margin-left: 5px;

    input[type="radio"] {
      display: none;
    }

    label {
      padding: 2px 5px;
      width: fit-content;
      font-size: 0.85rem;
      font-weight: bold;
      color: var(--gray-500);
      cursor: pointer;
    }

     input[type="radio"]:checked+label {
      @include themify() {
        color: themed('color-primary-500');
      }
    }
  }
}
</style>
