<template>
  <Teleport :to="'body'">
    <Modal
      ref="pricingModal"
      :width="'80%'"
      :height="'90%'"
      :title="'Deep View Enterprise Pricing'"
    >
      <template #modal-main>
        <div class="body scrollbar">
          <div class="image">
            <PriceTable :color="'black'" :maxWidth="'100%'" />
          </div>
        </div>
      </template>
    </Modal>
  </Teleport>
</template>

<script>
import { auzone_logo } from "@/assets/js/icons";

import PriceTable from '@/components/PriceTable.vue';
import Modal from './Modal.vue';

export default {
  name: 'PricingModal',
  components: {
    Modal,
    PriceTable,
  },
  data() {
    return {
      auzone_logo,
    };
  },
  methods: {
    showModal() {
      this.$refs.pricingModal.showModal();
    },
    closeModal() {
      this.$refs.pricingModal.closeModal();
    },
  },

};
</script>

<style lang="scss" scoped>

.body {
  display: flex;
  flex-direction: column;
  height: 100%;
  min-width: 450px;
  overflow: auto;
}

.image {
  display: flex;
  width: 100%;
}
.pricing {
  position: relative;
  left: -1%;
  object-fit: contain;
  min-width: 0;
  max-height: 100%;
}

.title {
  color: tint-color($accent-text-color, 45%);
  font-size: 1.1rem;
  margin-left: 20px;
  font-weight: 600;
  text-align: left;
}
.brand-name {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  line-height: 1;
  margin-bottom: 0.5rem;
  margin-top: 10px;
  margin-left: 20px;

  span {
    color: var(--color-accent);
    font-weight: 700;
    font-size: 3rem;
    text-wrap: nowrap;
  }

  span + span {
    color: var(--color-primary-500);
    font-size: 3rem;
    font-weight: 300;
    margin-left: 8px;
  }
}
</style>
