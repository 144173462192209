<template>
  <v-line
    ref="line"
    :config="{
      identifier: identifier,
      points: localPoints,
      fill: fill,
      stroke: stroke,
      opacity: opacity,
      strokeWidth: strokeWidth,
      isFocused: isFocused,
      isDragging: false,
      closed: closed,
      scaleX: scaleX,
      scaleY: scaleX,
      dash: [dashSize, dashSize],
      dashEnabled: true,
    }"
    @pointerclick="$emit('pointerclick', $event)"
    @mouseover="handleMouseover"
    @mouseout="handleMouseout"
  />
  <template v-if="isFocused && !isDragging">
    <v-circle
      v-for="(c, index) in getPolygonCoordinates(localPoints)"
      :key="index"
      :config="{
        x: c.x,
        y: c.y,
        radius: nodeRadius,
        fill: nodeFill,
        stroke: nodeStroke,
        strokeWidth: nodeStrokeWidth,
        draggable: true,
        dragBoundFunc: nodeDragBoundFunc,
      }"
      :opacity="1"
      @dragmove="handleVertexDragmove($event, index)"
      @mouseover="handleNodeMouseover"
      @mouseout="handleNodeMouseout"
    />
  </template>
</template>

<script>
import { debounce } from '@/assets/js/utils.js';

export default {
  name: 'Polygon',
  props: {
    identifier: {
      type: [String, Number],
      default: '',
    },
    imageDimensions: {
      type: Object,
      default: null,
    },
    points: {
      type: Array,
      default: () => [],
    },
    fill: {
      type: String,
      default: '#FF0000',
    },
    opacity: {
      type: Number,
      default: 0.4,
    },
    stroke: {
      type: String,
      default: '#FF0000',
    },
    strokeWidth: {
      type: Number,
      default: 4,
    },
    nodeFill: {
      type: String,
      default: 'white',
    },
    nodeStroke: {
      type: String,
      default: 'blue',
    },
    nodeStrokeWidth: {
      type: Number,
      default: 1,
    },
    closed: {
      type: Boolean,
      default: true,
    },
    draggable: {
      type: Boolean,
      default: true,
    },
    scaleX: {
      type: Number,
      default: 1,
    },
    scaleY: {
      type: Number,
      default: 1,
    },
    nodeRadius: {
      type: Number,
      default: 7,
    },
    isFocused: {
      type: Boolean,
      default: false,
    },
    dashEnabled: {
      type: Boolean,
      default: false,
    },
    dashSize: {
      type: Number,
      default: 1,
    },
    nodeDragBoundFunc: {
      type: Function,
      default: null,
    },
    polygonDragBoundFunc: {
      type: Function,
      default: null,
    },
  },
  emits: [
    'polygon-drag-end', 'polygon-drag-move', 'update:points', 'mouseover', 'mouseout', 'pointerclick', 'node-mouseover', 'node-mouseout',
  ],
  data() {
    return {
      localPoints: [],
      isDragging: false,
    };
  },
  computed: {
    debounceUpdatePoints() {
      return debounce((e) => { this.updatePoints(e); }, 50);
    },
  },
  watch: {
    points: {
      deep: true,
      handler(newPoints) {
        this.localPoints = [...newPoints];
      },
    },
  },
  mounted() {
    this.localPoints = [...this.points];
  },
  methods: {
    handleMouseover(e) {
      this.$emit('mouseover', e);
    },
    handleMouseout(e) {
      this.$emit('mouseout', e);
    },
    handleNodeMouseover(e) {
      this.$emit('node-mouseover', e);
    },
    handleNodeMouseout(e) {
      this.$emit('node-mouseout', e);
    },
    getPolygonCoordinates(points) {
      const xyArray = [];
      let id = 1;
      for (let i = 0; i < points.length; i += 2) {
        const point = {
          x: points[i],
          y: points[i + 1],
          id: `${id}`,
        };
        xyArray.push(point);
        id += 1;
      }
      return xyArray;
    },
    updatePoints() {
      this.$emit('update:points', this.localPoints);
    },
    handleVertexDragmove(e, cID) {
      this.localPoints[cID * 2] = e.target.attrs.x;
      this.localPoints[cID * 2 + 1] = e.target.attrs.y;
      this.debounceUpdatePoints(this.localPoints);
    },
    // TODO: implement dragmove with bounds: https://konvajs.org/docs/drag_and_drop/Complex_Drag_and_Drop.html
    handlePolygonDragmove(e) {
      this.isDragging = true;
      this.$emit('polygon-drag-move');
    },
    handlePolygonDragend(e) {
      const points = e.target.points();
      const transform = e.target.getTransform();
      this.isDragging = false;

      // Transform the points
      let transformedPoints = [];
      for (let i = 0; i < points.length; i += 2) {
        const point = {
          x: parseInt(points[i]),
          y: parseInt(points[i + 1]),
        };
        transformedPoints.push(transform.point(point));
      }

      // Prevent transform from causing overflowing of the image dimensions
      const minX = Math.min(...transformedPoints.map((p) => p.x));
      const maxX = Math.max(...transformedPoints.map((p) => p.x));
      const minY = Math.min(...transformedPoints.map((p) => p.y));
      const maxY = Math.max(...transformedPoints.map((p) => p.y));
      if (minX < 0) {
        transformedPoints = transformedPoints.map((p) => ({ x: p.x - minX, y: p.y }));
      }
      if (maxX > this.imageDimensions.width) {
        transformedPoints = transformedPoints.map((p) => ({ x: p.x - (maxX - this.imageDimensions.width), y: p.y }));
      }
      if (minY < 0) {
        transformedPoints = transformedPoints.map((p) => ({ x: p.x, y: p.y - minY }));
      }
      if (maxY > this.imageDimensions.height) {
        transformedPoints = transformedPoints.map((p) => ({ x: p.x, y: p.y - (maxY - this.imageDimensions.height) }));
      }

      // Create new polygon point array from the transformed points
      const newPoints = [];
      for (const val of transformedPoints) {
        newPoints.push(val.x);
        newPoints.push(val.y);
      }

      // Set new polygon points, reset the transformed position and scale
      this.localPoints = newPoints.map((p) => parseInt(p));
      e.target.position({ x: 0, y: 0 });
      e.target.scale({ x: 1, y: 1 });
      this.$emit('update:points', this.localPoints);
      this.$emit('polygon-drag-end');
    },
  },
};
</script>

<style>

</style>
