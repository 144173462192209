import {
  ref, computed, watch, onUnmounted,
} from 'vue';
import { storeToRefs } from 'pinia';
import { useDepthMapStore } from '@/stores/useDepthMapStore.js';

export default function useDepthMap() {
  const {
    depthMapFile,
    depthAnnotations,
    enableDepthMap,
  } = storeToRefs(useDepthMapStore());
  const {
    $reset,
  } = useDepthMapStore();

  onUnmounted(() => {
    $reset();
  });

  function parseDepthMapFile(file) {
    const array = [];

    return array;
  }

  return {
    depthMapFile,
    enableDepthMap,
    depthAnnotations,
    parseDepthMapFile,
  };
}
