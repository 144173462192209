<template>
  <FilterPopper>
    <template #default="{ menuOpen }">
      <BaseFilterButton
        :name="'Ignore Boxes'"
        :active="isActive"
        :tagValue="tagValue"
        :menuOpen="menuOpen"
      />
    </template>
    <template #popper>
      <div class="filter-text">Ignore Detections Smaller than</div>
      <div class="filter-settings scrollbar">
        <input
          v-model="value"
          class="select"
          type="number"
          step="0.01"
          @change="changeSanitizer"
          @input="inputSanitizer"
        >
        <span>px</span>
      </div>
      <div class="filter-footer">
        <button :disabled="!isActive" class="button-text clear-button" @click="clearSelection">Clear</button>
      </div>
    </template>
  </FilterPopper>
</template>

<script>
import FilterPopper from '@/components/FilterPopper.vue';
import BaseFilterButton from '@/components/BaseFilterButton.vue';
import { inputSanitizer, changeSanitizer, debounce } from '@/assets/js/utils.js';

export default {
  name: "IgnoreBoxesFilter",
  components: {
    FilterPopper,
    BaseFilterButton,
  },
  props: {
    modelValue: {
      type: Number,
      default: 0,
    },
    restore: {
      type: Number,
      default: 0,
    },
  },
  emits: [
    'update:modelValue',
  ],
  data() {
    return {
      value: 0,
    };
  },
  computed: {
    isActive() {
      return this.value > 0;
    },
    tagValue() {
      return this.value.toString();
    },
  },
  watch: {
    value() {
      this.debouncedUpdateFilters();
    },
    modelValue() {
      this.value = this.modelValue;
    },
    restore: {
      handler() {
        if (this.restore > 0) {
          this.value = this.restore;
        }
      },
    },
  },
  created() {
    this.debouncedUpdateFilters = debounce(this.updateFilters, 600);
  },
  mounted() {
    if (this.restore > 0) {
      this.value = this.restore;
    }
  },
  methods: {
    inputSanitizer,
    changeSanitizer,
    updateFilters() {
      this.$emit('update:modelValue', this.value);
    },
    clearSelection() {
      this.value = 0;
    },
  },
};
</script>

<style lang="scss" scoped>

.filter-text {
  text-align: center;
  font-weight: bold;
  font-size: 1.0rem;
}
.filter-settings {
  display: flex;
  flex-direction: row;
  padding: 12px;
  border-radius: inherit;
  gap: 6px;
  min-height: 50px;
  margin: 0 3px 0 3px;
  justify-content: center;
  align-items: center;
  width: 200px;
  overflow: auto;

  &__row {
    display: flex;
    align-items: center;
  }

  & input[type=checkbox] {
    margin-right: 12px;
  }

  & span {
    width: 40px;
    font-weight: bold;
    font-size: 1.0rem;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }
}

.filter-footer {
  min-height: 38px;
  width: 100%;
  border-top: 1px solid rgba(140,140,140,0.375);
  padding: 8px;
  text-align: center;

  .clear-button {
    @include themify() {
      color: themed('color-danger');
    }

    &[disabled] {
      @include themify() {
        color: themed('color-disabled');
      }
    }
  }
}

.select {
  @include themify() {
    border: 2px solid themed('body-text-color');
  }
}

.select:hover {
  @include themify() {
    border: 2px solid $color-primary-300;
  }
}
</style>
