<template>
  <Modal
    ref="createTrainerModal"
    :title="'Create New Experiment'"
  >
    <!-- <template #modal-header /> -->
    <template #modal-main>
      <div class="form container">
        <div class="row">
          <div class="col-12 settings__control-group">
            <label>Project</label>
            <span>{{ project.name }}</span>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-12 settings__control-group">
            <label class="required">Name</label>
            <input
              v-model="name"
              class="select"
              type="text"
            >
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-12 settings__control-group">
            <label>Description</label>
            <textarea
              v-model="description"
              rows="2"
            />
          </div>
        </div>
        <div v-if="message" class="row mt-3">
          <div class="col-12 settings__control-group">
            <div class="result error">
              <span>{{ message }}</span>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template #modal-footer>
      <div class="footer">
        <button
          class="button modal-action-button"
          @click="createTrainer"
        >
          Create New Experiment
        </button>
      </div>
    </template>
  </Modal>
</template>

<script>
import DatastoreConnect from '@/assets/js/DatastoreFunctions/datastore-interface';
import Modal from '@/components/Modal.vue';

export default {
  name: 'CreateTrainerModal',
  components: {
    Modal,
  },
  props: {
    trainer: {
      type: Object,
      default: () => null,
    },
    project: {
      type: Object,
      default: () => {},
    },
  },
  emits: ['trainer-created', 'trainer-updated'],
  data() {
    return {
      message: '',
      name: '',
      description: "",
    };
  },
  computed: {
    currentProject() {
      return this.$store.state.projects.currentProject;
    },
  },
  mounted() {

  },
  created() {
    this.dataConnect = new DatastoreConnect(this.$store.state.enterpriseServerUrl);
  },
  methods: {
    showModal() {
      this.$refs.createTrainerModal.showModal();
      this.initializeModal();
    },
    initializeModal() {
      this.message = '';
      if (this.trainer) {
        this.name = this.trainer.name;
        this.project_id = this.project.id;
      } else {
        this.name = '';
        this.description = '';
      }
    },
    async createTrainer() {
      if (!this.name) {
        this.message = `Missing Trainer name`;
        return;
      }

      await this.dataConnect.addTrainer({
        project_id: this.currentProject.id,
        name: this.name,
        description: this.description,
      })
        .then((data) => {
          if (data.result) {
            this.$refs.createTrainerModal.closeModal();
            this.$emit('trainer-created', data.result);
          } else if (data.error) {
            this.message = data.error.message;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};

</script>

<style lang="scss" scoped>

.form {
  padding: 0.75rem 3rem;
  min-width: 420px;

  span {
    width: 100%;
    font-size: 1rem;
    font-weight: 700;
    text-align: left;
    @include themify() {
      color: themed('color-primary');
    }
  }
}

.footer {
  display: flex;
  flex-direction: row;
  padding: 0.75rem 3rem;
  justify-content: flex-end;
}

.result {
  display: flex;
  width: 100%;
  height: 100%;
  border-radius: 5px;
  font-size: 1rem;
  font-weight:600;
  padding: 10px;
  align-items: center;
  justify-content: center;
}

.error {
  @include themify() {
    background: rgba(182, 94, 94, 0.5) ;
    color: themed('color-error')
  }
}

</style>
