<template>
  <slot name="trigger" />
  <!-- <transition :name="transition" :enter-active-class="enterActiveClass" :leave-active-class="leaveActiveClass" @after-leave="destroyPopper"> -->
  <Teleport v-if="isOpen" :disabled="!teleportEl" :to="teleportEl">
    <OnClickOutside
      ref="clickContainer"
      class="click-container"
      :options="{ignore: ignoreClickOutside}"
      v-bind="$attrs"
      @trigger="close"
    >
      <div
        v-show="isOpen"
        ref="popperEl"
        class="popper-container scrollbar"
      >
        <slot />
      </div>
    </OnClickOutside>
  </Teleport>
  <!-- </transition> -->
</template>

<script>
import { createPopper } from '@popperjs/core';
import { OnClickOutside } from '@vueuse/components';

function on(element, event, handler) {
  if (element && event && handler) {
    document.addEventListener ? element.addEventListener(event, handler, false) : element.attachEvent(`on${event}`, handler);
  }
}
function off(element, event, handler) {
  if (element && event) {
    document.removeEventListener ? element.removeEventListener(event, handler, false) : element.detachEvent(`on${event}`, handler);
  }
}

export default {
  name: 'Popper',
  components: {
    OnClickOutside,
  },
  props: {
    trigger: {
      type: String,
      default: 'click',
    },
    referenceElement: {
      type: String,
      default: null,
    },
    virtualReferenceElement: {
      type: Object,
      default: null,
    },
    appendToBody: {
      type: Boolean,
      default: false,
    },
    transition: {
      type: String,
      default: '',
    },
    delayOnMouseOver: {
      type: Number,
      default: 10,
    },
    delayOnMouseOut: {
      type: Number,
      default: 10,
    },
    config: {
      type: Object,
      default: () => ({
        placement: 'bottom',
      }),
    },
    ignoreClickOutside: {
      type: Array,
      default: () => [],
    },
    show: {
      type: Boolean,
      default: false,
    },
    teleport: {
      type: [String, HTMLElement],
      default: null,
    },
  },
  emits: ['popper-open', 'popper-closed', 'update:show'],
  data() {
    return {
      isOpen: false,
      hidden: false,
      triggerEl: null,
      popperEl: null,
      popperJS: null,
    };
  },
  computed: {
    teleportEl() {
      if (this.appendToBody) {
        return 'body';
      } else if (this.teleport) {
        return this.teleport;
      }
      return null;
    },
  },
  watch: {
    referenceElement() {
      this.setupEvents();
    },
    show(show) {
      this.setupEvents();
      // Toggle popper if required
      if (!this.isOpen && show) {
        this.open();
      } else if (this.isOpen && !show) {
        this.close();
      }
    },
  },
  beforeUnmount() {
    if (!this.popperEl) {
      return;
    }

    this.destroyPopper();
  },
  mounted() {
    this.setupEvents();
    if (!this.isOpen && this.show) {
      this.open();
    }
  },
  methods: {
    setupTriggerEvents() {
      if (!this.referenceElement && !this.virtualReferenceElement) {
        this.triggerEl = this.$el.nextElementSibling;
      } else if (this.referenceElement) {
        this.triggerEl = document.getElementById(this.referenceElement);
      }

      if (this.triggerEl) {
        switch (this.trigger) {
        case 'clickToOpen':
          on(this.triggerEl, 'click', this.doShow);
          break;
        case 'click': // Same as clickToToggle, provided for backwards compatibility.
        case 'clickToToggle':
          on(this.triggerEl, 'click', this.togglePopper);
          break;
        case 'hover':
          on(this.triggerEl, 'mouseover', this.onMouseOver);
          on(this.triggerEl, 'mouseout', this.onMouseOut);
          break;
        case 'focus':
          on(this.triggerEl, 'focus', this.onMouseOver);
          on(this.triggerEl, 'blur', this.onMouseOut);
          break;
        case 'show':
          break;
        default:
        }
      }
    },
    setupPopperEvents() {
      this.popperEl = this.$refs.popperEl;

      if (this.popperEl) {
        switch (this.trigger) {
        case 'clickToOpen':
          break;
        case 'click': // Same as clickToToggle, provided for backwards compatibility.
        case 'clickToToggle':
          break;
        case 'hover':
          on(this.popperEl, 'mouseover', this.onMouseOver);
          on(this.popperEl, 'mouseout', this.onMouseOut);
          break;
        case 'focus':
          on(this.popperEl, 'focus', this.onMouseOver);
          on(this.popperEl, 'blur', this.onMouseOut);
          break;
        case 'show':
          break;
        default:
        }
      }
    },
    setupEvents() {
      this.setupTriggerEvents();
    },
    togglePopper() {
      if (!this.isOpen) {
        this.open();
      } else {
        this.close();
      }
    },
    onMouseOver() {
      clearTimeout(this._timer);
      this._timer = setTimeout(() => {
        this.open();
      }, this.delayOnMouseOver);
    },
    onMouseOut() {
      clearTimeout(this._timer);
      this._timer = setTimeout(() => {
        this.close();
      }, this.delayOnMouseOut);
    },
    async open() {
      if (this.isOpen) {
        return;
      }

      this.isOpen = true;
      await this.$nextTick();
      this.setupPopperEvents();
      let referenceElement = this.triggerEl;
      if (this.virtualReferenceElement) {
        referenceElement = this.virtualReferenceElement;
      }
      this.popperJS = createPopper(referenceElement, this.popperEl, this.config);

      this.$emit('update:show', true);
      this.$emit('popper-open');
    },
    close(e) {
      if (!this.isOpen
          || (e && (this.triggerEl === e.target || this.triggerEl?.contains(e.target)))) {
        return;
      }
      this.destroyPopper();

      this.isOpen = false;
      this.$emit('update:show', false);
      this.$emit('popper-closed');
    },
    update() {
      this.setupEvents();
      this.popperJS.update();
    },
    destroyPopper() {
      // off(this.referenceElm, 'click', this.togglePopper);
      // off(this.referenceElm, 'mouseup', this.doClose);
      // off(this.referenceElm, 'mousedown', this.doShow);
      // off(this.referenceElm, 'focus', this.doShow);
      // off(this.referenceElm, 'blur', this.doClose);
      // off(this.referenceElm, 'mouseout', this.onMouseOut);
      // off(this.referenceElm, 'mouseover', this.onMouseOver);
      // off(document, 'click', this.handleDocumentClick);

      if (this.popperJS) {
        this.popperJS.destroy();
        this.popperJS = null;
      }
    },
    handleDocumentClick() {},
  },
};
</script>

<style lang="scss" scoped>
.click-container {
  position: absolute;
}
.popper-container {
  display: flex;
  opacity: 1;
  z-index: 1000;
}
</style>
