<template>
  <!-- eslint-disable max-len -->
  <div
    v-if="localShow"
    ref="modal"
    class="backdrop"
    :style="{ 'z-index': zIndex }"
    tabindex="-1"
    @mousedown="mousedown"
    @mouseup="mouseup"
    @keyup.esc="handleEscapeKey"
  >
    <div
      class="modal"
      :class="{'full-screen': fullScreen, pop: isPopping}"
      :style="{ width: width, height: height }"
      @click.stop=""
    >
      <div class="modal-header">
        <slot name="modal-header-prepend" />
        <h2 v-if="title" :title="title" class="mt-0 mb-0">{{ title }}</h2>
        <slot v-else name="modal-header-title-description" />
        <slot name="modal-header" />
        <IconButton
          class="close-modal-btn"
          :icon="'close'"
          :width="28"
          :height="28"
          @click="closeModal"
        />
      </div>
      <div class="modal-container scrollbar">
        <div class="modal-main">
          <slot name="modal-main" />
        </div>
      </div>
      <div class="modal-footer">
        <slot name="modal-footer" />
        <slot name="modal-actions" class="modal-actions" />
      </div>
    </div>
  </div>
</template>

<script>
import IconButton from '@/components/IconButton.vue';
import { useFocusTrap } from '@vueuse/integrations/useFocusTrap';
import { ref } from 'vue';

export default {
  name: 'Modal',
  components: {
    IconButton,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: null,
    },
    isPluginModal: {
      type: Boolean,
      default: false,
    },
    width: {
      type: String,
      default: undefined,
    },
    height: {
      type: String,
      default: undefined,
    },
    padding: {
      type: String,
      default: '20px',
    },
    fullScreen: {
      type: Boolean,
      default: false,
    },
    closeWithEscapeKey: {
      type: Boolean,
      default: true,
    },
    zIndex: {
      type: Number,
      default: null,
    },
    persistent: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:show', 'opened', 'closed'],
  setup() {
    const modal = ref(null);
    const { activate: activateFocusTrap, deactivate: deactivateFocusTrap } = useFocusTrap(modal, { immediate: false, allowOutsideClick: true });

    return {
      modal,
      activateFocusTrap,
      deactivateFocusTrap,
    };
  },
  data() {
    return {
      localShow: false,
      mousedownOnBackdrop: false,
      isPopping: false,
    };
  },
  watch: {
    show(shouldShow) {
      if (shouldShow && !this.localShow) {
        this.localShow = true;
      }
    },
    localShow(isShown) {
      if (isShown) {
        this.$nextTick(() => {
          this.activateFocusTrap();
          this.modal.focus();
        });
      } else {
        this.$nextTick(() => {
          this.deactivateFocusTrap();
        });
      }
      this.$emit('update:show', isShown);
    },
  },
  mounted() {
    if (this.show && !this.localShow) {
      this.localShow = true;
    }
  },
  methods: {
    showModal() {
      this.localShow = true;
      this.$emit('opened');
      this.$nextTick(() => {
        this.$refs.modal.focus();
      });
    },
    focus() {
      this.$nextTick(() => {
        this.$refs.modal.focus();
      });
    },
    closeModal() {
      this.localShow = false;
      this.$emit('closed');
    },
    handleEscapeKey() {
      if (this.closeWithEscapeKey) {
        this.closeModal();
      }
    },
    mousedown(e) {
      this.mousedownOnBackdrop = Boolean(e.srcElement === this.$refs.modal);
    },
    mouseup(e) {
      // Prevents drag originating from the modal to the backdrop from closing it
      const isDescendantOfModal = this.$refs.modal.contains(e.target);
      if (isDescendantOfModal && this.mousedownOnBackdrop) {
        if (this.persistent) {
          this.startPopAnimation();
        } else {
          this.closeModal();
        }
      }
      this.mousedownOnBackdrop = false;
    },
    startPopAnimation() {
      this.isPopping = true;
      setTimeout(() => {
        this.isPopping = false;
      }, 150); // Stop the wiggle after 1 second
    },
  },
};
</script>

<style lang="scss" scoped>
$mainPadding: 24px;
$mainPaddingMobile: 16px;

.backdrop {
  position: fixed;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.625);
  outline: none;
  z-index: 1000;
}

.modal {
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  max-height: calc((100% - 6px) - 2em);
  width: min-content;
  height: min-content;
  overflow: hidden;

  color: black;
  text-align: center;
  margin: auto;
  border: none;
  padding: 0;
  border-radius: 6px;
  box-shadow: 0 0 35px rgba(0,0,0,0.1), 0 0 10px rgba(0,0,0,0.25);
  max-width: calc((100% - 6px) - 2em);
  z-index: 500;
  cursor: default;
  @include themify() {
    background-color: themed('modal-background');
  }

  &.full-screen {
    max-width: 100vw;
    max-height: 100vh;
    width: 100%;
    height: 100%;
    border-radius: 0;
  }
}

.modal-container {
  display: flex;
  justify-content: space-between;
  flex-flow: column;
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.modal-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 24px;
  padding: 8px 16px;
  border-radius: 6px 6px 0 0;
  border-bottom: 1px solid grey;
  @include themify() {
    background: linear-gradient(45deg, themed('color-primary-600') 0%, themed('color-primary') 100%);
    color: themed('modal-header-text-color');
  }
}

.modal-header h2:first-of-type {
  text-align: left;
  white-space: nowrap;
  text-overflow: ellipsis;
  min-width: 100px;
  overflow: hidden;
  flex: 1 1 fit-content;
  max-width: fit-content;
}

.close-modal-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  background: none;
  border: none;
  border-radius: 4px;
  margin-left: auto;
  cursor: pointer;
}

.close-modal-btn:hover {
  background: rgba(128, 128, 128, 0.25);
}

.modal-main {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  overflow: hidden;
  position: relative;
}

@media (max-width: $breakpoint-sm) {
  .modal {
    width: 100% !important;
    height: 100% !important;
    max-width: 100%;
    max-height: 100%;
    margin: 0;
  }

  .modal-container {
    padding: 0 0 0 16px;
      margin: 16px 0;
  }

  .modal-header {
    padding: 16px;
  }
}

.pop {
  animation: pop 0.15s ease-in-out;
}
@keyframes pop {
  50%  {transform: scale(1.025);}
}
</style>
