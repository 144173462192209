<template>
  <div class="settings scrollbar account">
    <span class="header">User Information</span>
    <div class="row mt-3">
      <div class="label">Username</div>
      <div class="value">{{ user.username }}</div>
    </div>
    <div v-if="user.first_name" class="row mt-3">
      <div class="label">First Name</div>
      <div class="value">{{ user.first_name }}</div>
    </div>
    <div v-if="user.last_name" class="row mt-3">
      <div class="label">Last Name</div>
      <div class="value">{{ user.last_name }}</div>
    </div>
    <div class="row mt-2">
      <div class="label">Email</div>
      <div class="value">{{ user.email }}</div>
    </div>
    <div v-if="orgName" class="row mt-2">
      <div class="label">Organization</div>
      <div class="value">{{ orgName }}</div>
    </div>
    <div v-if="user.role" class="row mt-2">
      <div class="label">User Permission</div>
      <div class="value">{{ user.role.charAt(0).toUpperCase() + user.role.slice(1) }}</div>
    </div>
    <div v-if="bucket" class="row mt-2">
      <div class="label">S3 Bucket</div>
      <div class="value">{{ bucket }}</div>
    </div>
  </div>
</template>

<script>
import DatastoreConnect from '@/assets/js/DatastoreFunctions/datastore-interface';

export default {
  name: 'UserProfile',
  components: {
  },
  data() {
    return {
      dsConn: new DatastoreConnect(this.$store.state.enterpriseServerUrl),
      allRoles: [],
      bucket: null,
      orgName: '',
    };
  },
  computed: {
    user() {
      return this.$store.state.user.user;
    },
    isAdmin() {
      return this.user && this.user.role === 'admin';
    },
  },
  created() {
    this.getAllRoles();
    this.getOrg();
  },
  mounted() {
    this.getUserBucket();
  },
  methods: {
    async getOrg() {
      await this.dsConn.getOrg({ organization_id: this.user.organization_id })
        .then((data) => {
          if (data.result) {
            this.orgName = data.result.name;
          } else {
            this.message = data.error.message;
          }
        })
        .catch((e) => { this.message = e; });
    },
    async getUserBucket() {
      await this.dsConn.getUserBucket({
        username: this.user.username,
      }).then((data) => {
        if (data.result) {
          this.bucket = data.result;
        }
      }).catch((e) => { console.log(e); });
    },
    async getAllRoles() {
      await this.dsConn.getAllRoles()
        .then((data) => {
          this.allRoles = data.result;
        })
        .catch((e) => console.log(e));
    },
    editUser() {
      this.$router.push({ path: '/user/account/edit', query: { editUser: this.user.username } });
    },
  },
};
</script>

<style lang="scss" scoped>
.settings {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 30px;
  overflow-y: auto;
}

.header {
  font-size: 1.2rem;
  font-weight: 700;
}

.row {
  flex-wrap: nowrap !important;
}

.row > .label {
  display: flex;
  justify-content: end;
  align-items: center;
  width: 200px;
  text-align: right;
  font-size: 1.0rem;
  font-weight: 600;
  color: rgba(grey, 1.0)
}

.row > .value {
  flex: 1 1 auto;
  font-size: 1.0rem;
  margin-left: 20px;
}

.footer {
  display: flex;
  justify-content: flex-start;
  button {
    margin-left: 30px;
    width: fit-content
  }
}
</style>
