<template>
  <!-- eslint-disable max-len -->
  <BaseCard
    :id="`reviewTask-${task.id}`"
    ref="card"
    :title="`${task.name}`"
    class="reviewTask-card"
  >
    <template #header>
      <BaseMenu
        v-if="hasPermission"
        :placement="'bottom-end'"
        @closed="show_menu = false"
      >
        <IconButton
          v-model="show_menu"
          :icon="'more_vert'"
          :width="24"
          :height="24"
        />
        <template #menu="{closeMenu}">
          <ul class="reviewTask-menu">
            <li v-if="task.source_annotation_set_id">
              <BaseMenuButton @click.stop="handleStartAnnotationAudit(), closeMenu()">
                <SVGIcon
                  :iconName="'tag'"
                  :width="'22px'"
                  :height="'22px'"
                  :title="`Start Annotation Audit`"
                />
                <span>Start Annotation Audit</span>
              </BaseMenuButton>
            </li>
            <li v-if="isAdmin">
              <BaseMenuButton @click.stop="handleManageAccess(), closeMenu()">
                <SVGIcon
                  :iconName="'people'"
                  :width="'22px'"
                  :height="'22px'"
                />
                <span>Manage Access</span>
              </BaseMenuButton>
            </li>
            <li>
              <BaseMenuButton class="_danger" @click.stop="handleDeleteReviewTaskClicked(), closeMenu()">
                <SVGIcon
                  :iconName="'trash'"
                  :width="'22px'"
                  :height="'22px'"
                />
                <span>Remove</span>
              </BaseMenuButton>
            </li>
          </ul>
        </template>
      </BaseMenu>
    </template>
    <template #body>
      <!-- <span class="description">{{ task.description }}</span> -->
      <BaseTextExpander v-if="task.description" :text="task.description" />
      <div class="dataset-source-destination mt-2">
        <div class="dataset-source-destination__container">
          <SVGIcon
            class="dataset-source-destination__icon"
            :iconName="'database'"
            :width="'18px'"
            :height="'18px'"
          />
          <div class="dataset-source-destination__value">
            <span :title="sourceDataset?.name">{{ sourceDataset?.name }}</span>
          </div>
        </div>
        <div class="dataset-source-destination__container">
          Dest
          <SVGIcon
            class="dataset-source-destination__icon"
            :iconName="'set'"
            :width="'18px'"
            :height="'18px'"
          />
          <div class="dataset-source-destination__value">
            <span :title="destAnnSet?.name">{{ destAnnSet?.name }}</span>
          </div>
        </div>
        <div class="dataset-source-destination__container">
          <template v-if="task.source_annotation_set_id">
            Src
            <SVGIcon
              class="dataset-source-destination__icon"
              :iconName="'set'"
              :width="'18px'"
              :height="'18px'"
            />
            <div class="dataset-source-destination__value">
              <span :title="sourceAnnSet?.name">{{ sourceAnnSet?.name }}</span>
            </div>
          </template>
        </div>
      </div>
      <div class="reviewTask-card__body-row mt-1">
        <div class="progress">
          <div class="progress__text">
            <span>Progress</span>
            <span>{{ progress !== null ? progress : '-' }}%</span>
          </div>
          <div class="progress__bar">
            <progress id="reviewTask-progress-bar" :value="progress" :max="100" />
          </div>
        </div>
      </div>
      <div class="reviewTask-card__body-row mt-1">
        <div class="reviewTask-counts">
          <span>Remaining</span>
          <SVGIcon
            :iconName="'image'"
            :width="'18px'"
            :height="'18px'"
          />
          <span>{{ numImagesRemaining !== null ? numImagesRemaining : '-' }}</span>
        </div>
      </div>
      <div class="reviewTask-card__body-row mt-2">
        <div class="reviewers">
          <div
            v-for="(user, i) in reviewers"
            :key="`${user.username}-${i}`"
            class="reviewers__icon"
          >
            <Tooltip :background="'gray'">
              <template #body>
                <p>{{ user.first_name && user.last_name ? `${user.first_name} ${user.last_name}` : user.username }}</p>
              </template>
              <SVGIcon
                :iconName="'person'"
                :width="'100%'"
                :height="'100%'"
              />
            </Tooltip>
          </div>
        </div>
      </div>
    </template>
  </BaseCard>
</template>
<script>
import IconButton from '@/components/IconButton.vue';
import SVGIcon from '@/components/SVGIcon.vue';
import Tooltip from '@/components/Tooltip.vue';
import BaseCard from '@/components/BaseCard.vue';
import BaseMenu from '@/components/BaseMenu.vue';
import BaseMenuButton from '@/components/BaseMenuButton.vue';
import BaseTextExpander from '@/components/BaseTextExpander.vue';
import { decimalAdjust } from "@/assets/js/utils";
import DatastoreConnect from '@/assets/js/DatastoreFunctions/datastore-interface';

export default {
  name: "ReviewTaskCard",
  components: {
    IconButton,
    SVGIcon,
    Tooltip,
    BaseCard,
    BaseMenu,
    BaseMenuButton,
    BaseTextExpander,
  },
  props: {
    task: {
      type: Object,
      default: () => {},
    },
    hasPermission: {
      type: Boolean,
      default: false,
    },
    datasets: {
      type: Array,
      default: () => [],
    },
  },
  emits: ['delete-review-task', 'open-manage-access', 'start-annotation-review-task'],
  data() {
    return {
      dsConn: new DatastoreConnect(this.$store.state.enterpriseServerUrl),
      numAnnotationsRemaining: '?',
      show_menu: false,
      reviewers: [],
    };
  },
  computed: {
    user() {
      return this.$store.state.user.user;
    },
    isAdmin() {
      return this.user && (this.user.role === 'super' || this.user.role === 'admin');
    },
    remainingCount() {
      if (this.task && this.task.remainingCount) {
        return this.task.remainingCount;
      } else {
        return null;
      }
    },
    cardActionText() {
      if (this.progress === 0) {
        return 'Start reviewing';
      }
      return 'Continue review';
    },
    progress() {
      if (this.remainingCount && this.remainingCount.remaining_images > 0) {
        return decimalAdjust("floor", (1 - (this.remainingCount.remaining_images / this.remainingCount.total_images)) * 100, -1);
      } else {
        return 100;
      }
    },
    numImagesRemaining() {
      if (this.remainingCount) {
        return this.remainingCount.remaining_images;
      }
      return null;
    },
    sourceDataset() {
      const datasets = this.$store.state.datasets.datasetList;
      const dataset_id = this.task.dataset_id;
      const dataset = datasets.find((d) => d.id === dataset_id);
      return dataset;
    },
    destAnnSet() {
      if (this.sourceDataset) {
        const dest_annotation_set_id = this.task?.dest_annotation_set_id;
        return this.sourceDataset.annotation_sets.find((as) => as.id === dest_annotation_set_id);
      }
      return null;
    },
    sourceAnnSet() {
      if (this.sourceDataset) {
        const source_annotation_set_id = this.task?.source_annotation_set_id;
        const sourceAnnotationSet = this.sourceDataset.annotation_sets.find((as) => as.id === source_annotation_set_id);
        if (sourceAnnotationSet) {
          return sourceAnnotationSet;
        }
      }
      return null;
    },
  },
  mounted() {
    this.getReviewTaskAccessList();
  },
  methods: {
    async getReviewTaskAccessList() {
      if (this.task) {
        await this.dsConn.getReviewTaskAccessList({
          review_task_id: this.task.id,
        })
          .then((data) => {
            if (data.result) {
              this.reviewers = data.result;
            }
          })
          .catch((e) => console.log(e));
      }
    },
    getStyle(type) {
      switch (type) {
      case 'label':
        return 'label';
      case 'audit':
        return 'audit';
      case 'curate':
        return 'curate';
      default:
        return '';
      }
    },
    getString(type) {
      switch (type) {
      case 'label':
        return 'LABEL';
      case 'audit':
        return 'AUDIT';
      case 'curate':
        return 'CURATE';
      default:
        return '';
      }
    },
    handleDeleteReviewTaskClicked() {
      this.$emit('delete-review-task');
    },
    handleManageAccess() {
      this.$emit('open-manage-access');
    },
    handleStartAnnotationAudit() {
      this.$emit('start-annotation-review-task');
    },
  },
};
</script>

<style lang="scss" scoped>

.task-type {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 18px;
  padding: 4px 6px;
  line-height: 1;
  font-size: 0.7rem;
  font-weight: 800;
  letter-spacing: 0.05rem;
  width: fit-content;
  border: 2px solid;
  margin: 5px 0 5px 0;
  box-shadow: 0px 0px 1px rgb(0 0 0 / 25%), 0px 1px 4px rgb(0 0 0 / 25%);
  @include themify() {
    color: themed('color-black');
  }
}
.audit {
  color: rgb(0, 162, 255) !important;
  border: 2px solid rgb(0, 162, 255) !important;
}

.curate {
  color: rgb(245, 200, 2) !important;
  border: 2px solid rgb(245, 200, 2) !important;
}

.label {
  color: rgb(99, 22, 163) !important;
  border: 2px solid rgb(99, 22, 163) !important;
}

.description {
  margin: 5px 0 5px 0;
  font-size: 1.1rem;
  font-weight: 600;
}

.reviewTask-card {
  cursor: pointer;
  &__body-row {
    display: flex;
    flex: 1 1 auto;
  }
}

.reviewers {
  display: flex;
  flex-direction: row;
  height: 28px;

  &__icon {
    width: 28px;
    height: 28px;
    border-radius: 100%;
    @include themify() {
      border: solid 2px themed('color-primary-500');
    }

    @include themify() {
      background: themed('color-primary-100');
      color: themed('color-primary-500');
    }

    &:not(:first-of-type) {
      margin-left: -8px;
    }
    &:nth-last-of-type(odd) {
      @include themify() {
        background: themed('color-primary-200');
        color: themed('color-primary-500');
      }
    }
  }
}

.progress {
  display: flex;
  flex-direction: column;
  width: 100%;

  &__text {
    display: flex;
    width: 100%;
    font-size: 0.875rem;
    font-weight: 600;
    justify-content: space-between;
    @include themify() {
      color: themed('body-text-color-secondary');
    }
  }

  &__bar {
    display: flex;
    width: 100%;
    margin-top: 1px;

    progress {
      width: 100%;
      border-radius: 0;
      height: 6px;

      &::-webkit-progress-bar {
        @include themify() {
          background-color: themed('progress-bar');
        }
      }
      &::-webkit-progress-value {
        @include themify() {
          background-color: themed('progress-value');
        }
      }
    }
  }
}

.reviewTask-counts {
  display: flex;
  align-items: center;
  line-height: 1;
  font-size: 0.875rem;
  font-weight: 600;
  @include themify() {
    color: themed('body-text-color-secondary');
  }

  span:first-of-type {
    margin-right: 8px;
  }

  span:not(:first-of-type) {
    margin-left: 2px;
  }

  svg:not(:first-of-type) {
    margin-left: 8px;
  }
}

.reviewTask-menu {
  list-style-type: none;
  margin: 0;
  padding: 0;
  min-width: 100px;
}

.reviewTask-action-button {
  width: 100%;
  font-size: 0.875rem;
  font-weight: 500 ;
  outline: none;
  border: none;
  padding: 4px;
  cursor: pointer;

  @include themify() {
    background: themed('color-primary');
    color: themed('primary-text-color');
  }
}

.dataset-source-destination {
  display: flex;
  flex-direction: row;
  width: 100%;
  border-radius: 8px;

  &__container {
    display: flex;
    flex-direction: row;
    width: 100%;
    font-size: 0.75rem;
    font-weight: 600;
    color: var(--color-primary);
    align-items: flex-start;
    white-space: nowrap;
    max-width: 33%;
  }

  &__icon {
    min-width: 18px;
  }

  &__value {
    display: flex;
    flex-direction: row;
    font-size: 0.75rem;
    font-weight: 600;
    color: var(--body-text-color-secondary);
    white-space: nowrap;
    gap: 4px;
    margin: 0 8px 0 4px;
    overflow: hidden;

    span {
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
</style>
