<template>
  <v-rect
    v-for="box in Boxes2D"
    :key="box"
    :config="{
      x: box.x,
      y: box.y,
      width: box.width,
      height: box.height,
      stroke: '#250E81',
      strokeWidth: borderWidth,
    }"
  />
  <v-rect
    v-for="box in Boxes2D"
    :key="box"
    :config="{
      x: box.x,
      y: box.y,
      width: box.width,
      height: box.height,
      stroke: box.stroke,
      strokeWidth: borderWidth / 2,
    }"
  />
</template>

<script>

import useAnnotationColorMap from '@/composables/useAnnotationColorMap.js';
import AnnotationBox from './AnnotationBox.vue';

export default {
  name: "Project3Dto2D",
  components: {
    AnnotationBox,
  },
  props: {
    boxes3D: {
      type: Array,
      default: () => [],
    },
    imageDimensions: {
      type: Object,
      default: () => ({ width: 600, height: 300 }),
    },
    stageScale: {
      type: Object,
      default: () => ({ x: 1, y: 1 }),
    },
    labels: {
      type: Array,
      default: () => [],
    },
  },
  emits: [

  ],
  setup(props) {
    const { map: labelColorMap } = useAnnotationColorMap({ items: props.labels, key: 'index' });
    return { labelColorMap };
  },
  data() {
    return {
      node: null,
      isBusy: false,
      isMouseOver: false,
    };
  },
  computed: {
    borderWidth() {
      return 4 / this.stageScale.x;
    },
    Boxes2D() {
      const b3array = [];
      let b2array = [];

      // this.test();
      // return;

      for (let k = 0; k < this.boxes3D.length; k++) {
        const b3 = this.boxes3D[k];
        const bb3 = [0, b3.data_json.x, b3.data_json.y, b3.data_json.z, b3.data_json.dx, b3.data_json.dy, b3.data_json.dz];
        b3array.push(bb3);
      }

      if (b3array.length > 0) {
        b2array = this.project_boxes(b3array, this.imageDimensions);

        for (let k = 0; k < b2array.length; k++) {
          const b2 = b2array[k];
          b2.stroke = this.labelColorMap[this.boxes3D[k].label_index];
        }
      }

      return b2array;
    },
  },
  mounted() {
  },
  methods: {

    test() {
      const inp = [
        [0, 9.641414, -0.612911, 1.2554842, 0.70922685, 0.7092271, 1.8080416],
        [0, 3.641414, -0.412911, 3.2554842, 0.20922685, 0.2092271, 2.8080416],
        [0, 3.641414, -0.412911, 3.2554842, 0.5, 0.5, 0.5],
      ];

      let output; const sizes = this.project_boxes(inp);
      console.log(output, sizes);
    },
    transpose(matrix) {
      return matrix[0].map((col, c) => matrix.map((row, r) => matrix[r][c]));
    },

    MultRow(a, r, m) {
      const nr = a.length; const
        nc = a[0].length;
      for (let c = 0; c < nc; c++) {
        a[r][c] = a[r][c] * m;
      }
      return a;
    },
    NormalizeByLastRow(a) {
      const nr = a.length; const
        nc = a[0].length;
      for (let r = 0; r < nr; r++) {
        for (let c = 0; c < nc; c++) {
          a[r][c] = a[r][c] / a[nr - 1][c];
        }
      }
      return a;
    },
    maximum(a, b) {
      const d = [];
      const nr = a.length; const
        nc = a[0].length;
      for (let r = 0; r < nr; r++) {
        d[r] = [];
        for (let c = 0; c < nc; c++) {
          d[r][c] = Math.max(a[r][c], b[r][c]);
        }
      }
      return d;
    },
    minimum(a, b) {
      const nr = a.length; const
        nc = a[0].length;
      const d = [];
      for (let r = 0; r < nr; r++) {
        d[r] = [];
        for (let c = 0; c < nc; c++) {
          d[r][c] = Math.min(a[r][c], b[r][c]);
        }
      }
      return d;
    },
    subtract(a, b) {
      const nr = a.length; const
        nc = a[0].length;
      const d = [];
      for (let r = 0; r < nr; r++) {
        d[r] = [];
        for (let c = 0; c < nc; c++) {
          d[r][c] = a[r][c] - b[r][c];
        }
      }
      return d;
    },
    DropLastRow(a) {
      const nr = a.length; const
        nc = a[0].length;
      const b = [];
      for (let r = 0; r < nr - 1; r++) {
        b[r] = [];
        for (let c = 0; c < nc; c++) {
          b[r][c] = a[r][c];
        }
      }
      return b;
    },

    multiply(a, b) {
      const aNumRows = a.length; const aNumCols = a[0].length;
      const bNumRows = b.length; const bNumCols = b[0].length;
      const m = new Array(aNumRows); // initialize array of rows
      for (let r = 0; r < aNumRows; ++r) {
        m[r] = new Array(bNumCols); // initialize the current row
        for (let c = 0; c < bNumCols; ++c) {
          m[r][c] = 0; // initialize the current cell
          for (let i = 0; i < aNumCols; ++i) {
            m[r][c] += a[r][i] * b[i][c];
          }
        }
      }
      return m;
    },

    getCol(matrix, col) {
      const column = [];
      for (let i = 0; i < matrix.length; i++) {
        column.push(matrix[i][col]);
      }
      return column;
    },

    project_boxes(bb3d, imageDimensions) {
      if (imageDimensions == null) return [];

      const width = imageDimensions.width;
      const height = imageDimensions.height;

      /*
    Projects 3D bounding boxes from real world coordinates into standard
    2D image coordinates.

    Keyword arguments:
    bb3d -- The n x 7 bounding box from the annotation file.
            The format is (label, z, x, y, depth, width, height).
    width -- the width of the output image (default 1920)
    height -- the height of the output image (default 1080)

    */

      // Example:
      /*
    project_boxes(np.array([
        [0, 9.641414, -0.612911, 1.2554842, 0.70922685, 0.7092271, 1.8080416],
        [0, 9.641414, -0.612911, 1.2554842, 0.70922685, 0.7092271, 1.8080416]
    ]))
    */

      // Camera matrix that produces bounding boxes in 1920x1080 pixel coordinates
      const cam_mtx = [
        [1260 / 1920, 0, 960 / 1920],
        [0, 1260 / 1080, 540 / 1080],
        [0, 0, 1],
      ];

      // Converts lzxydwh (label, z, x, y, depth, width, height) coulmn vector into
      // (x_min, y_min, z_min) column vector
      const zxydwh2xyzmin = [
        [0, 0, 1, 0, 0, -0.5, 0],
        [0, 0, 0, 1, 0, 0, -0.5],
        [0, 1, 0, 0, 0, 0, 0],
      ];

      // Converts lzxydwh (label, z, x, y, depth, width, height) coulmn vector into
      // (x_max, y_max, z_max) column vector
      const zxydwh2xyzmax = [
        [0, 0, 1, 0, 0, 0.5, 0],
        [0, 0, 0, 1, 0, 0, 0.5],
        [0, 1, 0, 0, 0, 0, 0],
      ];

      // Converts (x, y, 1) column vector from camera coordinate system to the image
      // coordinate system.
      const coord_cnvt = [
        [-1, 0, 1],
        [0, -1, 1],
        [0, 0, 1],
      ];

      const xyzmin = this.multiply(zxydwh2xyzmin, this.transpose(bb3d));
      let bbmin = this.multiply(cam_mtx, xyzmin);
      bbmin = this.NormalizeByLastRow(bbmin);
      bbmin = this.multiply(coord_cnvt, bbmin);
      bbmin = this.DropLastRow(bbmin);
      bbmin = this.MultRow(bbmin, 0, width);
      bbmin = this.MultRow(bbmin, 1, height);

      const xyzmax = this.multiply(zxydwh2xyzmax, this.transpose(bb3d));
      let bbmax = this.multiply(cam_mtx, xyzmax);
      bbmax = this.NormalizeByLastRow(bbmax);
      bbmax = this.multiply(coord_cnvt, bbmax);
      bbmax = this.DropLastRow(bbmax);
      bbmax = this.MultRow(bbmax, 0, width);
      bbmax = this.MultRow(bbmax, 1, height);

      const bbmax_ = this.maximum(bbmax, bbmin);
      bbmin = this.minimum(bbmax, bbmin);
      let sizes = this.subtract(bbmax_, bbmin);

      bbmin = this.transpose(bbmin);
      sizes = this.transpose(sizes);

      const boxes = [];
      for (let k = 0; k < bbmin.length; k++) {
        const b2 = {
          x: bbmin[k][0],
          y: bbmin[k][1],
          width: sizes[k][0],
          height: sizes[k][1],
        };

        boxes.push(b2);
      }

      return boxes;
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
