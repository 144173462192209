<template>
  <div class="controls-bar">
    <span />
    <button class="button button-sm" @click="openCreateTaskModal">Create</button>
  </div>
  <div v-if="!loading" class="reviewTask__container">
    <div class="reviewTask__row">
      <div class="col-sm task-column">
        <div class="task-column__header">
          <span>Open</span>
        </div>
        <div class="task-column__body scrollbar">
          <ReviewTaskCard
            v-for="(task,i) in reviewTasks.filter(e => e.remainingCount && 0 < e.remainingCount['remaining_images'] && e.remainingCount['remaining_images'] === e.remainingCount['total_images'])"
            :key="i"
            :task="task"
            :hasPermission="shouldAccessTask(task)"
            :datasets="datasets"
            @click="createReviewSession(task)"
            @start-annotation-review-task="createAnnotationReviewSession(task)"
            @open-manage-access="handleOpenManageAccess(task)"
            @delete-review-task="openConfirmDeleteTaskModal(task)"
          />
        </div>
      </div>
      <div class="col-sm task-column">
        <div class="task-column__header">
          <span>In Progress</span>
        </div>
        <div class="task-column__body scrollbar">
          <ReviewTaskCard
            v-for="(task,i) in reviewTasks.filter(e => e.remainingCount && 0 < e.remainingCount['remaining_images'] && e.remainingCount['remaining_images'] < e.remainingCount['total_images'])"
            :key="i"
            :task="task"
            :hasPermission="shouldAccessTask(task)"
            :datasets="datasets"
            @click="createReviewSession(task)"
            @start-annotation-review-task="createAnnotationReviewSession(task)"
            @open-manage-access="handleOpenManageAccess(task)"
            @delete-review-task="openConfirmDeleteTaskModal(task)"
          />
        </div>
      </div>
      <div class="col-sm task-column">
        <div class="task-column__header">
          <span>Completed</span>
        </div>
        <div class="task-column__body scrollbar">
          <ReviewTaskCard
            v-for="(task,i) in reviewTasks.filter(e => e.remainingCount && (e.remainingCount['remaining_images'] === 0 || e.remainingCount['total_images'] === 0))"
            :key="i"
            :task="task"
            :hasPermission="shouldAccessTask(task)"
            :datasets="datasets"
            @click="createReviewSession(task)"
            @start-annotation-review-task="createAnnotationReviewSession(task)"
            @open-manage-access="handleOpenManageAccess(task)"
            @delete-review-task="openConfirmDeleteTaskModal(task)"
          />
        </div>
      </div>
    </div>
  </div>
  <div v-else class="empty-state">
    <InlineLoader
      :width="'60px'"
      :height="'60px'"
      :border="'12px'"
    />
  </div>
  <CreateAnnotationReviewTaskModal
    v-if="showCreateModal"
    ref="createAnnotationReviewTaskModal"
    v-model:reviewSettings="reviewSettings"
    :datasets="datasets"
    @review-task-created="handleReviewTaskCreated"
    @review-task-created-error="handleReviewTaskCreatedError"
    @closed="() => {showCreateModal = false;}"
  />
  <!-- <CreateAnnotationReviewSessionModal
      ref="createAnnotationReviewSessionModal"
      v-model:selectedReviewTask="selectedReviewTask"
      @review-session-created="handleReviewSessionCreated"
    /> -->
  <AnnotationEditorModal
    v-if="showEditorModal"
    ref="annotationEditorModal"
    :labels="editorLabels"
    :reviewSettings="reviewSettings"
    :initialAuditorType="reviewSettings?.initialAuditorType"
    @closed="handleExitReview(reviewSettings.reviewTask)"
    @new-color="(params) => updateLabelColor(params, reviewSettings.reviewTask)"
  />
  <ConfirmModal
    ref="confirmModal"
    :messageHeader="confirmMessageHeader"
    :message="confirmMessage"
    :buttonClass="'button-delete'"
    :buttonText="buttonText"
    @confirmed="handleDeleteReviewTask"
  />
  <ManageReviewsAccess
    ref="manageReviewsAccessModal"
    :modalTask="modalTask"
    @refresh-tasks="updateAllReviewCards"
  />
</template>

<script>
import DatastoreConnect from '@/assets/js/DatastoreFunctions/datastore-interface';
import ReviewTaskCard from '@/components/ReviewsPage/ReviewTaskCard.vue';
import CreateAnnotationReviewTaskModal from '@/components/ReviewsPage/CreateAnnotationReviewTaskModal.vue';
import AnnotationEditorModal from '@/components/DatasetComponent/AnnotationTool/AnnotationEditorModal.vue';
import ConfirmModal from '@/components/ConfirmModal.vue';
import ManageReviewsAccess from '@/components/ReviewsPage/ManageReviewsAccess.vue';
import InlineLoader from '@/components/InlineLoader.vue';

export default {
  name: "ReviewsTab",
  components: {
    // ReviewsPageFilters,
    CreateAnnotationReviewTaskModal,
    // CreateAnnotationReviewSessionModal,
    AnnotationEditorModal,
    ReviewTaskCard,
    ConfirmModal,
    ManageReviewsAccess,
    InlineLoader,
  },
  props: {
  },
  emits: [],
  data() {
    return {
      filters: {},
      dataConnect: null,
      reviewTasks: [],
      reviewSettings: null,
      editorLabels: [],
      confirmMessage: null,
      confirmMessageHeader: null,
      buttonText: "",
      toDelete: null,
      modalTask: null,
      showEditorModal: false,
      showCreateModal: false,
      loading: false,
    };
  },
  computed: {
    user() {
      return this.$store.state.user.user;
    },
    currentProject() {
      return this.$store.state.projects.currentProject;
    },
    datasets() {
      return this.$store.state.datasets.datasetList;
    },
  },
  watch: {
    currentProject() {
      this.updateAllReviewCards();
    },
  },
  created() {
    this.dataConnect = new DatastoreConnect(this.$store.state.enterpriseServerUrl);
  },
  async mounted() {
    this.updateAllReviewCards();
  },
  methods: {
    shouldAccessTask(task) {
      return true;
    },
    handleOpenManageAccess(task) {
      this.modalTask = task;
      this.$nextTick(() => {
        this.$refs.manageReviewsAccessModal.showModal();
      });
    },
    openConfirmDeleteTaskModal(t) {
      this.confirmMessage = `Are you sure you want to move task "${t.name}" to recycle bin?`;
      this.confirmMessageHeader = 'Remove Task';
      this.buttonText = "Remove";
      this.toDelete = t;
      this.$refs.confirmModal.showModal();
    },
    async updateAllReviewCards() {
      this.reviewTasks = await this.getReviewTasks();

      const updatedTasksPromises = this.reviewTasks.map(async (task) => {
        task.remainingCount = await this.getReviewTaskRemainingCount(task);
        return task;
      });
      this.reviewTasks = await Promise.all(updatedTasksPromises);

      if (this.modalTask) {
        const t = this.reviewTasks.find((e) => e.id === this.modalTask.id);
        if (t) {
          this.modalTask = t;
        }
      }
    },
    async updateReviewCardCount(reviewTask) {
      reviewTask.remainingCount = await this.getReviewTaskRemainingCount(reviewTask);
    },
    async getReviewTasks() {
      this.loading = true;
      const resp = await this.dataConnect.getReviewTasks({ project_id: this.currentProject?.id })
        .catch((error) => {
          // TODO: handle error
          this.loading = false;
          console.log(error);
        });
      if (resp.error || !resp.result) {
        // TODO: handle error
        this.loading = false;
        return {};
      }
      this.loading = false;
      return resp.result;
    },
    async getReviewTaskRemainingCount(reviewTask) {
      const params = {
        review_task_id: reviewTask.id,
        dataset_id: reviewTask.dataset_id,
      };
      let remainingCount;
      if (reviewTask?.type === 'audit') {
        remainingCount = await this.dataConnect.getReviewTaskRemainingCount(params)
          .catch((error) => {
          // TODO: handle error
            console.log(error);
          });
      } else if (reviewTask?.type === 'label') {
        remainingCount = await this.dataConnect.getLabelingTaskRemainingCount(params)
          .catch((error) => {
          // TODO: handle error
            console.log(error);
          });
      }

      if (remainingCount.error || !remainingCount.result) {
        // TODO: handle error
        return {};
      }
      return remainingCount.result;
    },
    async handleExitReview(reviewTask) {
      this.showEditorModal = false;
      this.reviewSettings = null;
      this.showBulkReview = false;
      this.updateReviewCardCount(reviewTask);
    },
    async handleReviewTaskCreated() {
      this.updateAllReviewCards();
    },
    async handleReviewTaskCreatedError(error) {
      if (error.message.includes("limit reached")) {
        this.confirmMessage = `You haved reached the limit for number of tasks. Delete unused tasks or upgrade your package.`;
        this.confirmMessageHeader = 'Limit Reached';
        this.buttonText = "";
        this.$refs.confirmModal.showModal();
      }
    },
    async createReviewSession(reviewTask) {
      const access = await this.shouldAccessTask(reviewTask);
      if (!access) {
        return;
      }
      const resp = await this.dataConnect.addReviewSession({
        reviewer: this.$store.state.user.user.username,
        duration: 0,
        review_task_id: reviewTask.id,
      })
        .catch((error) => {
          // TODO: handle error
          console.log(error);
        });
      if (resp.error || !resp.result) {
        // TODO: handle error
      } else {
        const reviewSessionSettings = {
          reviewSessionMethod: 'review_annotation_editor',
          batchSize: 10,
          samplingMethod: 'random',
          reviewSession: resp.result,
          reviewTask,
          initialAuditorType: 'image',
        };
        this.handleReviewSessionCreated(reviewTask, reviewSessionSettings);
      }
    },
    async createAnnotationReviewSession(reviewTask) {
      const resp = await this.dataConnect.addReviewSession({
        reviewer: this.$store.state.user.user.username,
        duration: 0,
        review_task_id: reviewTask.id,
      })
        .catch((error) => {
          // TODO: handle error
          console.log(error);
        });
      if (resp.error || !resp.result) {
        // TODO: handle error
      } else {
        const reviewSessionSettings = {
          reviewSessionMethod: 'review_annotation_editor',
          batchSize: 10,
          samplingMethod: 'random',
          reviewSession: resp.result,
          reviewTask,
          initialAuditorType: 'annotation',
        };
        this.handleReviewSessionCreated(reviewTask, reviewSessionSettings);
      }
    },
    async handleReviewSessionCreated(reviewTask, reviewSessionSettings) {
      const sourceAnnotationSet = await this.getAnnotationSet({ annotation_set_id: reviewTask.source_annotation_set_id });
      this.reviewSettings = { ...reviewSessionSettings, sourceAnnotationSet };
      this.editorLabels = await this.getLabelListForDataset(reviewTask.dataset_id);
      console.log(this.editorLabels);
      if (this.reviewSettings.reviewSessionMethod === 'bulk_annotation_review') {
        this.showBulkReview = true;
      } else if (this.reviewSettings.reviewSessionMethod === 'review_annotation_editor') {
        this.$nextTick(() => {
          this.showEditorModal = true;
        });
      }
    },
    async updateLabelColor(params, reviewTask) {
      params.dataset_id = reviewTask.dataset_id;
      await this.dataConnect.updateLabel(params)
        .then(async (data) => {
          if (!data.error) {
            this.editorLabels = await this.getLabelListForDataset(reviewTask.dataset_id);
          }
        })
        .catch((e) => console.log(e));
    },
    async getAnnotationSet(params) {
      const annotationSet = await this.dataConnect.getAnnotationSet(params)
        .catch((error) => {
          // TODO: handle error
          console.log(error);
        });
      if (annotationSet.error || !annotationSet.result) {
        // TODO: handle error
        return {};
      }
      return annotationSet.result;
    },
    async getLabelListForDataset(dataset_id) {
      const resp = await this.dataConnect.getLabelList({ dataset_id })
        .catch((error) => {
          console.log('Failed to retrieve labels:', error);
        });
      if (!resp || resp.error || !resp.result) {
        return [];
      }
      return resp.result;
    },
    async handleDeleteReviewTask() {
      const resp = await this.dataConnect.deleteReviewTask({ review_task_id: this.toDelete.id })
        .catch((error) => {
          // TODO: handle error
          console.log(error);
        });
      if (!resp || resp.error || !resp.result) {
        if (resp.error) {
          window.alert(resp.error);
        }
      }
      this.updateAllReviewCards();
    },
    openCreateTaskModal() {
      this.showCreateModal = true;
    },
  },
};
</script>

<style lang="scss" scoped>

.controls-bar {
  display: flex;
  flex-direction: row;
  padding: 10px 20px 10px 20px;
  align-items: center;
  border-bottom: 1px solid #c9c9c9;
  @include themify() {
    background: themed('color-ribbon');
  }
  justify-content: space-between;
}

.reviewTask {

  &__container {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    width: 100%;
    min-width: 500px;
    position: relative;
    min-height: 0;

    .task-column {
      display: flex;
      flex-direction: column;
      @include themify() {
        background: radial-gradient(circle, rgba($color-primary-400, 0.075) 50%, rgba(var(--color-primary-rgb), 0.125) 100%);
      }
      max-width: 33%;
      border-radius: 10px;

      &__header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 16px 16px 6px 16px;
        font-size: 1.4rem;
        font-weight: 700;
      }

      &__body {
        display: flex;
        flex-direction: column;
        flex: 1 1 auto;
        padding: 4px 16px;
        margin-bottom: 20px;
        overflow-y: auto;
        gap: 16px
      }
    }
  }

  &__row {
    display: flex;
    flex-direction: row;
    height: 100%;
    padding: 16px;
    gap: 16px;
  }
}

.empty-state {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  height: 100%;
}
</style>
