<template>
  <div class="settings scrollbar">
    <span class="header">Add Funds</span>

    <div class="row mt-4">
      <label class="label required">Organization</label>
      <div class="value">
        <input
          v-model="orgName"
          class="select"
          type="text"
          disabled
        >
      </div>
    </div>
    <div class="row mt-4">
      <label class="label">Amount ($)</label>
      <div class="value">
        <input
          v-model="amount"
          class="select"
          type="number"
          step="1"
          :class="{ 'error' : errorAmount }"
        >
      </div>
    </div>
    <div v-if="message" class="row mt-2">
      <label class="label" />
      <div
        class="value"
        :class="{ 'error-text': isError, 'success-text' : !isError }"
      >
        {{ message }}
      </div>
    </div>
    <div class="row mt-3 footer">
      <button class="button button-sm rounded" @click.stop="openConfirmModal()">Apply</button>
      <span @click.stop="$router.go(-1)">Cancel</span>
    </div>
  </div>
  <ConfirmModal
    ref="confirmModal"
    :messageHeader="confirmMessageHeader"
    :message="confirmMessage"
    :buttonText="buttonText"
    @confirmed="apply"
  />
</template>

<script>
import DatastoreConnect from '@/assets/js/DatastoreFunctions/datastore-interface';
import ConfirmModal from '@/components/ConfirmModal.vue';

export default {
  name: 'UserAddCredits',
  components: {
    ConfirmModal,
  },
  data() {
    return {
      dsConn: new DatastoreConnect(this.$store.state.enterpriseServerUrl),
      orgId: null,
      orgName: '',
      amount: 0,
      errorAmount: null,
      message: '',
      backendError: false,
      orgList: [],
      confirmMessage: '',
      confirmMessageHeader: '',
      buttonText: '',
    };
  },
  computed: {
    isError() {
      return this.errorAmount || this.backendError;
    },
    user() {
      return this.$store.state.user.user;
    },
    isSuper() {
      return this.user.role === 'super';
    },
    isAdmin() {
      return this.user.role === 'admin';
    },
  },
  created() {
    if (!this.isSuper) {
      this.$router.push('/login');
    }
  },
  async mounted() {
    await this.getOrgList();
    if (this.$route.query.org) {
      this.orgId = parseInt(this.$route.query.org);
      const org = this.orgList.find((e) => e.id === this.orgId);
      if (org) {
        this.orgName = org.name;
      }
    }
  },
  methods: {
    async getOrgList() {
      await this.dsConn.getOrgList()
        .then((data) => {
          if (data.result) {
            this.orgList = data.result;
          }
        })
        .catch((e) => console.log(e));
    },
    resetErrors() {
      this.errorAmount = false;
      this.backendError = false;
    },
    openConfirmModal() {
      this.confirmMessage = `Adding $ ${this.amount} to organization ${this.orgId} ?`;
      this.confirmMessageHeader = 'Adding Funds';
      this.buttonText = "Confirm";
      this.$refs.confirmModal.showModal();
    },
    async apply() {
      this.resetErrors();
      if (!this.orgId) {
        this.message = 'Missing Organization.';
        return;
      }
      if (!this.amount) {
        this.errorAmount = true;
        this.message = 'Missing Amount.';
        return;
      }
      const params = {
        organization_id: this.orgId,
        amount: this.amount,
      };
      await this.dsConn.purchaseCredits(params)
        .then((data) => {
          if (data.result) {
            this.backendError = false;
            this.message = `Successfully added $ ${this.amount} in credits`;
          } else {
            this.backendError = true;
            this.message = `Could not add credits: ${data.error}`;
          }
        })
        .catch((e) => {
          this.message = e;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.settings {
  display: flex;
  flex-direction: column;
  width: 730px;
  min-width: 400px;
  height: 100%;
  padding: 30px;
  overflow-y: auto;
}

.header {
  font-size: 1.2rem;
  font-weight: 700;
}

.row {
  flex-wrap: nowrap !important;
}

.row > .label {
  display: flex;
  justify-content: end;
  align-items: center;
  width: 200px;
  text-align: right;
  font-size: 0.9rem;
  font-weight: 600;
  color: rgba(grey, 0.9);

  &.required::after {
    content: '*';
    font-size: 14px;
    font-weight: 700;
    padding-left: 1px;
    @include themify() {
      color: themed('color-primary');
    }
  }
}

.row > .value {
  flex: 1 1 auto;
  font-size: 0.9rem;
  margin-left: 20px;
}

.select {
  width: 100%;
  height: 30px;
  border-radius: 5px;
  font-size: 1rem;
  padding: 3px 10px;
  align-self: center;
  -webkit-appearance: auto;
  background: rgba(#ffffff, 0.8);
  @include themify() {
    border: 1px solid themed('form-input-border');
  }

}

.select:focus {
  @include themify() {
    border: 1px solid themed('color-primary');
    box-shadow:  0 0 0 1px themed('color-primary'), 0 0 3px themed('color-primary-300');
  }
  outline: 2px solid transparent;
  outline-offset: 2px;
}

input.error , select.error {
  @include themify() {
    border: 1px solid themed('color-red') !important;
    box-shadow:  0 0 0 1px themed('color-red'), 0 0 3px themed('color-red') !important;
  }

  &:focus, &:focus-visible {
    @include themify() {
      border: 1px solid themed('color-red') !important;
      box-shadow:  0 0 0 1px themed('color-red'), 0 0 3px themed('color-red') !important;
    }
  }
}

.error-text {
  color: $color-red-500;
}

.success-text {
  color: $color-success;
}

.footer {
  display: flex;
  justify-content: flex-end;
  button {
    width: fit-content
  }
  span {
    color: $color-link;
    text-decoration: underline;
    cursor: pointer;
    width: fit-content;
  }
}

.role-grid {
  display: grid;
  grid-gap: 10px;
  grid-template-columns: repeat(auto-fill, 200px);
  grid-auto-rows: auto;
  width: 100%;
  overflow-y: auto;
}
</style>
