<template>
  <div class="settings">
    <div v-if="doneFetch" class="usage-summary-settings panel__text scrollbar">
      <template v-if="showDetailedOrg?.useSummary && showDetailedOrg?.users">
        <div class="row">
          <div class="col-9">
            <div class="generate-bill">
              <button class="button button-rounded create-button" @click="generateMonthlyBill">Generate Bill</button>
              <div class="date-selector settings__control-group">
                <select v-model="selectMonth" class="select">
                  <option v-for="(month, i) in months" :key="i" :value="month">
                    {{ month }}
                  </option>
                </select>
                <select v-model="selectYear" class="select">
                  <option v-for="(year, i) in years" :key="i" :value="year">
                    {{ year }}
                  </option>
                </select>
              </div>
              <div class="value" :class="{ 'error-text': error, 'success-text' : !error }">{{ message }}</div>
            </div>
          </div>
          <div class="col">
            <BaseMenu
              :placement="'bottom-start'"
              @closed="show_menu = false"
            >
              <button class="button button-rounded button-secondary actions-button">More Actions</button>
              <template #menu="{closeMenu}">
                <ul>
                  <li>
                    <BaseMenuButton @click.stop="navigateToBilling(), closeMenu()">
                      <SVGIcon
                        :iconName="'usage'"
                        :width="'22px'"
                        :height="'22px'"
                      />
                      <span>Usage & Billing</span>
                    </BaseMenuButton>
                  </li>
                  <li>
                    <BaseMenuButton @click.stop="navigateToAddCredits(), closeMenu()">
                      <SVGIcon
                        :iconName="'dollar'"
                        :width="'22px'"
                        :height="'22px'"
                      />
                      <span>Add Funds</span>
                    </BaseMenuButton>
                  </li>
                  <li>
                    <BaseMenuButton @click.stop="navigateToCreateUser(), closeMenu()">
                      <SVGIcon
                        :iconName="'person_add'"
                        :width="'22px'"
                        :height="'22px'"
                      />
                      <span>Create New User</span>
                    </BaseMenuButton>
                  </li>
                  <li>
                    <BaseMenuButton @click.stop="navigateToEditOrganization(), closeMenu()">
                      <SVGIcon
                        :iconName="'edit'"
                        :width="'22px'"
                        :height="'22px'"
                      />
                      <span>Edit Organization</span>
                    </BaseMenuButton>
                  </li>
                </ul>
              </template>
            </BaseMenu>
          </div>
          <!-- <div class="col-3 header-text hyperlink">
            <span @click="navigateToBilling(u)">Usage & Billing</span>
          </div>
          <div class="col-3 header-text hyperlink">
            <span @click="navigateToAddCredits(u)">Add Credits</span>
          </div> -->
          <div class="col header-text">
            <span>Funds Remaining</span>
            <h3>
              USD {{ Number(showDetailedOrg.useSummary.credits * dollarCreditRatio + showDetailedOrg.currentDateDollarCost).toFixed(2) }}
            </h3>
          </div>
        </div>
        <div class="static-summary">
          <div class="static-summary__item">
            <div class="icon">
              <SVGIcon
                :iconName="'folder2'"
                :width="'28px'"
                :height="'28px'"
              />
            </div>
            <div class="progress">
              <div class="progress__title">
                <span>Projects</span>
              </div>
              <div class="progress__subtitle">
                <span>{{ `${showDetailedOrg.useSummary.project}` }}</span>
              </div>
            </div>
          </div>
          <div class="static-summary__item">
            <div class="icon">
              <SVGIcon
                :iconName="'database2'"
                :width="'28px'"
                :height="'28px'"
              />
            </div>
            <div class="progress">
              <div class="progress__title">
                <span>Datasets</span>
              </div>
              <div class="progress__subtitle">
                <span>{{ `${showDetailedOrg.useSummary.dataset}` }}</span>
              </div>
            </div>
          </div>
          <div class="static-summary__item">
            <div class="icon">
              <SVGIcon
                :iconName="'set'"
                :width="'28px'"
                :height="'28px'"
              />
            </div>
            <div class="progress">
              <div class="progress__title">
                <span>Annotations (boxes)</span>
              </div>
              <div class="progress__subtitle">
                <span>{{ `${showDetailedOrg.useSummary.annotations}` }}</span>
              </div>
            </div>
          </div>
          <div class="static-summary__item">
            <div class="icon">
              <SVGIcon
                :iconName="'image'"
                :width="'28px'"
                :height="'28px'"
              />
            </div>
            <div class="progress">
              <div class="progress__title">
                <span>Images</span>
              </div>

              <div class="progress__subtitle">
                <span>{{ `${showDetailedOrg.useSummary.images}` }}</span>
              </div>
            </div>
          </div>
          <div class="static-summary__item">
            <div class="icon">
              <SVGIcon
                :iconName="'train'"
                :width="'28px'"
                :height="'28px'"
              />
            </div>
            <div class="progress">
              <div class="progress__title">
                <span>Training Time (hour)</span>
              </div>
              <div class="progress__subtitle">
                <span>{{ `${showDetailedOrg.useSummary.train_time.toFixed(4)}` }}</span>
              </div>
            </div>
          </div>
          <div class="static-summary__item">
            <div class="icon">
              <SVGIcon
                :iconName="'detection2'"
                :width="'28px'"
                :height="'28px'"
              />
            </div>
            <div class="progress">
              <div class="progress__title">
                <span>Auto Annotations (hours)</span>
              </div>
              <div class="progress__subtitle">
                <span>{{ `${showDetailedOrg.useSummary.autoanns.toFixed(4)}` }}</span>
              </div>
            </div>
          </div>
          <div class="static-summary__item">
            <div class="icon">
              <SVGIcon
                :iconName="'segments'"
                :width="'28px'"
                :height="'28px'"
              />
            </div>
            <div class="progress">
              <div class="progress__title">
                <span>Auto Segmentations (hours)</span>
              </div>
              <div class="progress__subtitle">
                <span>{{ `${showDetailedOrg.useSummary.autosegs.toFixed(4)}` }}</span>
              </div>
            </div>
          </div>
        </div>
      </template>
      <div v-if="showDetailedOrg?.useSummary && showDetailedOrg?.users && showDetailedOrg?.users.length > 0" class="user-table">
        <!-- <button class="button button-rounded create-button mt-3" @click="navigateToCreateUser">Create New User</button> -->
        <div class="user-table__table">
          <div class="row header-row">
            <div
              v-for="(h, i) in headers"
              :key="`header-cell-${i}`"
              :class="{ 'col-3' : i <= 2, 'col-sm' : i > 2 }"
            >
              {{ h }}
            </div>
            <div class="button-padding" />
          </div>
          <div
            v-for="(u, j) in showDetailedOrg.users"
            :key="`user-row-${j}`"
            class="row mt-3 li-row"
          >
            <div class="col-3 user-table__username">
              <span>{{ u['username'] }}</span>
            </div>
            <div class="col-3 user-table__access">
              {{ u['email'] }}
            </div>
            <div class="col-3 user-table__access">
              {{ u['role'] ? u['role'].charAt(0).toUpperCase() + u['role'].slice(1) : '' }}
            </div>
            <div class="button-padding">
              <IconButton
                class="edit-btn icon"
                :icon="'edit'"
                :width="22"
                :height="22"
                @click="navigateToEditUser(u)"
              />
              <IconButton
                class="edit-btn icon"
                :icon="'person_arrow'"
                :width="22"
                :height="22"
                @click="navigateToUserEmulation(u.username)"
              />
              <IconButton
                :class="{ invisible: u.username === user.username }"
                class="close-btn icon"
                :icon="'close'"
                :width="22"
                :height="22"
                @click="openConfirmDeleteUserModal(u)"
              />
            </div>
          </div>
          <div class="row footer-row" />
        </div>
      </div>
    </div>
    <div v-else class="empty-state">
      <InlineLoader
        :width="'60px'"
        :height="'60px'"
        :border="'12px'"
      />
    </div>
    <ConfirmModal
      ref="confirmModal"
      :messageHeader="confirmMessageHeader"
      :message="confirmMessage"
      :buttonClass="'button-delete'"
      :buttonText="'Delete'"
      @confirmed="removeUser"
    />
  </div>
</template>

<script>
import DatastoreConnect from '@/assets/js/DatastoreFunctions/datastore-interface';
import IconButton from '@/components/IconButton.vue';
import ConfirmModal from '@/components/ConfirmModal.vue';
import SVGIcon from '@/components/SVGIcon.vue';
import BaseMenu from '@/components/BaseMenu.vue';
import BaseMenuButton from '@/components/BaseMenuButton.vue';
import InlineLoader from '@/components/InlineLoader.vue';

export default {
  name: 'ShowDetailedOrganization',
  components: {
    IconButton,
    ConfirmModal,
    SVGIcon,
    BaseMenu,
    BaseMenuButton,
    InlineLoader,
  },
  data() {
    return {
      dsConn: new DatastoreConnect(this.$store.state.enterpriseServerUrl),
      allRoles: [],
      confirmMessage: null,
      confirmMessageHeader: null,
      openedDropdowns: [],
      showDetailedOrg: null,
      dollarCreditRatio: 0.001,
      selectMonth: '',
      selectYear: 0,
      message: '',
      error: false,
      currentDateDollarCost: 0,
      doneSummaryFetch: false,
      doneDetailsFetch: false,
      costPackage: null,
    };
  },
  computed: {
    doneFetch() {
      return this.doneDetailsFetch && this.doneSummaryFetch;
    },
    headers() {
      return ['User', 'E-mail', 'Role'];
    },
    user() {
      return this.$store.state.user.user;
    },
    isAdmin() {
      return this.user && (this.user.role === 'admin');
    },
    isSuper() {
      return this.user && (this.user.role === 'super');
    },
    months() {
      return [
        'January', 'February', 'March', 'April', 'May', 'June',
        'July', 'August', 'September', 'October', 'November', 'December',
      ];
    },
    years() {
      const currentYear = new Date().getFullYear();
      const startingYear = 2023;
      const years = [];
      for (let year = startingYear; year <= currentYear; year++) {
        years.push(year);
      }

      return years;
    },
  },
  async created() {
    if (!this.isAdmin && !this.isSuper) {
      this.$router.push('/login');
    }
    if (this.$route.query.org) {
      await this.getCostPackage(parseInt(this.$route.query.org));
      await this.getOrg(parseInt(this.$route.query.org));
      await this.getDollarCreditRatio(parseInt(this.$route.query.org));
      await this.retrieveMonthlyBill(parseInt(this.$route.query.org));
    }
    this.selectMonth = this.months[new Date().getMonth()];
    this.selectYear = new Date().getFullYear();
    this.getAllRoles();
  },

  methods: {
    async getOrg(id) {
      this.doneSummaryFetch = false;
      await this.dsConn.getOrg({ organization_id: id })
        .then(async (data) => {
          if (data.result) {
            this.showDetailedOrg = data.result;
            this.showDetailedOrg.users = await this.getUsers(id);
            this.showDetailedOrg.useSummary = await this.getUsagePercentage(id);
          }
          this.doneSummaryFetch = true;
        })
        .catch((e) => {
          this.message = e;
          this.doneSummaryFetch = true;
        });
    },
    async generateMonthlyBill() {
      this.message = '';
      await this.dsConn.generateMonthlyBill({
        organization_id: this.showDetailedOrg.id,
        month: this.selectMonth,
        year: this.selectYear,
      })
        .then((resp) => {
          if (resp.result) {
            this.message = "Bill generated successfully";
            this.error = false;
          } else {
            this.message = resp.error.message;
            this.error = true;
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    async getCostPackage(id) {
      await this.dsConn.getCostPackage({ organization_id: id })
        .then((resp) => {
          if (resp.result) {
            this.costPackage = resp.result;
          } else {
            this.costPackage = null;
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    async retrieveMonthlyBill(id) {
      this.doneDetailsFetch = false;
      const now = new Date();
      await this.dsConn.retrieveMonthlyBill({
        organization_id: id,
        month: now.toLocaleString('default', { month: 'long' }),
        year: now.getUTCFullYear(),
      })
        .then((resp) => {
          if (resp.result && Object.keys(resp.result).length > 0 && this.costPackage) {
            this.showDetailedOrg.currentDateDollarCost = this.parseReport(resp.result);
          } else {
            this.showDetailedOrg.currentDateDollarCost = this.parseReport([]);
          }
          this.doneDetailsFetch = true;
        })
        .catch((e) => {
          this.doneDetailsFetch = true;
        });
    },
    async getDollarCreditRatio(id) {
      await this.dsConn.getDollarCreditRatio({ organization_id: id })
        .then((resp) => {
          if (resp.result && resp.result > 0) {
            this.dollarCreditRatio = resp.result;
          } else {
            this.dollarCreditRatio = 0.001;
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    openConfirmDeleteUserModal(u) {
      this.confirmMessage = `Are you sure you want to remove user "${u.username}"?`;
      this.confirmMessageHeader = 'Remove User';
      this.toDelete = u;
      this.$refs.confirmModal.showModal();
    },
    async getAllRoles() {
      await this.dsConn.getAllRoles()
        .then((data) => {
          this.allRoles = data.result;
        })
        .catch((e) => console.log(e));
    },
    async getUsers(id) {
      const resp = await this.dsConn.getUserListByOrganization({ organization_id: id })
        .catch((error) => {
          console.log('Failed to retrieve training sessions:', error);
        });
      if (!resp || resp.error || !resp.result) {
        return [];
      }

      const users = resp.result.reverse();
      return users;
    },
    async getUsagePercentage(id) {
      const resp = await this.dsConn.getUsageSummaryForOrganization({ organization_id: id })
        .catch((error) => {
          console.log('Failed to retrieve training sessions:', error);
        });
      if (!resp || resp.error || !resp.result) {
        return [];
      }
      resp.result.credits = Number(resp.result.credits);
      return resp.result;
    },
    navigateToCreateOrganization() {
      this.$router.push({ name: 'super.neworg' });
    },
    navigateToEditOrganization() {
      this.$router.push({ name: 'super.editorg', query: { org: this.showDetailedOrg.id } });
    },
    navigateToEditUser(u) {
      this.$router.push({ name: 'super.edituser', query: { editUser: u.username } });
    },
    navigateToCreateUser() {
      this.$router.push({ name: 'super.createuser', query: { org: this.showDetailedOrg.id } });
    },
    async removeUser() {
      await this.dsConn.deleteUser({ username: this.toDelete.username })
        .then(async (data) => {
          if (!data.error) {
            this.toDelete = null;
            this.showDetailedOrg.users = await this.getUsers(this.showDetailedOrg.id);
          }
        }).catch((e) => console.log(e));
    },
    navigateToUserEmulation(name) {
      this.dsConn.send2FA({ username: this.user.username });
      this.$router.push({ name: 'super.emulate', query: { name } });
    },
    navigateToBilling() {
      this.$router.push({ name: 'super.usagelogs', query: { org: this.showDetailedOrg.id } });
    },
    navigateToAddCredits() {
      this.$router.push({ name: 'super.addcredits', query: { org: this.showDetailedOrg.id } });
    },
    parseReport(data) {
      // Images
      let currentDateDollarCost = 0;
      const num_images_array = data.filter((e) => e.type === 'num_images');
      currentDateDollarCost += this.appendCurrentDateRecord(num_images_array[0], num_images_array[num_images_array.length - 1]);
      const num_parked_images_array = data.filter((e) => e.type === 'parked_images' || e.type === 'parked_images_fee');
      currentDateDollarCost += this.appendCurrentDateRecord(num_parked_images_array[0], num_parked_images_array[num_parked_images_array.length - 1]);
      // annotations
      const num_annotations_array = data.filter((e) => e.type === 'num_annotations');
      currentDateDollarCost += this.appendCurrentDateRecord(num_annotations_array[0], num_parked_images_array[num_parked_images_array.length - 1]);
      return currentDateDollarCost;
    },

    appendCurrentDateRecord(latest, first) {
      if (!latest || !first) {
        return 0;
      }
      const now = new Date();

      // latest is the most recent entry
      const latestDate = new Date(latest.date);
      // first is first entry of the month, used to get which month the current record is from
      const firstDate = new Date(first.date);
      if (now.getUTCFullYear() !== firstDate.getUTCFullYear() || now.getUTCMonth() !== firstDate.getUTCMonth()) {
        return 0;
      }

      const diff = this.getDateDifferenceInDays(latestDate, now) + 1;
      let costPkgKey = null;
      let modifier = 1;
      switch (latest.type) {
      case "num_images":
        costPkgKey = 'images';
        break;
      case "num_annotations":
        costPkgKey = 'annotations';
        break;
      case "parked_images_fee":
      case "parked_images":
        costPkgKey = 'images';
        modifier = 0.5;
        break;
      default:
        break;
      }
      if (diff < 1.0) {
        return 0;
      } else {
        const dollars = this.getCreditCostForCount(costPkgKey, latest.amount, diff, modifier);
        if (Number(dollars) !== 0) {
          return Number(dollars);
        }
        return 0;
      }
    },
    getDateDifferenceInDays(date1, date2) {
    // Parse the input dates
      const startDate = new Date(date1);
      const endDate = new Date(date2);

      // Ensure that the date objects are valid
      if (Number.isNaN(startDate) || Number.isNaN(endDate)) {
        throw new Error("Invalid date format");
      }

      // Get the time in milliseconds for both dates
      const startTime = startDate.getTime();
      const endTime = endDate.getTime();

      // Calculate the difference in milliseconds
      const differenceInMilliseconds = endTime - startTime;

      // Convert milliseconds to full days (1000 milliseconds * 60 seconds * 60 minutes * 24 hours)
      const differenceInDays = Math.floor(differenceInMilliseconds / (1000 * 60 * 60 * 24));

      return differenceInDays;
    },

    getCreditCostForCount(key, count, duration, modifier) {
      let credit_range = null;
      let prorated = 1;
      switch (key) {
      case "seats":
        credit_range = this.costPackage['seats'];
        prorated = duration / 30.0;
        break;
      case "images":
        credit_range = this.costPackage['images'];
        prorated = duration / 30.0;
        break;
      case "annotations":
        credit_range = this.costPackage['annotations'];
        prorated = duration / 30.0;
        break;
      case "video":
        credit_range = this.costPackage['bulk_video'];
        prorated = duration / 30.0;
        break;
      default:
        break;
      }

      return Number(-1 * count * credit_range[0]['crpu'] * this.costPackage['dve_credits_cost_usd'] * prorated * modifier);
    },
  },
};
</script>

<style lang="scss" scoped>
.settings {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  padding: 30px;
  overflow-y: auto;
}

.empty-state {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  height: 100%;
}

.header-text{
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  margin-left: 20px;

  span {
    width:fit-content;
    font-weight: 600;
    font-size: 1.0rem;
  }
}

.hyperlink {
  color: #0b60f3;
  text-decoration: underline;
}

.hyperlink span:hover {
  cursor: pointer;
}

.date-selector {
  width: 400px;
  display: flex;
  flex-direction: row;
  margin-top: 20px;
  margin-bottom: 10px;

  select + select {
    margin-left: 20px;
  }
}

.value {
  display: flex;
  flex-direction: row;
  flex: 1 1 auto;
  font-size: 0.9rem;
}

.error-text {
  color: $color-red-500;
}

.success-text {
  color: $color-success;
}

.static-summary {
  width: 100%;
  display: grid;
  grid-gap: 30px;
  padding: 15px;
  margin-left: 13px;
  grid-template-columns: repeat(auto-fill, 300px);
  grid-auto-rows: auto;
  border-radius: 8px;
  margin-top: 10px;
  box-shadow: 1px 1px 4px 1px rgba(0, 0, 0, 0.2), 0 0 1px 1px rgba(0, 0, 0, 0.05);
  @include themify() {
    background: themed('card-color');
    color: themed('body-text-color');
  }

  &__item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .icon +.progress {
      margin-left: 20px;
    }
  }

  &__item > .label {
    width: 200px;
    text-align: right;
    font-size: 0.9rem;
    font-weight: 600;
    color: rgba(grey, 0.9);

    &.required::after {
      content: '*';
      font-size: 14px;
      font-weight: 700;
      padding-left: 1px;
      @include themify() {
        color: themed('color-primary');
      }
    }
  }

  &__item > .value {
    flex: 1 1 auto;
    font-size: 0.9rem;
    margin-left: 20px;
  }
}

.icon {
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
}

.progress {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;

  &__title {
    display: flex;
    width: 100%;
    font-size: 1.0rem;
    font-weight: 600;
    @include themify() {
    color: themed('body-text-color');
    }

    justify-content: space-between;
  }

  &__subtitle {
    display: flex;
    width: 100%;
    font-size: 1.1rem;
    font-weight: 600;
    justify-content: space-between;
    @include themify() {
    color: themed('body-text-color-secondary');
    }

    div:first-child {
    margin-bottom: 5px;
    }
  }

  &__bar {
      display: flex;
      flex-direction: column;
      width: 100%;
      margin-top: 1px;

      progress {
      width: 100%;
      border-radius: 0;
      height: 10px;

        &::-webkit-progress-bar {
            @include themify() {
            background-color: themed('progress-bar');
            }
        }
        &::-webkit-progress-value {
            @include themify() {
            background-color: themed('progress-value');
            }
        }
      }
  }

  &__estimate {
      display: flex;
      font-weight: 600;
      font-size: 0.875rem;
      @include themify() {
      color: themed('body-text-color-secondary');
      }
      margin-top: 5px;
      justify-content: space-between;
  }
}

.header {
  font-size: 1.2rem;
  font-weight: 700;
}

.actions-button {
  margin-left: 15px;
  margin-top: 10px;
}

.create-button {
  width: fit-content;
}

.generate-bill {
  margin: 20px 0 0 15px;
}

.user-table {
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  width: 100%;
  padding: 10px;

  &__header {
    font-size: 0.9rem;
  }

  &__table {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 15px 15px 15px 15px;

    .header-row {
      font-weight: 700;
      border-bottom: 2px solid rgba(96, 95, 95, 0.5);
    }

    .footer-row {
      min-height: 10px;
      border-bottom: 2px solid rgba(96, 95, 95, 0.5);
    }
  }

  &__username {
    display: flex;
    flex-direction: column;
    justify-content: center;

    span:nth-child(2) {
      font-size: 0.9rem;
      padding-left: 20px;
      color: rgba(grey, 0.9)
    }
  }

  &__package {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &__access {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

}

.button-padding {
  display: flex;
  flex-direction: row;
  width: 100px;
}

.li-row {
  padding: 5px;
  border-radius: 8px;
  align-items: center;
}

.li-row:hover {
  background-color: rgba(140, 140, 140, 0.2);
}

.row {
  flex-wrap: nowrap !important;
}

.row > .label {
  display: flex;
  justify-content: end;
  align-items: center;
  width: 200px;
  text-align: right;
  font-size: 1.0rem;
  font-weight: 600;
  color: rgba(grey, 1.0)
}

.row > .value {
  flex: 1 1 auto;
  font-size: 1.0rem;
  margin-left: 20px;
}

.icon {
  display: flex;
  align-items: center;
  justify-content: center;
  background: none;
  border: none;
  border-radius: 4px;
  margin-left: auto;
}

.icon + .icon {
  margin-left: 10px;
}

.edit-btn:hover {
  background: none !important;
  box-shadow: none !important;
  color: $color-primary-400;
}

.close-btn:hover {
  background: none !important;
  box-shadow: none !important;
  color: $color-red-400;
}

.invisible {
  visibility: hidden;
}
</style>
