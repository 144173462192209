<template>
  <FilterPopper :appendToBody="true">
    <BaseFilterButton :name="'Target Set'" :active="isActive" :tagValue="tagValue" />
    <template #popper>
      <div
        v-if="annotationSets && annotationSets.length > 0"
        ref="checkboxContainer"
        class="filter-settings scrollbar"
      >
        <div v-for="(task, i) in annotationSets" :key="i" class="filter-settings__row">
          <input
            :id="`annotationSetsForTarget-${i}`"
            v-model="selectedSet"
            type="radio"
            :name="`${task.name}-${i}`"
            :value="task"
          >
          <label :for="`task-${i}`">{{ task.name }}</label><br>
        </div>
      </div>
      <div v-else class="filter-settings">
        <span>Please define an annotation set.</span>
      </div>
    </template>
  </FilterPopper>
</template>

<script>
import FilterPopper from '@/components/FilterPopper.vue';
import BaseFilterButton from '@/components/BaseFilterButton.vue';

export default {
  name: "ChooseValidationTarget",
  components: {
    FilterPopper,
    BaseFilterButton,
  },
  props: {
    annotationSets: {
      type: Array,
      default: () => [],
    },
    modelValue: {
      type: Number,
      default: null,
    },
  },
  emits: [
    'update:modelValue',
  ],
  data() {
    return {
      selectedSet: {},
    };
  },
  computed: {
    isActive() {
      if (this.modelValue) {
        return true;
      }
      return false;
    },
    tagValue() {
      if (this.modelValue) {
        return this.selectedSet?.name;
      }
      return '';
    },
  },
  watch: {
    selectedSet() {
      this.updateFilters();
    },
    annotationSets: {
      handler(tasks) {
        if (tasks.length === 0 || (this.selectedSet && !tasks.find((e) => e.id === this.selectedSet.id))) {
          this.selectedSet = {};
        }
      },
    },
    modelValue(v) {
      if (v) {
        this.selectedSet = this.annotationSets.find((e) => e.id === this.modelValue);
        this.updateFilters();
      }
    },
  },
  mounted() {
    if (this.modelValue) {
      this.selectedSet = this.annotationSets.find((e) => e.id === this.modelValue);
      this.updateFilters();
    }
  },
  methods: {
    updateFilters() {
      this.$emit('update:modelValue', this.selectedSet?.id);
    },
  },
};
</script>

<style lang="scss" scoped>

.filter-tag {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 18px;
  padding: 0 6px;
  height: 18px;
  @include themify() {
    background: themed('color-accent');
  }
}

.filter-settings {
  display: flex;
  flex-direction: column;
  padding: 8px;
  border-radius: inherit;
  gap: 6px;
  overflow: auto;
  min-width: 140px;
  text-wrap: nowrap;

  &__row {
    display: flex;
    align-items: center;
  }

  & input[type=radio] {
    margin-right: 12px;
  }
}
</style>
