<template>
  <div class="stats-graph">
    <div class="table-header">
      <span class="title">Precision Recall Curve</span>
    </div>
    <apexchart
      id="apexchart"
      height="90%"
      type="line"
      :options="chartOptions"
      :series="series"
    />
  </div>
</template>

<script>

import VueApexCharts from "vue3-apexcharts";

export default {
  name: 'PrecisionRecallChart',
  components: {
    'apexchart': VueApexCharts,
  },
  props: {
    validation: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      chartOptions: {
        chart: {
          toolbar: {
            show: false,
          },
          zoom: {
            enabled: false,
          },
        },
        tooltip: {
          fillSeriesColor: false,
          x: {
            show: false,
            formatter(val) {
              return val ? `Recall: ${parseFloat(val).toFixed(2)}` : `Recall: 0`;
            },
          },
          y: {
            show: true,
            formatter(val) {
              return val ? `Precision: ${parseFloat(val).toFixed(2)}` : `Precision: 0`;
            },
          },
        },
        xaxis: {
          title: {
            text: `Recall`,
            style: {
              fontSize: '14px',
              fontWeight: 600,
            },
          },
          type: 'numeric',
          labels: {
            formatter(val) {
              return val.toFixed(1);
            },
          },
          tickAmount: 10,
          min: 0.0,
          max: 1.0,
        },
        yaxis: {
          title: {
            text: `Precision`,
            style: {
              fontSize: '14px',
              fontWeight: 600,
            },
          },
          labels: {
            formatter(val) {
              return val.toFixed(1);
            },
            style: {
              fontSize: '12px',
            },
          },
          tickAmount: 10,
          min: 0.0,
          max: 1.0,
        },
        legend: {
          show: true,
          showForSingleSeries: true,
          position: 'bottom',
        },
        markers: {
          size: 0,
        },
        stroke: {
          width: 1.5,
        },
      },
      series: [],
    };
  },
  watch: {
    validation: {
      deep: true,
      handler(data) {
        if (data) {
          this.updateChart();
        }
      },
    },
  },
  mounted() {
    if (this.validation) {
      this.updateChart();
    }
    this.$nextTick(() => {
      window.dispatchEvent(new Event('resize'));
    });
  },
  methods: {
    // update value of each column
    updateChart() {
      const prData = this.validation.results['precision_recall_data'];
      const localSeries = [];
      const labels = [];
      Object.values(this.validation.results["index_name_map"]).forEach((name, i) => {
        const obj = {
          name,
          data: [],
        };
        let j = 0;
        prData['recall'][i].forEach((key) => {
          obj.data.push([key, prData['precision'][i][j]]);
          j += 1;
        });
        obj.data.push([1.0, prData['precision'][i][j]]);
        localSeries.push(obj);
        labels.push(name);
      });

      this.series = localSeries;
      this.chartOptions = {
        ...this.chartOptions,
        ...{
          xaxis: {
            categories: labels,
          },
          colors: this.getColors(),
        },
      };
    },
    getColors() {
      const colors = {};
      Object.values(this.validation.results["index_name_map"]).forEach((label, i) => {
        if (i < 12) {
          colors[label] = this.getColorByIndex(i);
        } else {
          colors[label] = this.getColorByIndexFromScale(i);
        }
      });
      return Object.values(colors);
    },
    getColorByIndexFromScale(index) {
      const hue = index * 137.508 + 60; // use golden angle approximation
      return `hsl(${hue},100%,50%)`;
    },
    getColorByIndex(index) {
      const colors = ['#ffff00', '#00ff00', '#ffa500', '#ff0000', '#48d1cc', '#a0522d', '#ffc0cb', '#ff1493', '#f0e68c', '#1e90ff', '#0000ff', '#00fa9a'];
      return colors[index % 12];
    },
  },
};

</script>

<style scoped lang="scss">

.stats-graph {
  height: 100%;
  min-height: 500px;
  width: 100%;
  box-shadow: 0 0 10px rgba(0,0,0,0.1), 0 0 5px rgba(0,0,0,0.25);
  border-radius: 8px;
  padding-top: 10px;
  padding-left: 10px;
  padding-right: 15px;
  @include themify() {
    background: themed('card-color');
    color: themed('comparison-table-text-color');
  }
}

.table-header {
  display: flex;
  align-items: center;

}
.title {
  display: flex;
  align-items: center;
  margin-left: 15px;
  margin-right: 15px;
  font-size: 1.3rem;
  font-weight:700;
}

.apexcharts-yaxis-texts-g {
  margin-left: 10px;
}
</style>
