<template>
  <div class="progress-card">
    <div class="progress-card__text">
      <span>Progress</span>
      <div class="progress-card__text-value">
        <SVGIcon
          :iconName="'image'"
          :width="'18px'"
          :height="'18px'"
        />
        <span>{{ (totalImagesCount - remainingImagesCount) }} / {{ totalImagesCount }}</span>
      </div>
    </div>
    <div class="progress-card__bar">
      <v-progress-circular
        :rotate="360"
        :width="3"
        :model-value="taskProgress"
        color="color-primary"
      >
        <template #default><span>{{ taskProgress }} %</span></template>
      </v-progress-circular>
    </div>
  </div>
</template>

<script>
import SVGIcon from '@/components/SVGIcon.vue';
import useTasks from '@/composables/annotationTool/useTasks.js';
import {
  computed,
} from 'vue';

export default {
  name: 'TaskProgressCard',
  components: {
    SVGIcon,
  },
  setup(props, ctx) {
    const {
      remainingImagesCount,
      totalImagesCount,
    } = useTasks();

    const taskProgress = computed(() => {
      if (totalImagesCount.value !== 0) {
        return Math.floor(((totalImagesCount.value - remainingImagesCount.value) / totalImagesCount.value) * 100);
      }
      return 0;
    });

    return {
      remainingImagesCount,
      totalImagesCount,
      taskProgress,
    };
  },
};
</script>

<style lang="scss" scoped>
.progress-card {
  display: flex;
  flex-direction: row;
  width: fit-content;
  border-radius: 8px;
  background: var(--color-primary-400);

  &__text {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 2px 8px;
    font-size: 0.75rem;
    font-weight: 600;
    color: var(--primary-text-color-secondary);
    align-items: flex-start;
    white-space: nowrap;
  }

  &__text-value {
    display: flex;
    flex-direction: row;
    font-size: 0.75rem;
    font-weight: 600;
    color: var(--primary-text-color);
    white-space: nowrap;
    gap: 4px;
  }

  &__bar {
    display: flex;
    width: 100%;
    margin: 2px 8px;
    font-size: 0.625rem;
    color: var(--color-accent);
    align-items: center;
    justify-content: center;
    .v-progress-circular span {
      color: var(--primary-text-color);
    }
  }
}
</style>
