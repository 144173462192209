import DatastoreConnect from '@/assets/js/DatastoreFunctions/datastore-interface';
import { ref, computed } from 'vue';

export default function useImagesFetch() {
  const dataConnect = new DatastoreConnect();
  const controller = ref(new AbortController());

  function getImagesFilterParams(filters) {
    const params = {
      images_filter: {},
      image_files_filter: {},
      annotations_filter: {},
    };

    // Images filter
    if (filters.groupsFilter && filters.groupsFilter.length > 0) {
      params.images_filter.group_ids = filters.groupsFilter;
    }

    // Annotations filter
    if (filters.confidenceRangeFilter) {
      params.annotations_filter.lower_score = filters.confidenceRangeFilter[0];
      params.annotations_filter.upper_score = filters.confidenceRangeFilter[1];
    }
    if (filters.annotationSetFilter && filters.annotationSetFilter.length > 0) {
      params.annotations_filter.annotation_set_ids = filters.annotationSetFilter.map((set) => set.id);
    }
    if (filters.imagesWithAnnotationSetFilter && filters.imagesWithAnnotationSetFilter.length > 0) {
      params.annotations_filter.images_with_annotation_set_ids = filters.imagesWithAnnotationSetFilter.map((set) => set.id);
    }
    if (filters.annotationLabelFilter && filters.annotationLabelFilter.length > 0) {
      params.annotations_filter.images_with_annotation_label_indexes = filters.annotationLabelFilter;
    }
    if (filters.boundingBoxSizeFilter) {
      Object.entries(filters.boundingBoxSizeFilter).forEach(([key, value]) => {
        if (key !== 'type' && value) {
          params.annotations_filter[`${key}_${filters.boundingBoxSizeFilter.type}`] = value;
        }
      });
    }

    // Image files filter
    if (filters.imageNameFilter && filters.imageNameFilter.length > 0) {
      params.image_files_filter.image_name = filters.imageNameFilter;
    }
    if (filters.timeRangeFilter) {
      params.image_files_filter.lower_time = filters.timeRangeFilter[0];
      params.image_files_filter.upper_time = filters.timeRangeFilter[1];
    }
    if (filters.dateRangeFilter) {
      if (filters.dateRangeFilter[0]) {
        params.image_files_filter.lower_date = new Date(filters.dateRangeFilter[0]).toISOString();
      }
      if (filters.dateRangeFilter[1]) {
        params.image_files_filter.upper_date = new Date(filters.dateRangeFilter[1]).toISOString();
      }
    }
    if (filters.cameraSourceFilter && filters.cameraSourceFilter.length > 0) {
      params.image_files_filter.image_sources = filters.cameraSourceFilter;
    }

    // if (filters.displayAnnotationsFilter && filters.displayAnnotationsFilter.value.length > 0) {
    //   if (filters.displayAnnotationsFilter.displayType === 'byAnnotationSets') {
    //     params.annotations_filter.aggregate_annotation_set_ids = filters.displayAnnotationsFilter.value;
    //   }
    //   // if (filters.displayAnnotationsFilter.displayType === 'byAnnotationLabels') {}
    // }

    return params;
  }

  async function getImages(filters, {
    get_annotations = true,
    sort_by = 'id',
    reverse = false,
    offset = 0,
    limit = undefined,
    only_count = false,
  } = {}, signal) {
    const imageListParams = {
      ...{
        get_annotations,
        sort_by,
        reverse,
        offset,
        limit,
        only_count,
      },
      ...filters,
    };
    const imageList = await dataConnect.getFilteredImages(imageListParams, signal)
      .then((resp) => {
        if (resp.error !== undefined) {
          throw Error(resp.error);
        }
        return resp.result;
      })
      .catch((error) => {
        if (signal.aborted) {
          throw Error("aborted");
        }
        console.log(error);
        alert(error);
        return [];
      });

    return imageList;
  }

  async function getLabellingSequenceFrames(filters, {
    get_annotations = true,
    sort_by = 'id',
    reverse = false,
    offset = 0,
    limit = undefined,
    source_annotation_set_id = undefined,
    annotations_filter = undefined,
    aggregate_annotations_filter = undefined,
    review_task_id = undefined,
  } = {}, signal) {
    const imageListParams = {
      ...{
        get_annotations,
        sort_by,
        reverse,
        offset,
        limit,
        source_annotation_set_id,
        annotations_filter,
        aggregate_annotations_filter,
        review_task_id,
      },
      ...filters,
    };
    const imageList = await dataConnect.getLabellingSequenceFrames(imageListParams, signal)
      .then((resp) => {
        if (resp.error !== undefined) {
          throw Error(resp.error);
        }
        return resp.result;
      })
      .catch((error) => {
        if (signal.aborted) {
          throw Error("aborted");
        }
        console.log(error);
        alert(error);
        return [];
      });

    return imageList;
  }

  return {
    controller,
    getImages,
    getLabellingSequenceFrames,
  };
}
