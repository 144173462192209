import { ref, onBeforeUnmount } from 'vue';
import { useFetch } from '@vueuse/core';
import { useStore } from 'vuex';

export default function useFetchImage() {
  const store = useStore();
  const url = ref('');

  const {
    data: _data, execute, isFetching, error, abort, canAbort,
  } = useFetch(url, {
    async beforeFetch({ url, options, cancel }) {
      if (!store.state.user.token) cancel();

      options.headers = {
        ...options.headers,
        Authorization: `Bearer ${store.state.user.token}`,
      };

      return {
        options,
      };
    },
  }, { immediate: false }).blob();

  function getImage(id) {
    url.value = `v3/enterprise/image/${id}`;
    execute();
  }

  function getThumbnail(id) {
    url.value = `v3/enterprise/thumbnail/${id}`;
    execute();
  }

  return {
    data: _data, isFetching, error, abort, canAbort, getImage, getThumbnail,
  };
}
