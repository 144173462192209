<template>
  <JsonViewItem
    :class="[{ 'root-item': true, dark: colorScheme === 'dark' }]"
    :data="parsed"
    :maxDepth="maxDepth"
    :canSelect="hasSelectedListener"
    :loading="loading"
    :selectedAnnotationIdentifiers="selectedAnnotationIdentifiers"
    :itemId="itemId"
    :removable="removable"
    @update:selected="itemSelected"
    @open-in-full="$emit('open-in-full')"
    @opened="$emit('opened')"
    @selected="$emit('selected')"
    @remove-annotation="(params) => $emit('remove-annotation', params)"
  />
</template>

<script>
import JsonViewItem from './JsonViewItem.vue';

const JsonDataType = {
  ARRAY: 'array',
  VALUE: 'value',
  OBJECT: 'object',
};

const ColorMode = {
  LIGHT: 'light',
  DARK: 'dark',
};

export default {
  name: 'JsonView',
  components: { JsonViewItem },
  props: {
    data: {
      type: Object,
      required: true,
    },
    rootKey: {
      type: String,
      required: false,
      default: 'root',
    },
    maxDepth: {
      type: Number,
      required: false,
      default: 1,
    },
    colorScheme: {
      type: String,
      required: false,
      default: ColorMode.LIGHT,
    },
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
    selectedAnnotationIdentifiers: {
      type: Object,
      default: null,
    },
    itemId: {
      type: [Number, String],
      default: null,
    },
    removable: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:selected', 'opened', 'open-in-full', 'selected', 'remove-annotation'],
  computed: {
    parsed() {
      if (typeof this.data === 'object') {
        return this.build(this.rootKey, { ...this.data }, 0, '', true);
      }
      return {
        id: this.data.id,
        key: this.rootKey,
        type: JsonDataType.VALUE,
        path: '',
        depth: 0,
        value: this.data,
      };
    },
    hasSelectedListener() {
      return !!this.$attrs.onSelected;
    },
  },
  methods: {
    build(key, val, depth, path, includeKey) {
      if (this.isObject(val)) {
        // Build Object
        const children = [];
        for (const [childKey, childValue] of Object.entries(val)) {
          children.push(
            this.build(
              childKey,
              childValue,
              depth + 1,
              includeKey ? `${path}${key}.` : `${path}`,
              true,
            ),
          );
        }
        return {
          key,
          type: JsonDataType.OBJECT,
          depth,
          path,
          length: children.length,
          children,
        };
      } else if (this.isArray(val)) {
        // Build Array
        const children = [];
        for (let i = 0; i < val.length; i++) {
          children.push(
            this.build(
              i.toString(),
              val[i],
              depth + 1,
              includeKey ? `${path}${key}[${i}].` : `${path}`,
              false,
            ),
          );
        }
        return {
          key,
          type: JsonDataType.ARRAY,
          depth,
          path,
          length: children.length,
          children,
        };
      } else {
        // Build Value
        return {
          key,
          type: JsonDataType.VALUE,
          path: includeKey ? path + key : path.slice(0, -1),
          depth,
          value: val,
        };
      }
    },
    isObject: (val) => typeof val === 'object' && val !== null && !Array.isArray(val),
    isArray: (val) => Array.isArray(val),
    itemSelected(data) {
      this.$emit('update:selected', data);
    },
  },
};
</script>

<style lang="scss" scoped>

.root-item {

  --vjc-key-color: $color-primary;
  --vjc-arrow-size: 6px;
  --vjc-arrow-color: #444;
  --vjc-hover-color: rgba(116, 115, 115, 0.2);

  margin-left: 0;
  height: 100%;
}

.root-item.dark {
  --vjc-key-color: #80d8ff;
  --vjc-valueKey-color: #fdf6e3;
  --vjc-hover-color: rgba(255, 255, 255, 0.2);
  --vjc-arrow-color: #fdf6e3;
}

</style>
