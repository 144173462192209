<template>
  <!-- eslint-disable max-len -->
  <Popper
    v-model:show="localShow"
    :config="popperConfig"
    :trigger="'click'"
    :appendToBody="appendToBody"
  >
    <template #trigger>
      <div
        class="label-color"
        :style="sizeStyle"
        :class="{'checkered-background' : (!color || color === '')}"
      />
    </template>
    <div class="color-picker">
      <v-color-picker
        v-model="localColor"
        :modes="['rgb', 'hex']"
        flat
        @click.stop
      />
      <div class="footer">
        <button
          class="button modal-cancel-button"
          @click="handleCancelClicked"
        >
          Cancel
        </button>
        <button
          class="button modal-action-button"
          @click="handleNewColorPicked"
        >
          Confirm
        </button>
      </div>
    </div>
  </Popper>
</template>

<script>

import Popper from '@/components/Popper.vue';
import { detectOverflow } from '@popperjs/core';
import useColorParser from '@/composables/useColorParser.js';

const overflowPadding = 20;

export default {
  name: 'ColorPicker',
  components: {
    Popper,
  },
  props: {
    placement: {
      type: String,
      default: 'right',
    },
    offset: {
      type: Array,
      default: () => [-4, 4],
    },
    flip: {
      type: Boolean,
      default: true,
    },
    appendToBody: {
      type: Boolean,
      default: false,
    },
    show: {
      type: Boolean,
      default: false,
    },
    color: {
      type: [Number, String],
      default: null,
    },
    width: {
      type: [Number, String],
      default: null,
    },
    height: {
      type: [Number, String],
      default: null,
    },
    radius: {
      type: Number,
      default: 4,
    },
    labelColorMap: {
      type: Object,
      default: null,
    },
  },
  emits: ['update:show', 'update:color', 'new-color'],
  setup() {
    const {
      parseColor,
    } = useColorParser();
    return {
      parseColor,
    };
  },
  data() {
    return {
      localShow: false,
      localColor: '#00000000',
      colorPickerParent: null,
    };
  },
  computed: {
    body() {
      return document.body;
    },
    sizeStyle() {
      let width = this.width;
      let height = this.height;
      if (typeof this.width === 'number') {
        width = `${width}px`;
      }
      if (typeof this.height === 'number') {
        height = `${height}px`;
      }
      return {
        width,
        height,
        'min-width': width,
        'min-height': height,
        'background': this.color ? this.parseColor(this.color) : '#000000',
        'border-radius': `${this.radius}px`,
        'border': `0.1px solid grey`,
      };
    },
    detectOverflowModifier() {
      return {
        name: 'detectOverflowModifier',
        enabled: !this.flip,
        phase: 'main',
        requiresIfExists: ['offset'],
        fn({ state }) {
          const overflow = detectOverflow(state);
          if (state.placement === 'right') {
            state.styles.popper.maxWidth = `${state.elements.popper.offsetWidth - overflow.right - overflowPadding}px`;
          }
          if (state.placement === 'left') {
            state.styles.popper.maxWidth = `${state.elements.popper.offsetWidth - overflow.left - overflowPadding}px`;
          }
        },
      };
    },
    detectHeightOverflowModifier() {
      return {
        name: 'detectHeightOverflowModifier',
        enabled: true,
        phase: 'main',
        requiresIfExists: ['offset'],
        fn({ state }) {
          const overflow = detectOverflow(state);
          if (state.placement.startsWith('bottom')) {
            state.styles.popper.maxHeight = `${state.elements.popper.offsetHeight - overflow.bottom - overflowPadding}px`;
          } else if (state.placement.startsWith('left') || state.placement.startsWith('right')) {
            state.styles.popper.maxHeight = `${state.elements.popper.offsetHeight - overflow.bottom - overflow.top - (overflowPadding * 2)}px`;
          }
        },
      };
    },
    popperConfig() {
      return {
        placement: this.placement,
        strategy: 'fixed',
        modifiers: [
          this.detectOverflowModifier,
          this.detectHeightOverflowModifier,
          {
            name: 'offset',
            options: {
              offset: this.offset,
            },
          },
          {
            name: 'preventOverflow',
            options: {
              padding: overflowPadding,
            },
          },
          {
            name: 'flip',
            enabled: this.flip,
          },
        ],
      };
    },
  },
  watch: {
    localShow(show) {
      this.$emit('update:show', show);
    },
    color: {
      immediate: true,
      handler(c) {
        if (c) {
          this.localColor = this.parseColor(c);
        }
      },
    },
    localColor(color) {
      if (typeof color === 'string') {
        let colorCode = color.slice(1);
        if (colorCode.length === 6) {
          colorCode += 'FF';
        }
        this.$emit('update:color', this.hexToUint32(colorCode));
      }
    },
  },
  methods: {
    hexToUint32(hexString) {
      let value = parseInt(hexString, 16);
      if (Number.isNaN(value)) {
        throw new Error('Invalid hexadecimal string');
      }

      // Ensure the value is within the 32-bit unsigned integer range
      if (value < 0) {
        value += 2 ** 32;
      } else if (value > 0xFFFFFFFF) {
        value %= (2 ** 32);
      }
      return value;
    },
    uint32ToHex(uint32Value) {
      if (typeof uint32Value !== 'number' || uint32Value < 0 || uint32Value > 0xFFFFFFFF || !Number.isInteger(uint32Value)) {
        throw new TypeError('Input must be a valid 32-bit unsigned integer');
      }

      // Convert the number to a hexadecimal string
      const hexString = uint32Value.toString(16);

      // Pad the string with leading zeros to ensure it has 8 characters
      return `#${hexString.padStart(8, '0').toUpperCase()}`;
    },
    handleCancelClicked() {
      this.localColor = null;
      this.$emit('new-color', null);
      this.localShow = false;
    },
    handleNewColorPicked() {
      if (typeof this.localColor === 'string') {
        let colorCode = this.localColor.slice(1);
        if (colorCode.length === 6) {
          colorCode += 'FF';
        }
        if (colorCode.length === 8) {
          colorCode = colorCode.slice(0, -2);
          colorCode += 'FF';
        }
        this.$emit('new-color', this.hexToUint32(colorCode));
        this.localShow = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>

.popper {
  padding: 4px;
  color: #000000;
  margin: 0;
  z-index: 1500;
}

.checkered-background {
  background:
    repeating-conic-gradient(#b5b4b4 0% 25%, transparent 0% 50%)
      50% / 10px 10px !important;
}

.color-picker {
  position: relative;
}

:deep() {
  .v-color-picker__controls {
    height: 220px;
  }
}

.footer {
  position: absolute;
  display: flex;
  flex-direction: row;
  padding: 0.75rem 2rem;
  justify-content: flex-end;
  top: 310px;
  left: 70px;

  button + button {
    margin-left: 10px;
  }
}

</style>
