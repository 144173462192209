<template>
  <div
    class="pane-header"
    draggable="true"
    @dragstart="handleDragStart"
  >
    <div class="pane-header__start" />
    <div class="pane-header__end">
      <SelectVisualizationMenu @visualization-selected="handleVisualizationSelect" />
      <IconButton
        v-if="!hasOnlyOneDisplay && paneValue !== 'canvas'"
        class=""
        :type="'click'"
        :icon="'close'"
        :width="20"
        :height="20"
        @click="close"
      />
    </div>
  </div>
  <div
    :id="`pane-${location.join('_')}`"
    ref="pane"
    class="pane"
    :class="{'_drag-over': isOverDropZone, '_background': !Boolean(paneValue)}"
    @click="(e) => handlePaneClick"
  />
</template>

<script setup>
import { useDropZone } from '@vueuse/core';
import {
  ref,
} from 'vue';
import IconButton from '@/components/IconButton.vue';
import SelectVisualizationMenu from '@/components/DatasetComponent/AnnotationTool/SelectVisualizationMenu.vue';

const emit = defineEmits(['drop', 'close', 'visualization-selected']);

const pane = ref(null);

const { location, paneValue } = defineProps({
  location: {
    type: Array,
    default: null,
  },
  paneValue: {
    type: String,
    default: null,
  },
  hasOnlyOneDisplay: {
    type: Boolean,
    default: false,
  },
});

function handleVisualizationSelect(visualization) {
  emit('visualization-selected', { visualization, location });
}

function handleDragStart(e) {
  if (paneValue) {
    e.dataTransfer.setData('text/plain', paneValue);
  }
}

function close() {
  emit('close');
}

function onDrop(files, event, location) {
  const draggedVisualization = event.dataTransfer.getData('text/plain');
  if (draggedVisualization) {
    emit('drop', { draggedVisualization, location });
  }
}
function onOver(files, event) {
  console.log(files, event);
}
function onEnter(files, event) {
  console.log(files, event);
}
function onLeave(files, event) {
  console.log(files, event);
}
function testDragDataTypes(types) {
  if (types.length === 0) {
    return true;
  }
  return false;
}
const { isOverDropZone } = useDropZone(pane, {
  onDrop: (files, event) => onDrop(files, event, location),
  // onOver,
  // onEnter,
  // onLeave,
  dataTypes: testDragDataTypes,
  multiple: true,
  preventDefaultForUnhandled: false,
});

function handlePaneClick() {

}

</script>

<style lang="scss" scoped>
.pane {
  display: flex;
  flex-direction: column;
  position: relative;
  // flex: 1 0 50%;
  min-width: 0;
  height: 100%;
  width: 100%;
  min-height: 0;

  &._background {
    background: linear-gradient(45deg, #f0f0f0 25%, #e0e0e0 25%, #e0e0e0 50%, #f0f0f0 50%, #f0f0f0 75%, #e0e0e0 75%, #e0e0e0 100%);
    background-size: 20px 20px;
  }

  &._border {
    border: 2px solid lightgray;
  }

  &._active {
    border: 2px dotted var(--color-primary-300);
  }

  &._drag-over {
    background: rgba(var(--color-primary-rgb), 0.125)
  }
}

.pane-header {
  display: flex;
  flex-direction: row;
  position: relative;
  width: 100%;
  height: 30px;
  background: white;
  justify-content: center;
  cursor: grab;

  &__start {
    display: flex;
    flex: 1 1 auto;
    align-items: center;
    padding-left: 6px;
  }
  &__end {
    display: flex;
    flex: 0 1 auto;
    align-items: center;
    padding-right: 6px;
  }
}

</style>
