import { defineStore } from 'pinia';
import { ref, watch, computed } from 'vue';

export const usePCDStore = defineStore('pcd', () => {
  const pointArray = ref([]);
  const pcdAnnotations = ref([]);
  const header = ref('');
  const fields = ref([]);
  const scale = ref(1);
  const enablePCD = ref(false);

  function $reset() {
    pointArray.value = [];
    pcdAnnotations.value = [];
    header.value = '';
    fields.value = [];
    scale.value = 1;
    enablePCD.value = false;
  }

  return {
    pointArray,
    pcdAnnotations,
    header,
    fields,
    scale,
    enablePCD,
    $reset,
  };
});
