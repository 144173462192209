<template>
  <Modal
    ref="datasetDownloadsModal"
    :title="'All Downloads'"
    :width="'550px'"
    :height="'40%'"
  >
    <!-- <template #modal-header /> -->
    <template #modal-main>
      <div class="downloads">
        <template v-if="dataset && downloads && downloads.length > 0">
          <ul class="download-list scrollbar">
            <li
              v-for="(exp, i) in downloads"
              :key="i"
              class="download-list__list-item2"
            >
              <div class="download-format">
                <div class="download-format__icon">
                  <SVGIcon
                    v-if="formatParser(exp.format) === 'detection'"
                    :width="'100%'"
                    :height="'100%'"
                    :iconName="'detection2'"
                  />
                  <SVGIcon
                    v-if="formatParser(exp.format) === 'segmentation'"
                    :width="'100%'"
                    :height="'100%'"
                    :iconName="'segments'"
                  />
                </div>
              </div>
              <div class="download-description">
                <label>{{ exp["download-file"] }}</label>
                <span>{{ dateParser(exp["createdAt"]) }}</span>
              </div>
              <div class="download-actions">
                <a :href="`downloads/${exp['dockertask-id']}`" download>
                  <IconButton
                    class="download-icon"
                    :icon="'download'"
                    :width="24"
                    :height="24"
                    :showActive="false"
                  />
                </a>
                <IconButton
                  class="cancel-icon"
                  :icon="'cancel_cross'"
                  :width="24"
                  :height="24"
                  @click.stop="handleCancelDockerTask(exp['dockertask-id'])"
                />
              </div>
            </li>
          </ul>
        </template>
        <div v-else class="download-list-empty">
          <span>No Downloads Created</span>
        </div>
      </div>
    </template>
  </Modal>
</template>

<script>
import DatastoreConnect from '@/assets/js/DatastoreFunctions/datastore-interface';
import Modal from '@/components/Modal.vue';
import IconButton from '@/components/IconButton.vue';
import SVGIcon from '@/components/SVGIcon.vue';

export default {
  name: 'DatasetDownloadsModal',
  components: {
    Modal,
    IconButton,
    SVGIcon,
  },
  props: {
    dataset: {
      type: Object,
      default: () => {},
    },
  },
  emits: ['delete-task'],
  data() {
    return {
      dataConnect: new DatastoreConnect(this.$store.state.enterpriseServerUrl),
      format: 'detection',
      downloads: [],
      link: null,
      filename: null,
    };
  },
  computed: {
    currentProject() {
      return this.$store.state.projects.currentProject;
    },
  },
  methods: {
    formatParser(f) {
      let format = "";
      if (f.includes('segment')) {
        format = 'segmentation';
      } else {
        format = 'detection';
      }
      return format;
    },
    dateParser(d) {
      const date = new Date(d);
      return date;
    },
    showModal() {
      this.$refs.datasetDownloadsModal.showModal();
      this.$nextTick(() => {
        this.getDownloads();
        this.$store.commit('notifications/updateDatasetsNotifications', {
          dataset_id: this.dataset.id,
          key: 'downloads',
          value: 0,
        });
      });
    },
    async getDownloads() {
      await this.dataConnect.getDatasetDownloads({
        project_id: this.currentProject.id,
        dataset_id: this.dataset.id,
      })
        .then((data) => {
          if (data.result) {
            this.downloads = data.result;
          }
        })
        .catch((e) => console.log(e));
    },
    async handleCancelDockerTask(taskID) {
      await this.dataConnect.deleteExport({ docker_task_id: taskID, dataset_id: this.dataset.id })
        .then(async (data) => {
          if (data.result) {
            await this.getDownloads();
          }
        });
    },
  },
};

</script>

<style lang="scss" scoped>
.downloads {
  display: flex;
  flex-direction: column;
  min-height: 0px;
  flex: 1 1 auto;
  padding: 16px;

  h3 {
    margin: 0;
    font-size: 1rem;
    text-align: left;
    @include themify() {
      color: themed('body-text-color-secondary');
    }
  }

  &__body-row {
    display: flex;
    flex: 1 1 auto;
    margin-top: 5px;
    align-items: center;
  }
}

.download-list-empty {
  display: flex;
  flex-direction: column;
  height: 100%;
  align-items: center;
  justify-content: center;
  font-size: 1.25rem;
  font-weight: bold;
  border: solid 1px black;
  border-radius: 8px;
  @include themify() {
    background: radial-gradient(circle, rgba($color-primary-300, 0.075) 50%, rgba(var(--color-primary-rgb), 0.125) 100%);
  }

  span {
    padding: 16px;
    text-align: center;
  }
}

.download-list {
  display: flex;
  flex-direction: column;
  gap: 6px;
  list-style: none;
  padding: 2px 4px;
  margin: -2px -4px;
  overflow-y: auto;

  &__list-item {
    position: relative;
    background: rgb(231, 224, 224);
    padding: 8px;
    border-radius: 8px;

    span {
      overflow-wrap: anywhere;
    }

    &:hover {
      .download-list__list-item-buttons {
        visibility: visible;
      }
    }
  }

  &__list-item-buttons {
    visibility: hidden;
    position: absolute;
    top: 6px;
    right: 6px;
    @include themify() {
      color: themed('color-primary');
    }
  }

  &__list-item2 {
    display: flex;
    flex-direction: row;
    padding: 8px;
    border-radius: 4px;
    gap: 16px;
    cursor: pointer;
    align-items: center;
    @include themify() {
      background: themed('color-white-700');
    }

    &:hover {
      box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.375), 0 0 1px 1px rgba(0, 0, 0, 0.125);
    }

    .download-format{
      display: flex;
      align-items: center;
      justify-items: center;

      &__icon {
        width: 32px;
        height: 32px;
        @include themify() {
        color: themed('color-primary');
        }
      }
    }

    .download-description {
      display: flex;
      flex-direction: column;
      flex: 1 1 150px;

      label {
        font-weight: 700;
        font-size: 0.8rem;
        white-space: nowrap;
        @include themify() {
          color: themed('color-primary');
        }
      }
      span {
        font-weight: 500;
        font-size: 0.7rem;
        @include themify() {
          color: themed('body-text-color-secondary');
        }
      }
    }

    .download-actions {
      display: flex;
      flex-direction: row;
      height: 40px;
      align-items: center;
      margin-bottom: 6px;

      a {
        color: inherit;
      }

      a + button {
        margin-left: 10px;
      }
      @include themify() {
        color: themed('icon-color-inactive');
      }
      .download-icon:hover {
        @include themify() {
          color: themed('icon-color-primary');
        }
      }

      .cancel-icon:hover {
        @include themify() {
          color: themed('color-danger');
        }
      }
    }
  }
}

</style>
