<template>
  <div class="app-page">
    <Header ref="header">
      <template #header-slot>
        <BreadcrumbNavigation />
      </template>
    </Header>
    <div class="main-page-div">
      <div class="filter-sidebar">
        <div class="filter-sidebar__container">
          <ul>
            <li class="filter-sidebar__item chkbox-list scrollbar">
              <span class="title">Item Type</span>
              <div class="type-clear-all" @click="toggleTypes"><span>{{ selectedTypes.length > 0 ? 'Clear All' : 'Select All' }}</span></div>
              <div
                v-for="(s, i) in types"
                :key="`type-checkbox-${i}`"
              >
                <div class="chkbx-li">
                  <div class="label-icon" @click.stop.prevent="selectType(s.value)">
                    <SVGIcon
                      :iconName="s.icon"
                      :width="'20px'"
                      :height="'20px'"
                    />
                    <div class="text">
                      {{ s.name }}
                    </div>
                  </div>
                  <div class="checkbox">
                    <v-checkbox
                      v-model="selectedTypes"
                      :value="s.value"
                      :hide-details="true"
                      density="compact"
                    />
                  </div>
                </div>
              </div>
            </li>
          </ul>
          <ul>
            <li class="filter-sidebar__item chkbox-list scrollbar">
              <span class="title">Date Removed</span>
              <div
                v-for="(s, i) in dates"
                :key="`type-checkbox-${i}`"
              >
                <div class="chkbx-li">
                  <div class="label-icon" @click.stop.prevent="selectDate(s.value)">
                    <div class="text">
                      {{ s.name }}
                    </div>
                  </div>
                  <div class="checkbox">
                    <v-checkbox
                      v-model="selectedDate"
                      :value="s.value"
                      :hide-details="true"
                      density="compact"
                    />
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <main class="main-display-area">
        <div class="recycle-header">
          <h3>Recycled Items ({{ items.length }})</h3>
          <div class="actions">
            <button
              class="button button-secondary actions-button"
              :disabled="selectedItems.length === 0"
              @click="handleRestoreAll"
            >
              Restore
            </button>
            <button
              class="button button-secondary"
              :class="{ 'button-delete' : selectedItems.length > 0 }"
              :disabled="selectedItems.length === 0"
              @click="handleDeleteAll"
            >
              Delete
            </button>
          </div>
        </div>
        <div class="data-table scrollbar">
          <v-data-table
            :headers="headers"
            :items="items"
            :items-per-page="-1"
            density="comfortable"
          >
            <template #[`header.date_removed`]>
              <div class="date_removed">
                <span>Date Removed</span>
                <SVGIcon
                  iconName="arrow_upward"
                  :width="'20px'"
                  :height="'20px'"
                  class="sort-icon"
                  :class="{ 'desc' : !sortDateAsc}"
                  @click="() => sortDateAsc = !sortDateAsc"
                />
              </div>
            </template>
            <template #[`header.select`]>
              <div class="select-all checkbox">
                <v-checkbox
                  v-model="selectAll"
                  :hide-details="true"
                  density="compact"
                  :disabled="items && items.length === 0"
                />
              </div>
            </template>
            <template v-for="(header, i) in headers" :key="i" #[`item.${header.key}`]="{ item }">
              <template v-if="header.key ==='description'">
                <div class="label-icon">
                  <SVGIcon
                    :iconName="item.icon"
                    :width="'24px'"
                    :height="'24px'"
                    class="data-table-icon"
                  />
                  <div class="text">
                    {{ item[header.key] }}
                  </div>
                </div>
              </template>
              <template v-else-if="header.key ==='select'">
                <div class="select-all checkbox">
                  <v-checkbox
                    v-model="selectedItems"
                    :hide-details="true"
                    density="compact"
                    :value="item"
                  />
                </div>
              </template>
              <template v-else-if="header.key ==='actions'">
                <div class="action-icons-container">
                  <div :title="`Restore ${item['description']}`">
                    <SVGIcon
                      class="action-icon"
                      :iconName="'restore'"
                      :width="'24px'"
                      :height="'24px'"
                      @click.stop="undelete(item)"
                    />
                  </div>
                  <div :title="`Permanently remove ${item['description']}`">
                    <SVGIcon
                      class="action-icon"
                      :iconName="'trash'"
                      :width="'24px'"
                      :height="'24px'"
                      @click.stop="confirmPurge(item)"
                    />
                  </div>
                </div>
              </template>
              <template v-else>
                {{ item[header.key] }}
              </template>
            </template>
            <template #bottom />
          </v-data-table>
        </div>
      </main>
    </div>
  </div>
  <ConfirmModal
    ref="confirmModal"
    :messageHeader="confirmMessageHeader"
    :message="confirmMessage"
    :buttonClass="'button-delete'"
    :buttonText="buttonText"
    @confirmed="confirmFunction"
  />
</template>

<script>
import DatastoreConnect from '@/assets/js/DatastoreFunctions/datastore-interface';
import SVGIcon from '@/components/SVGIcon.vue';
import Header from '@/components/Header.vue';
import BreadcrumbNavigation from '@/components/BreadcrumbNavigation.vue';
import ConfirmModal from '@/components/ConfirmModal.vue';

export default {
  name: 'RecycleBin',
  components: {
    Header,
    BreadcrumbNavigation,
    SVGIcon,
    ConfirmModal,
  },
  data() {
    return {
      dsConn: new DatastoreConnect(this.$store.state.enterpriseServerUrl),
      types: [
        {
          name: "Project",
          value: "project",
          icon: "folder",
        },
        {
          name: "Dataset",
          value: "dataset",
          icon: "database2",
        },
        {
          name: "Annotation Set",
          value: "annset",
          icon: "set",

        },
        {
          name: "Train Experiment",
          value: "experiment",
          icon: "science",
        },
        {
          name: "Train Session",
          value: "train-session",
          icon: "train",
        },
        {
          name: "Automated Task",
          value: "auto-task",
          icon: "auto_annotate",
        },
        {
          name: "Validate Session",
          value: "validate",
          icon: "compare",
        },
      ],
      selectedTypes: [],
      dates: [
        {
          name: "Today",
          value: "today",
        },
        {
          name: "7 Days",
          value: "7d",
        },
        {
          name: "30 Days",
          value: "30d",
        },
        {
          name: "Current Month",
          value: "current-month",
        },
        {
          name: "Current Year",
          value: "current-year",
        },
        {
          name: "All Time",
          value: "all-time",
        },
      ],
      selectedDate: "all-time",
      sortDateAsc: true,
      headers: [
        {
          title: 'selection', key: 'select', sortable: false, width: '1%', align: 'start',
        },
        {
          title: 'Description', key: 'description', sortable: false, width: '35%', align: 'start',
        },
        {
          title: 'Project', key: 'project', width: '30%', sortable: false, align: 'start',
        },
        {
          title: 'Date Removed', key: 'date_removed', sortable: false, width: '25%', align: 'start',
        },
        {
          title: '', key: 'actions', sortable: false, width: '10%', align: 'end',
        },
      ],
      items: [],
      userList: [],
      controller: new AbortController(),
      confirmMessage: null,
      confirmMessageHeader: null,
      confirmFunction: null,
      buttonText: 'Purge',
      selectAll: false,
      selectedItems: [],
    };
  },
  computed: {
    user() {
      return this.$store.state.user.user;
    },
    projectList() {
      return this.$store.state.projects.projectList;
    },
  },
  watch: {
    selectedDate() {
      this.listDeletedItems();
    },
    selectedTypes() {
      this.listDeletedItems();
    },
    sortDateAsc(val) {
      this.sortByDate(val);
    },
    selectAll(val) {
      if (val) {
        this.selectedItems = this.items;
      } else {
        this.selectedItems = [];
      }
    },
    items(i) {
      if (i && i.length === 0) {
        this.selectAll = false;
      }
    },
  },
  created() {
    this.types.forEach((t) => {
      this.selectedTypes.push(t.value);
    });
    this.getUserList();
    this.listDeletedItems();
  },
  methods: {
    sortByDate(val) {
      if (val) {
        this.items.sort((a, b) => new Date(a.date_removed) - new Date(b.date_removed));
      } else {
        this.items.sort((a, b) => new Date(b.date_removed) - new Date(a.date_removed));
      }
    },
    async listDeletedItems() {
      let deleted_start = "";
      let deleted_end = "";
      let current = new Date();

      switch (this.selectedDate) {
      case 'today':
        deleted_end = current.toISOString();
        current.setHours(0, 0, 0, 0);
        deleted_start = current.toISOString();
        break;
      case '7d':
        deleted_end = current.toISOString();
        current.setDate(current.getDate() - 7);
        deleted_start = current.toISOString();
        break;
      case '30d':
        deleted_end = current.toISOString();
        current.setDate(current.getDate() - 30);
        deleted_start = current.toISOString();
        break;
      case 'current-month':
        deleted_end = current.toISOString();
        current.setDate(1);
        current.setHours(0, 0, 0, 0);
        deleted_start = current.toISOString();
        break;
      case 'current-year':
        deleted_end = current.toISOString();
        current = new Date(Date.UTC(current.getFullYear(), 0, 1));
        current.setHours(0, 0, 0, 0);
        deleted_start = current.toISOString();
        break;
      case 'all-time':
        deleted_end = current.toISOString();
        deleted_start = (new Date(Date.UTC(0))).toISOString();
        break;
      default:
      }
      this.controller.abort();
      this.controller = new AbortController();
      const currentController = this.controller;

      await this.dsConn.getListOfRecycledItems({
        types: this.selectedTypes,
        deleted_start,
        deleted_end,
      }, currentController.signal).then((data) => {
        if (data.result) {
          this.parseData(data.result);
        }
      }).catch((e) => console.log(e));
    },
    confirmPurge(item) {
      this.confirmMessage = `Are you sure you want to permanently remove "${item['description']}"?`;
      this.confirmMessageHeader = 'Remove Item';
      this.buttonText = 'Remove';
      this.$refs.confirmModal.showModal();
      this.confirmFunction = () => {
        this.purge(item);
      };
    },
    parseData(data) {
      this.items = [];
      data['projects'].forEach((project) => {
        this.items.push({
          icon: 'folder',
          type: 'project',
          id: project.id,
          description: project.name,
          project: project.name,
          date_removed: this.formatTime(project.deleted),
        });
      });
      data['datasets'].forEach((dataset) => {
        this.items.push({
          icon: 'database2',
          type: 'dataset',
          id: dataset.id,
          description: dataset.name,
          project: this.findProjectNameById(dataset.project_id),
          date_removed: this.formatTime(dataset.deleted),
        });
      });
      data['annotation_sets'].forEach((annset) => {
        this.items.push({
          icon: 'set',
          type: 'annset',
          id: annset.id,
          description: annset.name,
          project: this.findProjectNameById(annset.project_id),
          date_removed: this.formatTime(annset.deleted),
        });
      });
      data['experiments'].forEach((exp) => {
        this.items.push({
          icon: 'science',
          type: 'experiment',
          id: exp.id,
          description: exp.name,
          project: this.findProjectNameById(exp.project_id),
          date_removed: this.formatTime(exp.deleted),
        });
      });
      data['training_sessions'].forEach((session) => {
        this.items.push({
          icon: 'train',
          type: 'train-session',
          id: session.id,
          description: session.name,
          project: this.findProjectNameById(session.project_id),
          date_removed: this.formatTime(session.deleted),
        });
      });
      data['automated_tasks'].forEach((task) => {
        this.items.push({
          icon: 'auto_annotate',
          type: 'auto-task',
          id: task.id,
          description: task.name,
          project: this.findProjectNameById(task.project_id),
          date_removed: this.formatTime(task.deleted),
        });
      });
      data['validate_sessions'].forEach((session) => {
        this.items.push({
          icon: 'compare',
          type: 'validate',
          id: session.id,
          description: `${session.params.type.charAt(0).toUpperCase() + session.params.type.slice(1)} Validation (${session.id})`,
          project: this.findProjectNameById(session.project_id),
          date_removed: this.formatTime(session.deleted),
        });
      });
      this.sortByDate(this.sortDateAsc);
    },
    async getUserList() {
      await this.dsConn.getUserListByOrganization({ organization_id: this.user.organization_id })
        .then((data) => {
          if (data.result) {
            this.userList = data.result.reverse();
          }
        }).catch((e) => console.log(e));
    },
    findProjectNameById(id) {
      if (this.projectList && this.projectList.length > 0) {
        const project = this.projectList.find((e) => e.id === id);
        if (project) {
          return project.name;
        } else {
          return "";
        }
      } else { return ""; }
    },
    toggleTypes() {
      if (this.selectedTypes.length > 0) {
        this.selectedTypes = [];
      } else {
        const notIn = this.types.filter((e) => !this.selectedTypes.includes(e.value)).map((e) => e.value);
        this.selectedTypes = this.selectedTypes.concat(notIn);
      }
    },
    formatTime(str) {
      return `${new Date(str).toLocaleTimeString('default', {
        year: 'numeric', month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric', hour12: true,
      })}`;
    },
    async undelete(item) {
      await this.dsConn.undeleteRecycledItem({
        type: item.type,
        item_id: item.id,
      })
        .then(() => {
          this.listDeletedItems();
        });
    },
    async purge(item) {
      await this.dsConn.purgeRecycledItem({
        type: item.type,
        item_id: item.id,
      })
        .then(() => {
          this.listDeletedItems();
        });
    },
    selectType(str) {
      const index = this.selectedTypes.indexOf(str);
      if (index !== -1) {
        this.selectedTypes = this.selectedTypes.filter((type) => type !== str);
      } else {
        this.selectedTypes.push(str);
      }
    },
    selectDate(str) {
      this.selectedDate = str;
    },
    handleDeleteAll() {
      this.confirmMessage = `Are you sure you want to permanently remove all ${this.selectedItems.length} item(s)?`;
      this.confirmMessageHeader = 'Remove All';
      this.buttonText = 'Remove';
      this.$refs.confirmModal.showModal();
      this.confirmFunction = () => {
        this.deleteAll();
      };
    },
    deleteAll() {
      this.selectedItems.forEach((item) => {
        this.purge(item);
      });
      this.selectAll = false;
    },
    handleRestoreAll() {
      this.confirmMessage = `Are you sure you want to restore all ${this.selectedItems.length} item(s)?`;
      this.confirmMessageHeader = 'Restore All';
      this.buttonText = 'Restore';
      this.$refs.confirmModal.showModal();
      this.confirmFunction = () => {
        this.restoreAll();
      };
    },
    restoreAll() {
      this.selectedItems.forEach((item) => {
        this.undelete(item);
      });
      this.selectAll = false;
    },
  },
};
</script>

<style lang="scss" scoped>

.filter-sidebar {
  min-width: 250px;
  width: 250px;
  @include themify() {
    background-color: themed('background-light-gray');
  };
  padding: 10px 10px 30px 10px;

  &__container {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    height: 100%;
    padding: 0 10px 0 10px;

    ul {
      list-style-type: none;
    }

    ul + ul {
      margin-top: 10px;
    }

    li {
      display: flex;
      flex-direction: row;
      margin-top: 2px;
      margin-bottom: 2px;
      font-size: 0.9rem;
      padding: 4px;
      border-radius: 4px;
    }
  }

  &__item{
    display: flex;
    flex-direction: column;
    color: var(--color-black) !important;
  }

  .chkbox-list {
      display: flex;
      flex-direction: column;
      flex: 1 1 50%;
      margin: 0;

      .title {
        font-size: 1.1rem;
      }

      .type-clear-all {
        display: flex;
        flex-direction: row-reverse;
        margin-top: 5px;
        span {
          font-size: 0.85rem;
          color: var(--color-primary);
          cursor: pointer;
        }
      }

      .chkbx-li {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        height: 35px;
      }

      .checkbox {
        width: 28px;
        max-width: 28px;
      }

    }
}

.label-icon {
  display: flex;
  flex-direction: row;
  cursor: pointer;
  align-items: center;
  color: var(--color-black) !important;

  :nth-child(2) {
    margin-left: 5px;
  }

  .text {
    font-size: 0.9rem;
  }

  .data-table-icon {
    width: 24px;
    min-width: 24px;
  }
}

.action-icons-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  visibility: hidden;
  cursor: pointer;

  :nth-child(1) + :nth-child(2) {
    margin-left: 10px;
  }

  .action-icon {
    @include themify() {
      color: themed('body-text-color-secondary');
    }
  }

  .action-icon:hover {
    @include themify() {
      color: themed('color-primary');
      background: themed('icon-hover-color');
      border-radius: 100%;
      box-shadow: 0 0 0 4px themed('icon-hover-color');
    }
  }
}

.select-all {
  display: flex;
  flex-direction: row;
  width: 15px;
}

.date_removed {
  display: flex;
  flex-direction: row;

  .sort-icon {
    margin-left: 5px;
    cursor: pointer;

    &.desc{
      transform: rotate(180deg);
    }
  }
}

.recycle-header {
  width: calc(100% - 40px);
  padding: 15px;
  margin: 20px;
  display: flex;
  height: 36px;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  .actions {
    button {
      width: 90px;
      height: fit-content;
    }
    button + button {
      margin-left: 20px;
    }
  }
}

.data-table {
  width: calc(100% - 40px);
  height: 100%;
  padding: 0 15px 15px 15px;
  border-radius: 8px;
  margin: 0 20px 20px 20px;
  @include themify() {
    color: themed('body-text-color');
  }
  overflow-y: auto;
}

:deep(.v-table) {
  background: transparent;
  font-size: 0.95rem !important;
  border-radius: 0;
}

:deep(tbody > tr:hover) {
  background: var(--color-white-900);

  .action-icons-container {
    visibility: visible;
  }
}

</style>
