<template>
  <div class="group-li-card">
    <IconButton
      class="delete-group-icon"
      :icon="'close'"
      :width="15"
      :height="15"
      @click.stop="$emit('delete-group')"
    />
    <label class="font-weight-bold">{{ name }}</label>
    <p>{{ `${count} imgs` }}</p>
  </div>
</template>

<script>
import IconButton from '@/components/IconButton.vue';

export default {
  name: "AnnotationSetListItem",
  components: {
    IconButton,
  },
  props: {
    name: {
      type: String,
      default: '',
    },
    count: {
      type: Number,
      default: 0,
    },

  },
  emits: ['delete-group'],
};
</script>

<style lang="scss">
.group-li-card {
  position: relative;
  width: 135px;
  border-radius: 6px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  border: 2px solid rgb(69, 69, 69);

  label {
    font-size: 0.9rem;
  }

  p {
    font-size: 0.9rem;
    font-weight: 600;
  }
  label, p {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .delete-group-icon {
    position: absolute;
    right: 2px;
    top: 2px;
    visibility: hidden;
  }

  &:hover .delete-group-icon {
    z-index: 3;
    visibility: visible;
    @include themify() {
      background: transparent;
      border-radius: 100%;
      color: themed('color-primary')
    }
  }

}

</style>
