<template>
  <div
    id="annotation-editor-container"
    ref="annotationReviewContainer"
    tabindex="-1"
  >
    <v-layout
      :full-height="true"
      class="flex-column"
    >
      <div
        ref="annotationReview"
        class="annotation-editor"
      >
        <div class="annotation-editor__details">
          <div class="annotation-editor__sidebar annotation-editor__image-details">
            <h3 class="annotation-editor__sidebar-heading">Image Details</h3>
            <ImageDetails
              v-if="displayImage"
              :imageObj="displayImage"
              :options="imageDetailSettings"
              :sourceDataset="sourceDataset"
              @image-group-changed="handleImageGroupChanged"
            />
          </div>
          <div class="annotation-editor__sidebar  annotation-editor__annotation-details">
            <h3 class="annotation-editor__sidebar-heading">Annotation Details</h3>
            <div class="annotation-editor__annotation-details-inner">
              <AnnotationReviewAnnotationDetails v-if="annotation" :annotationObj="annotation" />
            </div>
            <template v-if="annotationCount > 0">
              <div v-if="!isFetchingNewImage && imageLoadedOnCanvas" class="annotation-editor__annotation-progress">
                <p>Annotation {{ activeAnnotationIndex + 1 }} / {{ annotationCount }}</p>
                <div class="annotation-editor__annotation-nodes">
                  <div
                    v-for="(anno, index) in annotations"
                    :key="index"
                    :class="{_verified: anno.reviewStatus === 'verified', _rejected: anno.reviewStatus === 'rejected', _active: index === activeAnnotationIndex}"
                  >
                    <div />
                  </div>
                </div>
              </div>
              <div v-else class="annotation-editor__annotation-progress p-0">
                <v-skeleton-loader
                  type="sentences"
                  theme="primary"
                  color="transparent"
                  :height="80"
                />
              </div>
            </template>
          </div>
        </div>
        <div class="annotation-editor__content">
          <div class="annotation-editor__image" :class="{_verified: annotation?.reviewStatus === 'verified', _rejected: annotation?.reviewStatus === 'rejected'}">
            <div class="annotation-editor__image-header">
              <div class="card-header">
                <h3 class="card-header__title">
                  <BaseMenu
                    :placement="'left-start'"
                    :appendToBody="true"
                    @closed="show_menu = false"
                  >
                    <button>{{ annotationTitle }}</button>
                    <template #menu="{closeMenu}">
                      <ul class="label-menu scrollbar">
                        <li v-for="(label, i) in labels" :key="i">
                          <BaseMenuButton @click="handleLabelChanged(label), closeMenu()">
                            <span>{{ label.name }}</span>
                          </BaseMenuButton>
                        </li>
                      </ul>
                    </template>
                  </BaseMenu>
                </h3>
                <SVGIcon
                  v-if="annotation?.reviewStatus === 'verified'"
                  class="review-status-icon verified-icon _verified"
                  :iconName="verifiedIcon"
                  :width="20"
                  :height="20"
                />
                <SVGIcon
                  v-if="annotation?.reviewStatus === 'rejected'"
                  class="review-status-icon rejected-icon _rejected"
                  :iconName="rejectedIcon"
                  :width="20"
                  :height="20"
                />
              </div>
            </div>
            <div class="annotation-editor__image-canvas">
              <AnnotationCanvas
                v-if="displayImage"
                ref="annotationCanvas"
                :annotations="canvasAnnotationArray"
                :imageObj="displayImage"
                :labels="labels"
                :editingEnabled="true"
                :shouldZoomToFit="false"
                :enableSAM="false"
                @image-loaded="imageLoadedOnCanvas = true"
              />
              <div v-else class="no-image-remaining"><span>No Images Remaining</span></div>
              <v-skeleton-loader
                v-if="isFetchingNewImage || !imageLoadedOnCanvas"
                type="image"
                class="image-loading"
              />
            </div>
          </div>
          <div class="annotation-editor__controls">
            <v-btn class="key-button" density="comfortable" @click="handleEnter">
              <div class="key">ENTER</div>
              <span>Accept</span>
            </v-btn>
            <v-btn class="key-button" density="comfortable" @click="handleSpace">
              <div class="key">SPACE</div>
              <span>Reject</span>
            </v-btn>
            <v-btn class="key-button" density="comfortable" @click="handleLeftArrow">
              <div class="key">&#8592;</div>
              <span>Previous</span>
            </v-btn>
            <v-btn class="key-button" density="comfortable" @click="handleRightArrow">
              <div class="key">&#8594;</div>
              <span>Next</span>
            </v-btn>
            <v-btn class="key-button" density="comfortable" @click="handleShiftRightArrow">
              <div class="key">SHIFT</div>
              <div class="key ms-1">&#8594;</div>
              <span>NEXT IMAGE</span>
            </v-btn>
            <v-btn class="key-button" density="comfortable" @click="handleDelete">
              <div class="key">DELETE</div>
              <span>DELETE IMAGE</span>
            </v-btn>
            <v-btn
              v-if="zoomedIn"
              class="key-button"
              density="comfortable"
              @click="handleZKey"
            >
              <div class="key">Z</div>
              <span>ZOOM OUT</span>
            </v-btn>
            <v-btn
              v-else
              class="key-button"
              density="comfortable"
              @click="handleZKey"
            >
              <div class="key">Z</div>
              <span>ZOOM IN</span>
            </v-btn>
          </div>
        </div>
        <div class="annotation-editor__sidebar annotation-editor__sidebar-hotkeys">
          <h3 class="annotation-editor__sidebar-heading">Label Hotkeys</h3>
          <div class="annotation-editor__sidebar-hotkeys-inner _scroll scrollbar">
            <ul class="annotation-editor__label-hotkeys">
              <li v-for="label in labels" :key="label.index" @click="handleLabelListClick(label)">
                <span>{{ label.name }}</span>
                <div>
                  <div v-for="(key, index) in getLabelKeyArray(label)" :key="index" class="key">{{ key }}</div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <AnnotationEditorHistoryDrawer
        v-if="reviewSettings !== null && reviewSettings !== undefined"
        :reviewSettings="reviewSettings"
        :active="true"
        :images="doneImages"
        :currentImage="currentTaskImage"
        :pendingImages="pendingImages"
        :activeIndex="activeImageIndex"
        :component="'footer'"
        :height="56"
        @image-clicked="handleTaskImageClicked"
      />
    </v-layout>
  </div>
</template>

<script>
import BaseMenu from '@/components/BaseMenu.vue';
import BaseMenuButton from '@/components/BaseMenuButton.vue';
import SVGIcon from '@/components/SVGIcon.vue';
import AnnotationEditorHistoryDrawer from '@/components/DatasetComponent/AnnotationTool/AnnotationEditorHistoryDrawer.vue';
import ImageDetails from '@/components/DatasetComponent/AnnotationTool/ImageDetails.vue';
import AnnotationReviewAnnotationDetails from '@/components/DatasetComponent/AnnotationTool/AnnotationReviewAnnotationDetails.vue';
import { useKeyModifier } from '@vueuse/core';
import AnnotationCanvas from '@/components/DatasetComponent/AnnotationTool/AnnotationCanvas.vue';
import DatastoreConnect from '@/assets/js/DatastoreFunctions/datastore-interface';
import useTasks from '@/composables/annotationTool/useTasks.js';

export default {
  name: 'AnnotationReview',
  components: {
    BaseMenu,
    BaseMenuButton,
    SVGIcon,
    AnnotationEditorHistoryDrawer,
    ImageDetails,
    AnnotationReviewAnnotationDetails,
    AnnotationCanvas,
  },
  props: {
    reviewSettings: {
      type: Object,
      default: null,
    },
    activeImageIndex: {
      type: Number,
      default: -1,
    },
    currentTaskImage: {
      type: Object,
      default: () => {},
    },
    pendingImages: {
      type: Array,
      default: () => [],
    },
    annotations: {
      type: Array,
      default: () => [],
    },
    labels: {
      type: Array,
      default: () => [],
    },
    originX: {
      type: Number,
      default: 0,
    },
    originY: {
      type: Number,
      default: 0,
    },
    enableAnnotationNavigation: {
      type: Boolean,
      default: true,
    },
  },
  emits: [
    'image-clicked',
  ],
  setup() {
    const shiftState = useKeyModifier('Shift');

    const {
      internalImageObj,
      isFetchingNewImage,
      sourceDataset,
      skipTaskImage,
      deleteTaskImageFromDataset,
      handleImageGroupChanged,
      updateTaskImages,
      switchToNextTaskImage,
      getTaskDoneImagesByUser,
      handleAuditSubmitAcceptedAnnotations,
      switchToPreviousTaskImage,
      doneImages,
    } = useTasks();

    return {
      internalImageObj,
      isFetchingNewImage,
      shiftState,
      sourceDataset,
      skipTaskImage,
      deleteTaskImageFromDataset,
      handleImageGroupChanged,
      updateTaskImages,
      switchToNextTaskImage,
      getTaskDoneImagesByUser,
      handleAuditSubmitAcceptedAnnotations,
      switchToPreviousTaskImage,
      doneImages,
    };
  },
  data() {
    return {
      title: 'Annotation Review',
      activeAnnotationIndex: 0,
      keysPressedWhileShiftHeld: [],
      imageLoadedOnCanvas: false,
      dataConnect: new DatastoreConnect(),
      zoomedIn: true,
    };
  },
  computed: {
    displayImage() {
      return this.isFetchingNewImage ? {} : this.internalImageObj;
    },
    imageCrop() {
      if (this.annotation) {
        return {
          x: this.annotation.x - (this.originX * this.annotation.w),
          y: this.annotation.y - (this.originY * this.annotation.h),
          w: this.annotation.w,
          h: this.annotation.h,
        };
      }
      return null;
    },
    annotation() {
      if (this.annotations.length && this.activeAnnotationIndex >= 0 && !this.isFetchingNewImage && this.imageLoadedOnCanvas) {
        return this.annotations[this.activeAnnotationIndex];
      }
      return null;
    },
    canvasAnnotationArray() {
      if (this.annotation && this.imageLoadedOnCanvas) {
        return [this.annotation];
      }
      return [];
    },
    verifiedIcon() {
      if (this.annotation?.reviewStatus === 'verified') {
        return 'verified';
      }
      return 'done_outline';
    },
    rejectedIcon() {
      if (this.annotation?.reviewStatus === 'rejected') {
        return 'dangerous';
      }
      return 'dangerous_outlined';
    },
    hasPreviousAnnotation() {
      if (this.activeAnnotationIndex > 0) {
        return true;
      } else if (this.activeAnnotationIndex === 0 && this.doneImages.length > 0) {
        return true;
      }
      return this.activeAnnotationIndex > 0;
    },
    hasNextAnnotation() {
      return this.activeAnnotationIndex < this.annotations.length - 1;
    },
    annotationTitle() {
      if (this.annotation) {
        let title = '';
        let label = this.annotation.label_index;
        let score = this.annotation.score;
        if (this.labels.length > 0) {
          label = this.labels[this.annotation.label_index]?.name;
        }
        title = label;
        if (this.annotation.score != null) {
          score = this.annotation.score;
          title = `${title} [${score.toPrecision(2)}]`;
        }
        return title;
      }
      return '';
    },
    annotationCount() {
      return this.annotations.length;
    },
    reviewedAnnotationCount() {
      return this.annotations.filter((anno) => anno.reviewStatus).length;
    },
    verifiedAnnotationCount() {
      return this.annotations.filter((anno) => anno.reviewStatus === 'verified').length;
    },
    hotkeyLabelMap() {
      return Object.fromEntries(
        Object
          .entries(this.labelHotkeyMap)
          .map(([key, value]) => [value, key]),
      );
    },
    imageDetailSettings() {
      return {
        showName: true,
        showDate: true,
        showFileLocation: false,
        showExternalURL: false,
        showImageLocation: this.displayImage.latitude !== 0 && this.displayImage.longitude !== 0,
        showCameraSource: Boolean(this.displayImage.source),
      };
    },
    labelHotkeyMap() {
      const labelHotkeyMap = {};
      this.labels.forEach((label) => {
        if (label.index < 9) {
          labelHotkeyMap[label.index] = (label.index + 1).toString().split('').join('_');
        } else {
          labelHotkeyMap[label.index] = `SHIFT_${(label.index + 1 - 10).toString().split('').join('_')}`;
        }
      });
      return labelHotkeyMap;
    },
  },
  watch: {
    internalImageObj() {
      this.imageLoadedOnCanvas = false;
      if (this.activeAnnotationIndex === -1) {
        this.activeAnnotationIndex = this.internalImageObj.annotations.length - 1;
      } else {
        this.activeAnnotationIndex = 0;
      }
    },
    shiftState(pressed) {
      if (!pressed) {
        this.handleLabelHotkey(`SHIFT_${this.keysPressedWhileShiftHeld.join('_')}`);
        this.keysPressedWhileShiftHeld = [];
      }
    },
    annotation() {
      if (this.imageLoadedOnCanvas && this.annotation) {
        if (this.zoomedIn) {
          this.$nextTick(() => {
            this.$refs.annotationCanvas.zoomToBox({
              x1: this.annotation.x,
              x2: this.annotation.x + this.annotation.w,
              y1: this.annotation.y,
              y2: this.annotation.y + this.annotation.h,
            });
          });
        } else {
          this.$refs.annotationCanvas.zoomToFitImage();
        }
      } else if (this.imageLoadedOnCanvas && !this.annotation) {
        this.$refs.annotationCanvas.zoomToFitImage();
      }
    },
    imageLoadedOnCanvas(isLoaded) {
      if (isLoaded && !this.annotation) {
        this.$refs.annotationCanvas.zoomToFitImage();
      }
    },
  },
  async mounted() {
    window.addEventListener('keyup', this.handleKeyup);

    await this.$nextTick();
    await this.$nextTick();
    document.querySelector('#annotation-editor-container').focus();
  },
  beforeUnmount() {
    window.removeEventListener('keyup', this.handleKeyup);
  },
  methods: {
    handleLeftArrow() {
      if (this.isFetchingNewImage) return;

      this.getPreviousAnnotation();
    },
    handleRightArrow() {
      if (this.isFetchingNewImage) return;

      if (this.shiftState) {
        this.handleShiftRightArrow();
      } else {
        this.getNextAnnotation();
      }
    },
    handleShiftRightArrow() {
      if (this.isFetchingNewImage) return;

      this.isFetchingNewImage = true;
      this.skipTaskImage()
        .catch((error) => {
          console.error(error);
          // TODO: handle error
        });
      this.isFetchingNewImage = false;
    },
    handleEnter() {
      if (this.isFetchingNewImage) return;

      if (this.annotation) {
        this.annotation.reviewStatus = 'verified';
      }
      this.getNextAnnotation();
    },
    handleSpace() {
      if (this.isFetchingNewImage) return;

      if (this.annotation) {
        this.annotation.reviewStatus = 'rejected';
      }
      this.getNextAnnotation();
    },
    async handleDelete() {
      if (this.isFetchingNewImage) return;

      this.isFetchingNewImage = true;
      await this.deleteTaskImageFromDataset(this.internalImageObj?.id)
        .catch((error) => {
          console.error(error);
          // TODO: handle error
        });
      this.isFetchingNewImage = false;
    },
    handleZKey() {
      if (this.isFetchingNewImage) return;

      if (this.zoomedIn) {
        this.zoomedIn = false;
        this.$refs.annotationCanvas.zoomToFitImage();
      } else {
        this.zoomedIn = true;
        this.$refs.annotationCanvas.zoomToBox({
          x1: this.annotation.x,
          x2: this.annotation.x + this.annotation.w,
          y1: this.annotation.y,
          y2: this.annotation.y + this.annotation.h,
        });
      }
    },
    getNextAnnotation() {
      if (this.reviewedAnnotationCount === this.annotationCount && this.activeImageIndex === -1 && this.activeAnnotationIndex === this.annotations.length - 1) {
        this.getNewImage();
        return;
      } else if (this.reviewedAnnotationCount === this.annotationCount && this.activeAnnotationIndex === this.annotations.length - 1) {
        this.getNextImage();
        return;
      }

      if (this.activeAnnotationIndex < this.annotations.length - 1) {
        this.activeAnnotationIndex += 1;
      } else {
        this.activeAnnotationIndex = 0;
      }
    },
    getPreviousAnnotation() {
      if (this.activeAnnotationIndex > 0) {
        this.activeAnnotationIndex -= 1;
      } else if (this.activeAnnotationIndex === 0) {
        this.getPreviousImage();
        // Set to -1 so it can be set to the last annotation when the next image loads
        this.activeAnnotationIndex = -1;
      } else {
        this.getPreviousImage();
        // Set to -1 so it can be set to the last annotation when the next image loads
        this.activeAnnotationIndex = -1;
      }
    },
    async getNextImage() {
      // TODO reset boolean if fetch error
      this.isFetchingNewImage = true;
      await this.handleAuditSubmitAcceptedAnnotations(this.initialEditingAnnotations);
      if (!this.doneImageOpen) {
        await this.updateTaskImages();
      } else {
        this.switchToNextTaskImage();
        await this.getTaskDoneImagesByUser()
          .then((doneImages) => {
            this.doneImages = doneImages;
          });
      }
      this.isFetchingNewImage = false;
    },
    getPreviousImage() {
    // TODO reset boolean if fetch error
      this.isFetchingNewImage = true;
      this.switchToPreviousTaskImage();
      this.isFetchingNewImage = false;
    },
    async getNewImage() {
      // TODO reset boolean if fetch error
      this.isFetchingNewImage = true;
      await this.handleAuditSubmitAcceptedAnnotations(this.initialEditingAnnotations);
      this.isFetchingNewImage = false;
    },
    getLabelKeyArray(label) {
      const hotkey = this.labelHotkeyMap[(label.index).toString()];
      if (hotkey) {
        return hotkey.split('_');
      }
      return [];
    },
    getLabelKeys(label) {
      const keys = [];
      if (label.index < 9) {
        keys.push(label.index + 1);
      } else {
        keys.push(0);
        if (label.index) {
          keys.push(...(label.index - 8).toString().split(''));
        }
      }
      return keys;
    },
    handleKeyup(e) {
      if (this.isFetchingNewImage) return;

      switch (e.key) {
      case 'ArrowRight':
        e.stopPropagation();
        this.handleRightArrow();
        break;
      case 'ArrowLeft':
        e.stopPropagation();
        this.handleLeftArrow();
        break;
      case 'Enter':
        e.stopPropagation();
        this.handleEnter();
        break;
      case ' ':
        e.stopPropagation();
        this.handleSpace();
        break;
      case 'Delete':
        e.stopPropagation();
        this.handleDelete();
        break;
      case 'z':
      case 'Z':
        e.stopPropagation();
        this.handleZKey();
        break;
      default:
        if (this.shiftState && ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0'].includes(String.fromCharCode(e.keyCode))) {
          this.keysPressedWhileShiftHeld.push(String.fromCharCode(e.keyCode));
        } else if (['1', '2', '3', '4', '5', '6', '7', '8', '9', '0'].includes(e.key)) {
          this.handleLabelHotkey(e.key);
        }
      }
    },
    handleLabelHotkey(hotkey) {
      if (this.hotkeyLabelMap[hotkey]) {
        const selectedLabel = this.labels.find((l) => l.index.toString() === this.hotkeyLabelMap[hotkey]);
        this.annotation.label_index = selectedLabel.index;
        this.annotation.label_id = selectedLabel.id;
        this.annotation.label_name = selectedLabel.name;
        this.annotation.reviewStatus = 'verified';
        this.getNextAnnotation();
      }
    },
    handleTaskImageClicked(imageIndex) {
      this.isFetchingNewImage = true;
      this.activeAnnotationIndex = 0;
      this.$emit('image-clicked', imageIndex);
      this.$refs.annotationReviewContainer.focus();
      this.isFetchingNewImage = false;
    },
    handleLabelChanged(label) {
      this.annotation.label_index = label.index;
      this.annotation.label_id = label.id;
      this.annotation.label_name = label.name;
    },
    handleLabelListClick(label) {
      this.annotation.label_index = label.index;
      this.annotation.label_id = label.id;
      this.annotation.label_name = label.name;
      this.annotation.reviewStatus = 'verified';
      this.getNextAnnotation();
    },
  },
};
</script>

<style lang="scss" scoped>
#annotation-editor-container {
  height: 100%;
  width: 100%;

  &:focus {
    outline: none;
  }
}

.annotation-editor {
  display: flex;
  flex-direction: row;
  flex: 1 1 auto;
  padding: 24px;
  gap: 24px;
  width: 100%;
  height: inherit;
  overflow: hidden;
  background: var(--color-white-700);

  &__sidebar {
    display: flex;
    flex-direction: column;
    flex: 1 1 25%;
    border-radius: 6px;
    min-width: 150px;
    // padding: 16px;
    text-align: left;
    background: var(--color-white-100);
    box-shadow: 1px 2px 6px 1px rgba(0, 0, 0, 0.125), 0 0 1px 1px rgba(0, 0, 0, 0.125);
    overflow: hidden;

    &._scroll {
      overflow: auto;
    }
  }

  &__sidebar-heading {
    padding-left: 16px;
    color: var(--color-primary);
    font-size: 0.875rem;
    font-weight: 600;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    margin-bottom: 6px;
  }

  &__sidebar-hotkeys {
    padding: 16px 0;
    max-width: 260px;
    flex: 0 0 auto;
  }

  &__sidebar-hotkeys-inner {
    padding: 0 16px;
    &._scroll {
      overflow: auto;
    }
  }

  &__details {
    display: flex;
    flex-direction: column;
    height: 100%;
    max-width: min-content;
    gap: 24px;
  }

  &__image-details {
    flex: 0 0 min-content;
    padding: 16px 0;
  }

  &__annotation-details {
    flex: 1 1 100%;
    padding: 16px 0 0;
  }

  &__annotation-details-inner {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    overflow: hidden;
  }

  &__annotation-progress {
    display: flex;
    flex-direction: column;
    gap: 4px;
    padding: 16px;
    background: var(--color-primary-400);
    color: var(--primary-text-color);
    font-weight: 500;
  }

  &__annotation-nodes {
    display: flex;
    flex-wrap: wrap;
    gap: 4px;

    > div {
      display: flex;
      width: 20px;
      height: 20px;
      align-items: center;
      justify-content: center;

      div {
        width: 12px;
        height: 12px;
        border-radius: 50%;
        border: 2px solid var(--color-accent);
        background: transparent;
      }
    }

    div._active {
      background: rgba(var(--color-white-rgb),0.125);
      border: 1px solid rgba(var(--color-white-rgb), 0.5);
    }
    div._verified {
      div {
        background: var(--color-success);
        border: none;
      }
    }
    div._rejected {
      div {
        background: var(--color-danger);
        border: none;
      }
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    min-width: 1px;
  }

  &__image {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    width: 100%;
    min-height: 0;
    box-shadow: 1px 2px 6px 1px rgba(0, 0, 0, 0.125), 0 0 1px 1px rgba(0, 0, 0, 0.125);
    border-radius: 6px;
    overflow: hidden;
  }

  &__image-header {
    width: 100%;
    height: 48px;
    border-radius: 6px;
  }

  &__image-canvas {
    position: relative;
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    border-top: 1px solid rgba(0, 0, 0, 0.125);
    min-height: 0;
  }

  &__controls {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    padding: 24px 0 0 0;
    gap: 8px;
    font-weight: 500;
    font-size: 1rem;
    user-select: none;

    & > div {
      display: flex;
      align-items: center;
      gap: 8px;
    }
  }

  &__label-hotkeys {
    list-style: none;

    li {
      display: flex;
      justify-content: space-between;
      padding: 3px 4px;

      span {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        margin-right: 6px;
      }

      div:first-of-type {
        display: flex;
        gap: 6px;
      }
    }

    li:hover {
      padding: 2px 3px;
      border: 1px solid var(--color-primary);
      border-radius: 6px;
      background: rgba(0,0,0,0.0625);
      cursor: pointer;
    }
  }
}

.header {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: flex-end;
  padding: 0 20px;
}

.card-header {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  min-height: 42px;
  border-bottom: none;
  padding: 10px 10px 10px 10px;
  gap: 4px;
  z-index: 2;

  &__title {
    margin: 0;
    margin-right: 8px;
    font-size: 1.25rem;
    width: 100%;

    button {
      font-size: inherit;
      background: transparent;
      border: none;
      width: 100%;
      cursor: pointer;

      @include themify() {
        box-shadow: 0 0 5px themed('icon-hover-color-primary'), 0 0 1px rgb(0 0 0 / 60%);
      }

      &:hover {
        @include themify() {
          box-shadow: 0 0 8px 3px themed('icon-hover-color-primary'), 0 0 0 1px themed('color-primary');
        }
      }
    }
  }
}

.key {
  box-sizing: border-box;
  padding: 4px;
  line-height: 1;
  font-size: 12px;
  text-align: center;
  width: fit-content;
  color: #555;
  border-color: var(--gray-400);
  border-style: solid;
  text-shadow: 0 0.5px 1px #777, 0 2px 6px #f2f2f2;
  border-width: 1px;
  border-radius: 4px;
  background: -webkit-linear-gradient(top, #f9f9f9 0%, #D2D2D2 80%, #c0c0c0 100%);
  font-family: sans-serif;
  display: inline-block;
  transition: box-shadow 0.3s ease, transform 0.15s ease;
}

.key-button {
  padding: 0 8px;

  span {
    margin-left: 4px;
  }
}

.label-menu {
  min-width: 100px;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}

.review-status-icon {
  color: var(--)
}

._rejected {
  &.verified-icon {
    color: rgba(white, 0.375);
  }
  &.rejected-icon {
    color: darkred;
  }
  :deep(.grid-item-container) {
    background: rgba(var(--color-danger-rgb), 0.90) !important;
  }
}
._verified {
  &.verified-icon {
    color: var(--color-primary);
  }
  &.rejected-icon {
    color: rgba(white, 0.375);
  }
  :deep(.grid-item-container) {
    background: rgba(var(--color-primary-rgb), 0.5) !important;
  }
}

:deep(.grid-item-container) {
  flex: 1 1 auto;
  z-index: 2;
}

:deep(.v-skeleton-loader.image-loading) {
  position: absolute;
  height: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0.75;
  z-index: 2;
  .v-skeleton-loader__image {
    height: 100%;
  }
}

:deep(.v-skeleton-loader > *) {
  height: 100%;
  display: flex;
}

:deep(.image-details__list) {
  gap: 16px;
}

.no-image-remaining {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  font-size: 1.5rem;
  font-weight: 600;
  color: var(--color-primary);
}

</style>
