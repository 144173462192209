import {
  ref, computed, watch, nextTick,
} from 'vue';
import { useStore } from 'vuex';
import DatastoreConnect from '@/assets/js/DatastoreFunctions/datastore-interface';

const doneImages = ref([]);

export default function useTasksHistory(reviewSettings, internalImageObj, internalAnnotations, currentTaskImage, activeImageIndex) {
  const store = useStore();
  const datastore = new DatastoreConnect(store.state.enterpriseServerUrl);
  const doneImageOpen = computed(() => {
    if (currentTaskImage.value && internalImageObj.value.id !== currentTaskImage.value.id) {
      return true;
    }
    return false;
  });

  function startTaskHistory() {
    // Watch
    watch(internalAnnotations, (newAnnotations) => {
      // Previously audited annotations should have status verified
      if (reviewSettings.value?.reviewTask?.type === 'audit') {
        newAnnotations.forEach((anno) => {
          if (doneImageOpen.value) {
            anno.reviewStatus = 'verified';
          }
        });
      }
    });
  }

  // Methods
  async function getTaskDoneImagesByUser() {
    const params = {
      dataset_id: reviewSettings.value.reviewTask.dataset_id,
      review_task_id: reviewSettings.value.reviewTask.id,
      annotation_set_id: reviewSettings.value.reviewTask.dest_annotation_set_id,
      username: store.state.user.user.username,
    };
    const resp = await datastore.getTaskDoneImagesByUser(params)
      .catch((error) => {
        console.log(error);
        alert('Failed to get done images');
      });
    if (!resp || resp.error || !resp.result) {
      if (resp.error) {
        console.error(resp.error);
        alert(`Failed to get done images\n${resp.error.message}`);
      }
      return { images: [], total_count: 0 };
    }
    return resp.result;
  }

  function switchToTaskImage(imageIndex) {
    activeImageIndex.value = imageIndex;
    if (imageIndex >= 0) {
      internalImageObj.value = doneImages.value[imageIndex];
    } else {
      internalImageObj.value = currentTaskImage.value;
    }
    nextTick(() => { internalAnnotations.value = (internalImageObj.value.annotations || []); });
  }

  function switchToNextTaskImage() {
    if (activeImageIndex.value > -1) {
      switchToTaskImage(activeImageIndex.value - 1);
    }
  }

  function switchToPreviousTaskImage() {
    if (activeImageIndex.value < (doneImages.value.length - 1)) {
      switchToTaskImage(activeImageIndex.value + 1);
    }
  }

  return {
    startTaskHistory,
    doneImages,
    doneImageOpen,
    getTaskDoneImagesByUser,
    switchToTaskImage,
    switchToNextTaskImage,
    switchToPreviousTaskImage,
  };
}
