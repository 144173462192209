import { defineStore } from 'pinia';
import { ref, watch, computed } from 'vue';

export const useDepthMapStore = defineStore('depth', () => {
  const depthMapFile = ref(null);
  const depthAnnotations = ref([]);
  const enableDepthMap = ref(false);

  function $reset() {
    depthMapFile.value = ref(null);
    depthAnnotations.value = ref([]);
    enableDepthMap.value = false;
  }

  return {
    depthMapFile,
    enableDepthMap,
    depthAnnotations,
    $reset,
  };
});
