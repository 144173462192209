<template>
  <div id="gallery-component-container" class="gallery">
    <template v-if="!doneFetch">
      <slot name="overlay" />
    </template>
    <div v-if="doneFetch && imageList && imageList.length == 0" class="empty-state">
      <slot name="empty-state" />
    </div>
    <template v-if="imageList && imageList.length > 0">
      <div v-if="showHeader" class="gallery__header">
        <div class="gallery__filters">
          <div class="header-slot">
            <slot name="header-start" />
          </div>
          <div class="header-slot">
            <slot name="header-end" />
          </div>
        </div>
        <div class="gallery__pagination">
          <v-pagination
            v-if="showPagination"
            id="gallery-page-selector"
            v-model="internalPage"
            class="pagination"
            :length="internalTotalPages"
            density="compact"
          />
        </div>
      </div>
      <div
        v-if="doneFetch"
        ref="gridContainer"
        class="gallery__grid-container"
        :style="gridContainerStyles"
      >
        <div ref="selectBoxContainer" class="gallery__select-box-container d-none" />
        <div
          v-if="!hasGridItemSlot"
          id="gallery"
          ref="gallery"
          class="gallery__grid scrollbar"
          @scroll="handleScroll"
        >
          <GalleryGridItem
            v-for="imageObj in imageList"
            :key="imageObj.id"
            :imageObj="imageObj"
            :annotationSets="annotationSets"
            @image-deleted="$emit('image-deleted')"
            @grid-item-clicked="handleImageClicked(imageObj)"
          />
          <slot name="grid-item" />
        </div>
        <div
          v-else
          id="gallery"
          ref="gallery"
          class="gallery__grid scrollbar"
          :style="gridItemStyles"
          @scroll="handleScroll"
        >
          <slot name="grid-item" />
        </div>
      </div>
    </template>
  </div>
</template>

<script>

import {
  ref, computed,
} from 'vue';
import DatastoreConnect from '@/assets/js/DatastoreFunctions/datastore-interface';
import GalleryGridItem from './GalleryGridItem.vue';

export default {
  name: 'GalleryGrid',
  components: {
    GalleryGridItem,
  },
  props: {
    doneFetch: {
      type: Boolean,
      default: true,
    },
    imageList: {
      type: Array,
      default: () => [],
    },
    selectedImages: {
      type: Array,
      default: () => [],
    },
    totalImageCount: {
      type: Number,
      default: 0,
    },
    imagesPerPage: {
      type: Number,
      default: 40,
    },
    annotationSets: {
      type: Array,
      default: () => [],
    },
    page: {
      type: Number,
      default: 1,
    },
    totalPages: {
      type: Number,
      default: 0,
    },
    gridItemScale: {
      type: Number,
      default: null,
    },
    minGridItemHeight: {
      type: Number,
      default: 250,
    },
    minGridItemWidth: {
      type: Number,
      default: 250,
    },
    showHeader: {
      type: Boolean,
      default: true,
    },
    showPagination: {
      type: Boolean,
      default: true,
    },
  },
  emits: [
    'update:page', 'update:totalPages', 'grid-item-clicked', 'image-deleted', 'scrolled-to-end', 'multi-select', 'update:selectedImages',
  ],
  setup(_, { slots }) {
    const hasGridItemSlot = computed(() => slots['grid-item'] && slots['grid-item']());
    const gridContainer = ref(null);
    const selectBoxContainer = ref(null);

    return {
      gridContainer,
      hasGridItemSlot,
      selectBoxContainer,
    };
  },
  data() {
    return {
      scrollTop: 0,
      imageOffset: -1,
      imageLimit: 40,
      selectionBox: null,
      startX: 0,
      startY: 0,
      selectedItemsSet: new Set([]),
    };
  },
  computed: {
    internalTotalPages() {
      return Math.ceil(this.totalImageCount / this.imagesPerPage);
    },
    internalPage: {
      get() { return this.page; },
      set(v) { this.$emit('update:page', v); },
    },
    gridItemStyles() {
      let style;
      if (this.gridItemScale < 1) {
        style = {
          '--grid-item-width': `${this.minGridItemWidth + (this.gridItemScale * this.minGridItemWidth)}px`,
          // '--grid-item-height': `${this.minGridItemHeight}px`,
        };
      }
      if (this.gridItemScale === 1) {
        style = {
          '--grid-item-width': `100%`,
          // '--grid-item-height': `100%`,
        };
      }
      return style;
    },
    gridContainerStyles() {
      if (this.gridItemScale === 1) {
        return {
          'justify-content': 'center',
        };
      }
      return null;
    },
  },
  watch: {
    internalTotalPages(totalPages) {
      this.$emit('update:totalPages', totalPages);
    },
    doneFetch() {
      if (this.doneFetch) {
        this.$nextTick(() => {
          this.gridContainer.addEventListener('mousedown', (e) => {
            this.selectBoxContainer.classList.remove('d-none');
            this.selectBoxContainer.addEventListener('wheel', this.scrollGallery);
            this.startX = e.offsetX;
            this.startY = e.offsetY;

            this.selectionBox = document.createElement('div');
            this.selectionBox.classList.add('selection-box');
            this.selectionBox.style.left = `${this.startX}px`;
            this.selectionBox.style.top = `${this.startY}px`;
            this.selectBoxContainer.appendChild(this.selectionBox);

            document.addEventListener('mousemove', this.onMouseMove);
            document.addEventListener('mouseup', this.onMouseUp);
          });
        });
      }
    },
    selectedImages: {
      deep: true,
      handler() {
        if (this.selectedImages && this.selectedImages.length > 0) {
          const selectedIDs = this.selectedImages.map((e) => e.id);
          this.selectedItemsSet = new Set(selectedIDs);
        } else {
          this.selectedItemsSet = new Set([]);
        }
      },
    },
    // selectedItemsSet: {
    //   handler() {
    //     this.$emit('update:selectedImages', this.imageList.filter((e) => this.selectedItemsSet.has(e.id)).map((e) => e.id));
    //   },
    // },
  },
  created() {
    this.dsConn = new DatastoreConnect(this.$store.state.enterpriseServerUrl);
  },
  methods: {
    handleScroll() {
      const galleryScreen = document.getElementById('gallery');
      if (Math.ceil(galleryScreen.offsetHeight) + Math.ceil(galleryScreen.scrollTop) >= galleryScreen.scrollHeight) {
        this.$emit('scrolled-to-end');
      }
    },
    handleImageClicked(imageObj) {
      this.$emit('grid-item-clicked', imageObj);
    },
    scrollGallery(data) {
      this.$refs.gallery.scrollBy(data.deltaX, data.deltaY);
    },
    handleSelectBoxDimensionsChanged(data) {
    },
    onMouseMove(e) {
      const currentX = e.offsetX;
      const currentY = e.offsetY;

      this.selectionBox.style.width = `${Math.abs(currentX - this.startX)}px`;
      this.selectionBox.style.height = `${Math.abs(currentY - this.startY)}px`;
      this.selectionBox.style.left = `${Math.min(this.startX, currentX)}px`;
      this.selectionBox.style.top = `${Math.min(this.startY, currentY)}px`;
    },

    onMouseUp() {
      const rect = this.selectionBox.getBoundingClientRect();
      const newSelected = [];
      this.gridContainer.querySelectorAll('.grid-item').forEach((item) => {
        const itemRect = item.getBoundingClientRect();
        if (rect.left < itemRect.right
            && rect.right > itemRect.left
            && rect.top < itemRect.bottom
            && rect.bottom > itemRect.top) {
          const result = parseInt(item.id, 10);
          if (!Number.isNaN(result)) {
            newSelected.push(result);
          }
        }
      });

      this.selectedItemsSet = new Set([...this.selectedItemsSet, ...newSelected]);

      this.$emit('multi-select', Array.from(this.selectedItemsSet));

      this.selectBoxContainer.querySelectorAll('.selection-box').forEach((box) => box.remove());
      this.selectBoxContainer.classList.add('d-none');
      this.selectBoxContainer.removeEventListener('wheel', this.scrollGallery);
      document.removeEventListener('mousemove', this.onMouseMove);
      document.removeEventListener('mouseup', this.onMouseUp);
    },
  },
};
</script>

<style>
  #gallery-page-selector li button {
    padding: 1px 6px;
  }
</style>

<style lang="scss" scoped>

.gallery-image-count {
  margin-left: 16px;
  font-size: 0.75rem;
  font-weight: 600;
  @include themify() {
    color: themed('body-text-color-secondary');
  }
}

.gallery {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  overflow: hidden;
  position: relative;

  &__header {
    display: flex;
    flex-direction: column;
  }

  &__pagination {
    display: flex;
    justify-content: flex-end;
    height: 40px;
    align-items: center;
    margin-top: 10px;
    margin-right: 20px;
  }

  &__filters {
    display: flex;
    margin: 20px 20px 0 20px;

    .header-slot:first-of-type{
      width: 30%;
      max-width: 30%;
    }
  }

  &__grid-container {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    min-height: 0;
  }

  &__select-box-container {
    position:absolute;
    z-index: 10;
    width: calc(100% - 15px);
    height: 100%;
  }

  &__grid {
    --grid-item-width: 250px;
    // --grid-item-height: 250px;
    display: grid;
    grid-template-columns: repeat(auto-fill, var(--grid-item-width));
    // grid-auto-rows: var(--grid-item-height);
    grid-gap: 20px 20px;
    // height: auto;
    padding: 0 20px 15px 20px;
    margin: 20px 0 20px 0;
    overflow: auto;
    transition: all 0.5s;
  }
}

.empty-state {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  height: 100%;
}

.header-slot {
  flex: 1 1 50%;
}

.pagination {
  width: 400px;
  max-width: 400px;
  height: fit-content;
}

:deep {
  .v-pagination__list {
    justify-content: flex-end;
  }
  .selection-box {
    position: absolute;
    border: 1px solid rgba(0,0,255,0.8);
    background: rgba(0,0,255,0.25);
    pointer-events: none;
  }

}

</style>
