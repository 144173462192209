<template>
  <FilterPopper>
    <template #default="{ menuOpen }">
      <BaseFilterButton
        :name="'Annotation Size'"
        :active="isActive"
        :tagValue="tagValue"
        :menuOpen="menuOpen"
      />
    </template>
    <template #popper>
      <div class="filter-settings scrollbar">
        <div class="filter-settings__header switch-container">
          <input
            id="pixel"
            v-model="type"
            type="radio"
            value="pixel"
          >
          <label for="html">Pixel</label>
          <input
            id="percent"
            v-model="type"
            type="radio"
            value="percent"
          >
          <label for="percent">Percent</label>
        </div>
        <div class="filter-settings__row settings__control-group">
          <label class="text">Width</label>
          <input
            v-model="values.lower_width"
            :min="lowerBoundWidth"
            :max="values.upper_width"
            type="number"
            placeholder="Min"
            @change="changeSanitizer"
            @input="inputSanitizer"
          >
          <span>&#x2013;</span>
          <input
            v-model="values.upper_width"
            :min="values.lower_width"
            :max="upperBoundWidth"
            type="number"
            placeholder="Max"
            @change="changeSanitizer"
            @input="inputSanitizer"
          >
        </div>
        <div class="filter-settings__row settings__control-group">
          <label class="text">Height</label>
          <input
            v-model="values.lower_height"
            :min="lowerBoundHeight"
            :max="values.upper_height"
            type="number"
            placeholder="Min"
            @change="changeSanitizer"
            @input="inputSanitizer"
          >
          <span>&#x2013;</span>
          <input
            v-model="values.upper_height"
            :min="values.lower_height"
            :max="upperBoundHeight"
            type="number"
            placeholder="Max"
            @change="changeSanitizer"
            @input="inputSanitizer"
          >
        </div>
      </div>
      <div class="filter-footer">
        <button :disabled="!isActive" class="button-text clear-button" @click="clearSelection">Clear Selection</button>
      </div>
    </template>
  </FilterPopper>
</template>

<script>
import FilterPopper from '@/components/FilterPopper.vue';
import BaseFilterButton from '@/components/BaseFilterButton.vue';
import { debounce, inputSanitizer, changeSanitizer } from '@/assets/js/utils.js';

export default {
  name: "BoundingBoxSizeFilter",
  components: {
    FilterPopper,
    BaseFilterButton,
  },
  props: {
    modelValue: {
      type: Object,
      default: null,
    },
  },
  emits: [
    'update:modelValue',
  ],
  data() {
    return {
      type: 'pixel',
      values: {
        lower_height: null,
        upper_height: null,
        lower_width: null,
        upper_width: null,
      },
      lowerBoundHeight: 0,
      upperBoundHeight: 0,
      lowerBoundWidth: 0,
      upperBoundWidth: 0,
    };
  },
  computed: {
    isActive() {
      return this.values.lower_height > 0 || this.values.upper_height > 0 || this.values.lower_width > 0 || this.values.upper_width > 0;
    },
    tagValue() {
      let count = 0;
      Object.keys(this.values).forEach((val) => {
        if (this.values[val] > 0) {
          count += 1;
        }
      });

      return count.toString();
    },
  },
  watch: {
    type() {
      this.clearSelection();
      this.updateBounds(this.type);
    },
    values: {
      deep: true,
      handler() {
        this.debouncedUpdateFilters();
      },
    },
    modelValue() {
      if (this.modelValue) {
        this.values.lower_height = this.modelValue.lower_height;
        this.values.upper_height = this.modelValue.upper_height;
        this.values.lower_width = this.modelValue.lower_width;
        this.values.upper_width = this.modelValue.upper_width;
        this.values.type = this.modelValue.type;
      }
    },
  },
  created() {
    this.debouncedUpdateFilters = debounce(this.updateFilters, 500);
  },
  mounted() {
    if (this.modelValue) {
      this.values.lower_height = this.modelValue.lower_height;
      this.values.upper_height = this.modelValue.upper_height;
      this.values.lower_width = this.modelValue.lower_width;
      this.values.upper_width = this.modelValue.upper_width;
      this.values.type = this.modelValue.type;
    }
    this.updateBounds(this.type);
  },
  methods: {
    inputSanitizer,
    changeSanitizer,
    updateFilters() {
      this.$emit('update:modelValue', { ...this.values, type: this.type });
    },
    clearSelection() {
      this.values.lower_height = null;
      this.values.upper_height = null;
      this.values.lower_width = null;
      this.values.upper_width = null;
      this.values.type = 'pixel';
    },
    updateBounds(type) {
      if (type === 'percent') {
        this.lowerBoundHeight = 0;
        this.upperBoundHeight = 1;
        this.lowerBoundWidth = 0;
        this.upperBoundWidth = 1;
      }
      if (type === 'pixel') {
        this.lowerBoundHeight = null;
        this.upperBoundHeight = null;
        this.lowerBoundWidth = null;
        this.upperBoundWidth = null;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.filter-settings {
  display: flex;
  flex-direction: column;
  padding: 12px;
  border-radius: inherit;
  gap: 6px;
  align-items: center;
  min-width: 350px;
  overflow: auto;

  &__header {
    display: flex;
    flex-direction: row;

    span {
      font-size: 0.9rem;
    }
    .text {
      width: 60%;
      font-size: 0.9rem;
    }
  }

  &__row {
    display: flex;
    flex-direction: row;
    width: 100%;

    span {
      font-size: 0.9rem;
    }
    .text {
      width: 60%;
      font-size: 0.9rem;
    }

    input {
      margin: 5px 10px;
    }
  }

  .switch-container {
    margin: 5px 5px 5px auto;

    span{
      text-align: right;
      margin-left: 5px;
    }
    input[type="radio"] {
        margin-left: 20px;
        accent-color: var(--color-primary-400);
    }
    label {
        margin-left: 5px;
    }
  }
}

.filter-footer {
  min-height: 38px;
  width: 100%;
  border-top: 1px solid rgba(140,140,140,0.375);
  padding: 8px;
  text-align: center;

  .clear-button {
    @include themify() {
      color: themed('color-danger');
    }

    &[disabled] {
      @include themify() {
        color: themed('color-disabled');
      }
    }
  }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
</style>
