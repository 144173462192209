<template>
  <div class="empty-state">
    <svg width="100%" height="500px">
      <use href="@/assets/img/empty_dataset.svg#empty_dataset" />
    </svg>
    <SVGIcon
      class="empty-state__cloud-upload-icon"
      :iconName="'cloud_upload'"
      :width="'100%'"
      :height="'200px'"
    />
    <div class="empty-state__message">
      <h3>No images found.</h3>
      <p>Drag and drop images into the gallery or <button class="button-link" @click="handleImportClicked"><strong>IMPORT</strong></button> to get started.</p>
    </div>
  </div>
</template>

<script>
import DatastoreConnect from '@/assets/js/DatastoreFunctions/datastore-interface';
import SVGIcon from '@/components/SVGIcon.vue';

export default {
  name: 'GalleryEmptyState',
  components: {
    SVGIcon,
  },
  props: {
  },
  emits: ['import-clicked'],
  created() {
    this.dsConn = new DatastoreConnect(this.$store.state.enterpriseServerUrl);
  },
  methods: {
    handleImportClicked() {
      this.$emit('import-clicked');
    },
  },
};
</script>

<style lang="scss" scoped>
.empty-state {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  height: fit-content;
  margin: auto;

  & svg {
    @include themify() {
      fill: themed('color-primary');
    }
  }

  &__cloud-upload-icon {
    position: absolute;
    top: 22%;
  }

  &__message {
    text-align: center;
    position: absolute;
    top: 60%;
    @include themify() {
      color: themed('body-text-color');
    }

    h2 {
      margin-bottom: 12px;
    }

    p {
      margin: 0;
    }
  }
}

</style>
