import pLimit from 'p-limit';
import { fetchRetry, getFileContents } from '@/assets/js/utils.js';
import DatastoreConnect from '@/assets/js/DatastoreFunctions/datastore-interface';
import path from 'path-browserify';

const parseAnnotationsJSON = async (annotationURLs) => {
  const annotationsJSON = await Promise.all(annotationURLs.map(async (annotationURL) => {
    const fileContents = await getFileContents(annotationURL);
    return JSON.parse(fileContents);
  }));

  return annotationsJSON || [];
};

const getLabelIndex = (category_id, categories, databaseLabels) => {
  const categoryObj = categories.find((category) => category.id === category_id);
  if (!categoryObj) return -1;

  const categoryName = `${categoryObj.name} - ${categoryObj.supercategory}`;
  const labelObj = databaseLabels.find((label) => label.name === categoryName);
  if (!labelObj) return -1;

  return labelObj.index;
};

const parseSegmentation = (originalSegmentation, image) => {
  const normalizedSegmentation = originalSegmentation.map((segmentation) => {
    const normalizedPoints = [];

    for (let i = 0; i < segmentation.length; i += 2) {
      const x = segmentation[i] / image.width;
      const y = segmentation[i + 1] / image.height;
      normalizedPoints.push([x, y]);
    }

    return normalizedPoints;
  });

  return normalizedSegmentation;
};

const convertAnnotation = (annotation, image, categories, databaseLabels) => {
  let convertedAnnotation = {
    label_index: getLabelIndex(annotation.category_id, categories, databaseLabels),
    score: 0,
  };

  if (annotation.bbox) {
    convertedAnnotation = {
      ...convertedAnnotation,
      ...{
        x: (annotation.bbox[0] / image.width),
        y: (annotation.bbox[1] / image.height),
        w: (annotation.bbox[2] / image.width),
        h: (annotation.bbox[3] / image.height),
      },
    };
    convertedAnnotation.type = 'box';
  }

  if (annotation.segmentation && annotation.segmentation.length > 0) {
    convertedAnnotation.polygon = JSON.stringify(parseSegmentation(annotation.segmentation, image));
    convertedAnnotation.type = 'seg';
  }

  return convertedAnnotation;
};

async function importDataset({ commit, dispatch }, { urls, importParams }) {
  const dataConnect = new DatastoreConnect('', { delay: 500, tries: 5, backoff_factor: 1 });

  // Filter by image urls
  const imageURLs = urls.filter((url) => url.file.type.startsWith('image/') && url.file.size > 0);
  const numImages = imageURLs.length;
  if (numImages === 0) {
    alert("No valid images found");
    return "Upload Cancelled";
  }
  const imageNameURLMap = imageURLs.reduce((acc, img) => {
    acc[img.file.name] = { ...img };
    return acc;
  }, {});

  // Get annotation files data as json array (one entry per annotation file)
  const annotationURLs = urls.filter((url) => url.file.type === 'application/json');
  const annotationJSON = await parseAnnotationsJSON(annotationURLs.map((url) => url.file));

  // Get labels
  const allLabelObjects = annotationJSON.map((groupJSON) => groupJSON.categories).flat();
  const labelsToAdd = [...new Set(allLabelObjects.map((obj) => `${obj.name} - ${obj.supercategory}`))];
  // Add labels to database
  await dataConnect.addLabel({ label_names: labelsToAdd, dataset_id: importParams.dataset_id });
  const labels = await dataConnect.getLabelList({ dataset_id: importParams.dataset_id })
    .then((resp) => {
      if (resp.result) {
        return resp.result;
      }
      throw Error("Failed to get labels from the database");
    });

  // Match image object with annotations, match image objects with imageNameURLMap
  annotationJSON.forEach((groupJSON) => {
    const imagesIDMap = groupJSON.images.reduce((acc, obj) => {
      acc[obj.id] = obj;
      acc[obj.id].annotations = [];
      return acc;
    }, {});

    // Copy all annotations to the corresponding image object
    groupJSON.annotations.forEach((anno) => {
      imagesIDMap[anno.image_id].annotations.push(convertAnnotation(anno, imagesIDMap[anno.image_id], groupJSON.categories, labels));
    });

    // Copy image with annotations object to the imageNameURLMap
    Object.values(imagesIDMap).forEach((imageWithAnnotations) => {
      if (imageNameURLMap[imageWithAnnotations.file_name]) {
        imageNameURLMap[imageWithAnnotations.file_name].data = imageWithAnnotations;
      }
    });
  });

  // Add upload task to store and database
  let dbTask;
  if (importParams.task) {
    // Resume from existing docker task
    const updatedTask = await dataConnect.updateDockerTaskStatus({
      docker_task_id: importParams.task.id,
      status: 'running',
    })
      .then((resp) => {
        if (resp.result) {
          return resp.result;
        }
        throw Error("Failed to update import task in database to status 'running'");
      })
      .catch((error) => {
        console.error(error);
      });
    dbTask = updatedTask;
    commit('tasks/resumeUploadTask', dbTask, { root: true });
  } else {
    // Create new docker task
    dbTask = await dataConnect.addDockerTask({
      name: `Upload To ${importParams.dataset_name}`,
      project_id: importParams.project_id,
      docker_id: '',
      type: 'upload',
      status: 'running',
      total: numImages,
      data: {
        dataset_id: importParams.dataset_id,
        annotation_set_id: importParams.annotation_set_id,
        s3_path: importParams.importer_source,
        import_type: importParams.import_type,
      },
    })
      .then((resp) => {
        if (resp.result) {
          return resp.result;
        }
        throw Error("Failed to create import task in database");
      })
      .catch((error) => {
        console.error(error);
        throw error;
      });
    commit('tasks/addNewUploadTask', dbTask, { root: true });
  }

  // Add groups to database
  let groups = [];
  if (importParams.create_image_groups) {
    const createGroupsParams = {
      dataset_id: importParams.dataset_id,
      group_names: [],
      group_splits: [],
    };
    importParams.create_image_groups.forEach((createGroup) => {
      createGroupsParams.group_names.push(createGroup);
      createGroupsParams.group_splits.push(0);
    });

    groups = await dataConnect.createGroupsForDataset(createGroupsParams).then((resp) => {
      if (resp.error !== undefined) {
        throw Error(resp.error);
      }
      return resp.result;
    })
      .catch((error) => {
        console.error(error);
      });
  }

  const promises = [];
  const max_concurrent_req = 6;
  const limit = pLimit(max_concurrent_req);
  const numAnnotationFileParseFailed = 0;
  let imageUploadSuccessCount = 0;
  Object.values(imageNameURLMap).forEach((image) => {
    promises.push(limit(async () => {
      // Handle creating database entry and uploading image/thumbnail files to S3
      if (image.file) {
        const imageRelativePath = image.file.relativePath || image.file.webkitRelativePath || image.file.name;

        // Parse image metadata
        let imageInfo;
        let thumbnail;
        try {
          ({ imageInfo, thumbnail } = await dispatch('parseImage', image.file));
        } catch (error) {
          console.log(error);
        }

        // Create database entry for uploaded image
        const uploadData = {
          dataset_id: importParams.dataset_id,
          annotation_set_id: importParams.annotation_set_id,
          image_width: imageInfo.width,
          image_height: imageInfo.height,
          image_filepath: imageRelativePath,
          s3_path: importParams.importer_source,
          docker_task_id: dbTask.id,
        };

        // Parse auto created group
        const pathSplit = path.normalize(imageRelativePath).split(path.sep);
        if (pathSplit.length > 1 && importParams.create_image_groups?.includes(pathSplit[1])) {
          const toCreateGroup = groups.find((group) => group.name === pathSplit[1]);
          uploadData.group_id = toCreateGroup.id;
        }

        if (importParams.group_id) {
          uploadData.group_id = importParams.group_id;
        }

        // if (imageInfo.exif) {
        //   if (imageInfo.exif.annotations && imageInfo.exif.annotations.length > 0) {
        //     uploadData.annotations = imageInfo.exif.annotations;
        //   }
        //   if (imageInfo.exif.meta && imageInfo.exif.meta.gps.lon && imageInfo.exif.meta.gps.lat) {
        //     uploadData.lon = imageInfo.exif.meta.gps.lon;
        //     uploadData.lat = imageInfo.exif.meta.gps.lat;
        //   }
        //   if (imageInfo.exif.meta && imageInfo.exif.meta.timestamp) {
        //     uploadData.timestamp = imageInfo.exif.meta.timestamp;
        //   }
        // }

        if (image.data.annotations.length > 0) {
          uploadData.annotations = image.data.annotations;
        }

        if (image.data.date_captured) {
          uploadData.timestamp = Math.round((new Date(image.data.date_captured)).getTime() * 1000000);
        }

        // if (annotationMeta?.source) {
        //   uploadData.image_source = annotationMeta.source;
        // }

        // if (annotationMeta?.gps) {
        //   uploadData.lat = annotationMeta.gps.lat;
        //   uploadData.lon = annotationMeta.gps.lon;
        // }
        await dataConnect.addUploadData(uploadData)
          .then(async () => {
            imageUploadSuccessCount += 1;
            // Upload image file to S3 bucket using presigned url
            await fetchRetry(image.url, { delay: 500, tries: 5, backoff_factor: 1 }, {
              method: 'PUT',
              body: image.file,
            })
              .then(async () => {
                // Upload thumbnail to S3 bucket using presigned url
                fetchRetry(image.thumbnail_url, { delay: 500, tries: 5, backoff_factor: 1 }, {
                  method: 'PUT',
                  body: thumbnail,
                })
                  .catch((error) => {
                    // Handle thumbnail upload error
                    console.error(error);
                  });
              })
              .catch((error) => {
                // Handle image upload error
                console.error(error);
              });
          })
          .catch((error) => {
            // TODO handle database fail
            console.error(error);
          });
      }
    }));
  });
  await Promise.all(promises);

  if (numAnnotationFileParseFailed) {
    console.log("Number of failed annotation parses:", numAnnotationFileParseFailed);
  }

  dataConnect.completeUpload()
    .then(() => commit('notifications/updateNotifications', true));

  // Remove upload task from store and set to complete in database
  // commit('tasks/removeUploadTask', { taskID }, { root: true });
  dataConnect.updateDockerTaskStatus({
    docker_task_id: dbTask.id,
    status: 'complete',
  });

  return "Upload Complete";
}

export default {
  importDataset,
};
