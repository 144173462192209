<template>
  <div class="controls-bar">
    <div />
    <button v-if="permissions && permissions.trainer_write" class="button button-sm" @click="openCreateModal">Create</button>
  </div>
  <div v-if="isNotEmpty && permissions && (permissions.trainer_read || permissions.trainer_read)" class="automation-card-container scrollbar">
    <AutoAnnotationCard
      v-for="(d, i) in filteredInternalDatasets"
      :key="i"
      :dataset="d"
      :permissions="permissions"
      @create-automated-task="openCreateModal(d)"
      @terminate-task="openConfirmTerminateTaskModal"
      @delete-task="openConfirmDeleteTaskModal"
    />
  </div>
  <div v-else-if="loading" class="empty-state">
    <InlineLoader
      :width="'60px'"
      :height="'60px'"
      :border="'12px'"
    />
  </div>
  <div
    v-else
    class="empty-state"
  >
    <svg width="60%" height="60%">
      <use href="@/assets/img/empty.svg#emptyStateSVG" />
    </svg>
    <div class="empty-state__message">
      <h3>No automated tasks to display.</h3>
    </div>
  </div>
  <CreateAutoAnnotationTaskModal
    ref="createTaskModal"
    :datasets="internalDatasets"
    :trainerList="trainerList"
    @task-created="getAutoAnnotationList"
  />
  <ConfirmModal
    ref="confirmModal"
    :messageHeader="confirmMessageHeader"
    :message="confirmMessage"
    :buttonClass="'button-delete'"
    :buttonText="buttonText"
    @confirmed="confirmFunction"
  />
</template>

<script>
import DatastoreConnect from '@/assets/js/DatastoreFunctions/datastore-interface';
import ConfirmModal from '@/components/ConfirmModal.vue';
import CreateAutoAnnotationTaskModal from '@/components/AutoAnnotations/CreateAutoAnnotationTaskModal.vue';
import AutoAnnotationCard from '@/components/AutoAnnotations/AutoAnnotationCard.vue';
import InlineLoader from '@/components/InlineLoader.vue';

export default {
  name: "AutomatedTasks",
  components: {
    ConfirmModal,
    CreateAutoAnnotationTaskModal,
    AutoAnnotationCard,
    InlineLoader,
  },
  props: {
  },
  emits: [
    'update-list',
  ],
  data() {
    return {
      hideNoExists: false,
      interval: null,
      dataConnect: new DatastoreConnect(this.$store.state.enterpriseServerUrl),
      loading: false,
      internalDatasets: [],
      taskList: [],
      trainerList: [],
      buttonText: '',
      confirmMessage: null,
      confirmMessageHeader: null,
      confirmFunction: null,
      toDelete: null,
      permissions: null,
    };
  },
  computed: {
    currentProject() {
      return this.$store.state.projects.currentProject;
    },
    isNotEmpty() {
      return this.filteredInternalDatasets.length > 0 && this.taskList.length > 0;
    },
    // convert dataset list to object {
    //   "dataset_id": {
    //     tasks: []
    //   }
    // }
    filteredInternalDatasets() {
      const datasetMap = {};
      this.internalDatasets.forEach((e) => {
        datasetMap[e.id] = e;
        datasetMap[e.id]['tasks'] = [];
      });
      if (Object.keys(datasetMap).length > 0) {
        this.taskList.forEach((task) => {
          const data = JSON.parse(task.docker_task.data);
          task['parsed_data'] = data;
          if (data && data !== "") {
            datasetMap[task['dataset_id']]?.tasks.push(task);
          }
        });
      }
      return Object.values(datasetMap).filter((e) => e['tasks'].length > 0).sort((a, b) => {
        if (a.id - b.id < 0) {
          return -1;
        } else {
          return 1;
        }
      });
    },
  },
  watch: {
    trainerList: {
      deep: false,
      async handler(trainers) {
        await Promise.all(trainers.map(async (trainer) => {
          trainer.training_sessions = await this.getTrainingSessionList(trainer.id);
        }));
      },
    },
    currentProject() {
      this.getDatasets();
      this.getTrainerList();
      this.getAutoAnnotationList();
      this.startStatusInterval();
    },
  },
  beforeUnmount() {
    this.stopStatusInterval();
  },
  mounted() {
    if (this.currentProject) {
      this.getDatasets();
      this.getTrainerList();
      this.getAutoAnnotationList();
      this.startStatusInterval();
      this.getProjectAccessForSingleUser();
    }
  },
  methods: {
    async getProjectAccessForSingleUser() {
      await this.dataConnect.getProjectAccessForSingleUser({
        project_id: this.currentProject.id,
        username: this.$store.state.user.user.username,
      })
        .then((data) => {
          if (data.result) {
            this.permissions = data.result;
          }
        })
        .catch((e) => console.log(e));
    },
    async getDatasets() {
      this.loading = true;
      if (this.currentProject) {
        const params = {
          project_id: this.currentProject.id,
          get_annotation_sets: true,
        };
        const resp = await this.dataConnect.getDatasetList(params)
          .catch((error) => {
            // TODO: handle error
            console.log(error);
          });
        if (resp.error) {
          this.internalDatasets = [];
          this.loading = false;
        } else {
          this.internalDatasets = resp.result;
          this.loading = false;
        }
      } else {
        this.internalDatasets = [];
        this.loading = false;
      }
    },
    openConfirmTerminateTaskModal(t) {
      this.confirmMessage = `Are you sure you want to terminate task "${t.name}"?`;
      this.confirmMessageHeader = 'Terminate Task';
      this.toDelete = t;
      this.buttonText = 'Terminate';
      this.$refs.confirmModal.showModal();
      this.confirmFunction = () => {
        this.handleTerminateClicked();
      };
    },
    openConfirmDeleteTaskModal(t) {
      this.confirmMessage = `Are you sure you want to move task "${t.name}" to recycle bin?`;
      this.confirmMessageHeader = 'Remove Task';
      this.toDelete = t;
      this.buttonText = 'Remove';
      this.$refs.confirmModal.showModal();
      this.confirmFunction = () => {
        this.handleDeleteClicked();
      };
    },
    async getTrainerList() {
      this.dataConnect.getTrainerList2({ project_id: this.currentProject.id })
        .then((data) => {
          if (data.result) {
            this.trainerList = data.result;
          }
        })
        .catch((e) => console.log(e));
    },
    async getTrainingSessionList(trainer_id) {
      const resp = await this.dataConnect.getTrainingSessionList({ trainer_id, reverse: true })
        .catch((error) => {
          console.log('Failed to retrieve training sessions:', error);
        });
      if (!resp || resp.error || !resp.result) {
        return [];
      }
      return resp.result;
    },
    startStatusInterval() {
      this.interval = setInterval(
        () => {
          this.getAutoAnnotationList();
        },
        20000,
      );
    },
    stopStatusInterval() {
      clearInterval(this.interval);
    },
    async getAutoAnnotationList() {
      this.dataConnect.getAutoAnnList({ project_id: this.currentProject.id })
        .then((data) => {
          if (data && data.result) {
            this.toDelete = null;
            this.taskList = data.result;
          } else {
            this.taskList = [];
          }
        })
        .catch((e) => console.log(e));
    },
    async handleTerminateClicked() {
      await this.dataConnect.terminateAutoAnn({ task_ids: [this.toDelete.id] })
        .then(() => {
          this.getAutoAnnotationList();
        })
        .catch((error) => {
          // TODO: handle error
          console.log(error);
        });
    },
    async handleDeleteClicked() {
      await this.dataConnect.deleteAutoAnn({ task_id: this.toDelete.id })
        .then(() => {
          this.getAutoAnnotationList();
        })
        .catch((error) => {
          // TODO: handle error
          console.log(error);
        });
    },
    openCreateModal() {
      this.$refs.createTaskModal.showModal();
    },
  },
};
</script>

<style lang="scss" scoped>
.controls-bar {
  display: flex;
  flex-direction: row;
  padding: 10px 20px 10px 20px;
  align-items: center;
  border-bottom: 1px solid #c9c9c9;
  @include themify() {
    background: themed('color-ribbon');
  }
  justify-content: space-between;
}

.automation-header {
  display: flex;
  flex-direction: row;
  gap: 16px;
}

.automation-card-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(750px, 1fr));
  grid-auto-rows: auto;
  width: 100%;
  gap: 16px;
  padding: 16px;
  overflow: auto;
}

.empty-state {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  height: 100%;
}

.hyperlink {
  color: var(--color-primary);
  text-decoration: underline;
  cursor: pointer;
}
</style>
