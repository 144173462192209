<template>
  <Modal
    ref="updateDatasetModal"
    :title="'Update Dataset'"
  >
    <template #modal-main>
      <div class="settings container">
        <div class="row mb-3">
          <div class="col-12 settings__control-group">
            <label>Name</label>
            <input
              v-model="name"
              class="select"
              type="text"
            >
          </div>
        </div>
        <div class="row">
          <div class="col-12 settings__control-group">
            <label>Description</label>
            <textarea
              v-model="description"
              rows="4"
              cols="50"
            />
          </div>
        </div>
      </div>
    </template>
    <template #modal-footer>
      <div class="footer">
        <button class="button button-sm" @click="apply">{{ 'Apply Changes' }}</button>
      </div>
    </template>
  </Modal>
</template>

<script>
import Modal from '../../Modal.vue';

export default {
  name: 'DatasetUpdateModal',
  components: {
    Modal,
  },
  props: {
    dataset: {
      type: Object,
      default: () => {},
    },
  },
  emits: {
    'edit-dataset': null,
  },
  data() {
    return {
      name: '',
      description: '',
    };
  },
  watch: {
    dataset(d) {
      if (d) {
        this.name = this.dataset.name;
        this.description = this.dataset.description;
      }
    },
  },
  methods: {
    showModal() {
      this.$refs.updateDatasetModal.showModal();
    },
    closeModal() {
      this.name = "";
      this.description = "";
      this.$refs.updateDatasetModal.closeModal();
    },
    apply() {
      if (this.name) {
        this.$emit('edit-dataset', { name: this.name, description: this.description, dataset_id: this.dataset.id });
      }
      this.closeModal();
    },
  },
};
</script>

<style lang="scss" scoped>

.settings {
  padding: 0.75rem 3rem;
  min-width: 420px;
}

.footer {
  display: flex;
  flex-direction: row;
  padding: 0.75rem 3rem;
  justify-content: flex-end;
}

.close-button {
  @include themify() {
    background: themed('button-secondary');
    color: themed('button-secondary-text');
  }
}
</style>
