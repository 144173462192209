<template>
  <div ref="sliderContainer" class="range-slidercontainer">
    <div class="range-slider">
      <input
        ref="singleSlider"
        v-model="localValue"
        type="range"
        :step="step"
        :min="min"
        :max="max"
        :disabled="disabled"
        @input="handleSliderInput"
      >
    </div>
  </div>
</template>

<script>
export default {
  name: 'SingleSlider',
  props: {
    step: {
      type: Number,
      default: 1,
    },
    min: {
      type: Number,
      default: 0,
    },
    max: {
      type: Number,
      default: 1,
    },
    sliderValue: {
      type: Number,
      default: 0,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: [
    'input', 'update:sliderValue',
  ],
  data() {
    return {
      localValue: 0,
      resizeObserver: null,
    };
  },
  watch: {
    sliderValue(newVal) {
      this.localValue = newVal;
    },
  },
  beforeMount() {
    this.localValue = this.sliderValue;
  },
  methods: {
    handleSliderInput(event) {
      this.$emit('input');
      this.$emit('update:sliderValue', Number(event.target.value));
    },
  },
};
</script>

<style lang="scss" scoped>
.range-slidercontainer {
  width: 100%;
}

.range-slider {
  position: relative;
  margin: 0;
  height: 25px;
}

.range-slider input[type='range'] {
  -webkit-appearance: none;
  pointer-events: none;
  padding: 0;
  margin: 0;
  position: absolute;
  left: 0;
  top: 10px;
  height: 5px;
  width: 100%;
  border-radius: 14px;
  @include themify {
    background: themed('range-slider-selected-color');
  }
}
.range-slider input[type='range']:focus, .range-slider input[type='range']:active {
  outline: none;
}
.range-slider input[type='range']::-webkit-slider-thumb {
  -webkit-appearance: none;
  box-sizing: content-box;
  width: 15px;
  height: 15px;
  border: none;
  pointer-events: auto;
  border-radius: 14px;
  @include themify {
    background-color: themed('range-slider-thumb-color');
  }
  cursor: pointer;
  position: relative;
  z-index: 3;
  top: -5px;
}
.range-slider input[type='range']:disabled::-webkit-slider-thumb {
  pointer-events: none;
  @include themify {
    background-color: themed('slider-background-disabled');
  }
}
.range-slider input[type='range']::-webkit-slider-runnable-track {
  -webkit-appearance: none;
  @include themify {
    background: themed('range-slider-selected-color');
  }
  height: 5px;
  border-radius: 2.5px;
}
.range-slider input[type='range']:disabled::-webkit-slider-runnable-track {
  @include themify {
    background: themed('slider-background-disabled');
  }
}

</style>
