import { getFileContents } from '@/assets/js/utils.js';

function parseCenterX(cx, w) {
  return parseFloat(cx) - (parseFloat(w) / 2);
}
function parseCenterY(cy, h) {
  return parseFloat(cy) - (parseFloat(h) / 2);
}

async function parseAnnotationJsonFile(file, labels) {
  const annotations = [];
  const meta = {};
  let fileContents = '';
  let json = {};
  try {
    fileContents = await getFileContents(file);
    json = JSON.parse(fileContents);
  } catch (error) {
    return { annotations, meta };
  }

  // Parse boxes
  json.boxes.forEach((box) => {
    const cx = box?.[0];
    const cy = box?.[1];
    const w = box?.[2];
    const h = box?.[3];
    const label_index = box?.[4];
    annotations.push({
      label_index,
      score: 0,
      x: parseCenterX(cx, w),
      y: parseCenterY(cy, h),
      w,
      h,
      type: 'box',
      polygon: '',
    });
  });

  // Parse segments
  json.segment.forEach((segment) => {
    // Assume class is the first polygon class
    const label_index = segment?.[0].class;
    const combinedPolygons = [];
    segment.forEach((polygon) => {
      combinedPolygons.push(polygon.polygon);
    });
    annotations.push({
      label_index,
      score: 0,
      x: 0,
      y: 0,
      w: 0,
      h: 0,
      type: 'seg',
      polygon: JSON.stringify(combinedPolygons),
    });
  });

  return { annotations, meta };
}

export default {
  parseAnnotationJsonFile,
};
