<template>
  <div ref="dropZone" class="upload-images">
    <div
      v-show="dropHighlighted"
      id="file-upload-backdrop"
      class="file-upload-backdrop"
      :class="{_highlight: dropHighlighted}"
      @drop="dropHighlighted = false"
      @drop.stop.prevent="handleDrop"
    >
      <template v-if="showIcon">
        <SVGIcon
          id="upload-icon"
          class="file-upload-backdrop__upload-icon"
          :iconName="'cloud_upload'"
          :width="'100%'"
          :height="'200px'"
        />
        <div id="file-upload-backdrop-text" class="file-upload-backdrop__text">
          <span>Drag and drop images here</span>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import SVGIcon from '@/components/SVGIcon.vue';
import DatastoreConnect from '@/assets/js/DatastoreFunctions/datastore-interface';

export default {
  name: 'GalleryDropZone',
  components: {
    SVGIcon,
  },
  props: {
    datasetID: {
      type: Number,
      default: null,
    },
    showIcon: {
      type: Boolean,
      default: true,
    },
  },
  emits: ['upload-complete', 'upload-error'],
  data() {
    return {
      files: null,
      dropHighlighted: false,
      dragCounter: 0,
      selectedImportScript: "",
      dataConnect: null,
      importScriptType: "images-folder",
      importScriptAnnsetID: 0,
      importerSource: "",
    };
  },
  computed: {
    user() {
      return this.$store.state.user.user;
    },
    isDebugMode() {
      return this.$store.state.debugMode;
    },
    datasetName() {
      const dataset = this.$store.state?.datasets?.datasetList.find((d) => d.id === this.datasetID);
      return dataset.name;
    },
    currentProject() {
      return this.$store.state.projects.currentProject;
    },
    importParams() {
      const importParams = {
        import_type: 'images-folder',
        dataset_id: this.datasetID,
        dataset_name: this.datasetName,
        project_id: this.currentProject.id,
      };
      return importParams;
    },
  },
  watch: {
    dropHighlighted() {

    },
  },
  created() {
    this.dataConnect = new DatastoreConnect(this.$store.state.enterpriseServerUrl);

    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'tasks/updateImportTaskStatus') {
        if (mutation.payload.data.status === 'complete') {
          this.$emit('upload-complete');
        }
      }
    });
  },
  mounted() {
    this.$refs.dropZone.parentElement.addEventListener('dragenter', this.handleDragEnter);
    this.$refs.dropZone.parentElement.addEventListener('dragover', this.handleDragOver);
    this.$refs.dropZone.parentElement.addEventListener('dragleave', this.handleDragLeave);
  },
  unmounted() {
    this.$refs.dropZone?.parentElement.removeEventListener('dragenter', this.handleDragEnter);
    this.$refs.dropZone?.parentElement.removeEventListener('dragover', this.handleDragOver);
    this.$refs.dropZone?.parentElement.removeEventListener('dragleave', this.handleDragLeave);
  },
  methods: {
    handleDragEnter(e) {
      e.preventDefault();
      this.dragCounter += 1;
      this.dropHighlighted = true;
    },
    handleDragOver(e) {
      e.preventDefault();
    },
    handleDragLeave() {
      this.dragCounter -= 1;
      if (this.dragCounter === 0) {
        this.dropHighlighted = false;
      }
    },
    handleDrop(e) {
      this.files = e.dataTransfer.files;
      this.upload(this.files);
    },
    async upload(files) {
      if (files == null || (files && files.length === 0)) return;

      await this.s3MultipleImageUpload({ fileList: files, params: this.importParams });
    },

    async s3MultipleImageUpload({ fileList, params }) {
      const keys = [];

      // Validate file count and sizes, also trim paths
      const maxFileSize = await this.getMaxImageSize();
      let imgCount = 0;
      let imgTooLargeErrCount = 0;
      for (const file of fileList) {
        const isImage = file.type.includes("image/");
        const isVideo = file.type.includes("video/");
        if ((isImage || isVideo) && (file.size <= maxFileSize || maxFileSize === -1)) {
          keys.push(file.name);
          imgCount += 1;
        } else if (isImage || isVideo) {
          imgTooLargeErrCount += 1;
        }
      }

      // Check image upload limit
      const imageLimit = await this.checkImageUploadLimit(imgCount)
        .catch((error) => {
          throw error;
        });
      if (imageLimit < 0) {
        this.$emit("upload-error", {
          confirmMessage: `Not enough credits to perform this operation.`,
          confirmMessageHeader: 'Insufficient Credits',
        });
        return null;
      }

      // How many files were too large to upload
      if (imgTooLargeErrCount > 0) {
        this.$emit("upload-error", {
          confirmMessage: `${imgTooLargeErrCount} image(s) were discard due to file size limit.`,
          confirmMessageHeader: 'Large File Size',
        });
      }

      // Get s3 presigned urls
      const presignedResponse = await this.s3CreatePresignedUrl({
        dataset_id: params.dataset_id,
        keys,
      })
        .catch((error) => {
          throw error;
        });

      const newFileList = [];
      if (presignedResponse) {
        const {
          importerSource, indexes, presignedUrls, tnPresignedUrls,
        } = presignedResponse;

        if (importerSource) {
          params.importer_source = importerSource;
        }

        presignedUrls.forEach((url, i) => {
          newFileList.push({
            url,
            thumbnail_url: tnPresignedUrls[i],
            file: fileList[indexes[i]],
          });
        });
      }
      this.processingFiles = false;
      const importResponse = await this.$store.dispatch('importer/importByType', { urls: newFileList, importParams: params });

      return importResponse;
    },
    async getMaxImageSize() {
      return this.dataConnect.getAccountingMaxImageSize()
        .then((resp) => {
          if (resp.error !== undefined) {
            throw Error(resp.error);
          }
          return resp.result;
        })
        .catch((error) => {
          throw error;
        });
    },
    async checkImageUploadLimit(imgCount) {
      return this.dataConnect.checkImageLimitForAccounting({
        image_count: imgCount,
      })
        .then((resp) => {
          if (resp.error !== undefined) {
            throw Error(resp.error);
          }
          return resp.result;
        })
        .catch((error) => {
          throw error;
        });
    },
    async s3CreatePresignedUrl({ dataset_id, keys }) {
      return this.dataConnect.s3CreatePresignedUrl({
        dataset_id,
        keys,
      })
        .then((resp) => {
          if (resp.error !== undefined) {
            throw Error(resp.error);
          }
          return resp.result;
        })
        .catch((error) => {
          throw error;
        });
    },
  },
};
</script>

<style lang="scss" scoped>

.upload-images {
  // display: flex;
  // flex-direction: column;
  // padding: 1rem;
  // overflow: auto;

  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
  z-index: 1;
}

.file-upload-backdrop {
  position: relative;
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  padding: 32px;
  align-items: center;
  justify-content: center;
  border: 2px dashed grey;
  border-radius: 8px;
  width: 100%;
  height: 100%;
  pointer-events: auto;

  &__upload-icon {
    position: absolute;
    bottom: 50%;
    width: 48px;
    height: 48px;
    @include themify() {
      color: themed('color-primary');
    }
  }

  &__text {
    position: absolute;
    top: 50%;
    display: flex;
    flex-direction: column;

    & span {
      padding-top: 0.25rem;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      direction: rtl;
      color: #250E81;
      font-size: 16px;
      font-weight: bold;
    }

    & span + span {
      color: grey;
      font-size: 12px;
    }
  }

  &._highlight {
    padding: 27px;
    border: 3px dashed #250E81;
    background: #250e8125;
    animation-name: fill-upload-btn;
    animation-duration: 0.5s;

    & .file-upload-backdrop__upload-icon {
      width: 56px;
      height: 56px;
      animation-name: grow-upload-icon;
      animation-duration: 0.05s;
    }
    @keyframes grow-upload-icon {
      from {  width: 48px; height: 48px;}
      to {  width: 56px; height: 56px;}
    }

    @keyframes fill-upload-btn {
      from {  background: transparent;}
      to {  background: #250e8125;}
    }
  }
}

</style>
