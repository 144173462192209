<template>
  <div class="annotation-display-filter-button">
    <FilterPopper>
      <template #default="{ menuOpen }">
        <button class="button sets-toggle-button" :class="{'active': menuOpen || displayActive}">
          Sort By
          <SVGIcon
            :iconName="'expand_more'"
            :width="'20px'"
            :height="'20px'"
          />
        </button>
      </template>
      <template #popper>
        <div class="sort-settings scrollbar">
          <template v-for="sort, idx in sortTypes" :key="idx">
            <label
              :for="`menuItem-${sort.value}`"
              class="sort-settings__row sort-settings__row-button"
              @click="() => selectedSort = sort.value"
            >
              <div class="sort-settings__row-button-check me-2">
                <SVGIcon
                  v-if="selectedSort===sort.value"
                  :iconName="'check'"
                  :height="'20px'"
                  :width="'20px'"
                />
              </div>
              <span>{{ sort.name }}</span>
            </label>
          </template>
        </div>
      </template>
    </FilterPopper>
    <FilterPopper
      :placement="'right-start'"
      :offset="[-4,0]"
    >
      <button class="settings-toggle-button" title="List By" @click.stop="listAscend = !listAscend">
        <SVGIcon
          :iconName="'arrow_upward'"
          :width="18"
          :height="24"
          :class="{_invert: !listAscend}"
        />
      </button>
    </FilterPopper>
  </div>
</template>

<script>
import FilterPopper from '@/components/FilterPopper.vue';
import SVGIcon from '@/components/SVGIcon.vue';

export default {
  name: "GalleryImageSort",
  components: {
    FilterPopper,
    SVGIcon,
  },
  props: {
    sortBy: {
      type: String,
      default: 'id',
    },
    ascend: {
      type: Boolean,
      default: true,
    },
  },
  emits: [
    'update:sortBy',
    'update:ascend',
  ],
  data() {
    return {
      sortTypes: [{
        name: 'Date Added',
        value: 'id',
      }, {
        name: 'Filename',
        value: 'name',
      }],
      listAscend: false,
      selectedSort: 'id',
    };
  },
  watch: {
    selectedSort() {
      this.$emit('update:sortBy', this.selectedSort);
    },
    listAscend() {
      this.$emit('update:ascend', this.listAscend);
    },
  },
};
</script>

<style lang="scss" scoped>

.filter-tag {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 18px;
  padding: 0 6px;
  height: 18px;
  @include themify() {
    background: themed('color-accent');
  }
}

.sets-toggle-button, .settings-toggle-button {
  background: white;

}

.sort-settings {
  position: relative;
  display: flex;
  flex-direction: column;
  border-radius: inherit;
  text-transform: none;
  min-width: 100px;

  &.scrollbar {
    overflow: auto;
  }

  &__row {
    display: flex;
    align-items: center;
    position: relative;
    max-width: 500px;
  }

  &__row-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: transparent;
    border: none;
    padding: 6px 8px;
    cursor: pointer;

    &:first-of-type {
      border-top-left-radius: inherit;
      border-top-right-radius: inherit;
    }
    &:last-of-type {
      border-bottom-left-radius: inherit;
      border-bottom-right-radius: inherit;
    }

    &:hover {
      background: rgba(0,0,0,0.125);
    }
    &:focus-within {
      background: rgba(0,0,0,0.125);
    }

    &> * {
      pointer-events: none;
    }

    input {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      opacity: 0;
      margin: 0;
    }

    span, label {
      font-size: 0.825rem;
      font-weight: 500;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }

  &__row-button-check {
    width: 20px;
    min-width: 20px;
    height: 20px;
    min-height: 20px;
    @include themify() {
      color: themed('filter-button-list-row-button-selected')
    }
  }

  &__row-button-legend {
    min-width: 10px;
    min-height: 10px;
    margin-right: 12px;
    border-radius: 100%;
  }

  &__row-button-chevron-right {
    margin-left: auto;
    padding-left: 4px;
  }
}

.annotation-display-filter-button {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 26px;
  padding: 0;
  box-shadow: 1px 2px 4px 1px rgba(0, 0, 0, 0.2), 0 0 1px 1px rgba(0, 0, 0, 1);
  @include themify() {
    color: themed('body-text-color')
  }
}

.sets-toggle-button {
  display: flex;
  align-items: center;
  height: 100%;
  flex: 1 1 auto;
  padding: 4px 8px;
  gap: 2px;
  border-right: solid 1px rgba(0,0,0,0.5);
  color: inherit;

  &:hover {
    box-shadow: 0 0 2px 1px rgba(var(--color-primary-rgb), 0.2) inset;
    background: rgba(var(--color-primary-100-rgb), 0.5);
  }

  &:active {
    opacity: 1;
  }

  &.active {
    @include themify() {
      box-shadow: 0 0 2px 1px rgba(var(--color-primary-rgb), 0.5) inset;
      background: rgba(var(--color-primary-100-rgb), 0.8);
    }
  }
}

.settings-toggle-button {
  display: flex;
  align-items: center;
  padding: 0 4px;
  color: inherit;

  &:active {
    opacity: 1;
  }
}

._invert {
    transform: rotate(180deg);
  }
</style>
