<template>
  <Modal
    ref="mergeDatasetModal"
    :title="'Copy Datasets'"
  >
    <template #modal-main>
      <form
        id="copy-form"
        class="form container scrollbar"
        novalidate
        @change="handleFormChange"
        @submit.prevent=""
      >
        <div class="copy-card">
          <h3>Source</h3>
          <div class="row">
            <div class="col-3 settings__control-group">
              <label class="required">Project</label>
              <select
                id="project-select"
                v-model="project"
                class="select"
                :disabled="lockSource"
                required
                @change="selectedProjectChange(project)"
              >
                <option :key="'project-select-null'" disabled :value="''">
                  Select a Project
                </option>
                <option v-for="option in projectList" :key="`project-select-${option.id}`" :value="option">
                  {{ option.name }}
                </option>
              </select>
            </div>
          </div>
          <div class="row mt-3">
            <div class="col-3 settings__control-group">
              <label class="required">Dataset</label>
              <select
                id="source-DS-select"
                v-model="sourceDS"
                class="select"
                :class="{error: hasError && !sourceParams.dataset_id}"
                :disabled="lockSource"
                required
              >
                <option :key="'source-select-null'" disabled :value="''">Select a Dataset</option>
                <option v-for="option in sourceDatasets" :key="`source-select-${option.id}`" :value="option">
                  {{ option.name }}
                </option>
              </select>
            </div>
            <div v-if="sourceDS?.groups && sourceDS.groups.length > 0" class="col-3 settings__control-group">
              <label>Group</label>
              <select
                id="source-group-select"
                v-model="sourceGroup"
                class="select"
                required
              >
                <option :key="'source-group-null'" :value="''">All Groups</option>
                <option v-for="option in sourceDS.groups" :key="`source-group-${option.id}`" :value="option">
                  {{ option.name }}
                </option>
              </select>
            </div>
            <div class="col-3 settings__control-group">
              <label>Annotation Set</label>
              <AnnotationSetsRadioSelect
                :id="'copyDS-source'"
                v-model="sourceAnnset"
                :annotationSets="sourceDS ? sourceDS.annotation_sets : null"
                class="annotation-set-select"
              />
            </div>
          </div>
          <div class="row mt-3">
            <v-checkbox
              v-model="copyAnnSetForDuplicate"
              color="deep-purple-darken-4"
              :hide-details="true"
              :density="'compact'"
            >
              <template #label>
                <span class="duplicate-copy-label">Copy Annotations For Duplicate Images</span>
              </template>
            </v-checkbox>
          </div>
          <div v-if="showFilters" class="row mt-1">
            <div class="col-12">
              <v-expansion-panels
                v-model="panels"
                multiple
                class="mt-2"
              >
                <v-expansion-panel
                  value="description"
                >
                  <v-expansion-panel-title>
                    <h3>Source Dataset Filters</h3>
                  </v-expansion-panel-title>
                  <v-expansion-panel-text>
                    <DatasetFilter
                      v-model="datasetFilters"
                      :dataset="sourceDS"
                      :annotationSets="sourceDS.annotation_sets"
                      :labels="sourceDS.labels"
                      :types="sourceDS.annotation_types"
                    />
                  </v-expansion-panel-text>
                </v-expansion-panel>
              </v-expansion-panels>
            </div>
          </div>
          <hr class="mt-3">
          <h3 class="destination-header">
            Destination Dataset(s)
            <IconButton
              class="add-dataset-icon"
              :icon="'add'"
              :width="24"
              :height="24"
              :title="`Add a Destination Dataset`"
              :type="''"
              @click.stop="addNewDestDS"
            />
          </h3>
          <div class="row dest-set-selector-container scrollbar">
            <template
              v-for="(dataset, i) in chosenDestDatasetAnnset"
              :key="dataset.d ? `dest-${dataset.d.id}` : `dest-null`"
            >
              <div class="col-12">
                <div class="row dest-set-selector">
                  <div class="col-3 settings__control-group">
                    <select
                      id="dest-DS-select"
                      v-model="dataset.d"
                      class="select"
                      :class="{error: hasError && invalidDestinationsIndexes.includes(i)}"
                      required
                    >
                      <option :key="'dest-select-null'" disabled :value="''">Select a Dataset</option>
                      <option v-for="option in datasets" :key="`dest-select-${option.id}`" :value="option">
                        {{ option.name }}
                      </option>
                    </select>
                  </div>
                  <div v-if="dataset.d.groups && dataset.d.groups.length > 0" class="col-3 settings__control-group">
                    <select
                      :id="`dest-group-select-${i}`"
                      v-model="dataset.group_id"
                      class="select"
                      required
                    >
                      <option :key="'destination-group-null'" :value="''">Ungrouped</option>
                      <option v-for="option in dataset.d.groups" :key="`destination-group-${option.id}`" :value="option.id">
                        {{ option.name }}
                      </option>
                    </select>
                  </div>
                  <div class="col-3 settings__control-group">
                    <AnnotationSetsRadioSelect
                      :id="'copyDS-dest'"
                      v-model="dataset.set"
                      :annotationSets="dataset.d ? dataset.d.annotation_sets : null"
                      class="annotation-set-select"
                    />
                  </div>
                  <div class="col settings__control-group me-1">
                    <div class="percent-input">
                      <input
                        v-model="dataset.percent"
                        :min="0"
                        :max="100"
                        type="number"
                        class="random-input"
                        :class="{error: hasError && !isDestinationSplitValid}"
                        @change="changeSanitizer"
                        @input="inputSanitizer"
                      >
                      %
                      <IconButton
                        class="remove-dest-ds-button ms-3"
                        :icon="'close'"
                        :width="20"
                        :height="20"
                        @click="removeDestDS(dataset)"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </div>
        </div>
      </form>
      <div v-if="awaitMergerResult || message" class="row mb-3 result-container">
        <div v-if="awaitMergerResult" class="loader merger-loader" />
        <div v-if="message && !awaitMergerResult" class="result" :class="[success ? 'success' : 'error']">
          <span><pre>{{ message }}</pre></span>
        </div>
      </div>
    </template>
    <template #modal-footer>
      <div class="footer">
        <div>
          <button
            class="button modal-action-button"
            :disabled="awaitMergerResult ? true : false || (destinationParams && destinationParams[0] === null)"
            @click="applyCopy"
          >
            Apply
          </button>
        </div>
      </div>
    </template>
  </Modal>
</template>

<script>
import Modal from '@/components/Modal.vue';
import IconButton from '@/components/IconButton.vue';
import { inputSanitizer, changeSanitizer } from '@/assets/js/utils';
import DatastoreConnect from '@/assets/js/DatastoreFunctions/datastore-interface';
import DatasetFilter from '@/components/DatasetComponent/DatasetFilters/DatasetFilter.vue';
import AnnotationSetsRadioSelect from '../AnnotationSetsRadioSelect.vue';

export default {
  name: 'DatasetCopyModal',
  components: {
    Modal,
    AnnotationSetsRadioSelect,
    IconButton,
    DatasetFilter,
  },
  props: {
    datasets: {
      type: Array,
      default: () => [],
    },
    source: {
      type: Object,
      default: () => {},
    },
    filters: {
      type: Object,
      default: null,
    },
    showFilters: {
      type: Boolean,
      default: true,
    },
    lockSource: {
      type: Boolean,
      default: false,
    },
  },
  emits: [
    'clear-copy', 'copy-complete',
  ],
  data() {
    return {
      dataConnect: null,
      project: '',
      sourceDatasets: [],
      sourceDS: '',
      sourceGroup: '',
      sourceAnnset: {},
      awaitMergerResult: false,
      success: false,
      message: "",
      random: 100,
      chosenDestDatasetAnnset: [{
        d: '',
        group_id: '',
        set: {},
        percent: 100,
      }],
      panels: [],
      datasetFilters: {},
      copyAnnSetForDuplicate: false,
    };
  },
  computed: {
    username() {
      return this.$store.state.user.user.username;
    },
    projectList() {
      return this.$store.state.projects.projectList;
    },
    hasError() {
      return this.message && !this.success;
    },
    sourceDatasetLabels() {
      const sourceDatasetLabels = this.sourceDS?.labels;
      if (sourceDatasetLabels) {
        return sourceDatasetLabels;
      }
      return [];
    },
    destinationParams() {
      const params = this.chosenDestDatasetAnnset.map((dest) => {
        const destParams = {
          dataset_id: dest.d.id,
          percent: dest.percent,
        };
        if (dest.set) {
          destParams.annotation_set_id = dest.set.id;
        }
        if (dest.group_id) {
          destParams.group_id = dest.group_id;
        }

        if (dest.d.id) {
          return destParams;
        } else {
          return null;
        }
      });
      return params;
    },
    sourceParams() {
      const params = {};

      if (this.sourceDS) {
        params.dataset_id = this.sourceDS.id;
      }
      if (this.sourceGroup) {
        params.dataset_group_id_src = this.sourceGroup.id;
      }
      if (this.sourceAnnset) {
        params.annotation_set_id_src = this.sourceAnnset.id;
      }

      return params;
    },
    filterParams() {
      if (this.filters) {
        return this.filters;
      }

      const params = {
        images_filter: {},
        image_files_filter: {},
        annotations_filter: {},
        image_review_status_filter: {},
      };

      // Images filter
      if (this.datasetFilters.groupsFilter && this.datasetFilters.groupsFilter.length > 0) {
        params.images_filter.group_ids = this.datasetFilters.groupsFilter;
      }

      // Image files filter
      if (this.datasetFilters.imageNameFilter && this.datasetFilters.imageNameFilter.length > 0) {
        params.image_files_filter.image_name = this.datasetFilters.imageNameFilter;
      }
      if (this.datasetFilters.timeRangeFilter) {
        params.image_files_filter.lower_time = this.datasetFilters.timeRangeFilter[0];
        params.image_files_filter.upper_time = this.datasetFilters.timeRangeFilter[1];
      }
      if (this.datasetFilters.dateRangeFilter) {
        if (this.datasetFilters.dateRangeFilter[0]) {
          params.image_files_filter.lower_date = new Date(this.datasetFilters.dateRangeFilter[0]).toISOString();
        }
        if (this.datasetFilters.dateRangeFilter[1]) {
          params.image_files_filter.upper_date = new Date(this.datasetFilters.dateRangeFilter[1]).toISOString();
        }
      }
      if (this.datasetFilters.cameraSourceFilter && this.datasetFilters.cameraSourceFilter.length > 0) {
        params.image_files_filter.image_sources = this.datasetFilters.cameraSourceFilter;
      }

      // Annotations filter
      if (this.datasetFilters.confidenceRangeFilter) {
        params.annotations_filter.lower_score = this.datasetFilters.confidenceRangeFilter[0];
        params.annotations_filter.upper_score = this.datasetFilters.confidenceRangeFilter[1];
      }
      if (this.datasetFilters.annotationTypeFilter && this.datasetFilters.annotationTypeFilter.length > 0) {
        params.annotations_filter.types = this.datasetFilters.annotationTypeFilter;
      }
      if (this.datasetFilters.annotationSetFilter && this.datasetFilters.annotationSetFilter.length > 0) {
        params.annotations_filter.annotation_set_ids = this.datasetFilters.annotationSetFilter.map((set) => set.id);
      }
      if (this.datasetFilters.imagesWithAnnotationSetFilter && this.datasetFilters.imagesWithAnnotationSetFilter.length > 0) {
        params.annotations_filter.images_with_annotation_set_ids = this.datasetFilters.imagesWithAnnotationSetFilter.map((set) => set.id);
      }
      if (this.datasetFilters.annotationLabelFilter && this.datasetFilters.annotationLabelFilter.length > 0) {
        params.annotations_filter.images_with_annotation_label_indexes = this.datasetFilters.annotationLabelFilter;
      }
      if (this.datasetFilters.boundingBoxSizeFilter) {
        Object.entries(this.datasetFilters.boundingBoxSizeFilter).forEach(([key, value]) => {
          if (key !== 'type' && value) {
            params.annotations_filter[`${key}_${this.datasetFilters.boundingBoxSizeFilter.type}`] = value;
          }
        });
      }

      return params;
    },
    isDestinationSplitValid() {
      const destinationSum = this.chosenDestDatasetAnnset.map((dest) => dest.percent).reduce((a, b) => a + b, 0);
      if (destinationSum > 100) {
        return false;
      }
      return true;
    },
    invalidDestinations() {
      return this.destinationParams.filter((dest) => !dest.dataset_id);
    },
    invalidDestinationsIndexes() {
      return this.invalidDestinations.map((dest) => this.destinationParams.indexOf(dest));
    },
  },
  created() {
    this.dataConnect = new DatastoreConnect(this.$store.state.enterpriseServerUrl);
  },
  async mounted() {
    if (this.source) {
      this.project = this.projectList.find((p) => p.id === this.source.project_id);
      this.sourceDatasets = this.datasets;
      this.sourceDS = this.sourceDatasets.find((ds) => this.source.id === ds.id);
      this.sourceGroup = '';
      this.sourceAnnset = {};
    }
  },
  methods: {
    inputSanitizer,
    changeSanitizer,
    async getDatasets(project) {
      const params = {
        project_id: project.id,
        get_annotation_sets: true,
        get_labels: true,
        get_groups: true,
      };
      const resp = await this.dataConnect.getDatasetList(params)
        .catch((error) => {
          // TODO: handle error
          console.log(error);
        });
      if (!resp || resp.error || !resp.result) {
        // TODO: handle error
        if (resp.error) console.error(resp.error);
        return [];
      }
      return resp.result;
    },
    async selectedProjectChange(p) {
      if (p) {
        this.sourceDS = '';
        this.sourceGroup = '';
        this.sourceAnnset = {};
        this.sourceDatasets = await this.getDatasets(p);
      }
    },
    showModal() {
      this.$refs.mergeDatasetModal.showModal();
    },
    addNewDestDS() {
      this.chosenDestDatasetAnnset.push({
        d: '',
        group_id: '',
        set: {},
        percent: 100,
      });
    },
    removeDestDS(ds) {
      const index = this.chosenDestDatasetAnnset.indexOf(ds);
      if (index > -1) {
        this.chosenDestDatasetAnnset.splice(index, 1);
      }
    },
    async applyCopy() {
      const params = {
        dest: this.destinationParams,
        ...this.sourceParams,
        ...this.filterParams,
        should_copy_annset_for_duplicate: this.copyAnnSetForDuplicate,
      };

      if (!params.dataset_id) {
        this.message = `Error: Please select Source dataset`;
        this.success = false;
        return;
      }

      if (params.dest.length === 0 || this.invalidDestinations.length > 0) {
        this.message = `Error: Please select Destination dataset`;
        this.success = false;
        return;
      }

      if (!this.isDestinationSplitValid) {
        this.message = `Error: Total destination percentage can not exceed 100`;
        this.success = false;
        return;
      }

      this.awaitMergerResult = true;
      await this.dataConnect.copyDataset(params)
        .then((response) => {
          if (response.result) {
            const result = response.result;
            this.message = "Copy Datasets Result:\n";
            if (result.length > 0) {
              this.chosenDestDatasetAnnset.forEach((el, i) => {
                // the tag uses <pre> so all spaces and \n need to be on same line
                this.message += `Dataset "${el.d.name}" -- Annotation Set "${el.set.name ? el.set.name : 'none'}":\n${result[i].new_images_count} new images -- ${result[i].duplicate_images_count} duplicate images -- ${result[i].new_annotations_count} new annotations -- ${result[i].duplicate_annotations_count} duplicate annotations\n`;
                this.$emit('copy-complete', el.d.id);
              });
            } else {
              this.chosenDestDatasetAnnset.forEach((el) => {
                this.message = 'No image or annotation was copied with the selected filters.';
                this.$emit('copy-complete', el.d.id);
              });
            }
            this.success = true;
            this.chosenDestDatasetAnnset = [{
              d: '',
              group_id: '',
              set: {},
              percent: 100,
            }];
          } else if (response.error) {
            this.message = `Error: ${response.error.message}`;
            this.success = false;
          } else {
            this.message = `Error Copying data`;
            this.success = false;
          }
          this.awaitMergerResult = false;
          this.$store.commit('notifications/updateNotifications', true);
        })
        .catch((e) => {
          this.message = `Error: ${e}`;
          this.success = false;
          this.awaitMergerResult = false;
        });
    },
    clearMessage() {
      this.success = false;
      this.message = "";
    },
    handleFormChange() {
      this.clearMessage();
    },
  },
};
</script>

<style lang="scss" scoped>
#copy-form {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  padding: 0 3rem;
  margin: 0.75rem 0;
  overflow: auto;
  position: relative;
}

.copy-card {
  display: flex;
  flex-direction: column;
  background: var(--color-white-100);
  padding: 12px;
  border-radius: 8px;
  border: 1px solid var(--color-primary-100);
  min-width: 850px;
}

.help-tooltip {
  position: absolute;
  top: 1rem;
  right: 3.25rem;
}

.tooltip-text{
  span {
    width: 100%;
    word-wrap: break-word;
  }
}

.dest-set-selector-container {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding-top: 4px;
}

.dest-set-selector {
  .annotation-set-select {
    margin-top: auto;
    margin-bottom: auto;
  }

  .percent-input {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: auto;
    width: 120px;
    min-width: 120px;
    height: 100%;
  }

  .remove-dest-ds-button {
    margin-right: 10px;
    visibility: hidden;
  }

  &:hover {
    background-color: rgb(237, 232, 232);
  }

  &:hover .remove-dest-ds-button {
      z-index: 3;
    visibility: visible;
    @include themify() {
      color: themed('icon-color-inactive');
    }
  }

  &:hover .remove-dest-ds-button:hover {
      z-index: 3;
    visibility: visible;
    @include themify() {
      color: themed('icon-color-primary');
    }
  }
}

.result {
  display: flex;
  width: 100%;
  height: 100%;
  border-radius: 5px;
  font-size: 1rem;
  font-weight:600;
  padding: 3px 10px;
  align-items: center;
  justify-content: center;
}

.result-container {
  justify-content: center;
}

.success {
  text-align: left;
  @include themify() {
    background: rgba(115, 206, 115, 0.5) ;
    color: themed('color-success');
    border: 1px solid themed('color-success');
  }
}

.error {
  @include themify() {
    background: rgba(182, 94, 94, 0.5) ;
    color: themed('color-error');
    border: 1px solid themed('color-error');
  }
}

.merger-loader {
  width: 60px !important;
  height: 60px !important;
}

.random-input {
  width: 70px;
  height: 30px;
  background: #ffffff;
  border-radius: 5px;
  font-size: 1rem;
  padding: 3px 10px;
  margin-left: 5px;
  margin-right: 5px;
  align-self: center;
  -webkit-appearance: auto;
  @include themify() {
    border: 1px solid themed('text-input-border-color');
  }
}

.destination-header {
  display: flex;
  flex-direction: row;
  align-items: center;

  .add-dataset-icon {
    visibility: visible;
    margin-left: 6px;
    @include themify() {
      color: themed('icon-color-inactive');
    }
  }

  .add-dataset-icon:hover {
    @include themify() {
      color: themed('icon-color-primary');
    }
  }

}

.footer {
  display: flex;
  flex-direction: row;
  padding: 0rem 3rem 0.75rem 3rem;
  justify-content: flex-end;
}

.settings__control-group {
  :deep(.filter-button) {
    width: 100%;
    height: 100%;
  }
}

.duplicate-copy-label {
  margin-left: 10px;
  font-size: 14px;
  font-weight: 600;
}
</style>
