import {
  computed, unref,
} from 'vue';
import { getColor } from '@/assets/js/utils.js';

export default function useAnnotationColorMap({ items, key }) {
  const colorMap = computed(() => {
    const map = {};
    unref(items).forEach((item, i) => {
      map[item[key]] = getColor(i);
    });
    return map;
  });

  return {
    map: colorMap,
  };
}
