<template>
  <!-- eslint-disable max-len -->
  <template v-if="splitAnnotations">
    <div class="grid-group-label"><label>{{ imageObj.name }}</label></div>
    <template v-for="(annotation, index) in filteredAnnotations" :key="index">
      <BaseGalleryGridItem
        :imageObj="imageObj"
        :annotations="annotation"
        :annotationSets="annotationSets"
        :annotationDisplayType="annotationDisplayType"
        :filterAnnotationsBySets="filterAnnotationsBySets"
        :filterAnnotationsByLabelIndexes="filterAnnotationsByLabelIndexes"
        :crop="annotation"
        :cropPadding="0.05"
        class="image-review-status-grid-item"
        :class="{'_unselectable': !canSelectImage()}"
        :imageQuality="'image'"
        :fetchPriority="'low'"
        @click="handleImageClicked"
        @mouseover="mouseHover=true"
        @mouseleave="mouseHover=false"
      >
        <template #header>
          <div v-if="imageReviewStatus?.status !== 'Pending'" class="gallery-card-header">
            <IconButton
              v-if="mouseHover"
              class="delete-icon"
              :icon="'delete'"
              :width="36"
              :height="36"
              @click.stop="handleDeleteClicked"
            />
          </div>
        </template>
        <template #content>
          <div
            v-if="imageReviewStatus"
            class="overlay"
            :class="`_${imageReviewStatus?.status}`"
            :style="{ '--reviewer': `'${imageReviewStatus?.username}'` }"
          />
          <GalleryGridItemLabelTags
            :labels="labels"
            :annotationList="annotation"
          />
        </template>
      </BaseGalleryGridItem>
    </template>
    <div class="grid-group-fill" />
  </template>
  <template v-else>
    <BaseGalleryGridItem
      :imageObj="imageObj"
      :annotations="filteredAnnotations"
      :annotationSets="annotationSets"
      :annotationDisplayType="annotationDisplayType"
      :filterAnnotationsBySets="filterAnnotationsBySets"
      :filterAnnotationsByLabelIndexes="filterAnnotationsByLabelIndexes"
      class="image-review-status-grid-item"
      :class="{'_unselectable': !canSelectImage()}"
      @click="handleImageClicked"
      @mouseover="mouseHover=true"
      @mouseleave="mouseHover=false"
    >
      <template #header>
        <div v-if="imageReviewStatus?.status !== 'Pending'" class="gallery-card-header">
          <IconButton
            v-if="mouseHover"
            class="delete-icon"
            :icon="'delete'"
            :width="36"
            :height="36"
            @click.stop="handleDeleteClicked"
          />
        </div>
      </template>
      <template #content>
        <div
          v-if="imageReviewStatus"
          class="overlay"
          :class="`_${imageReviewStatus?.status}`"
          :style="{ '--reviewer': `'${imageReviewStatus?.username}'` }"
        />
        <GalleryGridItemLabelTags
          :labels="labels"
          :annotationList="filteredAnnotations"
        />
      </template>
    </BaseGalleryGridItem>
  </template>
</template>

<script>
import IconButton from '@/components/IconButton.vue';
import BaseGalleryGridItem from '@/components/DatasetComponent/BaseGalleryGridItem.vue';
import GalleryGridItemLabelTags from '@/components/DatasetComponent/GalleryComponent/GalleryGridItemLabelTags.vue';
import DatastoreConnect from '../../../assets/js/DatastoreFunctions/datastore-interface';

export default {
  name: 'AnnotationEditorGalleryGridItem',
  components: {
    BaseGalleryGridItem,
    GalleryGridItemLabelTags,
    IconButton,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    imageObj: {
      type: Object,
      default: () => {},
    },
    annotations: {
      type: Array,
      default: () => [],
    },
    annotationSets: {
      type: Array,
      default: () => [],
    },
    annotationDisplayType: {
      type: String,
      default: 'set',
    },
    labels: {
      type: Array,
      default: () => [],
    },
    filterAnnotationsBySets: {
      type: Array,
      default: null,
    },
    filterAnnotationsByLabelIndexes: {
      type: Array,
      default: null,
    },
    imageReviewStatus: {
      type: Object,
      default: null,
    },
    reviewSettings: {
      type: Object,
      default: null,
    },
    splitAnnotations: {
      type: Boolean,
      default: false,
    },
  },
  emits: [
    'grid-item-clicked', 'delete-image-from-dataset',
  ],
  data() {
    return {
      dsConn: null,
      datastoreURL: null,
      mouseHover: false,
      renderedImageDimensions: {
        width: 0,
        height: 0,
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
      },
    };
  },
  computed: {
    filteredAnnotations() {
      if (this.annotations?.length > 0) {
        // Create boxes for the annotations to be displayed
        let filteredAnnotationList = this.annotations;
        if (this.filterAnnotationsBySets?.length >= 0) {
          filteredAnnotationList = filteredAnnotationList.filter((anno) => this.filterAnnotationsBySets.includes(anno.annotation_set_id));
        }
        if (this.filterAnnotationsByLabelIndexes?.length >= 0) {
          filteredAnnotationList = filteredAnnotationList.filter((anno) => this.filterAnnotationsByLabelIndexes.includes(anno.label_index));
        }
        return filteredAnnotationList;
      }
      return [];
    },
  },
  created() {
    this.enterpriseServerUrl = this.$store.state.enterpriseServerUrl;
    this.dsConn = new DatastoreConnect(this.enterpriseServerUrl);
  },
  methods: {
    handleImageClicked() {
      this.$emit('grid-item-clicked', this.imageObj);
    },
    handleDeleteClicked() {
      this.$emit('delete-image-from-dataset', this.imageObj);
    },
    canSelectImage() {
      if (!this.imageReviewStatus || this.reviewSettings.reviewSession.reviewer === this.imageReviewStatus.username) {
        return true;
      }
      return false;
    },
  },
};
</script>

<style lang="scss" scoped>

.delete-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 10;
  background: white;
  border: 6px solid white;
  border-radius: 100%;
  box-shadow: 0 0px 5px 0 rgb(0 0 0 / 38%);
  @include themify() {
    color: themed('icon-color-primary');
  }
}

.delete-icon:hover {
  border: 5px solid transparent;
  border-radius: 100%;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  border-radius: 6px;
  z-index: 3;

  &._Pending {
    background: rgba(var(--color-primary-rgb), 0.25);
    border: 2px solid var(--color-primary);
    &::after {
      background-color: var(--color-primary);
      color: var(--primary-text-color);
    }
  }

  &._Done {
    background: rgba(var(--color-success-rgb), 0.25);
    border: 2px solid var(--color-success);
    &::after {
      background-color: var(--color-success);
      color: var(--primary-text-color);
    }
  }

  &::after {
    content: var(--reviewer);
    font-weight: 600;
    padding: 5px 10px;
    position: absolute;
    top: -10px;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 5px;
  }
}

.image-review-status-grid-item {
  &._unselectable {
    outline: none !important;
    cursor: not-allowed;
  }
}

.grid-group-fill {
  grid-column-end: -1;
}

.grid-group-label {
  display: flex;
  grid-column-start: 1;
  grid-column-end: -1;
  height: 40px;
  position: relative;

  label {
    position: absolute;
    bottom: 0;
    transform: translate(0, 25%);
    font-weight: 700;
  }
}
</style>
