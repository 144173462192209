<template>
  <div class="main-page scrollbar">
    <div class="settings">
      <span class="header">Edit Organization Information</span>

      <div class="row mt-4">
        <label class="label required">Name</label>
        <div class="value">
          <input
            v-model="name"
            class="select"
            type="text"
            :class="{ 'error' : errorName }"
          >
        </div>
      </div>
      <div class="row mt-4">
        <label class="label">Street</label>
        <div class="value">
          <input
            v-model="street"
            class="select"
            type="text"
            :class="{ 'error' : errorStreet }"
          >
        </div>
      </div>
      <div class="row mt-4">
        <label class="label">City</label>
        <div class="value">
          <input
            v-model="city"
            class="select"
            type="text"
            :class="{ 'error' : errorCity }"
          >
        </div>
      </div>
      <div class="row mt-4">
        <label class="label">Country</label>
        <div class="value">
          <input
            v-model="country"
            class="select"
            type="text"
            :class="{ 'error' : errorCountry }"
          >
        </div>
      </div>
      <div class="row mt-4">
        <label class="label">Contact Name</label>
        <div class="value">
          <input
            v-model="contactName"
            class="select"
            type="text"
            :class="{ 'error' : errorContactName }"
          >
        </div>
      </div>
      <div class="row mt-4">
        <label class="label">Contact Email</label>
        <div class="value">
          <input
            v-model="contactEmail"
            class="select"
            type="text"
            :class="{ 'error' : errorContactEmail }"
          >
        </div>
      </div>
      <div class="row mt-4">
        <label class="label">Contact Phone</label>
        <div class="value">
          <input
            v-model="contactPhone"
            class="select"
            type="text"
            :class="{ 'error' : errorContactPhone }"
          >
        </div>
      </div>
      <div v-if="message" class="row mt-2">
        <label class="label" />
        <div
          class="value"
          :class="{ 'error-text': isError, 'success-text' : !isError }"
        >
          {{ message }}
        </div>
      </div>
      <div class="row mt-3 footer">
        <button class="button button-sm rounded" @click.stop="editOrg()">Apply</button>
        <span @click.stop="$router.go(-1)">Cancel</span>
      </div>
    </div>
  </div>
</template>

<script>
import DatastoreConnect from '@/assets/js/DatastoreFunctions/datastore-interface';

export default {
  name: 'EditOrganization',
  components: {
  },
  data() {
    return {
      dsConn: new DatastoreConnect(this.$store.state.enterpriseServerUrl),
      orgId: null,
      name: '',
      errorName: false,
      errorStreet: false,
      errorCity: false,
      errorCountry: false,
      errorContactName: false,
      errorContactEmail: false,
      errorContactPhone: false,
      street: '',
      city: '',
      country: '',
      contactName: '',
      contactEmail: '',
      contactPhone: '',
      message: '',
      packages: [],
      pack: '',
      backendError: false,
    };
  },
  computed: {
    isError() {
      return this.errorName || this.errorStreet || this.errorCity || this.errorCountry || this.errorContactName || this.errorContactEmail || this.errorContactPhone || this.backendError;
    },
    user() {
      return this.$store.state.user.user;
    },
    paidPackage() {
      return this.pack && this.pack !== 'free';
    },
    isAdmin() {
      return this.user && (this.user.role === 'admin');
    },
    isSuper() {
      return this.user && (this.user.role === 'super');
    },
  },
  async created() {
    if (!this.isAdmin && !this.isSuper) {
      this.$router.push('/login');
    }
    this.getAllPackages();
    if (this.$route.query.org) {
      this.orgId = parseInt(this.$route.query.org);
      this.getOrg();
    }
  },
  methods: {
    async getAllPackages() {
      await this.dsConn.getPackageList()
        .then((data) => {
          if (data.result) {
            this.packages = data.result;
          }
        })
        .catch((e) => console.log(e));
    },
    async getOrg() {
      await this.dsConn.getOrg({ organization_id: this.orgId })
        .then((data) => {
          if (data.result) {
            this.name = data.result.name;
            this.street = data.result.street;
            this.city = data.result.city;
            this.country = data.result.country;
            this.contactName = data.result.contact_name;
            this.contactEmail = data.result.contact_email;
            this.contactPhone = data.result.contact_phone;
            this.pack = data.result.package_name;
          } else {
            this.message = data.error.message;
          }
        })
        .catch((e) => { this.message = e; });
    },
    resetErrors() {
      this.errorName = false;
      this.errorStreet = false;
      this.errorCity = false;
      this.errorCountry = false;
      this.errorContactName = false;
      this.errorContactEmail = false;
      this.errorContactPhone = false;
      this.backendError = false;
    },
    async editOrg() {
      this.resetErrors();
      if (!this.name) {
        this.errorName = true;
        this.message = 'Missing Organization name.';
        return;
      }
      const params = {
        name: this.name,
        organization_id: this.orgId,
      };
      if (this.street) {
        params.street = this.street;
      }
      if (this.city) {
        params.city = this.city;
      }
      if (this.country) {
        params.country = this.country;
      }
      if (this.contactName) {
        params.contact_name = this.contactName;
      }
      if (this.contactEmail) {
        params.contact_email = this.contactEmail;
      }
      if (this.contactPhone) {
        params.contact_phone = this.contactPhone;
      }
      await this.dsConn.updateOrg(params)
        .then((data) => {
          if (data.result) {
            const temp = { ...this.user };
            temp.organization_id = data.result.id;
            temp.organization = data.result;
            this.$store.commit('user/setUser', temp);
            this.backendError = false;
            this.message = 'Successfully updated organization info.';
          } else {
            this.backendError = true;
            this.message = 'Could not update organization info.';
          }
        })
        .catch((e) => {
          this.message = e;
        });
    },
    async editPaidOrg() {
      this.resetErrors();
      if (!this.name) {
        this.errorName = true;
        this.message = 'Missing Organization name.';
        return;
      }
      if (!this.street) {
        this.errorStreet = true;
        this.message = 'Missing Street.';
        return;
      }
      if (!this.city) {
        this.errorCity = true;
        this.message = 'Missing City.';
        return;
      }
      if (!this.country) {
        this.errorCountry = true;
        this.message = 'Missing Country.';
        return;
      }
      if (!this.contactName) {
        this.errorContactName = true;
        this.message = 'Missing Contact Name.';
        return;
      }
      if (!this.contactEmail) {
        this.errorContactEmail = true;
        this.message = 'Missing Contact Email.';
        return;
      }
      if (!this.contactPhone) {
        this.errorContactPhone = true;
        this.message = 'Missing Contact Phone.';
        return;
      }
      const params = {
        name: this.name,
        organization_id: this.orgId,
        street: this.street,
        city: this.city,
        country: this.country,
        contact_name: this.contactName,
        contact_email: this.contactEmail,
        contact_phone: this.contactPhone,
      };
      await this.dsConn.updateOrg(params)
        .then((data) => {
          if (data.result) {
            const temp = { ...this.user };
            temp.organization_id = data.result.id;
            temp.organization = data.result;
            this.$store.commit('user/setUser', temp);
            this.backendError = false;
            this.message = 'Successfully updated organization info.';
          } else {
            this.backendError = true;
            this.message = 'Could not update organization info.';
          }
        })
        .catch((e) => {
          this.message = e;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.main-page {
  width: 100%;
  height: 100%;
  overflow-y: auto;
}

.settings {
  display: flex;
  flex-direction: column;
  width: 730px;
  height: 100%;
  padding: 30px;
}

.header {
  font-size: 1.2rem;
  font-weight: 700;
}

.row {
  flex-wrap: nowrap !important;
}

.row > .label {
  display: flex;
  justify-content: end;
  align-items: center;
  width: 200px;
  text-align: right;
  font-size: 0.9rem;
  font-weight: 600;
  color: rgba(grey, 0.9);

  &.required::after {
    content: '*';
    font-size: 14px;
    font-weight: 700;
    padding-left: 1px;
    @include themify() {
      color: themed('color-primary');
    }
  }
}

.row > .value {
  flex: 1 1 auto;
  font-size: 0.9rem;
  margin-left: 20px;
}

.select {
  width: 100%;
  height: 30px;
  border-radius: 5px;
  font-size: 1rem;
  padding: 3px 10px;
  align-self: center;
  -webkit-appearance: auto;
  background: rgba(#ffffff, 0.8);
  @include themify() {
    border: 1px solid themed('form-input-border');
  }

}

.select:focus {
  @include themify() {
    border: 1px solid themed('color-primary');
    box-shadow:  0 0 0 1px themed('color-primary'), 0 0 3px themed('color-primary-300');
  }
  outline: 2px solid transparent;
  outline-offset: 2px;
}

input.error , select.error {
  @include themify() {
    border: 1px solid themed('color-red') !important;
    box-shadow:  0 0 0 1px themed('color-red'), 0 0 3px themed('color-red') !important;
  }

  &:focus, &:focus-visible {
    @include themify() {
      border: 1px solid themed('color-red') !important;
      box-shadow:  0 0 0 1px themed('color-red'), 0 0 3px themed('color-red') !important;
    }
  }
}

.error-text {
  color: $color-red-500;
}

.success-text {
  color: $color-success;
}

.footer {
  display: flex;
  justify-content: flex-end;
  button {
    width: fit-content
  }
  span {
    color: $color-link;
    text-decoration: underline;
    cursor: pointer;
    width: fit-content;
  }
}

.role-grid {
  display: grid;
  grid-gap: 10px;
  grid-template-columns: repeat(auto-fill, 200px);
  grid-auto-rows: auto;
  width: 100%;
  overflow-y: auto;
}
</style>
