import { defineStore } from 'pinia';
import { ref } from 'vue';

export const useAnnotationTypeStore = defineStore('anntypes', () => {
  const allTypes = ref([
    {
      key: 'pcd',
      display: 'PCD',
    },
    {
      key: 'imu',
      display: 'IMU',
    },
    {
      key: 'gps',
      display: 'GPS',
    },
    {
      key: 'radar',
      display: 'Radar',
    },
    {
      key: 'box',
      display: '2D Bounding Box',
    },
    {
      key: '3dbox',
      display: '3D Bounding Box',
    },
    {
      key: 'seg',
      display: '2D Polygon',
    },
    {
      key: 'mask',
      display: 'Mask File',
    },
    {
      key: 'scales',
      display: 'Scales File',
    },
    {
      key: 'cube',
      display: 'Cube File',
    },
    {
      key: 'depthmap',
      display: 'Depth Map',
    },
  ]);
  const selectedDisplayTypes = ref([]);
  const jsonViewerShowTypes = ref({
    image: true,
    box: true,
    seg: true,
    pcd: false,
    json: false,
    '3dbox': false,
    depthmap: false,
  });

  function $reset() {
    selectedDisplayTypes.value = [];
    jsonViewerShowTypes.value = {
      image: true,
      box: true,
      seg: true,
      pcd: false,
      json: false,
      '3dbox': false,
      depthmap: false,
    };
    allTypes.value = [
      {
        key: 'pcd',
        display: 'PCD',
      },
      {
        key: 'imu',
        display: 'IMU',
      },
      {
        key: 'gps',
        display: 'GPS',
      },
      {
        key: 'radar',
        display: 'Radar',
      },
      {
        key: 'box',
        display: '2D Bounding Box',
      },
      {
        key: 'seg',
        display: '2D Polygon',
      },
      {
        key: '3dbox',
        display: '3D Bounding Box',
      },
      {
        key: 'mask',
        display: 'Mask File',
      },
      {
        key: 'scales',
        display: 'Scales File',
      },
      {
        key: 'cube',
        display: 'Cube File',
      },
      {
        key: 'depthmap',
        display: 'Depth Map',
      },
    ];
  }

  return {
    allTypes,
    jsonViewerShowTypes,
    selectedDisplayTypes,
    $reset,
  };
});
