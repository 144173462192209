<template>
  <!-- eslint-disable max-len -->
  <button
    class="menu-button"
    :disabled="disabled"
    :class="{ '_disabled' : disabled }"
  >
    <slot />
  </button>
</template>

<script>

export default {
  name: 'BaseMenuButton',
  components: {
  },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>

.menu-button:deep {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  outline: none;
  border: none;
  border-radius: 4px;
  background: none;
  color: inherit;
  padding: 8px 4px;
  margin: 0;
  line-height: 1;
  cursor: pointer;

  &:hover {
    background-color: var(--menu-button-hover-background);
  }

  &:focus-visible {
    background-color: var(--menu-button-hover-background);
  }

  > span {
    font: inherit;
    font-size: 1rem;
    font-weight: 600;
    white-space: nowrap;
  }

  > svg + span {
    margin-left: 12px;
  }

  &._danger {
    @include themify() {
      color: themed('color-danger');
    }
  }

  &._disabled {
    color: $color-auzone-disabled;
    cursor: not-allowed;

  }
}

</style>
