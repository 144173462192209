import { unref } from 'vue';
import DatastoreConnect from '@/assets/js/DatastoreFunctions/datastore-interface';

export default function useAnnotationDatabase(internalImageObj, internalAnnotations) {
  const datastore = new DatastoreConnect();

  function createNewAnnotation(annoObj, dest_annotation_set_id) {
    const annotation = {
      label_index: annoObj.label_index,
      label_id: annoObj.label_id,
      type: annoObj.type,
      score: 1,
      image_id: annoObj.image_id,
      annotation_set_id: dest_annotation_set_id,
      x: annoObj.x,
      y: annoObj.y,
      w: annoObj.w,
      h: annoObj.h,
    };
    if (annoObj.polygon) {
      annotation.polygon = annoObj.polygon;
    }
    if (annoObj.data_url) {
      annotation.data_url = annoObj.data_url;
    }
    if (annoObj.data_json) {
      annotation.data_json = annoObj.data_json;
    }

    return annotation;
  }

  function createExistingAnnotation(annoObj, dest_annotation_set_id) {
    const annotation = {
      id: annoObj.id,
      label_index: annoObj.label_index,
      label_id: annoObj.label_id,
      type: annoObj.type,
      score: 1,
      image_id: annoObj.image_id,
      annotation_set_id: dest_annotation_set_id,
      x: annoObj.x,
      y: annoObj.y,
      w: annoObj.w,
      h: annoObj.h,
    };
    if (annoObj.polygon) {
      annotation.polygon = annoObj.polygon;
    }
    if (annoObj.data_url) {
      annotation.data_url = annoObj.data_url;
    }
    if (annoObj.data_json) {
      annotation.data_json = annoObj.data_json;
    }

    return annotation;
  }

  async function createNewAnnotationInDatabase(annoObj, dest_annotation_set_id) {
    const annotation = createNewAnnotation(annoObj, dest_annotation_set_id);
    console.log('Create Annotation:', annotation);

    const newAnnotation = await datastore.addAnnotations(annotation)
      .catch((error) => {
        // TODO: handle error
        console.log(error);
      });
    if (newAnnotation.error || !newAnnotation.result) {
      // TODO: handle error
      return null;
    }
    return newAnnotation.result;
  }

  async function updateExistingAnnotationInDatabase(annoObj, dest_annotation_set_id) {
    const annotation = createExistingAnnotation();
    console.log('Update Annotation:', annotation);

    const updatedAnnotation = await datastore.updateAnnotations(annotation)
      .catch((error) => {
        // TODO: handle error
        console.log(error);
      });
    if (updatedAnnotation.error || !updatedAnnotation.result) {
      // TODO: handle error
      return null;
    }
    return updatedAnnotation.result;
  }

  async function deleteAnnotationInDatabase(annotation_id) {
    const deletedAnnotation = await datastore.deleteAnnotations({ id: annotation_id })
      .catch((error) => {
        // TODO: handle error
        console.log(error);
      });
    if (deletedAnnotation.error || !deletedAnnotation.result) {
      // TODO: handle error
      return false;
    }
    internalAnnotations.value = [...internalAnnotations.value.filter((anno) => anno.id !== deletedAnnotation.result.id)];
    return true;
  }

  function getAnnotationsForDatabase(_initialAnnotations, _newAnnotations, source_annotation_set_id, dest_annotation_set_id, _doneImageOpen) {
    const hasSourceAnnotationSet = Boolean(source_annotation_set_id);
    const isAudit = hasSourceAnnotationSet && (source_annotation_set_id !== dest_annotation_set_id);
    const initialAnnotations = unref(_initialAnnotations);
    const newAnnotations = unref(_newAnnotations);
    const doneImageOpen = unref(_doneImageOpen);

    const annotationsToSubmit = {
      create_annotations: [],
      update_annotations: [],
      delete_annotations: [],
    };

    // Create and update annotations in the database
    newAnnotations.forEach((anno) => {
      if (typeof anno.id !== 'number' || (isAudit && anno.annotation_set_id === source_annotation_set_id)) {
        const newAnno = createNewAnnotation(anno, dest_annotation_set_id);
        annotationsToSubmit.create_annotations.push(newAnno);
      } else {
        const updatedAnno = createExistingAnnotation(anno, dest_annotation_set_id);
        annotationsToSubmit.update_annotations.push(updatedAnno);
      }
    });

    // Delete missing annotations from the database
    if (initialAnnotations && (!isAudit || doneImageOpen)) {
      // Find annotations that have been removed from the initialAnnotations array
      const annotationsToDelete = initialAnnotations.filter((existingAnno) => !newAnnotations.map((anno) => anno.id).includes(existingAnno.id));
      annotationsToDelete.forEach((anno) => {
        annotationsToSubmit.delete_annotations.push(anno);
      });
    }

    return annotationsToSubmit;
  }

  return {
    createNewAnnotationInDatabase,
    updateExistingAnnotationInDatabase,
    deleteAnnotationInDatabase,
    getAnnotationsForDatabase,
  };
}
