<template>
  <div class="content scrollbar">
    <div class="main-content">
      <div class="welcome">
        <div class="pricing">
          <PriceTable />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PriceTable from "@/components/PriceTable.vue";

export default {
  name: 'Pricing',
  components: {
    PriceTable,
  },
  data() {
  },
  mounted() {
    this.$store.commit('setTheme', "theme-auzone-light");
  },
};
</script>
<style lang="scss" scoped>

.content {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  width: 100%;
  overflow-y: auto;
  background-color: var(--color-primary);
  background-image: url("@/assets/img/blu-stripes.png");
  background-repeat: repeat;
  color: var(--primary-text-color);
}

.main-content {
  display: flex;
  flex-direction: column;
  // height: calc(100vh - #{$header-height});
  min-height: fit-content;
}

@media (max-width: $breakpoint-md) {
  .main-content {
    height: fit-content;
  }
}

.welcome {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  min-height: 450px;
  align-items: center;

  &__paragraph {
    display: flex;
    flex-direction: column;
    width: 500px;
    margin-left: 64px;
    margin-right: 64px;
    margin-top: 75px;
    margin-bottom: 50px;
    justify-content: center;
  }

  &__prelude {
    color: var(--color-white-900);
    color: hsl(hue($primary-text-color), saturation($primary-text-color), 65%);
    font-size: 1rem;
    font-weight: 600;
    text-align: left;
  }

  &__brand-name {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    line-height: 1;
    margin-bottom: 0.5rem;

    span {
      color: var(--color-accent);
      font-weight: 700;
      font-size: 3rem;
      text-wrap: nowrap;
    }

    span + span {
      color: var(--primary-text-color);
      font-size: 3rem;
      font-weight: 300;
      margin-left: 8px;
    }
  }

  &__description {
    text-align: left;
    margin-bottom: 1rem;
  }

  &__button {
    width: 100%;
  }

  &__offer {
    color: var(--color-accent)
  }

}

@media (max-width: $breakpoint-lg) {
  .welcome {
    flex-direction: column;

    &__paragraph {
      max-width: 100%;
      padding-left: 16px;
      padding-right: 16px;
      margin-left: auto;
      margin-right: auto;
      margin-top: 25px;
      margin-bottom: 25px;
    }

    &__brand-name {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      line-height: 1;
      margin-bottom: 1rem;
      width: 100%;

      span {
        font-size: 1.75rem;
      }

      span + span {
        font-size: 1.75rem;

      }
    }
  }
}

.pricing {
  display: flex;
  padding: 16px;
  flex: 1 1 auto;
  min-width: 0;
  width: 100%;
  flex-direction: column;
  align-items: center;
}

</style>
