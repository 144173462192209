<template>
  <router-view />
</template>

<script setup>
import {
  onBeforeMount, onUnmounted,
} from 'vue';
import useGallery from '@/composables/annotationTool/useGallery.js';

const {
  $reset,
} = useGallery();

onBeforeMount(() => {
  $reset();
});

onUnmounted(() => {
  $reset();
});
</script>

<style lang="scss" scoped>

</style>
