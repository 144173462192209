import store from '@/store';
import { fetchRetry } from '@/assets/js/utils';

class DatastoreConnect {
  constructor(url = '', options = null) {
    this.url = url;
    this.options = options;
  }

  static errorMessage(error) {
    if (error === 'INVALID_URL') return ("Datastore URL Not Correct");
    if (error === 'URL_UNAVIALABLE') return ("Datastore Unavailable");
    if (error === 'INVALID_JSON') return ("Datastore Communication Error");
    if (error === "") return 'Datastore Error';
    return error;
  }

  createRPCQuery(url, {
    jsonrpc = "2.0", id = 1, params = {}, method = "", formData = null,
  }, signal = null) {
    return new Promise((resolve, reject) => {
      const headers = new Headers();
      headers.append('Authorization', `Bearer ${store.state.user.token}`);

      let body;
      if (formData) {
        body = formData;
      } else {
        body = JSON.stringify({
          jsonrpc, id, params, method,
        });
        headers.append('Content-Type', "application/json");
      }

      const options = {
        method: 'POST',
        headers,
        body,
        redirect: 'follow',
      };

      if (signal) {
        options.signal = signal;
      }

      let formattedUrl;
      if (process.env.NODE_ENV === 'development') {
        formattedUrl = `${url ? `${url}/` : ''}api?method=${method}`;
      } else {
        formattedUrl = `api?method=${method}`;
      }

      if (this.options?.tries) {
        const retryOptions = { delay: this.options.delay, tries: this.options.tries, backoff: this.options.backoff };
        fetchRetry(formattedUrl, retryOptions, options)
          .then((resp) => {
            resolve(resp.json());
          })
          .catch((error) => reject(DatastoreConnect.errorMessage(error)));
      } else {
        fetch(formattedUrl, options)
          .then((resp) => {
            resolve(resp.json());
          })
          .catch((error) => reject(DatastoreConnect.errorMessage(error)));
      }
    });
  }

  // ----------------------------------------   Package   ----------------------------------------------

  async getPackageList(params = {}) {
    return this.createRPCQuery(`${this.url}`, { method: "package.list", params });
  }

  async addPackage(params = {}) {
    return this.createRPCQuery(`${this.url}`, { method: "package.add", params });
  }

  async updatePackageQuota(params = {}) {
    return this.createRPCQuery(`${this.url}`, { method: "package.update_quota", params });
  }

  async getPackage(params = {}) {
    return this.createRPCQuery(`${this.url}`, { method: "package.get", params });
  }

  async deletePackage(params = {}) {
    return this.createRPCQuery(`${this.url}`, { method: "package.delete", params });
  }

  // ----------------------------------------   Accounting   ----------------------------------------------
  async checkImageLimitForAccounting(params = {}) {
    return this.createRPCQuery(`${this.url}`, { method: "accounting.check_image_limit", params });
  }

  async getAccountingMaxImageSize(params = {}) {
    return this.createRPCQuery(`${this.url}`, { method: "accounting.get_single_image_size", params });
  }

  async getCostPackage(params = {}) {
    return this.createRPCQuery(`${this.url}`, { method: "billing.get_cost_package", params });
  }

  async getCostPackageByName(params = {}) {
    return this.createRPCQuery(`${this.url}`, { method: "billing.get_cost_package_name", params });
  }

  async getUsageSummaryForOrganization(params = {}) {
    return this.createRPCQuery(`${this.url}`, { method: "accounting.get_usage_summary", params });
  }

  async getDateRangeReport(params = {}) {
    return this.createRPCQuery(`${this.url}`, { method: "accounting.date_range_report", params });
  }

  async getDetailedDatasets(params = {}) {
    return this.createRPCQuery(`${this.url}`, { method: "accounting.get_dataset_summary", params });
  }

  async getDetailedImages(params = {}) {
    return this.createRPCQuery(`${this.url}`, { method: "accounting.get_image_summary", params });
  }

  async purchaseCredits(params = {}) {
    return this.createRPCQuery(`${this.url}`, { method: "billing.purchase_credits", params });
  }

  async getDollarCreditRatio(params = {}) {
    return this.createRPCQuery(`${this.url}`, { method: "billing.get_dollar_to_credits", params });
  }

  async generateMonthlyBill(params = {}) {
    return this.createRPCQuery(`${this.url}`, { method: "billing.generate_monthly_bill", params });
  }

  async retrieveMonthlyBill(params = {}) {
    return this.createRPCQuery(`${this.url}`, { method: "billing.get_monthly_bill", params });
  }

  async retrieveAnnualBill(params = {}) {
    return this.createRPCQuery(`${this.url}`, { method: "billing.annual_bills", params });
  }

  // ----------------------------------------   Projects   ----------------------------------------------

  async getProject(params = {}) {
    return this.createRPCQuery(`${this.url}`, { method: "project.get", params });
  }

  async getProjectList(params = {}) {
    return this.createRPCQuery(`${this.url}`, { method: "project.list", params });
  }

  async addProject(params = {}) {
    return this.createRPCQuery(`${this.url}`, { method: "project.add", params });
  }

  async deleteProject(params = {}) {
    return this.createRPCQuery(`${this.url}`, { method: "project.del", params });
  }

  async updateProject(params = {}) {
    return this.createRPCQuery(`${this.url}`, { method: "project.update", params });
  }

  async getProjectAccessList(params = {}) {
    return this.createRPCQuery(`${this.url}`, { method: "projectaccess.list", params });
  }

  async getProjectAccessForSingleUser(params = {}) {
    return this.createRPCQuery(`${this.url}`, { method: "projectaccess.get_for_user", params });
  }

  async addProjectAccess(params = {}) {
    return this.createRPCQuery(`${this.url}`, { method: "projectaccess.add", params });
  }

  async updateProjectAccess(params = {}) {
    return this.createRPCQuery(`${this.url}`, { method: "projectaccess.update", params });
  }

  async deleteProjectAccess(params = {}) {
    return this.createRPCQuery(`${this.url}`, { method: "projectaccess.del", params });
  }

  async revertProjectAccessToDefault(params = {}) {
    return this.createRPCQuery(`${this.url}`, { method: "projectaccess.revert_to_default", params });
  }

  async setProjectAccessToPrivate(params = {}) {
    return this.createRPCQuery(`${this.url}`, { method: "projectaccess.set_to_private", params });
  }

  async getOrganizationDefaultPermissions(params = {}) {
    return this.createRPCQuery(`${this.url}`, { method: "projectaccess.get_org_default", params });
  }

  // ----------------------------------------   Datasets   ----------------------------------------------

  async getDatasetList(params) {
    return this.createRPCQuery(`${this.url}`, { method: "dataset.list", params });
  }

  async getDatasetById(params) {
    return this.createRPCQuery(`${this.url}`, { method: "dataset.get", params });
  }

  async copyDataset(params) {
    return this.createRPCQuery(`${this.url}`, { method: "dataset.copy", params });
  }

  async copyDatasetWithFilters(params) {
    return this.createRPCQuery(`${this.url}`, { method: "dataset.copyfilters", params });
  }

  async createDataset(params) {
    return this.createRPCQuery(`${this.url}`, { method: "dataset.create", params });
  }

  async deleteDataset(params) {
    return this.createRPCQuery(`${this.url}`, { method: "dataset.delete", params });
  }

  async getDatasetStats(params = {}) {
    return this.createRPCQuery(`${this.url}`, { method: "dataset.stats", params });
  }

  async updateDataset(params) {
    return this.createRPCQuery(`${this.url}`, { method: "dataset.update", params });
  }

  async freezeDataset(params) {
    return this.createRPCQuery(`${this.url}`, { method: "dataset.freeze", params });
  }

  async getDatasetDownloads(params) {
    return this.createRPCQuery(`${this.url}`, { method: "dataset.downloads", params });
  }

  async parkDataset(params) {
    return this.createRPCQuery(`${this.url}`, { method: "dataset.park", params });
  }

  async unparkDataset(params) {
    return this.createRPCQuery(`${this.url}`, { method: "dataset.unpark", params });
  }

  async getDatasetImageSources(params) {
    return this.createRPCQuery(`${this.url}`, { method: "dataset.get_sources", params });
  }

  async getDatasetAnnotationTypes(params) {
    return this.createRPCQuery(`${this.url}`, { method: "dataset.get_annotation_types", params });
  }

  async getDatasetDateRange(params) {
    return this.createRPCQuery(`${this.url}`, { method: "dataset.date_range", params });
  }

  async getDatasetTopics(params) {
    return this.createRPCQuery(`${this.url}`, { method: "dataset.get_topics", params });
  }

  // ----------------------------------------   Dataset Access   --------------------------------------

  async getDatasetAccessList(params = {}) {
    return this.createRPCQuery(`${this.url}`, { method: "datasetaccess.list", params });
  }

  async addDatasetAccess(params = {}) {
    return this.createRPCQuery(`${this.url}`, { method: "datasetaccess.add", params });
  }

  async updateDatasetAccess(params = {}) {
    return this.createRPCQuery(`${this.url}`, { method: "datasetaccess.update", params });
  }

  async deleteDatasetAccess(params = {}) {
    return this.createRPCQuery(`${this.url}`, { method: "datasetaccess.del", params });
  }

  async getDatasetAccessUserList(params = {}) {
    return this.createRPCQuery(`${this.url}`, { method: "datasetaccess.user_list", params });
  }

  async getProjectDatasetAccessUserList(params = {}) {
    return this.createRPCQuery(`${this.url}`, { method: "datasetaccess.project_user_list", params });
  }

  async getDatasetAccessForSingleUser(params = {}) {
    return this.createRPCQuery(`${this.url}`, { method: "datasetaccess.get_for_user", params });
  }

  async revertDatasetAccessToDefault(params = {}) {
    return this.createRPCQuery(`${this.url}`, { method: "datasetaccess.revert_to_default", params });
  }

  async setDatasetAccessToPrivate(params = {}) {
    return this.createRPCQuery(`${this.url}`, { method: "datasetaccess.set_to_private", params });
  }

  // ----------------------------------------   Images   ----------------------------------------------

  async getImage(params = {}, signal = null) {
    return this.createRPCQuery(`${this.url}`, { method: "image.get", params }, signal);
  }

  async getImages(params = {}, signal = null) {
    return this.createRPCQuery(`${this.url}`, { method: "image.list", params }, signal);
  }

  async getImagesCount(params = {}, signal = null) {
    return this.createRPCQuery(`${this.url}`, { method: "image.list", params }, signal);
  }

  async deleteImageFromDataset(params = {}) {
    return this.createRPCQuery(`${this.url}`, { method: "image.delete_from_dataset", params });
  }

  async getImageMetadata(params = {}) {
    return this.createRPCQuery(`${this.url}`, { method: "image.get_metadata", params });
  }

  async getFilteredImages(params = {}, signal = null) {
    return this.createRPCQuery(`${this.url}`, { method: "image.list.filter", params }, signal);
  }

  async deleteFilteredImages(params = {}, signal = null) {
    return this.createRPCQuery(`${this.url}`, { method: "image.delete.filter", params }, signal);
  }

  async copyImages(params = {}, signal = null) {
    return this.createRPCQuery(`${this.url}`, { method: "image.copy_images", params }, signal);
  }

  // ----------------------------------------   Annotations   ----------------------------------------------

  async addAnnotations(params = {}) {
    return this.createRPCQuery(`${this.url}`, { method: "annotation.add", params });
  }

  async getAnnotations(params = {}) {
    return this.createRPCQuery(`${this.url}`, { method: "annotation.list2", params });
  }

  async updateAnnotations(params = {}) {
    return this.createRPCQuery(`${this.url}`, { method: "annotation.update", params });
  }

  async deleteAnnotations(params = {}) {
    return this.createRPCQuery(`${this.url}`, { method: "annotation.del", params });
  }

  async getAnnotationJson(params = {}) {
    return this.createRPCQuery(`${this.url}`, { method: "annotation.data_json", params });
  }

  async deleteAnnotationBulk(params = {}) {
    return this.createRPCQuery(`${this.url}`, { method: "annotation.bulk.del", params });
  }

  async deleteAnnotationsByTrackingId(params = {}) {
    return this.createRPCQuery(`${this.url}`, { method: "annotation.delete_tracking_id", params });
  }

  async getAnnotationFile(annotation_id, raw = false, signal = null) {
    const headers = new Headers();
    headers.append('Authorization', `Bearer ${store.state.user.token}`);
    const requestOptions = {
      method: 'GET',
      redirect: 'follow',
      headers,
    };
    return fetch(`/annotation/${annotation_id}`, requestOptions, signal)
      .then(async (resp) => {
        if (!resp.ok) {
          throw Error(await resp.text());
        }
        if (raw) {
          return resp.blob();
        }
        return resp.text();
      });
  }

  async updateAnnotationFile(annotation_id, file, signal = null) {
    const headers = new Headers();
    headers.append('Authorization', `Bearer ${store.state.user.token}`);
    const requestOptions = {
      method: 'PUT',
      redirect: 'follow',
      headers,
      body: file,
    };
    return fetch(`/annotation/${annotation_id}`, requestOptions, signal)
      .then(async (resp) => {
        if (!resp.ok) {
          throw Error(await resp.text());
        }
        return resp.text();
      });
  }

  // ----------------------------------------   Annotation Sets   ----------------------------------------------

  async createAnnotationSet(params = {}) {
    return this.createRPCQuery(`${this.url}`, { method: "annset.add", params });
  }

  async updateAnnotationSet(params = {}) {
    return this.createRPCQuery(`${this.url}`, { method: "annset.update", params });
  }

  async getAnnotationSets(params = {}, signal = null) {
    return this.createRPCQuery(`${this.url}`, { method: "annset.list", params }, signal);
  }

  async getAnnotationSet(params = {}) {
    return this.createRPCQuery(`${this.url}`, { method: "annset.get", params });
  }

  async copyAnnotationSet(params = {}) {
    return this.createRPCQuery(`${this.url}`, { method: "annset.copy", params });
  }

  async deleteAnnotationSet(params = {}) {
    return this.createRPCQuery(`${this.url}`, { method: "annset.del", params });
  }

  async getAnnotationSetConfusionMatrix(params = {}) {
    return this.createRPCQuery(`${this.url}`, { method: "annset.confusion_matrix", params });
  }

  async getAnnotationsForAnnotationSets(params = {}) {
    return this.createRPCQuery(`${this.url}`, { method: "annset.get_all_annotations", params });
  }

  // ----------------------------------------   Labels   ----------------------------------------------

  async getLabelList(params = {}) {
    return this.createRPCQuery(`${this.url}`, { method: "label.list", params });
  }

  async addLabel(params = {}) {
    return this.createRPCQuery(`${this.url}`, { method: "label.add", params });
  }

  async addLabel2(params = {}) {
    return this.createRPCQuery(`${this.url}`, { method: "label.add2", params });
  }

  async deleteLabel(params = {}) {
    return this.createRPCQuery(`${this.url}`, { method: "label.del", params });
  }

  async updateLabel(params = {}) {
    return this.createRPCQuery(`${this.url}`, { method: "label.update", params });
  }

  // ----------------------------------------   Auto Annotations   ----------------------------------------------

  async getAutoAnnList(params = {}) {
    return this.createRPCQuery(`${this.url}`, { method: "autoann.list", params });
  }

  async getAutoAnnSet(params = {}) {
    return this.createRPCQuery(`${this.url}`, { method: "autoann.get", params });
  }

  async startAutoAnn(params = {}) {
    return this.createRPCQuery(`${this.url}`, { method: "autoann.start", params });
  }

  async deleteAutoAnn(params = {}) {
    return this.createRPCQuery(`${this.url}`, { method: "autoann.del", params });
  }

  async terminateAutoAnn(params = {}) {
    return this.createRPCQuery(`${this.url}`, { method: "autoann.terminate", params });
  }

  // ----------------------------------------   Auto Segmentations   ----------------------------------------------

  async startAutoSegmentation(params = {}) {
    return this.createRPCQuery(`${this.url}`, { method: "autoseg.start", params });
  }

  async getAutoSegList(params = {}) {
    return this.createRPCQuery(`${this.url}`, { method: "autoseg.list", params });
  }

  async getAutoSeg(params = {}) {
    return this.createRPCQuery(`${this.url}`, { method: "autoseg.get", params });
  }

  async removeAutoSeg(params = {}) {
    return this.createRPCQuery(`${this.url}`, { method: "autoseg.del", params });
  }

  // ----------------------------------------   Reviews   ----------------------------------------------

  async addReviews(params = {}) {
    return this.createRPCQuery(`${this.url}`, { method: "review.add", params });
  }

  async getReviewImages(params = {}) {
    return this.createRPCQuery(`${this.url}`, { method: "review.get_images", params });
  }

  async getLabellingImages(params = {}) {
    return this.createRPCQuery(`${this.url}`, { method: "review.get_labelling_images", params });
  }

  async getLabellingSequenceFrames(params = {}, signal = null) {
    return this.createRPCQuery(`${this.url}`, { method: "review.get_labelling_sequence_frames", params }, signal);
  }

  async setImageReviewStatusDone(params = {}) {
    return this.createRPCQuery(`${this.url}`, { method: "imagereviewstatus.update_done", params });
  }

  async deleteImageReviewStatus(params = {}) {
    return this.createRPCQuery(`${this.url}`, { method: "imagereviewstatus.del", params });
  }

  // ----------------------------------------   Review Tasks   ----------------------------------------------

  async addReviewTask(params = {}) {
    return this.createRPCQuery(`${this.url}`, { method: "reviewtask.add", params });
  }

  async deleteReviewTask(params = {}) {
    return this.createRPCQuery(`${this.url}`, { method: "reviewtask.del", params });
  }

  async getReviewTasks(params = {}) {
    return this.createRPCQuery(`${this.url}`, { method: "reviewtask.list", params });
  }

  async getReviewTaskById(params = {}) {
    return this.createRPCQuery(`${this.url}`, { method: "reviewtask.get", params });
  }

  async getReviewerList(params = {}) {
    return this.createRPCQuery(`${this.url}`, { method: "reviewtask.reviewer_list", params });
  }

  async getReviewTaskRemainingCount(params = {}) {
    return this.createRPCQuery(`${this.url}`, { method: "reviewtask.remaining_count", params });
  }

  async getLabelingTaskRemainingCount(params = {}) {
    return this.createRPCQuery(`${this.url}`, { method: "reviewtask.labeling_remaining_count", params });
  }

  async getTaskDoneImagesByUser(params = {}) {
    return this.createRPCQuery(`${this.url}`, { method: "reviewtask.done_images_by_user", params });
  }

  async deleteReviewTaskUser(params = {}) {
    return this.createRPCQuery(`${this.url}`, { method: "reviewtaskuser.del", params });
  }

  async addReviewTaskUser(params = {}) {
    return this.createRPCQuery(`${this.url}`, { method: "reviewtaskuser.add", params });
  }

  async submitAuditManualAnnotationTask(params = {}) {
    return this.createRPCQuery(`${this.url}`, { method: "manualannotationtask.audit.submit", params });
  }

  async submitKeyframes(params = {}) {
    return this.createRPCQuery(`${this.url}`, { method: "manualannotationtask.keyframe.submit", params });
  }

  async getReviewTaskImageList(params = {}) {
    return this.createRPCQuery(`${this.url}`, { method: "reviewtask.image_list", params });
  }

  // ----------------------------------------   Review Task Access   --------------------------------------

  async getReviewTaskAccessList(params = {}) {
    return this.createRPCQuery(`${this.url}`, { method: "reviewaccess.list", params });
  }

  async addReviewTaskAccess(params = {}) {
    return this.createRPCQuery(`${this.url}`, { method: "reviewaccess.add", params });
  }

  async updateReviewTaskAccess(params = {}) {
    return this.createRPCQuery(`${this.url}`, { method: "reviewaccess.update", params });
  }

  async deleteReviewTaskAccess(params = {}) {
    return this.createRPCQuery(`${this.url}`, { method: "reviewaccess.del", params });
  }

  async getReviewTaskAccessForSingleUser(params = {}) {
    return this.createRPCQuery(`${this.url}`, { method: "reviewaccess.get_for_user", params });
  }

  async revertReviewTaskAccessToDefault(params = {}) {
    return this.createRPCQuery(`${this.url}`, { method: "reviewaccess.revert_to_default", params });
  }

  // ----------------------------------------   Review Sessions   ----------------------------------------------

  async addReviewSession(params = {}) {
    return this.createRPCQuery(`${this.url}`, { method: "reviewsession.add", params });
  }

  // ----------------------------------------   S3   ----------------------------------------------

  async s3CreatePresignedUrl(params = {}) {
    return this.createRPCQuery(`${this.url}`, { method: "s3.create_presigned_url", params });
  }

  async s3SingleImageImport(params = {}) {
    return this.createRPCQuery(`${this.url}`, { method: "s3.single_image_import", params });
  }

  // ----------------------------------------   Trainer   ----------------------------------------------

  async addTrainer(params = {}) {
    return this.createRPCQuery(`${this.url}`, { method: "trainer.add", params });
  }

  async updateTrainer(params = {}) {
    return this.createRPCQuery(`${this.url}`, { method: "trainer.update", params });
  }

  async deleteTrainer(params = {}) {
    return this.createRPCQuery(`${this.url}`, { method: "trainer.del", params });
  }

  async getTrainerList2(params = {}) {
    return this.createRPCQuery(`${this.url}`, { method: "trainer.list2", params });
  }

  async getTrainerList(params = {}) {
    return this.createRPCQuery(`${this.url}`, { method: "trainer.list", params });
  }

  async getTrainerData(params = {}) {
    return this.createRPCQuery(`${this.url}`, { method: "trainer.getdata", params });
  }

  async startTrainer(params = {}) {
    return this.createRPCQuery(`${this.url}`, { method: "trainer.server.start", params });
  }

  async stopTrainer(params = {}) {
    return this.createRPCQuery(`${this.url}`, { method: "trainer.server.stop", params });
  }

  async updateEC2Status(params = {}) {
    return this.createRPCQuery(`${this.url}`, { method: "trainer.server.update_ec2", params });
  }

  async getTrainerStatus(params = {}) {
    return this.createRPCQuery(`${this.url}`, { method: "trainer.status", params });
  }

  async terminateTrainingSessions(params = {}) {
    return this.createRPCQuery(`${this.url}`, { method: "trainer.session.terminate", params });
  }

  async deleteTrainingSession(params = {}) {
    return this.createRPCQuery(`${this.url}`, { method: "trainer.session.delete", params });
  }

  async getTrainingSession(params = {}) {
    return this.createRPCQuery(`${this.url}`, { method: "trainer.session.get", params });
  }

  async getTrainingSessionList(params = {}) {
    return this.createRPCQuery(`${this.url}`, { method: "trainer.session.list", params });
  }

  async getTrainingSessionLogs(params = {}) {
    return this.createRPCQuery(`${this.url}`, { method: "trainer.session.logs", params });
  }

  async generateTwoStageModel(params = {}) {
    return this.createRPCQuery(`${this.url}`, { method: "trainer.server.generate_two_stage", params });
  }

  // ----------------------------------------   Authentication   ----------------------------------------------

  async signIn(params = {}) {
    return this.createRPCQuery(`${this.url}`, { method: "auth.login", params });
  }

  async refreshToken(params = {}) {
    return this.createRPCQuery(`${this.url}`, { method: "auth.refresh", params });
  }

  async send2FA(params = {}) {
    return this.createRPCQuery(`${this.url}`, { method: "auth.send2fa", params });
  }

  async generateBridgeToken(params = {}) {
    return this.createRPCQuery(`${this.url}`, { method: "auth.generate_bridge_token", params });
  }

  // ----------------------------------------   User   ----------------------------------------------

  async createUser(params = {}) {
    return this.createRPCQuery(`${this.url}`, { method: "user.add", params });
  }

  async updateUser(params = {}) {
    return this.createRPCQuery(`${this.url}`, { method: "user.update", params });
  }

  async updateUserPassword(params = {}) {
    return this.createRPCQuery(`${this.url}`, { method: "user.update_password", params });
  }

  async updateUserPasswordAuth(params = {}) {
    return this.createRPCQuery(`${this.url}`, { method: "user.update_password_with_auth", params });
  }

  async getAllRoles(params = {}) {
    return this.createRPCQuery(`${this.url}`, { method: "user.get_all_roles", params });
  }

  async getUserList(params = {}) {
    return this.createRPCQuery(`${this.url}`, { method: "user.list", params });
  }

  async getUserListByParent(params = {}) {
    return this.createRPCQuery(`${this.url}`, { method: "user.list_by_parent", params });
  }

  async getUserListByOrganization(params = {}) {
    return this.createRPCQuery(`${this.url}`, { method: "user.list_by_org", params });
  }

  async deleteUser(params = {}) {
    return this.createRPCQuery(`${this.url}`, { method: "user.del", params });
  }

  async checkForUser(params = {}) {
    return this.createRPCQuery(`${this.url}`, { method: "user.check_for_user", params });
  }

  async getUserBucket(params = {}) {
    return this.createRPCQuery(`${this.url}`, { method: "user.get_bucket", params });
  }

  async registerNewUser(params = {}) {
    return this.createRPCQuery(`${this.url}`, { method: "user.register", params });
  }

  // ----------------------------------------   Organization   ----------------------------------------------

  async createOrg(params = {}) {
    return this.createRPCQuery(`${this.url}`, { method: "org.add", params });
  }

  async getOrgList(params = {}) {
    return this.createRPCQuery(`${this.url}`, { method: "org.list", params });
  }

  async getOrg(params = {}) {
    return this.createRPCQuery(`${this.url}`, { method: "org.get", params });
  }

  async updateOrg(params = {}) {
    return this.createRPCQuery(`${this.url}`, { method: "org.update", params });
  }

  async getOrgForUser(params = {}) {
    return this.createRPCQuery(`${this.url}`, { method: "org.get_for_user", params });
  }

  async updateBucket(params = {}) {
    return this.createRPCQuery(`${this.url}`, { method: "org.update_bucket", params });
  }

  async getOrgDataField(params = {}) {
    return this.createRPCQuery(`${this.url}`, { method: "org.get_data_field", params });
  }

  async setOrgDataField(params = {}) {
    return this.createRPCQuery(`${this.url}`, { method: "org.set_data_field", params });
  }

  async listWarnings(params = {}) {
    return this.createRPCQuery(`${this.url}`, { method: "org.list_warnings", params });
  }

  // ----------------------------------------   Import   ----------------------------------------------

  async getSupportedImportTypes(params = {}) {
    return this.createRPCQuery(`${this.url}`, { method: "import.types", params });
  }

  // ----------------------------------------   Export   ----------------------------------------------

  async startExport(params = {}) {
    return this.createRPCQuery(`${this.url}`, { method: "export.start", params });
  }

  async getExportList(params = {}) {
    return this.createRPCQuery(`${this.url}`, { method: "export.list", params });
  }

  async getExportStatus(params = {}) {
    return this.createRPCQuery(`${this.url}`, { method: "export.status", params });
  }

  async deleteExport(params = {}) {
    return this.createRPCQuery(`${this.url}`, { method: "export.delete", params });
  }

  // ----------------------------------------   Groups   ----------------------------------------------

  async getGroupListForDataset(params = {}) {
    return this.createRPCQuery(`${this.url}`, { method: "groups.list", params });
  }

  async createGroupsForDataset(params = {}) {
    return this.createRPCQuery(`${this.url}`, { method: "groups.create", params });
  }

  async updateGroup(params = {}) {
    return this.createRPCQuery(`${this.url}`, { method: "groups.update", params });
  }

  async deleteGroup(params = {}) {
    return this.createRPCQuery(`${this.url}`, { method: "groups.delete", params });
  }

  // -----------------------------------   Training Sets   ----------------------------------------------

  async getTrainingSetList(params = {}) {
    return this.createRPCQuery(`${this.url}`, { method: "trainset.list", params });
  }

  async getTrainingSet(params = {}) {
    return this.createRPCQuery(`${this.url}`, { method: "trainset.get", params });
  }

  async addTrainingSet(params = {}) {
    return this.createRPCQuery(`${this.url}`, { method: "trainset.add", params });
  }

  async deleteTrainingSet(params = {}) {
    return this.createRPCQuery(`${this.url}`, { method: "trainset.delete", params });
  }

  async updateTrainingSet(params = {}) {
    return this.createRPCQuery(`${this.url}`, { method: "trainset.update", params });
  }

  async getTrainingSetConversionList(params = {}) {
    return this.createRPCQuery(`${this.url}`, { method: "trainset.conv.list", params });
  }

  async addTrainingSetConversion(params = {}) {
    return this.createRPCQuery(`${this.url}`, { method: "trainset.conv.add", params });
  }

  async deleteTrainingSetConversion(params = {}) {
    return this.createRPCQuery(`${this.url}`, { method: "trainset.conv.del", params });
  }

  async updateTrainingSetConversion(params = {}) {
    return this.createRPCQuery(`${this.url}`, { method: "trainset.conv.update", params });
  }

  async getTrainingSetCacheList(params = {}) {
    return this.createRPCQuery(`${this.url}`, { method: "trainset.cache.list", params });
  }

  async addTrainingSetCache(params = {}) {
    return this.createRPCQuery(`${this.url}`, { method: "trainset.cache.add", params });
  }

  async deleteTrainingSetCache(params = {}) {
    return this.createRPCQuery(`${this.url}`, { method: "trainset.cache.del", params });
  }

  async updateTrainingSetCache(params = {}) {
    return this.createRPCQuery(`${this.url}`, { method: "trainset.cache.update", params });
  }

  async getDockerTasksDashboard(params = {}) {
    return this.createRPCQuery(`${this.url}`, { method: "docker.list.dashboard", params });
  }

  async getDockerTaskById(params = {}) {
    return this.createRPCQuery(`${this.url}`, { method: "docker.get", params });
  }

  async addDockerTask(params = {}) {
    return this.createRPCQuery(`${this.url}`, { method: "docker.add", params });
  }

  async deleteDockerTask(params = {}) {
    return this.createRPCQuery(`${this.url}`, { method: "docker.delete", params });
  }

  async updateDockerTask(params = {}) {
    return this.createRPCQuery(`${this.url}`, { method: "docker.update", params });
  }

  async updateDockerTaskStatus(params = {}) {
    return this.createRPCQuery(`${this.url}`, { method: "docker.update.status", params });
  }

  // ----------------------------------------   Graph   ----------------------------------------------

  async startGraph(params = {}) {
    return this.createRPCQuery(`${this.url}`, { method: "graph.start", params });
  }

  async stopGraph(params = {}) {
    return this.createRPCQuery(`${this.url}`, { method: "graph.stop", params });
  }

  // ----------------------------------------   Validator   ----------------------------------------------

  async validate(params = {}) {
    return this.createRPCQuery(`${this.url}`, { method: "validate.start", params });
  }

  async getValidateSessionList(params = {}) {
    return this.createRPCQuery(`${this.url}`, { method: "validate.session.list", params });
  }

  async getValidateSession(params = {}) {
    return this.createRPCQuery(`${this.url}`, { method: "validate.session.get", params });
  }

  async terminateValidateSessions(params = {}) {
    return this.createRPCQuery(`${this.url}`, { method: "validate.session.terminate", params });
  }

  async deleteValidateSessions(params = {}) {
    return this.createRPCQuery(`${this.url}`, { method: "validate.session.delete", params });
  }

  async validateUpdateEC2(params = {}) {
    return this.createRPCQuery(`${this.url}`, { method: "validate.session.update_ec2", params });
  }

  // ----------------------------------------   Video Import   -----------------------------------------

  async videoImport(params = {}) {
    return this.createRPCQuery(`${this.url}`, { method: "video.import", params });
  }

  // ----------------------------------------   Upload   -----------------------------------------

  async addUploadData(params = {}) {
    return this.createRPCQuery(`${this.url}`, { method: "upload.image.add", params });
  }

  async uploadAnnotations(params = {}) {
    return this.createRPCQuery(`${this.url}`, { method: "upload.annotation", params });
  }

  async completeUpload(params = {}) {
    return this.createRPCQuery(`${this.url}`, { method: "upload.complete", params });
  }

  async uploadVideo(formData = null) {
    return this.createRPCQuery(`${this.url}`, { method: "upload.video", formData });
  }

  // ----------------------------------------   Stats   -----------------------------------------

  async statsCompare(params = {}, signal = null) {
    return this.createRPCQuery(`${this.url}`, { method: "stats.compare", params }, signal);
  }

  // ----------------------------------------   Version   ----------------------------------------------

  async getAppVersion() {
    return this.createRPCQuery(`${this.url}`, { method: "version" });
  }

  // ----------------------------------------   Organization   ----------------------------------------------

  async getConverterOptions(params = {}) {
    return this.createRPCQuery(`${this.url}`, { method: "convert.list", params });
  }

  async convertModel(params = {}) {
    return this.createRPCQuery(`${this.url}`, { method: "convert.start", params });
  }

  async convertorStatus(params = {}) {
    return this.createRPCQuery(`${this.url}`, { method: "convert.status", params });
  }

  async convertorFindAllFiles(params = {}) {
    return this.createRPCQuery(`${this.url}`, { method: "convert.allfiles", params });
  }

  async convertorGetDockerTasks(params = {}) {
    return this.createRPCQuery(`${this.url}`, { method: "convert.dockerlist", params });
  }

  // ----------------------------------------   Recycle   ----------------------------------------------

  async getListOfRecycledItems(params = {}, signal = null) {
    return this.createRPCQuery(`${this.url}`, { method: "recycle.list", params }, signal);
  }

  async undeleteRecycledItem(params = {}) {
    return this.createRPCQuery(`${this.url}`, { method: "recycle.undel", params });
  }

  async purgeRecycledItem(params = {}) {
    return this.createRPCQuery(`${this.url}`, { method: "recycle.purge", params });
  }

  // ----------------------------------------   Permission   ----------------------------------------------

  async getUserPermissions(params = {}, signal = null) {
    return this.createRPCQuery(`${this.url}`, { method: "permission.get_for_user", params }, signal);
  }

  // ----------------------------------------   Sequences   ----------------------------------------------

  async getSequences(params = {}, signal = null) {
    return this.createRPCQuery(`${this.url}`, { method: "sequences.list", params }, signal);
  }

  async purgeSequence(params = {}, signal = null) {
    return this.createRPCQuery(`${this.url}`, { method: "sequences.purge", params }, signal);
  }
}

export default DatastoreConnect;
