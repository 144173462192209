<template>
  <div class="stats-graph">
    <div class="header">
      <span class="title">{{ name }}</span>
      <v-btn-toggle
        v-model="mode"
        color="black"
        mandatory
        variant="outlined"
        class="toggle-btn"
      >
        <v-btn icon="mdi-chart-pie" value="pie" />
        <v-btn icon="mdi-chart-bar" value="bar" />
      </v-btn-toggle>
    </div>
    <div class="chart">
      <apexchart
        v-if="mode === 'pie'"
        ref="pieChartCount"
        height="100%"
        type="pie"
        :options="chartPieOptions"
        :series="series"
      />
      <apexchart
        v-if="mode === 'bar'"
        ref="barChartCount"
        height="100%"
        type="bar"
        :options="chartBarOptions"
        :series="series"
      />
    </div>
  </div>
</template>

<script>

import VueApexCharts from "vue3-apexcharts";

export default {
  name: 'LabelsPieChart',
  components: {
    'apexchart': VueApexCharts,
  },
  props: {
    name: {
      type: String,
      default: '',
    },
    dataArray: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      chartPieOptions: {
        chart: {
          toolbar: {
            show: false,
          },
        },
        dataLabels: {
          enabled: true,
          formatter: (val, { seriesIndex, w }) => w.config.series[seriesIndex],
          style: {
            colors: ['black'],
          },
        },
        legend: {
          show: true,
          showForSingleSeries: true,
          position: 'right',
        },
        tooltip: {
          fillSeriesColor: false,
        },
      },
      chartBarOptions: {
        plotOptions: {
          bar: {
            columnWidth: '45%',
            distributed: true,
          },
        },
        chart: {
          toolbar: {
            show: false,
          },
        },
        xaxis: {
          tickPlacement: 'between',
        },
        dataLabels: {
          enabled: false,
        },
        tooltip: {
          fillSeriesColor: false,
        },
        legend: {
          show: false,
        },
      },
      series: [],
      componentIsActive: false,
      mode: 'pie',
    };
  },
  watch: {
    dataArray: {
      deep: true,
      handler() {
        if (this.dataArray) {
          this.update();
        }
      },
    },
    mode: {
      handler() {
        if (this.dataArray) {
          this.update();
        }
      },
    },
  },
  mounted() {
    if (this.dataArray) {
      this.update();
    }
    this.$nextTick(() => {
      window.dispatchEvent(new Event('resize'));
    });
  },
  methods: {
    update() {
      if (this.mode === 'pie') {
        this.updatePie();
      } else {
        this.updateBar();
      }
    },
    updatePie() {
      const series = [];
      // This requires the first key-value pair of entry to be the label, the second to be the value at that label
      this.dataArray.counts.forEach((entry, index) => {
        const singleSeries = {
          name: '',
          data: [],
        };
        const element = series.find((e) => e?.name === entry.name);
        if (element) {
          series[series.indexOf(element)].data.push(entry.count);
        } else {
          singleSeries.name = entry.name;
          singleSeries.data.push(entry.count);
          series.push(singleSeries);
        }
      });

      const pieSeries = {
        series: [],
        labels: [],
      };
      series.forEach((s) => {
        const sum = s.data.reduce((partialSum, a) => partialSum + a, 0);
        pieSeries.series.push(sum);
        pieSeries.labels.push(s.name);
      });
      this.updateChart(pieSeries);
    },
    updateBar() {
      const data = {
        series: [{
          data: [],
        }],
        labels: [],
      };
      // This requires the first key-value pair of entry to be the label, the second to be the value at that label
      this.dataArray.counts.forEach((datum, index) => {
        data.series[0].data.push(datum.count);
        data.labels.push(datum.name);
      });
      this.updateChart(data);
    },
    // update value of each column
    updateChart(data) {
      this.series = data.series;
      if (this.mode === 'pie') {
        this.chartPieOptions = {
          ...this.chartPieOptions,
          ...{
            labels: data.labels,
            colors: this.getColors(),
          },
        };
      } else if (this.mode === 'bar') {
        this.chartBarOptions = {
          ...this.chartBarOptions,
          ...{
            xaxis: {
              categories: data.labels,
            },
            colors: this.getColors(),
          },
        };
      }
    },
    getColors() {
      const colors = {};
      this.dataArray.counts.forEach((label) => {
        if (label.label_index < 12) {
          colors[label.name] = this.getColorByIndex(label.label_index);
        } else {
          colors[label.name] = this.getColorByIndexFromScale(label.label_index);
        }
      });
      return Object.values(colors);
    },
    getColorByIndexFromScale(index) {
      const hue = index * 137.508 + 60; // use golden angle approximation
      return `hsl(${hue},100%,50%)`;
    },
    getColorByIndex(index) {
      const colors = ['#ffff00', '#00ff00', '#ffa500', '#ff0000', '#48d1cc', '#a0522d', '#ffc0cb', '#ff1493', '#f0e68c', '#1e90ff', '#0000ff', '#00fa9a'];
      return colors[index % 12];
    },
  },
};

</script>

<style scoped lang="scss">
.stats-graph {
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  width: 100%;
  height: calc(100% - 20px);
  box-shadow: 0 0 10px rgba(0,0,0,0.1), 0 0 5px rgba(0,0,0,0.25);
  padding-top: 10px;
  padding-bottom: 10px;
  @include themify() {
    background: themed('card-color');
    color: themed('datasets-stats-text-color');
  }
}

.header {
  display: flex;
  align-items: center;
  margin-left: 15px;
  margin-right: 15px;

  .title {
    font-size: 1.3rem;
    font-weight: 700;
    min-width: 200px;
  }

  .toggle-btn {
    width: fit-content;
    margin-left: auto;
    height: fit-content;

    button {
      width: 50px !important;
    }
  }
}

.chart {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  width: 100%;
}
</style>
