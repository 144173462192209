<template>
  <label
    ref="switch"
    class="switch"
    :class="`_${theme}`"
    :disabled="disabled"
  >
    <input
      :id="uid"
      :checked="active"
      class="switch__input"
      type="checkbox"
      :disabled="disabled"
      @change="handleSwitchToggle"
    >
    <span class="switch__slider _round" />
  </label>
</template>

<script>
let uid = 0;

export default {
  name: "BaseSwitch",
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    active: {
      type: Boolean,
      default: false,
    },
    theme: {
      type: String,
      default: 'primary',
    },
    fontSize: {
      type: String,
      default: '14px',
    },
  },
  emits: ['change', 'update:active'],
  data() {
    uid += 1;
    return {
      uid: `switch-${uid}`,
    };
  },
  methods: {
    handleSwitchToggle(e) {
      this.$emit('change');
      this.$emit('update:active', e.target.checked);
    },
  },
};
</script>

<style lang="scss" scoped>

/* The switch - the box around the slider */
.switch {
  --switchWidth: 44px;
  --switchHeight: 22px;
  position: relative;
  display: inline-block;
  width: var(--switchWidth);
  height: var(--switchHeight);
  margin: 1px;

  &__input {
    width: 100%;
    height: 100%;
    margin: 0;

    &:focus-visible {
      outline: 1px dotted rgba(0,0,0,0.5);
    }
  }

  /* Toggle functionality */
  &__input:checked + .switch__slider {
    @include themify() {
      background-color: themed('primary-switch-active-background');
    }
    &:before {
      -webkit-transform: translateX(calc(var(--switchWidth) - var(--switch-handle-size) - var(--switch-handle-margin) * 2));
      -ms-transform: translateX(calc(var(--switchWidth) - var(--switch-handle-size) - var(--switch-handle-margin) * 2));
      transform: translateX(calc(var(--switchWidth) - var(--switch-handle-size) - var(--switch-handle-margin) * 2));
    }
  }

  /* Disabled slider style */
  &[disabled="true"] {
    opacity: 0.25;
    & span {
      background-color: grey;
      cursor: not-allowed;
    }

    & .switch__slider._round:before {
      background-color: #CCCCCC;
    }
  }

  /* Switch slider style */
  &__slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;

    &:before {
      --switch-handle-margin: 3px;
      --switch-handle-size: calc(var(--switchHeight) - 6px);
      position: absolute;
      content: "";
      height: var(--switch-handle-size);
      width: var(--switch-handle-size);
      left: var(--switch-handle-margin);
      bottom: var(--switch-handle-margin);
      background-color: white;
      -webkit-transition: .4s;
      transition: .4s;
    }

    /* Rounded sliders */
    &._round {
      border-radius: var(--switchHeight);

      &:before {
        border-radius: 50%;
      }
    }
  }
}

// Alternative themes
.switch._accent .switch__input:checked + .switch__slider {
  @include themify() {
    background-color: themed('accent-switch-active-background');
  }
}

</style>
