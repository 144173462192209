<template>
  <FilterPopper>
    <template #default="{ menuOpen }">
      <BaseFilterButton
        :name="'Review Status'"
        :active="isActive"
        :tagValue="tagValue"
        :menuOpen="menuOpen"
      />
    </template>
    <template #popper>
      <div class="filter-settings scrollbar">
        <div v-for="(reviewStatus, i) in reviewStatuses" :key="i" class="filter-settings__row">
          <input
            :id="`imageReviewStatusFilter-reviewStatus-${i}`"
            v-model="selectedReviewStatuses"
            type="checkbox"
            :name="`${reviewStatus.key}-${i}`"
            :value="reviewStatus.key"
          >
          <label :for="`reviewStatus-${i}`">{{ reviewStatus.name }}</label><br>
        </div>
      </div>
      <div class="filter-footer">
        <button :disabled="!isActive" class="button-text clear-button" @click="clearSelection">Clear Selection</button>
      </div>
    </template>
  </FilterPopper>
</template>

<script>
import FilterPopper from '@/components/FilterPopper.vue';
import BaseFilterButton from '@/components/BaseFilterButton.vue';

export default {
  name: "ImageReviewStatusFilter",
  components: {
    FilterPopper,
    BaseFilterButton,
  },
  props: {
    reviewStatuses: {
      type: Array,
      default: () => [
        { name: 'Unreviewed', key: 'unreviewed' },
        { name: 'Reviewed', key: 'reviewed' },
        { name: 'Verified', key: 'verified' },
        { name: 'Flagged', key: 'flagged' },
      ],
    },
    modelValue: {
      type: Array,
      default: () => [],
    },
  },
  emits: [
    'update:modelValue',
  ],
  data() {
    return {
      selectedReviewStatuses: [],
    };
  },
  computed: {
    isActive() {
      return this.selectedReviewStatuses.length > 0;
    },
    tagValue() {
      return this.selectedReviewStatuses.length.toString();
    },
  },
  watch: {
    modelValue(newVal) {
      this.selectedReviewStatuses = newVal;
    },
    selectedReviewStatuses() {
      this.updateFilters();
    },
  },
  methods: {
    updateFilters() {
      this.$emit('update:modelValue', this.selectedReviewStatuses);
    },
    clearSelection() {
      this.selectedReviewStatuses = [];
    },
  },
};
</script>

<style lang="scss" scoped>
.filter-settings {
  display: flex;
  flex-direction: column;
  padding: 8px;
  border-radius: inherit;
  gap: 6px;
  min-width: 200px;
  overflow: auto;

  &__row {
    display: flex;
    align-items: center;
  }

  & input[type=checkbox] {
    margin-right: 12px;
  }
}

.filter-footer {
  min-height: 38px;
  width: 100%;
  border-top: 1px solid rgba(140,140,140,0.375);
  padding: 8px;
  text-align: center;

  .clear-button {
    @include themify() {
      color: themed('color-danger');
    }

    &[disabled] {
      @include themify() {
        color: themed('color-disabled');
      }
    }
  }
}
</style>
