<template>
  <v-transformer
    ref="transformer"
    :rotateEnabled="rotateEnabled"
    :resizeEnabled="resizeEnabled"
    :config="{
      anchorDragBoundFunc: anchorDragBoundFunc
    }"
  />
</template>

<script>
export default {
  name: 'KonvaTransformer',
  props: {
    rotateEnabled: {
      type: Boolean,
      default: false,
    },
    resizeEnabled: {
      type: Boolean,
      default: false,
    },
    stageScale: {
      type: Object,
      default: () => ({ x: 1, y: 1 }),
    },
    imageNode: {
      type: Object,
      default: null,
    },
  },
  computed: {
    anchorDragBoundFunc() {
      const anchorDragBoundFunc = (oldAbsPos, newAbsPos, event) => {
        const imagePos = this.imageNode.absolutePosition();
        const imageAttr = this.imageNode.getAttrs();
        // Get drag bounds with image node
        const minX = imagePos.x;
        const maxX = imagePos.x + (imageAttr.width * this.stageScale.x);
        const minY = imagePos.y;
        const maxY = imagePos.y + (imageAttr.height * this.stageScale.y);
        // Override box position
        const x = Math.max(minX, Math.min(newAbsPos.x, maxX));
        const y = Math.max(minY, Math.min(newAbsPos.y, maxY));
        return {
          x,
          y,
        };
      };
      return anchorDragBoundFunc;
    },
  },
};
</script>

<style>

</style>
