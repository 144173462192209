<template>
  <v-image
    ref="image"
    :config="configImg"
    @dragend="$emit('dragend')"
    @pointermove="$emit('pointermove', $event)"
    @pointerout="updateImagePointerLeave"
    @pointerclick="$emit('pointerclick', $event)"
  />
  <KonvaTransformer
    ref="transformer"
    :nodes="isSelected && node ? [node] : []"
    :rotateEnabled="true"
  />

  <v-line v-if="showPointerAxes" :config="configAxisX" />
  <v-line v-if="showPointerAxes" :config="configAxisY" />
</template>

<script>
import { roundNumber } from '@/assets/js/utils.js';
import { storeToRefs } from 'pinia';
import { useViewerVisualizationsStore } from '@/stores/useViewerVisualizationsStore.js';
import KonvaTransformer from './KonvaTransformer.vue';

export default {
  name: 'KonvaImage',
  components: {
    KonvaTransformer,
  },
  props: {
    imagePath: {
      type: String,
      default: '',
    },
    imageId: {
      type: Number,
      default: null,
    },
    isSelected: {
      type: Boolean,
      default: false,
    },
    opacity: {
      type: Number,
      default: 1,
    },
    stageReady: {
      type: Boolean,
      default: false,
    },
    useImageCacheOnly: {
      type: Boolean,
      default: false,
    },
    imageCacheElem: {
      type: HTMLElement,
      default: null,
    },
    showPointerAxes: {
      type: Boolean,
      default: false,
    },
  },
  emits: [
    'new-coordinates', 'shape-selected', 'image-loaded', 'dragend', 'pointermove', 'pointerclick',
  ],
  setup() {
    const {
      mouse,
    } = storeToRefs(useViewerVisualizationsStore());

    return {
      mouse,
    };
  },
  data() {
    return {
      node: null,
      image: null,
      width: 0,
      height: 0,
      configAxisX: {
        stroke: 'red',
        strokeWidth: 1,
        points: [],
      },
      configAxisY: {
        stroke: 'red',
        strokeWidth: 1,
        points: [],
      },
    };
  },
  computed: {
    configImg() {
      return {
        type: 'map',
        fill: 'white',
        x: 0,
        y: 0,
        image: this.imageCacheElem ? this.imageCacheElem : this.image,
        width: this.width,
        height: this.height,
        draggable: false,
        opacity: this.opacity,
      };
    },
  },
  watch: {
    imagePath: {
      immediate: true,
      handler(path) {
        if (path && this.imageId && !this.useImageCacheOnly && !this.imageCacheElem) {
          this.loadImage(path, this.imageId);
        }
      },
    },
    imageCacheElem: {
      immediate: true,
      handler() {
        if (this.imageCacheElem) {
          this.width = this.imageCacheElem.naturalWidth;
          this.height = this.imageCacheElem.naturalHeight;
          this.$emit('image-loaded', {
            width: this.width, height: this.height, url: this.imageCacheElem.src, imageId: this.imageId,
          });
        }
      },
    },
    mouse: {
      deep: true,
      immediate: true,
      handler() {
        const x = this.mouse.offsetX * this.width;
        const y = this.mouse.offsetY * this.height;
        this.configAxisX.points = [x, 0, x, this.height];
        this.configAxisY.points = [0, y, this.width, y];
      },
    },
  },
  mounted() {
    this.node = this.$refs.image.getNode();
  },
  methods: {
    loadImage(path, imageId) {
      // Create a new request
      fetch(path, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${this.$store.state.user.token}`,
        },
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error(`Error loading image`);
          }
          return response.blob();
        })
        .then((blob) => {
          const image = document.createElement('img');
          image.onerror = (e) => { console.log(e); };
          image.onload = () => {
            this.width = image.naturalWidth;
            this.height = image.naturalHeight;
            this.image = image;
            this.$emit('image-loaded', {
              width: this.width, height: this.height, url: image.src, imageId,
            });
            URL.revokeObjectURL(image.src);
          };
          image.src = URL.createObjectURL(blob);
        })
        .catch((e) => {
          console.log(e);
        });
    },
    handleImagePointerMove() {
      const pointerPos = this.node.getRelativePointerPosition();
      if (!pointerPos) {
        return;
      }
      if (pointerPos.x < 0 || pointerPos.x > this.width || pointerPos.y < 0 || pointerPos.y > this.height) {
        this.updateImagePointerLeave();
        return;
      }
      const ptrX = parseInt(pointerPos.x);
      const ptrY = parseInt(pointerPos.y);
      const calcX = ptrX / this.width;
      const calcY = Math.abs((ptrY - this.height) / this.height);

      this.mouse = {
        offsetX: roundNumber(calcX, 3),
        offsetY: 1.0 - roundNumber(calcY, 3),
      };
    },
    updateImagePointerLeave() {
      this.configAxisX.points = [];
      this.configAxisY.points = [];

      this.mouse = {
        offsetX: null,
        offsetY: null,
      };
    },
    getImageNode() {
      return this.$refs.image.getNode();
    },
  },
};
</script>

<style>
</style>
