<template>
  <FilterPopper :appendToBody="true">
    <template #default="{ menuOpen }">
      <BaseFilterButton
        :name="name"
        :active="isActive"
        :tagValue="tagValue"
        :menuOpen="menuOpen"
      />
    </template>
    <template #popper>
      <div
        v-if="annotationSets && annotationSets.length > 0"
        ref="checkboxContainer"
        class="filter-settings scrollbar"
      >
        <div v-for="(task, i) in annotationSets" :key="i" class="filter-settings__row">
          <input
            :id="`annset-${id}-${i}`"
            v-model="selectedSet"
            type="radio"
            :name="`${task.name}-${i}`"
            :value="task"
          >
          <label :for="`annset-${id}-${i}`">{{ task.name }}</label><br>
        </div>
      </div>
      <div v-else class="filter-settings">
        <span>Please define an annotation set.</span>
      </div>
    </template>
  </FilterPopper>
</template>

<script>
import FilterPopper from '@/components/FilterPopper.vue';
import BaseFilterButton from '@/components/BaseFilterButton.vue';

export default {
  name: "AnnotationSetsRadioSelect",
  components: {
    FilterPopper,
    BaseFilterButton,
  },
  props: {
    name: {
      type: String,
      default: () => 'Annotation Set',
    },
    error: {
      type: Boolean,
      default: false,
    },
    annotationSets: {
      type: Array,
      default: () => [],
    },
    modelValue: {
      type: Object,
      default: () => {},
    },
    id: {
      type: String,
      default: '',
    },
    restore: {
      type: Object,
      default: () => {},
    },
  },
  emits: [
    'update:modelValue',
  ],
  data() {
    return {
      selectedSet: {},
    };
  },
  computed: {
    isActive() {
      if (this.selectedSet) {
        return Object.keys(this.selectedSet).length > 0;
      }
      return false;
    },
    tagValue() {
      if (this.selectedSet) {
        return this.selectedSet?.name;
      }
      return '';
    },
  },
  watch: {
    selectedSet() {
      this.updateFilters();
    },
    annotationSets() {
      if ((this.annotationSets && this.annotationSets.length === 0) || (this.annotationSets && this.selectedSet && !this.annotationSets.find((e) => e.id === this.selectedSet.id))) {
        this.selectedSet = {};
      }
    },
    restore: {
      handler(r) {
        if (this.restore) {
          this.selectedSet = this.restore;
        }
      },
    },
  },
  mounted() {
    if (this.selectedSet !== this.modelValue) {
      this.selectedSet = this.modelValue;
    }
    if ((this.annotationSets && this.annotationSets.length === 0) || (this.annotationSets && this.selectedSet && !this.annotationSets.find((e) => e.id === this.selectedSet.id))) {
      this.selectedSet = {};
    }
  },
  methods: {
    updateFilters() {
      this.$emit('update:modelValue', this.selectedSet);
    },
  },
};
</script>

<style lang="scss" scoped>

.filter-tag {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 6px;
  height: 18px;
  @include themify() {
    background: themed('color-accent');
  }
}

.filter-settings {
  display: flex;
  flex-direction: column;
  padding: 8px;
  gap: 6px;
  overflow: auto;
  width: fit-content;
  min-width: 140px;
  text-wrap: nowrap;

  &__row {
    display: flex;
    align-items: center;

    label {
      margin-left: 5px;
    }
  }

  & input[type=checkbox] {
    margin-right: 12px;
  }

}
</style>
