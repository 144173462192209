<template>
  <div class="app-page">
    <Header ref="header">
      <template #header-slot>
        <BreadcrumbNavigation />
      </template>
      <template #page-slot>
        <BreadcrumbSelect
          id="project-select"
          v-model="currentProject"
          :options="projectList"
          :placeholder="'Project'"
          :displayTag="'name'"
        />
      </template>
    </Header>
    <div class="main-page-div">
      <main class="main-display-area">
        <router-view />
      </main>
    </div>
  </div>
</template>

<script>
import Header from '@/components/Header.vue';
import BreadcrumbSelect from '@/components/BreadcrumbSelect.vue';
import DatastoreConnect from '@/assets/js/DatastoreFunctions/datastore-interface';
import BreadcrumbNavigation from '@/components/BreadcrumbNavigation.vue';

export default {
  name: 'Validator',
  components: {
    Header,
    BreadcrumbSelect,
    BreadcrumbNavigation,
  },
  data() {
    return {
      dataConnect: new DatastoreConnect(this.$store.state.enterpriseServerUrl),
    };
  },
  computed: {
    projectList() {
      return this.$store.state.projects.projectList;
    },
    currentProject: {
      get() {
        return this.$store.state.projects.currentProject;
      },
      set(value) {
        this.$store.commit('projects/setCurrentProject', value);
      },
    },
  },
};
</script>

<style lang="scss" scoped>

.main {
  height: 100vh;
}
</style>
