<template>
  <div class="settings scrollbar">
    <span class="header">User Management</span>
    <span v-if="isAdmin || isSuper" class="user-table__header">Manage users for your organization.</span>
    <button v-if="isAdmin || isSuper" class="button button-rounded create-button mt-3" @click="navigateToCreateUser">Create New User</button>
    <div v-if="users && users.length > 0" class="user-table">
      <div class="user-table__table">
        <div class="row header-row">
          <div
            v-for="(h, i) in headers"
            :key="`header-cell-${i}`"
            class="header-cell"
            :class="{ 'col-3' : i <= 2, 'col-sm' : i > 2 }"
          >
            {{ h }}
          </div>
          <div class="button-padding" />
        </div>
        <div
          v-for="(u, j) in users"
          :key="`user-row-${j}`"
          class="row mt-3 li-row"
        >
          <div class="col-3 user-table__username">
            <span>{{ u['username'] }}</span>
          </div>
          <div class="col-3 user-table__access">
            {{ u['email'] }}
          </div>
          <div class="col-3 user-table__access">
            {{ u['role'] ? u['role'].charAt(0).toUpperCase() + u['role'].slice(1) : '' }}
          </div>
          <div class="button-padding">
            <IconButton
              :class="{ invisible: (!isAdmin && !isSuper) || u.role === 'super' }"
              class="edit-btn icon"
              :icon="'edit'"
              :width="22"
              :height="22"
              @click="navigateToEditUser(u)"
            />
            <IconButton
              :class="{ invisible: (!isAdmin && !isSuper) || u.username === user.username || u.role === 'super' }"
              class="close-btn icon"
              :icon="'close'"
              :width="22"
              :height="22"
              @click="openConfirmDeleteUserModal(u)"
            />
          </div>
        </div>
        <div class="row footer-row" />
      </div>
    </div>
  </div>
  <ConfirmModal
    ref="confirmModal"
    :messageHeader="confirmMessageHeader"
    :message="confirmMessage"
    :buttonClass="'button-delete'"
    :buttonText="'Delete'"
    @confirmed="removeUser"
  />
</template>

<script>
import DatastoreConnect from '@/assets/js/DatastoreFunctions/datastore-interface';
import IconButton from '@/components/IconButton.vue';
import ConfirmModal from '@/components/ConfirmModal.vue';

export default {
  name: 'UserManagement',
  components: {
    IconButton,
    ConfirmModal,
  },
  data() {
    return {
      dsConn: new DatastoreConnect(this.$store.state.enterpriseServerUrl),
      users: [
      ],
      org: null,
      allRoles: [],
      confirmMessage: null,
      confirmMessageHeader: null,
    };
  },
  computed: {
    headers() {
      return ['User', 'E-mail', 'Role'];
    },
    user() {
      return this.$store.state.user.user;
    },
    isAdmin() {
      return this.user && (this.user.role === 'admin');
    },
    isSuper() {
      return this.user && (this.user.role === 'super');
    },
  },
  created() {
    if (!this.isAdmin && !this.isSuper) {
      this.$router.push('/login');
    }
    this.getUsers();
    this.getAllRoles();
  },
  methods: {
    openConfirmDeleteUserModal(u) {
      this.confirmMessage = `Are you sure you want to remove user "${u.username}"?`;
      this.confirmMessageHeader = 'Remove User';
      this.toDelete = u;
      this.$refs.confirmModal.showModal();
    },
    async getAllRoles() {
      await this.dsConn.getAllRoles()
        .then((data) => {
          this.allRoles = data.result;
        })
        .catch((e) => console.log(e));
    },
    async getUsers() {
      await this.dsConn.getUserListByOrganization({ organization_id: this.user.organization_id })
        .then((data) => {
          if (data.result) {
            this.users = data.result.reverse();
          }
        }).catch((e) => console.log(e));
    },
    navigateToCreateUser() {
      this.$router.push({ name: 'admin.people.newuser', query: { org: this.user.organization_id } });
    },
    navigateToEditUser(u) {
      this.$router.push({ name: 'admin.people.edituser', query: { editUser: u.username } });
    },
    async removeUser() {
      await this.dsConn.deleteUser({ username: this.toDelete.username })
        .then(async (data) => {
          if (!data.error) {
            this.toDelete = null;
            await this.getUsers();
          }
        }).catch((e) => console.log(e));
    },
  },
};
</script>

<style lang="scss" scoped>
.settings {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 30px;
  overflow-y: auto;
}

.header {
  font-size: 1.2rem;
  font-weight: 700;
}

.create-button {
  width: fit-content;
}
.user-table {
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  width: 100%;
  padding-top: 20px;
  padding-left: 30px;
  padding-right: 30px;

  &__header {
    font-size: 0.9rem;
  }

  &__table {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 15px 15px 15px 15px;

    .header-row {
      font-weight: 700;
      border-bottom: 2px solid rgba(96, 95, 95, 0.5);
    }

    .footer-row {
      min-height: 10px;
      border-bottom: 2px solid rgba(96, 95, 95, 0.5);
    }
  }

  &__username {
    display: flex;
    flex-direction: column;
    justify-content: center;

    span:nth-child(2) {
      font-size: 0.9rem;
      padding-left: 20px;
      color: rgba(grey, 0.9)
    }
  }

  &__package {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &__access {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

}

.button-padding {
  display: flex;
  flex-direction: row;
  width: 100px;
}

.li-row {
  padding: 5px;
  border-radius: 8px;
  align-items: center;
}

.li-row:hover {
  background-color: rgba(140, 140, 140, 0.2);
}

.row {
  flex-wrap: nowrap !important;
}

.row > .label {
  display: flex;
  justify-content: end;
  align-items: center;
  width: 200px;
  text-align: right;
  font-size: 1.0rem;
  font-weight: 600;
  color: rgba(grey, 1.0)
}

.row > .value {
  flex: 1 1 auto;
  font-size: 1.0rem;
  margin-left: 20px;
}

.icon {
  display: flex;
  align-items: center;
  justify-content: center;
  background: none;
  border: none;
  border-radius: 4px;
  margin-left: auto;
  cursor: pointer;
}

.icon + .icon {
  margin-left: 10px;
}

.edit-btn:hover {
  background: none !important;
  box-shadow: none !important;
  color: $color-primary-400;
}

.close-btn:hover {
  background: none !important;
  box-shadow: none !important;
  color: $color-red-400;
}

.invisible {
  visibility: hidden;
}
</style>
