/*
 * Copyright 2020 Au-zone
 * Copyright 2021 NXP
 *
 * NXP Confidential. This software is owned or controlled by NXP and may
 * only be used strictly in accordance with the applicable license terms.
 * By expressly accepting such terms or by downloading, installing,
 * activating and/or otherwise using the software, you are agreeing that
 * you have read, and that you agree to comply with and are bound by,
 * such license terms.  If you do not agree to be bound by the applicable
 * license terms, then you may not retain, install, activate or otherwise
 * use the software.
 *
 * Project: eIQ Toolkit - Portal UI app
 * utils.js - Utility code
 */

// This file holds functions that are shared between training js files
// to avoid code duplication

function checkpointNameToH5Path(modelName, checkpointName) {
  let path = sessionStorage.getItem('project_directory');
  const slash = path.slice(-1);
  path += `${sessionStorage.getItem('project_name')}${slash}${modelName}${slash}chk${slash}${checkpointName}.h5`;
  return path;
}

// eslint-disable-next-line consistent-return
function uppercaseFirstLetter(word) {
  if (word) {
    return word.charAt(0).toUpperCase() + word.slice(1);
  }
}

function splitStream(splitOn) {
  let buffer = '';

  return new TransformStream({
    transform(chunk, controller) {
      buffer += chunk;
      const parts = buffer.split(splitOn);
      parts.slice(0, -1)
        .forEach((part) => controller.enqueue(part));
      buffer = parts[parts.length - 1];
    },
    flush(controller) {
      if (buffer) controller.enqueue(buffer);
    },
  });
}

function parseJSON() {
  return new TransformStream({
    transform(chunk, controller) {
      controller.enqueue(JSON.parse(chunk));
    },
  });
}

const goToPage = (oldPage, newPage) => {
  window.location.pathname = window.location.pathname.replace(oldPage, newPage);
};

const resetSessionStorage = () => {
  const converter = sessionStorage.getItem('converter');
  const datastore = sessionStorage.getItem('datastore');
  const modelrunner = sessionStorage.getItem('modelrunner');
  const trainer = sessionStorage.getItem('trainer');
  sessionStorage.clear();
  sessionStorage.setItem('converter', converter);
  sessionStorage.setItem('datastore', datastore);
  sessionStorage.setItem('modelrunner', modelrunner);
  sessionStorage.setItem('trainer', trainer);
};

const backPage = (oldPage) => {
  const fileOrder = ['../index', 'gallery', 'select-model', 'trainer', 'validate', 'deploy'];
  const oldPageIndex = fileOrder.indexOf(oldPage);
  const newPageIndex = oldPageIndex - 1;
  goToPage(fileOrder[oldPageIndex], fileOrder[newPageIndex]);
  if (newPageIndex === 0) {
    resetSessionStorage();
  }
};

const setupNumberInputButtons = () => {
  document.querySelectorAll('.number-input-minus').forEach((minus) => {
    minus.addEventListener('click', () => {
      const valueNode = minus.parentNode.querySelector('.number-input-value');
      const step = parseFloat(valueNode.step);
      const precision = (`${step}`.split('.')[1] || []).length;
      const newVal = parseFloat(valueNode.value) - step;
      if (newVal >= parseFloat(valueNode.min)) {
        valueNode.value = newVal.toFixed(precision);
        const event = new Event('change');
        valueNode.dispatchEvent(event);
      }
      minus.blur();
    });
  });
  document.querySelectorAll('.number-input-plus').forEach((plus) => {
    plus.addEventListener('click', () => {
      const valueNode = plus.parentNode.querySelector('.number-input-value');
      const step = parseFloat(valueNode.step);
      const precision = (`${step}`.split('.')[1] || []).length;
      const newVal = parseFloat(valueNode.value) + step;
      if (newVal <= parseFloat(valueNode.max) || valueNode.max === '') {
        valueNode.value = newVal.toFixed(precision);
        const event = new Event('change');
        valueNode.dispatchEvent(event);
      }
      plus.blur();
    });
  });
  // Disable children elements if number-input is disabled
  document.querySelectorAll('.number-input').forEach((numInput) => {
    if (numInput.hasAttribute('disabled')) {
      numInput.querySelectorAll('button').forEach((btn) => {
        btn.setAttribute('disabled', '');
      });
      numInput.querySelector('input').setAttribute('disabled', '');
    }
    if (!numInput.hasAttribute('disabled')) {
      numInput.querySelectorAll('button').forEach((btn) => {
        btn.removeAttribute('disabled');
      });
      numInput.querySelector('input').removeAttribute('disabled');
    }
  });
  // Set up a new observer for when the number-input "disabled" attribute changes
  const observer = new MutationObserver((mutations) => {
    mutations.forEach((mutation) => {
      // Check the modified attributeName is "disabled"
      if (mutation.attributeName === "disabled") {
        const numInput = mutation.target;
        if (numInput.hasAttribute('disabled')) {
          numInput.querySelectorAll('button').forEach((btn) => {
            btn.setAttribute('disabled', '');
          });
          numInput.querySelector('input').setAttribute('disabled', '');
        }
        if (!numInput.hasAttribute('disabled')) {
          numInput.querySelectorAll('button').forEach((btn) => {
            btn.removeAttribute('disabled');
          });
          numInput.querySelector('input').removeAttribute('disabled');
        }
      }
    });
  });
  // Configure to only listen to attribute changes
  const config = { attributes: true };
  // Start observing the number-input
  document.querySelectorAll('.number-input').forEach((numInput) => {
    observer.observe(numInput, config);
  });
};

const scaleNanos = (time) => {
  if (time < 10e3) {
    const scaled = time.toFixed(0);
    return `${scaled}ns`;
  }

  if (time < 10e6) {
    const scaled = (time / 1e3).toFixed(0);
    return `${scaled}µs`;
  }

  if (time < 10e9) {
    const scaled = (time / 1e6).toFixed(0);
    return `${scaled}ms`;
  }

  const scaled = (time / 1e9).toFixed(0);
  return `${scaled}s`;
};

const connectLinks = () => {
  const openLinks = document.querySelectorAll('.open-link');
  openLinks.forEach((link) => {
    link.addEventListener('click', (e) => {
      e.preventDefault();
      window.api.send('open-link', link.getAttribute('link'));
    });
  });
};

// Debounce function for events
const debounce = (fn, delay) => {
  let timeoutID;
  return (...args) => {
    if (timeoutID) {
      clearTimeout(timeoutID);
    }
    timeoutID = setTimeout(() => {
      fn(...args);
    }, delay);
  };
};

// Throttle function: Input as function which needs to be throttled
// and delay is the time interval in milliseconds
const throttle = (fn, delay) => {
  let timerID;
  return (...args) => {
    // If setTimeout is already scheduled, no need to do anything
    if (timerID) {
      return;
    }
    // Schedule a setTimeout after delay seconds
    timerID = setTimeout(() => {
      fn(...args);
      // Once setTimeout function execution is finished, timerId = undefined so that
      // the next execution can be scheduled by the setTimeout
      timerID = undefined;
    }, delay);
  };
};

// open external link

const openLink = (event) => {
  window.api.send('open-link', event.target.attributes.link.value);
};

connectLinks();

const inputSanitizer = (event) => {
  const input = event.target;
  const inputValue = event.target.value;
  if (inputValue === '' || inputValue === undefined) {
    input.style.color = 'black';
  } else if (parseFloat(inputValue) > parseFloat(input.max)) {
    input.style.color = 'red';
  } else if (parseFloat(inputValue) < parseFloat(input.min)) {
    input.style.color = 'red';
  } else {
    input.style.color = 'black';
  }
};

const changeSanitizer = (event) => {
  const input = event.target;
  const inputValue = event.target.value;
  if (parseFloat(inputValue) > parseFloat(input.max)) {
    event.target.value = input.max;
    input.style.color = 'black';
  } else if (parseFloat(inputValue) < parseFloat(input.min)) {
    event.target.value = input.min;
    input.style.color = 'black';
  } else if (inputValue === "" || Number.isNaN(inputValue)) {
    event.target.value = input.min;
    input.style.color = 'black';
  }
  input.dispatchEvent(new CustomEvent('input'));
};

/**
 * Adjusts a number to the specified digit.
 *
 * @param {"round" | "floor" | "ceil"} type The type of adjustment.
 * @param {number} value The number.
 * @param {number} exp The exponent (the 10 logarithm of the adjustment base).
 * @returns {number} The adjusted value.
 */
function decimalAdjust(type, value, exp) {
  type = String(type);
  if (!["round", "floor", "ceil"].includes(type)) {
    throw new TypeError(
      "The type of decimal adjustment must be one of 'round', 'floor', or 'ceil'.",
    );
  }
  exp = Number(exp);
  value = Number(value);
  if (exp % 1 !== 0 || Number.isNaN(value)) {
    return NaN;
  } else if (exp === 0) {
    return Math[type](value);
  }
  const [magnitude, exponent = 0] = value.toString().split("e");
  const adjustedValue = Math[type](`${magnitude}e${exponent - exp}`);
  // Shift back
  const [newMagnitude, newExponent = 0] = adjustedValue.toString().split("e");
  return Number(`${newMagnitude}e${+newExponent + exp}`);
}

const roundNumber = (number, digits) => {
  const multiple = 10 ** digits;
  return Math.round(number * multiple) / multiple;
};

function hslToHex(h, s, l) {
  l /= 100;
  const a = s * Math.min(l, 1 - l) / 100;
  const f = (n) => {
    const k = (n + h / 30) % 12;
    const color = l - a * Math.max(Math.min(k - 3, 9 - k, 1), -1);
    return Math.round(255 * color).toString(16).padStart(2, '0'); // convert to Hex and prefix "0" if needed
  };
  return `#${f(0)}${f(8)}${f(4)}`;
}

const getColorByIndexFromScale = (index) => {
  const hue = (index * 137.508) % 360; // use golden angle approximation
  return hslToHex(hue, 70, 50);
};
const getColorByIndex = (index) => {
  // const colors = ['#ffff00', '#00ff00', '#ffa500', '#ff0000', '#48d1cc', '#a0522d', '#ffc0cb', '#ff1493', '#f0e68c', '#1e90ff', '#0000ff', '#00fa9a'];
  // const colors = [
  //   "#ffff00", // yellow
  //   "#00ff00", // lime
  //   "#ff8c00", // darkorange
  //   '#ff0000', // red
  //   "#00ced1", // darkturquoise
  //   "#8b4513", // saddlebrown
  //   "#ffb6c1", // lightpink
  //   "#dc143c", // crimson
  //   "#9400d3", // darkviolet
  //   "#00bfff", // deepskyblue
  //   "#00008b", // darkblue
  //   "#00ff7f", // springgreen
  //   "#2f4f4f", // darkslategray
  //   "#556b2f", // darkolivegreen
  //   "#228b22", // forestgreen
  //   "#7f0000", // maroon2
  //   "#191970", // midnightblue
  //   "#808000", // olive
  //   "#3cb371", // mediumseagreen
  //   "#008080", // teal
  //   "#b8860b", // darkgoldenrod
  //   "#4682b4", // steelblue
  //   "#d2691e", // chocolate
  //   "#9acd32", // yellowgreen
  //   "#32cd32", // limegreen
  //   "#8fbc8f", // darkseagreen
  //   "#800080", // purple
  //   "#b03060", // maroon3
  //   "#ff4500", // orangered
  //   "#00ced1", // darkturquoise
  //   "#ffd700", // gold
  //   "#0000cd", // mediumblue
  //   "#deb887", // burlywood
  //   "#f4a460", // sandybrown
  //   "#f08080", // lightcoral
  //   "#adff2f", // greenyellow
  //   "#ff6347", // tomato
  //   "#da70d6", // orchid
  //   "#b0c4de", // lightsteelblue
  //   "#ff00ff", // fuchsia
  //   "#1e90ff", // dodgerblue
  //   "#f0e68c", // khaki
  //   "#dda0dd", // plum
  //   "#ff1493", // deeppink
  //   "#7b68ee", // mediumslateblue
  //   "#afeeee", // paleturquoise
  //   "#98fb98", // palegreen
  //   "#7fffd4", // aquamarine
  //   "#ff69b4", // hotpink
  // ];
  const colorsHSL = [
    "hsl(60, 100%, 50%)", // yellow
    "hsl(120, 100%, 50%)", // lime
    "hsl(33, 100%, 50%)", // darkorange
    "hsl(0, 100%, 50%)", // red
    "hsl(181, 87%, 41%)", // darkturquoise
    "hsl(25, 76%, 31%)", // saddlebrown
    "hsl(351, 100%, 86%)", // lightpink
    "hsl(348, 83%, 47%)", // crimson
    "hsl(282, 100%, 41%)", // darkviolet
    "hsl(195, 100%, 50%)", // deepskyblue
    "hsl(240, 100%, 27%)", // darkblue
    "hsl(150, 100%, 50%)", // springgreen
    "hsl(180, 25%, 24%)", // darkslategray
    "hsl(82, 39%, 31%)", // darkolivegreen
    "hsl(120, 61%, 34%)", // forestgreen
    "hsl(0, 100%, 25%)", // maroon2
    "hsl(240, 64%, 27%)", // midnightblue
    "hsl(60, 100%, 25%)", // olive
    "hsl(147, 50%, 47%)", // mediumseagreen
    "hsl(180, 100%, 25%)", // teal
    "hsl(43, 89%, 38%)", // darkgoldenrod
    "hsl(207, 44%, 49%)", // steelblue
    "hsl(25, 74%, 47%)", // chocolate
    "hsl(80, 61%, 50%)", // yellowgreen
    "hsl(120, 61%, 50%)", // limegreen
    "hsl(120, 25%, 60%)", // darkseagreen
    "hsl(300, 100%, 25%)", // purple
    "hsl(340, 55%, 47%)", // maroon3
    "hsl(16, 100%, 50%)", // orangered
    "hsl(181, 87%, 41%)", // darkturquoise (duplicate)
    "hsl(51, 100%, 50%)", // gold
    "hsl(240, 100%, 40%)", // mediumblue
    "hsl(33, 57%, 70%)", // burlywood
    "hsl(28, 87%, 67%)", // sandybrown
    "hsl(0, 79%, 72%)", // lightcoral
    "hsl(84, 100%, 59%)", // greenyellow
    "hsl(9, 100%, 64%)", // tomato
    "hsl(302, 59%, 65%)", // orchid
    "hsl(214, 41%, 78%)", // lightsteelblue
    "hsl(300, 100%, 50%)", // fuchsia
    "hsl(210, 100%, 56%)", // dodgerblue
    "hsl(54, 77%, 75%)", // khaki
    "hsl(300, 100%, 75%)", // plum
    "hsl(327, 100%, 54%)", // deeppink
    "hsl(248, 80%, 67%)", // mediumslateblue
    "hsl(180, 65%, 81%)", // paleturquoise
    "hsl(120, 93%, 79%)", // palegreen
    "hsl(160, 100%, 75%)", // aquamarine
    "hsl(330, 100%, 71%)", // hotpink
  ];
  return colorsHSL[index % 49];
};

const getColor = (index) => {
  let color = '';
  if (index < 49) {
    color = getColorByIndex(index);
  } else {
    color = getColorByIndexFromScale(index);
  }
  return color;
};

function wait(delay) {
  return new Promise((resolve) => setTimeout(resolve, delay));
}

function fetchRetry(url, { delay = 500, tries = 3, backoff_factor = 1 }, fetchOptions = {}) {
  function onError(err) {
    const triesLeft = tries - 1;
    if (!triesLeft) {
      throw err;
    }
    const delayWithBackoff = delay * (tries * backoff_factor);
    return wait(delay).then(() => fetchRetry(url, { delay: delayWithBackoff, tries: triesLeft, backoff_factor }, fetchOptions));
  }
  return fetch(url, fetchOptions).catch(onError);
}

async function getFileContents(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = function (e) {
      const fileContents = e.target.result;
      resolve(fileContents);
    };
    reader.onerror = function (err) {
      reject(err);
    };
    reader.readAsText(file);
  });
}

function parseJwt(token) {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(window.atob(base64).split('').map((c) => `%${(`00${c.charCodeAt(0).toString(16)}`).slice(-2)}`).join(''));

  return JSON.parse(jsonPayload);
}

function deepCompare(obj1, obj2) {
  // Get the keys of the objects
  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  // Check if the number of keys is the same
  if (keys1.length !== keys2.length) {
    return false;
  }

  // Check if all keys in obj1 exist in obj2 and have the same value recursively
  for (const key of keys1) {
    const val1 = obj1[key];
    const val2 = obj2[key];

    // Check if both values are objects and if so, perform a recursive deep comparison
    if (typeof val1 === 'object' && typeof val2 === 'object') {
      if (!deepCompare(val1, val2)) {
        return false;
      }
    } else if (val1 !== val2) {
      return false;
    }
  }

  // If all checks pass, the objects are deeply equal
  return true;
}

export {
  checkpointNameToH5Path,
  uppercaseFirstLetter,
  splitStream,
  parseJSON,
  backPage,
  goToPage,
  debounce,
  throttle,
  resetSessionStorage,
  setupNumberInputButtons,
  scaleNanos,
  openLink,
  inputSanitizer,
  changeSanitizer,
  decimalAdjust,
  roundNumber,
  getColor,
  getColorByIndexFromScale,
  getColorByIndex,
  hslToHex,
  fetchRetry,
  getFileContents,
  parseJwt,
  deepCompare,
};
