<template>
  <BaseMenu
    :placement="'bottom-start'"
    :appendToBody="true"
    @closed="show_layout_menu = false"
  >
    <IconButton
      v-model="show_layout_menu"
      :icon="'settings'"
      :width="22"
      :height="22"
    />
    <template #menu="{closeMenu}">
      <ul>
        <li>
          <BaseMenuButton @click.stop="handleClick('canvas'), closeMenu()">
            <!-- <SVGIcon
              :iconName="'canvas'"
              :width="'22px'"
              :height="'22px'"
            /> -->
            <span>2D Canvas</span>
          </BaseMenuButton>
        </li>
        <li>
          <BaseMenuButton @click.stop="handleClick('3d-canvas'), closeMenu()">
            <!-- <SVGIcon
              :iconName="'3d_canvas'"
              :width="'22px'"
              :height="'22px'"
            /> -->
            <span>3D Canvas</span>
          </BaseMenuButton>
        </li>
        <li>
          <BaseMenuButton @click.stop="handleClick('depth-map'), closeMenu()">
            <!-- <SVGIcon
              :iconName="'depth_map'"
              :width="'22px'"
              :height="'22px'"
            /> -->
            <span>Depth Map</span>
          </BaseMenuButton>
        </li>
      </ul>
    </template>
  </BaseMenu>
</template>

<script setup>
import SVGIcon from '@/components/SVGIcon.vue';
import IconButton from '@/components/IconButton.vue';
import BaseMenu from '@/components/BaseMenu.vue';
import BaseMenuButton from '@/components/BaseMenuButton.vue';

const emit = defineEmits(['visualization-selected']);

function handleClick(visualization) {
  emit('visualization-selected', visualization);
}

</script>

<style lang="scss" scoped>

</style>
