<template>
  <div class="main-page scrollbar">
    <div class="settings">
      <span class="header">Edit User</span>

      <div class="row mt-3">
        <label class="label required">Username</label>
        <div class="value">
          <input
            v-model="username"
            class="select"
            type="text"
            :class="{ 'error' : errorUsername }"
            disabled
          >
        </div>
      </div>
      <div class="row mt-3">
        <label class="label required">First Name</label>
        <div class="value">
          <input
            v-model="firstname"
            class="select"
            type="text"
            :class="{ 'error' : errorFirstname }"
          >
        </div>
      </div>
      <div class="row mt-3">
        <label class="label required">Last Name</label>
        <div class="value">
          <input
            v-model="lastname"
            class="select"
            type="text"
            :class="{ 'error' : errorLastname }"
          >
        </div>
      </div>
      <div class="row mt-3">
        <label class="label">Email</label>
        <div class="value">
          <input
            v-model="email"
            class="select"
            type="text"
            :class="{ 'error' : errorEmail }"
          >
        </div>
      </div>
      <div v-if="presetRole !== 'super'" class="row mt-3">
        <label class="label required">Role</label>
        <div class="value permission-list">
          <div class="role-grid-single-row">
            <div class="chkbx-li">
              <v-checkbox
                v-model="presetRole"
                :hide-details="true"
                density="compact"
                value="admin"
                :false-icon="'mdi-checkbox-blank-circle-outline'"
                :true-icon="'mdi-circle-slice-8'"
                :disabled="user.role !== 'admin' && user.role !== 'super'"
              >
                <template #label>
                  <div class="label-text">Admin</div>
                </template>
              </v-checkbox>
              <span class="chkbx-li__subtitle">Full access to Deep View Enterprise. Can read and write to projects, datasets, trainer, validation, automation, and task board.</span>
            </div>
            <div class="chkbx-li">
              <v-checkbox
                v-model="presetRole"
                :hide-details="true"
                density="compact"
                value="auditor"
                :false-icon="'mdi-checkbox-blank-circle-outline'"
                :true-icon="'mdi-circle-slice-8'"
                :disabled="user.role !== 'admin' && user.role !== 'super'"
              >
                <template #label>
                  <div class="label-text">Audit & Label</div>
                </template>
              </v-checkbox>
              <span class="chkbx-li__subtitle">Limited access to Deep View Enterprise. Can only perform auditing and labelling in task board.</span>
            </div>
            <div class="chkbx-li">
              <v-checkbox
                v-model="presetRole"
                :hide-details="true"
                density="compact"
                value="user"
                :false-icon="'mdi-checkbox-blank-circle-outline'"
                :true-icon="'mdi-circle-slice-8'"
                :disabled="user.role !== 'admin' && user.role !== 'super'"
              >
                <template #label>
                  <div class="label-text">Custom</div>
                </template>
              </v-checkbox>
              <span class="chkbx-li__subtitle">Customize access to Deep View Enterprise for specific usage.</span>
            </div>
          </div>
        </div>
      </div>
      <div v-else class="row mt-3">
        <label class="label required">Role</label>
        <div class="value permission-list">
          <div class="role-grid-single-row">
            <div class="chkbx-li">
              <v-checkbox
                v-model="presetRole"
                :hide-details="true"
                density="compact"
                value="super"
                :false-icon="'mdi-checkbox-blank-circle-outline'"
                :true-icon="'mdi-circle-slice-8'"
                disabled
              >
                <template #label>
                  <div class="label-text">Super</div>
                </template>
              </v-checkbox>
            </div>
          </div>
        </div>
      </div>
      <template v-if="presetRole === 'user'">
        <div class="row mt-3">
          <label class="label">Project</label>
          <div class="value permission-list">
            <div class="role-grid">
              <div class="chkbx-li">
                <v-checkbox
                  v-model="permissions.project_read"
                  :hide-details="true"
                  density="compact"
                  :disabled="user.role !== 'admin' && user.role !== 'super'"
                >
                  <template #label>
                    <div class="label-text">Read</div>
                  </template>
                </v-checkbox>
              </div>
              <div class="chkbx-li">
                <v-checkbox
                  v-model="permissions.project_write"
                  :hide-details="true"
                  density="compact"
                  :disabled="user.role !== 'admin' && user.role !== 'super'"
                >
                  <template #label>
                    <div class="label-text">Write</div>
                  </template>
                </v-checkbox>
              </div>
            </div>
          </div>
        </div>
        <div class="row mt-3">
          <label class="label">Dataset</label>
          <div class="value permission-list">
            <div class="role-grid">
              <div class="chkbx-li">
                <v-checkbox
                  v-model="permissions.dataset_read"
                  :hide-details="true"
                  density="compact"
                  :disabled="user.role !== 'admin' && user.role !== 'super'"
                >
                  <template #label>
                    <div class="label-text">Read</div>
                  </template>
                </v-checkbox>
              </div>
              <div class="chkbx-li">
                <v-checkbox
                  v-model="permissions.dataset_write"
                  :hide-details="true"
                  density="compact"
                  :disabled="user.role !== 'admin' && user.role !== 'super'"
                >
                  <template #label>
                    <div class="label-text">Write</div>
                  </template>
                </v-checkbox>
              </div>
            </div>
          </div>
        </div>
        <div class="row mt-3">
          <label class="label">Trainer</label>
          <div class="value permission-list">
            <div class="role-grid">
              <div class="chkbx-li">
                <v-checkbox
                  v-model="permissions.trainer_read"
                  :hide-details="true"
                  density="compact"
                  :disabled="user.role !== 'admin' && user.role !== 'super'"
                >
                  <template #label>
                    <div class="label-text">Read</div>
                  </template>
                </v-checkbox>
              </div>
              <div class="chkbx-li">
                <v-checkbox
                  v-model="permissions.trainer_write"
                  :hide-details="true"
                  density="compact"
                  :disabled="user.role !== 'admin' && user.role !== 'super'"
                >
                  <template #label>
                    <div class="label-text">Write</div>
                  </template>
                </v-checkbox>
              </div>
            </div>
          </div>
        </div>
        <div class="row mt-3">
          <label class="label">Task Board</label>
          <div class="value permission-list">
            <div class="role-grid">
              <div class="chkbx-li">
                <v-checkbox
                  v-model="permissions.annotator_read"
                  :hide-details="true"
                  density="compact"
                  :disabled="user.role !== 'admin' && user.role !== 'super'"
                >
                  <template #label>
                    <div class="label-text">Read</div>
                  </template>
                </v-checkbox>
              </div>
              <div class="chkbx-li">
                <v-checkbox
                  v-model="permissions.annotator_write"
                  :hide-details="true"
                  density="compact"
                  :disabled="user.role !== 'admin' && user.role !== 'super'"
                >
                  <template #label>
                    <div class="label-text">Write</div>
                  </template>
                </v-checkbox>
              </div>
            </div>
          </div>
        </div>
      </template>
      <!-- <div class="row mt-4">
          <label class="label required">Role</label>
          <div class="value">
            <select
              v-model="role"
              class="select"
            >
              <option v-for="option in roles" :key="`access-select-${option.id}`" :value="option.value">
                {{ option.name }}
              </option>
            </select>
          </div>
        </div> -->
      <div v-if="message" class="row mt-2">
        <label class="label" />
        <div class="value" :class="{ 'error-text': isError, 'success-text' : !isError }">{{ message }}</div>
      </div>
      <div class="row mt-3 footer">
        <button class="button button-sm rounded" @click.stop="applyChanges">Apply</button>
        <span @click.stop="$router.go(-1)">Cancel</span>
      </div>

      <span class="header">Change Password</span>
      <div class="row mt-3">
        <label class="label">Password</label>
        <div class="value">
          <input
            v-model="password"
            class="select"
            type="password"
            :class="{ 'error' : errorPassword }"
          >
        </div>
      </div>
      <div class="row mt-3">
        <label class="label">Confirm Password</label>
        <div class="value">
          <input
            v-model="confirmPassword"
            class="select"
            type="password"
            :class="{ 'error' : errorConfirmPassword }"
          >
        </div>
      </div>
      <div v-if="passwordMessage" class="row mt-2">
        <label class="label" />
        <div class="value" :class="{ 'error-text': passwordError, 'success-text' : !passwordError }">{{ passwordMessage }}</div>
      </div>
      <div class="row mt-3 footer">
        <button class="button button-sm rounded" @click.stop="changePassword">Apply</button>
        <span @click.stop="$router.go(-1)">Cancel</span>
      </div>
    </div>
  </div>
</template>

<script>
import DatastoreConnect from '@/assets/js/DatastoreFunctions/datastore-interface';

export default {
  name: 'EditUser',
  components: {
  },
  data() {
    return {
      dsConn: new DatastoreConnect(this.$store.state.enterpriseServerUrl),
      password: '',
      errorPassword: false,
      confirmPassword: '',
      errorConfirmPassword: false,
      username: '',
      errorUsername: false,
      firstname: '',
      errorFirstname: false,
      lastname: '',
      errorLastname: false,
      message: '',
      errorRole: false,
      email: '',
      errorEmail: false,
      backendError: false,
      passwordMessage: '',
      presetRole: 'admin',
      permissions: {
        project_read: false,
        project_write: false,
        dataset_read: false,
        dataset_write: false,
        trainer_read: false,
        trainer_write: false,
        annotator_read: false,
        annotator_write: false,
      },
    };
  },
  computed: {
    isAdmin() {
      return this.user.role === 'admin';
    },
    isSuper() {
      return this.user.role === 'super';
    },
    isError() {
      return this.errorUsername || this.errorRole || this.errorEmail || this.backendError || this.errorFirstname || this.errorLastname;
    },
    passwordError() {
      return this.errorConfirmPassword || this.errorPassword;
    },
    user() {
      return this.$store.state.user.user;
    },
  },
  watch: {
    permissions: {
      deep: true,
      handler() {
        Object.values(this.permissions).forEach((type) => {
          if (type.canWrite) {
            Object.keys(type).forEach((key) => {
              type[key] = true;
            });
          }
        });
      },
    },
  },
  async created() {
    if (this.$route.query.editUser) {
      this.getExistingUser(this.$route.query.editUser);
      this.getUserPermissions(this.$route.query.editUser);
    }
  },
  methods: {
    async getUserPermissions(u) {
      await this.dsConn.getUserPermissions({ username: u })
        .then(async (data) => {
          if (data.result) {
            this.permissions = data.result;
          }
        })
        .catch((e) => { this.message = e; });
    },

    async getExistingUser(u) {
      await this.dsConn.checkForUser({ username: u })
        .then(async (data) => {
          if (data.result) {
            this.username = data.result.username;
            this.firstname = data.result.first_name;
            this.lastname = data.result.last_name;
            this.email = data.result.email;
            this.presetRole = data.result.role;
          } else {
            this.message = data.error.message;
          }
        })
        .catch((e) => { this.message = e; });
    },
    resetErrors() {
      this.errorRole = false;
      this.errorEmail = false;
      this.backendError = false;
      this.errorFirstname = false;
      this.errorLastname = false;
    },
    async changePassword() {
      this.errorConfirmPassword = false;
      this.errorPassword = false;
      this.passwordMessage = "";
      let tempMessage = "No changes made";
      // should separate each field then make optional checks on BE
      if ((this.password !== '' || this.confirmPassword !== '') && this.password !== this.confirmPassword) {
        this.errorPassword = true;
        this.errorConfirmPassword = true;
        tempMessage = 'Confirm password does not match password.';
        this.message = tempMessage;
      } else if (this.password !== '' && this.confirmPassword !== '' && this.password === this.confirmPassword) {
        await this.dsConn.updateUserPasswordAuth({ username: this.username, password: this.password })
          .then((resp) => {
            if (resp.result) {
              this.errorPassword = false;
              tempMessage = 'Successfully updated password';
            } else {
              this.errorPassword = true;
              tempMessage = resp.error.message;
            }
          })
          .catch((e) => {
            this.errorPassword = true;
            tempMessage = e;
          });
      }
      this.passwordMessage = tempMessage;
    },
    async applyChanges() {
      this.resetErrors();
      this.message = "";

      let tempMessage = "No changes made";

      if (!this.username) {
        this.errorUsername = true;
        this.message = 'Missing username field.';
        return;
      }

      if (!this.firstname) {
        this.errorFirstname = true;
        this.message = 'Missing first name field.';
        return;
      }

      if (!this.lastname) {
        this.errorLastname = true;
        this.message = 'Missing last name field.';
        return;
      }
      const params = {
        username: this.username,
        last_name: this.lastname,
        first_name: this.firstname,
        email: this.email,
        role: this.presetRole,
        permissions: {},
      };
      if (this.presetRole === 'admin') {
        params.permissions.project_read = true;
        params.permissions.project_write = true;
        params.permissions.dataset_read = true;
        params.permissions.dataset_write = true;
        params.permissions.trainer_read = true;
        params.permissions.trainer_write = true;
        params.permissions.annotator_read = true;
        params.permissions.annotator_write = true;
      } else if (this.presetRole === 'auditor') {
        params.permissions.project_read = true;
        params.permissions.project_write = false;
        params.permissions.dataset_read = false;
        params.permissions.dataset_write = false;
        params.permissions.trainer_read = false;
        params.permissions.trainer_write = false;
        params.permissions.annotator_read = true;
        params.permissions.annotator_write = true;
      } else if (this.presetRole === 'user') {
        params.permissions = this.permissions;
      }

      await this.dsConn.updateUser(params)
        .then(async (data) => {
          if (data.result) {
            this.backendError = false;
            tempMessage = 'Successfully updated user';

            if (this.user.username === this.username) {
              // user is logged in, refresh data
              const newUserObj = { ...this.user };
              newUserObj.last_name = this.lastname;
              newUserObj.first_name = this.firstname;
              newUserObj.email = this.email;
              newUserObj.role = this.presetRole;
              this.$store.commit('user/setUser', newUserObj);
            }
          } else {
            this.backendError = true;
            tempMessage = 'Could not update user information';
          }
        })
        .catch((e) => {
          this.backendError = true;
          tempMessage = e.message;
        });

      this.message = tempMessage;
    },
  },
};
</script>

<style lang="scss" scoped>
.main-page {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  margin-bottom: 10px;
}

.settings {
  display: flex;
  flex-direction: column;
  width: 730px;
  height: 100%;
  padding: 30px;
}

.header {
  font-size: 1.2rem;
  font-weight: 700;
}

.row {
  flex-wrap: nowrap !important;
}

.row:nth-child(n + 6):not(:nth-last-child(-n + 3)) > .label {
  align-items: start !important;
  margin-top: 10px !important;
}

.row > .label {
  display: flex;
  justify-content: end;
  align-items: center;
  width: 200px;
  text-align: right;
  font-size: 0.9rem;
  font-weight: 600;
  color: rgba(grey, 0.9);

  &.required::after {
    content: '*';
    font-size: 14px;
    font-weight: 700;
    padding-left: 1px;
    @include themify() {
      color: themed('color-primary');
    }
  }
}

.row > .value {
  display: flex;
  flex-direction: row;
  flex: 1 1 auto;
  font-size: 0.9rem;
  margin-left: 20px;
}
.role-grid {
  display: grid;
  grid-gap: 10px;
  grid-template-columns: 1fr 1fr;
  grid-auto-rows: auto;
  width: 100%;
}

.label-text {
  display: flex;
  flex-direction: row;
  cursor: pointer;
  align-items: center;
  color: var(--color-black) !important;
  font-size: 0.9rem;
  font-weight: 600;
  margin-left: 2px;
  width: 100px;
}

:deep(.v-label) {
  opacity: 1 !important;
}

.select {
  width: 100%;
  height: 30px;
  border-radius: 5px;
  font-size: 1rem;
  padding: 3px 10px;
  align-self: center;
  -webkit-appearance: auto;
  background: rgba(#ffffff, 0.8);
  @include themify() {
    border: 1px solid themed('form-input-border');
  }

}

.select:focus {
  @include themify() {
    border: 1px solid themed('color-primary');
    box-shadow:  0 0 0 1px themed('color-primary'), 0 0 3px themed('color-primary-300');
  }
  outline: 2px solid transparent;
  outline-offset: 2px;
}

input.error , select.error {
  @include themify() {
    border: 1px solid themed('color-red') !important;
    box-shadow:  0 0 0 1px themed('color-red'), 0 0 3px themed('color-red') !important;
  }

  &:focus, &:focus-visible {
    @include themify() {
      border: 1px solid themed('color-red') !important;
      box-shadow:  0 0 0 1px themed('color-red'), 0 0 3px themed('color-red') !important;
    }
  }
}

.error-text {
  color: $color-red-500;
}

.success-text {
  color: $color-success;
}

.footer {
  display: flex;
  justify-content: flex-end;
  button {
    width: fit-content
  }
  span {
    color: $color-link;
    text-decoration: underline;
    cursor: pointer;
    width: fit-content;
  }
}
</style>
