<template>
  <Modal
    ref="taskAccessModal"
    :title="modalTask ? modalTask.name : 'Manage Task Access'"
    :width="'700px'"
    @closed="resetData"
  >
    <template #modal-main>
      <div class="settings container">
        <div class="row mt-2">
          <div class="header-buttons">
            <button
              class="button button-back"
              aria-label="Back"
              :title="'Back'"
              :class="{ 'invisible' : mode === 'users' }"
              @click="() => mode = 'users'"
            >
              <SVGIcon
                class="me-1"
                :iconName="'chevron_left'"
                :width="'18px'"
                :height="'18px'"
              />
              <span>Back</span>
            </button>
            <button
              class="button button-mode"
              aria-label="Add Users"
              :title="'Add New Users'"
              :class="{ 'invisible' : mode === 'new' }"
              @click="() => mode = 'new'"
            >
              <SVGIcon
                class="me-1"
                :iconName="'person_add'"
                :width="'18px'"
                :height="'18px'"
              />
              <span>Add Users</span>
            </button>
          </div>
        </div>
        <div v-if="mode === 'users'" class="row mt-3">
          <div class="col-12 settings__control-group">
            <label class="label required">Access Sharing</label>
            <div class="value permission-list">
              <div class="role-grid-single-row">
                <v-radio-group
                  v-model="shareMode"
                  :density="'compact'"
                  :hide-details="true"
                >
                  <div class="chkbx-li">
                    <v-radio value="default" color="#250E81">
                      <template #label>
                        <div class="label-text">Share with organization</div>
                      </template>
                    </v-radio>
                    <span class="chkbx-li__subtitle">Everyone in your organization has access to this task.</span>
                  </div>
                  <div class="chkbx-li">
                    <v-radio value="custom" color="#250E81">
                      <template #label>
                        <div class="label-text">Share with users</div>
                      </template>
                    </v-radio>
                    <span class="chkbx-li__subtitle">Only owner and selected users have access to this task.</span>
                  </div>
                </v-radio-group>
              </div>
            </div>
          </div>
        </div>
        <template v-if="mode === 'users'">
          <div class="row mt-3">
            <div class="col-12 settings__control-group">
              <label>Users with access to this task</label>
              <ul class="mt-1 users scrollbar">
                <li
                  v-for="(user, index) in taskAccessList"
                  :key="index"
                  class="users__list-item"
                >
                  <div class="users__container" :class="{ 'slashed': remove.indexOf(user.username) > -1 }">
                    <div class="users__icon">
                      <div class="users__initials">
                        {{ user.first_name && user.last_name ? user.first_name.charAt(0).toUpperCase() + user.last_name.charAt(0).toUpperCase() : user.username.$emitcharAt(0).toUpperCase() }}
                      </div>
                    </div>
                    <div class="users__content">
                      <span class="users__username">{{ user.first_name && user.last_name ? `${user.first_name} ${user.last_name}` : user.username }}</span>
                      <span class="users__email">{{ user.email }}</span>
                    </div>
                    <template v-if="(user.username === currentUser.username )">
                      <div class="users__role">
                        <template v-if="user.annotator_read && !user.annotator_write">
                          <SVGIcon
                            :iconName="'visibility'"
                            :width="'18px'"
                            :height="'18px'"
                            class="role-icon"
                          />
                          <span>Viewer</span>
                        </template>
                        <template v-else-if="user.annotator_write">
                          <SVGIcon
                            :iconName="'edit'"
                            :width="'18px'"
                            :height="'18px'"
                            class="role-icon"
                          />
                          <span>Editor</span>
                        </template>
                      </div>
                    </template>
                    <template v-else>
                      <BaseMenu
                        :placement="'bottom-end'"
                        :appendToBody="true"
                      >
                        <div class="users__role editable">
                          <template v-if="user.annotator_read && !user.annotator_write">
                            <SVGIcon
                              :iconName="'visibility'"
                              :width="'18px'"
                              :height="'18px'"
                              class="role-icon"
                            />
                            <span>Viewer</span>
                          </template>
                          <template v-else-if="user.annotator_write">
                            <SVGIcon
                              :iconName="'edit'"
                              :width="'18px'"
                              :height="'18px'"
                              class="role-icon"
                            />
                            <span>Editor</span>
                          </template>
                          <SVGIcon
                            :iconName="'chevron_left'"
                            :width="'18px'"
                            :height="'18px'"
                            class="dropdown-role"
                          />
                        </div>
                        <template #menu="{closeMenu}">
                          <ul>
                            <li>
                              <BaseMenuButton class="_danger" @click.stop="toggleRemoveAccess(user.username), closeMenu()">
                                <template v-if="remove.indexOf(user.username) > -1">
                                  <SVGIcon
                                    :iconName="'close'"
                                    :width="'22px'"
                                    :height="'22px'"
                                  />
                                  <span>Cancel</span>
                                </template>
                                <template v-else>
                                  <SVGIcon
                                    :iconName="'trash'"
                                    :width="'22px'"
                                    :height="'22px'"
                                  />
                                  <span>Remove Access</span>
                                </template>
                              </BaseMenuButton>
                            </li>
                          </ul>
                        </template>
                      </BaseMenu>
                    </template>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </template>
        <template v-else-if="mode === 'new'">
          <div class="row mt-3">
            <div class="col-12 settings__control-group">
              <label>Add users by name or e-mail.</label>
            </div>
          </div>
          <div class="row">
            <v-combobox
              v-model="userInputValue"
              class="combobox"
              chips
              clearable
              multiple
              :density="'comfortable'"
              :hide-details="true"
              :label="'Add People'"
              :items="formatOrgUserListObject"
              :item-title="(obj) => obj['username']"
              :item-value="(obj) => obj['username']"
              :return-object="false"
              variant="outlined"
            />
          </div>
        </template>
        <div v-if="message" class="row mt-2">
          <div class="col-12 settings__control-group">
            <div class="result error">
              <span>{{ message }}</span>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template #modal-footer>
      <div class="footer">
        <button
          v-if="mode === 'users'"
          class="button modal-action-button"
          :disabled="!hasRemoved && !hasAccessModeChange"
          @click="confirmChanges"
        >
          Confirm
        </button>
        <button
          v-else-if="mode === 'new'"
          class="button modal-action-button"
          @click="addAccess"
        >
          Add Users
        </button>
      </div>
    </template>
  </Modal>
</template>

<script>
import DatastoreConnect from '@/assets/js/DatastoreFunctions/datastore-interface';
import Modal from '@/components/Modal.vue';
import SVGIcon from '@/components/SVGIcon.vue';
import BaseMenu from '@/components/BaseMenu.vue';
import BaseMenuButton from '@/components/BaseMenuButton.vue';
import { deepCompare } from '@/assets/js/utils.js';

export default {
  name: 'TaskAccessModal',
  components: {
    Modal,
    SVGIcon,
    BaseMenu,
    BaseMenuButton,
  },
  props: {
    modalTask: {
      type: Object,
      default: () => {},
    },
  },
  emits: ['refresh-tasks'],
  data() {
    return {
      dsConn: new DatastoreConnect(this.$store.state.enterpriseServerUrl),
      mode: 'users',
      openedDropdowns: [],
      taskAccessList: [],
      orgUserList: [],
      userInputValue: [],
      remove: [],
      message: '',
      shareMode: 'default',
    };
  },
  computed: {
    currentUser() {
      return this.$store.state.user.user;
    },
    formatOrgUserListObject() {
      if (this.orgUserList.length > 0) {
        const temp = this.orgUserList.map((e) => ({ username: e.username, email: e.email }));
        return temp.filter((e) => this.taskAccessList.find((el) => el.username === e.username) === undefined);
      } else {
        return [];
      }
    },
    hasRemoved() {
      if (this.remove.length > 0) {
        return true;
      }
      return false;
    },
    hasAccessModeChange() {
      if (this.originalShareMode !== this.shareMode) {
        return true;
      }
      return false;
    },
  },
  watch: {
    shareMode(r) {
      if (r === 'default') {
        this.mode = 'users';
      }
    },
    role_writer(value) {
      if (value) {
        this.role_viewer = true;
      }
    },
    modalTask: {
      immediate: true,
      handler() {
        this.shareMode = this.modalTask ? this.modalTask.permission_type : 'default';
        this.originalShareMode = this.modalTask ? this.modalTask.permission_type : 'default';
      },
    },
  },
  async mounted() {
    if (this.currentUser) {
      this.orgUserList = await this.getUsersListByOrganization(this.currentUser.organization_id);
    }
  },
  methods: {
    deepCompare,
    toggleRemoveAccess(username) {
      const index = this.remove.indexOf(username);
      if (index < 0) {
        this.remove.push(username);
      } else {
        this.remove.splice(index, 1);
      }
    },
    showModal() {
      this.$refs.taskAccessModal.showModal();
      this.getReviewTaskAccessList();
    },
    closeModal() {
      this.resetData();
    },
    resetData() {
      this.taskAccessList = [];
      this.userInputValue = [];
      this.openedDropdowns = [];
      this.message = "";
      this.remove = [];
      this.mode = 'users';
    },
    async getUsersListByOrganization(id) {
      const resp = await this.dsConn.getUserListByOrganization({
        organization_id: id,
      })
        .catch((error) => {
          console.log(error);
        });
      if (resp.error || !resp.result) {
        return [];
      }
      return resp.result;
    },
    async getReviewTaskAccessList() {
      if (this.modalTask) {
        await this.dsConn.getReviewTaskAccessList({ review_task_id: this.modalTask.id })
          .then((data) => {
            if (data.result) {
              this.taskAccessList = data.result;
            } else {
              this.resetData();
            }
          })
          .catch((e) => {
            console.log(e);
          });
      }
    },
    async confirmChanges() {
      this.message = "";
      const promises = [];
      const deleted_ids = [];
      this.taskAccessList.filter((e) => this.remove.indexOf(e.username) > -1).forEach((task_access, index) => {
        deleted_ids.push(task_access.username);
      });

      if (deleted_ids.length > 0) {
        promises.push(this.deleteReviewTaskAccess(deleted_ids));
      }

      if (this.hasAccessModeChange && this.shareMode === 'default') {
        promises.push(this.revertReviewTaskAccessToDefault());
      }

      await Promise.all(promises)
        .then(async () => {
          this.getReviewTaskAccessList();
          this.remove = [];
          this.$emit('refresh-tasks');
        });
    },
    async revertReviewTaskAccessToDefault() {
      await this.dsConn.revertReviewTaskAccessToDefault({
        review_task_id: this.modalTask.id,
      })
        .then((data) => {
          if (!data.result || data.error) {
            this.message = data.error.message;
          } else {
            this.mode = 'users';
          }
        })
        .catch((error) => {
          // TODO: handle error
          console.log(error);
        });
    },
    async deleteReviewTaskAccess(review_task_access_usernames) {
      await this.dsConn.deleteReviewTaskAccess({
        review_task_id: this.modalTask.id,
        review_task_access_usernames,
      })
        .then((data) => {
          if (!data.result || data.error) {
            this.message = data.error.message;
          } else {
            this.mode = 'users';
          }
        })
        .catch((error) => {
          // TODO: handle error
          console.log(error);
        });
    },
    async addAccess() {
      this.message = "";
      const nameMap = this.taskAccessList.map((e) => e.username);
      const overlap = this.userInputValue.some((r) => nameMap.includes(r));
      if (this.userInputValue.length === 0) {
        this.message = "Please select one or more users.";
        return;
      }
      if (overlap) {
        this.message = "One or more users already has access to this project.";
        return;
      }

      await this.dsConn.addReviewTaskAccess({
        review_task_id: this.modalTask.id,
        usernames: this.userInputValue,
      })
        .then((data) => {
          if (!data.result || data.error) {
            this.message = data.error.message;
          } else {
            this.userInputValue = [];
            this.getReviewTaskAccessList();
            this.mode = 'users';
          }
          this.remove = [];
          this.$emit('refresh-tasks');
        })
        .catch((error) => {
          // TODO: handle error
          console.log(error);
        });
    },
  },
};

</script>

<style lang="scss" scoped>

.settings {
  padding: 0.75rem 3rem;
  min-width: 480px;
  overflow: visible;
}

.header-buttons{
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .invisible {
    visibility: hidden;
  }
}

.combobox {
  margin: 1px 0;
  width: 100%;
  padding-top: 8px;
  border-radius: 8px;
}

.users {
  display: flex;
  flex-direction: column;
  gap: 6px;
  list-style: none;
  width: 100%;
  max-height: 450px;
  padding: 0 15px 0 15px;
  margin: 0;
  overflow-y: scroll;

  &__list-item {
    margin: 0px -8px;
    padding: 4px 8px;

    &:hover {
      background: var(--hover-background);
    }
  }

  &__container {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  &__icon {
    padding-right: 16px;
  }

  &__initials {
    display: flex;
    flex: 0 0 auto;
    width: 32px;
    height: 32px;
    // cursor: pointer;
    border: none;
    border-radius: 50%;
    background: #053c8f;
    color: white;
    justify-content: center;
    align-items: center;
  }

  &__content {
    display: flex;
    flex-direction: column;
    text-align: left;
    flex: 1 1 auto;
  }

  &__username {
    font-weight: 600;
    font-size: 16px;
  }

  &__email {
    font-weight: 400;
    font-size: 12px;
  }

  &__role {
    border-radius: 32px;
    height: 34px;
    width: 100px;
    padding: 2px 0 2px 2px;
    font-size: 0.9rem;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  .slashed {
    position: relative;
  }

  .slashed::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 1px;
    top: 50%;
    left: -10px;
    background: var(--color-red-600);
  }

  .dropdown-role{
    margin-left: 5px;
  }

  .editable {
    .dropdown-role {
        transform: rotate(-90deg);
      }
  }

  .editable:hover {
    padding: 2px 0 2px 2px;
    background: rgba(185, 184, 184, 0.5);
    cursor: pointer;
  }

  .role-icon {
    margin-right: 5px;
  }
}

.italic {
  font-style: italic;
}

.removing {
  font-weight: 600;
  @include themify() {
    color: themed('color-red-600');
  }
}

.button-mode {
  background: none;
  height: 35px;
  color: var(--body-text-color);
  padding: 10px 10px;
  justify-content: center;
  display: flex;
  align-items: center;
  box-shadow: none;
  margin: 4px;
  text-transform: capitalize;
  font-size: 0.9rem;
  font-weight: 550;
  border: 1px solid rgb(76, 76, 76);

  &:hover {
    background: var(--color-primary-200);
  }

  &.active {
    border: 1px solid transparent;
    background: var(--color-primary);
    color: var(--button-text-color);
  }
}

.button-back {
  background: none;
  color: var(--body-text-color);
  padding: 10px 6px;
  justify-content: center;
  display: flex;
  align-items: center;
  box-shadow: none;
  margin: 4px;
  border-radius: 4px;
  text-transform: capitalize;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;

  &:hover {
    background: var(--color-primary-200);
  }
}

.remove-row {
  justify-content: flex-end;
  &__button {
    font-weight: 600;
    font-size: 14px;
    width: fit-content;
    margin-right: 5px;
    border-radius: 20px;
    padding: 2px 5px 2px 5px;
    color: var(--color-red-600);
    border: 1px solid var(--color-red-600);

    &:hover{
      cursor: pointer;
      background: rgba(red, 0.1);
    }
  }
}

.row {
  flex-wrap: nowrap !important;
}

.row > .label {
  display: flex;
  justify-content: end;
  align-items: start;
  width: 130px;
  margin-top: 10px;
  text-align: right;
  font-size: 0.9rem;
  font-weight: 600;

}

.row > .value {
  display: flex;
  flex-direction: row;
  flex: 1 1 auto;
  font-size: 0.9rem;
  margin-left: 20px;
}

.role-grid {
  display: grid;
  grid-gap: 10px;
  grid-template-columns: 1fr 1fr;
  grid-auto-rows: auto;
  width: 100%;
}

.role-grid-single-row {
  display: grid;
  grid-gap: 10px;
  grid-template-columns: 1fr;
  grid-auto-rows: auto;

  .label-text {
    width: 200px;
  }
}

.permission-list {
  display: flex;
  flex-direction: column !important;
  width: 100%;

  &__title {
    margin: 5px 0 5px 0;
    font-size: 1rem;
    font-weight: 600;
    color: rgba(rgb(0, 0, 0), 0.7);
  }
}

.chkbx-li {
  display: flex;
  flex-direction: column;
  align-items: start;

  &__subtitle {
    text-align: left;
    margin-left: 30px;
    font-size: 0.85rem;
    color: rgba(grey, 0.95);
  }
}

.chkbx-li +.chkbx-li {
  margin-top: 10px;
}

.label-text {
  display: flex;
  flex-direction: row;
  cursor: pointer;
  align-items: center;
  color: var(--color-black) !important;
  font-size: 0.9rem;
  font-weight: 600;
  margin-left: 2px;
  width: 100px;
}

:deep() {
  .v-label {
    opacity: 1 !important;
  }
  .v-expansion-panel:not(:first-child)::after {
    border-style: none;
  }
}

.footer {
  display: flex;
  flex-direction: row;
  padding: 0.75rem 3rem;
  justify-content: flex-end;

  button + button {
    margin-left: 10px;
  }
}

.result {
  display: flex;
  width: 100%;
  height: 100%;
  border-radius: 5px;
  font-size: 1rem;
  font-weight:600;
  padding: 10px;
  align-items: center;
  justify-content: center;

  span {
    width: 100%;
    line-break: anywhere;
  }
}

.error {
  @include themify() {
    background: rgba(182, 94, 94, 0.5) ;
    color: themed('color-error')
  }
}
</style>
