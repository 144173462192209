export const auzone_logo = ` 
<svg id="auzoneLogoWhite" width="100%" height="100%" viewBox="0 0 60 40" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve" xmlns:serif="http://www.serif.com/" style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;">
    <g>
        <path d="M23.68,5.15C23.68,6.426 24.714,7.46 25.989,7.46C27.264,7.46 28.298,6.426 28.298,5.15C28.298,3.875 27.264,2.841 25.989,2.841C24.714,2.841 23.68,3.875 23.68,5.15Z" style="fill:url(#_Radial1);fill-rule:nonzero;"/>
        <path d="M20.319,10.191C20.319,11.467 21.353,12.501 22.627,12.501C23.902,12.501 24.937,11.467 24.937,10.191C24.937,8.916 23.902,7.882 22.627,7.882C21.353,7.882 20.319,8.916 20.319,10.191Z" style="fill:url(#_Radial2);fill-rule:nonzero;"/>
        <path d="M27.04,10.191C27.04,11.467 28.074,12.501 29.349,12.501C30.624,12.501 31.659,11.467 31.659,10.191C31.659,8.916 30.624,7.882 29.349,7.882C28.074,7.882 27.04,8.916 27.04,10.191Z" style="fill:url(#_Radial3);fill-rule:nonzero;"/>
        <g transform="matrix(1.30485,0,0,1.30485,-7.003,-10.6871)">
            <path d="M25.445,37.232C25.589,37.221 25.69,37.201 25.749,37.171C25.847,37.118 25.916,37.019 25.955,36.874L26.66,34.309C26.287,34.309 26.04,34.337 25.92,34.393C25.799,34.449 25.659,34.62 25.5,34.904L25.417,34.888L25.618,34.14L28.207,34.14L27.993,34.933L27.908,34.921L27.92,34.753C27.92,34.604 27.886,34.493 27.819,34.419C27.752,34.346 27.619,34.309 27.419,34.309L27.149,34.309L26.475,36.727C26.459,36.779 26.447,36.826 26.439,36.867C26.425,36.928 26.418,36.978 26.418,37.014C26.418,37.109 26.447,37.168 26.506,37.191C26.564,37.214 26.679,37.227 26.85,37.232L26.85,37.311L25.445,37.311L25.445,37.232Z" style="fill:#F3F4F6;"/>
        </g>
        <g transform="matrix(1.30485,0,0,1.30485,-7.003,-10.6871)">
            <path d="M28.008,36.682C28.008,36.33 28.166,35.99 28.482,35.661C28.796,35.332 29.119,35.168 29.453,35.168C29.576,35.168 29.674,35.196 29.747,35.253C29.82,35.31 29.856,35.395 29.856,35.507C29.856,35.735 29.723,35.93 29.456,36.092C29.19,36.254 28.909,36.353 28.615,36.39L28.48,36.406C28.459,36.471 28.445,36.523 28.437,36.562C28.429,36.601 28.425,36.649 28.425,36.708C28.425,36.847 28.471,36.955 28.562,37.033C28.653,37.111 28.761,37.149 28.886,37.149C29.004,37.149 29.13,37.111 29.263,37.032C29.339,36.985 29.449,36.902 29.593,36.784L29.655,36.838C29.583,36.928 29.481,37.021 29.348,37.116C29.113,37.285 28.876,37.37 28.639,37.37C28.471,37.37 28.324,37.311 28.198,37.192C28.071,37.073 28.008,36.903 28.008,36.682ZM28.513,36.3C28.815,36.255 29.061,36.149 29.251,35.98C29.441,35.812 29.536,35.645 29.536,35.479C29.536,35.419 29.522,35.371 29.493,35.335C29.465,35.299 29.423,35.282 29.367,35.282C29.265,35.282 29.164,35.326 29.065,35.413C28.966,35.501 28.877,35.6 28.798,35.711C28.714,35.841 28.648,35.966 28.599,36.084C28.572,36.147 28.543,36.219 28.513,36.3Z" style="fill:#F3F4F6;"/>
        </g>
        <g transform="matrix(1.30485,0,0,1.30485,-7.003,-10.6871)">
            <path d="M31.532,35.168C31.725,35.168 31.864,35.211 31.949,35.298C32.035,35.385 32.077,35.471 32.077,35.552C32.077,35.627 32.055,35.684 32.011,35.725C31.967,35.767 31.914,35.787 31.854,35.787C31.796,35.787 31.747,35.769 31.707,35.733C31.668,35.696 31.648,35.651 31.648,35.597C31.648,35.556 31.661,35.512 31.687,35.464C31.713,35.417 31.726,35.386 31.726,35.372C31.726,35.343 31.71,35.319 31.677,35.299C31.645,35.28 31.593,35.27 31.522,35.27C31.267,35.27 31.046,35.415 30.859,35.704C30.671,35.994 30.578,36.297 30.578,36.615C30.578,36.794 30.616,36.934 30.691,37.035C30.767,37.136 30.881,37.187 31.033,37.187C31.155,37.187 31.273,37.152 31.388,37.082C31.503,37.012 31.608,36.921 31.705,36.812L31.721,36.793L31.79,36.845C31.679,36.986 31.573,37.095 31.47,37.171C31.288,37.302 31.086,37.367 30.865,37.367C30.653,37.367 30.481,37.3 30.35,37.165C30.218,37.029 30.153,36.845 30.153,36.611C30.153,36.251 30.299,35.921 30.592,35.619C30.881,35.318 31.195,35.168 31.532,35.168Z" style="fill:#F3F4F6;"/>
        </g>
        <g transform="matrix(1.30485,0,0,1.30485,-7.003,-10.6871)">
            <path d="M32.708,34.129C32.81,34.114 32.892,34.103 32.954,34.094C33.017,34.085 33.071,34.077 33.117,34.069C33.303,34.033 33.404,34.013 33.418,34.01C33.432,34.007 33.443,34.005 33.451,34.005C33.47,34.015 33.48,34.026 33.48,34.041L32.922,36.16C33.121,35.865 33.284,35.65 33.411,35.514C33.632,35.28 33.836,35.163 34.021,35.163C34.097,35.163 34.17,35.187 34.24,35.235C34.311,35.284 34.346,35.37 34.346,35.495C34.346,35.525 34.342,35.564 34.334,35.612C34.326,35.659 34.317,35.701 34.305,35.737L33.985,36.874C33.976,36.906 33.968,36.936 33.961,36.965C33.955,36.995 33.952,37.021 33.952,37.045C33.952,37.072 33.958,37.092 33.971,37.105C33.984,37.119 34.002,37.125 34.025,37.125C34.074,37.125 34.132,37.091 34.199,37.023C34.24,36.981 34.317,36.887 34.429,36.743L34.491,36.8C34.38,36.965 34.286,37.085 34.208,37.161C34.071,37.295 33.932,37.363 33.793,37.363C33.73,37.363 33.677,37.343 33.634,37.303C33.591,37.264 33.57,37.207 33.57,37.133C33.57,37.112 33.571,37.093 33.573,37.074C33.576,37.056 33.58,37.036 33.586,37.014L33.952,35.623C33.955,35.611 33.958,35.598 33.96,35.584C33.963,35.571 33.964,35.557 33.964,35.543C33.964,35.503 33.952,35.472 33.928,35.45C33.903,35.428 33.87,35.417 33.831,35.417C33.725,35.417 33.591,35.502 33.43,35.672C33.268,35.842 33.132,36.016 33.022,36.193C32.946,36.318 32.888,36.436 32.85,36.546C32.811,36.657 32.736,36.912 32.625,37.311L32.258,37.311L33.017,34.432C33.022,34.412 33.025,34.394 33.026,34.38C33.028,34.366 33.029,34.352 33.029,34.34C33.029,34.272 33.007,34.231 32.962,34.217C32.918,34.204 32.833,34.2 32.708,34.207L32.708,34.129Z" style="fill:#F3F4F6;"/>
        </g>
        <g transform="matrix(1.30485,0,0,1.30485,-7.003,-10.6871)">
            <path d="M34.687,37.311L35.098,35.844C35.133,35.721 35.153,35.646 35.159,35.62C35.164,35.594 35.167,35.566 35.167,35.536C35.167,35.49 35.154,35.454 35.128,35.428C35.102,35.402 35.051,35.388 34.975,35.388C34.956,35.388 34.937,35.39 34.919,35.392C34.901,35.394 34.88,35.397 34.858,35.4L34.858,35.32C34.961,35.304 35.034,35.292 35.078,35.284C35.121,35.276 35.168,35.267 35.219,35.256L35.644,35.163C35.653,35.169 35.66,35.176 35.663,35.184L35.347,36.205C35.54,35.909 35.702,35.689 35.831,35.545C36.059,35.29 36.263,35.163 36.443,35.163C36.513,35.163 36.573,35.176 36.624,35.203C36.72,35.256 36.769,35.35 36.769,35.486C36.769,35.524 36.765,35.563 36.757,35.604C36.749,35.646 36.738,35.69 36.726,35.737L36.415,36.874C36.409,36.898 36.402,36.926 36.394,36.961C36.386,36.995 36.382,37.019 36.382,37.033C36.382,37.057 36.387,37.078 36.398,37.097C36.409,37.116 36.428,37.125 36.453,37.125C36.494,37.125 36.542,37.101 36.595,37.053C36.649,37.005 36.737,36.902 36.859,36.743L36.928,36.805C36.798,36.981 36.695,37.104 36.619,37.175C36.489,37.3 36.365,37.363 36.246,37.363C36.193,37.363 36.141,37.345 36.091,37.309C36.041,37.275 36.016,37.209 36.016,37.116C36.016,37.089 36.018,37.061 36.022,37.032C36.026,37 36.031,36.974 36.038,36.952L36.337,35.799C36.352,35.736 36.363,35.684 36.369,35.644C36.374,35.603 36.377,35.574 36.377,35.557C36.377,35.519 36.368,35.486 36.35,35.458C36.332,35.431 36.299,35.417 36.254,35.417C36.135,35.417 35.985,35.524 35.805,35.74C35.699,35.868 35.592,36.02 35.482,36.198C35.399,36.335 35.333,36.469 35.284,36.6C35.236,36.73 35.16,36.967 35.055,37.311L34.687,37.311Z" style="fill:#F3F4F6;"/>
        </g>
        <g transform="matrix(1.30485,0,0,1.30485,-7.003,-10.6871)">
            <path d="M37.568,36.8C37.568,36.932 37.601,37.04 37.667,37.127C37.732,37.213 37.828,37.256 37.955,37.256C38.213,37.256 38.441,37.061 38.641,36.672C38.81,36.338 38.895,36.032 38.895,35.752C38.895,35.633 38.88,35.537 38.852,35.464C38.797,35.327 38.695,35.258 38.548,35.258C38.306,35.258 38.079,35.439 37.867,35.801C37.668,36.14 37.568,36.473 37.568,36.8ZM38.574,35.158C38.84,35.158 39.028,35.229 39.137,35.371C39.246,35.512 39.3,35.68 39.3,35.873C39.3,36.209 39.153,36.537 38.857,36.86C38.551,37.195 38.226,37.363 37.879,37.363C37.661,37.363 37.486,37.299 37.355,37.173C37.223,37.046 37.158,36.868 37.158,36.639C37.158,36.293 37.305,35.961 37.599,35.645C37.9,35.32 38.225,35.158 38.574,35.158Z" style="fill:#F3F4F6;"/>
        </g>
        <g transform="matrix(1.30485,0,0,1.30485,-7.003,-10.6871)">
            <path d="M40.342,34.432C40.348,34.412 40.352,34.391 40.354,34.37C40.356,34.35 40.356,34.333 40.356,34.321C40.356,34.267 40.335,34.234 40.291,34.222C40.248,34.21 40.161,34.205 40.031,34.207L40.031,34.129C40.102,34.119 40.184,34.108 40.277,34.096C40.369,34.083 40.43,34.074 40.458,34.069C40.547,34.053 40.655,34.03 40.781,33.998C40.792,33.998 40.803,34.006 40.814,34.022L40.067,36.874C40.049,36.942 40.039,36.983 40.036,36.997C40.033,37.012 40.031,37.026 40.031,37.04C40.031,37.071 40.037,37.096 40.048,37.117C40.059,37.138 40.08,37.149 40.11,37.149C40.167,37.149 40.241,37.099 40.333,36.997C40.424,36.896 40.502,36.798 40.565,36.703L40.629,36.748C40.493,36.949 40.387,37.088 40.311,37.166C40.18,37.297 40.048,37.363 39.915,37.363C39.858,37.363 39.807,37.348 39.763,37.32C39.695,37.277 39.661,37.205 39.661,37.102C39.661,37.062 39.667,37.009 39.68,36.943C39.688,36.903 39.698,36.857 39.711,36.805L40.342,34.432Z" style="fill:#F3F4F6;"/>
        </g>
        <g transform="matrix(1.30485,0,0,1.30485,-7.003,-10.6871)">
            <path d="M41.348,36.8C41.348,36.932 41.381,37.04 41.447,37.127C41.512,37.213 41.608,37.256 41.735,37.256C41.993,37.256 42.221,37.061 42.421,36.672C42.59,36.338 42.675,36.032 42.675,35.752C42.675,35.633 42.66,35.537 42.632,35.464C42.577,35.327 42.475,35.258 42.328,35.258C42.086,35.258 41.859,35.439 41.647,35.801C41.448,36.14 41.348,36.473 41.348,36.8ZM42.354,35.158C42.62,35.158 42.808,35.229 42.917,35.371C43.026,35.512 43.08,35.68 43.08,35.873C43.08,36.209 42.933,36.537 42.637,36.86C42.331,37.195 42.006,37.363 41.659,37.363C41.441,37.363 41.266,37.299 41.135,37.173C41.003,37.046 40.938,36.868 40.938,36.639C40.938,36.293 41.085,35.961 41.379,35.645C41.68,35.32 42.005,35.158 42.354,35.158Z" style="fill:#F3F4F6;"/>
        </g>
        <g transform="matrix(1.30485,0,0,1.30485,-7.003,-10.6871)">
            <path d="M44.082,36.096C44.082,36.194 44.097,36.272 44.127,36.33C44.178,36.427 44.269,36.475 44.4,36.475C44.542,36.475 44.669,36.385 44.781,36.204C44.892,36.022 44.948,35.823 44.948,35.604C44.948,35.5 44.925,35.418 44.879,35.359C44.833,35.299 44.764,35.27 44.673,35.27C44.496,35.27 44.353,35.364 44.244,35.552C44.136,35.74 44.082,35.922 44.082,36.096ZM44.165,38.212C44.353,38.212 44.509,38.167 44.634,38.077C44.758,37.987 44.82,37.879 44.82,37.754C44.82,37.659 44.787,37.579 44.723,37.515C44.688,37.48 44.632,37.443 44.554,37.403C44.489,37.371 44.38,37.326 44.225,37.267C44.071,37.207 43.982,37.178 43.958,37.178C43.917,37.178 43.837,37.233 43.719,37.343C43.6,37.452 43.541,37.577 43.541,37.716C43.541,37.927 43.632,38.072 43.814,38.151C43.909,38.192 44.026,38.212 44.165,38.212ZM43.277,37.778C43.277,37.659 43.323,37.547 43.414,37.44C43.505,37.333 43.649,37.227 43.847,37.121C43.807,37.095 43.775,37.069 43.75,37.041C43.724,37.014 43.712,36.977 43.712,36.931C43.712,36.866 43.757,36.792 43.847,36.708C43.898,36.66 43.98,36.598 44.094,36.52C43.992,36.482 43.905,36.429 43.833,36.359C43.744,36.266 43.7,36.145 43.7,35.996C43.7,35.757 43.793,35.559 43.98,35.4C44.166,35.242 44.397,35.163 44.673,35.163C44.817,35.163 44.938,35.186 45.038,35.231C45.138,35.276 45.196,35.313 45.214,35.343L45.534,35.343L45.534,35.533L45.292,35.533C45.302,35.568 45.309,35.6 45.313,35.628C45.318,35.657 45.321,35.697 45.321,35.749C45.321,36.005 45.221,36.207 45.023,36.354C44.824,36.501 44.61,36.575 44.381,36.575C44.357,36.575 44.335,36.574 44.314,36.571C44.294,36.569 44.269,36.565 44.238,36.558C44.215,36.558 44.182,36.581 44.141,36.627C44.098,36.673 44.077,36.714 44.077,36.751C44.077,36.804 44.251,36.896 44.599,37.026C44.946,37.157 45.119,37.349 45.119,37.602C45.119,37.792 45.032,37.96 44.858,38.102C44.684,38.245 44.421,38.317 44.07,38.317C43.826,38.317 43.633,38.264 43.491,38.16C43.349,38.056 43.277,37.928 43.277,37.778Z" style="fill:#F3F4F6;"/>
        </g>
        <g transform="matrix(1.30485,0,0,1.30485,-7.003,-10.6871)">
            <path d="M46.713,34.641C46.649,34.641 46.593,34.616 46.547,34.565C46.501,34.514 46.478,34.454 46.478,34.382C46.478,34.31 46.501,34.248 46.547,34.197C46.593,34.147 46.649,34.121 46.713,34.121C46.778,34.121 46.834,34.147 46.881,34.197C46.927,34.248 46.951,34.31 46.951,34.382C46.951,34.455 46.927,34.516 46.881,34.566C46.834,34.616 46.778,34.641 46.713,34.641ZM46.77,35.162C46.78,35.165 46.784,35.172 46.782,35.18L46.303,36.874C46.281,36.964 46.27,37.019 46.27,37.035C46.27,37.059 46.274,37.08 46.284,37.097C46.292,37.116 46.312,37.125 46.343,37.125C46.397,37.125 46.468,37.079 46.557,36.985C46.609,36.93 46.673,36.851 46.749,36.748L46.811,36.8L46.787,36.834C46.668,37 46.57,37.119 46.493,37.192C46.371,37.306 46.252,37.363 46.137,37.363C46.069,37.363 46.012,37.334 45.967,37.277C45.922,37.22 45.9,37.153 45.9,37.076C45.9,37.03 45.903,36.99 45.909,36.956C45.915,36.922 45.927,36.872 45.945,36.805L46.277,35.585C46.282,35.566 46.286,35.549 46.289,35.533C46.292,35.517 46.293,35.502 46.293,35.486C46.293,35.429 46.272,35.394 46.231,35.38C46.189,35.367 46.106,35.36 45.983,35.36L45.983,35.282C46.114,35.266 46.208,35.254 46.266,35.245C46.324,35.236 46.382,35.227 46.441,35.218C46.516,35.205 46.588,35.191 46.656,35.175C46.724,35.159 46.762,35.154 46.77,35.162Z" style="fill:#F3F4F6;"/>
        </g>
        <g transform="matrix(1.30485,0,0,1.30485,-7.003,-10.6871)">
            <path d="M47.171,36.682C47.171,36.33 47.33,35.99 47.646,35.661C47.959,35.332 48.283,35.168 48.616,35.168C48.74,35.168 48.838,35.196 48.911,35.253C48.984,35.31 49.02,35.395 49.02,35.507C49.02,35.735 48.887,35.93 48.62,36.092C48.353,36.254 48.073,36.353 47.779,36.39L47.644,36.406C47.623,36.471 47.609,36.523 47.601,36.562C47.593,36.601 47.589,36.649 47.589,36.708C47.589,36.847 47.635,36.955 47.725,37.033C47.816,37.111 47.924,37.149 48.049,37.149C48.168,37.149 48.294,37.111 48.427,37.032C48.503,36.985 48.613,36.902 48.756,36.784L48.818,36.838C48.747,36.928 48.645,37.021 48.512,37.116C48.276,37.285 48.04,37.37 47.803,37.37C47.635,37.37 47.488,37.311 47.361,37.192C47.235,37.073 47.171,36.903 47.171,36.682ZM47.677,36.3C47.979,36.255 48.225,36.149 48.415,35.98C48.605,35.812 48.7,35.645 48.7,35.479C48.7,35.419 48.685,35.371 48.657,35.335C48.628,35.299 48.586,35.282 48.531,35.282C48.428,35.282 48.327,35.326 48.229,35.413C48.13,35.501 48.041,35.6 47.962,35.711C47.878,35.841 47.811,35.966 47.762,36.084C47.735,36.147 47.707,36.219 47.677,36.3Z" style="fill:#F3F4F6;"/>
        </g>
        <g transform="matrix(1.30485,0,0,1.30485,-7.003,-10.6871)">
            <path d="M49.35,36.603L49.428,36.603C49.463,36.795 49.504,36.935 49.551,37.023C49.638,37.186 49.768,37.269 49.941,37.269C50.037,37.269 50.118,37.234 50.184,37.166C50.249,37.098 50.282,37.008 50.282,36.895C50.282,36.826 50.274,36.769 50.257,36.724C50.241,36.68 50.22,36.64 50.194,36.603L49.998,36.312C49.879,36.134 49.805,36.016 49.774,35.956C49.729,35.865 49.706,35.775 49.706,35.685C49.706,35.547 49.755,35.426 49.854,35.321C49.953,35.216 50.093,35.163 50.275,35.163C50.353,35.163 50.441,35.178 50.541,35.207C50.641,35.236 50.709,35.251 50.745,35.251C50.785,35.251 50.814,35.243 50.833,35.227C50.852,35.211 50.869,35.19 50.885,35.163L50.954,35.163L50.857,35.837L50.778,35.837C50.758,35.683 50.727,35.568 50.686,35.49C50.61,35.347 50.49,35.275 50.325,35.275C50.255,35.275 50.193,35.296 50.139,35.34C50.084,35.383 50.057,35.447 50.057,35.531C50.057,35.596 50.069,35.653 50.094,35.702C50.118,35.751 50.174,35.838 50.261,35.963L50.429,36.205C50.516,36.33 50.578,36.432 50.614,36.511C50.651,36.59 50.669,36.675 50.669,36.767C50.669,36.938 50.596,37.082 50.451,37.199C50.305,37.316 50.132,37.375 49.931,37.375C49.866,37.375 49.774,37.361 49.653,37.334C49.533,37.307 49.464,37.295 49.445,37.295C49.413,37.295 49.39,37.301 49.375,37.315C49.36,37.329 49.346,37.351 49.333,37.379L49.255,37.379L49.35,36.603Z" style="fill:#F3F4F6;"/>
        </g>
        <path d="M7.493,26.873L6.733,22.388L4.157,26.873L7.493,26.873ZM0.229,31.114C0.612,31.018 0.914,30.841 1.137,30.582C1.359,30.323 1.803,29.627 2.467,28.495L7.519,19.845L7.899,19.845L9.563,29.636C9.67,30.266 9.797,30.659 9.943,30.814C10.089,30.969 10.402,31.069 10.881,31.114L10.881,31.393L6.615,31.393L6.615,31.114C7.127,31.063 7.46,31.001 7.612,30.928C7.854,30.815 7.975,30.596 7.975,30.269C7.975,30.185 7.972,30.095 7.966,29.999C7.961,29.903 7.952,29.822 7.941,29.754L7.595,27.49L3.793,27.49L2.788,29.433C2.715,29.562 2.65,29.7 2.594,29.847C2.498,30.106 2.45,30.323 2.45,30.497C2.45,30.717 2.525,30.872 2.674,30.962C2.823,31.052 3.089,31.103 3.472,31.114L3.472,31.393L0.229,31.393L0.229,31.114Z" style="fill:#F3F4F6;"/>
        <path d="M13.237,25.294C13.249,25.237 13.259,25.185 13.267,25.138C13.275,25.09 13.28,25.04 13.28,24.99C13.28,24.748 13.208,24.6 13.064,24.546C12.921,24.493 12.652,24.466 12.258,24.466L12.258,24.229L13.499,23.984L14.842,23.732C14.871,23.725 14.896,23.737 14.918,23.765C14.941,23.793 14.949,23.821 14.944,23.85L14.918,23.968L13.423,29.712C13.384,29.852 13.356,29.962 13.339,30.041C13.322,30.12 13.314,30.176 13.314,30.21C13.314,30.34 13.35,30.441 13.423,30.514C13.497,30.587 13.592,30.624 13.711,30.624C14.043,30.624 14.458,30.328 14.957,29.737C15.455,29.146 15.898,28.532 16.287,27.896C16.636,27.327 16.957,26.693 17.25,25.995C17.43,25.561 17.689,24.871 18.027,23.925L19.303,23.925L17.706,29.914C17.678,30.027 17.653,30.131 17.63,30.227C17.608,30.323 17.596,30.402 17.596,30.464C17.596,30.537 17.613,30.6 17.647,30.654C17.681,30.707 17.74,30.734 17.824,30.734C17.909,30.734 17.996,30.701 18.086,30.637C18.176,30.572 18.289,30.472 18.424,30.337C18.486,30.275 18.599,30.143 18.762,29.94C18.863,29.816 19.018,29.627 19.227,29.374L19.463,29.534C19.204,29.962 18.934,30.331 18.652,30.641C18.095,31.266 17.577,31.579 17.098,31.579C16.85,31.579 16.647,31.511 16.49,31.376C16.332,31.241 16.253,31.029 16.253,30.742C16.253,30.596 16.267,30.435 16.295,30.261C16.323,30.086 16.368,29.889 16.431,29.669C16.532,29.275 16.661,28.783 16.819,28.191L17.005,27.473L16.946,27.473C16.169,28.763 15.558,29.675 15.113,30.21C14.341,31.128 13.612,31.587 12.925,31.587C12.739,31.587 12.57,31.548 12.418,31.469C12.125,31.311 11.979,31.029 11.979,30.624C11.979,30.506 11.984,30.41 11.996,30.337C12.007,30.264 12.027,30.162 12.055,30.033L13.237,25.294Z" style="fill:#F3F4F6;"/>
        <path d="M34.122,31.459L26.157,31.459L26.157,29.578L30.777,23.333L26.4,23.333L26.4,21.417L33.852,21.417L33.852,23.333L29.239,29.544L34.122,29.544L34.122,31.459Z" style="fill:#F3F4F6;"/>
        <path d="M42.565,27.676C42.565,28.921 42.225,29.902 41.543,30.62C40.862,31.337 39.899,31.695 38.654,31.695C37.417,31.695 36.456,31.337 35.771,30.62C35.085,29.902 34.742,28.921 34.742,27.676C34.742,26.417 35.085,25.431 35.771,24.719C36.456,24.006 37.417,23.65 38.654,23.65C39.895,23.65 40.857,24.008 41.54,24.725C42.223,25.442 42.565,26.426 42.565,27.676ZM40.144,27.689C40.144,27.24 40.107,26.865 40.033,26.566C39.959,26.267 39.856,26.028 39.726,25.848C39.587,25.659 39.429,25.528 39.254,25.454C39.079,25.379 38.878,25.342 38.654,25.342C38.442,25.342 38.251,25.375 38.08,25.44C37.91,25.505 37.752,25.63 37.608,25.814C37.473,25.99 37.366,26.23 37.285,26.536C37.204,26.842 37.163,27.226 37.163,27.689C37.163,28.152 37.201,28.527 37.278,28.812C37.354,29.098 37.453,29.326 37.575,29.497C37.701,29.672 37.858,29.8 38.047,29.881C38.236,29.962 38.445,30.002 38.674,30.002C38.863,30.002 39.055,29.962 39.25,29.881C39.446,29.8 39.602,29.679 39.719,29.517C39.859,29.324 39.964,29.091 40.036,28.819C40.108,28.547 40.144,28.17 40.144,27.689Z" style="fill:#F3F4F6;"/>
        <path d="M50.914,31.459L48.54,31.459L48.54,27.703C48.54,27.397 48.528,27.092 48.503,26.789C48.479,26.485 48.432,26.262 48.365,26.118C48.284,25.947 48.167,25.825 48.014,25.75C47.861,25.676 47.657,25.639 47.401,25.639C47.207,25.639 47.012,25.675 46.814,25.747C46.616,25.819 46.405,25.934 46.18,26.091L46.18,31.459L43.82,31.459L43.82,23.886L46.18,23.886L46.18,24.722C46.567,24.394 46.943,24.137 47.31,23.953C47.676,23.769 48.082,23.677 48.527,23.677C49.3,23.677 49.891,23.921 50.301,24.408C50.71,24.896 50.914,25.603 50.914,26.529L50.914,31.459Z" style="fill:#F3F4F6;"/>
        <path d="M56.539,31.662C55.109,31.662 54.013,31.32 53.251,30.636C52.489,29.953 52.108,28.98 52.108,27.716C52.108,26.48 52.463,25.493 53.173,24.756C53.884,24.018 54.877,23.65 56.154,23.65C57.314,23.65 58.187,23.962 58.771,24.587C59.355,25.212 59.648,26.107 59.648,27.271L59.648,28.121L54.475,28.121C54.498,28.472 54.572,28.765 54.698,29.001C54.824,29.237 54.99,29.425 55.197,29.564C55.399,29.704 55.635,29.804 55.905,29.864C56.175,29.925 56.469,29.955 56.788,29.955C57.063,29.955 57.332,29.925 57.598,29.864C57.863,29.804 58.106,29.724 58.326,29.625C58.519,29.539 58.698,29.447 58.862,29.348C59.026,29.249 59.16,29.162 59.263,29.085L59.513,29.085L59.513,31.034C59.32,31.111 59.143,31.182 58.983,31.247C58.824,31.312 58.605,31.378 58.326,31.446C58.07,31.513 57.805,31.566 57.533,31.604C57.261,31.642 56.93,31.662 56.539,31.662ZM57.321,26.698C57.308,26.199 57.192,25.819 56.974,25.558C56.756,25.297 56.422,25.167 55.972,25.167C55.514,25.167 55.155,25.304 54.897,25.578C54.638,25.853 54.495,26.226 54.468,26.698L57.321,26.698Z" style="fill:#F3F4F6;"/>
        <path d="M21.879,27.39C23.439,27.511 24.225,28.23 24.225,28.23L24.256,26.204L20.808,26.688L20.327,27.451C20.327,27.451 20.891,27.314 21.879,27.39Z" style="fill:#F3F4F6;"/>
        <g transform="matrix(0.613339,0,0,0.613339,-148.574,-234.227)">
            <clipPath id="_clip4">
                <path d="M294.206,389.253C303.213,387.444 310.102,387.986 311.124,391.029C312.509,395.155 302.602,402.305 288.995,407.001C275.391,411.696 262.814,412.209 261.429,408.084C260.612,405.652 263.872,402.15 269.473,398.721C261.802,403.033 257.368,407.569 258.398,410.858C260.121,416.362 276.458,416.293 294.889,410.708C313.319,405.124 326.865,396.137 325.141,390.635C324.289,387.912 319.856,386.554 313.361,386.554C308.071,386.554 301.412,387.455 294.206,389.253Z"/>
            </clipPath>
            <g clip-path="url(#_clip4)">
                <path d="M255.39,403.107L321.314,379.578L328.499,399.706L262.574,423.235L255.39,403.107Z" style="fill:url(#_Linear5);fill-rule:nonzero;"/>
            </g>
        </g>
    </g>
    <defs>
        <radialGradient id="_Radial1" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="matrix(2.78964,0,0,2.78964,25.008,4.118)"><stop offset="0" style="stop-color:white;stop-opacity:1"/><stop offset="1" style="stop-color:rgb(249,194,14);stop-opacity:1"/></radialGradient>
        <radialGradient id="_Radial2" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="matrix(2.78964,0,0,2.78964,21.647,9.159)"><stop offset="0" style="stop-color:white;stop-opacity:1"/><stop offset="1" style="stop-color:rgb(249,194,14);stop-opacity:1"/></radialGradient>
        <radialGradient id="_Radial3" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="matrix(2.78964,0,0,2.78964,28.368,9.159)"><stop offset="0" style="stop-color:white;stop-opacity:1"/><stop offset="1" style="stop-color:rgb(249,194,14);stop-opacity:1"/></radialGradient>
        <linearGradient id="_Linear5" x1="0" y1="0" x2="1" y2="0" gradientUnits="userSpaceOnUse" gradientTransform="matrix(75.9342,-27.1018,27.1018,75.9342,248.736,416.405)"><stop offset="0" style="stop-color:white;stop-opacity:1"/><stop offset="1" style="stop-color:rgb(28,10,98);stop-opacity:1"/></linearGradient>
    </defs>
</svg>
`;

export const close_icon = `
    <?xml version="1.0" encoding="UTF-8"?><!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd"><svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="100%" height="100%" viewBox="0 0 24 24"><path d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z" /></svg>
`;

export const action_icon = `
<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 40 40">
    <path d="M10.4 26.4Q9.4 26.4 8.7 25.7Q8 25 8 24Q8 23 8.7 22.3Q9.4 21.6 10.4 21.6Q11.4 21.6 12.1 22.3Q12.8 23 12.8 24Q12.8 25 12.1 25.7Q11.4 26.4 10.4 26.4ZM24 26.4Q23 26.4 22.3 25.7Q21.6 25 21.6 24Q21.6 23 22.3 22.3Q23 21.6 24 21.6Q25 21.6 25.7 22.3Q26.4 23 26.4 24Q26.4 25 25.7 25.7Q25 26.4 24 26.4ZM37.6 26.4Q36.6 26.4 35.9 25.7Q35.2 25 35.2 24Q35.2 23 35.9 22.3Q36.6 21.6 37.6 21.6Q38.6 21.6 39.3 22.3Q40 23 40 24Q40 25 39.3 25.7Q38.6 26.4 37.6 26.4Z"/>
</svg>`;

export const time_icon = `<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><path d="M0 0h24v24H0z" fill="none"/><path d="M11.99 2C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z"/><path d="M12.5 7H11v6l5.25 3.15.75-1.23-4.5-2.67z"/></svg>`;
