<template>
  <div class="stats-graph">
    <div class="header">
      <div class="text-flex">
        <span class="title">{{ name }}</span>
        <span class="subtitle">Select a column to zoom in</span>
      </div>
      <div class="icon-container">
        <IconButton
          class="refresh-icon"
          :icon="'refresh'"
          :width="20"
          :height="20"
          :type="''"
          @click.stop="chooseRange(-1)"
        />
      </div>
    </div>
    <div class="body">
      <apexchart
        ref="bboxHistogram"
        height="100%"
        type="bar"
        :options="chartOptions"
        :series="series"
        @dataPointSelection="dataPointSelection"
      />
    </div>
  </div>
</template>

<script>

import VueApexCharts from "vue3-apexcharts";
import IconButton from '@/components/IconButton.vue';

export default {
  name: 'BoundingBoxSizeHistogram',
  components: {
    'apexchart': VueApexCharts,
    IconButton,
  },
  props: {
    name: {
      type: String,
      default: '',
    },
    dataArray: {
      type: Array,
      default: () => [],
    },
  },
  emits: ['custom-range'],
  data() {
    return {
      chartOptions: {
        chart: {
          toolbar: {
            show: false,
          },
          zoom: {
            enabled: false,
          },
        },
        legend: {
          show: false,
        },
        colors: ['#48378a'],
        xaxis: {
          type: "categories",
          tickAmount: 'dataPoints',
          labels: {
            formatter(value) {
              if (typeof value === 'string') {
                const temp = value.split(" - ");
                const exp0 = parseFloat(temp[0], 3);
                const exp1 = parseFloat(temp[1], 3);
                return `${exp0} - ${exp1}`;
              }
              return value;
            },
          },
          title: {
            text: 'Size (%)',
            offsetX: 0,
            offsetY: 110,
            style: {
              color: undefined,
              fontSize: '14px',
              fontWeight: 700,
            },
          },
          tickPlacement: 'between',
        },
        dataLabels: {
          enabled: false,
        },
        states: {
          active: {
            allowMultipleDataPointsSelection: false,
          },
        },
      },
      series: [],
      zoomLimit: 1,
    };
  },
  watch: {
    dataArray: {
      handler() {
        if (this.dataArray && this.dataArray.length > 0) {
          this.updateHistogram();
        }
      },
    },
  },
  created() {
    if (this.dataArray && this.dataArray.length > 0) {
      this.updateHistogram();
    }
  },
  methods: {
    dataPointSelection(event, chartContext, config) {
      if (config.selectedDataPoints[0].length > 0 && this.labels.length > 0 && this.zoomLimit <= 3) {
        this.zoomLimit += 1;
        this.chooseRange(config.selectedDataPoints[0][0]);
      }
    },
    chooseRange(index) {
      if (index >= 0) {
        this.$emit('custom-range', this.parseRangeString(this.labels[index]));
      } else {
        this.zoomLimit = 1;
        this.$emit('custom-range', this.parseRangeString('0.0 - 1.0'));
      }
    },
    parseRangeString(str) {
      const temp = str.split(" - ");
      return [parseFloat(temp[0]), parseFloat(temp[1])];
    },
    updateHistogram() {
      const series = [];
      // This requires the first key-value pair of entry to be the label, the second to be the value at that label
      this.dataArray.forEach((datum) => {
        const singleSeries = {
          name: this.dataArray.annotation_set_name,
          data: datum.entries.map((e) => e.count),
        };
        series.push(singleSeries);
      });
      this.labels = this.dataArray[0].entries.map((e) => e.range);
      this.updateChart(series);
    },
    // update value of each column
    updateChart(series) {
      this.series = series;
      this.chartOptions.xaxis.categories = this.labels;
      this.chartOptions = {
        ...this.chartOptions,
      };
    },
  },
};

</script>

<style scoped lang="scss">
.stats-graph {
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  width: 100%;
  height: calc(100% - 20px);
  box-shadow: 0 0 10px rgba(0,0,0,0.1), 0 0 5px rgba(0,0,0,0.25);
  padding-top: 10px;
  padding-bottom: 10px;
  @include themify() {
    background: themed('card-color');
    color: themed('datasets-stats-text-color');
  }
}

.body {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  width: 100%;
}

.header {
  display: flex;
  align-items: center;
  margin-left: 15px;
  margin-right: 15px;

  .title {
    font-size: 1.3rem;
    font-weight: 700;
    min-width: 200px;
  }

  .subtitle {
    font-size: 0.9rem;
    font-weight: 600;
    min-width: 200px;
    align-items: center;
    margin-left: 15px;
  }

  .icon-container {
    display: flex;
    flex-direction: column;
    margin: 0 5px 0 5px;
  }
}

.text-flex {
  display: flex;
  flex-direction: column;
}

.refresh-icon:hover {
  @include themify() {
    color: themed('icon-color-primary');
  }
}
</style>
