<template>
  <!-- eslint-disable max-len -->
  <OnClickOutside @trigger="handleClickOutside">
    <div
      ref="container"
      class="text-expander"
      :class="{_expand: expand_text, ellipsisActive: isEllipsisActive}"
      @click.stop="handleTextClick"
    >
      <p
        ref="textEl"
        :title="text"
        class="text-expander__content"
        :class="{ellipsisActive: isEllipsisActive}"
      >
        {{ text }}
      </p>
    </div>
  </OnClickOutside>
</template>

<script>
import { OnClickOutside } from '@vueuse/components';
import { useResizeObserver } from '@vueuse/core';

export default {
  name: 'BaseTextExpander',
  components: {
    OnClickOutside,
  },
  props: {
    text: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      expand_text: false,
      isEllipsisActive: false,
    };
  },
  watch: {
    expand_text() {
      this.$nextTick(() => {
        this.isEllipsisActive = this.checkIsEllipsisActive(this.$refs.textEl);
      });
    },
  },
  mounted() {
    this.isEllipsisActive = this.checkIsEllipsisActive(this.$refs.textEl);

    useResizeObserver(this.$refs.container, () => {
      this.isEllipsisActive = this.checkIsEllipsisActive(this.$refs.textEl);
    });
  },
  methods: {
    handleTextClick() {
      if (this.isEllipsisActive) {
        this.expand_text = true;
      }
    },
    handleClickOutside() {
      this.expand_text = false;
    },
    checkIsEllipsisActive(el) {
      return (el.offsetHeight < el.scrollHeight);
    },
  },
};
</script>

<style lang="scss" scoped>
.text-expander {
  display: flex;
  flex-direction: column;
  position: relative;
  line-height: 1.2rem;
  border-radius: 4px;
  @include themify() {
    background: themed('card-color');
  }

  &.ellipsisActive:hover {
    box-shadow: 0px 2px 5px rgb(0 0 0 / 25%), 0 0 0 1px rgb(0 0 0 / 10%);
    padding: 4px;
    margin: -4px;
  }

  &__content {
    font-size: 14px;
    font-weight: 600;
    margin: 0;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    @include themify() {
      background: themed('card-color');
    }
    max-height: 2.4rem;
  }

  p {
    text-align: left;
  }

  p.ellipsisActive {
    cursor: pointer;
  }

  &._expand {
    height: 2.4rem;

    .text-expander__content {
      position: absolute;
      -webkit-line-clamp: unset;
      z-index: 2;
      top: 0;
      padding: 8px;
      margin: -8px;
      border-radius: 4px;
      box-shadow: 0px 2px 5px rgb(0 0 0 / 25%), 0 0 0 1px rgb(0 0 0 / 10%);
      max-height: fit-content;
    }
  }
}
</style>
