<template>
  <v-rect
    ref="samSelectBox"
    :config="{
      fill: 'transparent',
      stroke: 'white',
      strokeWidth: strokeWidth,
      visible: false
    }"
  />
</template>

<script>
import { debounce } from '@/assets/js/utils.js';
import useSAMCanvas from '@/composables/canvas/useSAMCanvas.js';

export default {
  name: 'SAMSelectBox',
  props: {
    stageScale: {
      type: Object,
      default: () => ({ x: 1, y: 1 }),
    },
    stageNode: {
      type: Object,
      default: () => {},
    },
    imageNode: {
      type: Object,
      default: () => {},
    },
    samBoxActive: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['sam-box-dimensions-changed'],
  setup(props, ctx) {
    const { SAMEventBus } = useSAMCanvas();
    return { SAMEventBus };
  },
  data() {
    return {
      samSelectBoxNode: null,
    };
  },
  computed: {
    boxDimensions() {
      if (this.samSelectBoxNode && this.samSelectBoxNode.getAttrs().visible) {
        return {
          x1: this.samSelectBoxNode.x(),
          y1: this.samSelectBoxNode.y(),
          x2: this.samSelectBoxNode.x() + this.samSelectBoxNode.width(),
          y2: this.samSelectBoxNode.y() + this.samSelectBoxNode.height(),
        };
      }
      return null;
    },
    debounceHandleBoxDimensionsChanged() {
      return debounce(() => { this.handleBoxDimensionsChanged(); }, 50);
    },
    strokeWidth() {
      return 2 / this.stageScale.x;
    },
  },
  watch: {
    boxDimensions() {
      this.debounceHandleBoxDimensionsChanged();
    },
  },
  mounted() {
    this.SAMEventBus.on(this.SAMEventListener);
    this.samSelectBoxNode = this.$refs.samSelectBox.getNode();
    this.setUpStageMouseEvents();
  },
  unmounted() {
    const stage = this.stageNode;
    stage.off('mousedown touchstart');
    stage.off('mousemove touchmove');
    stage.off('mouseup touchend');
  },
  methods: {
    SAMEventListener(event) {
      switch (event) {
      case 'reset':
        this.reset();
        break;
      default:
      }
    },
    handleBoxDimensionsChanged() {
      this.$emit('sam-box-dimensions-changed', this.boxDimensions);
    },
    setUpStageMouseEvents() {
      const stage = this.stageNode;
      const konvaImage = this.imageNode;
      const selectionRectangle = this.samSelectBoxNode;
      let mousedown = false;
      let x1; let y1; let x2; let y2;

      stage.on('mousedown touchstart', (e) => {
        // Do nothing if we mousedown on any shape
        // if (this.editingEnabled && !this.isAnnotating && e.target !== stage && e.target !== konvaImage) {
        //   return;
        // }

        if (!this.samBoxActive) return;

        // Only do selection if left mouse button pressed with ctrl key not held
        if (e.evt.button === 0 && !e.evt.ctrlKey) {
          this.stageNode.setAttr('draggable', false);
        } else {
          return;
        }

        e.evt.preventDefault();
        mousedown = true;
        x1 = stage.getRelativePointerPosition().x;
        y1 = stage.getRelativePointerPosition().y;
        x2 = stage.getRelativePointerPosition().x;
        y2 = stage.getRelativePointerPosition().y;

        selectionRectangle.visible(true);
        selectionRectangle.width(0);
        selectionRectangle.height(0);
      });

      stage.on('mousemove touchmove', (e) => {
        if (!this.samBoxActive) return;

        if (mousedown) {
          e.evt.preventDefault();
          x2 = stage.getRelativePointerPosition().x;
          y2 = stage.getRelativePointerPosition().y;
          selectionRectangle.setAttrs({
            x: Math.min(x1, x2),
            y: Math.min(y1, y2),
            width: Math.abs(x2 - x1),
            height: Math.abs(y2 - y1),
          });
        }
      });

      stage.on('mouseup touchend', (e) => {
        if (!this.samBoxActive) return;

        mousedown = false;
        // Allow image to be dragged again now that selection is complete
        this.stageNode.setAttr('draggable', true);
      });
    },
    reset() {
      this.samSelectBoxNode.visible(false);
      this.samSelectBoxNode.width(0);
      this.samSelectBoxNode.height(0);
    },
  },
};
</script>

<style>

</style>
