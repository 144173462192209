<template>
  <div class="app-page">
    <div class="login">
      <div v-if="mode === 'login'" class="login-form scrollbar">
        <div class="login-form__header">
          <img class="login-form__logo" src="../assets/img/dve_logo_and_primary_name.svg">
          <h1>Welcome Back.</h1>
        </div>
        <div v-if="require2fa" class="login-form__body">
          <form class="login-form__form" @submit.prevent="handleSignIn(userEmail, userPassword, authCode)">
            <label for="login_form-email">Two-Factor Authentication Code</label>
            <input
              id="login_form-email"
              v-model="authCode"
              type="text"
              placeholder="eg. 123456"
              class="box-input"
            >
            <!-- <button id="forgot-password" class="button-link">Forgot password?</button> -->
            <div class="submit-container">
              <span v-if="error" class="_error">{{ error }}</span>
              <button type="submit" class="button">Confirm Code</button>
            </div>
          </form>
        </div>
        <div v-else class="login-form__body">
          <form class="login-form__form" @submit.prevent="handleSignIn(userEmail, userPassword, '')">
            <label for="login_form-email">Username</label>
            <input
              id="login_form-email"
              v-model="userEmail"
              type="text"
              placeholder="Enter your username"
              class="box-input"
            >
            <label for="form-login_password">Password</label>
            <input
              id="form-login_password"
              v-model="userPassword"
              type="password"
              class="box-input"
              maxlength="72"
            >
            <!-- <button id="forgot-password" class="button-link">Forgot password?</button> -->
            <div class="submit-container">
              <span v-if="error" class="_error">{{ error }}</span>
              <button type="submit" class="button">Sign in</button>
            </div>
          </form>
        </div>
        <h3 v-if="isSaas === 'yes'" class="new-user">New user? <button class="button-link" @click="handleNewUserToggle">Create an account</button></h3>
        <p class="version-text">DeepView Enterprise (c) Au-Zone Technologies</p>
        <p class="version-text">Version: {{ version }} - {{ frontendVersion }}</p>
      </div>
      <div v-if="mode === 'new-user'" class="login-form scrollbar">
        <div class="login-form__header">
          <img class="login-form__logo" src="../assets/img/dve_logo_and_primary_name.svg">
          <h1>Create new account.</h1>
        </div>
        <div class="login-form__body">
          <form class="login-form__form" @submit.prevent="handleCreateAccount">
            <label class="required" for="form_create-firstname">First Name</label>
            <input
              id="form_create-firstname"
              v-model="userCreateFirstname"
              type="text"
              placeholder="Enter your name"
            >
            <label class="required" for="form_create-lastname">Last Name</label>
            <input
              id="form_create-lastname"
              v-model="userCreateLastname"
              type="text"
              placeholder="Enter your name"
            >
            <label class="required" for="form_create-username">Username</label>
            <input
              id="form_create-username"
              v-model="userCreateUsername"
              type="text"
              placeholder="Enter your preferred username"
            >
            <label class="required" for="form_create-email">Email</label>
            <input
              id="form_create-email"
              v-model="userCreateEmail"
              type="text"
              placeholder="Enter your email"
            >
            <label for="form_create-org">Organization</label>
            <input
              id="form_create-org"
              v-model="userCreateOrganization"
              type="text"
              placeholder="Enter your organization name"
            >
            <label class="required" for="form_create-password">Password</label>
            <input
              id="form_create-password"
              v-model="userCreatePassword"
              type="password"
              maxlength="72"
            >
            <label class="required" for="form_create-confirm_password">Confirm Password</label>
            <input
              id="form_create-confirm_password"
              v-model="userConfirmPassword"
              type="password"
              maxlength="72"
            >
            <div class="submit-container">
              <span v-if="error" class="_error">{{ error }}</span>
              <span v-if="successMessage" class="_success">{{ successMessage }}</span>
              <button type="submit" class="button">Create account</button>
            </div>
          </form>
        </div>
        <h3 class="new-user">Already have an account? <button class="button-link" @click="handleNewUserToggle">Sign in.</button></h3>
      </div>
      <div v-if="mode === 'change-pw'" class="login-form scrollbar">
        <div class="login-form__header">
          <img class="login-form__logo" src="../assets/img/dve_logo_and_primary_name.svg">
          <h1>Password change required.</h1>
        </div>
        <div class="login-form__body">
          <form class="login-form__form" @submit.prevent="updatePassword(userChangePassword, userChangePasswordConfirm)">
            <label for="form_create-password">Password</label>
            <input
              id="form_create-password"
              v-model="userChangePassword"
              type="password"
              maxlength="72"
            >
            <label for="form_create-confirm_password">Confirm Password</label>
            <input
              id="form_create-confirm_password"
              v-model="userChangePasswordConfirm"
              type="password"
              maxlength="72"
            >
            <div class="submit-container">
              <span v-if="error" class="_error">{{ error }}</span>
              <button type="submit" class="button">Confirm changes</button>
            </div>
          </form>
        </div>
      </div>
    </div>
    <div
      v-if="showSlideshow"
      ref="slideshow"
      class="slideshow"
    >
      <div class="slideshow__slideshow">
        <img ref="slideshowImg">
      </div>
      <div class="slideshow__description">
        <h1 ref="descriptionTitle" />
        <h3 ref="descriptionDetails" />
      </div>
    </div>
  </div>
</template>

<script>
import DatastoreConnect from '@/assets/js/DatastoreFunctions/datastore-interface';
import { parseJwt } from '@/assets/js/utils.js';

export default {
  name: 'Login',
  data() {
    return {
      dataConnect: null,
      authUsername: '',
      userEmail: '',
      userPassword: '',
      userCreateFirstname: '',
      userCreateLastname: '',
      userCreateUsername: '',
      userCreateEmail: '',
      userCreateOrganization: '',
      userCreatePassword: '',
      userConfirmPassword: '',
      userChangePassword: '',
      userChangePasswordConfirm: '',
      require2fa: false,
      authCode: '',
      slides: [
        {
          title: 'Visualization',
          details: 'Combine results from multiple object detection sources to display real-time update.',
          path: '/login-slide1.png',
        },
        {
          title: 'Statistics',
          details: 'View data over different periods of time.',
          path: '/login-slide2.png',
        }],
      slideshowInterval: null,
      mode: 'login',
      error: '',
      successMessage: '',
      showSlideshow: false,
    };
  },
  computed: {
    isSaas() {
      return this.$store.state.appVersion.isSaas;
    },
    version() {
      return this.$store.state.appVersion.version;
    },
    frontendVersion() {
      return this.$store.state.appVersion.frontendVersion;
    },
  },
  created() {
    this.dataConnect = new DatastoreConnect(this.$store.state.enterpriseServerUrl);

    if (this.$route.query.initialMode) {
      this.mode = this.$route.query.initialMode;
    }
  },
  mounted() {
    this.$store.commit('setTheme', "theme-auzone-light");

    if (this.showSlideshow) {
      this.changeSlideshow();
      this.slideshowInterval = setInterval(this.changeSlideshow, 6000);
    }
  },
  beforeUnmount() {
    clearInterval(this.slideshowInterval);
  },
  methods: {
    parseJwt,
    changeSlideshow() {
      const slide = this.slides.shift();
      this.slides.push(slide);
      this.$refs.slideshow.classList.remove("fade-in");
      this.$refs.slideshow.classList.add("fade-out");
      setTimeout(() => {
        this.$refs.slideshowImg.src = slide.path;
        this.$refs.descriptionTitle.innerHTML = slide.title;
        this.$refs.descriptionDetails.innerHTML = slide.details;
        this.$refs.slideshow.classList.remove("fade-out");
        this.$refs.slideshow.classList.add("fade-in");
      }, 500);
    },
    handleNewUserToggle() {
      if (this.mode === 'login') {
        this.mode = 'new-user';
      } else if (this.mode === 'new-user') {
        this.mode = 'login';
      }
      this.successMessage = '';
      this.error = '';
    },
    async updatePassword(pw, conf) {
      if (!pw) {
        this.error = 'Missing password';
        return;
      }

      if (!conf) {
        this.error = 'Missing password confirmation';
        return;
      }

      if (pw !== conf) {
        this.error = 'Confirm password does not match password.';
        return;
      }
      await this.dataConnect.updateUserPassword({ username: this.authUsername, password: this.userChangePassword })
        .then((resp) => {
          if (resp.result) {
            this.performUserCheckAndLogin(resp.result.username);
          }
        })
        .catch((e) => {
          this.handleSignInError(e);
        });
    },
    async handleSignIn(inputUsername, inputPassword, authCode) {
      this.successMessage = '';
      this.error = '';
      const params = {
        username: inputUsername,
        password: inputPassword,
      };

      if (authCode) {
        params.code = authCode;
      }
      const resp = await this.dataConnect.signIn(params)
        .catch((error) => {
          console.log(error);
          alert(error);
        });
      if (!resp || resp.error || !resp.result) {
        if (resp && resp.error) {
          console.error(resp.error);
          this.handleSignInError(resp.error);
          return;
        }
      }

      const authResult = resp.result;
      if (authResult.require2fa) {
        this.require2fa = true;
        return;
      }
      const token = authResult.token;

      const tokenJson = this.parseJwt(token);

      this.$store.commit('user/setPermissions', tokenJson.permissions);

      this.$store.commit('user/setToken', token);

      if ('changepassword' in authResult && authResult.changepassword) {
        this.mode = 'change-pw';
        this.authUsername = authResult.username;
      } else {
        this.performUserCheckAndLogin(authResult.username);
      }
    },
    async performUserCheckAndLogin(u) {
      const userResp = await this.dataConnect.checkForUser({
        username: u,
      });

      const userObj = { ...userResp.result };
      userObj.role = userResp.result.role;
      this.$store.commit('user/setUser', userObj);
      if (userObj.role === 'super') {
        this.$store.commit('appVersion/setAllowDebug', 'yes');
        this.$router.push({ path: '/user' });
      } else {
        this.$router.push({ path: '/projects' });
      }
    },
    handleSignInError(error) {
      if (error.code === 101) {
        this.error = error.message;
      }
    },
    async handleCreateAccount() {
      this.successMessage = '';
      this.error = '';
      // Validate password
      if (!this.userCreateFirstname) {
        this.error = "Missing first name.";
        return;
      }

      if (!this.userCreateLastname) {
        this.error = "Missing last name.";
        return;
      }

      if (!this.userCreateUsername) {
        this.error = "Missing username.";
        return;
      }

      if (!this.userCreateEmail) {
        this.error = "Missing email.";
        return;
      }

      if (!this.userCreatePassword) {
        this.error = "Missing password.";
        return;
      }

      if (!this.userConfirmPassword) {
        this.error = "Missing confirm password.";
        return;
      }

      if (this.userCreatePassword !== this.userConfirmPassword) {
        this.error = "Confirm password does not match.";
        return;
      }
      const resp = await this.dataConnect.registerNewUser({
        username: this.userCreateUsername,
        email: this.userCreateEmail,
        password: this.userCreatePassword,
        firstname: this.userCreateFirstname,
        lastname: this.userCreateLastname,
        company: this.userCreateOrganization,
      })
        .catch((error) => {
          console.log(error);
          alert(error);
        });
      if (!resp || resp.error || !resp.result) {
        if (resp && resp.error) {
          console.error(resp.error);
          this.handleSignInError(resp.error);
          return;
        }
      }

      this.successMessage = resp.result;
    },
    handleCreateAccountError(error) {
      if (error.code === 101) {
        // TODO
      }
    },
  },

};
</script>

<style lang="scss" scoped>
.app-page {
  display: flex;
  flex-direction: row;
  text-align: center;
  overflow: visible;
  height: 100dvh;
  background-color: #250e81;
  background-image: url("@/assets/img/blu-stripes.png");
}

.login {
  display: flex;
  flex-direction: column;
  flex: 1 1 100%;
  height: 100%;
  padding: 16px 100px;
  align-items: center;
  // justify-content: center;
  overflow: auto;
}

@media (max-width: $breakpoint-sm) {
  .login {
    padding: 8px;
  }
}
@media (max-width: $breakpoint-md) {
  .login {
    padding: 16px;
  }
}

.login-form {
  width: 610px;
  padding: 60px 80px 60px 80px;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-height: fit-content;
  box-shadow: 0 0 35px rgb(0 0 0 / 50%), 0 0 10px rgb(0 0 0 / 50%);
  min-height: fit-content;
  margin: auto;
  @include themify() {
    background: themed('login-form');
    color: themed('body-text-color');
  }

  &__header {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 10;
    margin-bottom: 10px;

    h1 {
      margin-top: 10px;
      text-align: center;
      font-size: 24px;
    }
  }

  &__logo {
    max-width: 100%;
    height: 160px;
    object-fit: contain;
  }

  &__body {
    display: flex;
    flex-direction: column;
    text-align: left;
    margin-bottom: 16px;
    flex: 1 1 auto;
    @include themify() {
      color: themed('body-text-color');
    }
  }

  .new-user {
    text-align: center;
    font-weight: normal;
    font-size: 16px;
    padding-top: 20px;
    margin-top: auto;
    margin-bottom: 0.5rem;
  }
}

@media (max-width: $breakpoint-sm) {
  .login-form {
    padding: 32px;
  }
}
@media (max-width: $breakpoint-md) {
  .login-form {
    width: 100%;
  }
}

.slideshow {
  flex: 1 1 100%;
  height: 100%;
  padding: 90px 0 90px 0;
  @include themify() {
    background: themed('slideshow-bg');
  }
  img {
    width: 100%;
    height: 100%;
    border-radius: 8px;
  }

  &__description {
    margin-top: 40px;
    margin-left: 40px;
    margin-right: 40px;
    word-break: keep-all;
    text-align: left;
    letter-spacing: 0.01em;
    @include themify() {
      color: themed('slideshow-text-color');
    }
  }
}

form {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;

  label {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 2px;

      &.required::after {
      content: '*';
      font-size: 14px;
      font-weight: 700;
      padding-left: 1px;
      @include themify() {
        color: themed('color-primary');
      }
    }
  }

  input {
    width: 100%;
    height: 42px;
    background: transparent;
    padding: 10px;
    border: 2px solid rgb(189, 186, 186);
    border-radius: 8px;
    color: black;
    font-size: 16px;
    outline: none;

    &:focus, &:focus-visible {
      @include themify() {
        border: 2px solid themed('color-primary');
        box-shadow:  0 0px 2px themed('color-primary-300');
      }
      outline: 2px solid transparent;
      outline-offset: 2px;
    }
  }
  input:not(:last-of-type) {
    margin-bottom: 16px;
  }

  button[type=submit] {
    letter-spacing: 0.1em;
    width: 100%;
    height: 50px;
    border-radius: 6px;
  }

  .submit-container {
    margin-top: 16px;
    span._error {
      margin: 0 0 4px 0;
      font-weight: 400;
      font-size: 16px;
      text-align: left;
      margin-bottom: 0.5rem;
      @include themify() {
        color: themed('login-error');
      }
    }

    span._success {
      margin: 0 0 4px 0;
      font-weight: 400;
      font-size: 16px;
      text-align: left;
      margin-bottom: 0.5rem;
      @include themify() {
        color: themed('login-success');
      }
    }
  }
}

#forgot-password {
  margin-top: 2px;
  align-self: flex-end;
}

.fade-in {
  transition: opacity 0.5s ease-in-out;
  opacity: 0.9;
}

.fade-out {
  transition: opacity 0.5s ease-in-out;
  opacity: 0;
}

.version-text {
  font-size: 0.85rem;
}
</style>
