<template>
  <Modal
    ref="bulkDeleteModal"
    :title="'Delete Annotations'"
    @closed="clearData"
  >
    <template #modal-main>
      <form
        id="form"
        class="form container scrollbar"
        novalidate
        @change="handleFormChange"
        @submit.prevent=""
      >
        <v-select
          v-model="selectedTypes"
          clearable
          chips
          label="Annotation Types"
          :items="selectedDisplayTypes.filter(e => e.key !== 'image')"
          item-title="display"
          item-value="key"
          multiple
          variant="outlined"
          class="mt-1"
        />
        <div class="row">
          <div class="col-12 form__control-group">
            <label class="required">Annotation Set</label>
            <select v-model="annotationSet" class="select" required>
              <option disabled :value="null">Select an Annotation Set</option>
              <option v-for="annSet in annotationSets" :key="annSet.id" :value="annSet.id">
                {{ annSet.name }}
              </option>
            </select>
          </div>
        </div>
        <div v-if="frameCount > 1" class="row mt-3">
          <div class="form__control-group">
            <label class="text">Delete Annotation in Frames</label>
            <div class="frame-input">
              <input
                v-model="lower"
                :min="min"
                :max="max"
                type="number"
                placeholder="Min"
                @change="changeSanitizer"
                @input="inputSanitizer"
              >
              <span>&#x2013;</span>
              <input
                v-model="upper"
                :min="min"
                :max="max"
                type="number"
                placeholder="Max"
                @change="changeSanitizer"
                @input="inputSanitizer"
              >
            </div>
          </div>
        </div>
        <div class="row mt-3">
          <v-checkbox
            :key="`del-all`"
            v-model="deleteAll"
            density="compact"
            :hide-details="true"
          >
            <template #label>
              <span class="delete-all-chkbox">{{ frameCount > 1 ? 'Delete for all images in sequence' : 'Delete for all images in dataset' }}</span>
            </template>
          </v-checkbox>
        </div>
      </form>
      <div v-if="message" class="row mb-1 result-container">
        <div v-if="message" class="result" :class="[error ? 'error' : 'success']">
          <span><pre>{{ message }}</pre></span>
        </div>
      </div>
    </template>
    <template #modal-footer>
      <div class="footer">
        <button
          class="button modal-action-button button-delete"
          :disabled="selectedTypes.length === 0 || !annotationSet"
          @click="handleDeleteClicked"
        >
          Delete
        </button>
      </div>
    </template>
  </Modal>
</template>

<script setup>
import {
  ref, computed, onMounted, toRefs,
} from 'vue';
import Modal from '@/components/Modal.vue';
import DatastoreConnect from '@/assets/js/DatastoreFunctions/datastore-interface';
import { storeToRefs } from 'pinia';
import useAnnotationType from '@/composables/useAnnotationType.js';
import { useViewerVisualizationsStore } from '@/stores/useViewerVisualizationsStore.js';

const props = defineProps({
  frameCount: {
    type: Number,
    default: 0,
  },
  imageObj: {
    type: Object,
    default: null,
  },
  annotationSets: {
    type: Array,
    default: () => [],
  },
});

defineExpose({ showModal });

const {
  animationImageCache,
} = storeToRefs(useViewerVisualizationsStore());

const {
  frameCount,
  imageObj,
  annotationSets,
} = toRefs(props);

const {
  selectedDisplayTypes,
} = useAnnotationType();

const emit = defineEmits(['closed', 'deleted']);
const bulkDeleteModal = ref(null);
const selectedTypes = ref([]);
const upper = ref(1);
const lower = ref(1);
const min = ref(1);
const max = ref(1);
const annotationSet = ref(null);
const message = ref('');
const error = ref(false);
const deleteAll = ref(false);

function showModal() {
  bulkDeleteModal.value.showModal();
  if (frameCount.value > 1) {
    max.value = frameCount.value;
    upper.value = frameCount.value;
  }
}

function clearData() {
  selectedTypes.value = [];
  upper.value = 1;
  lower.value = 1;
  min.value = 1;
  max.value = 1;
  annotationSet.value = null;
  message.value = '';
  error.value = false;
  deleteAll.value = false;
}

async function handleDeleteClicked() {
  const dataConnect = new DatastoreConnect();
  const params = {
    annotation_set_id: annotationSet.value,
    annotation_types: selectedTypes.value,
    sequence_id: imageObj.value.type === 'sequence' ? imageObj.value.sequence_id : null,
    image_ids: animationImageCache.value.slice(lower.value - 1, upper.value + 1).map((e) => e.imageObj?.id),
    delete_all: deleteAll.value,
  };
  await dataConnect.deleteAnnotationBulk(params)
    .then((resp) => {
      if (resp.error !== undefined) {
        message.value = resp.error;
        error.value = true;
      } else {
        message.value = "Delete in progress";
        error.value = false;
        emit('deleted');
      }
    });
}

</script>

<style lang="scss" scoped>

.form {
  padding: 0.75rem 3rem;
  min-width: 480px;
  overflow-y: scroll;
}

.filter-settings {
  display: flex;
  flex-direction: column;
  padding: 8px;
  border-radius: inherit;
  gap: 6px;
  min-width: 200px;
  overflow: auto;

  &__row {
    display: flex;
    align-items: center;
  }

}

.footer {
  display: flex;
  flex-direction: row;
  padding: 0.75rem 3rem;
  justify-content: flex-end;
}

#form {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  padding: 0.75rem 3rem;
  overflow: auto;
  position: relative;
}

.frame-input {
  display: flex;
  flex-direction: row;
  width: 100%;

  span {
    display: flex;
    align-items: center;
    font-size: 0.9rem;
    margin: 0 10px;

  }
  .text {
    width: 60%;
    font-size: 0.9rem;
  }

  input {
    margin: 5px 0;
  }
}

.delete-all-chkbox{
  color: #383c48;
  font-size: 14px;
  font-weight: 600;
}

.result {
  display: flex;
  width: 100%;
  height: 100%;
  border-radius: 5px;
  font-size: 1rem;
  font-weight:600;
  padding: 3px 10px;
  align-items: center;
  justify-content: center;
}

.result-container {
  justify-content: center;
}

.success {
  text-align: left;
  @include themify() {
    background: rgba(115, 206, 115, 0.5) ;
    color: themed('color-success');
    border: 1px solid themed('color-success');
  }
}

.error {
  @include themify() {
    background: rgba(182, 94, 94, 0.5) ;
    color: themed('color-error');
    border: 1px solid themed('color-error');
  }
}
:deep {
  .v-label {
    opacity: 1 !important;
  }
}

</style>
