<template>
  <Modal
    ref="generateTokenModal"
    :title="'Generate API Token'"
    @closed="clearData"
  >
    <!-- <template #modal-header /> -->
    <template #modal-main>
      <div class="settings container">
        <div class="row mt-3">
          <div class="col-12 settings__control-group">
            <label>Dataset</label>
            <select v-model="dataset" class="select" required>
              <option :key="'train-select-null'" disabled :value="null">Select a Dataset</option>
              <option v-for="d in datasets" :key="`select-${d.id}`" :value="d">
                {{ d.name }}
              </option>
            </select>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-12 settings__control-group align-items-start">
            <label>Annotation Set</label>
            <AnnotationSetsRadioSelect
              v-model="annSet"
              :annotationSets="dataset?.annotation_sets"
            />
          </div>
        </div>
        <div v-if="result" class="row mt-3">
          <div class="col-12 settings__control-group">
            <div class="result" :class="[err ? 'error' : 'success']">
              <span>{{ result }}</span>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template #modal-footer>
      <div class="footer">
        <button
          class="button modal-action-button"
          @click="createToken"
        >
          Generate
        </button>
      </div>
    </template>
  </Modal>
</template>

<script>
import Modal from '@/components/Modal.vue';
import AnnotationSetsRadioSelect from '@/components/DatasetComponent/AnnotationSetsRadioSelect.vue';
import DatastoreConnect from '@/assets/js/DatastoreFunctions/datastore-interface';

export default {
  name: 'GenerateTokenModal',
  components: {
    Modal,
    AnnotationSetsRadioSelect,
  },
  props: {
    modalDataset: {
      type: Object,
      default: () => null,
    },
  },
  emits: { 'token-created': null },
  data() {
    return {
      dataset: null,
      annSet: null,
      result: '',
      err: false,
      dsConnect: new DatastoreConnect(this.$store.state.enterpriseServerUrl),
    };
  },
  computed: {
    datasets() {
      return this.$store.state.datasets.datasetList;
    },
  },
  watch: {
    modalDataset() {
      if (this.modalDataset) {
        this.dataset = this.modalDataset;
      }
    },
  },
  methods: {
    showModal() {
      this.$nextTick(() => {
        this.$refs.generateTokenModal.showModal();
      });
    },
    closeModal() {
      this.clearData();
      this.$refs.generateTokenModal.closeModal();
    },
    clearData() {
      this.dataset = null;
      this.annSet = null;
      this.result = '';
    },
    async createToken() {
      this.err = false;
      if (!this.dataset) {
        this.result = "Missing dataset";
        this.err = true;
        return;
      }
      const params = {
        "dataset_id": this.dataset.id,
      };
      if (this.annSet) {
        params["annotation_set_id"] = this.annSet.id;
      }

      await this.dsConnect.generateBridgeToken(params)
        .then((data) => {
          if (data.result) {
            this.result = data.result;
          } else {
            this.err = data.error;
          }
        })
        .catch((e) => console.log(e));
    },
  },
};

</script>

<style lang="scss" scoped>
.settings {
  padding: 0.75rem 3rem;
  min-width: 420px;
}

.footer {
  display: flex;
  flex-direction: row;
  padding: 0.75rem 3rem;
  justify-content: flex-end;
}

.error {
  @include themify() {
    background: rgba(182, 94, 94, 0.5) ;
    color: themed('color-error')
  }
}

.success {
  @include themify() {
    background: rgba(154, 237, 154, 0.5) ;
    color: black
  }
}

.result {
  display: flex;
  width: 100%;
  height: 100%;
  border-radius: 5px;
  font-size: 1rem;
  font-weight:600;
  padding: 10px;
  align-items: center;
  justify-content: center;

  span {
    width: 100%;
    overflow-wrap: break-word;
  }
}

</style>
