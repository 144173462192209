<template>
  <div ref="bboxGraph" class="bbox-graph">
    <div class="header">
      <span class="title">{{ name }}</span>
      <div class="selector">
        <LabelSelector
          :key="'visualizer'"
          v-model="labelVisualizer"
          :name="'visualizer'"
          :labels="labels"
          :restore="restore"
        />
      </div>
    </div>
    <div
      ref="canvasContainer"
      class="image-canvas"
    >
      <canvas
        ref="canvas"
        class="image-canvas__canvas"
      />
    </div>
  </div>
</template>

<script>
import { useResizeObserver } from '@vueuse/core';
import LabelSelector from '@/components/DatasetComponent/DatasetStats/LabelSelector.vue';

export default {
  name: 'BoundingBoxDisplay',
  components: {
    LabelSelector,
  },
  props: {
    name: {
      type: String,
      default: '',
    },
    dataset: {
      type: Object,
      default: () => {},
    },
    annotationList: {
      type: Array,
      default: () => [],
    },
    labelList: {
      type: Array,
      default: () => [],
    },
  },
  emits: ['new-visualizer-label'],
  data() {
    return {
      // may update store
      colorMap: {},
      labelVisualizer: null,
      restore: null,
    };
  },
  computed: {
    labels() {
      const l = [...this.labelList];
      l.unshift({
        index: -1,
        name: "All Labels",
      });
      return l;
    },
  },
  watch: {
    annotationList: {
      handler(l) {
        this.updateCanvas();
      },
    },
    labelVisualizer: {
      handler(l) {
        this.$emit('new-visualizer-label', l);
      },
    },
  },
  mounted() {
    useResizeObserver(this.$refs.bboxGraph, this.debounceResize);
    this.updateCanvas();
    const restoreDS = this.$store.state.statistics.datasetID;
    if (restoreDS && restoreDS === this.dataset.id) {
      const restoreLabel = this.$store.state.statistics.labelVisualizer;
      if (restoreLabel) {
        this.restore = restoreLabel;
      } else {
        if (this.labelList.length > 0) {
          this.restore = this.labels[0];
        }
      }
    } else {
      if (this.labelList.length > 0) {
        this.restore = this.labels[0];
      }
    }
  },
  methods: {
    debounceResize() {
      return setTimeout(this.updateCanvas, 15);
    },
    updateCanvas() {
      const container = this.$refs.canvasContainer;
      const canvas = this.$refs.canvas;

      canvas.width = container.clientWidth - 40;
      canvas.height = container.clientHeight;

      const ctx = canvas.getContext("2d", { alpha: false });
      ctx.clearRect(0, 0, canvas.width, canvas.height);

      ctx.fillStyle = "white";
      ctx.fillRect(0, 0, canvas.width, canvas.height);
      if (this.annotationList && this.annotationList.length > 0) {
        const boxLineWidth = 1;
        ctx.lineWidth = boxLineWidth;

        ctx.globalAlpha = 1;
        const canvasRect = canvas.getBoundingClientRect();

        let x;
        let y;
        let width;
        let height;
        this.annotationList.forEach((anno) => {
          x = (anno.x * canvasRect.width);
          y = (anno.y * canvasRect.height);
          width = anno.w * canvasRect.width;
          height = anno.h * canvasRect.height;
          ctx.beginPath();
          ctx.strokeStyle = this.getColor(anno.label_index);
          ctx.rect(x, y, width, height);
          ctx.stroke();
        });
      }
    },
    getColor(index) {
      if (!(index in this.colorMap)) {
        if (index < 12) {
          this.colorMap[index] = this.getColorByIndex(index);
        } else {
          this.colorMap[index] = this.getColorByIndexFromScale(index);
        }
      }
      return this.colorMap[index];
    },
    getColorByIndexFromScale(index) {
      const hue = index * 137.508 + 60; // use golden angle approximation
      return `hsl(${hue},100%,50%)`;
    },
    getColorByIndex(index) {
      const colors = ['#ffff00', '#00ff00', '#ffa500', '#ff0000', '#48d1cc', '#a0522d', '#ffc0cb', '#ff1493', '#f0e68c', '#1e90ff', '#0000ff', '#00fa9a'];
      return colors[index % 12];
    },
  },
};

</script>

<style scoped lang="scss">
.bbox-graph {
  border-radius: 8px;
  width: 100%;
  height: 100%;
  box-shadow: 0 0 10px rgba(0,0,0,0.1), 0 0 5px rgba(0,0,0,0.25);
  padding-top: 10px;
  padding-bottom: 10px;
  display: flex;
  flex-direction: column;
  @include themify() {
    background: themed('card-color');
    color: themed('datasets-stats-text-color');
  }
}

.header {
  display: flex;
  align-items: center;
  margin-left: 15px;
  margin-right: 15px;

  .title {
    font-size: 1.3rem;
    font-weight: 700;
    min-width: 200px;
  }

  .selector {
    margin-left: auto;
    display: flex;
    align-items: center;
  }
}

.image-canvas {
  user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -o-user-select: none;

  position: relative;
  width: 100%;
  flex-grow: 1;
  margin-top: 10px;
  margin-bottom: 10px;

  &__canvas {
    position: absolute;
    left: 20px;
    top: 0;
    // height: 100%;
    // width: calc(100% - 40px);
  }
}
</style>
