<template>
  <FilterPopper
    :placement="'bottom-end'"
    :appendToBody="true"
    :offset="[0, 4]"
  >
    <template #default="{ menuOpen }">
      <BaseFilterButton
        :name="'Label'"
        :active="isActive"
        :tagValue="tagValue"
        :menuOpen="menuOpen"
      />
    </template>
    <template #popper>
      <div v-if="labels && Object.values(labels).length > 0" class="filter-settings scrollbar">
        <div v-for="(label, i) in labels" :key="i" class="filter-settings__row">
          <input
            :id="`locationLabel-${name}-${label.name}-${i}`"
            v-model="selectedLabel"
            type="radio"
            :name="`${label.name}-${i}`"
            :value="label"
          >
          <label :for="`${label.name}-${i}`">{{ label.name }}</label><br>
        </div>
      </div>
      <div v-else class="filter-settings">
        <span>Please define a label.</span>
      </div>
    </template>
  </FilterPopper>
</template>

<script>
import FilterPopper from '@/components/FilterPopper.vue';
import BaseFilterButton from '@/components/BaseFilterButton.vue';

export default {
  name: "LabelSelector",
  components: {
    FilterPopper,
    BaseFilterButton,
  },
  props: {
    labels: {
      type: Array,
      default: () => [],
    },
    modelValue: {
      type: Object,
      default: () => {},
    },
    restore: {
      type: Object,
      default: () => {},
    },
    name: {
      type: String,
      default: '',
    },
  },
  emits: [
    'update:modelValue',
  ],
  data() {
    return {
      selectedLabel: null,
    };
  },
  computed: {
    isActive() {
      return this.selectedLabel && Object.keys(this.selectedLabel).length > 0;
    },
    tagValue() {
      return this.selectedLabel && this.selectedLabel.name;
    },
  },
  watch: {
    labels() {
      if (this.labels.length === 0 || (this.selectedLabel && !this.labels.find((e) => e.id === this.selectedLabel.id))) {
        this.selectedLabel = null;
      }
    },
    selectedLabel() {
      this.updateFilters();
    },
    restore: {
      handler(r) {
        if (this.restore) {
          this.selectedLabel = this.restore;
        }
      },
    },
  },
  mounted() {
    if (this.restore) {
      this.selectedLabel = this.restore;
    }
  },
  methods: {
    updateFilters() {
      this.$emit('update:modelValue', this.selectedLabel);
    },
  },
};
</script>

<style lang="scss" scoped>
.filter-settings {
  display: flex;
  flex-direction: column;
  padding: 8px;
  border-radius: inherit;
  gap: 6px;
  width: fit-content;
  overflow: auto;
  min-width: 140px;
  text-wrap: nowrap;

  &__row {
    display: flex;
    align-items: center;
  }

  & input[type=checkbox] {
    margin-right: 12px;
  }

  & input[type=radio] {
    margin-right: 12px;
  }

}
</style>
