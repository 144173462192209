import { defineStore, skipHydrate } from 'pinia';
import { computed, ref } from 'vue';
import { useMediaControls, useFullscreen } from '@vueuse/core';

export const useVideoPlayerStore = defineStore('video', () => {
  // we won't expose (return) this element directly
  const videoElement = ref();
  const src = ref('');
  const {
    playing, volume, muted, currentTime, duration, buffered, togglePictureInPicture,
  } = useMediaControls(videoElement, { src });

  function loadVideo(element, newSrc) {
    videoElement.value = element;
    src.value = newSrc;
  }

  const {
    isFullscreen, enter, exit, toggle: toggleFullScreen,
  } = useFullscreen(videoElement);

  return {
    src,
    playing,
    volume,
    muted,
    currentTime,
    duration,
    buffered,

    loadVideo,
    toggleFullScreen,
    togglePictureInPicture,
  };
});
