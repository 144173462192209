<template>
  <!-- eslint-disable max-len -->
  <BaseCard
    :id="`dataset-${dataset.name}`"
    ref="card"
    :title="cardTitle"
    class="dataset-card"
    :class="{ 'dataset-selected': selected }"
    @edit-mode="(mode) => editTitle = mode"
    @apply-edit="handleApplyEdit"
    @click="handleClickEvent"
  >
    <template #header>
      <div class="icon-container">
        <IconButton
          v-if="!isParked"
          class="refresh-icon"
          :icon="'gallery_thumbnail'"
          :width="22"
          :height="22"
          :title="`Navigate to Gallery`"
          :type="''"
          @click.stop="handleGalleryClicked"
        />
        <IconButton
          v-if="!isParked"
          class="refresh-icon"
          :icon="'refresh'"
          :width="22"
          :height="22"
          :title="`Refresh ${dataset.name}`"
          :type="''"
          @click.stop="handleRefreshClicked"
        />
        <BaseMenu
          :placement="'bottom-start'"
          @closed="show_menu = false"
        >
          <IconButton
            v-model="show_menu"
            class="refresh-icon"
            :icon="'more_vert'"
            :width="22"
            :height="22"
          />
          <template #menu="{closeMenu}">
            <ul>
              <li v-if="!isParked && canWrite && !isFrozen">
                <BaseMenuButton @click.stop="handleFreezeDataset(1), closeMenu()">
                  <SVGIcon
                    :iconName="'pause_circle'"
                    :width="'22px'"
                    :height="'22px'"
                  />
                  <span>Pause Activities</span>
                </BaseMenuButton>
              </li>
              <li v-if="!isParked && canWrite && isFrozen">
                <BaseMenuButton @click.stop="handleFreezeDataset(0), closeMenu()">
                  <SVGIcon
                    :iconName="'play_circle'"
                    :width="'22px'"
                    :height="'22px'"
                  />
                  <span>Resume Activities</span>
                </BaseMenuButton>
              </li>
              <li v-if="!isParked && canWrite">
                <BaseMenuButton
                  :disabled="isFrozen"
                  @click.stop="handleEditClicked(), closeMenu()"
                >
                  <SVGIcon
                    :iconName="'edit'"
                    :width="'22px'"
                    :height="'22px'"
                  />
                  <span>Edit Dataset</span>
                </BaseMenuButton>
              </li>
              <li v-if="!isParked && (canWrite || user.role === 'admin' || user.role === 'super')">
                <BaseMenuButton
                  :disabled="isFrozen"
                  @click.stop="handleManageAccess(), closeMenu()"
                >
                  <SVGIcon
                    :iconName="'people'"
                    :width="'22px'"
                    :height="'22px'"
                  />
                  <span>Manage Access</span>
                </BaseMenuButton>
              </li>
              <li v-if="!isParked && canWrite">
                <BaseMenuButton
                  :disabled="isFrozen"
                  @click.stop="handleCopyClicked(), closeMenu()"
                >
                  <SVGIcon
                    :iconName="'copy_content'"
                    :width="'22px'"
                    :height="'22px'"
                  />
                  <span>Copy Dataset {{ dataset.name }}</span>
                </BaseMenuButton>
              </li>
              <li v-if="!isParked && canWrite">
                <BaseMenuButton
                  :disabled="isFrozen"
                  @click.stop="openUploadDataset(), closeMenu()"
                >
                  <SVGIcon
                    :iconName="'import'"
                    :width="'22px'"
                    :height="'22px'"
                  />
                  <span>Import Dataset</span>
                </BaseMenuButton>
              </li>
              <li v-if="!isParked && (canWrite || canRead)">
                <BaseMenuButton @click.stop="openExportDataset(), closeMenu()">
                  <SVGIcon
                    :iconName="'export'"
                    :width="'22px'"
                    :height="'22px'"
                  />
                  <span>Export Dataset</span>
                </BaseMenuButton>
              </li>
              <li v-if="!isParked && (canWrite || canRead)">
                <BaseMenuButton @click.stop="openAnalytics(), closeMenu()">
                  <SVGIcon
                    :iconName="'insights'"
                    :width="'22px'"
                    :height="'22px'"
                  />
                  <span>Analytics</span>
                </BaseMenuButton>
              </li>
              <li v-if="!isParked && (canWrite || canRead)">
                <BaseMenuButton @click.stop="openMap(), closeMenu()">
                  <SVGIcon
                    :iconName="'map'"
                    :width="'22px'"
                    :height="'22px'"
                  />
                  <span>View on Map</span>
                </BaseMenuButton>
              </li>
              <li v-if="!isParked && (canWrite)">
                <BaseMenuButton @click.stop="generateAPIToken(), closeMenu()">
                  <SVGIcon
                    :iconName="'token'"
                    :width="'22px'"
                    :height="'22px'"
                  />
                  <span>Generate API Token</span>
                </BaseMenuButton>
              </li>
              <li v-if="!isParked && canWrite">
                <BaseMenuButton @click.stop="parkDataset(), closeMenu()">
                  <SVGIcon
                    :iconName="'parking'"
                    :width="'22px'"
                    :height="'22px'"
                  />
                  <span>Park Dataset</span>
                </BaseMenuButton>
              </li>
              <li v-else-if="isParked && canWrite">
                <BaseMenuButton @click.stop="unparkDataset(), closeMenu()">
                  <SVGIcon
                    :iconName="'play'"
                    :width="'22px'"
                    :height="'22px'"
                  />
                  <span>Activate Dataset</span>
                </BaseMenuButton>
              </li>
              <li v-if="canWrite">
                <BaseMenuButton
                  :class="{ '_danger' : !isFrozen}"
                  :disabled="isFrozen"
                  @click.stop="deleteDataset(), closeMenu()"
                >
                  <SVGIcon
                    :iconName="'trash'"
                    :width="'22px'"
                    :height="'22px'"
                  />
                  <span>Remove Dataset</span>
                </BaseMenuButton>
              </li>
            </ul>
          </template>
        </BaseMenu>
      </div>
    </template>
    <template #body>
      <BaseTextExpander v-if="dataset.description" :text="dataset.description" class="mt-2" />
      <div class="card-detail-box-row mt-2">
        <div class="card-detail-box">
          <div class="card-detail-box__badge">
            <div class="card-detail-box__icon">
              <SVGIcon
                :iconName="'image'"
                :width="'24px'"
                :height="'24px'"
              />
            </div>
            <div class="card-detail-box__details">
              <h3 class="card-detail-box__details-title">Images</h3>
              <span class="card-detail-box__details-value" :title="dataset.numImages">{{ dataset.numImages ? dataset.numImages : '--' }}</span>
            </div>
          </div>
        </div>
        <div class="card-detail-box">
          <div class="card-detail-box__badge">
            <div class="card-detail-box__icon">
              <SVGIcon
                :iconName="'tag'"
                :width="'24px'"
                :height="'24px'"
              />
            </div>
            <div class="card-detail-box__details">
              <h3 class="card-detail-box__details-title">Labels</h3>
              <span class="card-detail-box__details-value" :title="dataset.numLabels">{{ dataset.numLabels ? dataset.numLabels : '--' }}</span>
              <div class="card-detail-box__icon-container">
                <DatasetCardLabelsPopper
                  v-if="!isParked"
                  :dataset="dataset"
                  :labels="labels"
                  :disableDelete="!canWrite"
                  :permissions="permissions"
                  @delete-label="(label) => handleDeleteLabelClicked(label)"
                  @update-label="$emit('update-label')"
                  @label-created="$emit('label-created')"
                />
                <IconButton
                  v-if="canWrite && !isParked"
                  class="create-label-icon"
                  :icon="'add'"
                  :width="20"
                  :height="20"
                  :type="''"
                  :disabled="isFrozen"
                  @click.stop="createLabel"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <template v-if="!isParked">
        <div class="card-detail-box _groups mt-2">
          <div class="card-detail-box__badge _bottom-border">
            <div class="card-detail-box__icon">
              <SVGIcon
                :iconName="'split'"
                :width="'24px'"
                :height="'24px'"
              />
            </div>
            <div class="card-detail-box__details">
              <h3 class="card-detail-box__details-title">Groups</h3>
              <span class="card-detail-box__details-value" :title="dataset.groups?.length">{{ dataset.groups ? dataset.groups.length : '--' }}</span>
              <div class="card-detail-box__icon-container">
                <IconButton
                  v-if="canWrite"
                  class="create-annset-icon"
                  :icon="'add'"
                  :width="20"
                  :height="20"
                  :type="''"
                  :disabled="isFrozen"
                  @click.stop="createGroupClicked"
                />
              </div>
            </div>
          </div>
          <div v-if="dataset.groups && dataset.groups.length > 0" class="group-li-container scrollbar">
            <GroupListItem
              v-for="(g,i) in dataset.groups"
              :key="i"
              :name="g.name"
              :count="g.count"
              @delete-group="$emit('delete-group', g)"
            />
          </div>
        </div>

        <div class="card-detail-box mt-2">
          <div class="card-detail-box__badge _bottom-border">
            <div class="card-detail-box__icon">
              <SVGIcon
                :iconName="'set'"
                :width="'24px'"
                :height="'24px'"
              />
            </div>
            <div class="card-detail-box__details">
              <h3 class="card-detail-box__details-title">Annotation Sets</h3>
              <span class="card-detail-box__details-value" :title="numAnnotationSets">{{ numAnnotationSets }}</span>
              <div class="card-detail-box__icon-container">
                <!-- <IconButton
                v-if="isOwner || isEditor || isAdmin"
                class="create-annset-icon"
                :icon="'export'"
                :width="20"
                :height="20"
                :type="''"
                :disabled="isFrozen"
                @click.stop="importAnnotationSet"
              /> -->
                <IconButton
                  v-if="canWrite"
                  class="create-annset-icon"
                  :icon="'add'"
                  :width="20"
                  :height="20"
                  :type="''"
                  :disabled="isFrozen"
                  @click.stop="createAnnotationSetClicked"
                />
              </div>
            </div>
          </div>
          <div v-if="!annotationSetsLoading" class="annset-li-container scrollbar">
            <AnnotationSetListItem
              v-for="(s,i) in internalAnnotationSets"
              :key="i"
              v-model:numImg="s.num_images"
              v-model:numAnnotations="s.num_annotations"
              :annotationSet="s"
              :isFrozen="isFrozen"
              @copy-annset="handleCopyAnnSetClicked(s)"
              @delete-annset="$emit('delete-annset', s)"
              @open-conf-matrix="$emit('open-conf-matrix', s)"
              @open-description="$emit('open-description', s)"
            />
          </div>
          <div v-else class="loading-container">
            <InlineLoader :width="'30px'" :height="'30px'" :border="'7px'" />
          </div>
        </div>
      </template>
      <template v-else>
        <div class="parked-dataset-status">
          <SVGIcon
            class="parking-icon"
            :iconName="'parking'"
            :width="'50%'"
            :height="'50%'"
          />
          <p class="date-text">Parked Date: {{ formatTime(dataset.parked_at) }}</p>
        </div>
      </template>
    </template>
  </BaseCard>
</template>
<script>
import BaseCard from '@/components/BaseCard.vue';
import BaseMenu from '@/components/BaseMenu.vue';
import BaseMenuButton from '@/components/BaseMenuButton.vue';
import BaseTextExpander from '@/components/BaseTextExpander.vue';
import AnnotationSetListItem from '@/components/DatasetComponent/DatasetManagement/AnnotationSetListItem.vue';
import GroupListItem from '@/components/DatasetComponent/DatasetManagement/GroupListItem.vue';
import DatastoreConnect from '../../../assets/js/DatastoreFunctions/datastore-interface';
import IconButton from '../../IconButton.vue';
import DatasetCardLabelsPopper from '../DatasetCardLabelsPopper.vue';
import SVGIcon from '../../SVGIcon.vue';
import InlineLoader from '../../InlineLoader.vue';

export default {
  name: 'DatasetCard',
  components: {
    IconButton,
    DatasetCardLabelsPopper,
    SVGIcon,
    InlineLoader,
    BaseCard,
    BaseMenu,
    BaseMenuButton,
    BaseTextExpander,
    AnnotationSetListItem,
    GroupListItem,
  },
  props: {
    dataset: {
      type: Object,
      default: () => {},
    },
    selected: {
      type: Boolean,
      default: false,
    },
    annotationSets: {
      type: Array,
      default: () => [],
    },
    labels: {
      type: Array,
      default: () => [],
    },
  },
  emits: [
    'delete-dataset',
    'copy-dataset',
    'copy-annset',
    'clicked',
    'create-annset',
    'delete-annset',
    'create-label',
    'deleted-label',
    'refresh',
    'update:annotation-sets',
    'update:labels',
    'open-dataset-access-management',
    'edit-dataset',
    'edit-annset',
    'open-export',
    'open-import',
    'open-upload',
    'open-downloads',
    'freeze-dataset', 'import-annset', 'create-groups', 'delete-group', 'to-gallery', 'open-analytics', 'open-map', 'generate-token', 'open-conf-matrix', 'open-description', 'park-dataset', 'unpark-dataset', 'update-label', 'label-created',
  ],
  data() {
    return {
      dsConn: new DatastoreConnect(this.$store.state.enterpriseServerUrl),
      loading: false,
      show_menu: false,
      annotationSetsLoading: false,
      editTitle: false,
      statusType: '',
      permissions: null,

    };
  },
  computed: {
    datasetNotifications() {
      return this.$store.state.notifications.datasets[this.dataset.id];
    },
    user() {
      return this.$store.state.user.user;
    },
    canWrite() {
      return this.permissions && this.permissions.dataset_write;
    },
    canRead() {
      return this.permissions && this.permissions.dataset_read;
    },
    isAdmin() {
      return this.user.role === 'super' || this.user.role === 'admin';
    },
    isFrozen() {
      return this.dataset.is_frozen;
    },
    isDebugMode() {
      return this.$store.state.debugMode;
    },
    isParked() {
      return this.dataset && this.dataset['parked_at'] !== "0001-01-01T00:00:00Z";
    },
    internalAnnotationSets: {
      get() { return this.annotationSets; },
      set(v) { this.$emit('update:annotation-sets', v); },
    },
    cardTitle() {
      if (this.dataset?.name) {
        return this.isDebugMode ? `(${this.dataset.id}) ${this.dataset.name}` : `${this.dataset.name}`;
      }
      return '';
    },
    numAnnotationSets() {
      if (this.internalAnnotationSets && this.internalAnnotationSets.length) {
        return this.internalAnnotationSets.length;
      }
      return 0;
    },
  },
  watch: {
    dataset: {
      deep: true,
      handler(d) {
        if (d && d.annotation_sets) {
          this.internalAnnotationSets = d.annotation_sets;
        }
      },
    },
  },
  mounted() {
    // this.datasetAccessList = await this.getDatasetAccessList(this.dataset.id);
    this.GetPermissionsForCurrentUser();
  },
  methods: {
    formatTime(str) {
      if (!str || str === "") {
        return "";
      } else {
        return `${new Date(str).toLocaleTimeString('default', {
          year: 'numeric', month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric', hour12: true,
        })}`;
      }
    },
    async GetPermissionsForCurrentUser() {
      const user = this.$store.state.user.user;
      await this.dsConn.getDatasetAccessForSingleUser({
        dataset_id: this.dataset.id,
        username: user.username,
      })
        .then((data) => {
          if (data.result) {
            this.permissions = data.result;
          }
        })
        .catch((e) => console.log(e));
    },
    async getDatasetAccessList(dataset_id) {
      const resp = await this.dsConn.getDatasetAccessList({
        dataset_id,
      })
        .catch((error) => {
          // TODO: handle error
          console.log(error);
        });
      if (!resp || resp.error || !resp.result) {
        // TODO: handle error
        return null;
      }
      return resp.result;
    },
    handleCopyClicked() {
      this.$emit('copy-dataset');
    },
    deleteDataset() {
      this.$emit('delete-dataset');
    },
    handleCopyAnnSetClicked(set) {
      this.$emit('copy-annset', set);
    },
    async handleDeleteLabelClicked(label) {
      this.$emit('deleted-label', label);
    },
    handleClickEvent() {
      this.$emit('clicked');
    },
    createAnnotationSetClicked() {
      this.$emit('create-annset');
    },
    createLabel() {
      this.$emit('create-label', this.dataset);
    },
    handleRefreshClicked() {
      this.$emit('refresh');
    },
    handleGalleryClicked() {
      this.$emit('to-gallery');
    },
    handleManageAccess() {
      this.$emit('open-dataset-access-management', this.dataset);
    },
    handleEditAnnset() {
      this.$emit('edit-annset');
    },
    openImportDataset() {
      this.$emit('open-import');
    },
    openUploadDataset() {
      this.$emit('open-upload');
    },
    openExportDataset() {
      this.$emit('open-export');
    },
    openAnalytics() {
      this.$store.commit('datasets/setCurrentDataset', this.dataset);
      this.$nextTick(() => {
        this.$router.push({ path: '/datasets/stats' });
      });
    },
    openMap() {
      this.$store.commit('datasets/setCurrentDataset', this.dataset);
      this.$nextTick(() => {
        this.$router.push({ path: '/datasets/map' });
      });
    },
    handleApplyEdit(name) {
      this.$emit('edit-dataset', name);
    },
    handleOpenDownloads() {
      this.$emit('open-downloads');
    },
    handleEditClicked() {
      this.$emit('edit-dataset');
    },
    handleFreezeDataset(isFrozen) {
      this.$emit('freeze-dataset', isFrozen);
    },
    importAnnotationSet() {
      this.$emit('import-annset');
    },
    createGroupClicked() {
      this.$emit('create-groups');
    },
    generateAPIToken() {
      this.$emit('generate-token');
    },
    parkDataset() {
      this.$emit('park-dataset');
    },
    unparkDataset() {
      this.$emit('unpark-dataset');
    },
  },
};
</script>

<style lang="scss" scoped>
.dataset-card {
  margin: 2px;
}

.card:not([disabled="true"]):hover {
  @include themify() {
    outline: 2px solid themed('card-border-color-hover');
  }
}
.card:not([disabled="true"]).dataset-selected {
  // margin: 3px;
  @include themify() {
    outline: 3px solid themed('card-list-color-selected-border')
  }
}

.icon-container {
  display: flex;
  align-items: center;
  margin-left: 12px;
  margin-bottom: 6px;
  button + button {
    margin-left: 10px;
  }
  @include themify() {
    color: themed('body-text-color-secondary');
  }
}

.card .create-annset-icon {
  margin-left: 4px;
  @include themify() {
    color: themed('icon-color-primary');
  }
}

.card .create-label-icon {
  @include themify() {
    color: themed('icon-color-primary');
  }
}

.card:hover .copy-icon:hover,
.card:hover .create-annset-icon:hover,
.card:hover .refresh-icon:hover,
.card:hover .downloads-icon:hover {
  @include themify() {
    color: themed('icon-color-primary');
  }
}

.annset-li-container {
  overflow-y: auto;
  font-size: 0.875rem;
  flex: 1 1;
}

.group-li-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, 140px);
  grid-auto-rows: 65px;
  width: 100%;
  gap: 5px;
  padding: 5px;
  overflow-y: auto;
  flex: 1 1 auto;
}

.loading-container {
  display: flex;
  flex: 1 1;
  align-items: center;
  justify-content: center;
}

.secondary-title-container {
  display: flex;
  margin-top: 7px;
  align-items: center;
  line-height: 1;
}

.secondary-title {
  font-weight: bold;
  @include themify() {
    font-size: 0.875rem;
    color: themed('body-text-color-secondary');
  }
}

$badge-height: 50px;
$badge-padding: 8px;
.card-detail-box-row {
  display: flex;
  flex-direction: row;
  min-height: $badge-height;
  max-height: $badge-height;
  width: 100%;
  gap: 0.5rem;
}
.card-detail-box {
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 0 1 100%;
  border: 1px solid rgba(0,0,0,0.075);
  border-radius: 4px;
  overflow: hidden;
  @include themify() {
    background: themed('color-white-600');
  }

  &__badge {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: $badge-height - 2px;
    width: 100%;
    padding: $badge-padding;

    &._bottom-border {
      border-bottom: 1px solid rgba(0, 0, 0, 0.075);
    }
  }

  &__icon {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    min-width: $badge-height - ($badge-padding * 2) - 2px;
    height: 100%;
    @include themify() {
      background: themed('color-primary');
    }

    svg {
      color: white;
    }
  }

  &__details {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    height: 100%;
    border-radius: 4px;
    margin-left: 12px;
    overflow: hidden;
  }

  &__details-title {
    line-height: 1;
    font-weight: 500;
    @include themify() {
      font-size: 0.75rem;
      color: themed('body-text-color-secondary');
    }
  }

  &__details-value {
    margin-top: auto;
    line-height: 1;
    font-weight: 600;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    @include themify() {
      font-size: 1.125rem;
      color: themed('body-text-color');
    }
  }

  &__icon-container {
    position: absolute;
    top: 4px;
    right: 4px;
    display: flex;
    align-items: center;
    gap: 4px;
    @include themify() {
      color: themed('body-text-color-secondary');
    }
  }

  &._groups {
    max-height: 130px;
    flex: 1 0 min-content;
  }
}

.parked-dataset-status {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .parking-icon {
    color: themed('icon-color-primary');
    opacity: 0.3
  }

  p {
    @include themify() {
      font-size: 0.9rem;
      color: themed('body-text-color');
    }
  }
}

</style>
