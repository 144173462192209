<template>
  <FilterPopper>
    <template #default="{ menuOpen }">
      <BaseFilterButton
        :name="'Confidence Threshold'"
        :active="isActive"
        :tagValue="tagValue"
        :menuOpen="menuOpen"
      />
    </template>
    <template #popper>
      <div class="filter-settings scrollbar">
        <SingleSlider
          v-model:sliderValue="sliderVal"
          :step="step"
          :min="min"
          :max="max"
        />
        <span>{{ sliderVal }}</span>
      </div>
      <div class="filter-footer">
        <button :disabled="!isActive" class="button-text clear-button" @click="clearSelection">Clear Selection</button>
      </div>
    </template>
  </FilterPopper>
</template>

<script>
import FilterPopper from '@/components/FilterPopper.vue';
import BaseFilterButton from '@/components/BaseFilterButton.vue';
import SingleSlider from '@/components/SingleSlider.vue';
import * as utils from '@/assets/js/utils.js';

export default {
  name: "ConfidenceThresholdFilter",
  components: {
    FilterPopper,
    BaseFilterButton,
    SingleSlider,
  },
  props: {
    modelValue: {
      type: Number,
      default: null,
    },
  },
  emits: [
    'update:modelValue',
  ],
  data() {
    return {
      min: 0,
      max: 1,
      sliderVal: 0.25,
      step: 0.01,
    };
  },
  computed: {
    isActive() {
      if (this.modelValue) {
        return this.modelValue >= 0;
      }
      return false;
    },
    tagValue() {
      if (this.modelValue) {
        return `${this.modelValue.toFixed(2)}`;
      }
      return '';
    },
  },
  watch: {
    sliderVal() {
      this.debouncedUpdateFilters();
    },
    modelValue() {
      if (this.modelValue && this.modelValue > -1) {
        this.sliderVal = this.modelValue;
      }
    },
  },
  created() {
    this.debouncedUpdateFilters = utils.debounce(this.updateFilters, 600);
  },
  mounted() {
    if (this.modelValue) {
      this.sliderVal = this.modelValue;
      this.updateFilters();
    }
  },
  methods: {
    updateFilters() {
      this.$emit('update:modelValue', this.sliderVal);
    },
    clearSelection() {
      this.sliderVal = 0.25;
    },
  },
};
</script>

<style lang="scss" scoped>
.filter-settings {
  display: flex;
  flex-direction: row;
  padding: 12px;
  border-radius: inherit;
  gap: 6px;
  min-width: 350px;
  min-height: 50px;
  justify-content: center;
  align-items: center;
  overflow: auto;

  &__row {
    display: flex;
    align-items: center;
  }

  & input[type=checkbox] {
    margin-right: 12px;
  }

  & span {
    width: 40px;
    text-align: center;
  }
}

.filter-footer {
  min-height: 38px;
  width: 100%;
  border-top: 1px solid rgba(140,140,140,0.375);
  padding: 8px;
  text-align: center;

  .clear-button {
    @include themify() {
      color: themed('color-danger');
    }

    &[disabled] {
      @include themify() {
        color: themed('color-disabled');
      }
    }
  }
}
</style>
