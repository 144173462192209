<template>
  <div class="app-page">
    <Header ref="header">
      <template #header-slot>
        <BreadcrumbNavigation />
      </template>
    </Header>
    <div class="main-page-div">
      <div class="user-router">
        <div class="user-router__container">
          <ul>
            <li @click="navigateToOrganization">
              <span>Organization</span>
            </li>
            <li class="secondary" :class="{ 'active': currentTab.includes('admin/organization/details') }" @click="navigateToOrganization">
              <SVGIcon
                :iconName="'info'"
                :width="'20px'"
                :height="'20px'"
              />
              <span>Information</span>
            </li>
            <li class="secondary" :class="{ 'active': currentTab.includes('admin/organization/editorg') }" @click="navigateToEditOrganization">
              <SVGIcon
                :iconName="'edit'"
                :width="'20px'"
                :height="'20px'"
              />
              <span>Edit Info</span>
            </li>
            <li class="secondary" :class="{ 'active': currentTab.includes('admin/organization/security') }" @click="navigateToOrganizationSecurity">
              <SVGIcon
                :iconName="'security'"
                :width="'20px'"
                :height="'20px'"
              />
              <span>Security</span>
            </li>
            <li @click="navigateToUserManager">
              <span>Users</span>
            </li>
            <li class="secondary" :class="{ 'active': currentTab.includes('admin/people/list') }" @click="navigateToUserManager">
              <SVGIcon
                :iconName="'people'"
                :width="'20px'"
                :height="'20px'"
              />
              <span>User Manager</span>
            </li>
            <li class="secondary" :class="{ 'active': currentTab.includes('admin/people/newuser') }" @click="navigateToCreatingUser">
              <SVGIcon
                :iconName="'person_add'"
                :width="'20px'"
                :height="'20px'"
              />
              <span>New User</span>
            </li>
            <!-- <li class="secondary" :class="{ 'active': currentTab.includes('admin/people/edituser') }" @click="navigateToEditUser">
              <SVGIcon
                :iconName="'edit'"
                :width="'20px'"
                :height="'20px'"
              />
              <span>Edit User</span>
            </li> -->
            <li :class="{ 'active': currentTab.includes('organization/usagelogs') }" @click="navigateToUserUsageLogs">
              <SVGIcon
                :iconName="'usage'"
                :width="'20px'"
                :height="'20px'"
              />
              <span>Usage & Billing</span>
            </li>
            <li :class="{ 'active': currentTab.includes('organization/transactions') }" @click="navigateToTransactions">
              <SVGIcon
                :iconName="'receipt'"
                :width="'20px'"
                :height="'20px'"
              />
              <span>Transactions</span>
            </li>
            <!-- <li>
                <span>Billing Address</span>
              </li>
              <li>
                <span>Usage & Payment</span>
              </li> -->
          </ul>
        </div>
      </div>
      <main class="main-display-area">
        <router-view />
      </main>
    </div>
  </div>
</template>

<script>
import DatastoreConnect from '@/assets/js/DatastoreFunctions/datastore-interface';
import SVGIcon from '@/components/SVGIcon.vue';
import Header from '@/components/Header.vue';
import BreadcrumbNavigation from '@/components/BreadcrumbNavigation.vue';

export default {
  name: 'UserProfile',
  components: {
    Header,
    BreadcrumbNavigation,
    SVGIcon,
  },
  data() {
    return {
      dsConn: new DatastoreConnect(this.$store.state.enterpriseServerUrl),
      currentTab: 'account',
    };
  },
  computed: {
    currentProject() {
      return this.$store.state.projects.currentProject;
    },
    user() {
      return this.$store.state.user.user;
    },
    isAdmin() {
      return this.user && this.user.role === 'admin';
    },
    isSuper() {
      return this.user && this.user.role === 'super';
    },
  },
  watch: {
    $route(to, from) {
      if (to && to !== '') {
        this.currentTab = to.fullPath;
      }
    },
  },
  created() {
    if (!this.isAdmin && !this.isSuper) {
      this.$router.push('/login');
    }
    if (this.$route) {
      this.currentTab = this.$route.fullPath;
    }
  },
  methods: {
    navigateToOrganization() {
      this.$router.push({ name: 'admin.organization.details' });
    },
    navigateToEditOrganization() {
      this.$router.push({ name: 'admin.organization.editorg', query: { org: this.user.organization_id } });
    },
    navigateToOrganizationSecurity() {
      this.$router.push({ name: 'admin.organization.security', query: { org: this.user.organization_id } });
    },
    navigateToUserUsageLogs() {
      this.$router.push({ name: 'admin.usagelogs', query: { org: this.user.organization_id } });
    },
    navigateToTransactions() {
      this.$router.push({ name: 'admin.transactions', query: { org: this.user.organization_id } });
    },
    navigateToUserManager() {
      this.$router.push({ name: 'admin.people.list' });
    },
    navigateToCreatingUser() {
      this.$router.push({ name: 'admin.people.newuser', query: { org: this.user.organization_id } });
    },
    navigateToEditUser() {
      this.$router.push({ name: 'admin.people.edituser' });
    },
  },
};
</script>

<style lang="scss" scoped>

.user-router {
  min-width: 250px;
  width: 250px;
  @include themify() {
    background-color: themed('background-light-gray');
  };
  padding: 30px 0px 30px 30px;

  &__container {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    height: 100%;
    padding-right: 30px;

    ul {
      list-style-type: none;
    }

    ul + ul {
      margin-top: 20px;
    }

    li {
      display: flex;
      flex-direction: row;
      margin-top: 2px;
      margin-bottom: 2px;
      font-size: 0.9rem;
      padding: 4px;
      color: $color-link;
      cursor: pointer;
      border-radius: 4px;

      span {
        margin-left: 5px;
      }

      &.secondary {
          margin-left: 15px;
      }
    }

    li:hover {
      background-color: rgba(140, 140, 140, 0.1);

    }

    li:focus-visible {
      background-color: rgba(140, 140, 140, 0.1);
    }

    li.active {
      background-color: rgba(140, 140, 140, 0.2);
      color: inherit;
    }
  }
}
</style>
