<template>
  <!-- eslint-disable max-len -->
  <div
    :id="imageObj.id"
    class="grid-item-container"
    @click="handleImageClicked"
    @mouseover="mouseHover=true"
    @mouseleave="mouseHover=false"
  >
    <div class="card-header">
      <h3 class="card-title">{{ imageObj.name ? imageObj.name : "-" }}</h3>
      <IconButton
        v-if="mouseHover"
        class="delete-icon"
        :icon="'delete'"
        :width="36"
        :height="36"
        @click.stop="handleDeleteClicked"
      />
    </div>
    <div :class="['grid-item', {'failed': imageObj.status === 'failed'}, {'import preload': imageObj.status === 'loading'}]">
      <ImageCanvas
        :imageSrc="imageSrc"
        :boxes="boxes"
      />
    </div>
    <div class="image-body">
      <div v-if="allLabelNames && allLabelNames.length > 0" data-type="labels" class="image-details-row">
        <div class="description">Labels:&nbsp;</div>
        <div class="labels">
          <template v-for="(label, i) in allLabelNames" :key="label">
            <span v-if="i !== allLabelNames.length - 1">{{ label }},&nbsp;</span>
            <span v-else>{{ label }}</span>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ImageCanvas from '@/components/ImageCanvas.vue';
import IconButton from '@/components/IconButton.vue';
import DatastoreConnect from '../../../assets/js/DatastoreFunctions/datastore-interface';
import showError from '../../../assets/js/error-message';

export default {
  name: 'GalleryGridItem',
  components: {
    ImageCanvas,
    IconButton,
  },
  props: {
    imageObj: {
      type: Object,
      default: () => {},
    },
    annotationSets: {
      type: Array,
      default: () => [],
    },
    labels: {
      type: Array,
      default: () => [],
    },
  },
  emits: [
    'image-clicked', 'image-deleted',
  ],
  data() {
    return {
      dsConn: null,
      datastoreURL: null,
      mouseHover: false,
      renderedImageDimensions: {
        width: 0,
        height: 0,
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
      },
    };
  },
  computed: {
    imageSrc() {
      if (!this.imageObj.id) {
        return '';
      }
      return `v3/enterprise/image/${this.imageObj.id}`;
    },
    allLabelIDs() {
      if (this.imageObj.annotations) {
        return [...new Set(this.imageObj.annotations.map((anno) => anno.label))].sort((a, b) => a - b);
      }
      return [];
    },
    allLabelNames() {
      if (this.allLabelIDs.length > 0 && this.labels.length > 0) {
        return this.allLabelIDs.map((id) => this.labels[id].name);
      }
      return this.allLabelIDs;
    },
    boxes() {
      if (this.imageObj.annotations) {
        return this.imageObj.annotations.map((anno) => {
          let originX = 0;
          let originY = 0;
          const annoTask = this.annotationSets.find((task) => task.id === anno.annotation_set_id);
          if (annoTask) {
            if (annoTask.origin_x) {
              originX = annoTask.origin_x;
            }
            if (annoTask.origin_y) {
              originY = annoTask.origin_y;
            }
          }
          return { ...anno, originX, originY };
        });
      }
      return [];
    },
  },
  created() {
    const protocol = window.location.protocol;
    const host = window.location.host.split(':')[0];
    this.datastoreURL = `${protocol}//${host}:10912`;
    // this.datastoreURL = 'http://ubuntuqa.office.au-zone.com:10912';
    this.dsConn = new DatastoreConnect(this.datastoreURL);
    this.enterpriseServerUrl = this.$store.state.enterpriseServerUrl;
  },
  methods: {
    showError,
    handleImageLoadError() {
      const img = this.$refs.gridItemImg;
      // Retry in 500 ms if image loading errors
      console.log('Loading gallery image failed. Retrying...');
      setTimeout(() => {
        img.src = this.imageSrc;
      }, 500);
    },
    handleImageClicked() {
      this.$emit('image-clicked', this.imageObj);
    },
    handleDeleteClicked() {
      window.api.invoke('confirm', {
        type: 'question',
        buttons: ['Yes', 'Cancel'],
        detail: 'Are you sure you want to remove this image from the current project?',
      })
        .then((result) => {
          if (result === 0) {
            this.deleteImage(this.imageObj.id);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    handleInfoClicked() {
      this.dsConn.getImageObject(this.imageObj.id)
        .then((data) => {
          window.api.send('confirm', {
            buttons: ['OK'],
            detail: `Image ID: ${data.id}\nImage Group: ${data.group}\nImage Height: ${data.height} px\nImage Width: ${data.width} px\nImage Type: ${data.mime}`,
          });
        })
        .catch((err) => showError('Failed to shuffle dataset:', err));
    },
    deleteImage(imageID) {
      // Remove image from cache
      const datastoreURL = sessionStorage.getItem('datastore');
      const img_url = `${datastoreURL}/v1/images/${imageID}`;
      const img_url_orig = `${img_url}?original=1`;
      URL.revokeObjectURL(img_url);
      URL.revokeObjectURL(img_url_orig);

      this.dsConn.deleteImage(imageID)
        .then(() => {
          this.$emit('image-deleted');
        })
        .catch((error) => {
          showError('Failed to delete image:', error);
        });
    },
    drawBox(annotation) {
      if (this.imageSrc) {
        const canvas = this.$refs.gridItemCanvas;
        const ctx = canvas.getContext("2d");

        // Box dimensions
        const x = Math.round(annotation.x * canvas.width);
        const y = Math.round(annotation.y * canvas.height);
        const width = Math.round(annotation.w * canvas.width);
        const height = Math.round(annotation.h * canvas.height);

        // Set Box styling
        const boxLineWidth = 4;
        ctx.lineWidth = boxLineWidth;
        ctx.strokeStyle = "#EFC15F";

        // Draw the bounding box
        ctx.beginPath();
        ctx.rect(x, y, width, height);
        ctx.stroke();

        // Draw the label
        // if (result.label || (result.id && result.time)) {
        //   ctx.font = "24px Arial";
        //   let currentWidth = 0;
        //   if (result.label) {
        //     currentWidth += drawLabelText(ctx, result, x, y, boxLineWidth);
        //   }
        //   if (result.score) {
        //     currentWidth += drawLabelScore(ctx, result, x + currentWidth, y, boxLineWidth);
        //   }
        // }
      }
    },
    drawBoundingBoxes() {
      const canvas = this.$refs.gridItemCanvas;
      const img = this.$refs.gridItemImg;
      const v = this.getRenderedSize(img.width, img.height, img.naturalWidth, img.naturalHeight);
      canvas.style.width = `${img.width}px`;
      canvas.style.height = `${img.height}px`;
      canvas.width = v[0];
      canvas.height = v[1];
      const context = canvas.getContext('2d');
      context.drawImage(img, 0, 0, v[0], v[1]);
      if (this.imageObj.annotations && this.imageObj.annotations.length !== 0) {
        this.imageObj.annotations.forEach((a) => {
          context.beginPath();
          const xRatio = a.x / this.imageObj.width;
          const yRatio = a.y / this.imageObj.height;
          const widthRatio = a.w / this.imageObj.width;
          const heightRatio = a.h / this.imageObj.height;
          context.rect(
            v[0] * xRatio,
            v[1] * yRatio,
            v[0] * widthRatio,
            v[1] * heightRatio,
          );
          context.strokeStyle = "blue";
          context.stroke();
        });
      }
      img.remove();
    },
    // Get dimensions of the rendered display image
    // This is not equal to the element dimensions when using 'object-fit: contain'
    getRenderedImageDimensions(image) {
      const {
        width, height, naturalWidth, naturalHeight,
      } = image;
      const naturalRatio = naturalWidth / naturalHeight;
      const elementRatio = width / height;
      const [renderedWidth, renderedHeight] = naturalRatio >= elementRatio ? [width, width / naturalRatio] : [height * naturalRatio, height];

      return {
        width: Math.round(renderedWidth),
        height: Math.round(renderedHeight),
        top: Math.round((height - renderedHeight) / 2),
        left: Math.round((width - renderedWidth) / 2),
        bottom: Math.round(((height - renderedHeight) / 2) + height),
        right: Math.round(((width - renderedWidth) / 2) + width),
      };
    },
    handleImageLoad(e) {
      const canvas = this.$refs.gridItemCanvas;
      const image = this.$refs.gridItemImg;
      if (canvas && image) {
        this.renderedImageDimensions = this.getRenderedImageDimensions(e.target);
        canvas.style.left = `${this.renderedImageDimensions.left}px`;
        canvas.style.top = `${this.renderedImageDimensions.top}px`;
        canvas.style.width = `${this.renderedImageDimensions.width}px`;
        canvas.style.height = `${this.renderedImageDimensions.height}px`;
      }

      this.imageObj.annotations.forEach((anno) => {
        this.drawBox(anno);
      });
    },
  },
};
</script>

<style lang="scss" scoped>

.grid-item-container {
  user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -o-user-select: none;

  position: relative;
  border-radius: 8px;
  cursor: pointer;
  box-shadow: 0 2px 5px 0 rgb(0 0 0 / 40%);
  margin: 1px;
  transition: transform .4s ease;
  @include themify() {
    background: themed('card-color');
    color: themed('text-color');
  }
}

.grid-item {
  position: relative;
  cursor: pointer;
  width: inherit;
  height: 150px;
  margin: 0 10px;
  @include themify() {
    background: themed('image-background-fill');
  }
}

.grid-item-container:hover {
  @include themify() {
    outline: 1px solid themed('card-list-hover-outline');
  }
}

.preload:before {
  content: "Imported";
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 10px;
  left: 10px;
  right: 10px;
  bottom: 10px;
  border: 1px dashed var(--v-primary-base);
  color: var(--v-primary-base);
}

.import:before {
  content: "Queued";
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 10px;
  left: 10px;
  right: 10px;
  bottom: 10px;
  border: 1px dashed var(--v-secondary-base);
  color: var(--v-secondaryText-base);
}

.failed:before {
  content: "Failed";
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 10px;
  left: 10px;
  right: 10px;
  bottom: 10px;
  border: 1px dashed var(--v-error-base);
  color: var(--v-secondaryText-base);
}

.delete-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 10;
  background: white;
  border: 6px solid white;
  border-radius: 100%;
  box-shadow: 0 0px 5px 0 rgb(0 0 0 / 38%);
  @include themify() {
    color: themed('icon-color-primary');
  }
}

.delete-icon:hover {
  border: 5px solid transparent;
  border-radius: 100%;
}

.image-body {
  height: fit-content;
  margin: 10px;
  font-size: 14px;
  text-align: left;
}

.card-header {
  border-bottom: none;
  padding: 10px 10px 4px 10px;
}
.card-title {
  font-size: 0.75rem;
  margin: 0px;
}

.image-details-row {
  display: flex;
  align-items: center;
}

.image-details-row  + .image-details-row {
  margin-top: 3px;
}

.description {
  font-weight: bold;
  margin: 1px 1px 1px 0;
  min-width: 50px;
}

.value {
  max-width: 100px;
  margin: 1px 1px 1px 5px;
}

.multi-row {
  display: block;
}
</style>
