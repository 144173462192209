<template>
  <div v-if="show" id="annotationToolRibbon" class="ribbon">
    <div class="ribbon__group">
      <template v-if="imageObj">
        <SVGIcon
          id="downloadImageBtn"
          class="ribbon__button"
          aria-label="Download image"
          :iconName="'download'"
          :showHover="false"
          :type="''"
          :title="'Download image'"
          :width="32"
          :height="32"
          @click="downloadImage"
        />
        <hr class="ribbon__vr">
      </template>
      <span>{{ zoom }}%</span>
      <IconButton
        id="zoomToFitBtn"
        class="ribbon__button"
        aria-label="Zoom To Fit (Space)"
        :icon="'zoom_to_extents'"
        :showHover="false"
        :type="''"
        :title="'Zoom To Fit (Space)'"
        @click="handleZoomToFit"
      />
      <IconButton
        id="zoomToSelectionBtn"
        class="ribbon__button"
        aria-label="Zoom To Selection (Selection + Space)"
        :disabled="!hasSelection"
        :icon="'zoom_to_selection'"
        :showHover="false"
        :type="''"
        :title="'Zoom To Selection (Selection + Space)'"
        @click="handleZoomToSelection"
      />
    </div>
  </div>
</template>

<script>
import IconButton from '@/components/IconButton.vue';
import SVGIcon from '@/components/SVGIcon.vue';

export default {
  name: 'AnnotationToolViewingModeControls',
  components: {
    IconButton,
    SVGIcon,
  },
  props: {
    show: {
      type: Boolean,
      default: true,
    },
    zoom: {
      type: Number,
      default: 100,
    },
    hasSelection: {
      type: Boolean,
      default: false,
    },
    labels: {
      type: Array,
      default: () => [],
    },
    annotationSets: {
      type: Array,
      default: () => [],
    },
    annotationDisplaySettings: {
      type: Object,
      default: null,
    },
    imageObj: {
      type: Object,
      default: null,
    },
  },
  emits: [
    'zoom-to-fit',
    'zoom-to-selection',
    'show-image-info',
    'show-annotation-info', 'show-image-metadata',
  ],
  data() {
    return {
      internalAnnotationDisplaySettings: null,
    };
  },
  beforeMount() {
    if (this.annotationDisplaySettings) {
      this.internalAnnotationDisplaySettings = this.annotationDisplaySettings;
    }
  },
  methods: {
    handleZoomToFit() {
      this.$emit('zoom-to-fit');
    },
    handleZoomToSelection() {
      this.$emit('zoom-to-selection');
    },
    handleShowImageInfo() {
      this.$emit('show-image-info');
    },
    handleShowAnnotationInfo() {
      this.$emit('show-annotation-info');
    },
    handleShowImageMetadata() {
      this.$emit('show-image-metadata');
    },
    async downloadImage() {
      const url = `v3/enterprise/image/${this.imageObj.id}`;
      const headers = new Headers({
        'Authorization': `Bearer ${this.$store.state.user.token}`,
      });

      try {
        const response = await fetch(url, { headers });
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const blob = await response.blob();
        const blobUrl = window.URL.createObjectURL(blob);

        const link = document.createElement('a');
        link.href = blobUrl;
        link.download = this.imageObj.name || this.imageObj.id || 'download';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(blobUrl);
      } catch (error) {
        console.error('Error downloading the image:', error);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.ribbon {
  position: absolute;
  top: 8px;
  right: 8px;
  display: flex;
  flex-direction: row;
  width: fit-content;
  height: 40px;
  padding: 2px 16px;
  justify-content: space-between;
  font-weight: 500;
  box-shadow: 0 0 3px gray, 0 0 10px gray;
  border-radius: 4px;
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  z-index: 9;
  @include themify() {
    color: themed('ribbon-icons-color');
    background: themed('ribbon-bg-transparent');
  }

  &__group {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
  }

  &__vr {
    display: flex;
    width: 1px;
    height: 60%;
    margin: 0;
  }

  &__button {
    display: flex;
    min-width: 22px;
    height: 22px;
    color: inherit;
    background: none;
    border: none;
    font-size: 0.875rem;
    line-height: 1rem;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: .05em;
    align-items: center;
    cursor: pointer;

    &:hover:not(:disabled), &.active:not(:disabled) {
      @include themify() {
        box-shadow: 0 0 0 4px themed('icon-hover-color');
        background-color: themed('icon-hover-color');
      }
      border-radius: 4px;
    }

    &:disabled {
      cursor: default;
      @include themify() {
        color: themed('color-disabled');
      }
    }
  }
}

:deep(.filter-button) {
  height: 22px;
}

</style>
