<template>
  <Modal
    ref="createLabelsModal"
    :title="'Create New Labels'"
    @closed="clearData"
  >
    <!-- <template #modal-header /> -->
    <template #modal-main>
      <div class="settings container">
        <div class="row">
          <div class="col-12 settings__control-group">
            <label>Labels</label>
            <textarea
              ref="labels"
              v-model="labels"
              placeholder="Comma-separated values, eg. label1,label2,etc."
              rows="4"
              cols="50"
            />
          </div>
        </div>
      </div>
    </template>
    <template #modal-footer>
      <div class="footer">
        <button
          class="button modal-action-button"
          @click="createLabels"
        >
          Create Labels
        </button>
      </div>
    </template>
  </Modal>
</template>

<script>
import DatastoreConnect from '@/assets/js/DatastoreFunctions/datastore-interface';
import Modal from '@/components/Modal.vue';

export default {
  name: 'CreateLabelsModal',
  components: {
    Modal,
  },
  props: {
    dataset: {
      type: Object,
      default: null,
    },
  },
  emits: { 'label-created': null },
  data() {
    return {
      labels: '',
    };
  },
  created() {
    this.dataConnect = new DatastoreConnect(this.$store.state.enterpriseServerUrl);
  },
  methods: {
    showModal() {
      this.$refs.createLabelsModal.showModal();
      this.$nextTick(() => {
        this.$refs.labels.focus();
      });
    },
    clearData() {
      this.labels = '';
    },
    closeModal() {
      this.labels = '';
      this.$refs.createLabelsModal.closeModal();
    },
    isJSON(str) {
      try {
        JSON.parse(str);
        return true;
      } catch (e) {
        return false;
      }
    },
    parseInput(input) {
      if (this.isJSON(input)) {
        return JSON.parse(input);
      } else {
        return input; // or you can return input.split(",") if you expect comma-separated strings
      }
    },
    async createLabels() {
      if (!this.labels) {
        console.error('Missing labels');
        return;
      }
      let l = this.parseInput(this.labels);
      if (typeof l === 'object') {
        await this.dataConnect.addLabel2({
          dataset_id: this.dataset.id,
          labels: l,
        })
          .then((data) => {
            this.closeModal();
            this.$emit('label-created');
          })
          .catch((error) => {
          // TODO: handle error
            console.log(error);
          });
      } else if (typeof l === 'string') {
        l = l.split(",");
        await this.dataConnect.addLabel({
          dataset_id: this.dataset.id,
          label_names: l,
        })
          .then((data) => {
            this.closeModal();
            this.$emit('label-created');
          })
          .catch((error) => {
          // TODO: handle error
            console.log(error);
          });
      }
    },
  },
};

</script>

<style lang="scss" scoped>
.settings {
  padding: 0.75rem 3rem;
  min-width: 420px;
}

.footer {
  display: flex;
  flex-direction: row;
  padding: 0.75rem 3rem;
  justify-content: flex-end;
}

</style>
