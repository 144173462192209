<template>
  <div class="stats-graph">
    <div class="header">
      <span class="title">{{ name }}</span>
    </div>
    <div class="body">
      <apexchart
        ref="confHistogram"
        height="100%"
        type="bar"
        :options="chartOptions"
        :series="series"
      />
    </div>
  </div>
</template>

<script>

import VueApexCharts from "vue3-apexcharts";

export default {
  name: 'HistogramData',
  components: {
    'apexchart': VueApexCharts,
  },
  props: {
    name: {
      type: String,
      default: '',
    },
    dataArray: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      chartOptions: {
        chart: {
          toolbar: {
            show: false,
          },
        },
        xaxis: {
          tickAmount: 10,
          labels: {
            show: true,
            rotate: 0,
            rotateAlways: true,
            offsetX: 10,
            offsetY: 10,
          },
          tickPlacement: 'between',
        },
        dataLabels: {
          enabled: false,
        },
        tooltip: {
          fillSeriesColor: false,
        },
        legend: {
          show: true,
          showForSingleSeries: true,
          position: 'right',
        },
      },
      series: [],
      labels: ['0.0 - 0.1', '0.1 - 0.2', '0.2 - 0.3', '0.3 - 0.4', '0.4 - 0.5', '0.5 - 0.6', '0.6 - 0.7', '0.7 - 0.8', '0.8 - 0.9', '0.9 - 1.0'],
      componentIsActive: false,
    };
  },
  watch: {
    dataArray: {
      handler() {
        if (this.dataArray && this.dataArray.length > 0) {
          this.updateHistogram();
        }
      },
    },
  },
  created() {
    if (this.dataArray && this.dataArray.length > 0) {
      this.updateHistogram();
    }
  },
  methods: {
    updateHistogram() {
      const series = [];
      // This requires the first key-value pair of entry to be the label, the second to be the value at that label
      this.dataArray.forEach((datum, index) => {
        const singleSeries = {
          name: datum.label_name,
          data: datum.entries.map((e) => e.count),
        };
        series.push(singleSeries);
      });
      this.updateChart(series);
    },
    // update value of each column
    updateChart(series) {
      this.series = series;
      this.chartOptions = {
        ...this.chartOptions,
        ...{
          labels: this.labels,
          colors: this.getColors(),
        },
      };
    },

    getColors() {
      const colors = {};
      this.dataArray.forEach((label) => {
        if (label.label_index < 12) {
          colors[label.label_name] = this.getColorByIndex(label.label_index);
        } else {
          colors[label.label_name] = this.getColorByIndexFromScale(label.label_index);
        }
      });
      return Object.values(colors);
    },
    getColorByIndexFromScale(index) {
      const hue = index * 137.508 + 60; // use golden angle approximation
      return `hsl(${hue},100%,50%)`;
    },
    getColorByIndex(index) {
      const colors = ['#ffff00', '#00ff00', '#ffa500', '#ff0000', '#48d1cc', '#a0522d', '#ffc0cb', '#ff1493', '#f0e68c', '#1e90ff', '#0000ff', '#00fa9a'];
      return colors[index % 12];
    },
  },
};

</script>

<style scoped lang="scss">
.stats-graph {
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  width: 100%;
  height: calc(100% - 20px);
  box-shadow: 0 0 10px rgba(0,0,0,0.1), 0 0 5px rgba(0,0,0,0.25);
  padding-top: 10px;
  padding-bottom: 10px;
  @include themify() {
    background: themed('card-color');
    color: themed('datasets-stats-text-color');
  }
}

.body {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  width: 100%;
}

.header {
  display: flex;
  align-items: center;
  margin-left: 15px;
  margin-right: 15px;

  .title {
    font-size: 1.3rem;
    font-weight: 700;
    min-width: 200px;
  }

  .toggle-btn {
    width: fit-content;
    margin-left: auto;
    height: fit-content;

    button {
      width: 50px !important;
    }
  }
}
</style>
