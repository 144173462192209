<template>
  <div ref="filters" class="filters" :class="{'_collapse': collapsible && collapse}">
    <!-- <template> -->
    <div class="filters__active-and-default-filters scrollbar">
      <component
        :is="filter.name"
        v-for="filter in filterList"
        :key="filter.key"
        v-model="filters[filter.key]"
        v-bind="filterProps[filter.key]"
      />
      <button v-if="showCopyFilteredImagesBtn" class="button filter-button" @click="$emit('open-copy-filters')">
        <span>Copy Filtered Images</span>
      </button>
      <button
        v-if="showDeleteFilteredImagesBtn && hasFilters"
        class="button delete-button"
        @click="$emit('delete-filtered-images')"
      >
        <span>Delete Filtered Images</span>
      </button>
    </div>
    <IconButton
      v-if="collapsible"
      id="expand_less"
      :icon="'expand_less'"
      :width="'20px'"
      :height="'20px'"
      :type="''"
      :showHover="false"
      @click="collapse = !collapse"
    />
  </div>
</template>

<script>
import CameraSourceFilter from '@/components/DatasetComponent/DatasetFilters/CameraSourceFilter.vue';
import GroupsFilter from '@/components/DatasetComponent/DatasetFilters/GroupsFilter.vue';
import BoundingBoxSizeFilter from '@/components/DatasetComponent/DatasetFilters/BoundingBoxSizeFilter.vue';
import ConfidenceRangeFilter from '@/components/DatasetComponent/DatasetFilters/ConfidenceRangeFilter.vue';
import AnnotationLabelFilter from '@/components/DatasetComponent/DatasetFilters/AnnotationLabelFilter.vue';
import AnnotationTypeFilter from '@/components/DatasetComponent/DatasetFilters/AnnotationTypeFilter.vue';
import TimeRangeFilter from '@/components/DatasetComponent/DatasetFilters/TimeRangeFilter.vue';
import DateRangeFilter from '@/components/DatasetComponent/DatasetFilters/DateRangeFilter.vue';
import ImageReviewStatusFilter from '@/components/DatasetComponent/DatasetFilters/ImageReviewStatusFilter.vue';
import ImageNameFilter from '@/components/DatasetComponent/DatasetFilters/ImageNameFilter.vue';
import MoreFilters from '@/components/DatasetComponent/DatasetFilters/MoreFilters.vue';
import IconButton from '@/components/IconButton.vue';
import { computed, toRaw } from 'vue';
import useGallery from '@/composables/annotationTool/useGallery.js';
import AnnotationSetFilter from '@/components/DatasetComponent/DatasetFilters/AnnotationSetFilter.vue';
import ImagesWithAnnotationSetFilter from '@/components/DatasetComponent/DatasetFilters/ImagesWithAnnotationSetFilter.vue';

export default {
  name: "DatasetFilter",
  components: {
    CameraSourceFilter,
    ConfidenceRangeFilter,
    AnnotationSetFilter,
    ImagesWithAnnotationSetFilter,
    AnnotationLabelFilter,
    AnnotationTypeFilter,
    TimeRangeFilter,
    DateRangeFilter,
    ImageReviewStatusFilter,
    ImageNameFilter,
    MoreFilters,
    IconButton,
    GroupsFilter,
    BoundingBoxSizeFilter,
  },
  provide() {
    return {
      teleport: computed(() => this.teleport),
    };
  },
  props: {
    collapsible: {
      type: Boolean,
      default: false,
    },
    showCopyFilteredImagesBtn: {
      type: Boolean,
      default: false,
    },
    showDeleteFilteredImagesBtn: {
      type: Boolean,
      default: false,
    },
    annotationSets: {
      type: Array,
      default: () => [],
    },
    labels: {
      type: Array,
      default: () => [],
    },
    types: {
      type: Array,
      default: () => [],
    },
    dataset: {
      type: Object,
      default: () => {},
    },
    modelValue: {
      type: Object,
      default: () => {},
    },
    filterList: {
      type: Array,
      default: () => [
        { name: 'AnnotationSetFilter', key: 'annotationSetFilter' },
        // { name: 'ImagesWithAnnotationSetFilter', key: 'imagesWithAnnotationSetFilter' },
        { name: 'AnnotationLabelFilter', key: 'annotationLabelFilter' },
        { name: 'AnnotationTypeFilter', key: 'annotationTypeFilter' },
        { name: 'ConfidenceRangeFilter', key: 'confidenceRangeFilter' },
        { name: 'TimeRangeFilter', key: 'timeRangeFilter' },
        { name: 'DateRangeFilter', key: 'dateRangeFilter' },
        { name: 'CameraSourceFilter', key: 'cameraSourceFilter' },
        { name: 'GroupsFilter', key: 'groupsFilter' },
        { name: 'BoundingBoxSizeFilter', key: 'boundingBoxSizeFilter' },
        { name: 'ImageNameFilter', key: 'imageNameFilter' },
      ],
    },
    additionalFilterList: {
      type: Array,
      default: () => [
        { name: 'AnnotationSetFilter', key: 'annotationSetFilter' },
      ],
    },
  },
  emits: [
    'update:modelValue', 'open-copy-filters', 'delete-filtered-images',
  ],
  setup() {
    const {
      datasetFilters,
    } = useGallery();

    return {
      datasetFilters,
    };
  },
  data() {
    return {
      showFilters: false,
      filters: {},
      collapse: false,
      teleport: null,
    };
  },
  computed: {
    filterProps() {
      return {
        annotationSetFilter: { annotationSets: this.annotationSets },
        imagesWithAnnotationSetFilter: { annotationSets: this.annotationSets },
        annotationLabelFilter: { labels: this.labels },
        annotationTypeFilter: { dataset: this.dataset },
        groupsFilter: { dataset: this.dataset },
        cameraSourceFilter: { dataset: this.dataset },
        dateRangeFilter: { dataset: this.dataset },
      };
    },
    filterValue() {
      const filterKeys = Object.keys(this.filters);
      return filterKeys
        .filter((key) => this.filters[key] !== null && this.filters[key].length !== 0)
        .reduce((result, key) => {
          result[key] = this.filters[key];
          return result;
        }, {});
    },
    hasFilters() {
      return this.filterValue && Object.keys(this.filterValue).length > 0;
    },
  },
  watch: {
    filterValue: {
      deep: true,
      handler() {
        this.$emit('update:modelValue', this.filterValue);
      },
    },
  },
  mounted() {
    this.teleport = this.$refs.filters.parentElement;
    if (this.$route.query.filter_params) {
      this.filters = JSON.parse(decodeURI(this.$route.query.filter_params));
    }
    // override if store value exists
    if (this.datasetFilters) {
      this.filters = this.datasetFilters;
    }
  },
  methods: {

  },
};
</script>

<style lang="scss" scoped>
.filters {
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;

  &._collapse {
    flex-wrap: nowrap;
    gap: 12px;

    .filters__active-and-default-filters {
      margin-bottom: -8px;
      position: relative;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      flex: unset;
      gap: 12px;
      overflow-x: auto;
      overflow-y: hidden;
    }

    .filters__more-filters {
      position: relative;
      display: flex;
      flex-direction: row;
      flex: unset;
      gap: 12px;
    }

    #expand_less {
      position: absolute;
      bottom: -8px;
      right: -16px;
      transform: rotateX(180deg);
      &:hover {
        @include themify() {
          background-color: themed('icon-hover-color');
          border-radius: unset;
        }
      }
    }
  }

  &__active-and-default-filters {
    position: relative;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    flex: 1 1 auto;
    gap: 12px;
  }

  &__more-filters {
    position: relative;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    flex: 1 1 auto;
    gap: 12px;
  }

  #expand_less {
    position: absolute;
    bottom: -8px;
    right: -16px;
    &:hover {
      @include themify() {
        background-color: themed('icon-hover-color');
        border-radius: unset;
      }
    }
  }
}

.filter-button {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 30px;
  border-radius: 6px;
  padding: 2px 10px;
  box-shadow: 0 1px 3px 0 rgb(0 0 0 / 20%);
  gap: 2px;
  white-space: nowrap;
  @include themify() {
    border: 2px solid themed('button-secondary-outlined-border');
    background: themed('button-secondary-outlined');
    color: themed('button-secondary-text');
  }

  &:hover {
    @include themify() {
      background: themed('button-secondary-hover-color');
    }
  }
}

.delete-button {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 30px;
  border-radius: 6px;
  padding: 2px 10px;
  box-shadow: 0 1px 3px 0 rgb(0 0 0 / 20%);
  gap: 2px;
  white-space: nowrap;

  @include themify() {
    border: 2px solid themed('button-red-border');
    background: themed('button-red');
    color: themed('button-secondary-text');
  }

  &:hover {
    @include themify() {
      background: themed('button-red-hover');
    }
  }

  &:disabled {
    @include themify() {
      border: 2px solid themed('button-secondary-outlined-border');
      background: themed('color-disabled');
    }
  }
}

</style>
