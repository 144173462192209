<template>
  <div class="main-page scrollbar">
    <div class="settings">
      <span class="header">Security Settings</span>

      <div class="row mt-4">
        <label class="label required">Image Limit</label>
        <div class="value">
          <input
            v-model="imageLimit"
            class="select"
            type="number"
            min="0"
          >
        </div>
      </div>
      <div v-if="message" class="row mt-2">
        <label class="label" />
        <div
          class="value"
          :class="{ 'error-text': hasError, 'success-text' : !hasError }"
        >
          {{ message }}
        </div>
      </div>
      <div class="row mt-3 footer">
        <button class="button button-sm rounded" :disabled="!hasChanges" @click.stop="apply()">Apply</button>
        <span @click.stop="$router.go(-1)">Cancel</span>
      </div>
    </div>
  </div>
</template>

<script>
import DatastoreConnect from '@/assets/js/DatastoreFunctions/datastore-interface';

export default {
  name: 'OrganizationSecuritySettings',
  components: {
  },
  data() {
    return {
      dsConn: new DatastoreConnect(this.$store.state.enterpriseServerUrl),
      orgId: null,
      imageLimit: 0,
      originalImageLimit: 0,
      message: '',
      hasError: false,
    };
  },
  computed: {
    user() {
      return this.$store.state.user.user;
    },
    isAdmin() {
      return this.user && (this.user.role === 'admin');
    },
    isSuper() {
      return this.user && (this.user.role === 'super');
    },
    hasChanges() {
      if (this.imageLimit !== this.originalImageLimit) {
        return true;
      }

      return false;
    },
  },
  async created() {
    if (!this.isAdmin && !this.isSuper) {
      this.$router.push('/login');
    }
    if (this.$route.query.org) {
      this.orgId = parseInt(this.$route.query.org);
      this.getOrgDataImageLimit();
    }
  },
  methods: {
    async getOrgDataImageLimit() {
      await this.dsConn.getOrgDataField({ key: "image-limit" })
        .then((data) => {
          if (!data.error) {
            if (data.result) {
              this.imageLimit = parseInt(data.result, 10);
              this.originalImageLimit = parseInt(data.result, 10);
            } else {
              this.imageLimit = 0;
              this.originalImageLimit = 0;
            }
          } else {
            this.hasError = true;
            this.message = data.error.message;
          }
        })
        .catch((e) => { this.hasError = true; this.message = e; });
    },
    resetErrors() {
      this.hasError = false;
    },
    async apply() {
      this.resetErrors();
      if (!this.imageLimit) {
        this.imageLimit = 0;
      }
      const params = {
        name: this.name,
        key: "image-limit",
        value: this.imageLimit.toString(),
      };
      await this.dsConn.setOrgDataField(params)
        .then((data) => {
          if (data.result) {
            this.hasError = false;
            this.message = 'Successfully updated image limit';
            this.originalImageLimit = this.imageLimit;
          } else {
            this.hasError = true;
            this.message = 'Could not update image limit.';
          }
          this.$store.commit('notifications/updateNotifications', true);
        })
        .catch((e) => {
          this.hasError = true;
          this.message = e;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.main-page {
  width: 100%;
  height: 100%;
  overflow-y: auto;
}

.settings {
  display: flex;
  flex-direction: column;
  width: 730px;
  height: 100%;
  padding: 30px;
}

.header {
  font-size: 1.2rem;
  font-weight: 700;
}

.row {
  flex-wrap: nowrap !important;
}

.row > .label {
  display: flex;
  justify-content: end;
  align-items: center;
  width: 200px;
  text-align: right;
  font-size: 0.9rem;
  font-weight: 600;
  color: rgba(grey, 0.9);

  &.required::after {
    content: '*';
    font-size: 14px;
    font-weight: 700;
    padding-left: 1px;
    @include themify() {
      color: themed('color-primary');
    }
  }
}

.row > .value {
  flex: 1 1 auto;
  font-size: 0.9rem;
  margin-left: 20px;
}

.select {
  width: 100%;
  height: 30px;
  border-radius: 5px;
  font-size: 1rem;
  padding: 3px 10px;
  align-self: center;
  -webkit-appearance: auto;
  background: rgba(#ffffff, 0.8);
  @include themify() {
    border: 1px solid themed('form-input-border');
  }

}

.select:focus {
  @include themify() {
    border: 1px solid themed('color-primary');
    box-shadow:  0 0 0 1px themed('color-primary'), 0 0 3px themed('color-primary-300');
  }
  outline: 2px solid transparent;
  outline-offset: 2px;
}

input.error , select.error {
  @include themify() {
    border: 1px solid themed('color-red') !important;
    box-shadow:  0 0 0 1px themed('color-red'), 0 0 3px themed('color-red') !important;
  }

  &:focus, &:focus-visible {
    @include themify() {
      border: 1px solid themed('color-red') !important;
      box-shadow:  0 0 0 1px themed('color-red'), 0 0 3px themed('color-red') !important;
    }
  }
}

.error-text {
  color: $color-red-500;
}

.success-text {
  color: $color-success;
}

.footer {
  display: flex;
  justify-content: flex-end;
  button {
    width: fit-content
  }
  span {
    color: $color-link;
    text-decoration: underline;
    cursor: pointer;
    width: fit-content;
  }
}

.role-grid {
  display: grid;
  grid-gap: 10px;
  grid-template-columns: repeat(auto-fill, 200px);
  grid-auto-rows: auto;
  width: 100%;
  overflow-y: auto;
}
</style>
