<template>
  <v-breadcrumbs :items="crumbs">
    <template #title="{ item }">
      <div class="item-flex">
        <span>{{ item.title }}</span>
        <span v-if="item.siblings && item.siblings.length > 1" class="breadcrumb-path-siblings">
          <BaseMenu
            :placement="'bottom'"
            :offset="[0, 16]"
          >
            <!-- allow click = false to remove active state on click  -->
            <IconButton
              :id="`${item.title.trim()}`"
              class="breadcrumb-dropdown-icon"
              :icon="'expand_more'"
              :width="22"
              :height="22"
              :allowClick="false"
              @click.stop.prevent=""
            />
            <template #menu="{closeMenu}">
              <ul>
                <li
                  v-for="(s, idx) in item.siblings.filter(i => i.meta && Object.keys(i.meta).length > 0)"
                  :key="`${s.meta.breadCrumb.trim()}_${idx}`"
                >
                  <BaseMenuButton
                    :disabled="s.meta.breadCrumb === item.title"
                    @click.stop="navigate(s.name), closeMenu()"
                  >
                    <SVGIcon
                      :iconName="s.meta.icon"
                      :width="'22px'"
                      :height="'22px'"
                    />
                    <span>{{ s.meta.breadCrumb }}</span>
                  </BaseMenuButton>
                </li>
              </ul>
            </template>
          </BaseMenu>
        </span>
      </div>
    </template>
  </v-breadcrumbs>
</template>
<script>
import IconButton from '@/components/IconButton.vue';
import BaseMenu from '@/components/BaseMenu.vue';
import BaseMenuButton from '@/components/BaseMenuButton.vue';
import SVGIcon from '@/components/SVGIcon.vue';

export default {
  name: 'BreadcrumbNavigation',
  components: {
    IconButton,
    BaseMenu,
    BaseMenuButton,
    SVGIcon,
  },
  computed: {
    user() {
      return this.$store.state.user.user;
    },
    crumbs() {
      const pathArray = this.$route.path.split("/");
      pathArray.shift();
      const breadcrumbs = pathArray.reduce((breadcrumbArray, path, idx) => {
        if (this.$route.matched[idx].meta.breadCrumb) {
          const obj = {
            path,
            to: breadcrumbArray[idx - 1]
              ? `${breadcrumbArray[idx - 1].to}/${path}`
              : `/${path}`,
            title: this.$route.matched[idx].meta.breadCrumb,
            siblings: this.$route.matched[idx - 1] ? this.$route.matched[idx - 1].children : null,
          };

          if (obj.siblings) {
            obj.siblings = obj.siblings.filter((item) => ((!item.permissions) || (item.permissions && item.permissions.some((i) => this.user.role === i))) && item.meta?.breadCrumb);
          }
          breadcrumbArray.push(obj);
        }
        return breadcrumbArray;
      }, []);
      return breadcrumbs;
    },

  },
  methods: {
    navigate(name) {
      this.$router.push({ name });
    },
  },
};
</script>
<style lang="scss">
.item-flex {
  display: flex;
  padding: 5px;
  flex-wrap: nowrap;
  overflow-x: hidden;
  overflow-y: hidden;
  span {
    width: fit-content;
    white-space: nowrap;
  }
}

.v-breadcrumbs {
  padding: 0;
}

.v-breadcrumbs .v-breadcrumbs-item--link,
.v-breadcrumbs .v-icon {
  font-size: 1.0rem;
}

.v-breadcrumbs li.v-breadcrumbs-item--disabled:first-child{
  opacity: 1;
}

.v-breadcrumbs li.v-breadcrumbs-item--disabled:not(:first-child) {
  opacity: 1;
  text-decoration: underline;
  text-decoration-thickness: 2px;
}

.breadcrumb-path-siblings {
  margin-left: 8px;
  pointer-events: auto;
}

.breadcrumb-dropdown-icon {
  z-index: 4;
}

.breadcrumb-dropdown-icon.active {
  color: var(--color-white) !important;
}
</style>
