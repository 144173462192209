<template>
  <Teleport :to="'body'">
    <Modal
      ref="confirmModal"
      v-model:show="isShown"
      class="confirm-modal"
      :width="'30%'"
      @keyup="handleKeyup"
    >
      <template #modal-header>
        <div class="header">
          <h2>
            {{ messageHeader ? messageHeader : "" }}
          </h2>
        </div>
      </template>
      <template #modal-main>
        <div class="body">
          <p class="confirm-text">
            {{ message ? message : "" }}
          </p>
          <p class="confirm-text-note">
            {{ note ? note : "" }}
          </p>
        </div>
      </template>
      <template #modal-footer>
        <div class="footer">
          <button
            class="button modal-cancel-button"
            @click="handleCancelClicked"
          >
            Cancel
          </button>
          <button
            v-if="buttonText && buttonText !== ''"
            class="button modal-action-button"
            :class="buttonClass"
            @click="handleMainButtonClicked"
          >
            {{ buttonText }}
          </button>
        </div>
      </template>
    </Modal>
  </Teleport>
</template>

<script>
import Modal from './Modal.vue';

export default {
  name: 'ConfirmModal',
  components: {
    Modal,
  },
  props: {
    messageHeader: {
      type: String,
      default: '',
    },
    message: {
      type: String,
      default: '',
    },
    note: {
      type: String,
      default: '',
    },
    buttonClass: {
      type: String,
      default: '',
    },
    buttonText: {
      type: String,
      default: '',
    },
  },
  emits: [
    'confirmed',
  ],
  data() {
    return {
      isShown: false,
    };
  },
  watch: {
    isShown() {
      if (this.isShown) {
        window.addEventListener('keyup', this.handleKeyup, true);
      } else {
        window.removeEventListener('keyup', this.handleKeyup, true);
      }
    },
  },
  beforeUnmount() {
    window.removeEventListener('keyup', this.handleKeyup, true);
  },
  methods: {
    showModal() {
      this.$refs.confirmModal.showModal();
    },
    handleCancelClicked() {
      this.$refs.confirmModal.closeModal();
    },
    handleSubmit() {
      this.$emit('confirmed');
      this.$refs.confirmModal.closeModal();
    },
    handleMainButtonClicked() {
      this.handleSubmit();
    },
    handleKeyup(event) {
      switch (event.key) {
      case 'Enter':
        event.stopPropagation();
        this.handleSubmit();
        break;
      default:
        break;
      }
    },
  },
};
</script>

<style lang="scss" scoped>

.body {
  display: flex;
  flex-direction: column;
  padding: 0.75rem 2rem;

  p + p {
    margin-top: 5px;
  }

  z-index: 1500;
}

.confirm-text {
  height: fit-content;
  display: flex;
  font-size: 1.1rem;
  font-weight: 500;
  word-break: break-word;
  text-align: left;
}

.confirm-text-note {
  height: fit-content;
  display: flex;
  font-size: 0.9rem;
  font-weight: 400;
  word-break: break-word;
  text-align: left;
  color: var(--gray-600)
}

.footer {
  display: flex;
  flex-direction: row;
  padding: 0.75rem 2rem;
  justify-content: flex-end;

  button + button {
    margin-left: 10px;
  }
}

</style>
