<template>
  <!-- eslint-disable max-len -->
  <BaseCard
    :id="`dataset-${dataset.id}`"
    ref="card"
    :title="dataset.name"
    class="dataset-card"
  >
    <template #header>
      <BaseMenu
        v-if="permissions && permissions.trainer_write"
        :placement="'bottom-end'"
        @closed="show_menu = false"
      >
        <IconButton
          v-model="show_menu"
          class="header-icon"
          :icon="'more_vert'"
          :width="24"
          :height="24"
        />
        <template #menu="{closeMenu}">
          <ul class="dataset-menu">
            <li>
              <BaseMenuButton @click.stop="handleValidatorStart(), closeMenu()">
                <SVGIcon
                  :iconName="'play'"
                  :width="'22px'"
                  :height="'22px'"
                />
                <span>New Validate Session</span>
              </BaseMenuButton>
            </li>
          </ul>
        </template>
      </BaseMenu>
    </template>
    <template #body>
      <div class="dataset-card__body mt-2">
        <BaseTextExpander v-if="dataset.description" class="mb-2" :text="dataset.description" />
        <template v-if="dataset && dataset.validate_sessions && dataset.validate_sessions.length > 0">
          <ul class="validate-sessions scrollbar">
            <li
              v-for="(session, i) in dataset.validate_sessions"
              :key="i"
              class="validate-sessions__list-item2"
              @click="handleSessionInfoClick(session)"
            >
              <div class="header">
                <div class="left">
                  <SVGIcon
                    v-if="session.params.type === 'detection'"
                    :iconName="'detection2'"
                    class="task-type-icon"
                    :width="'20px'"
                    :height="'20px'"
                  />
                  <SVGIcon
                    v-else-if="session.params.type === 'segmentation'"
                    :iconName="'segments'"
                    class="task-type-icon"
                    :width="'20px'"
                    :height="'20px'"
                  />
                  <span>{{ getString(session.params.type) }}</span>
                </div>
                <div class="center" />
                <div class="right timer">
                  <span v-if="session.docker_task">{{ formatTime(session.docker_task.date) }}</span>

                  <div
                    v-if="getStatus(session) === 'evaluating' || getStatus(session) === 'concluding' || getStatus(session) === 'running'"
                    class="icon-container"
                  >
                    <div class="icon" title="Terminate Validator">
                      <SVGIcon
                        v-if="permissions && permissions.trainer_write"
                        :iconName="'pause'"
                        :width="18"
                        :height="18"
                        @click="terminateTask(session)"
                      />
                    </div>
                  </div>
                  <div v-else class="icon-container">
                    <div class="icon" title="Delete Session">
                      <SVGIcon
                        v-if="permissions && permissions.trainer_write"
                        :iconName="'trash'"
                        :width="18"
                        :height="18"
                        @click.stop="deleteTask(session)"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="body">
                <div val-status class="status-col">
                  <span v-if="getStatus(session) === 'complete'" class="status-tag _complete"><div class="status-icon">&#x2713;</div>Complete</span>
                  <span v-else-if="getStatus(session) === 'running' || getStatus(session) === 'pending'" class="status-tag _running"><div class="running-icon" />Initializing</span>
                  <span v-else-if="getStatus(session) === 'evaluating'" class="status-tag _running"><div class="running-icon" />Evaluating</span>
                  <span v-else-if="getStatus(session) === 'concluding'" class="status-tag _running"><div class="running-icon" />Concluding</span>
                  <span v-else-if="getStatus(session) === 'terminated'" class="status-tag _warning"><div class="status-icon">&#x21;</div>Terminated</span>
                  <span v-else :title="getStatus(session)" class="status-tag _error"><div class="status-icon">&#x2716;</div>Error</span>
                  <span v-if="session.docker_task" class="duration">Duration: {{ getEndTime(session.docker_task) }} hr</span>
                </div>

                <hr class="vr">
                <div val-name class="name-col">
                  <div class="name-col__main">
                    <div class="item">
                      <label>GROUND TRUTH</label>
                      <span>{{ getName(session.gt_annotation_set_id) }}</span>
                    </div>
                    <div class="item">
                      <label>TARGET</label>
                      <span>{{ getName(session.target_annotation_set_id) }}</span>
                    </div>
                  </div>
                </div>
                <hr class="vr">
                <div val-epoch class="session-col">
                  <label>IMAGE</label>
                  <span v-if="getTotal(session) && getStatus(session) !== 'error'">{{ getCount(session) }} / {{ getTotal(session) }}</span>
                  <span v-else>n/a</span>
                </div>
              </div>
            </li>
          </ul>
        </template>
        <div v-else class="validate-sessions-empty">
          <span>No Validate Sessions Created</span>
        </div>
      </div>
    </template>
  </BaseCard>
</template>
<script>
import BaseCard from '@/components/BaseCard.vue';
import IconButton from '@/components/IconButton.vue';
import SVGIcon from '@/components/SVGIcon.vue';
import BaseMenu from '@/components/BaseMenu.vue';
import BaseMenuButton from '@/components/BaseMenuButton.vue';
import BaseTextExpander from '@/components/BaseTextExpander.vue';
import DatastoreConnect from '@/assets/js/DatastoreFunctions/datastore-interface';

export default {
  name: "ValidatorCard",
  components: {
    BaseCard,
    IconButton,
    SVGIcon,
    BaseMenu,
    BaseMenuButton,
    BaseTextExpander,
  },
  props: {
    dataset: {
      type: Object,
      default: () => {},
    },
    permissions: {
      type: Object,
      default: () => {},
    },
  },
  emits: ['create-validate-session', 'info-clicked', 'terminate-task', 'delete-task'],
  data() {
    return {
      dsConn: new DatastoreConnect(this.$store.state.enterpriseServerUrl),
      show_menu: false,
    };
  },
  watch: {

  },
  methods: {
    getName(id) {
      const result = this.dataset.annotation_sets.find((e) => e.id === id);
      return result?.name || 'Unknown';
    },
    handleValidatorStart() {
      this.$emit('create-validate-session');
    },
    handleSessionInfoClick(session) {
      this.$emit('info-clicked', session);
    },
    getEpoch(validate_session) {
      if (validate_session.docker_task) {
        return validate_session.docker_task.count;
      }
      return 0;
    },
    getTotalEpochs(session) {
      let params = {};
      if (session && session.params) {
        params = session.params;
      }
      if (params.epochs) {
        return params.epochs;
      }
      return null;
    },
    getStatus(session) {
      if (session.docker_task) {
        return session.docker_task.status;
      }
      return "Failed to start docker task";
    },
    getMessage(session) {
      if (session.docker_task) {
        return session.docker_task.message;
      }
      return "Failed to start docker task";
    },
    getCount(session) {
      if (session.docker_task) {
        return session.docker_task.count;
      }
      return "Failed to get docker task";
    },
    getTotal(session) {
      if (session.docker_task) {
        return session.docker_task.total;
      }
      return null;
    },
    getString(type) {
      switch (type) {
      case 'detection':
        return 'Detect Validation';
      case 'segmentation':
        return 'Segment Validation';
      default:
        return '';
      }
    },
    getEndTime(docker_task) {
      if (docker_task.end_date !== "0001-01-01T00:00:00Z" && docker_task.date !== "0001-01-01T00:00:00Z") {
        return Number(Math.abs(new Date(docker_task.end_date) - new Date(docker_task.date)) / 3.6e6).toFixed(2);
      } else if (docker_task.end_date === "0001-01-01T00:00:00Z" && docker_task.date !== "0001-01-01T00:00:00Z") {
        return Number(Math.abs(Date.now() - new Date(docker_task.date)) / 3.6e6).toFixed(2);
      } else {
        return null;
      }
    },
    formatTime(str) {
      return `${new Date(str).toLocaleTimeString('default', {
        year: 'numeric', month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric', hour12: true,
      })}`;
    },
    terminateTask(session) {
      this.$emit('terminate-task', session);
    },
    deleteTask(session) {
      this.$emit('delete-task', session);
    },
  },
};
</script>

<style lang="scss" scoped>

.dataset-card {
  display: flex;
  height: 360px;
  flex-direction: column;
  position: relative;
  padding: 16px;
  text-align: left;
  border-radius: 8px;

  @include themify() {
    background: themed('card-color');
    color: themed('body-text-color');
  }

  &__header {
    width: 100%;
    display: flex;
  }

  &__titles {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    line-height: 1;
  }

  &__title {
    font-size: 1.25rem;
    font-weight: 700;
    @include themify() {
      color: themed('body-text-color');
    }
  }

  &__subtitle {
    font-size: 0.75rem;
    font-weight: 700;
    margin-top: 2px;
    @include themify() {
      color: themed('body-text-color-secondary');
    }
  }

  &__body {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 0px;
    flex: 1 1 auto;

    h3 {
      margin: 0;
      font-size: 1rem;
      @include themify() {
        color: themed('body-text-color-secondary');
      }
    }
  }

  &__body-row {
    display: flex;
    flex: 1 1 auto;
    margin-top: 5px;
    align-items: center;
  }

}

.dataset-menu {
  list-style-type: none;
  margin: 0;
  padding: 0;
  min-width: 100px;
}

.validate-sessions {
  display: flex;
  flex-direction: column;
  gap: 6px;
  list-style: none;
  padding: 2px 4px;
  margin: -2px -4px;
  overflow-y: auto;

  &__list-item {
    position: relative;
    background: rgb(231, 224, 224);
    padding: 8px;
    border-radius: 8px;

    span {
      overflow-wrap: anywhere;
    }

    &:hover {
      .validate-sessions__list-item-buttons {
        visibility: visible;
      }
    }
  }

  &__list-item-buttons {
    visibility: hidden;
    position: absolute;
    top: 6px;
    right: 6px;
    @include themify() {
      color: themed('color-primary');
    }
  }

  &__list-item2 {
    display: flex;
    flex-direction: column;
    @include themify() {
        background: themed('color-white-700');
      }
    border-radius: 4px;

    cursor: pointer;
    &:hover {
      box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.375), 0 0 1px 1px rgba(0, 0, 0, 0.125);
    }

    .header {
      padding: 8px 0 8px 8px;
      display: grid;
      grid-template-columns:0.9fr minmax(0px, 1.2fr) 0.9fr;
      gap: 15px;
      font-weight: 700;
      font-size: 0.85rem;
      @include themify() {
        color: themed('color-primary');
      }

      .left {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        .task-type-icon {
          margin: 0 8px 0 0;
          @include themify() {
            color: themed('color-primary');
          }
        }
      }

      .center {
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .right {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        overflow-x: hidden;
        overflow-y: hidden;
      }

      .icon-container {
        display: flex;
        align-items: center;
        margin-left: 10px;
          @include themify() {
            color: themed('body-text-color-secondary');
          }
          .icon + .icon {
            margin-left: 8px;
          }
        }

      .timer {
        margin-right: 8px;
        font-weight: 600;
        font-size: 0.695rem;
        white-space: nowrap;
        @include themify() {
          color: themed('body-text-color-secondary');
        }
      }
    }
    .body {
      display: flex;
      flex-direction: row;
      position: relative;
      padding: 0 8px 8px 8px;
      gap: 16px;
      align-items: center;

      > div {
        flex: 1 1 100%;
      }

      .session-col {
        display: flex;
        flex-direction: column;
        width: fit-content;

        label, span {
          text-align: center;
          overflow-wrap: anywhere;
          pointer-events: none;
        }
        label {
          font-weight: 600;
          font-size: 0.625rem;
          letter-spacing: 0.05em;
          white-space: nowrap;
          @include themify() {
            color: themed('body-text-color-secondary');
          }
        }
        span {
          font-weight: 700;
          font-size: 0.75rem;
          @include themify() {
            color: themed('color-primary');
          }
        }
      }

      .status-col {
        display: flex;
        flex-direction: column;
        align-items: center;

        .duration {
          font-weight: 600;
          font-size: 0.625rem;
          letter-spacing: 0.05em;
          white-space: nowrap;
          @include themify() {
            color: themed('body-text-color-secondary');
          }
        }
      }

      .name-col {
        display: flex;
        flex-direction: column;

        &__main {
          display: flex;
          flex-direction: row;
            justify-content: space-around;
          .item {
            display: flex;
            flex-direction: column;
            max-width: 50%;
            width: 50%;
          }
        }

        label, span {
          text-align: center;
          overflow-wrap: anywhere;
          pointer-events: none;
        }
        label {
          font-weight: 600;
          font-size: 0.625rem;
          letter-spacing: 0.05em;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          @include themify() {
            color: themed('body-text-color-secondary');
          }
        }
        span {
          font-weight: 700;
          font-size: 0.75rem;
          padding-left: 10px;
          padding-right: 10px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          @include themify() {
            color: themed('color-primary');
          }
        }
      }
    }
  }
}

.validate-sessions__list-item2 div[val-epoch] {
  max-width: 100px !important;
  min-width: 40px !important;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.validate-sessions__list-item2 div[val-status] {
  min-width: 120px !important;
  flex: 0 1 auto;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.validate-sessions__list-item2 div[val-name] {
  min-width: 180px !important;
  flex: 1 1 auto;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.validate-sessions-empty {
  display: flex;
  flex-direction: column;
  height: 100%;
  align-items: center;
  justify-content: center;
  font-size: 1.25rem;
  font-weight: bold;
  border: solid 1px black;
  border-radius: 8px;
  @include themify() {
    background: radial-gradient(circle, rgba($color-primary-300, 0.075) 50%, rgba(var(--color-primary-rgb), 0.125) 100%);
  }

  span {
    padding: 16px;
    text-align: center;
  }
}

.header-icon {
  @include themify() {
    color: themed('body-text-color-secondary');
  }
}

.header-icon:hover {
  @include themify() {
    color: themed('color-primary');
  }
}

.status-tag {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  min-width: 100px;
  border-radius: 18px;
  padding: 4px 6px;
  line-height: 1;
  font-size: 0.625rem;
  font-weight: 700;
  white-space: nowrap;
  border: 2px solid;
  box-shadow: 0px 0px 1px rgb(0 0 0 / 25%), 0px 1px 4px rgb(0 0 0 / 25%);
  @include themify() {
    color: themed('color-black');
  }

  &._complete {
    @include themify() {
      color: themed('color-status-complete');
      background: themed('color-status-complete-background');
    }
  }
  &._running {
    @include themify() {
      color: themed('color-status-running');
      background: themed('color-status-running-background');
    }
  }
  &._error {
    @include themify() {
      color: themed('color-status-error');
      background: themed('color-status-error-background');
    }
  }

  &._warning {
    @include themify() {
      color: themed('color-status-warning');
      background: themed('color-status-warning-background');
    }
  }

  .running-icon {
    color: inherit;
    width: 4px;
    height: 4px;
    border: solid 4px;
    border-radius: 4px;
    margin-right: 4px;
  }

  .status-icon {
    color: inherit;
    margin-right: 4px;
  }
}

hr.vr {
  display: flex;
  width: 1px;
  height: 60%;
  margin: 0;
}

</style>
