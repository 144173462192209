<template>
  <div class="pricing-table" :style="{ maxWidth: maxWidth}">
    <h1 :style="{color: textColor}">Pricing Plans</h1>
    <div class="pricing-table__subtitle">
      <p :style="{color: textColor}">
        Sign up for free to claim $10 in credits.
        For customized plans and payments, contact our sales team
      </p>
    </div>
    <button class="button sales-btn" @click="contactSales">Contact Sales</button>
    <table v-if="costPackage.freePackage && costPackage.basicPackage && costPackage.proPackage && costPackage.businessPackage" class="table">
      <colgroup>
        <col span="1" style="width: 26%;">
        <col span="1" style="width: 17%;" class="free">
        <col span="1" style="width: 17%;" class="low">
        <col span="1" style="width: 17%;" class="medium">
        <col span="1" style="width: 17%;" class="high">
      </colgroup>
      <tbody>
        <tr class="header-row">
          <th />
          <th><div class="cell text-center highlight"><h3>Free (Trial)</h3></div></th>
          <th><div class="cell text-center highlight"><h3>Basic</h3></div></th>
          <th><div class="cell text-center highlight"><h3>Pro</h3></div></th>
          <th><div class="cell text-center highlight"><h3>Business</h3></div></th>
        </tr>
        <tr class="header-row-secondary">
          <th />
          <th><div class="cell text-center cost-tag">One-time $ 10 credit</div></th>
          <th><div class="cell text-center cost-tag">$ {{ costPackage.basicPackage['package_cost_dollar'] }} *</div></th>
          <th><div class="cell text-center cost-tag">$ {{ costPackage.proPackage['package_cost_dollar'] }} *</div></th>
          <th><div class="cell text-center cost-tag">$ {{ costPackage.businessPackage['package_cost_dollar'] }} *</div></th>
        </tr>
        <tr class="header-row-secondary description">
          <td><div class="cell text-left text-italic" :style="{color: textColor}"><h3>Seats (Per User / Month)</h3></div></td>
          <td />
          <td />
          <td />
          <td />
        </tr>
        <tr>
          <td><div class="cell text-left tab" :style="{color: textColor}"><span>Adding New User</span></div></td>
          <td><div class="cell text-center highlight"><span>$1</span></div></td>
          <td>
            <div class="cell text-center highlight">
              <span v-if="costPackage.basicPackage['seats'][0]['crpu']*costPackage.basicPackage['dve_credits_cost_usd'] > 1">$ </span>
              <span v-else>&#65504;</span>
              <span> {{ roundToTwoDecimals(costPackage.basicPackage['seats'][0]['crpu']*costPackage.basicPackage['dve_credits_cost_usd']) }}</span>
            </div>
          </td>
          <td>
            <div class="cell text-center highlight">
              <span v-if="costPackage.proPackage['seats'][0]['crpu']*costPackage.proPackage['dve_credits_cost_usd'] > 1">$ </span>
              <span v-else>&#65504;</span>
              <span>{{ roundToTwoDecimals(costPackage.proPackage['seats'][0]['crpu']*costPackage.proPackage['dve_credits_cost_usd']) }}</span>
            </div>
          </td>
          <td>
            <div class="cell text-center highlight">
              <span v-if="costPackage.businessPackage['seats'][0]['crpu']*costPackage.businessPackage['dve_credits_cost_usd'] > 1">$ </span>
              <span v-else>&#65504;</span>
              <span>{{ roundToTwoDecimals(costPackage.businessPackage['seats'][0]['crpu']*costPackage.businessPackage['dve_credits_cost_usd']) }}</span>
            </div>
          </td>
        </tr>
        <tr class="header-row-secondary description">
          <td><div class="cell text-left text-italic" :style="{color: textColor}"><h3>Machine Learning Operations (Per Hour)</h3></div></td>
          <td />
          <td />
          <td />
          <td />
        </tr>
        <tr>
          <td><div class="cell text-left tab" :style="{color: textColor}"><span>Auto Annotation</span></div></td>
          <td>
            <div class="cell text-center highlight">
              <span v-if="costPackage.freePackage['auto_ann'][0]['crpu']*costPackage.freePackage['dve_credits_cost_usd'] > 1">$ </span>
              <span v-else>&#65504;</span>
              <span> {{ roundToTwoDecimals(costPackage.freePackage['auto_ann'][0]['crpu']*costPackage.freePackage['dve_credits_cost_usd']) }}</span>
            </div>
          </td>
          <td>
            <div class="cell text-center highlight">
              <span v-if="costPackage.freePackage['auto_ann'][0]['crpu']*costPackage.freePackage['dve_credits_cost_usd'] > 1">$ </span>
              <span v-else>&#65504;</span>
              <span> {{ roundToTwoDecimals(costPackage.freePackage['auto_ann'][0]['crpu']*costPackage.freePackage['dve_credits_cost_usd']) }}</span>
            </div>
          </td>
          <td>
            <div class="cell text-center highlight">
              <span v-if="costPackage.proPackage['auto_ann'][0]['crpu']*costPackage.proPackage['dve_credits_cost_usd'] > 1">$ </span>
              <span v-else>&#65504;</span>
              <span>{{ roundToTwoDecimals(costPackage.proPackage['auto_ann'][0]['crpu']*costPackage.proPackage['dve_credits_cost_usd']) }}</span>
            </div>
          </td>
          <td>
            <div class="cell text-center highlight">
              <span v-if="costPackage.businessPackage['auto_ann'][0]['crpu']*costPackage.businessPackage['dve_credits_cost_usd'] > 1">$ </span>
              <span v-else>&#65504;</span>
              <span>{{ roundToTwoDecimals(costPackage.businessPackage['auto_ann'][0]['crpu']*costPackage.businessPackage['dve_credits_cost_usd']) }}</span>
            </div>
          </td>
        </tr>
        <tr>
          <td><div class="cell text-left tab" :style="{color: textColor}"><span>Auto Segmentation</span></div></td>
          <td>
            <div class="cell text-center highlight">
              <span v-if="costPackage.freePackage['auto_seg'][0]['crpu']*costPackage.freePackage['dve_credits_cost_usd'] > 1">$ </span>
              <span v-else>&#65504;</span>
              <span> {{ roundToTwoDecimals(costPackage.freePackage['auto_seg'][0]['crpu']*costPackage.freePackage['dve_credits_cost_usd']) }}</span>
            </div>
          </td>
          <td>
            <div class="cell text-center highlight">
              <span v-if="costPackage.basicPackage['auto_seg'][0]['crpu']*costPackage.basicPackage['dve_credits_cost_usd'] > 1">$ </span>
              <span v-else>&#65504;</span>
              <span> {{ roundToTwoDecimals(costPackage.basicPackage['auto_seg'][0]['crpu']*costPackage.basicPackage['dve_credits_cost_usd']) }}</span>
            </div>
          </td>
          <td>
            <div class="cell text-center highlight">
              <span v-if="costPackage.proPackage['auto_seg'][0]['crpu']*costPackage.proPackage['dve_credits_cost_usd'] > 1">$ </span>
              <span v-else>&#65504;</span>
              <span>{{ roundToTwoDecimals(costPackage.proPackage['auto_seg'][0]['crpu']*costPackage.proPackage['dve_credits_cost_usd']) }}</span>
            </div>
          </td>
          <td>
            <div class="cell text-center highlight">
              <span v-if="costPackage.businessPackage['auto_seg'][0]['crpu']*costPackage.businessPackage['dve_credits_cost_usd'] > 1">$ </span>
              <span v-else>&#65504;</span>
              <span>{{ roundToTwoDecimals(costPackage.businessPackage['auto_seg'][0]['crpu']*costPackage.businessPackage['dve_credits_cost_usd']) }}</span>
            </div>
          </td>
        </tr>
        <tr>
          <td><div class="cell text-left tab" :style="{color: textColor}"><span>AI Assisted Segmentation</span></div></td>
          <td>
            <div class="cell text-center highlight">
              <span v-if="costPackage.freePackage['training'][0]['crpu']*costPackage.freePackage['dve_credits_cost_usd'] > 1">$ </span>
              <span v-else>&#65504;</span>
              <span> {{ roundToTwoDecimals(costPackage.freePackage['training'][0]['crpu']*costPackage.freePackage['dve_credits_cost_usd']) }}</span>
            </div>
          </td>
          <td>
            <div class="cell text-center highlight">
              <span v-if="costPackage.freePackage['training'][0]['crpu']*costPackage.freePackage['dve_credits_cost_usd'] > 1">$ </span>
              <span v-else>&#65504;</span>
              <span>{{ roundToTwoDecimals(costPackage.freePackage['training'][0]['crpu']*costPackage.freePackage['dve_credits_cost_usd']) }}</span>
            </div>
          </td>
          <td>
            <div class="cell text-center highlight">
              <span v-if="costPackage.proPackage['training'][0]['crpu']*costPackage.proPackage['dve_credits_cost_usd'] > 1">$ </span>
              <span v-else>&#65504;</span>
              <span>{{ roundToTwoDecimals(costPackage.proPackage['training'][0]['crpu']*costPackage.proPackage['dve_credits_cost_usd']) }}</span>
            </div>
          </td>
          <td>
            <div class="cell text-center highlight">
              <span v-if="costPackage.businessPackage['training'][0]['crpu']*costPackage.businessPackage['dve_credits_cost_usd'] > 1">$ </span>
              <span v-else>&#65504;</span>
              <span>{{ roundToTwoDecimals(costPackage.businessPackage['training'][0]['crpu']*costPackage.businessPackage['dve_credits_cost_usd']) }}</span>
            </div>
          </td>
        </tr>
        <tr>
          <td><div class="cell text-left tab" :style="{color: textColor}"><span>Image Based Training</span></div></td>
          <td>
            <div class="cell text-center highlight">
              <span v-if="costPackage.freePackage['training'][0]['crpu']*costPackage.freePackage['dve_credits_cost_usd'] > 1">$ </span>
              <span v-else>&#65504;</span>
              <span> {{ roundToTwoDecimals(costPackage.freePackage['training'][0]['crpu']*costPackage.freePackage['dve_credits_cost_usd']) }}</span>
            </div>
          </td>
          <td>
            <div class="cell text-center highlight">
              <span v-if="costPackage.freePackage['training'][0]['crpu']*costPackage.freePackage['dve_credits_cost_usd'] > 1">$ </span>
              <span v-else>&#65504;</span>
              <span>{{ roundToTwoDecimals(costPackage.freePackage['training'][0]['crpu']*costPackage.freePackage['dve_credits_cost_usd']) }}</span>
            </div>
          </td>
          <td>
            <div class="cell text-center highlight">
              <span v-if="costPackage.proPackage['training'][0]['crpu']*costPackage.proPackage['dve_credits_cost_usd'] > 1">$ </span>
              <span v-else>&#65504;</span>
              <span>{{ roundToTwoDecimals(costPackage.proPackage['training'][0]['crpu']*costPackage.proPackage['dve_credits_cost_usd']) }}</span>
            </div>
          </td>
          <td>
            <div class="cell text-center highlight">
              <span v-if="costPackage.businessPackage['training'][0]['crpu']*costPackage.businessPackage['dve_credits_cost_usd'] > 1">$ </span>
              <span v-else>&#65504;</span>
              <span>{{ roundToTwoDecimals(costPackage.businessPackage['training'][0]['crpu']*costPackage.businessPackage['dve_credits_cost_usd']) }}</span>
            </div>
          </td>
        </tr>
        <tr>
          <td><div class="cell text-left tab" :style="{color: textColor}"><span>Sensor Fusion Model Training</span></div></td>
          <td>
            <div class="cell text-center highlight">
              <span v-if="costPackage.freePackage['training'][0]['crpu']*costPackage.freePackage['dve_credits_cost_usd'] > 1">$ </span>
              <span v-else>&#65504;</span>
              <span> {{ roundToTwoDecimals(costPackage.freePackage['training'][0]['crpu']*costPackage.freePackage['dve_credits_cost_usd']) }}</span>
            </div>
          </td>
          <td>
            <div class="cell text-center highlight">
              <span v-if="costPackage.freePackage['training'][0]['crpu']*costPackage.freePackage['dve_credits_cost_usd'] > 1">$ </span>
              <span v-else>&#65504;</span>
              <span>{{ roundToTwoDecimals(costPackage.freePackage['training'][0]['crpu']*costPackage.freePackage['dve_credits_cost_usd']) }}</span>
            </div>
          </td>
          <td>
            <div class="cell text-center highlight">
              <span v-if="costPackage.proPackage['training'][0]['crpu']*costPackage.proPackage['dve_credits_cost_usd'] > 1">$ </span>
              <span v-else>&#65504;</span>
              <span>{{ roundToTwoDecimals(costPackage.proPackage['training'][0]['crpu']*costPackage.proPackage['dve_credits_cost_usd']) }}</span>
            </div>
          </td>
          <td>
            <div class="cell text-center highlight">
              <span v-if="costPackage.businessPackage['training'][0]['crpu']*costPackage.businessPackage['dve_credits_cost_usd'] > 1">$ </span>
              <span v-else>&#65504;</span>
              <span>{{ roundToTwoDecimals(costPackage.businessPackage['training'][0]['crpu']*costPackage.businessPackage['dve_credits_cost_usd']) }}</span>
            </div>
          </td>
        </tr>
        <tr>
          <td><div class="cell text-left tab" :style="{color: textColor}"><span>Model Quantization & Conversion</span></div></td>
          <td><div class="cell text-center highlight"><span>Free</span></div></td>
          <td><div class="cell text-center highlight"><span>Free</span></div></td>
          <td><div class="cell text-center highlight"><span>Free</span></div></td>
          <td><div class="cell text-center highlight"><span>Free</span></div></td>
        </tr>
        <tr>
          <td><div class="cell text-left tab" :style="{color: textColor}"><span>Model & Inference Validation</span></div></td>
          <td>
            <div class="cell text-center highlight">
              <span v-if="costPackage.freePackage['auto_ann'][0]['crpu']*costPackage.freePackage['dve_credits_cost_usd'] > 1">$ </span>
              <span v-else>&#65504;</span>
              <span> {{ roundToTwoDecimals(costPackage.freePackage['auto_ann'][0]['crpu']*costPackage.freePackage['dve_credits_cost_usd']) }}</span>
            </div>
          </td>
          <td>
            <div class="cell text-center highlight">
              <span v-if="costPackage.freePackage['auto_ann'][0]['crpu']*costPackage.freePackage['dve_credits_cost_usd'] > 1">$ </span>
              <span v-else>&#65504;</span>
              <span>{{ roundToTwoDecimals(costPackage.freePackage['auto_ann'][0]['crpu']*costPackage.freePackage['dve_credits_cost_usd']) }}</span>
            </div>
          </td>
          <td>
            <div class="cell text-center highlight">
              <span v-if="costPackage.proPackage['auto_ann'][0]['crpu']*costPackage.proPackage['dve_credits_cost_usd'] > 1">$ </span>
              <span v-else>&#65504;</span>
              <span>{{ roundToTwoDecimals(costPackage.proPackage['auto_ann'][0]['crpu']*costPackage.proPackage['dve_credits_cost_usd']) }}</span>
            </div>
          </td>
          <td>
            <div class="cell text-center highlight">
              <span v-if="costPackage.businessPackage['auto_ann'][0]['crpu']*costPackage.businessPackage['dve_credits_cost_usd'] > 1">$ </span>
              <span v-else>&#65504;</span>
              <span>{{ roundToTwoDecimals(costPackage.businessPackage['auto_ann'][0]['crpu']*costPackage.businessPackage['dve_credits_cost_usd']) }}</span>
            </div>
          </td>
        </tr>
        <tr>
          <td><div class="cell text-left tab" :style="{color: textColor}"><span>Edge Model Optimization & Export</span></div></td>
          <td><div class="cell text-center highlight"><span>Free</span></div></td>
          <td><div class="cell text-center highlight"><span>Free</span></div></td>
          <td><div class="cell text-center highlight"><span>Free</span></div></td>
          <td><div class="cell text-center highlight"><span>Free</span></div></td>
        </tr>
        <tr>
          <td><div class="cell text-left tab" :style="{color: textColor}"><span>Data Audit & Labelling</span></div></td>
          <td><div class="cell text-center highlight"><span>Free</span></div></td>
          <td><div class="cell text-center highlight"><span>Free</span></div></td>
          <td><div class="cell text-center highlight"><span>Free</span></div></td>
          <td><div class="cell text-center highlight"><span>Free</span></div></td>
        </tr>
        <tr class="header-row-secondary description">
          <td><div class="cell text-left text-italic" :style="{color: textColor}"><h3>Data Storage (Per Month)</h3></div></td>
          <td />
          <td />
          <td />
          <td />
        </tr>
        <tr>
          <td><div class="cell text-left tab" :style="{color: textColor}"><span>Image (per Image)</span></div></td>
          <td>
            <div class="cell text-center highlight">
              <span v-if="costPackage.freePackage['images'][0]['crpu']*costPackage.freePackage['dve_credits_cost_usd'] > 1">$ </span>
              <span v-else>&#65504;</span>
              <span> {{ roundToTwoDecimals(costPackage.freePackage['images'][0]['crpu']*costPackage.freePackage['dve_credits_cost_usd']) }}</span>
            </div>
          </td>
          <td>
            <div class="cell text-center highlight">
              <span v-if="costPackage.basicPackage['images'][0]['crpu']*costPackage.basicPackage['dve_credits_cost_usd'] > 1">$ </span>
              <span v-else>&#65504;</span>
              <span> {{ roundToTwoDecimals(costPackage.basicPackage['images'][0]['crpu']*costPackage.basicPackage['dve_credits_cost_usd']) }}</span>
            </div>
          </td>
          <td>
            <div class="cell text-center highlight">
              <span v-if="costPackage.proPackage['images'][0]['crpu']*costPackage.proPackage['dve_credits_cost_usd'] > 1">$ </span>
              <span v-else>&#65504;</span>
              <span>{{ roundToTwoDecimals(costPackage.proPackage['images'][0]['crpu']*costPackage.proPackage['dve_credits_cost_usd']) }}</span>
            </div>
          </td>
          <td>
            <div class="cell text-center highlight">
              <span v-if="costPackage.businessPackage['images'][0]['crpu']*costPackage.businessPackage['dve_credits_cost_usd'] > 1">$ </span>
              <span v-else>&#65504;</span>
              <span>{{ roundToTwoDecimals(costPackage.businessPackage['images'][0]['crpu']*costPackage.businessPackage['dve_credits_cost_usd']) }}</span>
            </div>
          </td>
        </tr>
        <tr>
          <td><div class="cell text-left tab" :style="{color: textColor}"><span>Parked Image (per Image)</span></div></td>
          <td>
            <div class="cell text-center highlight">
              <span v-if="costPackage.freePackage['images'][0]['crpu']*costPackage.freePackage['dve_credits_cost_usd'] > 1">$ </span>
              <span v-else>&#65504;</span>
              <span> {{ roundToTwoDecimals(costPackage.freePackage['images'][0]['crpu']*costPackage.freePackage['dve_credits_cost_usd']/2) }}</span>
            </div>
          </td>
          <td>
            <div class="cell text-center highlight">
              <span v-if="costPackage.basicPackage['images'][0]['crpu']*costPackage.basicPackage['dve_credits_cost_usd'] > 1">$ </span>
              <span v-else>&#65504;</span>
              <span> {{ roundToTwoDecimals(costPackage.basicPackage['images'][0]['crpu']*costPackage.basicPackage['dve_credits_cost_usd']/2) }}</span>
            </div>
          </td>
          <td>
            <div class="cell text-center highlight">
              <span v-if="costPackage.proPackage['images'][0]['crpu']*costPackage.proPackage['dve_credits_cost_usd'] > 1">$ </span>
              <span v-else>&#65504;</span>
              <span>{{ roundToTwoDecimals(costPackage.proPackage['images'][0]['crpu']*costPackage.proPackage['dve_credits_cost_usd']/2) }}</span>
            </div>
          </td>
          <td>
            <div class="cell text-center highlight">
              <span v-if="costPackage.businessPackage['images'][0]['crpu']*costPackage.businessPackage['dve_credits_cost_usd'] > 1">$ </span>
              <span v-else>&#65504;</span>
              <span>{{ roundToTwoDecimals(costPackage.businessPackage['images'][0]['crpu']*costPackage.businessPackage['dve_credits_cost_usd']/2) }}</span>
            </div>
          </td>
        </tr>
        <tr>
          <td><div class="cell text-left tab" :style="{color: textColor}"><span>Video & Binary (per MB)</span></div></td>
          <td>
            <div class="cell text-center highlight">
              <span v-if="costPackage.freePackage['bulk_video'][0]['crpu']*costPackage.freePackage['dve_credits_cost_usd'] > 1">$ </span>
              <span v-else>&#65504;</span>
              <span> {{ roundToTwoDecimals(costPackage.freePackage['bulk_video'][0]['crpu']*costPackage.freePackage['dve_credits_cost_usd']) }}</span>
            </div>
          </td>
          <td>
            <div class="cell text-center highlight">
              <span v-if="costPackage.basicPackage['bulk_video'][0]['crpu']*costPackage.basicPackage['dve_credits_cost_usd'] > 1">$ </span>
              <span v-else>&#65504;</span>
              <span>{{ roundToTwoDecimals(costPackage.basicPackage['bulk_video'][0]['crpu']*costPackage.basicPackage['dve_credits_cost_usd']) }}</span>
            </div>
          </td>
          <td>
            <div class="cell text-center highlight">
              <span v-if="costPackage.proPackage['bulk_video'][0]['crpu']*costPackage.proPackage['dve_credits_cost_usd'] > 1">$ </span>
              <span v-else>&#65504;</span>
              <span>{{ roundToTwoDecimals(costPackage.proPackage['bulk_video'][0]['crpu']*costPackage.proPackage['dve_credits_cost_usd']) }}</span>
            </div>
          </td>
          <td>
            <div class="cell text-center highlight">
              <span v-if="costPackage.businessPackage['bulk_video'][0]['crpu']*costPackage.businessPackage['dve_credits_cost_usd'] > 1">$ </span>
              <span v-else>&#65504;</span>
              <span>{{ roundToTwoDecimals(costPackage.businessPackage['bulk_video'][0]['crpu']*costPackage.businessPackage['dve_credits_cost_usd']) }}</span>
            </div>
          </td>
        </tr>
        <tr class="header-row-secondary description">
          <td><div class="cell text-left text-italic" :style="{color: textColor}"><h3>Annotations (Per Month)</h3></div></td>
          <td />
          <td />
          <td />
          <td />
        </tr>
        <tr>
          <td><div class="cell text-left tab" :style="{color: textColor}"><span>Image Bounding Box</span></div></td>
          <td>
            <div class="cell text-center highlight">
              <span v-if="costPackage.freePackage['annotations'][0]['crpu']*costPackage.freePackage['dve_credits_cost_usd'] > 1">$ </span>
              <span v-else>&#65504;</span>
              <span> {{ roundToTwoDecimals(costPackage.freePackage['annotations'][0]['crpu']*costPackage.freePackage['dve_credits_cost_usd']) }}</span>
            </div>
          </td>
          <td>
            <div class="cell text-center highlight">
              <span v-if="costPackage.basicPackage['annotations'][0]['crpu']*costPackage.basicPackage['dve_credits_cost_usd'] > 1">$ </span>
              <span v-else>&#65504;</span>
              <span>{{ roundToTwoDecimals(costPackage.basicPackage['annotations'][0]['crpu']*costPackage.basicPackage['dve_credits_cost_usd']) }}</span>
            </div>
          </td>
          <td>
            <div class="cell text-center highlight">
              <span v-if="costPackage.proPackage['annotations'][0]['crpu']*costPackage.proPackage['dve_credits_cost_usd'] > 1">$ </span>
              <span v-else>&#65504;</span>
              <span>{{ roundToTwoDecimals(costPackage.proPackage['annotations'][0]['crpu']*costPackage.proPackage['dve_credits_cost_usd']) }}</span>
            </div>
          </td>
          <td>
            <div class="cell text-center highlight">
              <span v-if="costPackage.businessPackage['annotations'][0]['crpu']*costPackage.businessPackage['dve_credits_cost_usd'] > 1">$ </span>
              <span v-else>&#65504;</span>
              <span>{{ roundToTwoDecimals(costPackage.businessPackage['annotations'][0]['crpu']*costPackage.businessPackage['dve_credits_cost_usd']) }}</span>
            </div>
          </td>
        </tr>
        <tr>
          <td><div class="cell text-left tab" :style="{color: textColor}"><span>Image Segmentation</span></div></td>
          <td>
            <div class="cell text-center highlight">
              <span v-if="costPackage.freePackage['annotations'][0]['crpu']*costPackage.freePackage['dve_credits_cost_usd'] > 1">$ </span>
              <span v-else>&#65504;</span>
              <span> {{ roundToTwoDecimals(costPackage.freePackage['annotations'][0]['crpu']*costPackage.freePackage['dve_credits_cost_usd']) }}</span>
            </div>
          </td>
          <td>
            <div class="cell text-center highlight">
              <span v-if="costPackage.freePackage['annotations'][0]['crpu']*costPackage.freePackage['dve_credits_cost_usd'] > 1">$ </span>
              <span v-else>&#65504;</span>
              <span>{{ roundToTwoDecimals(costPackage.freePackage['annotations'][0]['crpu']*costPackage.freePackage['dve_credits_cost_usd']) }}</span>
            </div>
          </td>
          <td>
            <div class="cell text-center highlight">
              <span v-if="costPackage.proPackage['annotations'][0]['crpu']*costPackage.proPackage['dve_credits_cost_usd'] > 1">$ </span>
              <span v-else>&#65504;</span>
              <span>{{ roundToTwoDecimals(costPackage.proPackage['annotations'][0]['crpu']*costPackage.proPackage['dve_credits_cost_usd']) }}</span>
            </div>
          </td>
          <td>
            <div class="cell text-center highlight">
              <span v-if="costPackage.businessPackage['annotations'][0]['crpu']*costPackage.businessPackage['dve_credits_cost_usd'] > 1">$ </span>
              <span v-else>&#65504;</span>
              <span>{{ roundToTwoDecimals(costPackage.businessPackage['annotations'][0]['crpu']*costPackage.businessPackage['dve_credits_cost_usd']) }}</span>
            </div>
          </td>
        </tr>
        <tr>
          <td><div class="cell text-left tab" :style="{color: textColor}"><span>Radar Point Cloud</span></div></td>
          <td>
            <div class="cell text-center highlight">
              <span v-if="costPackage.freePackage['annotations'][0]['crpu']*costPackage.freePackage['dve_credits_cost_usd'] > 1">$ </span>
              <span v-else>&#65504;</span>
              <span> {{ roundToTwoDecimals(costPackage.freePackage['annotations'][0]['crpu']*costPackage.freePackage['dve_credits_cost_usd']) }}</span>
            </div>
          </td>
          <td>
            <div class="cell text-center highlight">
              <span v-if="costPackage.freePackage['annotations'][0]['crpu']*costPackage.freePackage['dve_credits_cost_usd'] > 1">$ </span>
              <span v-else>&#65504;</span>
              <span>{{ roundToTwoDecimals(costPackage.freePackage['annotations'][0]['crpu']*costPackage.freePackage['dve_credits_cost_usd']) }}</span>
            </div>
          </td>
          <td>
            <div class="cell text-center highlight">
              <span v-if="costPackage.proPackage['annotations'][0]['crpu']*costPackage.proPackage['dve_credits_cost_usd'] > 1">$ </span>
              <span v-else>&#65504;</span>
              <span>{{ roundToTwoDecimals(costPackage.proPackage['annotations'][0]['crpu']*costPackage.proPackage['dve_credits_cost_usd']) }}</span>
            </div>
          </td>
          <td>
            <div class="cell text-center highlight">
              <span v-if="costPackage.businessPackage['annotations'][0]['crpu']*costPackage.businessPackage['dve_credits_cost_usd'] > 1">$ </span>
              <span v-else>&#65504;</span>
              <span>{{ roundToTwoDecimals(costPackage.businessPackage['annotations'][0]['crpu']*costPackage.businessPackage['dve_credits_cost_usd']) }}</span>
            </div>
          </td>
        </tr>
        <tr>
          <td><div class="cell text-left tab" :style="{color: textColor}"><span>Pose / IMU / GPS</span></div></td>
          <td>
            <div class="cell text-center highlight">
              <span v-if="costPackage.freePackage['annotations'][0]['crpu']*costPackage.freePackage['dve_credits_cost_usd'] > 1">$ </span>
              <span v-else>&#65504;</span>
              <span> {{ roundToTwoDecimals(costPackage.freePackage['annotations'][0]['crpu']*costPackage.freePackage['dve_credits_cost_usd']) }}</span>
            </div>
          </td>
          <td>
            <div class="cell text-center highlight">
              <span v-if="costPackage.freePackage['annotations'][0]['crpu']*costPackage.freePackage['dve_credits_cost_usd'] > 1">$ </span>
              <span v-else>&#65504;</span>
              <span>{{ roundToTwoDecimals(costPackage.freePackage['annotations'][0]['crpu']*costPackage.freePackage['dve_credits_cost_usd']) }}</span>
            </div>
          </td>
          <td>
            <div class="cell text-center highlight">
              <span v-if="costPackage.proPackage['annotations'][0]['crpu']*costPackage.proPackage['dve_credits_cost_usd'] > 1">$ </span>
              <span v-else>&#65504;</span>
              <span>{{ roundToTwoDecimals(costPackage.proPackage['annotations'][0]['crpu']*costPackage.proPackage['dve_credits_cost_usd']) }}</span>
            </div>
          </td>
          <td>
            <div class="cell text-center highlight">
              <span v-if="costPackage.businessPackage['annotations'][0]['crpu']*costPackage.businessPackage['dve_credits_cost_usd'] > 1">$ </span>
              <span v-else>&#65504;</span>
              <span>{{ roundToTwoDecimals(costPackage.businessPackage['annotations'][0]['crpu']*costPackage.businessPackage['dve_credits_cost_usd']) }}</span>
            </div>
          </td>
        </tr>
        <tr class="header-row-secondary description">
          <td><div class="cell text-left text-italic" :style="{color: textColor}"><h3>Live Data & Dataset Enrichment (Per Call)</h3></div></td>
          <td />
          <td />
          <td />
          <td />
        </tr>

        <tr>
          <td><div class="cell text-left tab" :style="{color: textColor}"><span>Bridge API</span></div></td>
          <td><div class="cell text-center highlight"><span>Free</span></div></td>
          <td><div class="cell text-center highlight"><span>Free</span></div></td>
          <td><div class="cell text-center highlight"><span>Free</span></div></td>
          <td><div class="cell text-center highlight"><span>Free</span></div></td>
        </tr>
        <tr class="header-row-secondary description">
          <td><div class="cell text-left text-italic" :style="{color: textColor}"><h3>License</h3></div></td>
          <td />
          <td />
          <td />
          <td />
        </tr>
        <tr>
          <td><div class="cell text-left tab" :style="{color: textColor}"><span>ModelPack Enterprise</span></div></td>
          <td><div class="cell text-center highlight"><span>Included</span></div></td>
          <td><div class="cell text-center highlight"><span>Included</span></div></td>
          <td><div class="cell text-center highlight"><span>Included</span></div></td>
          <td><div class="cell text-center highlight"><span>Included</span></div></td>
        </tr>
        <tr class="header-row-secondary description">
          <td><div class="cell text-left text-italic" :style="{color: textColor}"><h3>Support Package</h3></div></td>
          <td />
          <td />
          <td />
          <td />
        </tr>
        <tr>
          <td><div class="cell text-left tab" :style="{color: textColor}"><span>Technical Support</span></div></td>
          <td><div class="cell text-center highlight"><span>Not Included</span></div></td>
          <td><div class="cell text-center highlight"><span>Included</span></div></td>
          <td><div class="cell text-center highlight"><span>Included</span></div></td>
          <td><div class="cell text-center highlight"><span>Included</span></div></td>
        </tr>
        <tr>
          <td><div class="cell text-left tab" :style="{color: textColor}"><span>Feature Request</span></div></td>
          <td><div class="cell text-center highlight"><span>Not Included</span></div></td>
          <td><div class="cell text-center highlight"><span>Included</span></div></td>
          <td><div class="cell text-center highlight"><span>Included</span></div></td>
          <td><div class="cell text-center highlight"><span>Included</span></div></td>
        </tr>
        <tr>
          <td />
          <td />
          <td />
          <td />
          <td />
        </tr>
        <tr>
          <td colspan="5">
            <div class="cell text-left" :style="{color: textColor}">
              <span class="unit">
                (*): Minimum monthly charge on an annual basis. A credit amount equal to the minimum monthly charge will be deducted.
              </span>
            </div>
          </td>
        </tr>
      <!-- <div class="t-row">
        <div class="cell"> {{ parseFloat(params.iou) }} </div>
        <div class="cell">{{ parseFloat(params.confidence) }}</div>
      </div> -->
      </tbody>
    </table>

    <div ref="sliderInput" class="slider-pricing">
      <div class="container slider-card" :style="{color: textColor}">
        <div class="slider-title">
          <div class="slider-title__text">
            <span class="item text-italic">Seats</span>
            <span class="subtitle">How many members does your team have?</span>
          </div>
          <div class="settings__control-group slider-box-input">
            <input
              v-model="sliderValue.seats"
              type="number"
              placeholder="0"
              max="999999999"
              @input="updateInput('seats')"
            >
          </div>
        </div>
        <SingleSliderWithTwoColors
          v-model:sliderValue="sliderValue.seats"
          class="mb-4"
          :step="1"
          :min="0"
          :max="20"
        />
        <div class="slider-title">
          <div class="slider-title__text">
            <span class="item text-italic">Images</span>
            <span class="subtitle">How many images does your project have?</span>
          </div>
          <div class="settings__control-group slider-box-input">
            <input
              v-model="sliderValue.images"
              type="number"
              placeholder="0"
              @input="updateInput('images')"
            >
          </div>
        </div>
        <SingleSliderWithTwoColors
          v-model:sliderValue="sliderValue.images"
          class="mb-4"
          :step="10000"
          :min="0"
          :max="1000000"
        />
        <div class="slider-title">
          <div class="slider-title__text">
            <span class="item text-italic">Video & binary files (MB)</span>
            <span class="subtitle">Videos and other file storages</span>
          </div>
          <div class="settings__control-group slider-box-input">
            <input
              v-model="sliderValue.video"
              type="number"
              placeholder="0"
              max="999999999"
              @input="updateInput('video')"
            >
          </div>
        </div>
        <SingleSliderWithTwoColors
          v-model:sliderValue="sliderValue.video"
          class="mb-4"
          :step="10"
          :min="0"
          :max="10000"
        />
        <div class="slider-title">
          <div class="slider-title__text">
            <span class="item text-italic">Annotations</span>
            <span class="subtitle">Bounding boxes, segments, and other annotations</span>
          </div>
          <div class="settings__control-group slider-box-input slider-box-input">
            <input
              v-model="sliderValue.annotations"
              type="number"
              placeholder="0"
              max="999999999"
              @input="updateInput('annnotations')"
            >
          </div>
        </div>
        <SingleSliderWithTwoColors
          v-model:sliderValue="sliderValue.annotations"
          class="mb-4"
          :min="0"
          :max="1000000"
          :step="10000"
        />
        <div class="slider-title">
          <div class="slider-title__text">
            <span class="item text-italic">Machine Learning Operations (per month)</span>
            <span class="subtitle">Training, inference, and AI assisted tasks usage</span>
          </div>
          <div class="settings__control-group slider-box-input">
            <input
              v-model="sliderValue.mlops"
              type="number"
              placeholder="0"
              max="999999999"
              @input="updateInput('mlops')"
            >
          </div>
        </div>
        <SingleSliderWithTwoColors
          v-model:sliderValue="sliderValue.mlops"
          class="mb-4"
          :step="5"
          :min="0"
          :max="200"
        />
      </div>

      <div class="container total-price-card" :style="{color: textColor}">
        <span class="label text-italic">Monthly Total</span>
        <div class="item">
          <div class="price">
            <h2>
              $ {{
                displayWithComma(roundToTwoDecimals(displayTotal))
              }} <span class="unit"> / month</span>
            </h2>
          </div>
        </div>
        <span class="label text-italic">Recommended Plan</span>
        <div class="item">
          <div class="price">
            <h2>{{ displayPlan }}</h2>
          </div>
        </div>
        <br>
        <br>
        <div class="text-center">
          <p>For customized plans and payments</p>
          <button class="button sales-btn" @click="contactSales">Contact Sales</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import SingleSliderWithTwoColors from '@/components/SingleSliderWithTwoColors.vue';
import DatastoreConnect from '@/assets/js/DatastoreFunctions/datastore-interface';
import { inputSanitizer, changeSanitizer } from '../assets/js/utils';

export default {
  name: "PriceTable",
  components: {
    SingleSliderWithTwoColors,
  },
  props: {
    color: {
      type: String,
      default: 'white',
    },
    maxWidth: {
      type: String,
      default: '1500px',
    },
  },
  data() {
    return {
      dsConn: new DatastoreConnect(),
      costPackage: {
        freePackage: null,
        basicPackage: null,
        proPackage: null,
        businessPackage: null,
      },
      sliderValue: {
        seats: 0,
        images: 0,
        video: 0,
        annotations: 0,
        mlops: 0,
      },
      sliderInput: {
        seats: 0,
        images: 0,
        video: 0,
        annotations: 0,
        mlops: 0,
      },
    };
  },
  computed: {
    user() {
      return this.$store.state.user.user;
    },
    textColor() {
      if (this.color === '' || this.color === 'white') {
        return "#f6f7f8";
      } else {
        return `${this.color}`;
      }
    },
    totalMonthlyCostBasic() {
      return this.getCreditCost('seats', this.sliderValue.seats, 'basicPackage')
              + this.getCreditCost('training', this.sliderValue.mlops, 'basicPackage')
              + this.getCreditCost('images', this.sliderValue.images, 'basicPackage')
              + this.getCreditCost('video', this.sliderValue.video, 'basicPackage')
              + this.getCreditCost('annotations', this.sliderValue.annotations, 'basicPackage');
    },
    totalMonthlyCostPro() {
      return this.getCreditCost('seats', this.sliderValue.seats, 'proPackage')
              + this.getCreditCost('training', this.sliderValue.mlops, 'proPackage')
              + this.getCreditCost('images', this.sliderValue.images, 'proPackage')
              + this.getCreditCost('video', this.sliderValue.video, 'proPackage')
              + this.getCreditCost('annotations', this.sliderValue.annotations, 'proPackage');
    },
    totalMonthlyCostBusiness() {
      return this.getCreditCost('seats', this.sliderValue.seats, 'businessPackage')
              + this.getCreditCost('training', this.sliderValue.mlops, 'businessPackage')
              + this.getCreditCost('images', this.sliderValue.images, 'businessPackage')
              + this.getCreditCost('video', this.sliderValue.video, 'businessPackage')
              + this.getCreditCost('annotations', this.sliderValue.annotations, 'businessPackage');
    },
    displayTotal() {
      if ((this.sliderValue.seats + this.sliderValue.mlops
      + this.sliderValue.images
      + this.sliderValue.video + this.sliderValue.annotations) === 0) {
        return 0;
      }
      const basic = Math.max(this.totalMonthlyCostBasic, this.costPackage.basicPackage?.package_cost_dollar);
      const pro = Math.max(this.totalMonthlyCostPro, this.costPackage.proPackage?.package_cost_dollar);
      const business = Math.max(this.totalMonthlyCostBusiness, this.costPackage.businessPackage?.package_cost_dollar);
      if (basic < this.costPackage.proPackage?.package_cost_dollar) {
        return basic;
      } else if (pro <= this.costPackage.businessPackage?.package_cost_dollar) {
        return pro;
      } else {
        return business;
      }
    },
    displayPlan() {
      if (this.displayTotal === 0) {
        return "Free";
      } else if (this.displayTotal < this.costPackage.proPackage?.package_cost_dollar) {
        return "Basic";
      } else if (this.displayTotal < this.costPackage.businessPackage?.package_cost_dollar) {
        return "Pro";
      } else {
        return "Business";
      }
    },
  },
  watch: {
    sliderValue: {
      deep: true,
      handler() {
        Object.keys(this.sliderValue).forEach((key) => {
          if (this.sliderValue[key] === "" || !this.sliderValue[key]) {
            this.sliderValue[key] = 0;
          }
          this.sliderInput[key] = this.sliderValue[key];
        });
      },
    },
  },
  created() {
    this.getFreeCostPackage();
    this.getBasicCostPackage();
    this.getProCostPackage();
    this.getBusinessCostPackage();
  },
  mounted() {
    const inputs = this.$refs.sliderInput.querySelectorAll('input[type=number]');
    inputs.forEach((input) => {
      input.onkeydown = function (e) {
        if (e.keyCode === 13) {
          input.blur();
        }
        if (e.target.value && e.target.value.length > 9) {
          return false;
        }
        if (!((e.keyCode > 95 && e.keyCode < 106)
      || (e.keyCode > 47 && e.keyCode < 58)
      || e.keyCode === 8)) {
          return false;
        }
        return true;
      };
    });
  },
  methods: {
    changeSanitizer,
    inputSanitizer,
    updateInput(key) {
      if (!this.sliderInput[key] || this.sliderInput[key] === "") {
        this.sliderValue[key] = 0;
      }
    },
    async getFreeCostPackage() {
      await this.dsConn.getCostPackageByName({ package_name: 'free' })
        .then((data) => {
          if (data.result) {
            this.costPackage.freePackage = data.result;
          }
        })
        .catch((e) => console.log(e));
    },
    async getBasicCostPackage() {
      await this.dsConn.getCostPackageByName({ package_name: 'basic' })
        .then((data) => {
          if (data.result) {
            this.costPackage.basicPackage = data.result;
          }
        })
        .catch((e) => console.log(e));
    },
    async getProCostPackage() {
      await this.dsConn.getCostPackageByName({ package_name: 'pro' })
        .then((data) => {
          if (data.result) {
            this.costPackage.proPackage = data.result;
          }
        })
        .catch((e) => console.log(e));
    },
    async getBusinessCostPackage() {
      await this.dsConn.getCostPackageByName({ package_name: 'business' })
        .then((data) => {
          if (data.result) {
            this.costPackage.businessPackage = data.result;
          }
        })
        .catch((e) => console.log(e));
    },
    roundToTwoDecimals(n) {
      const log10 = n ? Math.floor(Math.log10(n)) : 0;
      const div = log10 < 0 ? 10 ** (2 - log10 - 1) : 10 ** 2;
      const result = Math.round(n * div) / div;

      return result > 1 ? `${result}` : `${result * 100}`;
    },
    displayWithComma(x) {
      return Intl.NumberFormat('en-US').format(x);
    },
    contactSales() {
      window.open('https://support.deepviewml.com/hc/en-us/requests/new');
    },
    getCreditCost(itemName, total, pkg) {
      let credit_range = null;
      if (!this.costPackage[pkg]) {
        return 0;
      }
      switch (itemName) {
      case 'seats':
        credit_range = this.costPackage[pkg]['seats'];
        break;
      case 'images':
        credit_range = this.costPackage[pkg]['images'];
        break;
      case 'annotations':
        credit_range = this.costPackage[pkg]['annotations'];
        break;
      case 'video':
        credit_range = this.costPackage[pkg]['bulk_video'];
        break;
      case 'training':
        credit_range = this.costPackage[pkg]['training'];
        break;
      case 'auto_ann':
        credit_range = this.costPackage[pkg]['auto_ann'];
        break;
      case 'auto_seg':
        credit_range = this.costPackage[pkg]['auto_seg'];
        break;
      default:
      }
      let total_dollar_used = 0;
      let localTotal = total;

      credit_range.forEach((range, i) => {
        if (credit_range[i]['max'] === -1) {
          credit_range[i]['max'] = Infinity;
        }
        credit_range[i]['count'] = Math.min(credit_range[i]['max'], localTotal);
        credit_range[i]['cost_credits'] = credit_range[i]['count'] * credit_range[i]['crpu'];
        credit_range[i]['cost_dollars'] = credit_range[i]['cost_credits'] * this.costPackage[pkg]['dve_credits_cost_usd'];
        total_dollar_used += credit_range[i]['cost_dollars'];

        // move to next bracket
        localTotal -= credit_range[i]['count'];
      });
      return total_dollar_used;
    },
  },

};

</script>

<style scoped lang="scss">
.pricing-table {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 20px;
  background: rgba(255, 255, 255, 0.075);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(1px);
  -webkit-backdrop-filter: blur(1px);

  &__subtitle {
    display: flex;
    flex-direction: column;
    width: 60%;
    padding: 8px;
  }
}

table {
  border-radius: 4px;
  border-spacing: 10px 0;
  width: 100%;
  margin: 8px;
  color: var(--primary-text-color);

  .header-row {
    font-weight: 700;
  }

  .header-row-secondary {
    font-weight: 300;
    font-size: 0.9rem;

    td:first-child h3 {
      padding-left: 5px;
    }
  }

  .cell {
    display: flex;
  }

  // .cell + .cell {
  //   border-left:  0.5px solid rgba(96, 95, 95, 0.5);
  // }

  span {
    font-size: 1.06rem;
  }
}

tr:first-child th:nth-child(n+2) {
  padding-top: 10px;
  margin-left: 20px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom: 0.5px solid inherit;
}

td {
  height: 40px;
}

tr:nth-last-child(2) td:nth-child(n+2) {
  padding-bottom: 10px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

tr.header-row-secondary.description {
  background: rgba(255, 255, 255, 0.1);
}

.free {
  background: rgb(20, 110, 68);
}

.low {
  background: rgb(31, 95, 224);
}

.medium {
  background: rgb(148, 38, 19);
}

.high {
  background: #ae862e;
}

.highlight {
  font-weight: 600;
}

.cost-tag {
  font-weight: 400;
  font-size: 1.7rem !important;
}

.spaced {
  letter-spacing: 0.2rem;
}
.text-left{
  justify-content: flex-start;
}

.text-right{
  justify-content: flex-end;
}

.text-center{
  justify-content: center;
  align-items: center;
}

.text-italic {
  font-style: italic;
}

.tab {
  margin-left: 20px;
}

.sales-btn {
  background-color: var(--color-primary-400);
  border-radius: 6px;
  width: 300px;
  height: 50px;
  font-size: 16px;
  margin: 12px 0 36px;

  &:hover {
    background-color: var(--color-primary-300);
  }
}

.slider-pricing {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  .container {
    padding: 20px;
    margin: 20px 20px;
    background: rgba(17, 17, 17, 0.075);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(1px);
    -webkit-backdrop-filter: blur(1px);
    border: 1px solid rgba(255, 255, 255, 0.3);
  }
}

.slider-card{
  display: flex;
  flex-direction: column;
  text-align: left;
  width: 45%;

  .slider-title {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    &__text {
      display: flex;
      flex-direction: column;
    }
  }

  .slider-box-input {
    width: 120px;
  }

  .item {
    font-size: 1.2rem;
    font-weight: 600;
  }

  .subtitle {
    padding-bottom: 10px;
    font-size: 0.9rem;
  }
}

.total-price-card{
  display: flex;
  flex-direction: column;
  text-align: left;
  padding-top: 20px !important;
  width: 25%;

  button {
    width: 100%;
  }
}

.item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 10px;
  }

  .label {
    padding-top: 5px;
    font-size: 1.1rem;
    font-weight: 600;
  }
  .price {
    padding-top: 5px;
    font-size: 1.2rem;
    font-weight: 500;
  }

.unit {
    font-size: 0.9rem !important;
    font-weight: 500 !important;
  }

:deep() {

.range-slidercontainer {
  width: 100%;
}

.range-slider input[type='range'] {
  background: var(--color-white-500) !important;
}
.range-slider input[type='range']::-webkit-slider-thumb {
  background-color: rgb(31, 164, 221)  !important;
}
.range-slider input[type='range']:disabled::-webkit-slider-thumb {
  background-color: rgb(31, 164, 221) !important;
}
.range-slider input[type='range']::-webkit-slider-runnable-track {
  background: var(--color-white-500) !important;
}

.range-slider .range-slider-left {
  background: rgb(31, 164, 221)  !important;
}
}
</style>
