<template>
  <Modal
    ref="createTaskModal"
    :title="'AI Assisted Tasks'"
    :width="'600px'"
    @closed="resetData"
  >
    <!-- <template #modal-header /> -->
    <template #modal-main>
      <div class="form container">
        <div class="row">
          <div class="col-12 settings__control-group">
            <label>Task Type</label>
            <select
              id="type-select"
              v-model="mode"
              class="select"
              required
            >
              <option disabled :value="''">Select a Task Type</option>
              <option :value="'auto-ann'">Auto Annotation</option>
              <option :value="'auto-seg'">Auto Segmentation</option>
              <option :value="'sam'">Annotation to Segment</option>
            </select>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-12 settings__control-group">
            <label class="required">Task Name</label>
            <input
              v-model="task"
              class="select"
              type="text"
            >
          </div>
        </div>
        <template v-if="mode === 'auto-ann'">
          <div class="row mt-3">
            <div class="col-6 settings__control-group">
              <label class="required">Experiment</label>
              <select v-model="selectedExperiment" class="select" required>
                <option disabled :value="''">Select an Experiment</option>
                <option v-for="(trainer, i) in trainerList" :key="i" :value="trainer">
                  {{ trainer.name }}
                </option>
              </select>
            </div>
            <div class="col-6 settings__control-group">
              <label class="required">Training Session</label>
              <select v-model="selectedTrainingSession" class="select" required>
                <option disabled :value="''">Training Session</option>
                <option v-for="(session, i) in autoAnnTrainingSessions" :key="i" :value="session">
                  {{ session.name }}
                </option>
              </select>
            </div>
          </div>
          <div class="row mt-3">
            <div class="col-6 settings__control-group">
              <label class="required">Available Model</label>
              <select v-model="selectedModelFiles" class="select" required>
                <option disabled :value="''">Select a Model</option>
                <option v-for="(file, i) in selectedSessionAvailableFiles" :key="i" :value="file">
                  {{ file.model }}
                </option>
              </select>
            </div>
          </div>
        </template>
        <template v-if="mode === 'auto-seg'">
          <div class="row mt-3">
            <div class="col-6 settings__control-group">
              <label class="required">Experiment</label>
              <select v-model="selectedExperiment" class="select" required>
                <option disabled :value="''">Select an Experiment</option>
                <option v-for="(trainer, i) in trainerList" :key="i" :value="trainer">
                  {{ trainer.name }}
                </option>
              </select>
            </div>
            <div class="col-6 settings__control-group">
              <label class="required">Training Session</label>
              <select v-model="selectedTrainingSession" class="select" required>
                <option disabled :value="''">Training Session</option>
                <option v-for="(session, i) in autoSegTrainingSessions" :key="i" :value="session">
                  {{ session.name }}
                </option>
              </select>
            </div>
          </div>
          <div class="row mt-3">
            <div class="col-6 settings__control-group">
              <label class="required">Available Model</label>
              <select v-model="selectedModelFiles" class="select" required>
                <option disabled :value="''">Select a Model</option>
                <option v-for="(file, i) in selectedSessionAvailableFiles" :key="i" :value="file">
                  {{ file.model }}
                </option>
              </select>
            </div>
          </div>
        </template>
        <template v-if="mode === 'sam'">
          <div class="row mt-3">
            <div class="col-12 settings__control-group">
              <label class="required">Source Dataset</label>
              <div class="dataset-selector">
                <select v-model="segmentationSourceDataset" class="select" required>
                  <option :key="'train-select-null'" disabled :value="''">Select a Dataset</option>
                  <option v-for="d in datasets" :key="`test-select-${d.id}`" :value="d">
                    {{ d.name }}
                  </option>
                </select>
                <AnnotationSetsRadioSelect
                  v-model="segmentationSourceAnnset"
                  :annotationSets="segmentationSourceDataset.annotation_sets"
                />
              </div>
            </div>
          </div>
        </template>
        <div class="row mt-3">
          <div class="col-12 settings__control-group">
            <label class="required">Destination Dataset</label>
            <div class="dataset-selector">
              <select
                v-model="selectedDestinationDataset"
                class="select"
                required
                :disabled="mode === 'segmentation'"
              >
                <option :key="'train-select-null'" disabled :value="''">Select a Dataset</option>
                <option v-for="d in datasets" :key="`test-select-${d.id}`" :value="d">
                  {{ d.name }}
                </option>
              </select>
              <AnnotationSetsRadioSelect
                v-model="destinationAnnset"
                :annotationSets="selectedDestinationDataset.annotation_sets"
              />
            </div>
          </div>
        </div>
        <div v-if="message" class="row mt-3">
          <div class="col-12 settings__control-group">
            <div class="result error">
              <span>{{ message }}</span>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template #modal-footer>
      <div class="footer">
        <button
          class="button modal-action-button"
          :class="{'button-spinner': starting}"
          :disabled="starting"
          @click="start"
        >
          Create Task
        </button>
      </div>
    </template>
  </Modal>
</template>

<script>
import DatastoreConnect from '@/assets/js/DatastoreFunctions/datastore-interface';
import Modal from '@/components/Modal.vue';
import AnnotationSetsRadioSelect from '@/components/DatasetComponent/AnnotationSetsRadioSelect.vue';

export default {
  name: 'CreateAutoAnnotationTaskModal',
  components: {
    Modal,
    AnnotationSetsRadioSelect,
  },
  props: {
    datasets: {
      type: Array,
      default: () => [],
    },
    trainerList: {
      type: Array,
      default: () => [],
    },
  },
  emits: { 'task-created': null },
  data() {
    return {
      message: '',
      task: '',
      modelList: [
        { name: 'ModelPack', value: 'mpk' },
        { name: 'Yolo v8', value: 'yolo' },
      ],
      selectedModel: null,
      destinationAnnset: {},
      path: '',
      selectedDestinationDataset: '',
      selectedExperiment: '',
      selectedTrainingSession: '',
      mode: 'auto-ann',
      segmentationSourceDataset: '',
      segmentationSourceAnnset: {},
      starting: false,
      modelFileTypes: [
        {
          model: 'YOLO',
          files: ['best.pt'],
        },
        {
          model: 'Keras',
          files: ['best.h5'],
        },
        {
          model: 'Keras - two Stage',
          files: ['best_decoder.h5', 'best_backbone.h5'],
        },
        {
          model: 'Maivin RTM',
          files: ['best.rtm'],
        },
        {
          model: 'Maivin RTM - Two Stage',
          files: ['best_decoder.rtm', 'best_backbone.rtm'],
        },
      ],
      selectedSessionAvailableFiles: [],
      selectedModelFiles: '',
    };
  },
  computed: {
    isDebugMode() {
      return this.$store.state.debugMode;
    },
    trainingSessions() {
      if (this.selectedExperiment) {
        return this.selectedExperiment.training_sessions;
      }
      return [];
    },
    autoAnnTrainingSessions() {
      return this.trainingSessions.filter((e) => e.params?.trainer_params?.task.includes('detect'));
    },
    autoSegTrainingSessions() {
      return this.trainingSessions.filter((e) => e.params?.trainer_params?.task.includes('segment'));
    },
  },
  watch: {
    selectedExperiment() {
      this.selectedTrainingSession = '';
      this.selectedModelFiles = '';
    },
    segmentationSourceDataset(d) {
      if (this.mode === 'segmentation' && d) {
        this.selectedDestinationDataset = d;
      }
    },
    selectedTrainingSession(s) {
      this.selectedModelFiles = '';
      if (s) {
        this.getAllFiles();
      }
    },
  },
  created() {
    this.dataConnect = new DatastoreConnect(this.$store.state.enterpriseServerUrl);
  },
  methods: {
    showModal() {
      this.$refs.createTaskModal.showModal();
      this.resetData();
    },
    resetData() {
      this.task = '';
      this.selectedDestinationDataset = '';
      this.selectedExperiment = '';
      this.selectedTrainingSession = '';
      this.destinationAnnset = {};
      this.segmentationSourceDataset = '';
      this.segmentationSourceAnnset = {};
      this.mode = 'auto-ann';
      this.path = '';
      this.message = "";
      this.selectedSessionAvailableFiles = [];
      this.selectedModelFiles = '';
    },
    closeModal() {
      this.$refs.createTaskModal.closeModal();
      this.resetData();
    },
    async getAllFiles() {
      await this.dataConnect.convertorFindAllFiles({
        training_session_id: this.selectedTrainingSession.id,
      }).then((data) => {
        this.selectedSessionAvailableFiles = [];
        const allFiles = data.result ? Object.keys(data.result) : [];
        this.modelFileTypes.forEach((type) => {
          if (type.files.every((file) => allFiles.includes(file))) {
            this.selectedSessionAvailableFiles.push(type);
          }
        });
      }).catch((e) => console.log(e));
    },
    async start() {
      if (!this.task) {
        this.message = 'Missing task name';
        return;
      }
      if (this.mode === 'auto-ann') {
        if (!this.selectedExperiment) {
          this.message = 'Missing Experiment';
          return;
        }

        if (!this.selectedTrainingSession) {
          this.message = 'Missing Training Session';
          return;
        }

        if (Object.keys(this.selectedDestinationDataset).length === 0) {
          this.message = `Missing Dataset`;
          return;
        }

        if (Object.keys(this.destinationAnnset).length === 0) {
          this.message = `Missing Annotation Set`;
          return;
        }

        if (!this.selectedModelFiles) {
          this.message = `Missing Model File`;
          return;
        }

        const params = {
          annotation_set_id: this.destinationAnnset.id,
          task_name: this.task,
          auto_remove: true,
        };

        params.experiment_id = this.selectedExperiment.id;
        params.training_session_id = this.selectedTrainingSession.id;
        params.model_file = this.selectedModelFiles.files[0];

        if (this.selectedModelFiles.files.length > 1) {
          params.backbone = this.selectedModelFiles.files.find((e) => e.includes("backbone"));
          params.decoder = this.selectedModelFiles.files.find((e) => e.includes("decoder"));
        }

        if (this.isDebugMode) {
          params['auto_remove'] = false;
        }
        this.starting = true;
        await this.dataConnect.startAutoAnn(params)
          .then((data) => {
            if (data.result) {
              this.closeModal();
              this.$emit('task-created');
              this.starting = false;
            } else {
              this.starting = false;
              this.message = data.error.message;
            }
          })
          .catch((error) => {
          // TODO: handle error
            console.log(error);
          });
      } else if (this.mode === 'sam') {
        if (Object.keys(this.segmentationSourceDataset).length === 0) {
          this.message = `Missing Source Dataset`;
          return;
        }

        if (Object.keys(this.segmentationSourceAnnset).length === 0) {
          this.message = `Missing Source Annotation Set`;
          return;
        }

        if (Object.keys(this.selectedDestinationDataset).length === 0) {
          this.message = `Missing Destination Dataset`;
          return;
        }

        if (Object.keys(this.destinationAnnset).length === 0) {
          this.message = `Missing Destination Annotation Set`;
          return;
        }

        const params = {
          src_annotation_set_id: this.segmentationSourceAnnset.id,
          dst_annotation_set_id: this.destinationAnnset.id,
          task_name: this.task,
          auto_remove: true,
        };

        if (this.isDebugMode) {
          params['auto_remove'] = false;
        }
        this.starting = true;
        await this.dataConnect.startAutoSegmentation(params)
          .then((data) => {
            if (data.result) {
              this.closeModal();
              this.starting = false;
              this.$emit('task-created');
            } else {
              this.starting = false;
              this.message = data.error.message;
            }
          })
          .catch((error) => {
          // TODO: handle error
            console.log(error);
          });
      } else if (this.mode === 'auto-seg') {
        if (!this.selectedExperiment) {
          this.message = 'Missing Experiment';
          return;
        }

        if (!this.selectedTrainingSession) {
          this.message = 'Missing Training Session';
          return;
        }

        if (Object.keys(this.selectedDestinationDataset).length === 0) {
          this.message = `Missing Destination Dataset`;
          return;
        }

        if (Object.keys(this.destinationAnnset).length === 0) {
          this.message = `Missing Destination Annotation Set`;
          return;
        }

        if (!this.selectedModelFiles) {
          this.message = `Missing Model File`;
          return;
        }

        const params = {
          dst_annotation_set_id: this.destinationAnnset.id,
          task_name: this.task,
          auto_remove: true,
        };

        params.experiment_id = this.selectedExperiment.id;
        params.training_session_id = this.selectedTrainingSession.id;
        params.model_file = this.selectedModelFiles.files[0];

        if (this.selectedModelFiles.files.length > 1) {
          params.backbone = this.selectedModelFiles.files.find((e) => e.includes("backbone"));
          params.decoder = this.selectedModelFiles.files.find((e) => e.includes("decoder"));
        }

        if (this.isDebugMode) {
          params['auto_remove'] = false;
        }
        this.starting = true;
        await this.dataConnect.startAutoSegmentation(params)
          .then((data) => {
            if (data.result) {
              this.closeModal();
              this.starting = false;
              this.$emit('task-created');
            } else {
              this.starting = false;
              this.message = data.error.message;
            }
          })
          .catch((error) => {
          // TODO: handle error
            console.log(error);
          });
      }
    },
  },
};

</script>

<style lang="scss" scoped>
.form {
  padding: 0.75rem 3rem;
  min-width: 420px;
}

.dataset-selector{
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;

  select {
    width: 180px;
    margin-right: 20px;
  }

  button {
    width: 350px;
  }
}

.flex-start {
  align-items: flex-start;
}

.footer {
  display: flex;
  flex-direction: row;
  padding: 0.75rem 3rem;
  justify-content: flex-end;
}

.result {
  display: flex;
  width: 100%;
  height: 100%;
  border-radius: 5px;
  font-size: 1rem;
  font-weight:600;
  padding: 10px;
  align-items: center;
  justify-content: center;
}

.error {
  @include themify() {
    background: rgba(182, 94, 94, 0.5) ;
    color: themed('color-error')
  }
}
.row-switch {
  flex-direction: row !important;
  overflow: visible;
  align-items: center;

  label:first-child {
    flex: 1 1 40%;
    max-width: 40%;
  }
}

</style>
