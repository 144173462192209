<template>
  <Modal
    ref="annSetCopyModal"
    :width="'650px'"
    :title="'Copy Annotations'"
    @closed="clearSourceDest"
  >
    <template #modal-main>
      <div class="form container">
        <div class="row mt-3">
          <div class="col-12 settings__control-group">
            <label class="required">Source Dataset</label>
            <div class="dataset-selector">
              <select id="source-DS-select" v-model="localSource" class="select">
                <option :key="'source-select-null'" disabled :value="null">Select a Dataset</option>
                <option v-for="option in datasets" :key="`source-AS-select-${option.id}`" :value="option">
                  {{ option.name }}
                </option>
              </select>
              <AnnotationSetsRadioSelect
                :id="'copyDS-source'"
                v-model="chosenSourceAnnotationSet"
                :annotationSets="localSource ? localSource.annotation_sets : null"
              />
            </div>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-12 settings__control-group">
            <label class="required">Destination Dataset</label>
            <div class="dataset-selector">
              <select id="source-DS-select" v-model="localSource" class="select">
                <option :key="'source-select-null'" disabled :value="null">Select a Dataset</option>
                <option v-for="option in datasets" :key="`dest-AS-select-${option.id}`" :value="option">
                  {{ option.name }}
                </option>
              </select>
              <AnnotationSetsRadioSelect
                :id="'copyDS-source'"
                v-model="chosenDestAnnotationSet"
                :annotationSets="localSource ? localSource.annotation_sets : null"
              />
            </div>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-12 settings__control-group">
            <label><em>Only copy from annotation sets in the same dataset.</em></label>
          </div>
        </div>
        <div class="row mt-3 result-container">
          <div v-if="awaitMergerResult" class="loader merger-loader" />
          <div v-if="message && !awaitMergerResult" class="result" :class="[success ? 'success' : 'error']">
            <span>{{ message }}</span>
          </div>
        </div>
      </div>
    </template>
    <template #modal-footer>
      <div class="footer">
        <div>
          <button
            class="button modal-action-button"
            :disabled="awaitMergerResult ? true : false"
            @click="applyCopy"
          >
            Apply
          </button>
        </div>
      </div>
    </template>
  </Modal>
</template>

<script>
import '@vuepic/vue-datepicker/dist/main.css';
import Modal from '../../Modal.vue';
import DatastoreConnect from '../../../assets/js/DatastoreFunctions/datastore-interface';
import AnnotationSetsRadioSelect from '../AnnotationSetsRadioSelect.vue';

export default {
  name: 'AnnSetCopyModal',
  components: {
    Modal,
    AnnotationSetsRadioSelect,
  },
  props: {
    datasets: {
      type: Array,
      default: () => [],
    },
    sourceDataset: {
      type: Object,
      default: () => {},
    },
  },
  emits: {
    'clear-copy': null, 'copy-complete': null,
  },
  data() {
    return {
      localSource: null,
      localDest: null,
      awaitMergerResult: false,
      success: false,
      message: "",
      dsConn: null,
      chosenSourceAnnotationSet: {},
      chosenDestAnnotationSet: {},
    };
  },
  mounted() {
    this.dsConn = new DatastoreConnect(this.$store.state.enterpriseServerUrl);
  },
  methods: {
    // async getAnnotationSetsForSourceDataset() {
    //   await this.dsConn.getAnnotationSets({ dataset_id: this.localSource.id })
    //     .then((data) => {
    //       this.localSourceAnnotationSets = data.result;
    //     })
    //     .catch((error) => {
    //       console.log('Failed to retrieve sets:', error);
    //     });
    // },
    // async getAnnotationSetsForDestDataset() {
    //   await this.dsConn.getAnnotationSets({ dataset_id: this.localDest.id })
    //     .then((data) => {
    //       this.localDestAnnotationSets = data.result;
    //     })
    //     .catch((error) => {
    //       console.log('Failed to retrieve sets:', error);
    //     });
    // },
    showModal() {
      this.$refs.annSetCopyModal.showModal();
    },
    clearSourceDest() {
      this.message = "";
      this.$emit('clear-copy');
    },
    async applyCopy() {
      if (!this.localSource) {
        this.message = `Error: Please select Source dataset`;
        this.success = false;
        return;
      }
      if (Object.keys(this.chosenSourceAnnotationSet) === 0) {
        this.message = `Error: Please select Source annotation set`;
        this.success = false;
        return;
      }
      if (Object.keys(this.chosenDestAnnotationSet) === 0) {
        this.message = `Error: Please select Destination annotation set`;
        this.success = false;
        return;
      }
      if (this.chosenSourceAnnotationSet.id === this.chosenDestAnnotationSet.id) {
        this.message = `Error: Source and Destination sets must be different`;
        this.success = false;
        return;
      }
      this.awaitMergerResult = true;
      const params = {
        annotation_set_id_src: this.chosenSourceAnnotationSet.id,
        annotation_set_id_dst: this.chosenDestAnnotationSet.id,
      };
      this.awaitMergerResult = true;
      await this.dsConn.copyAnnotationSet(params)
        .then((response) => {
          if (response.result) {
            this.message = response.result;
            this.success = true;
            this.$emit('copy-complete', params.annotation_set_id_dst);
          } else if (response.error) {
            this.message = `Error: ${response.error}`;
            this.success = false;
          } else {
            this.message = `Error Copying data`;
            this.success = false;
          }
          this.awaitMergerResult = false;
        })
        .catch((e) => {
          this.message = `Error: ${e}`;
          this.success = false;
          this.awaitMergerResult = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>

.form {
  padding: 0.75rem 3rem;
  min-width: 420px;
}

.dataset-selector{
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;

  select {
    width: 180px;
    margin-right: 20px;
  }

  button {
    width: 350px;
  }
}

.merge-button {
  height: 40px;
  width: 80px;
  border-radius: 6px;
  padding: 2px 10px;
  margin-top: 10px;
  box-shadow: 0 1px 3px 0 rgb(0 0 0 / 20%);
  @include themify() {
    background: themed('button-active');
    color: themed('button-active-text');
  }
}

.result {
  display: flex;
  width: 100%;
  height: 100%;
  border-radius: 5px;
  font-size: 1rem;
  font-weight:600;
  padding: 3px 10px;
  align-items: center;
  justify-content: center;
}

.result-container {
  justify-content: center;
}

.success {
  @include themify() {
    background: rgba(115, 206, 115, 0.5) ;
    color: themed('color-succes');
    outline: 1px solid themed('color-succes');
  }
}

.error {
  @include themify() {
    background: rgba(182, 94, 94, 0.5) ;
    color: themed('color-error');
    outline: 1px solid themed('color-error');
  }
}

.merger-loader {
  width: 60px !important;
  height: 60px !important;
}

.footer {
  display: flex;
  flex-direction: row;
  padding: 0.75rem 3rem;
  justify-content: flex-end;
}

</style>
