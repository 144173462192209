<template>
  <div class="annotation-tool-select">
    <v-select
      v-model="selectedTool"
      label="Audit Tool Type"
      :items="options"
      density="compact"
      variant="outlined"
      bg-color="transparent"
      hide-details="true"
    >
      <template #item="{ props }">
        <v-list-item v-bind="props" :subtitle="props.subtitle" />
      </template>
    </v-select>
  </div>
</template>

<script>

export default {
  name: 'AuditToolSelect',
  props: {
    modelValue: {
      type: String,
      default: 'image',
    },
  },
  emits: ['update:modelValue'],
  data() {
    return {
      options: [
        {
          title: 'Image', value: 'image', props: { subtitle: 'Audit the full image with all annotations' },
        }, {
          title: 'Annotation', value: 'annotation', props: { subtitle: 'Audit individual annotations' },
        },
      ],
      selectedTool: 'image',
    };
  },
  watch: {
    modelValue() {
      this.selectedTool = this.modelValue;
    },
    selectedTool() {
      this.$emit('update:modelValue', this.selectedTool);
    },
  },
  mounted() {
    if (this.modelValue) {
      this.selectedTool = this.modelValue;
    }
  },
};
</script>

<style lang="scss" scoped>
.annotation-tool-select {
  display: flex;
  flex-direction: row;
  min-width: 182px;
}

.key {
  box-sizing: border-box;
  padding: 4px;
  line-height: 1;
  font-size: 12px;
  text-align: center;
  width: fit-content;
  color: #555;
  border-color: var(--gray-400);
  border-style: solid;
  text-shadow: 0 0.5px 1px #777, 0 2px 6px #f2f2f2;
  border-width: 1px;
  border-radius: 4px;
  background: -webkit-linear-gradient(top, #f9f9f9 0%, #D2D2D2 80%, #c0c0c0 100%);
  font-family: sans-serif;
  display: inline-block;
  transition: box-shadow 0.3s ease, transform 0.15s ease;
}
</style>
