<template>
  <TriRangeSlider
    v-model:valueLower="lowerValue"
    v-model:valueUpper="upperValue"
    :step="step"
    :min="min"
    :max="max"
    :showMiddle="true"
  />
</template>

<script>
import TriRangeSlider from '@/components/TriRangeSlider.vue';
import * as utils from '@/assets/js/utils.js';

export default {
  name: "TrainValTestSplitSlider",
  components: {
    TriRangeSlider,
  },
  props: {
    modelValue: {
      type: Array,
      default: () => [],
    },
  },
  emits: [
    'update:modelValue',
  ],
  data() {
    return {
      min: 0,
      max: 100,
      lowerValue: 80,
      upperValue: 100,
      step: 1,
    };
  },
  computed: {
    isActive() {
      return this.lowerValue > 0 || this.upperValue < 1;
    },
    tagValue() {
      return `${this.lowerValue.toFixed()} - ${this.upperValue.toFixed()}`;
    },
    result() {
      return [this.lowerValue, this.upperValue - this.lowerValue, this.max - this.upperValue];
    },
  },
  watch: {
    lowerValue() {
      this.debouncedUpdateFilters();
    },
    upperValue() {
      this.debouncedUpdateFilters();
    },
  },
  created() {
    this.debouncedUpdateFilters = utils.debounce(this.updateFilters, 10);
  },
  mounted() {
    if (this.modelValue) {
      this.lowerValue = this.modelValue[0];
      this.upperValue = this.modelValue[1] + this.lowerValue;
    }
  },
  methods: {
    async updateFilters() {
      if (!this.isActive) {
        this.$emit('update:modelValue', null);
        return;
      }
      this.$emit('update:modelValue', this.result);
    },
  },
};
</script>
