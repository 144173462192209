<template>
  <v-footer
    class="p-0 footer"
  >
    <div class="controls">
      <Scrubber
        v-model="videoPlayerStore.currentTime"
        :max="videoPlayerStore.duration"
        :secondary="endBuffer"
      >
        <template #default="{ position, pendingValue }">
          <div
            class="time-popup"
            :style="{ left: position }"
          >
            {{ formatDuration(pendingValue) }}
          </div>
        </template>
      </Scrubber>
      <div class="controls__bottom">
        <button class="controls__play-button" @click="videoPlayerStore.playing = !videoPlayerStore.playing">
          <SVGIcon
            v-if="!videoPlayerStore.playing"
            :iconName="'play'"
            :width="'20px'"
            :height="'20px'"
          />
          <SVGIcon
            v-else
            :iconName="'pause'"
            :width="'20px'"
            :height="'20px'"
          />
        </button>
        <button ref="volumeButton" class="controls__volume-button" @click="videoPlayerStore.muted = !videoPlayerStore.muted">
          <SVGIcon
            v-if="videoPlayerStore.muted"
            :iconName="'volume_off'"
            :width="'20px'"
            :height="'20px'"
          />
          <SVGIcon
            v-else
            :iconName="'volume_up'"
            :width="'20px'"
            :height="'20px'"
          />
        </button>
        <div class="controls__volume-bar" :class="{'_show': isVolumeButtonHovered}">
          <Scrubber
            ref="volumeBar"
            v-model="videoPlayerStore.volume"
            :max="1"
          />
        </div>
        <div
          class="flex flex-col flex-1 text-sm ml-2"
        >
          {{ formatDuration(videoPlayerStore.currentTime) }} / {{ formatDuration(videoPlayerStore.duration) }}
        </div>
      </div>
    </div>
  </v-footer>
</template>

<script setup>
import Scrubber from '@/components/DatasetComponent/AnnotationTool/VideoControls/Scrubber.vue';
import SVGIcon from '@/components/SVGIcon.vue';
import { useVideoPlayerStore } from '@/stores/useVideoPlayerStore.js';
import { ref, computed } from 'vue';
import { useElementHover } from '@vueuse/core';

const videoPlayerStore = useVideoPlayerStore();

const endBuffer = computed(() => (videoPlayerStore.buffered.length > 0 ? videoPlayerStore.buffered[videoPlayerStore.buffered.length - 1][1] : 0));

const volumeButton = ref();
const isVolumeButtonHovered = useElementHover(volumeButton);

function formatDuration(seconds) {
  return new Date(1000 * seconds).toISOString().slice(14, 19);
}

</script>

<style lang="scss" scoped>
.footer {
  z-index: 10;
  border-left: 1px solid rgba(0,0,0,0.25);
  min-height: 48px;
  background: var(--body-color);
}

.controls {
  display: flex;
  flex-direction: column;
  flex: 1 1 100%;
  width: 100%;
  height: 100%;
  padding: 0 1rem;

  &__bottom {
    display: flex;
    flex: 1 1 auto;
    flex-direction: row;
    align-items: center;
  }

  button:hover {
    background: rgba(0,0,0,0.125);
  }

  &__play-button {
    width: 28px;
    height: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__volume-button {
    width: 28px;
    height: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__volume-bar {
    width: 0;
    height: 100%;
    justify-content: center;
    display: flex;
    flex-direction: column;
    transition: margin .2s cubic-bezier(.4,0,1,1),width .2s cubic-bezier(.4,0,1,1);
  }
  &__volume-bar._show, &__volume-bar:hover {
    width: 116px;
    padding: 0 8px;
    transition: margin .2s cubic-bezier(.4,0,1,1),width .2s cubic-bezier(.4,0,1,1);
  }
}

.time-popup {
  position: absolute;
  transform: translateX(-50%);
  background-color: #000;
  border-radius: 0.375rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  bottom: 0;
  margin-bottom: 1rem;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  font-size: 0.75rem;
  color: #fff;
}

</style>
