<template>
  <div class="settings scrollbar">
    <span class="header">Create Organization</span>
    <div class="row mt-4">
      <label class="label required">Name</label>
      <div class="value">
        <input
          v-model="name"
          class="select"
          type="text"
          :class="{ 'error' : errorName }"
        >
      </div>
    </div>
    <div class="row mt-4">
      <label class="label">Street</label>
      <div class="value">
        <input
          v-model="street"
          class="select"
          type="text"
        >
      </div>
    </div>
    <div class="row mt-4">
      <label class="label">City</label>
      <div class="value">
        <input
          v-model="city"
          class="select"
          type="text"
        >
      </div>
    </div>
    <div class="row mt-4">
      <label class="label">Country</label>
      <div class="value">
        <input
          v-model="country"
          class="select"
          type="text"
        >
      </div>
    </div>
    <div class="row mt-4">
      <label class="label">Contact Name</label>
      <div class="value">
        <input
          v-model="contactName"
          class="select"
          type="text"
        >
      </div>
    </div>
    <div class="row mt-4">
      <label class="label">Contact Email</label>
      <div class="value">
        <input
          v-model="contactEmail"
          class="select"
          type="text"
        >
      </div>
    </div>
    <div class="row mt-4">
      <label class="label">Contact Phone</label>
      <div class="value">
        <input
          v-model="contactPhone"
          class="select"
          type="text"
        >
      </div>
    </div>
    <div v-if="message" class="row mt-2">
      <label class="label" />
      <div class="value" :class="{ 'error-text': isError, 'success-text' : !isError }">{{ message }}</div>
    </div>
    <div class="row mt-3 footer">
      <button class="button button-sm rounded" @click.stop="createOrg">Create</button>
      <span @click.stop="$router.go(-1)">Cancel</span>
    </div>
  </div>
</template>

<script>
import DatastoreConnect from '@/assets/js/DatastoreFunctions/datastore-interface';

export default {
  name: 'AddNewOrganization',
  components: {
  },
  data() {
    return {
      dsConn: new DatastoreConnect(this.$store.state.enterpriseServerUrl),
      name: '',
      errorName: false,
      isError: false,
      street: '',
      city: '',
      country: '',
      contactName: '',
      contactEmail: '',
      contactPhone: '',
      message: '',
    };
  },
  computed: {
    user() {
      return this.$store.state.user.user;
    },
    isSuper() {
      return this.user && (this.user.role === 'super');
    },
  },
  created() {
    if (!this.isSuper) {
      this.$router.push('/login');
    }
  },
  methods: {
    async createOrg() {
      this.isError = false;
      if (!this.name) {
        this.errorName = true;
        this.message = 'Missing Organization name.';
        return;
      }
      const params = {
        name: this.name,
        parent_username: this.user.username,
      };
      if (this.street) {
        params.street = this.street;
      }
      if (this.city) {
        params.city = this.city;
      }
      if (this.country) {
        params.country = this.country;
      }
      if (this.contactName) {
        params.contact_name = this.contactName;
      }
      if (this.contactEmail) {
        params.contact_email = this.contactEmail;
      }
      if (this.contactPhone) {
        params.contact_phone = this.contactPhone;
      }
      await this.dsConn.createOrg(params)
        .then((data) => {
          if (data.result) {
            this.message = "Organization created successfully.";
          } else {
            this.message = data.error.message;
            this.isError = false;
          }
        })
        .catch((e) => {
          this.message = e;
          this.isError = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.settings {
  display: flex;
  flex-direction: column;
  width: 600px;
  min-width: 400px;
  height: 100%;
  padding: 30px;
  overflow-y: auto;
}

.header {
  font-size: 1.2rem;
  font-weight: 700;
}

.row {
  flex-wrap: nowrap !important;
}

.row > .label {
  display: flex;
  justify-content: end;
  align-items: center;
  width: 200px;
  text-align: right;
  font-size: 0.9rem;
  font-weight: 600;
  color: rgba(grey, 0.9);

  &.required::after {
    content: '*';
    font-size: 14px;
    font-weight: 700;
    padding-left: 1px;
    @include themify() {
      color: themed('color-primary');
    }
  }
}

.row > .value {
  flex: 1 1 auto;
  font-size: 0.9rem;
  margin-left: 20px;
}

.select {
  width: 100%;
  height: 30px;
  border-radius: 5px;
  font-size: 1rem;
  padding: 3px 10px;
  align-self: center;
  -webkit-appearance: auto;
  background: rgba(#ffffff, 0.8);
  @include themify() {
    border: 1px solid themed('form-input-border');
  }

}

.select:focus {
  @include themify() {
    border: 1px solid themed('color-primary');
    box-shadow:  0 0 0 1px themed('color-primary'), 0 0 3px themed('color-primary-300');
  }
  outline: 2px solid transparent;
  outline-offset: 2px;
}

input.error , select.error {
  @include themify() {
    border: 1px solid themed('color-red') !important;
    box-shadow:  0 0 0 1px themed('color-red'), 0 0 3px themed('color-red') !important;
  }

  &:focus, &:focus-visible {
    @include themify() {
      border: 1px solid themed('color-red') !important;
      box-shadow:  0 0 0 1px themed('color-red'), 0 0 3px themed('color-red') !important;
    }
  }
}

.error-text {
  color: $color-red-500;
}

.success-text {
  color: $color-success;
}

.footer {
  display: flex;
  justify-content: flex-end;
  button {
    width: fit-content
  }
  span {
    color: $color-link;
    text-decoration: underline;
    cursor: pointer;
    width: fit-content;
  }
}
</style>
