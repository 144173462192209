<template>
  <div class="annotation-display-filter-button">
    <FilterPopper>
      <template #default="{ menuOpen }">
        <button class="button sets-toggle-button" :class="{'active': menuOpen || displayActive}">
          Display Annotation Sets
          <SVGIcon
            :iconName="'expand_more'"
            :width="'20px'"
            :height="'20px'"
          />
        </button>
      </template>
      <template #popper>
        <div class="filter-settings scrollbar">
          <template v-for="(set, index) in annotationSets" :key="index">
            <label
              :for="`menuItem-${uid}-${set.name}-${index}`"
              class="filter-settings__row filter-settings__row-button"
            >
              <div class="filter-settings__row-button-check me-2">
                <SVGIcon
                  v-if="menuValue.displayAnnotationSets.annotation_sets.includes(set.id)"
                  :iconName="'check'"
                  :height="'20px'"
                  :width="'20px'"
                />
              </div>
              <input
                :id="`menuItem-${uid}-${set.name}-${index}`"
                v-model="menuValue.displayAnnotationSets.annotation_sets"
                type="checkbox"
                :value="set.id"
              >
              <div class="filter-settings__row-button-legend" :style="{ background: annotationSetColorMap[set.id] }" />
              <span>{{ set.name }}</span>
            </label>
          </template>
        </div>
      </template>
    </FilterPopper>
    <button
      v-if="showSplitAnnotationOption && menuValue.displayAnnotationSets.annotation_sets.length > 0"
      class="sets-toggle-button"
      :class="{'active': splitActive}"
      title="Split Annotation Display"
      @click="handleSplitAnnotations"
    >
      <SVGIcon
        :iconName="'call_split'"
        :height="'20px'"
        :width="'20px'"
      />
    </button>
    <FilterPopper>
      <button class="settings-toggle-button" title="Annotation Display Settings" @click.stop="">
        <SVGIcon
          :iconName="'settings'"
          :width="24"
          :height="24"
        />
      </button>
      <template #popper>
        <div class="filter-settings">
          <template v-for="(menuItem, index) in menuItems" :key="index">
            <FilterPopper
              :placement="'right-start'"
              :offset="[-4,0]"
              :trigger="'hover'"
            >
              <button
                class="filter-settings__row filter-settings__row-button"
                @mouseover="hoveredDisplayType = menuItem.key"
                @focusin="hoveredDisplayType = menuItem.key"
              >
                <label :for="`menuItem-${menuItem.key}`">{{ menuItem.label }}</label>
                <SVGIcon
                  class="filter-settings__row-button-chevron-right"
                  :iconName="'chevron_right'"
                  :height="'20px'"
                  :width="'20px'"
                />
              </button>
              <template #popper>
                <div v-if="hoveredDisplayType === menuItem.key && menuValue[menuItem.key]" class="filter-settings scrollbar">
                  <template v-for="(submenuItem, submenuIndex) in menuItem.submenu" :key="submenuIndex">
                    <!-- Radio -->
                    <label
                      v-if="submenuItem.type === 'radio'"
                      :for="`menuItem-${uid}-${submenuItem.value}-${submenuIndex}`"
                      class="filter-settings__row filter-settings__row-button"
                    >
                      <div class="filter-settings__row-button-check me-2">
                        <SVGIcon
                          v-if="menuValue[menuItem.key][submenuItem.group] === submenuItem.value"
                          :iconName="'check'"
                          :height="'20px'"
                          :width="'20px'"
                        />
                      </div>
                      <input
                        :id="`menuItem-${uid}-${submenuItem.value}-${submenuIndex}`"
                        v-model="menuValue[menuItem.key][submenuItem.group]"
                        type="radio"
                        :name="menuItem.key"
                        :value="submenuItem.value"
                      >
                      <span>{{ submenuItem.label }}</span>
                    </label>
                    <!-- Checkbox -->
                    <label
                      v-else-if="submenuItem.type === 'checkbox'"
                      :for="`menuItem-${uid}-${submenuItem.value}-${submenuIndex}`"
                      class="filter-settings__row filter-settings__row-button"
                    >
                      <div class="filter-settings__row-button-check me-2">
                        <SVGIcon
                          v-if="menuValue[menuItem.key][submenuItem.group].includes(submenuItem.value)"
                          :iconName="'check'"
                          :height="'20px'"
                          :width="'20px'"
                        />
                      </div>
                      <input
                        :id="`menuItem-${uid}-${submenuItem.value}-${submenuIndex}`"
                        v-model="menuValue[menuItem.key][submenuItem.group]"
                        type="checkbox"
                        :value="submenuItem.value"
                      >
                      <div v-if="submenuItem.color" class="filter-settings__row-button-legend" :style="{ background: submenuItem.color }" />
                      <span>{{ submenuItem.label }}</span>
                    </label>
                  </template>
                </div>
              </template>
            </FilterPopper>
          </template>
        </div>
      </template>
    </FilterPopper>
  </div>
</template>

<script>
import FilterPopper from '@/components/FilterPopper.vue';
import SVGIcon from '@/components/SVGIcon.vue';
import useAnnotationColorMap from '@/composables/useAnnotationColorMap.js';
import { toRefs } from 'vue';

let uid = 0;

export default {
  name: "DisplayAnnotationsFilter",
  components: {
    FilterPopper,
    SVGIcon,
  },
  props: {
    annotationSets: {
      type: Array,
      default: null,
    },
    labels: {
      type: Array,
      default: () => [],
    },
    modelValue: {
      type: Object,
      default: null,
    },
    showSplitAnnotationOption: {
      type: Boolean,
      default: false,
    },
  },
  emits: [
    'update:modelValue',
  ],
  setup(props) {
    const { labels, annotationSets } = toRefs(props);
    const { map: labelColorMap } = useAnnotationColorMap({ items: labels, key: 'index' });
    const { map: annotationSetColorMap } = useAnnotationColorMap({ items: annotationSets, key: 'id' });
    return {
      labelColorMap,
      annotationSetColorMap,
    };
  },
  data() {
    uid += 1;
    return {
      menuValue: {
        displayAnnotationSets: {
          annotation_sets: [],
        },
        displayLabels: {
          labels: [],
        },
        colorAnnotationsBy: {
          type: "label",
        },
        splitAnnotations: false,
      },
      hoveredDisplayType: null,
      selectedAnnotationSets: [],
      selectedAnnotationLabels: {},
      uid: `DisplayAnnotationsFilter-${uid}`,
    };
  },
  computed: {
    labelMenuItems() {
      if (this.labels.length > 0) {
        return this.labels.map((label) => ({
          key: label.index, value: label.index, label: label.name, type: 'checkbox', group: 'labels', default: true,
        }));
      }
      return [];
    },
    menuItems() {
      return [
        {
          key: 'displayLabels',
          label: 'Labels to Display',
          submenu: [...this.labelMenuItems],
        },
        {
          key: 'colorAnnotationsBy',
          label: 'Color Annotations By',
          submenu: [
            {
              value: 'label', label: 'Label', type: 'radio', group: 'type', default: true,
            },
            {
              value: 'set', label: 'Annotation Set', type: 'radio', group: 'type',
            },
          ],
        },
      ];
    },
    dataReady() {
      if (this.annotationSets && this.labels) {
        return true;
      }
      return false;
    },
    displayActive() {
      return this.menuValue?.displayAnnotationSets?.annotation_sets?.length > 0;
    },
    splitActive() {
      return this.menuValue?.splitAnnotations;
    },
  },
  watch: {
    dataReady: {
      immediate: true,
      handler(ready) {
        if (ready) {
          this.mapMenuItemValue(this.menuItems, this.menuValue);
        }
      },
    },
    menuValue: {
      deep: true,
      handler(menuValue) {
        this.$emit('update:modelValue', menuValue);
      },
    },
    labels() {
      this.mapMenuItemValue(this.menuItems, this.menuValue);
    },
    annotationSets(newSets) {
      this.menuValue.displayAnnotationSets.annotation_sets = this.menuValue?.displayAnnotationSets?.annotation_sets
        .filter((existingSet) => newSets.includes(existingSet));
    },
    'menuValue.displayAnnotationSets.annotation_sets': {
      handler(annotation_sets) {
        if (Object.keys(annotation_sets).length === 0) {
          this.menuValue.splitAnnotations = false;
        }
      },
    },
  },
  mounted() {
    this.$nextTick(() => {
      if (this.modelValue) {
        Object.keys(this.modelValue).forEach((key) => {
          this.menuValue[key] = this.modelValue[key];
        });
      }
      this.$emit('update:modelValue', this.menuValue);
    });
  },
  methods: {
    // TODO: Simplify this so all values are on first level of the result object (even if it is a submenu value)
    mapMenuItemValue(menuItems, parentValue) {
      menuItems.forEach((item) => {
        if (item.submenu) {
          parentValue[item.key] = {};
          // Get unique groups
          const groups = item.submenu.map((submenuItem) => ({ group: submenuItem.group, type: submenuItem.type })).reduce((unique, o) => {
            if (!unique.some((obj) => obj.label === o.label && obj.value === o.value)) {
              unique.push(o);
            }
            return unique;
          }, []);
          if (groups) {
            groups.forEach((group) => {
              if (group.type === 'radio') {
                parentValue[item.key][group.group] = null;
              }
              if (group.type === 'checkbox') {
                parentValue[item.key][group.group] = [];
              }
            });
          }
        } else if (item.default) {
          if (item.group) {
            if (item.type === 'radio') {
              parentValue[item.group] = item.value;
            }
            if (item.type === 'checkbox') {
              parentValue[item.group].push(item.value);
            }
          } else {
            parentValue[item.key] = item.default;
          }
        }

        if (item.submenu) {
          this.mapMenuItemValue(item.submenu, parentValue[item.key]);
        }
      });
    },
    handleSplitAnnotations() {
      this.menuValue.splitAnnotations = !this.menuValue.splitAnnotations;
    },
  },
};
</script>

<style lang="scss" scoped>

.filter-tag {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 18px;
  padding: 0 6px;
  height: 18px;
  @include themify() {
    background: themed('color-accent');
  }
}

.filter-settings {
  position: relative;
  display: flex;
  flex-direction: column;
  border-radius: inherit;
  text-transform: none;
  min-width: 100px;

  &.scrollbar {
    overflow: auto;
  }

  &__row {
    display: flex;
    align-items: center;
    position: relative;
    max-width: 500px;
  }

  &__row-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: transparent;
    border: none;
    padding: 6px 8px;
    cursor: pointer;

    &:first-of-type {
      border-top-left-radius: inherit;
      border-top-right-radius: inherit;
    }
    &:last-of-type {
      border-bottom-left-radius: inherit;
      border-bottom-right-radius: inherit;
    }

    &:hover {
      background: rgba(0,0,0,0.125);
    }
    &:focus-within {
      background: rgba(0,0,0,0.125);
    }

    &> * {
      pointer-events: none;
    }

    input {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      opacity: 0;
      margin: 0;
    }

    span, label {
      font-size: 0.825rem;
      font-weight: 500;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }

  &__row-button-check {
    width: 20px;
    min-width: 20px;
    height: 20px;
    min-height: 20px;
    @include themify() {
      color: themed('filter-button-list-row-button-selected')
    }
  }

  &__row-button-legend {
    min-width: 10px;
    min-height: 10px;
    margin-right: 12px;
    border-radius: 100%;
  }

  &__row-button-chevron-right {
    margin-left: auto;
    padding-left: 4px;
  }
}

.sets-toggle-button, .settings-toggle-button {
  background: white;

}

.annotation-display-filter-button {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 26px;
  padding: 0;
  box-shadow: 1px 2px 4px 1px rgba(0, 0, 0, 0.2), 0 0 1px 1px rgba(0, 0, 0, 1);
  @include themify() {
    color: themed('body-text-color')
  }
}

.sets-toggle-button {
  display: flex;
  align-items: center;
  height: 100%;
  flex: 1 1 auto;
  padding: 4px 8px;
  gap: 2px;
  border-right: solid 1px rgba(0,0,0,0.5);
  color: inherit;

  &:hover {
    box-shadow: 0 0 2px 1px rgba(var(--color-primary-rgb), 0.2) inset;
    background: rgba(var(--color-primary-100-rgb), 0.5);
  }

  &:active {
    opacity: 1;
  }

  &.active {
    @include themify() {
      box-shadow: 0 0 2px 1px rgba(var(--color-primary-rgb), 0.5) inset;
      background: rgba(var(--color-primary-100-rgb), 0.8);
    }
  }
}

.settings-toggle-button {
  display: flex;
  align-items: center;
  padding: 0 4px;
  color: inherit;

  &:active {
    opacity: 1;
  }
}
</style>
