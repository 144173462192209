<template>
  <FilterPopper v-model:show="isOpen">
    <template #default="{ menuOpen }">
      <BaseFilterButton
        :name="'Group'"
        :active="isActive"
        :tagValue="tagValue"
        :menuOpen="menuOpen"
      />
    </template>
    <template #popper>
      <div class="filter-settings scrollbar">
        <div v-if="groups && groups.length > 0">
          <div v-for="(group, i) in groups" :key="i" class="filter-settings__row">
            <input
              :id="`groupFilter-group-${i}`"
              v-model="selectedGroups"
              type="checkbox"
              :name="`${group}-${i}`"
              :value="group.id"
            >
            <label :for="`groupFilter-group-${i}`">{{ group.name }}</label><br>
          </div>
        </div>
        <div v-else>
          No groups in current dataset.
        </div>
      </div>

      <div class="filter-footer">
        <button :disabled="!isActive" class="button-text clear-button" @click="clearSelection">Clear Selection</button>
      </div>
    </template>
  </FilterPopper>
</template>

<script>
import FilterPopper from '@/components/FilterPopper.vue';
import BaseFilterButton from '@/components/BaseFilterButton.vue';

export default {
  name: "GroupsFilter",
  components: {
    FilterPopper,
    BaseFilterButton,
  },
  props: {
    modelValue: {
      type: Array,
      default: () => [],
    },
    dataset: {
      type: Object,
      default: null,
    },
  },
  emits: [
    'update:modelValue',
  ],
  data() {
    return {
      isOpen: false,
      selectedGroups: [],
      groups: null,
    };
  },
  computed: {
    isActive() {
      return this.selectedGroups.length > 0;
    },
    tagValue() {
      return this.selectedGroups.length.toString();
    },
  },
  watch: {
    modelValue(newVal) {
      this.selectedGroups = newVal;
    },
    selectedGroups() {
      this.updateFilters();
    },
    isOpen(isOpen) {
      if (isOpen) {
        if (this.dataset.groups) {
          this.groups = this.dataset?.groups;
        } else {
          this.groups = null;
        }
      }
    },
    dataset() {
      this.selectedGroups = [];
    },
  },
  methods: {
    updateFilters() {
      this.$emit('update:modelValue', this.selectedGroups);
    },
    clearSelection() {
      this.selectedGroups = [];
    },
  },
};
</script>

<style lang="scss" scoped>
.filter-settings {
  display: flex;
  flex-direction: column;
  padding: 8px;
  border-radius: inherit;
  gap: 6px;
  min-width: 200px;
  overflow: auto;

  &__row {
    display: flex;
    align-items: center;
  }

  & input[type=checkbox] {
    margin-right: 12px;
  }
}

.filter-footer {
  min-height: 38px;
  width: 100%;
  border-top: 1px solid rgba(140,140,140,0.375);
  padding: 8px;
  text-align: center;

  .clear-button {
    @include themify() {
      color: themed('color-danger');
    }

    &[disabled] {
      @include themify() {
        color: themed('color-disabled');
      }
    }
  }
}

.fade-enter-active, .fade-leave-active {
  transition: opacity 0.1s ease;
}
.fade-enter-from, .fade-leave-to {
  opacity: 0;
}

:deep(.v-skeleton-loader__list-item) {
  margin: 4px;
}
</style>
