<template>
  <router-view />
</template>

<script>
import DatastoreConnect from '@/assets/js/DatastoreFunctions/datastore-interface';

export default {
  components: {
  },
  data() {
    return {
      routerIsReady: false,
    };
  },
  computed: {
    currentProject() {
      return this.$store.state.projects.currentProject;
    },
    currentRoutePath() {
      if (this.routerIsReady) {
        return this.$route.path;
      }
      return null;
    },
    isTokenExpired() {
      const currentToken = this.$store.state.user.token;
      if (this.$store.state.user.token) {
        const parseJwt = (token) => {
          try {
            return JSON.parse(atob(token.split('.')[1]));
          } catch (e) {
            return null;
          }
        };
        const parsedToken = parseJwt(currentToken);
        const tokenExpireInSeconds = parsedToken.exp - (new Date().getTime() / 1000);
        if (tokenExpireInSeconds > 0) {
          return false;
        }
      }
      return true;
    },
  },
  watch: {
    $route(to, from) {
      if (from.name === 'Datasets') {
        this.$store.commit('datasets/clearDatasetsState');
      }
    },
    isTokenExpired: {
      immediate: true,
      handler(isExpired) {
        if (isExpired && this.currentRoutePath && this.currentRoutePath !== '/') {
          this.$router.push({ path: '/logout' });
        }
      },
    },
    currentProject() {
      this.getDockerTasksForProject();
    },
  },
  created() {
    // const protocol = window.location.protocol;
    // const host = window.location.host.split(':')[0];
    const host = `${process.env.VUE_APP_BACKEND_URL}`;
    const datastoreServerUrl = `${host}:10912`;
    const trainerServerUrl = `${host}:10914`;
    const enterpriseServerUrl = `${host}:${process.env.VUE_APP_BACKEND_PORT}`;
    const samServerUrl = `${process.env.VUE_APP_DVE_SAM_URL}:${process.env.VUE_APP_DVE_SAM_PORT}`;
    this.$store.commit('setDatastoreServerUrl', datastoreServerUrl);
    this.$store.commit('setTrainerServerUrl', trainerServerUrl);
    this.$store.commit('setEnterpriseServerUrl', enterpriseServerUrl);
    this.$store.commit('setEnterpriseServerHost', host);
    this.$store.commit('setSAMServerUrl', samServerUrl);

    if (process.env.NODE_ENV === 'development') {
      this.$store.commit('appVersion/setAllowDebug', 'yes');
    }
  },
  mounted() {
    this.getAppVersion();
    this.$router.isReady().then(() => {
      this.routerIsReady = true;

      // Continue refreshing tokens if still valid
      this.$nextTick(() => {
        if (!this.isTokenExpired && this.$route.path !== '/') {
          this.$store.dispatch('user/autoRefreshToken', this.$store.state.user.token);
        }
      });
    });

    this.getDockerTasksForProject();
  },
  methods: {
    async getAppVersion() {
      const dataConnect = new DatastoreConnect(this.$store.state.enterpriseServerUrl);
      await dataConnect.getAppVersion()
        .then((data) => {
          if (data.result) {
            this.$store.commit('appVersion/setVersion', data.result.version);
            this.$store.commit('appVersion/setIsSaas', data.result.is_saas);
            this.$store.commit('appVersion/setFrontendVersion', process.ver.PACKAGE_VERSION || '0');
          }
        })
        .catch((e) => console.log(e));
    },
    async getDockerTasksForProject() {
      this.$store.commit('tasks/resetUploadTasks');
      if (this.currentProject) {
        const dataConnect = new DatastoreConnect(this.$store.state.enterpriseServerUrl);
        await dataConnect.getDockerTasksDashboard({ project_id: this.currentProject.id, completed_limit: 50 })
          .then((data) => {
            if (data.result) {
              const tasks = data.result;
              tasks.forEach((task) => {
                if (task.type === 'upload') { this.$store.commit('tasks/addExistingUploadTask', task); }
              });
            }
          })
          .catch((e) => { console.log(e); });
      }
    },
  },
};
</script>

<style lang="scss">
@import '@/assets/css/styles.scss';
@import '@/assets/css/bootstrap-grid.min.css';

.button-main {
  width: 120px;
  background: #095b7c;
  color: aliceblue;
  border: none;
  padding: 10px;
}

.button-main:hover {
  background: #068697;
}

#app {
}

.side-panel {
  width: 330px;
  height: 100%;
  background-color: var(--side-panel-color);
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
</style>
