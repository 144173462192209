<template>
  <div id="drawingToolModeControls" class="ribbon">
    <div class="ribbon__group">
      <IconButton
        id="3dPointerToolBtn"
        class="ribbon__button"
        aria-label="Pointer Tool (q)"
        :icon="'cursor'"
        :showHover="false"
        :type="''"
        :title="'Pointer Tool (q)'"
        :width="22"
        :height="22"
        :class="{ 'selected': current3DCanvasTool === '3d-pointer' }"
        @click="emit('update:current3DCanvasTool', '3d-pointer')"
      />
      <IconButton
        id="3dBoxToolBtn"
        class="ribbon__button"
        aria-label="New 3D Box (b)"
        :icon="'cube'"
        :showHover="false"
        :type="''"
        :title="'New 3D Box (b)'"
        :width="22"
        :height="22"
        :class="{ 'selected': current3DCanvasTool === '3d-box' }"
        @click="emit('update:current3DCanvasTool', '3d-box')"
      />
      <IconButton
        id="3dEditLabelToolBtn"
        class="ribbon__button"
        aria-label="Edit Label (l)"
        :icon="'tag'"
        :showHover="false"
        :type="''"
        :title="'Edit Label (l)'"
        :width="22"
        :height="22"
        :class="{ 'selected': current3DCanvasTool === '3d-edit-label' }"
        @click="emit('update:current3DCanvasTool', '3d-edit-label')"
      />
      <IconButton
        id="translateObjectToolBtn"
        class="ribbon__button"
        aria-label="Translate Object (g)"
        :icon="'drag_drop'"
        :showHover="false"
        :type="''"
        :title="'Translate Object (g)'"
        :width="22"
        :height="22"
        :class="{ 'selected': current3DCanvasTool === 'translate-object' }"
        @click="emit('update:current3DCanvasTool', 'translate-object')"
      />
      <IconButton
        id="scaleObjectToolBtn"
        class="ribbon__button"
        aria-label="Scale Object (s)"
        :icon="'scale'"
        :showHover="false"
        :type="''"
        :title="'Scale Object (s)'"
        :width="22"
        :height="22"
        :class="{ 'selected': current3DCanvasTool === 'scale-object' }"
        @click="emit('update:current3DCanvasTool', 'scale-object')"
      />
    </div>
  </div>
</template>

<script setup>
import IconButton from '@/components/IconButton.vue';
import { storeToRefs } from 'pinia';
import { useViewerVisualizationsStore } from '@/stores/useViewerVisualizationsStore.js';
import {
  ref, toRefs, watch,
} from 'vue';

defineProps({
  current3DCanvasTool: { type: String, default: '' },
});

const emit = defineEmits(['update:current3DCanvasTool']);

const visualizationStore = useViewerVisualizationsStore();
const {
  frame,
} = storeToRefs(visualizationStore);

const drawMode = ref('pointer');

watch(frame, () => {
  // samActive.value = false;
});

</script>

<style lang="scss" scoped>
.ribbon {
  position: absolute;
  top: 8px;
  left: 8px;
  display: flex;
  flex-direction: column;
  width: fit-content;
  width: 40px;
  padding: 16px 2px;
  justify-content: space-between;
  font-weight: 500;
  box-shadow: 0 0 3px gray, 0 0 10px gray;
  border-radius: 4px;
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  z-index: 5;
  align-items: center;
  @include themify() {
    color: themed('ribbon-icons-color');
    background: themed('ribbon-bg-transparent');
  }

  &__group {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
  }

  &__vr {
    display: flex;
    width: 100%;
    height: 1px;
    margin-top: 5px;
  }

  &__button {
    display: flex;
    min-width: 22px;
    height: 22px;
    color: inherit;
    background: none;
    border: none;
    font-size: 0.875rem;
    line-height: 1rem;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: .05em;
    align-items: center;
    cursor: pointer;

    &:hover:not(:disabled), &.active:not(:disabled) {
      @include themify() {
        box-shadow: 0 0 0 4px themed('icon-hover-color');
        background-color: themed('icon-hover-color');
      }
      border-radius: 4px;
    }

    &:disabled {
      cursor: default;
      @include themify() {
        color: themed('color-disabled');
      }
    }
  }

  &__button + &__button {
      margin-top: 5px;
  }

  .icon-button + .icon-button {
      margin-left: 0px !important;
  }

  .selected {
      @include themify() {
        box-shadow: 0 0 0 4px hsl(0, 1%, 66%);
        background-color: hsl(0, 1%, 66%);
      }
      border-radius: 4px;
    }
}

:deep(.filter-button) {
  height: 22px;
}

</style>
