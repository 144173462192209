<template>
  <div class="image-details">
    <ul class="image-details__list scrollbar">
      <li class="image-details__list-item">
        <div class="image-details__list-item-icon">
          <SVGIcon
            :iconName="'info'"
            :width="22"
            :height="22"
          />
        </div>
        <div v-if="annotationObj" class="image-details__list-item-details">
          <span>ID</span>
          <div class="image-details__list-item-sub-details _overflow-wrap">
            <span :title="annotationObj.id">{{ annotationObj.id }}</span>
          </div>
          <span>Score</span>
          <div class="image-details__list-item-sub-details _overflow-wrap">
            <span :title="annotationObj.score">{{ annotationObj.score }}</span>
          </div>
          <span>Label</span>
          <div class="image-details__list-item-sub-details _overflow-wrap">
            <span :title="annotationObj.label_index">{{ annotationObj.label_index }}</span>
          </div>
          <span>Date</span>
          <div class="image-details__list-item-sub-details _overflow-wrap">
            <div>
              <span :title="(new Date(annotationObj.date)).toDateString()">{{ (new Date(annotationObj.date)).toDateString() }}</span>
              <br>
              <span :title="(new Date(annotationObj.date)).toTimeString()">{{ (new Date(annotationObj.date)).toTimeString() }}</span>
            </div>
          </div>
          <span>X</span>
          <div class="image-details__list-item-sub-details _overflow-wrap">
            <span :title="annotationObj.x">{{ annotationObj.x }}</span>
          </div>
          <span>Y</span>
          <div class="image-details__list-item-sub-details _overflow-wrap">
            <span :title="annotationObj.y">{{ annotationObj.y }}</span>
          </div>
          <span>Width</span>
          <div class="image-details__list-item-sub-details _overflow-wrap">
            <span :title="annotationObj.w">{{ annotationObj.w }}</span>
          </div>
          <span>Height</span>
          <div class="image-details__list-item-sub-details _overflow-wrap">
            <span :title="annotationObj.h">{{ annotationObj.h }}</span>
          </div>
          <span>Annotation Set ID</span>
          <div class="image-details__list-item-sub-details _overflow-wrap">
            <span :title="annotationObj.annotation_set_id">{{ annotationObj.annotation_set_id }}</span>
          </div>
        </div>
        <div v-if="!annotationObj" class="image-details__list-item-details">
          <span><i>No Annotation Selected</i></span>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import SVGIcon from '@/components/SVGIcon.vue';

export default {
  name: 'AnnotationDetails',
  components: {
    SVGIcon,
  },
  props: {
    annotationObj: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {

    };
  },
};
</script>

<style lang="scss" scoped>
.image-details {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 300px;
  max-width: 300px;
  padding: 0;
  z-index: 2;

  &__list {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    padding: 0;
    margin: 0;
    gap: 16px;
    overflow-y: auto;
    list-style-type: none;
  }

  &__list-item {
    display: flex;
    flex-direction: row;
    width: 100%;
    padding: 0 16px;
  }

  &__list-item-icon {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    width: 40px;
    min-width: 40px;
  }

  &__list-item-details {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    text-align: left;
    overflow: hidden;

    span {
      font-weight: 600;
      font-size: 0.9rem;
      line-height: 1.1rem;
      overflow: hidden;
      margin-bottom: 2px;
      overflow-wrap: anywhere;
    }
  }

  &__list-item-sub-details {
    display: flex;
    flex-direction: row;
    flex: 1 1 auto;
    text-align: left;

    span {
      font-weight: 500;
      font-size: 0.825rem;
      display: inline-flex;
      @include themify() {
        color: themed('body-text-color-secondary');
      }
    }

    &._overflow-wrap span {
      white-space: unset;
      overflow-wrap: anywhere;
    }
  }
}
</style>
