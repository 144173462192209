<template>
  <div class="overview-table">
    <div class="table-header">
      <span class="title">Dataset Summary</span>
    </div>
    <div class="dataset-details">
      <ul class="dataset-details__list scrollbar">
        <li class="dataset-details__list-item">
          <div class="dataset-details__list-item-icon">
            <SVGIcon
              :iconName="'database2'"
              :width="30"
              :height="30"
            />
          </div>
          <div class="dataset-details__list-item-details">
            <span :title="`${ dataset.name }`">{{ dataset.name }}</span>
          </div>
        </li>
        <li class="dataset-details__list-item">
          <div class="dataset-details__list-item-icon">
            <SVGIcon
              :iconName="'image'"
              :width="30"
              :height="30"
            />
          </div>
          <div class="dataset-details__list-item-details">
            <span>Images</span>
            <div class="dataset-details__list-item-sub-details _overflow-wrap">
              <span>{{ dataset.numImages }}</span>
            </div>
          </div>
        </li>
        <li class="dataset-details__list-item">
          <div class="dataset-details__list-item-icon">
            <SVGIcon
              :iconName="'tag'"
              :width="30"
              :height="30"
            />
          </div>
          <div class="dataset-details__list-item-details">
            <span>Labels</span>
            <div class="dataset-details__list-item-sub-details _overflow-wrap">
              <span :title="`${dataset.numLabels}`">{{ dataset.numLabels }}</span>
            </div>
          </div>
        </li>
        <li class="dataset-details__list-item">
          <div class="dataset-details__list-item-icon">
            <SVGIcon
              :iconName="'set'"
              :width="28"
              :height="28"
            />
          </div>
          <div class="dataset-details__list-item-details">
            <span>Annotation Sets</span>
            <AnnotationSetsRadioSelect
              :id="'analytics'"
              v-model="set"
              :name="'Set'"
              :restore="restore"
              :annotationSets="dataset.annotation_sets"
            />
          </div>
        </li>
        <template v-if="boxSize && Object.keys(boxSize).length > 0">
          <li class="dataset-details__list-item">
            <div class="dataset-details__list-item-icon">
              <SVGIcon
                :iconName="'minimize'"
                :width="30"
                :height="30"
              />
            </div>
            <div class="dataset-details__list-item-details">
              <span>Min Box Size</span>
              <div class="dataset-details__list-item-sub-details _overflow-wrap">
                <span>{{ `${boxSize.min_width.toFixed(4)} x ${boxSize.min_height.toFixed(4)}` }}</span>
              </div>
            </div>
          </li>
          <li class="dataset-details__list-item">
            <div class="dataset-details__list-item-icon">
              <SVGIcon
                :iconName="'maximize'"
                :width="30"
                :height="30"
              />
            </div>
            <div class="dataset-details__list-item-details">
              <span>Max Box Size</span>
              <div class="dataset-details__list-item-sub-details _overflow-wrap">
                <span>{{ `${boxSize.max_width.toFixed(4)} x ${boxSize.max_height.toFixed(4)}` }}</span>
              </div>
            </div>
          </li>
        </template>
      </ul>
    </div>
  </div>
</template>

<script>
import SVGIcon from '@/components/SVGIcon.vue';
import AnnotationSetsRadioSelect from '@/components/DatasetComponent/AnnotationSetsRadioSelect.vue';
import DatastoreConnect from '@/assets/js/DatastoreFunctions/datastore-interface';

export default {
  name: "StatsOverview",
  components: {
    SVGIcon,
    AnnotationSetsRadioSelect,
  },
  props: {
    dataset: {
      type: Object,
      default: () => {},
    },
    boxSize: {
      type: Object,
      default: () => {},
    },
  },
  emits: ['new-set'],
  data() {
    return {
      set: null,
      dataConnect: new DatastoreConnect(this.$store.state.enterpriseServerUrl),
      restore: null,
    };
  },
  watch: {
    dataset(d) {
      if (d && d['annotation_sets'] && d['annotation_sets'].length > 0) {
        this.restore = d.annotation_sets[0];
      }
    },
    set(s) {
      if (s) {
        this.$emit('new-set', s);
      }
    },
  },
  mounted() {
    if (!this.set && this.dataset['annotation_sets'] && this.dataset['annotation_sets'].length > 0) {
      this.restore = this.dataset.annotation_sets[0];
    }
  },
  methods: {
  },
};
</script>

<style lang="scss" scoped>

.overview-table {
  height: calc(100% - 20px);
  width: 275px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0,0,0,0.1), 0 0 5px rgba(0,0,0,0.25);
  margin: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  @include themify() {
    background: themed('card-color');
    color: themed('datasets-stats-text-color');
  }
}

.table-header {
  display: flex;
  align-items: center;

}
.title {
  display: flex;
  align-items: center;
  margin-left: 15px;
  margin-right: 15px;
  font-size: 1.3rem;
  font-weight:700;
}

.dataset-details {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 250px;
  max-width: 300px;
  margin-top: 10px;
  padding: 0;
  z-index: 2;

  &__list {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    padding: 0;
    margin: 0;
    gap: 8px;
    overflow-y: auto;
    list-style-type: none;
  }

  &__list-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    padding: 10px 16px 10px 16px;
  }

  &__list-item-icon {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    width: 40px;
    min-width: 40px;
  }

  &__list-item-details {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    text-align: left;
    overflow: hidden;

    span {
      font-weight: 600;
      font-size: 1.05rem;
      line-height: 1.3rem;
      overflow: hidden;
      margin-bottom: 2px;
      overflow-wrap: anywhere;
    }

    .radio-dropdown {
      width: fit-content;
    }
  }

  &__list-item-sub-details {
    display: flex;
    flex-direction: row;
    flex: 1 1 auto;
    text-align: left;

    span {
      font-weight: 500;
      font-size: 1rem;
      @include themify() {
        color: themed('body-text-color-secondary');
      }
    }

    &._overflow-wrap span {
      white-space: unset;
      overflow-wrap: anywhere;
    }
  }
}
</style>
