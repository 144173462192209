<template>
  <div class="inline-loader" :style="{ width: width, height: height }" />
</template>

<script>
export default {
  name: 'InlineLoader',
  props: {
    width: {
      type: String,
      default: '50px',
    },
    height: {
      type: String,
      default: '50px',
    },
    border: {
      type: String,
      default: '10px',
    },
  },
};
</script>

<style lang="scss" scoped>
.inline-loader {
 @include themify() {
  border: v-bind(border) solid themed('loader-background');
  border-top: v-bind(border) solid themed('loader-main-color');;
 }
 border-radius: 50%;
 animation: spin 2s linear infinite;
}

@keyframes spin {
 0% { transform: rotate(0deg); }
 100% { transform: rotate(360deg); }
}
</style>
