<template>
  <Modal
    ref="trainingSessionDetailsModal"
    :title="'Session Details'"
    :width="'100%'"
    :height="'100%'"
    @closed="clearData"
  >
    <!-- <template #modal-header /> -->
    <template #modal-main>
      <div class="converter">
        <div class="sessions">
          <div class="session-header">
            <h3>Session</h3>
            <div class="icon-container">
              <IconButton
                class="icon"
                :icon="'refresh'"
                :width="22"
                :height="22"
                :title="`Refresh Session`"
                :type="''"
                :disabled="refreshTimeout"
                @click.stop="handleRefreshClicked"
              />
              <IconButton
                v-if="permissions && permissions.trainer_write"
                class="icon"
                :icon="'pause'"
                :width="22"
                :height="22"
                :title="`Stop Session`"
                :type="''"
                @click.stop="handleStopTrainingClicked"
              />
            </div>
          </div>
          <div v-if="internalSession" id="training-sessions-container" class="training-sessions">
            <div
              class="training-sessions__list-item2"
              :title="internalSession ? internalSession.name : internalSession.docker_task.date ? internalSession.docker_task.date : 'Unknown'"
            >
              <span v-if="getStatus(internalSession) === 'complete'" class="status-tag _complete"><div />Complete</span>
              <span v-else-if="getStatus(internalSession) === 'pending'" class="status-tag _running"><div />Initializing</span>
              <span v-else-if="getStatus(internalSession) === 'exporting'" class="status-tag _running"><div />Preparing Data</span>
              <span v-else-if="getStatus(internalSession) === 'export-done'" class="status-tag _running"><div />Init. Trainer</span>
              <span v-else-if="getStatus(internalSession) === 'train-start'" class="status-tag _running"><div />Start Training</span>
              <span v-else-if="getStatus(internalSession) === 'training' || getStatus(internalSession) === 'running'" class="status-tag _running"><div />Training</span>
              <span v-else-if="getStatus(internalSession) === 'terminated'" class="status-tag _warning"><div />Terminated</span>
              <span v-else :title="getStatus(internalSession)" class="status-tag _error"><div />Error</span>
              <hr class="vr">
              <div val-name class="name-col">
                <label>{{ internalSession ? internalSession.name : "Unknown" }}</label>
                <span v-if="internalSession.docker_task">{{ formatTime(internalSession.docker_task.date) }}</span>
              </div>
              <hr class="vr">
              <div val-epoch class="session-col">
                <template
                  v-if="getStatus(internalSession) === 'exporting' || getStatus(internalSession) === 'export-done'
                    || (getStatus(internalSession) === 'terminated' && getMessage(internalSession).includes('export'))"
                >
                  <label>IMAGE</label>
                  <span v-if="getTotal(internalSession) && getStatus(internalSession) !== 'error'">{{ getCount(internalSession) }} / {{ getTotal(internalSession) }}</span>
                  <span v-else>n/a</span>
                </template>
                <template
                  v-else-if="getStatus(internalSession) === 'train-start' || getStatus(internalSession) === 'training'
                    || getStatus(internalSession) === 'running' || getStatus(internalSession) === 'complete'
                    || (getStatus(internalSession) === 'terminated' && ['train-start', 'training', 'running', 'complete'].some(val => getMessage(internalSession).includes(val)))"
                >
                  <label>EPOCH</label>
                  <span
                    v-if="getTotal(internalSession) && getStatus(internalSession) !== 'error'
                      && getStatus(internalSession) !== 'train-start'"
                  >{{ getEpoch(internalSession) }} / {{ getTotal(internalSession) }}</span>
                  <span v-else>n/a</span>
                </template>
                <template v-else>
                  <label>PROGRESS</label>
                  <span>n/a</span>
                </template>
              </div>
            </div>
          </div>
          <div class="tab-selector">
            <input
              id="option-params"
              v-model="tab"
              type="radio"
              name="tab-selector"
              :value="'params'"
            >
            <label for="option-params">Parameters</label>
            <input
              id="option-metrics"
              v-model="tab"
              type="radio"
              name="tab-selector"
              :value="'metrics'"
            >
            <label for="option-metrics">Metrics</label>
            <input
              id="option-logs"
              v-model="tab"
              type="radio"
              name="tab-selector"
              :value="'logs'"
            >
            <label for="option-logs">System Logs</label>
          </div>
          <div v-if="tab==='params'" class="params mt-2">
            <div id="training-session-system-logs" class="text-area-details-white scrollbar">
              <div
                v-for="(value, name, index) in parameters"
                :key="index"
                class="list-item"
                :title="value"
              >
                <label>{{ name }}</label>
                <span>{{ value }}</span>
              </div>
            </div>
          </div>
          <div v-else-if="tab==='metrics'" class="metrics mt-2">
            <div id="training-session-system-logs" class="text-area-details-white scrollbar">
              <div
                v-for="(value, name, index) in metrics"
                :key="index"
                class="list-item"
                :title="value"
              >
                <label>{{ name }}</label>
                <span>{{ value }}</span>
              </div>
            </div>
          </div>
          <div v-else-if="tab==='logs'" class="system-logs mt-2">
            <div id="training-session-system-logs" class="text-area-box scrollbar">
              <span><pre>{{ logs }}</pre></span>
            </div>
          </div>
        </div>
        <div class="converter-list">
          <h3>Download Raw Model</h3>
          <template v-if="internalSession">
            <div id="converter-raw-download" class="converter-download top scrollbar">
              <template v-if="selectedSessionAvailableFiles && Object.keys(selectedSessionAvailableFiles).includes(`best${modelExtensionMap[internalSession.model]}`)">
                <div
                  id="download-button"
                  class="download-button"
                  :class="{ 'has-file': Object.keys(selectedSessionAvailableFiles).includes(`best${modelExtensionMap[internalSession.model]}`) }"
                >
                  <div class="download-button__text">
                    <div class="key-value">
                      <span class="key">Model Type</span><span>{{ internalSession.model === 'yolov8' ? 'Yolo V8' : 'ModelPack' }}</span>
                    </div>
                    <div class="key-value">
                      <span class="key">File</span><span>{{ `best${modelExtensionMap[internalSession.model]}` }}</span>
                    </div>
                    <div class="key-value">
                      <span class="key">Date</span><span>{{ `${formatTime(selectedSessionAvailableFiles[`best${modelExtensionMap[internalSession.model]}`])}` }}</span>
                    </div>
                  </div>
                  <div class="download-button__icons">
                    <div id="download-button__icon" class="icon" :title="`Download model best${modelExtensionMap[internalSession.model]}`">
                      <a :href="`download_model?${downloadRawParams}`" download>
                        <SVGIcon
                          class="icon"
                          :iconName="'download'"
                          :width="'24px'"
                          :height="'24px'"
                        />
                      </a>
                    </div>
                  </div>
                </div>
                <div
                  v-if="internalSession.model === 'modelpack'"
                  id="download-button"
                  class="download-button"
                  :class="{ 'has-file': Object.keys(selectedSessionAvailableFiles).includes(`best_backbone${modelExtensionMap[internalSession.model]}`)
                    && Object.keys(selectedSessionAvailableFiles).includes(`best_decoder${modelExtensionMap[internalSession.model]}`)
                    && Object.keys(selectedSessionAvailableFiles).includes(`best_decoder.tflite`) }"
                >
                  <div v-if="filteredSessionConvertorDockerTasks.find(e => e.conversion_type === `generate_two_stage`)" class="download-button__spinner" @click.stop>
                    <InlineLoader
                      :width="'30px'"
                      :height="'30px'"
                      :border="'5px'"
                    />
                  </div>
                  <div class="download-button__text">
                    <div class="key-value">
                      <span class="key">Model Type</span><span>{{ `${internalSession.model === 'yolov8' ? 'Yolo V8' : 'ModelPack'} - Two Stage` }}</span>
                    </div>
                    <div class="key-value">
                      <span class="key">File</span><span>{{ `best_backbone${modelExtensionMap[internalSession.model]}, best_decoder${modelExtensionMap[internalSession.model]}` }}</span>
                    </div>
                    <div class="key-value">
                      <span class="key">Date</span><span>{{ `${formatTime(selectedSessionAvailableFiles[`best_backbone${modelExtensionMap[internalSession.model]}`])}` }}</span>
                    </div>
                  </div>
                  <div class="download-button__icons">
                    <div :title="`Generate ModelPack two stage`" class="icon">
                      <SVGIcon
                        class="icon"
                        :iconName="'call_split'"
                        :width="'24px'"
                        :height="'24px'"
                        :title="`Generate ModelPack two stage`"
                        @click.stop="generateTwoStageModel"
                      />
                    </div>
                    <div
                      v-if="Object.keys(selectedSessionAvailableFiles).includes(`best_backbone${modelExtensionMap[internalSession.model]}`)
                        && Object.keys(selectedSessionAvailableFiles).includes(`best_decoder${modelExtensionMap[internalSession.model]}`)"
                      class="icon"
                      :title="`Download two stage h5`"
                    >
                      <a :href="`download_model?${downloadRawParams}&two_stage=true`" download>
                        <SVGIcon
                          class="icon"
                          :iconName="'download'"
                          :width="'24px'"
                          :height="'24px'"
                        />
                      </a>
                    </div>
                  </div>
                </div>
              </template>
              <template v-else>
                <div id="download-button" class="download-button _disabled">
                  <div class="download-button__text">
                    <div class="key-value">
                      <span class="key">Model Type</span><span>{{ `${internalSession.model === 'yolov8' ? 'Yolo V8' : 'ModelPack'}` }}</span>
                    </div>
                    <div class="key-value">
                      <span class="key">File</span><span>{{ `best${modelExtensionMap[internalSession.model]}` }}</span>
                    </div>
                    <div class="key-value">
                      <span class="key">Date</span>
                    </div>
                  </div>
                  <div class="download-button__icons">
                    <SVGIcon
                      :iconName="'dangerous_outlined'"
                      class="icon"
                      :width="'24px'"
                      :height="'24px'"
                    />
                  </div>
                </div>
              </template>
            </div>
          </template>
          <template
            v-if="internalSession && internalSession.model === 'yolov8' &&
              selectedSessionAvailableFiles && Object.keys(selectedSessionAvailableFiles).includes(`best${modelExtensionMap[internalSession.model]}`)"
          >
            <div class="separator my-3">Or Convert to Other File Types</div>
            <h3>Supported Conversion Types for Yolo V8</h3>
            <div v-if="internalSession && internalSession.model === 'yolov8'" class="converter-download bottom scrollbar">
              <template
                v-for="(key, value, i) in converterOptionsYL"
                :key="i"
              >
                <div class="download-button" :class="{ 'has-file': Object.keys(selectedSessionAvailableFiles).includes(`best.${value}`) }">
                  <div class="download-button__text">
                    <div class="key-value">
                      <span class="key">Model Type</span><span>{{ key }}</span>
                    </div>
                    <div class="key-value">
                      <span class="key">File</span><span>{{ `best.${value}` }}</span>
                    </div>
                    <div class="key-value">
                      <span class="key">Date</span><span>{{ `${formatTime(selectedSessionAvailableFiles[`best.${value}`])}` }}</span>
                    </div>
                  </div>
                  <div class="download-button__icons">
                    <div :title="`Convert model to ${value}`" class="icon">
                      <SVGIcon
                        class="icon"
                        :iconName="'sync_alt'"
                        :width="'24px'"
                        :height="'24px'"
                        @click.stop="convert(['best.pt'], value)"
                      />
                    </div>
                    <div
                      v-if="Object.keys(selectedSessionAvailableFiles).includes(`best.${value}`)"
                      class="icon"
                      :title="`Download model best.${value}`"
                    >
                      <a :href="`download_model?session_id=${internalSession.id}&conversion_type=${value}`" download>
                        <SVGIcon
                          class="icon"
                          :iconName="'download'"
                          :width="'24px'"
                          :height="'24px'"
                        />
                      </a>
                    </div>
                  </div>
                </div>
              </template>
            </div>
          </template>
          <template
            v-if="internalSession && internalSession.model === 'modelpack' &&
              selectedSessionAvailableFiles && Object.keys(selectedSessionAvailableFiles).includes(`best${modelExtensionMap[internalSession.model]}`)"
          >
            <div class="separator my-3">Or Convert to Other File Types</div>
            <h3>Supported Conversion Types for ModelPack</h3>
            <div v-if="internalSession && internalSession.model === 'modelpack'" class="converter-download bottom scrollbar">
              <template
                v-for="(key, value, i) in converterOptionsMP"
                :key="i"
              >
                <div class="download-button" :class="{ 'has-file': Object.keys(selectedSessionAvailableFiles).includes(`best.${value}`) }">
                  <div v-if="filteredSessionConvertorDockerTasks.find(e => e.conversion_type === value)" class="download-button__spinner" @click.stop>
                    <InlineLoader
                      :width="'30px'"
                      :height="'30px'"
                      :border="'5px'"
                    />
                  </div>
                  <div class="download-button__text">
                    <div class="key-value">
                      <span class="key">Model Type</span><span>{{ key }}</span>
                    </div>
                    <div class="key-value">
                      <span class="key">File</span><span>{{ `best.${value}` }}</span>
                    </div>
                    <div class="key-value">
                      <span class="key">Date</span><span>{{ `${formatTime(selectedSessionAvailableFiles[`best.${value}`])}` }}</span>
                    </div>
                  </div>
                  <div class="download-button__icons">
                    <div :title="`Convert model to ${value}`" class="icon">
                      <SVGIcon
                        class="icon"
                        :iconName="'sync_alt'"
                        :width="'24px'"
                        :height="'24px'"
                        @click.stop="convert(['best.h5'], value)"
                      />
                    </div>
                    <div
                      v-if="Object.keys(selectedSessionAvailableFiles).includes(`best.${value}`)"
                      class="icon"
                      :title="`Download model best.${value}`"
                    >
                      <a :href="`download_model?session_id=${internalSession.id}&conversion_type=${value}`" download>
                        <SVGIcon
                          class="icon"
                          :iconName="'download'"
                          :width="'24px'"
                          :height="'24px'"
                        />
                      </a>
                    </div>
                  </div>
                </div>
                <div
                  v-if="selectedSessionAvailableFiles &&
                    Object.keys(selectedSessionAvailableFiles).includes(`best_backbone${modelExtensionMap[internalSession.model]}`) &&
                    Object.keys(selectedSessionAvailableFiles).includes(`best_decoder${modelExtensionMap[internalSession.model]}`)"
                  class="download-button"
                  :class="{ 'has-file': Object.keys(selectedSessionAvailableFiles).includes(`best_backbone.${value}`)
                    && Object.keys(selectedSessionAvailableFiles).includes(`best_decoder.${value}`) }"
                >
                  <div v-if="filteredSessionConvertorDockerTasks.find(e => e.conversion_type === `${value}_two_stage`)" class="download-button__spinner" @click.stop>
                    <InlineLoader
                      :width="'30px'"
                      :height="'30px'"
                      :border="'5px'"
                    />
                  </div>
                  <div class="download-button__text">
                    <div class="key-value">
                      <span class="key">Model Type</span><span>{{ key }} - Two Stage</span>
                    </div>
                    <div class="key-value">
                      <span class="key">File</span><span>{{ `best_backbone.${value}, best_decoder.${value}` }}</span>
                    </div>
                    <div class="key-value">
                      <span class="key">Date</span><span>{{ `${formatTime(selectedSessionAvailableFiles[`best_backbone.${value}`])}` }}</span>
                    </div>
                  </div>
                  <div class="download-button__icons">
                    <div class="icon" :title="`Convert two stage model to ${value}`">
                      <SVGIcon
                        class="icon"
                        :iconName="'sync_alt'"
                        :width="'24px'"
                        :height="'24px'"
                        @click.stop="convert([`best_backbone.h5`, `best_decoder.tflite`], value)"
                      />
                    </div>
                    <div
                      v-if="Object.keys(selectedSessionAvailableFiles).includes(`best_backbone.${value}`)
                        && Object.keys(selectedSessionAvailableFiles).includes(`best_decoder.${value}`)"
                      class="icon"
                      :title="`Download model best.${value}`"
                    >
                      <a :href="`download_model?session_id=${internalSession.id}&conversion_type=${value}&two_stage=true`" download>
                        <SVGIcon
                          class="icon"
                          :iconName="'download'"
                          :width="'24px'"
                          :height="'24px'"
                        />
                      </a>
                    </div>
                  </div>
                </div>
              </template>
            </div>
          </template>
        </div>
      </div>
    </template>
  </Modal>
</template>

<script>
import DatastoreConnect from '@/assets/js/DatastoreFunctions/datastore-interface';
import Modal from '@/components/Modal.vue';
import SVGIcon from '@/components/SVGIcon.vue';
import IconButton from "@/components/IconButton.vue";
import InlineLoader from "@/components/InlineLoader.vue";

export default {
  name: 'TrainerDetailsModal',
  components: {
    Modal,
    SVGIcon,
    IconButton,
    InlineLoader,
  },
  props: {
    session: {
      type: Object,
      default: () => {},
    },
    permissions: {
      type: Object,
      default: () => {},
    },
  },
  emits: ['stop-training', 'delete-session'],
  data() {
    return {
      tab: 'params',
      internalSession: null,
      logs: null,
      converterOptionsMP: null,
      converterOptionsYL: null,
      selectedConvertType: null,
      selectedSessionAvailableFiles: null,
      modelExtensionMap: {
        'yolov8': '.pt',
        'modelpack': '.h5',
      },
      convertorDockerTasks: [],
      interval: null,
      logsInterval: null,
      show_menu: false,
      refreshTimeout: false,

    };
  },
  computed: {
    currentProject() {
      return this.$store.state.projects.currentProject;
    },
    converterTasks() {
      return this.$store.state.tasks.converterTasks;
    },
    converterTasksList() {
      return Object.values(this.converterTasks);
    },
    currentSessionConverterTasks() {
      return this.converterTasksList.filter((e) => e.session_id === this.internalSession.id);
    },
    downloadRawParams() {
      if (this.internalSession) {
        let type = "";
        if (this.internalSession.model === 'yolov8') {
          type = "yolov8";
        } else if (this.internalSession.model === 'modelpack') {
          type = "mpk";
        }
        return `session_id=${this.internalSession.id}&conversion_type=${type}`;
      }
      return "";
    },
    downloadConvertedParams() {
      if (this.selectedConvertType) {
        return `session_id=${this.internalSession.id}&conversion_type=${this.selectedConvertType}`;
      }
      return "";
    },
    filteredSessionConvertorDockerTasks() {
      let res = [];
      if (this.internalSession) {
        res = this.convertorDockerTasks.reduce((acc, curr) => {
          const data = JSON.parse(curr.data);
          if (data && data.session_id === this.internalSession.id) {
            curr.session_id = data.session_id;
            curr.conversion_type = data.conversion_type;
            acc.push(curr);
          }
          return acc;
        }, []);
      }
      return res;
    },
    metrics() {
      const result = {};
      for (const [key, value] of Object.entries(this.internalSession?.metrics)) {
        if (key !== 'timings' && key !== 'model' && key !== 'dataset'
        && key !== 'class_histogram_data' && key !== 'confusion_matrix_data' && key !== 'precision_recall_data') {
          result[key] = value;
        }
      }
      return result;
    },
    parameters() {
      return this.internalSession?.params.trainer_params;
    },
  },
  watch: {
    session: {
      deep: true,
      immediate: true,
      handler(newval, old) {
        if (newval) {
          this.internalSession = newval;
          this.selectedConvertType = null;
          this.getAllFiles();
          this.getLogs();
        }
      },
    },
    filteredSessionConvertorDockerTasks: {
      handler(tasks) {
        if (tasks && tasks.length > 0) {
          tasks.forEach((task) => {
            this.getConverterStatus(task);
          });
        }
      },
    },
  },
  beforeUnmount() {
    this.clearData();
  },
  created() {
    this.dataConnect = new DatastoreConnect(this.$store.state.enterpriseServerUrl);
  },
  mounted() {
    this.getConverterOptions();
  },
  methods: {
    async showModal() {
      // if (this.session) {
      //   this.internalSession = s;
      //   this.selectedConvertType = null;
      //   this.getAllFiles();
      // }
      this.interval = setInterval(this.getConvertorDockerList, 5000);
      this.logsInterval = setInterval(this.getLogs, 5000);
      this.$refs.trainingSessionDetailsModal.showModal();
      // this.getInternalSession();
    },
    closeModal() {
      this.clearData();
      this.$refs.trainingSessionDetailsModal.closeModal();
    },
    clearData() {
      // this.selectedSessionAvailableFiles = null;
      // this.internalSession = null;
      clearInterval(this.interval);
      this.interval = null;
      clearInterval(this.logsInterval);
      this.logsInterval = null;
      this.convertorDockerTasks = [];
      this.selectedConvertType = null;
      this.refreshTimeout = false;
    },
    getEpoch(training_session) {
      if (training_session.docker_task) {
        return training_session.docker_task.count;
      }
      return 0;
    },
    getTotalEpochs(session) {
      let params = {};
      if (session && session.params) {
        params = session.params;
      }
      if (params.epochs) {
        return params.epochs;
      }
      return null;
    },
    getStatus(session) {
      if (session && session.docker_task) {
        return session.docker_task.status;
      }
      return null;
    },
    getMessage(session) {
      if (session && session.docker_task) {
        return session.docker_task.message;
      }
      return null;
    },
    getCount(session) {
      if (session && session.docker_task) {
        return session.docker_task.count;
      }
      return null;
    },
    getTotal(session) {
      if (session && session.docker_task) {
        return session.docker_task.total;
      }
      return null;
    },
    formatTime(str) {
      if (!str || str === "") {
        return "";
      } else {
        return `${new Date(str).toLocaleTimeString('default', {
          year: 'numeric', month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric', hour12: true,
        })}`;
      }
    },
    async getConverterOptions() {
      await this.dataConnect.getConverterOptions()
        .then((data) => {
          this.converterOptionsMP = data.result.modelpackTypes;
          this.converterOptionsYL = data.result.yolov8Types;
        })
        .catch((e) => console.log(e));
    },
    async getAllFiles() {
      await this.dataConnect.convertorFindAllFiles({
        training_session_id: this.internalSession.id,
      }).then((data) => {
        this.selectedSessionAvailableFiles = data.result;
      }).catch((e) => console.log(e));
    },
    async getConvertorDockerList() {
      await this.dataConnect.convertorGetDockerTasks({
        project_id: this.currentProject.id,
      }).then((data) => {
        this.convertorDockerTasks = data.result;
      }).catch((e) => console.log(e));
    },
    async getConverterStatus(docker_task) {
      await this.dataConnect.convertorStatus({
        docker_container_id: docker_task.docker_id,
        training_session_id: docker_task.session_id,
        conversion_type: docker_task.conversion_type,
      })
        .then((data) => {
          if (data && data.result !== "") {
            const task = data.result;
            if (task === 'nofile' || task === 'fileexists') {
              this.dataConnect.deleteDockerTask({
                docker_task_id: docker_task.id,
              });
              this.getAllFiles();
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async convert(files, type) {
      await this.dataConnect.convertModel({
        training_session_id: this.internalSession.id,
        files,
        conversion_type: type,
        two_stage: files.length > 1,
      })
        .then(() => {
          this.getConvertorDockerList();
        })
        .catch((e) => console.log(e));
    },
    async generateTwoStageModel() {
      await this.dataConnect.generateTwoStageModel({
        session_id: this.internalSession.id,
      })
        .then(() => {
          this.getConvertorDockerList();
        })
        .catch((e) => console.log(e));
    },

    async getLogs() {
      if (this.internalSession && this.internalSession.id) {
        const resp = await this.dataConnect.getTrainingSessionLogs({
          session_id: this.internalSession.id,
          trainer_id: this.internalSession.trainer_id,
        });
        let temp = "";
        if (!resp || resp.error || !resp.result) {
          temp += `Trainer may take some time to create logs file for "${this.internalSession.name}".`;
        } else {
          temp += resp.result;
        }

        this.logs = temp;
      } else {
        this.logs = null;
      }
    },
    handleStopTrainingClicked() {
      this.$emit("stop-training", this.internalSession);
    },
    handleDeleteSessionClicked() {
      this.$emit("delete-session", this.internalSession);
    },
    async getInternalSession() {
      await this.dataConnect.getTrainingSession({
        trainer_session_id: this.internalSession.id,
      })
        .then((data) => {
          if (data.result) {
            this.internalSession = data.result;
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    async handleRefreshClicked() {
      this.refreshTimeout = true;
      setTimeout(() => {
        this.refreshTimeout = false;
      }, 5000);
      await this.getInternalSession();
    },
  },
};

</script>

<style lang="scss" scoped>

a {
  text-decoration: none;
  @include themify() {
      color: themed('body-text-color');
    }
}

.help-tooltip {
  position: absolute;
  top: 3%;
  right:5%;
}
.converter{
  display: flex;
  flex-direction: row;
  height: 100%;
}
.icon:hover {
  @include themify() {
    color: themed('icon-color-primary');
  }
}
.sessions {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  height: 100%;
  width: calc(100% - 400px);
  padding: 16px;

  ul {
    list-style-type: none;
  }
  .session-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

   .icon-container {
    display: flex;
    align-items: center;
      @include themify() {
        color: themed('body-text-color-secondary');
      }
      .icon + .icon {
        margin-left: 10px;
      }
    }
  }

  h3 {
    margin: 0;
    margin-bottom: 10px;
    font-size: 1rem;
    text-align: left;
    @include themify() {
      color: themed('body-text-color-secondary');
    }
  }

  &__body-row {
    display: flex;
    flex: 1 1 auto;
    margin-top: 5px;
    align-items: center;
  }

  .text-area-box {
    display: flex;
    flex-direction: row;
    border-radius: 4px;
    background: rgb(38, 38, 38);
    border: 1px solid grey;
    height: 100%;
    padding: 15px;
    overflow-y: auto;

    span {
      color: white;
      font-weight: 700;
      font-size: 0.9rem;

      pre {
        font-family: 'Courier New', monospace !important;
        text-align: left;
      }
    }
  }

  .text-area-details-white {
    display: grid;
    grid-template-columns: repeat(auto-fit, 450px);
    grid-auto-rows: 50px;
    grid-gap: 20px 5px;
    height: 100%;
    border-radius: 4px;
    background: var(--color-white-100);
    border: 1px solid var(--color-primary-100);
    padding: 15px;
    overflow-y: auto;

    .list-item {
      height: fit-content;
      display: flex;
      flex-direction: column;
      text-align: left;
      padding: 5px;

      label {
        @include themify() {
          color: tint-color(themed('body-text-color-secondary'), 20%);
        }
        font-weight: 700;
        font-size: 0.9rem;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      span {
        @include themify() {
          color: themed('body-text-color');
        }
        font-weight: 600;
        font-size: 0.9rem;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
  }

  .system-logs, .metrics, .params {
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow-y: auto;
  }
}

.converter-list {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  padding: 16px;
  max-width: 400px;

  h3 {
    margin: 0;
    font-size: 1rem;
    text-align: left;
    @include themify() {
      color: themed('body-text-color-secondary');
    }
  }

  &__body-row {
    display: flex;
    flex: 1 1 auto;
    margin-top: 5px;
    align-items: center;
  }
}

.converter-download {
  padding: 10px 0 15px 10px;
  margin: 8px 0 0 0;
  display: grid;
  grid-template-columns: repeat(auto-fill, 320px);
  grid-auto-rows: auto;
  grid-gap: 20px 30px;

  .two-stage {
    width: fit-content;
  }

  .download-button {
    display: flex;
    flex-direction: row;
    position: relative;
    width: 320px;
    padding: 8px;
    border-radius: 8px;
    font-size: 0.775rem;
    font-weight: 600;
    line-height: 1.5;
    align-items: center;
    @include themify() {
      border: 1px solid themed('color-primary-500');
    }

    &__text{
      margin: 5px 10px 5px 10px;
      width: calc(100% - 40px);
      display: flex;
      flex-direction: column;
      text-align: left;

      .key-value {
        display: flex;
        flex-direction: row;

        .key {
          min-width: 90px;
        }
      }
    }

    &__spinner{
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      padding: 8px;
      border-radius: 10px;
      z-index: 1000;
      display: flex;
      backdrop-filter: blur(2px);
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    &__icons{
      margin: 5px 10px 5px 10px;
      display: flex;
      flex-direction: column;
      text-align: left;

      .icon:hover {
        @include themify() {
          background-color: themed('icon-hover-color');
          border-radius: 100%;
          box-shadow: 0 0 0 4px themed('icon-hover-color');
        }
      }

      .icon + .icon {
        margin-top: 5px;
      }
    }

    &:hover {
      box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.375), 0 0 1px 1px rgba(0, 0, 0, 0.125);
    }
  }
}

.top {
  min-height: fit-content;
}

.bottom {
  overflow-y: auto;
}

._disabled {
  @include themify() {
    background: themed('color-disabled-100') !important;
    color: white;
    cursor: not-allowed !important;
    pointer-events: none;
    border: 1px solid transparent !important;
  }
}

.has-file {
  background: tint-color($color-success, 80%) !important;
}

.separator {
  display: flex;
  align-items: center;
  text-align: center;
  font-weight: 700;
  font-size: 0.75rem;
  @include themify() {
    color: themed('form-separator-color');
    border-color: themed('form-separator-color');
  }
  &::before, &::after {
    content: '';
    flex: 1;
    border-bottom: 1px solid;
  }
  &:not(:empty)::before {
    margin-right: 1.5em;
    margin-left: .25em;
  }
  &:not(:empty)::after {
    margin-right: .25em;
    margin-left: 1.5em;
  }
}

.training-sessions {
  display: flex;
  flex-direction: column;
  gap: 6px;
  list-style: none;
  padding: 2px 4px;
  margin: -2px -4px;

  &__list-item {
    position: relative;
    background: rgb(231, 224, 224);
    padding: 8px;
    border-radius: 8px;

    span {
      overflow-wrap: anywhere;
    }

    &:hover {
      .training-sessions__list-item-buttons {
        visibility: visible;
      }
    }
  }

  &__list-item-buttons {
    visibility: hidden;
    position: absolute;
    top: 6px;
    right: 6px;
    @include themify() {
      color: themed('color-primary');
    }
  }

  &__list-item2 {
    display: flex;
    flex-direction: row;
    position: relative;
    background: rgb(231, 224, 224);
    padding: 8px;
    border-radius: 4px;
    gap: 16px;
    align-items: center;
    @include themify() {
      background: themed('color-white-700');
    }
    // box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.375), 0 0 1px 1px rgba(0, 0, 0, 0.125);
    margin-bottom: 20px;
    border: 1px solid grey;
    > div {
      flex: 1 1 100%;
    }

    .session-col {
      display: flex;
      flex-direction: column;

      label, span {
        width: 100%;
        text-align: center;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        pointer-events: none;
      }
      label {
        font-weight: 600;
        font-size: 0.625rem;
        letter-spacing: 0.05em;
        white-space: nowrap;
        @include themify() {
          color: themed('body-text-color-secondary');
        }
      }
      span {
        font-weight: 700;
        font-size: 0.75rem;
        @include themify() {
          color: themed('color-primary');
        }
      }
    }

    .name-col {
      display: flex;
      flex-direction: column;

      label, span {
        width: 100%;
        text-align: center;
        overflow: hidden;
        overflow-wrap: anywhere;
        pointer-events: none;
      }
      span {
        font-weight: 600;
        font-size: 0.625rem;
        letter-spacing: 0.05em;
        white-space: nowrap;
        @include themify() {
          color: themed('body-text-color-secondary');
        }
      }
      label {
        font-weight: 700;
        font-size: 0.75rem;
        @include themify() {
          color: themed('color-primary');
        }
      }
    }
  }
}

._selected {
  @include themify() {
    border: solid 1px themed('color-primary');
    background: rgba(themed('color-primary'), 0.2);
  }
}

// List item columns
.training-sessions__list-item2 div[val-name] {
  flex: 1 1 120px;
  width: 120px;
}
.training-sessions__list-item2 div[val-folder] {
  flex: 1 1 120px;
}
.training-sessions__list-item2 div[val-docker-id] {
  flex: 1 1 80px;
  min-width: 80px;
  white-space: nowrap
}
.training-sessions__list-item2 div[val-epoch] {
  flex: 1 1 20px;
  width: 20px;
  white-space: nowrap
}

.status-tag {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  min-width: 100px;
  border-radius: 18px;
  padding: 4px 6px;
  line-height: 1;
  font-size: 0.625rem;
  font-weight: 700;
  white-space: nowrap;
  border: 2px solid;
  box-shadow: 0px 0px 1px rgb(0 0 0 / 25%), 0px 1px 4px rgb(0 0 0 / 25%);
  @include themify() {
    color: themed('color-black');
  }

  &._complete {
    @include themify() {
      color: themed('color-status-complete');
      background: themed('color-status-complete-background');
    }
  }
  &._running {
    @include themify() {
      color: themed('color-status-running');
      background: themed('color-status-running-background');
    }
  }
  &._error {
    @include themify() {
      color: themed('color-status-error');
      background: themed('color-status-error-background');
    }
  }
  &._warning {
    @include themify() {
      color: themed('color-status-warning');
      background: themed('color-status-warning-background');
    }
  }

  div {
    color: inherit;
    width: 4px;
    height: 4px;
    border: solid 4px;
    border-radius: 4px;
    margin-right: 4px;
  }
}

hr.vr {
  display: flex;
  width: 1px;
  height: 60%;
  margin: 0;
}

a {
  text-decoration:none;
}
a + button {
  margin-left: 10px;
}

.tab-selector {
  display: flex;
  flex-direction: row;
}

.tab-selector input[type="radio"] {
  display: none;
}

.tab-selector label {
  padding: 2px 5px;
  margin-right: 10px;
  width: fit-content;
  font-size: 0.9rem;
  font-weight: bold;
  cursor: pointer;
}

.tab-selector input[type="radio"]:checked+label {
  @include themify() {
    color: themed('color-primary-500');
    border-bottom: 2px solid themed('color-primary-500');
  }
}
</style>
