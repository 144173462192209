<template>
  <div
    v-if="show && !isReviewMode"
    id="annotationToolRibbon"
    class="ribbon"
  >
    <div class="ribbon__group">
      <span>{{ zoom }}%</span>
      <IconButton
        id="zoomToFitBtn"
        class="ribbon__button"
        aria-label="Zoom To Fit (Z)"
        :icon="'zoom_to_extents'"
        :showHover="false"
        :type="''"
        :title="'Zoom To Fit (Z)'"
        @click="handleZoomToFit"
      />
      <IconButton
        id="zoomToSelectionBtn"
        class="ribbon__button"
        aria-label="Zoom To Selection (Selection + Z)"
        :disabled="!hasSelection"
        :icon="'zoom_to_selection'"
        :showHover="false"
        :type="''"
        :title="'Zoom To Selection (Selection + Z)'"
        @click="handleZoomToSelection"
      />
      <hr class="ribbon__vr">
      <IconButton
        id="undoBtn"
        class="ribbon__button"
        aria-label="Undo"
        :disabled="!canUndo"
        :icon="'undo'"
        :showHover="false"
        :type="''"
        :title="'Undo'"
        @click="handleUndo"
      />
      <IconButton
        id="revertChangesBtn"
        class="ribbon__button"
        aria-label="Revert All Changes"
        :icon="'restore'"
        :showHover="false"
        :type="''"
        :title="'Revert All Changes'"
        @click="handleRevertChanges"
      />
      <IconButton
        id="copyBtn"
        class="ribbon__button"
        aria-label="Copy Annotation"
        :icon="'copy'"
        :showHover="false"
        :type="''"
        :title="'Copy Annotations (ctrl + c)'"
        @click="handleCopyAnnotation"
      />
      <IconButton
        id="pasteBtn"
        class="ribbon__button"
        aria-label="Paste Annotation"
        :icon="'paste'"
        :showHover="false"
        :type="''"
        :title="'Paste Annotations (ctrl + v)'"
        :disabled="!hasCopiedAnnotations"
        @click="handlePasteAnnotation"
      />
      <IconButton
        id="delBtn"
        class="ribbon__button"
        aria-label="Delete Annotations"
        :icon="'remove_selection'"
        :showHover="false"
        :type="''"
        :title="'Delete Annotations (ctrl + x)'"
        @click="handleDeleteAnnotationsBulk"
      />
    </div>
    <div class="ribbon__group">
      <!-- <IconButton
        v-if="hasSelection"
        id="showAnnotationInfoBtn"
        class="ribbon__button"
        aria-label="Show Annotation Info"
        :icon="'auto'"
        :showHover="false"
        :type="''"
        :title="'Show Annotation Info'"
        @click="handleShowAnnotationInfo"
      /> -->
      <template v-if="hasSelection">
        <button
          v-if="!isKeyframeSelected"
          class="button button-secondary ribbon__button-secondary"
          :title="'Set Keyframe'"
          @click="handleSetKeyframe"
        >
          Set Keyframe
        </button>
        <button
          v-else
          class="button button-secondary ribbon__button-secondary"
          :title="'Remove Keyframe'"
          @click="handleRemoveKeyframe"
        >
          Remove Keyframe
        </button>
      </template>
      <template v-if="doKeyframesExist">
        <button
          class="button ribbon__button-primary"
          :class="{'button-spinner': isSubmittingKeyframes}"
          :disabled="isSubmittingKeyframes"
          :title="'Remove Keyframe'"
          @click="handleSubmitKeyframes"
        >
          Submit Keyframes
        </button>
      </template>
      <hr class="ribbon__vr">
      <!-- <button
        class="button button-secondary ribbon__button-secondary"
        :title="'Skip Image (Right Arrow)'"
        @click="handleSkipSequence"
      >
        Skip Sequence
      </button> -->
      <button
        class="button ribbon__button-primary"
        :title="'Submit Annotations (Enter)'"
        @click="handleLabellingSubmit"
      >
        Submit
      </button>
      <button
        class="button ribbon__button-primary"
        :title="'Finish Editing Sequence'"
        @click="completeTaskSequence"
      >
        Finish Editing Sequence
      </button>
    </div>
  </div>
  <div v-if="show && isReviewMode" id="annotationToolRibbon" class="ribbon">
    <div class="ribbon__group">
      <IconButton
        id="undoBtn"
        class="ribbon__button"
        aria-label="Undo"
        :disabled="!canUndo"
        :icon="'undo'"
        :showHover="false"
        :type="''"
        :title="'Undo'"
        @click="handleUndo"
      />
      <IconButton
        id="revertChangesBtn"
        class="ribbon__button"
        aria-label="Revert All Changes"
        :icon="'restore'"
        :showHover="false"
        :type="''"
        :title="'Revert All Changes'"
        @click="handleRevertChanges"
      />
      <IconButton
        id="copyBtn"
        class="ribbon__button"
        aria-label="Copy Annotation"
        :icon="'copy'"
        :showHover="false"
        :type="''"
        :title="'Copy Annotations (ctrl + c)'"
        @click="handleCopyAnnotation"
      />
      <IconButton
        id="pasteBtn"
        class="ribbon__button"
        aria-label="Paste Annotation"
        :icon="'paste'"
        :showHover="false"
        :type="''"
        :title="'Paste Annotations (ctrl + v)'"
        :disabled="!hasCopiedAnnotations"
        @click="handlePasteAnnotation"
      />
      <IconButton
        id="delBtn"
        class="ribbon__button"
        aria-label="Delete Annotations"
        :icon="'remove_selection'"
        :showHover="false"
        :type="''"
        :title="'Delete Annotations (ctrl + x)'"
        @click="handleDeleteAnnotationsBulk"
      />
    </div>
    <div class="ribbon__group">
      <IconButton
        v-if="hasSelection"
        id="showAnnotationInfoBtn"
        class="ribbon__button"
        aria-label="Show Annotation Info"
        :icon="'auto'"
        :showHover="false"
        :type="''"
        :title="'Show Annotation Info'"
        @click="handleShowAnnotationInfo"
      />
      <!-- <button
        class="button button-danger ribbon__button-secondary button-flex"
        :title="'Delete Image'"
        @click="handleDeleteImageFromDataset"
      >
        <SVGIcon
          class="me-1"
          :iconName="'delete'"
          :width="'20px'"
          :height="'20px'"
        />
        Delete Image
      </button> -->
      <IconButton
        v-if="hasSelection && trackingIdSelected"
        id="deleteTrackingIdBtn"
        class="ribbon__button"
        aria-label="Delete All Annotation in Series"
        :icon="'delete_sweep'"
        :showHover="false"
        :type="''"
        :title="'Delete All Annotation in Series'"
        @click="handleDeleteTrackingIdClicked(trackingIdSelected)"
      />
      <template v-if="hasSelection">
        <button
          v-if="!isKeyframeSelected"
          class="button button-secondary ribbon__button-secondary"
          :title="'Set Keyframe'"
          @click="handleSetKeyframe"
        >
          Set Keyframe
        </button>
        <button
          v-else
          class="button button-secondary ribbon__button-secondary"
          :title="'Remove Keyframe'"
          @click="handleRemoveKeyframe"
        >
          Remove Keyframe
        </button>
      </template>
      <template v-if="doKeyframesExist">
        <button
          class="button ribbon__button-primary"
          :class="{'button-spinner': isSubmittingKeyframes}"
          :disabled="isSubmittingKeyframes"
          :title="'Remove Keyframe'"
          @click="handleSubmitKeyframes"
        >
          Submit Keyframes
        </button>
        <hr class="ribbon__vr">
      </template>
      <!-- <button
        class="button button-secondary ribbon__button-secondary"
        :title="'Skip Image (Right Arrow)'"
        @click="handleSkipSequence"
      >
        Skip Sequence
      </button> -->
      <BasePersistentSplitButton
        v-model="submitType"
        class="ribbon__button-primary"
        title="Submit Annotations (Enter)"
        :options="submitTypes"
        @click="handleReviewSubmit"
      />
      <button
        class="button ribbon__button-primary"
        :title="'Finish Editing Sequence'"
        @click="completeTaskSequence"
      >
        Finish Editing Sequence
      </button>
    </div>
  </div>
  <ConfirmModal
    ref="confirmModal"
    :messageHeader="confirmMessageHeader"
    :message="confirmMessage"
    :buttonClass="'button-delete'"
    :buttonText="buttonText"
    @confirmed="confirmFunction"
  />
</template>

<script setup>
import SVGIcon from '@/components/SVGIcon.vue';
import IconButton from '@/components/IconButton.vue';
import BasePersistentSplitButton from '@/components/BasePersistentSplitButton.vue';
import useTasks from '@/composables/annotationTool/useTasks.js';
import { useViewerVisualizationsStore } from '@/stores/useViewerVisualizationsStore.js';
import {
  ref, toRefs, onMounted, computed, watch,
} from 'vue';
import { storeToRefs } from 'pinia';
import DatastoreConnect from '@/assets/js/DatastoreFunctions/datastore-interface';
import ConfirmModal from '@/components/ConfirmModal.vue';

const props = defineProps({
  show: {
    type: Boolean,
    default: true,
  },
  zoom: {
    type: Number,
    default: 100,
  },
  canUndo: {
    type: Boolean,
    default: false,
  },
  hasSelection: {
    type: Boolean,
    default: false,
  },
  labels: {
    type: Array,
    default: () => [],
  },
  annotationSets: {
    type: Array,
    default: () => [],
  },
  annotationDisplaySettings: {
    type: Object,
    default: null,
  },
  isReviewMode: {
    type: Boolean,
    default: false,
  },
  frameImageObj: {
    type: Object,
    default: null,
  },
  reviewSettings: {
    type: Object,
    default: null,
  },
  selectedAnnotations: {
    type: Array,
    default: () => [],
  },
  copiedAnnotations: {
    type: Array,
    default: () => [],
  },
});
const {
  show,
  zoom,
  canUndo,
  hasSelection,
  labels,
  annotationSets,
  annotationDisplaySettings,
  isReviewMode,
  frameImageObj,
  reviewSettings,
  selectedAnnotations,
  copiedAnnotations,
} = toRefs(props);

const emit = defineEmits([
  'zoom-to-fit',
  'zoom-to-selection',
  'undo',
  'revert-changes',
  'show-annotation-info',
  'skip',
  'submit',
  'copy',
  'paste',
  'bulk-del-ann',
  'exit-editor',
]);

const confirmModal = ref(null);
const confirmMessage = ref(null);
const confirmMessageHeader = ref(null);
const confirmFunction = ref(null);
const buttonText = ref(null);

const {
  internalImageObj,
  internalAnnotations,
  submitType,
  skipTaskSequence,
  completeTaskSequence,
  handleFrameAuditSubmitAllAnnotations,
  handleFrameAuditSubmitAcceptedAnnotations,
  handleFrameLabellingSubmitAnnotations,
  deleteTaskImageFromDataset,
  hasReviewTask,
} = useTasks();

const internalAnnotationDisplaySettings = ref(null);
const submitTypes = ref([
  { title: 'Submit All Annotations', value: 'submit-all-annotations' },
  { title: 'Submit Accepted Annotations', value: 'submit-accepted-annotations' },
]);
const isSubmittingKeyframes = ref(false);

const isKeyframeSelected = computed(() => {
  if (selectedAnnotations.value[0]) {
    const selectedKeyframeObject = keyframes.value.find((keyframeObject) => keyframeObject.id === selectedAnnotations.value[0].id);
    if (selectedKeyframeObject && Object.keys(selectedKeyframeObject.keyframes).map((key) => parseInt(key)).includes(frame.value)) {
      return true;
    }
  }
  return false;
});

const hasCopiedAnnotations = computed(() => {
  if (copiedAnnotations.value.length > 0) {
    return true;
  }
  return false;
});

const hasAnnotations = computed(() => {
  if (internalAnnotations.value.length > 0) {
    return true;
  }
  return false;
});

const keyframeSelected = computed(() => {
  if (isKeyframeSelected.value) {
    return selectedAnnotations.value[0].id;
  }
  return null;
});

const trackingIdSelected = computed(() => {
  if (selectedAnnotations?.value[0]?.tracking_id) {
    return selectedAnnotations.value[0].tracking_id;
  }
  return null;
});

onMounted(async () => {
  if (annotationDisplaySettings.value) {
    internalAnnotationDisplaySettings.value = annotationDisplaySettings.value;
  }
});

function handleZoomToFit() {
  emit('zoom-to-fit');
}

function handleZoomToSelection() {
  emit('zoom-to-selection');
}

function handleRevertChanges() {
  emit('revert-changes');
}

function handleUndo() {
  emit('undo');
}

function handleShowAnnotationInfo() {
  emit('show-annotation-info');
}

async function handleDeleteImageFromDataset() {
  const resp = await deleteTaskImageFromDataset(internalImageObj.value?.id)
    .catch((error) => {
      alert(error);
    });
}

function handleSkipSequence() {
  emit('skip');
  skipTaskSequence();
}

function handleLabellingSubmit() {
  handleFrameLabellingSubmitAnnotations(frameImageObj.value);
  currentAnimationSample.value.imageObj.annotations = internalAnnotations.value;
  emit('submit');
}

function handleReviewSubmit(submitType) {
  if (submitType === 'submit-all-annotations') {
    handleFrameAuditSubmitAllAnnotations(frameImageObj.value)
      .then(() => {
        currentAnimationSample.value.imageObj.review_status = "Done";
      })
      .catch((err) => {
        console.error(err);
      });
  } else if (submitType === 'submit-accepted-annotations') {
    handleFrameAuditSubmitAcceptedAnnotations(frameImageObj.value)
      .then(() => {
        currentAnimationSample.value.imageObj.review_status = "Done";
      })
      .catch((err) => {
        console.error(err);
      });
  }
  currentAnimationSample.value.imageObj.annotations = internalAnnotations.value;
  emit('submit');

  if (!hasReviewTask.value) {
    emit('exit-editor');
  }
}

function handleEnter() {
  emit(submitType.value);
}

// Keyframes
const visualizationStore = useViewerVisualizationsStore();
const {
  animationImageCache, currentAnimationSample, frame, keyframes, doKeyframesExist,
} = storeToRefs(visualizationStore);
const { removeKeyframe, setKeyframeNew, removeAnnotationsFromCache } = visualizationStore;

function handleSetKeyframe() {
  selectedAnnotations.value.forEach((anno) => {
    setKeyframeNew(frame.value, currentAnimationSample.value.imageObj, anno);
  });
}

function handleRemoveKeyframe() {
  removeKeyframe(keyframeSelected.value);
}

function handleDeleteTrackingIdClicked(trackingId) {
  confirmMessage.value = 'Are you sure you want to permanently delete all annotations from this keyframe series?';
  confirmMessageHeader.value = 'Delete Annotations from Series';
  buttonText.value = 'Delete All';
  confirmModal.value.showModal();
  confirmFunction.value = () => { handleDeleteTrackingId(trackingId); };
}

async function handleDeleteTrackingId(trackingId) {
  const dataConnect = new DatastoreConnect();
  const removedAnnotations = await dataConnect.deleteAnnotationsByTrackingId({
    tracking_id: trackingId,
    annotation_set_id: reviewSettings.value.reviewTask.dest_annotation_set_id,
  })
    .then((resp) => {
      if (resp.error !== undefined) {
        throw Error(resp.error);
      }
      return resp.result;
    })
    .catch((error) => {
      console.error(error);
      throw error;
    });

  if (removedAnnotations.length > 0) {
    removeAnnotationsFromCache(removedAnnotations);
  }
}

// async function handleSubmitKeyframes() {
//   const params = [];
//   keyframeRanges.value.forEach((range) => {
//     const start = keyframes.value[range[0]];
//     const end = keyframes.value[range[1]];
//     start.frame = range[0];
//     end.frame = range[1];
//     params.push([start, end]);
//   });

//   const image_ids = animationImageCache.value.map((item) => item.imageObj.id);
//   const newAnnotations = await submitKeyframes({
//     keyframes: params,
//     image_ids,
//     sequence_id: internalImageObj.value.sequence_id,
//     review_task_id: reviewSettings.value.reviewTask.id,
//     review_session_id: reviewSettings.value.reviewSession.id,
//     annotation_set_id: reviewSettings.value.reviewTask.dest_annotation_set_id,
//   })
//     .catch((error) => {
//       console.error(error);
//     });

//   animationImageCache.value.forEach((cacheFrame) => {
//     if (newAnnotations[cacheFrame.imageObj.id]) {
//       cacheFrame.annotations = newAnnotations[cacheFrame.imageObj.id];
//       cacheFrame.imageObj.annotations = newAnnotations[cacheFrame.imageObj.id];
//     }
//   });

//   keyframes.value = {};
// }

async function handleSubmitKeyframes() {
  isSubmittingKeyframes.value = true;
  const image_ids = Object.values(animationImageCache.value).map((item) => item.imageObj.id);
  const newAnnotations = await submitKeyframes({
    keyframes: keyframes.value,
    image_ids,
    sequence_id: internalImageObj.value.sequence_id,
    review_task_id: reviewSettings.value.reviewTask.id,
    review_session_id: reviewSettings.value.reviewSession.id,
    annotation_set_id: reviewSettings.value.reviewTask.dest_annotation_set_id,
  })
    .catch((error) => {
      console.error(error);
    });

  console.log(newAnnotations);

  Object.values(animationImageCache.value).forEach((cacheFrame) => {
    if (newAnnotations[cacheFrame.imageObj.id]) {
      newAnnotations[cacheFrame.imageObj.id].forEach((anno) => { anno.reviewStatus = "verified"; });
      cacheFrame.annotations = [...cacheFrame.annotations, ...newAnnotations[cacheFrame.imageObj.id]];
      cacheFrame.imageObj.annotations = [...cacheFrame.imageObj.annotations, ...newAnnotations[cacheFrame.imageObj.id]];
    }
  });

  keyframes.value = [];
  isSubmittingKeyframes.value = false;
}

async function submitKeyframes(params) {
  const dataConnect = new DatastoreConnect();
  return dataConnect.submitKeyframes(params)
    .then((resp) => {
      if (resp.error !== undefined) {
        throw Error(resp.error);
      }
      return resp.result;
    })
    .catch((error) => {
      throw error;
    });
}

function handleCopyAnnotation() {
  emit('copy');
}

function handlePasteAnnotation() {
  emit('paste');
}

function handleDeleteAnnotationsBulk() {
  emit('bulk-del-ann');
}

</script>

<style lang="scss" scoped>
.ribbon {
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  min-height: 37px;
  max-height: 37px;
  padding: 2px 16px;
  justify-content: space-between;
  z-index: 10;
  box-shadow: 0 3px 3px -3px gray, 0 10px 10px -10px gray;
  color: var(--ribbon-icons-color);
  background: var(--ribbon-bg);
  border-left: solid 1px var(--ribbon-border);

  &__group {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
  }

  &__vr {
    display: flex;
    width: 1px;
    height: 60%;
    margin: 0;
  }

  &__button {
    display: flex;
    min-width: 22px;
    height: 22px;
    color: inherit;
    background: none;
    border: none;
    font-size: 0.875rem;
    line-height: 1rem;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: .05em;
    align-items: center;
    cursor: pointer;

    &:hover:not(:disabled), &.active:not(:disabled) {
      box-shadow: 0 0 0 4px var(--icon-hover-color);
      background-color: var(--icon-hover-color);
      border-radius: 4px;
    }

    &:disabled {
      cursor: default;
      color: var(--color-disabled);
    }
  }

  &__button-primary {
    display: flex;
    height: 28px;
    font-size: 0.875rem;
    line-height: 1rem;
    align-items: center;
  }

  &__button-secondary {
    display: flex;
    height: 28px;
    font-size: 0.875rem;
    line-height: 1rem;
    align-items: center;
  }

  &__labeled_button {
    width: auto;
  }
}

:deep(.filter-button) {
  height: 22px;
}

</style>
