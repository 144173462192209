<template>
  <v-layout>
    <v-menu :location="'bottom end'">
      <template #activator="{ props }">
        <div class="split-btn">
          <v-btn
            class="main-btn button"
            @click="handleMainClick"
          >
            {{ selectedOption.title }}
          </v-btn>
          <v-btn
            class="actions-btn button"
            v-bind="props"
          >
            <v-icon left>mdi-menu-down</v-icon>
          </v-btn>
        </div>
      </template>

      <v-list>
        <v-list-item
          v-for="(option, index) in options"
          :key="index"
          :value="option"
          @click="handleListItemClick(option)"
        >
          <template #prepend>
            <div class="list-item-prepend">
              <SVGIcon
                v-if="selectedOption === option"
                :iconName="'check'"
                :width="'20px'"
                :height="'20px'"
              />
            </div>
          </template>
          <v-list-item-title>{{ option.title }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </v-layout>
</template>

<script>
import SVGIcon from '@/components/SVGIcon.vue';

export default {
  name: 'BasePersistentSplitButton',
  components: {
    SVGIcon,
  },
  props: {
    modelValue: {
      type: null,
      default: null,
    },
    options: {
      type: Array,
      default: () => [
        { title: 'Option 1', value: 1 },
        { title: 'Option 2', value: 2 },
      ],
    },
  },
  emits: ['update:modelValue', 'click'],
  data() {
    return {
      selectedOption: { title: '', value: null },
    };
  },
  watch: {
    selectedOption() {
      this.$emit('update:modelValue', this.selectedOption.value);
    },
  },
  mounted() {
    if (this.modelValue) {
      this.selectedOption = this.options.find((option) => option.value === this.modelValue);
    } else if (this.options.length > 0) {
      this.selectedOption = this.options[0];
    }
  },
  methods: {
    handleListItemClick(option) {
      this.selectedOption = option;
    },
    handleMainClick() {
      this.$emit('click', this.selectedOption.value);
    },
  },
};
</script>

<style lang="scss" scoped>

.main-btn{
  display: flex;
  align-items: center;
  justify-content: center;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  height: inherit;
  font-size: inherit;
}
.actions-btn{
  display: flex;
  align-items: center;
  justify-content: center;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  min-width: 35px !important;
  margin-left: -3.5px;
  height: inherit;
  border-left: 1px solid var(--color-primary-300);
}
.split-btn{
  display: flex;
  height: inherit;
}
.list-item-prepend {
  width: 20px;
  margin-right: 8px;
}
</style>
