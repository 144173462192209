<template>
  <div class="controls-bar">
    <DatasetFilter
      v-model="datasetFilters"
      :dataset="dataset"
      :annotationSets="annotationSets"
      :labels="labels"
      :types="types"
    />
  </div>
  <GalleryGrid
    ref="galleryGrid"
    v-model:page="currentPage"
    v-model:totalPages="totalPagesCount"
    :imageList="imageList"
    :totalImageCount="totalImageCount"
    :imagesPerPage="imagesPerPage"
    :annotationSets="annotationSets"
    class="annotation-editor-grid"
  >
    <template #header-start>
      <div class="grid-header-start">
        <DisplayAnnotationsFilter
          v-model="annotationDisplaySettings"
          :annotationSets="annotationSets"
          :labels="labels"
          :showSplitAnnotationOption="shouldShowSplitAnnotationOption"
        />
      </div>
    </template>
    <template #header-end>
      <div class="grid-header-end">
        <div class="sort-by">
          <GalleryImageSort v-model:sortBy="sortBy" v-model:ascend="reverse" />
        </div>
      </div>
    </template>
    <template #grid-item>
      <template v-if="isOpen">
        <AnnotationEditorGalleryGridItem
          v-for="imageObj in imageList"
          :key="imageObj.id"
          :imageObj="imageObj"
          :annotations="imageObj.annotations"
          :annotationSets="annotationSets"
          :annotationDisplayType="colorAnnotationsBy"
          :filterAnnotationsBySets="getDisplayAnnotationSet(imageObj)"
          :filterAnnotationsByLabelIndexes="displayLabels"
          :labels="labels"
          :imageReviewStatus="imageObj.image_review_status"
          :reviewSettings="reviewSettings"
          :splitAnnotations="showSplitAnnotations"
          v-bind="gridItemDisplayProp"
          @grid-item-clicked="imageClicked(imageObj)"
          @delete-image-from-dataset="deleteImageFromDataset"
        />
      </template>
    </template>
    <template #empty-state>
      <GalleryEmptyState ref="galleryEmptyState" :datasetID="dataset?.id" />
    </template>
  </GalleryGrid>
</template>

<script>
import DatasetFilter from '@/components/DatasetComponent/DatasetFilters/DatasetFilter.vue';
import DisplayAnnotationsFilter from '@/components/DatasetComponent/GalleryComponent/DisplayAnnotationsFilterV2.vue';
import DatastoreConnect from '@/assets/js/DatastoreFunctions/datastore-interface';
import GalleryGrid from '@/components/DatasetComponent/GalleryComponent/GalleryGrid.vue';
import AnnotationEditorGalleryGridItem from '@/components/DatasetComponent/GalleryComponent/AnnotationEditorGalleryGridItem.vue';
import GalleryEmptyState from '@/components/DatasetComponent/GalleryComponent/GalleryEmptyState.vue';
import SVGIcon from '@/components/SVGIcon.vue';
import IconButton from '@/components/IconButton.vue';
import useTasks from '@/composables/annotationTool/useTasks.js';
import GalleryImageSort from '@/components/DatasetComponent/GalleryComponent/GalleryImageSort.vue';

export default {
  name: 'AnnotationEditorGallery',
  components: {
    GalleryGrid,
    DatasetFilter,
    DisplayAnnotationsFilter,
    AnnotationEditorGalleryGridItem,
    GalleryEmptyState,
    SVGIcon,
    IconButton,
    GalleryImageSort,
  },
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
    images: {
      type: Array,
      default: () => [],
    },
    dataset: {
      type: Object,
      default: null,
    },
    reviewSettings: {
      type: Object,
      default: null,
    },
  },
  emits: ['image-clicked'],
  setup() {
    const {
      internalImageObj,
      pendingTaskImages,
      switchToImageID,
      switchToDoneImage,
      imageFilters,
      auditorType,
      updateTaskImageFilters,
    } = useTasks();

    return {
      internalImageObj,
      pendingTaskImages,
      switchToImageID,
      switchToDoneImage,
      imageFilters,
      auditorType,
      updateTaskImageFilters,
    };
  },
  data() {
    return {
      dataConnect: new DatastoreConnect(this.$store.state.enterpriseServerUrl),
      imageList: null,
      imageListPromise: null,
      imagesPerPage: 40,
      currentPage: 1,
      totalPagesCount: 0,
      totalImageCount: 0,
      minImageNumber: 0,
      maxImageNumber: 0,
      datasetFilters: {},
      controller: new AbortController(),
      annotationDisplaySettings: {
        displayAnnotationSets: { annotation_sets: [] },
      },
      sortBy: 'id',
      reverse: false,
      filtersUpdated: false,
    };
  },
  computed: {
    datasets() {
      return this.$store.state.datasets.datasetList;
    },
    types() {
      if (this.dataset) {
        return this.dataset.annotation_types;
      }
      return [];
    },
    labels() {
      if (this.dataset) {
        return this.dataset.labels;
      }
      return [];
    },
    annotationSets() {
      if (this.dataset) {
        return this.dataset.annotation_sets;
      }
      return [];
    },
    getImagesParams() {
      return {
        ...this.getImagesListParams,
        ...this.getImagesFilterParams,
      };
    },
    getImagesListParams() {
      if (!this.dataset) {
        return null;
      }

      const limit = this.imagesPerPage;
      const offset = (this.currentPage - 1) * this.imagesPerPage;
      const sort_by = this.sortBy;
      const reverse = this.reverse;

      const params = {
        sort_by,
        reverse,
        offset,
        limit,
      };

      return params;
    },
    getImagesFilterParams() {
      if (!this.dataset) {
        return null;
      }

      const params = {
        images_filter: { dataset_id: this.dataset.id },
        image_files_filter: {},
        annotations_filter: {},
        image_review_status_filter: {},
        review_task_id: this.reviewSettings?.reviewTask.id,
        get_annotations: true,
      };

      // Images filter
      if (this.datasetFilters.groupsFilter && this.datasetFilters.groupsFilter.length > 0) {
        params.images_filter.group_ids = this.datasetFilters.groupsFilter;
      }

      // Annotations filter
      if (this.datasetFilters.confidenceRangeFilter) {
        params.annotations_filter.lower_score = this.datasetFilters.confidenceRangeFilter[0];
        params.annotations_filter.upper_score = this.datasetFilters.confidenceRangeFilter[1];
      }
      if (this.datasetFilters.annotationTypeFilter && this.datasetFilters.annotationTypeFilter.length > 0) {
        params.annotations_filter.types = this.datasetFilters.annotationTypeFilter;
      }
      if (this.datasetFilters.annotationSetFilter && this.datasetFilters.annotationSetFilter.length > 0) {
        params.annotations_filter.annotation_set_ids = this.datasetFilters.annotationSetFilter.map((set) => set.id);
      }
      if (this.datasetFilters.imagesWithAnnotationSetFilter && this.datasetFilters.imagesWithAnnotationSetFilter.length > 0) {
        params.annotations_filter.images_with_annotation_set_ids = this.datasetFilters.imagesWithAnnotationSetFilter.map((set) => set.id);
      }
      if (this.datasetFilters.annotationLabelFilter && this.datasetFilters.annotationLabelFilter.length > 0) {
        params.annotations_filter.images_with_annotation_label_indexes = this.datasetFilters.annotationLabelFilter;
      }
      if (this.datasetFilters.boundingBoxSizeFilter) {
        Object.entries(this.datasetFilters.boundingBoxSizeFilter).forEach(([key, value]) => {
          if (key !== 'type' && value) {
            params.annotations_filter[`${key}_${this.datasetFilters.boundingBoxSizeFilter.type}`] = value;
          }
        });
      }

      // Image files filter
      if (this.datasetFilters.imageNameFilter && this.datasetFilters.imageNameFilter.length > 0) {
        params.image_files_filter.image_name = this.datasetFilters.imageNameFilter;
      }
      if (this.datasetFilters.timeRangeFilter) {
        params.image_files_filter.lower_time = this.datasetFilters.timeRangeFilter[0];
        params.image_files_filter.upper_time = this.datasetFilters.timeRangeFilter[1];
      }
      if (this.datasetFilters.dateRangeFilter) {
        if (this.datasetFilters.dateRangeFilter[0]) {
          params.image_files_filter.lower_date = new Date(this.datasetFilters.dateRangeFilter[0]).toISOString();
        }
        if (this.datasetFilters.dateRangeFilter[1]) {
          params.image_files_filter.upper_date = new Date(this.datasetFilters.dateRangeFilter[1]).toISOString();
        }
      }
      if (this.datasetFilters.cameraSourceFilter && this.datasetFilters.cameraSourceFilter.length > 0) {
        params.image_files_filter.image_sources = this.datasetFilters.cameraSourceFilter;
      }

      if (Object.keys(params.annotations_filter).length === 0) {
        delete params.annotations_filter;
      }

      // if (this.datasetFilters.displayAnnotationsFilter && this.datasetFilters.displayAnnotationsFilter.value.length > 0) {
      //   if (this.datasetFilters.displayAnnotationsFilter.displayType === 'byAnnotationSets') {
      //     params.annotations_filter.aggregate_annotation_set_ids = this.datasetFilters.displayAnnotationsFilter.value;
      //   }
      //   // if (this.datasetFilters.displayAnnotationsFilter.displayType === 'byAnnotationLabels') {}
      // }

      return params;
    },
    gridItemDisplayProp() {
      if (!this.datasetFilters?.displayAnnotationsFilter) {
        return {};
      }
      if (this.datasetFilters?.displayAnnotationsFilter?.displayType === 'byAnnotationSets') {
        return { filterAnnotationsBySets: this.datasetFilters.displayAnnotationsFilter.value };
      }
      if (this.datasetFilters?.displayAnnotationsFilter?.displayType === 'byAnnotationLabels') {
        return { filterAnnotationsByLabelIndexes: this.datasetFilters.displayAnnotationsFilter.value };
      }
      return {};
    },
    colorAnnotationsBy() {
      if (this.annotationDisplaySettings?.colorAnnotationsBy?.type) {
        return this.annotationDisplaySettings?.colorAnnotationsBy?.type;
      }
      return 'set';
    },
    displayAnnotationSets() {
      if (this.annotationDisplaySettings?.displayAnnotationSets) {
        return this.annotationDisplaySettings?.displayAnnotationSets.annotation_sets;
      }

      return [];
    },
    displayLabels() {
      if (this.annotationDisplaySettings?.displayLabels) {
        return this.annotationDisplaySettings?.displayLabels.labels;
      }
      return [];
    },
    shouldShowSplitAnnotationOption() {
      if (this.auditorType === 'annotation') {
        return false;
      }
      return true;
    },
    showSplitAnnotations() {
      if (this.auditorType === 'annotation') {
        return true;
      }
      return this.annotationDisplaySettings?.splitAnnotations;
    },
  },
  watch: {
    getImagesParams: {
      deep: true,
      async handler(params) {
        if (!params || !this.isOpen) {
          return;
        }
        this.imageListPromise = new Promise((resolve, reject) => {
          this.getImages().then((imageList) => {
            this.imageList = imageList;
            resolve(imageList);
          });
        });
      },
    },
    getImagesFilterParams: {
      deep: true,
      handler() {
        this.filtersUpdated = true;
      },
    },
    isOpen() {
      if (this.isOpen) {
        this.handleGalleryOpened();
      } else {
        this.handleGalleryClosed();
      }
    },
    imageList: {
      deep: true,
      handler(newList) {
        this.minImageNumber = ((this.currentPage - 1) * this.imagesPerPage) + 1;
        this.maxImageNumber = ((this.currentPage - 1) * this.imagesPerPage) + newList.length;
      },
    },
    internalImageObj() {
      if (!this.imageFilters || !this.isOpen) {
        return;
      }
      this.imageListPromise = new Promise((resolve, reject) => {
        this.getImages().then((imageList) => {
          this.imageList = imageList;
          resolve(imageList);
        });
      });
    },
    pendingTaskImages() {
      if (!this.imageFilters || !this.isOpen) {
        return;
      }
      this.imageListPromise = new Promise((resolve, reject) => {
        this.getImages().then((imageList) => {
          this.imageList = imageList;
          resolve(imageList);
        });
      });
    },
  },
  mounted() {
    this.annotationDisplaySettings.displayAnnotationSets.annotation_sets.push(this.reviewSettings?.reviewTask.source_annotation_set_id);
  },
  methods: {
    async getSelectionImageCount(params) {
      const resp = await this.dataConnect.getReviewTaskImageList({ only_count: true, ...params })
        .catch((error) => {
          // TODO: handle error
          console.log(error);
        });
      if (!resp || resp.error || !resp.result) {
        // TODO: handle error
        return 0;
      }

      return resp.result;
    },
    async getImages() {
      this.controller.abort();
      this.controller = new AbortController();
      const currentController = this.controller;

      this.totalImageCount = await this.getSelectionImageCount(this.getImagesFilterParams, currentController.signal);
      if (this.totalImageCount === 0) {
        return [];
      }

      // Handle case when currentPage is greater than the new total number of pages
      if (Math.ceil(this.totalImageCount / this.imagesPerPage) < this.currentPage) {
        let newPage = Math.ceil(this.totalImageCount / this.imagesPerPage);
        if (newPage < 1) {
          newPage = 1;
        }
        this.currentPage = newPage;
        return this.imageList;
      }

      const imageListParams = {
        ...this.getImagesListParams,
        ...this.getImagesFilterParams,
      };
      const resp = await this.dataConnect.getReviewTaskImageList(imageListParams, currentController.signal)
        .catch((error) => {
          if (currentController.signal.aborted) {
            return this.imageList;
          }
          console.log(error);
          alert(error);
          return this.imageList;
        });
      if (!resp || resp.error || !resp.result) {
        if (resp && resp.error) {
          console.error(resp.error);
          alert(resp.error.message);
        }
        return this.imageList;
      }

      return resp.result;
    },
    async deleteImageFromDataset(imageObj) {
      const param = {
        image_ids: [imageObj.id],
      };
      const resp = await this.dataConnect.deleteImageFromDataset(param);
      if (!resp || resp.error || !resp.result) {
        if (resp && resp.error) {
          console.error(resp.error);
        }
        alert("Image failed to delete");
      } else {
        this.imageListPromise = new Promise((resolve, reject) => {
          this.getImages().then((imageList) => {
            this.imageList = imageList;
            resolve(imageList);
          });
        });
      }
    },
    async imageClicked(imageObj) {
      if (imageObj?.image_review_status?.status === 'Done') {
        this.switchToDoneImage(imageObj);
        this.$emit('image-clicked', imageObj);
      } else {
        await this.switchToImageID(imageObj.id);
        this.$emit('image-clicked', imageObj);
      }

      this.imageListPromise = new Promise((resolve, reject) => {
        this.getImages().then((imageList) => {
          this.imageList = imageList;
          resolve(imageList);
        });
      });
    },
    getDisplayAnnotationSet(imageObj) {
      if (imageObj?.image_review_status?.status === 'Done') {
        return [this.reviewSettings?.reviewTask.dest_annotation_set_id];
      } else if (this.annotationDisplaySettings?.displayAnnotationSets) {
        return this.annotationDisplaySettings?.displayAnnotationSets.annotation_sets;
      }
      return [];
    },
    handleGalleryOpened() {
      this.filtersUpdated = false;

      this.imageListPromise = new Promise((resolve, reject) => {
        this.getImages().then((imageList) => {
          this.imageList = imageList;
          resolve(imageList);
        });
      });
    },
    handleGalleryClosed() {
      if (this.filtersUpdated) {
        this.updateTaskImageFilters(this.getImagesFilterParams);
      }
      this.filtersUpdated = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.controls-bar {
  display: flex;
  flex-direction: row;
  padding: 10px 20px 10px 20px;
  align-items: center;
  border-bottom: 1px solid #c9c9c9;
  @include themify() {
    background: themed('color-ribbon');
  }
  justify-content: space-between;
}
.grid-header-start {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  height: 100%;
  align-items: center;
  gap: 8px;
}

.grid-header-end {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  height: 100%;
  align-items: center;
  gap: 8px;
  justify-content: flex-end;
}

#dataset-select {
  width: 300px;
}

.gallery-image-count {
  margin-left: 16px;
  font-size: 0.75rem;
  font-weight: 600;
  @include themify() {
    color: themed('body-text-color-secondary');
  }
}

.sort-by {
  display: flex;
  flex-direction: row;
  align-items: center;

  &__select {
    width: 130px;
  }

  &__reverse._invert {
    transform: rotate(180deg);
  }
}
</style>
