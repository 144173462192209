<template>
  <div class="conf-matrix scrollbar">
    <div class="table-header">
      <span class="matrix-title">Confusion Matrix</span>
    </div>
    <div class="matrix-container">
      <div class="matrix scrollbar">
        <ConfusionMatrixV2 :matrix="localMatrix" />
      </div>
    </div>
  </div>
</template>

<script>
import ConfusionMatrixV2 from "@/components/ValidationPage/ConfusionMatrixV2.vue";

export default {
  name: "ConfusionMatrixCardV2",
  components: {
    ConfusionMatrixV2,
  },
  props: {
    matrix: {
      type: Object,
      default: null,
    },
  },
  emits: { },
  data() {
    return {
      series: [],
      localMatrix: null,
    };
  },
  watch: {
    matrix: {
      immediate: true,
      handler(d) {
        if (d) {
          this.updateChart();
        }
      },
    },
  },
  methods: {
    // update value of each column
    updateChart() {
      const labels = Object.values(this.matrix['index_name_map']);
      labels.unshift('background');
      const formattedData = [];
      this.matrix['confusion_matrix_data'].forEach((row, i) => {
        row.forEach((value, j) => {
          const obj = {
            Value: value,
            Xlabel: labels[j],
            Ylabel: labels[i],
          };
          formattedData.push(obj);
        });
      });
      this.localMatrix = {
        labels,
        data: formattedData,
      };
    },
  },
};
</script>

<style lang="scss">
.conf-matrix {
  display: flex;
  flex-direction: column;
  height: fit-content;
  box-shadow: 0 0 10px rgba(0,0,0,0.1), 0 0 5px rgba(0,0,0,0.25);
  border-radius: 8px;
  padding-top: 10px;
  @include themify() {
    background: themed('card-color');
  }

  .apexcharts-heatmap g {
    @include themify() {
    color: themed('matrix-text-color') !important;
    }
  }

  .apexcharts-heatmap rect:hover {
    filter: brightness(85%) !important;
  }
}

.table-header {
  display: flex;
  align-items: center;

}
.matrix-title {
  display: flex;
  align-items: center;
  margin-left: 15px;
  margin-right: 15px;
  font-size: 1.3rem;
  font-weight:700;
}

.matrix-subtitle {
  font-size: 0.9rem;
  font-weight: 600;
  min-width: 200px;
  align-items: center;
  margin-left: 15px;
}

.matrix-container {
  width: 100%;
  // height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.matrix {
  display: block;
  height: 100%;
  min-height: 600px;
  aspect-ratio: 16/9;
}

.matrix:deep{
  pointer-events: none;
}

.apex-charts-data-labels {
  text-shadow: 0px 0px 17px rgba(0, 0, 0, 1);
}
</style>
