<template>
  <div ref="sliderContainer" class="range-slidercontainer">
    <div class="range-slider">
      <div ref="right" class="range-slider-left" />
      <input
        ref="singleSlider"
        v-model="localValue"
        type="range"
        :step="step"
        :min="min"
        :max="max"
        :disabled="disabled"
        @input="handleSliderInput"
      >
    </div>
  </div>
</template>

<script>
export default {
  name: 'SingleSlider',
  props: {
    step: {
      type: Number,
      default: 1,
    },
    min: {
      type: Number,
      default: 0,
    },
    max: {
      type: Number,
      default: 1,
    },
    sliderValue: {
      type: Number,
      default: 0,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: [
    'input', 'update:sliderValue',
  ],
  data() {
    return {
      localValue: 0,
      resizeObserver: null,
    };
  },
  watch: {
    sliderValue(newVal) {
      this.localValue = newVal;
      this.refresh();
    },
  },
  beforeMount() {
    this.localValue = this.sliderValue;
  },
  mounted() {
    this.refresh();
    window.addEventListener("resize", () => this.refresh());
  },
  beforeUnmount() {
    window.removeEventListener("resize", () => this.refresh());
  },
  methods: {
    refresh() {
      const thumbHalfWidth = 10;
      const value = this.localValue;
      const minValue = this.min;
      const maxValue = this.max;
      const totalInputWidth = this.$refs.singleSlider.clientWidth;
      const width = (((value - minValue) / (maxValue - minValue)) * ((totalInputWidth - thumbHalfWidth) - thumbHalfWidth));
      const maxWidth = totalInputWidth - thumbHalfWidth;
      if (value > maxValue) {
        this.$refs.right.style.width = `${maxWidth}px`;
      } else {
        this.$refs.right.style.width = `${width + thumbHalfWidth}px`;
      }
      this.$emit('input');
      this.$emit('update:sliderValue', this.localValue);
    },
    handleSliderInput(event) {
      const thumbHalfWidth = 10;
      const value = event.target.value;
      const minValue = this.min;
      const maxValue = this.max;
      const totalInputWidth = this.$refs.singleSlider.clientWidth;
      const width = (((value - minValue) / (maxValue - minValue)) * ((totalInputWidth - thumbHalfWidth) - thumbHalfWidth));
      const maxWidth = totalInputWidth - thumbHalfWidth;

      if (value > maxValue) {
        this.$refs.right.style.width = `${maxWidth}px`;
      } else {
        this.$refs.right.style.width = `${width + thumbHalfWidth}px`;
      }
      this.$emit('input');
      this.$emit('update:sliderValue', Number(event.target.value));
    },
  },
};
</script>

<style lang="scss" scoped>
.range-slidercontainer {
  width: 100%;
}

.range-slider {
  position: relative;
  margin: 0;
  height: 25px;
}

.range-slider input[type='range'] {
  -webkit-appearance: none;
  pointer-events: none;
  padding: 0;
  margin: 0;
  position: absolute;
  left: 0;
  top: 10px;
  height: 5px;
  width: 100%;
  border-radius: 14px;
  @include themify {
    background: themed('range-slider-unselected-color');
  }
}
.range-slider input[type='range']:focus, .range-slider input[type='range']:active {
  outline: none;
}
.range-slider input[type='range']::-webkit-slider-thumb {
  -webkit-appearance: none;
  box-sizing: content-box;
  width: 20px;
  height: 20px;
  border: none;
  pointer-events: auto;
  border-radius: 14px;
  @include themify {
    background-color: themed('range-slider-thumb-color');
  }
  cursor: pointer;
  position: relative;
  z-index: 3;
  top: -7.5px;
}
.range-slider input[type='range']:disabled::-webkit-slider-thumb {
  pointer-events: none;
  @include themify {
    background-color: themed('slider-background-disabled');
  }
}
.range-slider input[type='range']::-webkit-slider-runnable-track {
  -webkit-appearance: none;
  @include themify {
    background: themed('range-slider-unselected-color');
  }
  height: 5px;
  border-radius: 2.5px;
}
.range-slider input[type='range']:disabled::-webkit-slider-runnable-track {
  @include themify {
    background: themed('slider-background-disabled');
  }
}
.range-slider .range-slider-value {
  color: white;
  position: relative;
  width: 60px;
  display:flex;
  z-index: 3;
  top: 25px;
  left: -22px;
  span {
    width: 100%;
    text-align: center;
    font-size: 0.9;
  }
}

.range-slider .range-slider-left {
  height: 5px;
  width: 0;
  @include themify {
    background: themed('range-slider-selected-color');
  }
  position: absolute;
  top: 10px;
  border-radius: 2.5px;
  z-index: 2;
}
@-moz-document url-prefix() {
  .range-slider .range-slider-track-background {
    display: block;
    height: 5px;
    width: 100%;
    @include themify {
      background: themed('range-slider-unselected-color');
    }
    position: absolute;
    border-radius: 2.5px;
    top: 10px;
    z-index: 0;
  }
  .range-slider .range-slider-left {
    height: 5px;
    width: 20px;
    @include themify {
      background: themed('range-slider-selected-color');
    }
    position: absolute;
    border-radius: 2.5px;
    top: 10px;
    z-index: 0;
  }
}

</style>
