import {
  ref, watch,
} from 'vue';

export default function usePCD() {
  const visualizationLocations = ref({});
  const layouts = {
    layout_single: [[null]],
    layout_2_columns: [[null, null]],
    layout_2x1: [[null, null], [null]],
    layout_2x2: [[null, null], [null, null]],
  };
  const layout = ref([
    ['canvas'],
  ]);

  function handleAddVisualization(visualization) {
    if (visualization) {
      const layoutHasVisualization = checkForNestedArrayValue(layout.value, visualization);
      if (!layoutHasVisualization) {
        const wasReplaced = replaceFirstNull(layout.value, visualization);
        if (!wasReplaced) {
          layout.value[0].push(visualization);
        }
      }
    }
  }

  function replaceFirstNull(arr, newValue) {
    for (let i = 0; i < arr.length; i++) {
      if (Array.isArray(arr[i])) {
        // If the current element is an array, recurse into it
        const result = replaceFirstNull(arr[i], newValue);
        if (result) return true; // Stop once we have replaced the first null
      } else if (arr[i] === null) {
        // If a null is found, replace it with the new value
        arr[i] = newValue;
        return true; // Return true to indicate a replacement was made
      }
    }
    return false; // Return false if no null was found
  }

  function handleVisualizationSelected(e) {
    if (e.visualization) {
      removeNestedArrayValue(layout.value, e.visualization);
      setNestedArrayValue(layout.value, e.location, e.visualization);
    }
  }

  function handleVisualizationContainerDrop(e) {
    if (e.draggedVisualization) {
      const currentLocation = visualizationLocations.value[e.draggedVisualization];
      const targetValue = getNestedArrayValue(layout.value, e.location);
      setNestedArrayValue(layout.value, currentLocation, targetValue);
      setNestedArrayValue(layout.value, e.location, e.draggedVisualization);
    }
  }

  function handleLayoutSelected(layoutSelected) {
    const newLayout = structuredClone(layouts[layoutSelected]);

    const existingVisualizations = layout.value.flatMap((row) => row).filter((value) => value !== null);

    let index = 0; // Keep track of the current index in the 1D array
    function traverseAndFill(arr) {
      for (let i = 0; i < arr.length; i++) {
        if (Array.isArray(arr[i])) {
        // If the current element is an array, recursively traverse it
          traverseAndFill(arr[i]);
        } else if (arr[i] === null && index < existingVisualizations.length) {
        // If the element is null, replace it with the next value from the 1D array
          arr[i] = existingVisualizations[index];
          index += 1;
        }
      }
    }
    traverseAndFill(newLayout);

    layout.value = newLayout;
  }

  function checkForNestedArrayValue(arr, value) {
    for (let i = 0; i < arr.length; i++) {
      if (Array.isArray(arr[i])) {
        // If the element is an array, recurse into it
        if (checkForNestedArrayValue(arr[i], value)) return true;
      } else if (arr[i] === value) {
        // If the specific value is found, return true
        return true;
      }
    }
    // Return false if the specific value is not found
    return false;
  }

  function getNestedArrayValue(layout, indices) {
    const indicesClone = JSON.parse(JSON.stringify(indices));
    // Navigate to the second-to-last index
    const lastIndex = indicesClone.pop(); // Remove the last index for assignment
    const targetArray = indicesClone.reduce((acc, currentIndex) => acc[currentIndex], layout);

    // Set the value at the specified index
    return targetArray[lastIndex];
  }

  function setNestedArrayValue(layout, indices, value) {
    const indicesClone = JSON.parse(JSON.stringify(indices));
    // Navigate to the second-to-last index
    const lastIndex = indicesClone.pop(); // Remove the last index for assignment
    const targetArray = indicesClone.reduce((acc, currentIndex) => acc[currentIndex], layout);

    // Set the value at the specified index
    targetArray[lastIndex] = value;
  }

  function removeNestedArrayValue(array, valueToRemove) {
    for (let i = 0; i < array.length; i++) {
      if (Array.isArray(array[i])) {
        // If the current element is an array, recurse into it
        removeNestedArrayValue(array[i], valueToRemove);
      } else if (array[i] === valueToRemove) {
        // If the element matches the value to remove, set it to null
        array[i] = null;
      }
    }
  }

  function mapNonNullValues(array) {
    const map = {};

    function traverse(arr, currentIndex = []) {
      for (let i = 0; i < arr.length; i++) {
        const newIndex = currentIndex.concat(i); // Build up the index for this element

        if (Array.isArray(arr[i])) {
          // If the current element is an array, recursively traverse it
          traverse(arr[i], newIndex);
        } else if (arr[i] !== null) {
          // If the element is not null, map the value to its index
          map[arr[i]] = newIndex;
        }
      }
    }

    traverse(array);
    return map;
  }

  function getLocationId(location) {
    return `#pane-${location.join('_')}`;
  }

  watch(layout, (newLayout) => {
    const result = mapNonNullValues(newLayout);
    visualizationLocations.value = result;
  }, { immediate: true, deep: true });

  return {
    handleAddVisualization,
    handleVisualizationSelected,
    handleVisualizationContainerDrop,
    getLocationId,
    handleLayoutSelected,
    visualizationLocations,
    layout,
  };
}
