<template>
  <!-- eslint-disable max-len -->
  <Popper
    ref="popper"
    :trigger="trigger"
    :config="menuPopperConfig"
    :virtualReferenceElement="virtualReferenceElement"
    :appendToBody="appendToBody"
    :ignoreClickOutside="ignoreClickOutside"
    v-bind="$attrs"
    @popper-open="handlePopperOpened"
    @popper-closed="handlePopperClosed"
  >
    <template #trigger>
      <slot :isActive="internalIsOpen" />
    </template>
    <div ref="menu" class="menu__container" :class="background">
      <slot name="menu" :closeMenu="closeMenu" />
    </div>
  </Popper>
</template>

<script>
import Popper from '@/components/Popper.vue';
import { detectOverflow } from '@popperjs/core';

const overflowPadding = 20;

export default {
  name: 'BaseMenu',
  components: {
    Popper,
  },
  props: {
    text: {
      type: String,
      default: '',
    },
    placement: {
      type: String,
      default: 'bottom',
    },
    flip: {
      type: Boolean,
      default: true,
    },
    virtualReferenceElement: {
      type: Object,
      default: null,
    },
    trigger: {
      type: String,
      default: 'click',
    },
    isOpen: {
      type: Boolean,
      default: false,
    },
    background: {
      type: String,
      default: '',
    },
    appendToBody: {
      type: Boolean,
      default: false,
    },
    offset: {
      type: Array,
      default: () => [0, 8],
    },
    ignoreClickOutside: {
      type: Array,
      default: () => [],
    },
  },
  emits: ['update:isOpen', 'opened', 'closed'],
  data() {
    return {
      internalIsOpen: false,
    };
  },
  computed: {
    detectOverflowModifier() {
      return {
        name: 'detectOverflowModifier',
        enabled: !this.flip,
        phase: 'main',
        requiresIfExists: ['offset'],
        fn({ state }) {
          const overflow = detectOverflow(state);
          if (state.placement === 'right') {
            state.styles.popper.maxWidth = `${state.elements.popper.offsetWidth - overflow.right - overflowPadding}px`;
          }
          if (state.placement === 'left') {
            state.styles.popper.maxWidth = `${state.elements.popper.offsetWidth - overflow.left - overflowPadding}px`;
          }
        },
      };
    },
    detectHeightOverflowModifier() {
      return {
        name: 'detectHeightOverflowModifier',
        enabled: true,
        phase: 'main',
        requiresIfExists: ['offset'],
        fn({ state }) {
          const overflow = detectOverflow(state);
          if (state.placement.startsWith('bottom')) {
            state.styles.popper.maxHeight = `${state.elements.popper.offsetHeight - overflow.bottom - overflowPadding}px`;
          } else if (state.placement.startsWith('left') || state.placement.startsWith('right')) {
            state.styles.popper.maxHeight = `${state.elements.popper.offsetHeight - overflow.bottom - overflow.top - (overflowPadding * 2)}px`;
          }
        },
      };
    },
    menuPopperConfig() {
      return {
        placement: this.placement,
        modifiers: [
          this.detectOverflowModifier,
          this.detectHeightOverflowModifier,
          {
            name: 'offset',
            options: {
              offset: this.offset,
            },
          },
          {
            name: 'preventOverflow',
            options: {
              padding: overflowPadding,
            },
          },
          {
            name: 'flip',
            enabled: this.flip,
          },
        ],
      };
    },
  },
  watch: {
    isOpen(value) {
      this.internalIsOpen = value;
    },
  },
  methods: {
    handlePopperOpened() {
      this.internalIsOpen = true;
      this.$emit('update:isOpen', this.internalIsOpen);
      this.$emit('opened');
    },
    handlePopperClosed() {
      this.internalIsOpen = false;
      this.$emit('update:isOpen', this.internalIsOpen);
      this.$emit('closed');
    },
    closeMenu() {
      if (this.$refs.popper) {
        this.$refs.popper.close();
      }
    },
  },
};
</script>

<style lang="scss" scoped>

.menu__container:deep {
  background: #ffffff;
  border: 1px solid #ffffff;
  color: #000000;
  box-shadow: 0 2px 5px 0 rgb(0 0 0 / 30%);
  border-radius: 8px;
  margin: 0;
  padding: 8px;
  z-index: 999;

  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }

  hr {
    margin: 4px 0;
  }
}

</style>
