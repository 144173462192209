<template>
  <v-rect
    ref="canvasSelectBox"
    :config="{
      fill: 'rgba(0,0,255,0.25)',
      strokeWidth: strokeWidth,
      visible: false
    }"
  />
</template>

<script>
import Konva from 'konva';
import { debounce } from '@/assets/js/utils.js';

export default {
  name: 'CanvasSelectBox',
  props: {
    stageScale: {
      type: Object,
      default: () => ({ x: 1, y: 1 }),
    },
    stageNode: {
      type: Object,
      default: () => {},
    },
  },
  emits: ['update:selectedObjects', 'canvas-box-dimensions-changed'],
  data() {
    return {
      canvasSelectBoxNode: null,
    };
  },
  computed: {
    boxDimensions() {
      if (this.canvasSelectBoxNode && this.canvasSelectBoxNode.getAttrs().visible) {
        return {
          x1: this.canvasSelectBoxNode.x(),
          y1: this.canvasSelectBoxNode.y(),
          x2: this.canvasSelectBoxNode.x() + this.canvasSelectBoxNode.width(),
          y2: this.canvasSelectBoxNode.y() + this.canvasSelectBoxNode.height(),
        };
      }
      return null;
    },
    debounceHandleBoxDimensionsChanged() {
      return debounce(() => { this.handleBoxDimensionsChanged(); }, 50);
    },
    strokeWidth() {
      return 2 / this.stageScale.x;
    },
  },
  watch: {
    boxDimensions() {
      this.debounceHandleBoxDimensionsChanged();
    },
  },
  mounted() {
    this.canvasSelectBoxNode = this.$refs.canvasSelectBox.getNode();
    this.setUpStageMouseEvents();
  },
  unmounted() {
    const stage = this.stageNode;
    stage.off('mousedown touchstart');
    stage.off('mousemove touchmove');
    stage.off('mouseup touchend');
  },
  methods: {
    handleBoxDimensionsChanged() {
      this.$emit('canvas-box-dimensions-changed', this.boxDimensions);
    },
    setUpStageMouseEvents() {
      const stage = this.stageNode;
      const selectionRectangle = this.canvasSelectBoxNode;
      let mousedown = false;
      let x1; let y1; let x2; let y2;

      stage.on('mousedown touchstart', (e) => {
        mousedown = true;
        // Do nothing if we mousedown on any shape
        if (this.editingEnabled && !this.isAnnotating && e.target !== stage && e.target.getClassName() !== 'Image') {
          return;
        }
        // Only do selection if left mouse button pressed with ctrl key not held
        if (e.evt.button === 0 && !e.evt.ctrlKey) {
          this.stageNode.setAttr('draggable', false);
        } else {
          return;
        }
        e.evt.preventDefault();
        x1 = stage.getRelativePointerPosition().x;
        y1 = stage.getRelativePointerPosition().y;
        x2 = stage.getRelativePointerPosition().x;
        y2 = stage.getRelativePointerPosition().y;

        selectionRectangle.x(x1);
        selectionRectangle.y(y1);
        const box = selectionRectangle.getClientRect();
        const shapes = stage.find((shape) => shape.getAttrs().identifier && Konva.Util.haveIntersection(box, shape.getClientRect()));

        if (!selectionRectangle.attrs.visible && shapes.length === 0) {
          selectionRectangle.visible(true);
          selectionRectangle.width(0);
          selectionRectangle.height(0);
          selectionRectangle.zIndex(selectionRectangle.getParent().children.length - 1);
        }
      });

      stage.on('mousemove touchmove', (e) => {
        if (mousedown) {
          this.isSelectionDragging = true;
          e.evt.preventDefault();
          x2 = stage.getRelativePointerPosition().x;
          y2 = stage.getRelativePointerPosition().y;
          if (selectionRectangle.attrs.visible) {
            selectionRectangle.setAttrs({
              x: Math.min(x1, x2),
              y: Math.min(y1, y2),
              width: Math.abs(x2 - x1),
              height: Math.abs(y2 - y1),
            });
          }
        }
      });

      stage.on('mouseup touchend', (e) => {
        e.evt.preventDefault();
        mousedown = false;

        // update visibility in timeout, so we can check it in click event
        setTimeout(() => {
          selectionRectangle.visible(false);
          selectionRectangle.x(0);
          selectionRectangle.y(0);
          selectionRectangle.width(0);
          selectionRectangle.height(0);
          selectionRectangle.zIndex(selectionRectangle.getParent().children.length - 1);
        });

        if (this.isSelectionDragging) {
          const box = selectionRectangle.getClientRect();
          if (selectionRectangle.attrs.visible) {
            const shapes = stage.find((shape) => shape.getAttrs().identifier && Konva.Util.haveIntersection(box, shape.getClientRect()));
            const selectedObjects = [];
            selectedObjects.push(...shapes.map((shape) => shape.getAttrs().identifier));
            this.$emit('update:selectedObjects', selectedObjects);
          }
        }

        this.isSelectionDragging = false;

        // Allow image to be dragged again now that selection is complete
        this.stageNode.setAttr('draggable', true);
      });
    },
    reset() {
      this.canvasSelectBoxNode.visible(false);
      this.canvasSelectBoxNode.width(0);
      this.canvasSelectBoxNode.height(0);
    },
  },
};
</script>

<style>

</style>
