import {
  ref, onBeforeUnmount, watch,
} from 'vue';

export default function useAnnotationEditorModalHotkeys(hotkeys) {
  const areListenersActive = ref(false);

  const attachEventListeners = () => {
    window.addEventListener('keydown', handleKeyDown, true);
    window.addEventListener('keyup', handleKeyUp);
  };

  const detachEventListeners = () => {
    window.removeEventListener('keydown', handleKeyDown, true);
    window.removeEventListener('keyup', handleKeyUp);
  };

  const setEventListenersActive = (isActive) => {
    areListenersActive.value = isActive;

    if (isActive) {
      attachEventListeners();
    } else {
      detachEventListeners();
    }
  };

  const activateListeners = () => {
    setEventListenersActive(true);
  };

  const deactivateListeners = () => {
    setEventListenersActive(false);
  };

  onBeforeUnmount(() => {
    window.removeEventListener('keydown', handleKeyDown, true);
    window.removeEventListener('keyup', handleKeyUp);
  });

  async function handleKeyDown(event) {
    switch (event.key) {
    case 'Enter':
      event.preventDefault();
      event.stopPropagation();
      document.querySelector('#annotation-editor-modal').focus();
      break;
    case ' ':
      event.preventDefault();
      event.stopPropagation();
      document.querySelector('#annotation-editor-modal').focus();
      break;
    case '=':
    case '-':
      event.preventDefault();
      event.stopPropagation();
      hotkeys.value[event.key]();
      break;
    default:
      break;
    }
  }

  async function handleKeyUp(event) {
    let key = event.key;
    if (event.ctrlKey || event.metaKey) {
      key = `ctrl,${key}`;
    }
    if (hotkeys.value[key] === '-' || hotkeys.value[key] === '=') {
      return;
    }
    if (hotkeys.value[key]) {
      hotkeys.value[key]();
    }
  }

  return {
    activateListeners,
    deactivateListeners,
    areListenersActive,
  };
}
