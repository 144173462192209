<template>
  <!-- eslint-disable max-len -->
  <button
    class="icon-button"
    :disabled="disabled"
    :class="{ 'active': active, [type]: type,
              'icon-button-hover': hoverStyle && showHover,
              'icon-button-active': showActive,
              'icon-button-disabled': disabled }"
    :style="btnStyles"
    @click.stop="handleButtonClick"
    @mousedown.prevent=""
  >
    <SVGIcon
      :width="'100%'"
      :height="'100%'"
      :iconName="currIcon"
      :iconColor="iconColor"
    />
  </button>
</template>

<script>
import SVGIcon from '@/components/SVGIcon.vue';

export default {
  name: 'IconButton',
  components: {
    SVGIcon,
  },
  props: {
    modelValue: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      default: '',
    },
    iconActive: {
      type: String,
      default: '',
    },
    iconColor: {
      type: String,
      default: 'currentColor',
    },
    type: {
      type: String,
      default: 'toggle',
    },
    width: {
      type: [Number, String],
      default: null,
    },
    height: {
      type: [Number, String],
      default: null,
    },
    showHover: {
      type: Boolean,
      default: true,
    },
    showActive: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    notifications: {
      type: Number,
      default: 0,
    },
    allowClick: {
      type: Boolean,
      default: true,
    },
  },
  emits: ['update:modelValue', 'change'],
  data() {
    return {
      active: false,
    };
  },
  computed: {
    hoverStyle() {
      return this.type !== 'tooltip-hover';
    },
    currIcon() {
      if (this.active && this.type === 'toggle-icon') {
        return this.iconActive;
      }
      return this.icon;
    },
    btnStyles() {
      let width = this.width;
      let height = this.height;
      if (typeof this.width === 'number') {
        width = `${width}px`;
      }
      if (typeof this.height === 'number') {
        height = `${height}px`;
      }
      return {
        width,
        height,
        'min-width': width,
        'min-height': height,
      };
    },
  },
  watch: {
    modelValue: {
      immediate: true,
      handler(val) {
        this.active = val;
        this.$emit('change', val);
      },
    },
    active() {
      this.$emit('update:modelValue', this.active);
    },
  },
  methods: {
    handleButtonClick() {
      if (this.allowClick) {
        if (this.type === 'toggle' || this.type === 'toggle-color' || this.type === 'toggle-icon') { this.active = !this.active; this.$emit('change', this.active); }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.icon-button {
  background-color: transparent;
  color: inherit;
  border: none;
  background-size: contain;
  padding: 0;
  vertical-align: middle;
  box-shadow: none;
  font-size: 12px;
  position: relative;
  cursor: pointer;
}

.icon-button.icon-button-hover:hover,
.icon-button.icon-button-hover:focus-visible {
  @include themify() {
    background-color: themed('icon-hover-color');
    border-radius: 100%;
    box-shadow: 0 0 0 4px themed('icon-hover-color');
  }
}

.icon-button.icon-button-active.active {
  @include themify() {
    color: themed('icon-color-primary');
    border-radius: 100%;
  }
}

.icon-button.icon-button-active.active:not(.toggle-icon) {
  @include themify() {
    background-color: themed('icon-hover-color');
    box-shadow: 0 0 0 4px themed('icon-hover-color');
  }
}

.icon-button.icon-button-disabled {
    color: $color-auzone-disabled !important;
    cursor:not-allowed;
}

</style>
