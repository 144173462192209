<template>
  <div class="settings">
    <span class="header">Organization Management</span>
    <button v-if="isSuper" class="button button-rounded create-button mt-3" @click="navigateToCreateOrganization">Create New Organization</button>
    <div class="panels scrollbar">
      <div class="panel panel-header">
        <div class="panel__title">
          <div class="panel__title-text">
            <div class="row">
              <div class="col-3">
                <span>Org Name</span>
              </div>
              <div class="col-3">
                <span>Org Id</span>
              </div>
              <div class="col-3">
                <span>Contact Name</span>
              </div>
              <div class="col-3">
                <span>Contact Email</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        v-for="(org, index) in allOrgs"
        :key="index"
        class="panel"
        @click="navigateToOrgDetails(org.id)"
      >
        <div class="panel__title">
          <div class="panel__title-text">
            <div class="row">
              <div class="col-3">
                <span>{{ org.name }}</span>
              </div>
              <div class="col-3">
                <span>{{ org.id }}</span>
              </div>
              <div class="col-3">
                <span>{{ org.contact_name }}</span>
              </div>
              <div class="col-3">
                <span>{{ org.contact_email }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DatastoreConnect from '@/assets/js/DatastoreFunctions/datastore-interface';

export default {
  name: 'OrganizationManagement',
  components: {
  },
  data() {
    return {
      dsConn: new DatastoreConnect(this.$store.state.enterpriseServerUrl),
      allOrgs: [],
      allRoles: [],
      confirmMessage: null,
      confirmMessageHeader: null,
      openedDropdowns: [],
      dollarCreditRatio: 0.001,
      selectMonth: '',
      selectYear: 0,
      message: '',
      error: false,
    };
  },
  computed: {
    headers() {
      return ['User', 'E-mail', 'Role'];
    },
    user() {
      return this.$store.state.user.user;
    },
    isAdmin() {
      return this.user && (this.user.role === 'admin');
    },
    isSuper() {
      return this.user && (this.user.role === 'super');
    },
    months() {
      return [
        'January', 'February', 'March', 'April', 'May', 'June',
        'July', 'August', 'September', 'October', 'November', 'December',
      ];
    },
    years() {
      const currentYear = new Date().getFullYear();
      const startingYear = 2023;
      const years = [];
      for (let year = startingYear; year <= currentYear; year++) {
        years.push(year);
      }

      return years;
    },
  },
  created() {
    if (!this.isAdmin && !this.isSuper) {
      this.$router.push('/login');
    }
    this.getAllOrgs();
    this.getAllRoles();
  },
  methods: {
    async getAllOrgs() {
      await this.dsConn.getOrgList()
        .then((data) => {
          this.allOrgs = data.result;
        })
        .catch((e) => console.log(e));
    },
    async getAllRoles() {
      await this.dsConn.getAllRoles()
        .then((data) => {
          this.allRoles = data.result;
        })
        .catch((e) => console.log(e));
    },
    async getUsers(id) {
      const resp = await this.dsConn.getUserListByOrganization({ organization_id: id })
        .catch((error) => {
          console.log('Failed to retrieve training sessions:', error);
        });
      if (!resp || resp.error || !resp.result) {
        return [];
      }

      const users = resp.result.reverse();
      return users;
    },
    navigateToCreateOrganization() {
      this.$router.push({ name: 'super.neworg' });
    },
    navigateToOrgDetails(id) {
      this.$router.push({ name: 'super.orgdetail', query: { org: id } });
    },
  },
};
</script>

<style lang="scss" scoped>
.settings {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  padding: 30px;
  overflow-y: auto;
}

.header-text{
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  margin-left: 20px;

  span {
    width:fit-content;
    font-weight: 600;
    font-size: 1.0rem;
  }
}

.create-button {
    width: fit-content;
  }

.panel-header {
}

.panels {
  background: var(--color-white-100);
  padding: 10px;
  border-radius: 10px;
  margin: 10px 0;
  overflow-y: auto;
}

.panel {
  padding: 0 !important;

  &__title-text {
    display: flex;
    width: 100%;
    height: 100%;
    margin: 0 10px 0 0;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .row {
      width: 100%;
    }

  }

  &__title {
    height: 30px;
    min-height: 0;
    font-weight: 600;
    border-radius: 0;
  }

}

.panel:not(.panel-header):hover {
  cursor: pointer;
  background: var(--color-white-600);
}

.panel + .panel {
  border-top: 0.5px solid grey;
}
</style>
