<template>
  <div class="project-row">
    <IconButton
      class="project-row__icon"
      :icon="icon"
      :width="32"
      :height="32"
      :title="tooltip"
      :type="''"
      @click="handleClickEvent(tooltip)"
    />

    <div class="stack">
      <div class="stack__value-row">
        <span>{{ val }}</span>
        <label>{{ tooltip }}</label>
      </div>
      <progress
        :value="!isNaN(val/max) ? (val*100/max) : 0"
        :max="100"
      />
    </div>
  </div>
</template>

<script>
import IconButton from '../IconButton.vue';

export default {
  components: {
    IconButton,
  },
  props: {
    icon: {
      type: String,
      default: "database",
    },
    tooltip: {
      type: String,
      default: "Database",
    },

    val: {
      type: Number,
      default: 0,
    },
    max: {
      type: Number,
      default: 1,
    },

  },

  emits: {
    'delete-project': null, 'clicked': null, 'edit-project': null,
  },
  methods: {
    handleClickEvent(name) {
      this.$emit('clicked', name);
    },

  },
};
</script>

<style lang="scss" scoped>

.project-row {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  width:100%;
  height: 38px;
  padding: 0px;
  margin-top: 8px;
  font-size: small;

  &__icon {
    color: var(--color-primary);
  }
}

.stack{
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  width:100%;
  height: 38px;
  padding-left: 8px;
  padding-right: 8px;
  margin: 0px;

  &__value-row {
    display: flex;
    flex-direction: row;
    gap: 6px;
    align-items: baseline;

    span {
      font-size: 1rem;
      font-weight: 700;
    }

    label {
      font-size: 0.875rem;
      font-weight: 500;
      color: var(--body-text-color-secondary);
    }
  }
}

.icon-container {
  display: flex;
  align-items: center;
  button + button {
    margin: 4px;
  }
}

progress {
  border-radius: 10px;
  width: 100%;
  height: 8px;
  padding: 1px;
}

progress::-webkit-progress-value {
  background-color: var(--progress-value);
  border-radius: 10px;
}

progress::-moz-progress-bar {
  background-color: var(--progress-bar);
}

progress::-webkit-progress-bar {
  background-color: var(--progress-bar);
}
  </style>
