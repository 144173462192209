<template>
  <!-- eslint-disable max-len -->
  <BaseCard
    :id="`project-${project.name}`"
    ref="card"
    class="card project-card"
    :class="{ 'project-selected': selected }"
    :title="title"
    @edit-mode="(mode) => editTitle = mode"
    @apply-edit="handleApplyEdit"
    @click="handleClickEvent"
  >
    <template #header>
      <BaseMenu
        v-if="(permissions && permissions.project_write) || (user.role === 'admin' || user.role === 'super')"
        :placement="'bottom-start'"
        @closed="show_menu = false"
      >
        <IconButton
          v-model="show_menu"
          class="refresh-icon"
          :icon="'more_vert'"
          :width="22"
          :height="22"
        />
        <template #menu="{closeMenu}">
          <ul>
            <li>
              <BaseMenuButton @click.stop="handleApplyEdit(), closeMenu()">
                <SVGIcon
                  :iconName="'edit'"
                  :width="'22px'"
                  :height="'22px'"
                />
                <span>Edit</span>
              </BaseMenuButton>
            </li>
            <li>
              <BaseMenuButton
                @click.stop="handleManageAccess(), closeMenu()"
              >
                <SVGIcon
                  :iconName="'people'"
                  :width="'22px'"
                  :height="'22px'"
                />
                <span>Manage Access</span>
              </BaseMenuButton>
            </li>
            <li>
              <BaseMenuButton class="_danger" @click.stop="deleteProject(), closeMenu()">
                <SVGIcon
                  :iconName="'trash'"
                  :width="'22px'"
                  :height="'22px'"
                />
                <span>Remove Project</span>
              </BaseMenuButton>
            </li>
          </ul>
        </template>
      </BaseMenu>
    </template>
    <template #body>
      <BaseTextExpander v-if="project.description" :text="project.description" class="mt-2" />
      <div class="project-rows">
        <div class="bar" :style="barstyle" />
        <ProjectRow
          icon="database"
          tooltip="Datasets"
          :val="project.no_of_datasets"
          :max="project.total_datasets"
          @clicked="moveToHandlerPage"
        />
        <ProjectRow
          icon="train"
          tooltip="Trainers"
          :val="project.no_of_trainers"
          :max="project.total_trainers"
          @clicked="moveToHandlerPage"
        />
        <ProjectRow
          icon="auto_annotate"
          tooltip="Automations"
          :val="project.no_of_automations"
          :max="project.total_automations"
          @clicked="moveToHandlerPage"
        />
        <ProjectRow
          icon="tag"
          tooltip="Label Tasks"
          :val="project.no_of_label_tasks"
          :max="project.total_label_tasks"
          @clicked="moveToHandlerPage"
        />
        <ProjectRow
          icon="compare"
          tooltip="Validations"
          :val="project.no_of_validations"
          :max="project.total_validations"
          @clicked="moveToHandlerPage"
        />
        <div class="bar" :style="barstyle" />
      </div>
    </template>
  </BaseCard>
</template>
<script>
import BaseCard from '@/components/BaseCard.vue';
import BaseTextExpander from '@/components/BaseTextExpander.vue';
import ProjectRow from '@/components/ProjectsComponent/ProjectEntryRow.vue';
import BaseMenu from '@/components/BaseMenu.vue';
import BaseMenuButton from '@/components/BaseMenuButton.vue';
import DatastoreConnect from '@/assets/js/DatastoreFunctions/datastore-interface';
import IconButton from '@/components/IconButton.vue';
import SVGIcon from '@/components/SVGIcon.vue';

export default {
  components: {
    IconButton,
    BaseCard,
    BaseTextExpander,
    ProjectRow,
    BaseMenu,
    BaseMenuButton,
    SVGIcon,
  },
  props: {
    project: {
      type: Object,
      default: () => {},
    },
    selected: {
      type: Boolean,
      default: false,
    },
    refresh: {
      type: Boolean,
      default: false,
    },
  },
  emits: {
    'delete-project': null, 'clicked': null, 'edit-project': null, 'open-project-access-management': null,
  },
  data() {
    return {
      dataConnect: new DatastoreConnect(this.$store.state.enterpriseServerUrl),
      editTitle: false,
      datasets: [23, 44],
      permissions: null,
    };
  },
  computed: {
    user() {
      return this.$store.state.user.user;
    },
    barstyle() {
      const aa = `background: ${this.stringToColour(this.project.name)};`;
      return aa;
    },
    isDebugMode() {
      return this.$store.state.debugMode;
    },
    title() {
      return this.isDebugMode ? `${this.project.name} (${this.project.id})` : `${this.project.name}`;
    },
  },
  created() {
    this.GetPermissionsForCurrentUser();
  },
  methods: {
    async GetPermissionsForCurrentUser() {
      const user = this.$store.state.user.user;
      await this.dataConnect.getProjectAccessForSingleUser({
        project_id: this.project.id,
        username: user.username,
      })
        .then((data) => {
          if (data.result) {
            this.permissions = data.result;
          }
        })
        .catch((e) => console.log(e));
    },
    stringToColour(str) {
      let hash = 0;
      str.split('').forEach((char) => {
        hash = char.charCodeAt(0) + ((hash << 5) - hash);
      });
      let colour = '#';
      for (let i = 0; i < 3; i++) {
        const value = (hash >> (i * 8)) & 0xff;
        colour += value.toString(16).padStart(2, '0');
      }
      return colour;
    },
    deleteProject() {
      this.$emit('delete-project');
    },
    handleClickEvent() {
      this.$emit('clicked');
    },
    moveToHandlerPage(name) {
      this.handleClickEvent();
      if (name.toLowerCase() === "datasets") this.$router.push({ path: '/datasets' });
      if (name.toLowerCase() === "trainers") this.$router.push({ path: '/trainer' });
      if (name.toLowerCase() === "automations") this.$router.push({ path: '/automation' });
      if (name.toLowerCase() === "label tasks") this.$router.push({ path: '/taskboard' });
      if (name.toLowerCase() === "validations") this.$router.push({ path: '/validator' });
    },
    handleApplyEdit() {
      this.$emit('edit-project');
    },
    handleManageAccess() {
      this.$emit('open-project-access-management');
    },
  },
};
</script>

<style lang="scss" scoped>

.bar {
  width: 100%;
  height: 3px;
  margin-top: 10px;
  margin-bottom: 10px;
  background: red;
}
.project-selected {
  @include themify() {
    outline: 3px solid themed('card-list-color-selected-border')
  }
}

.icon-container {
  display: flex;
  align-items: center;
  button + button {
    margin-left: 10px;
  }
}

.details {
  margin-top: auto;
  &__row {
    display: flex;
    flex-direction: row;
    margin-top: 8px;
  }
  label {
    width: fit-content;
    font-weight: 600;
    margin-right: 8px;
  }
  span {
    flex: 1 1 auto;
  }
}

.delete-project-icon,
.apply-edit-icon,
.edit-data-icon,
.database-icon {
  visibility: hidden;
}

.card:hover .database-icon,
.card:hover .apply-edit-icon,
.card:hover .edit-data-icon,
.card:hover .delete-project-icon {
  visibility: visible;
  @include themify() {
    color: themed('icon-color-inactive');
  }
}

.card:hover .database-icon:hover,
.card:hover .apply-edit-icon:hover,
.card:hover .edit-data-icon:hover {
  @include themify() {
    color: themed('icon-color-primary');
  }
}

.card:hover .delete-project-icon:hover {
  @include themify() {
    color: themed('color-red');
  }
}

.project-card {
  min-width: 300px;
  max-width: 500px;
  height: 100%;
}

.project-rows {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  justify-content: space-between;
}

</style>
