<template>
  <div class="stats-graph">
    <div class="header">
      <span class="title">{{ name }}</span>
      <div class="selector">
        <LabelSelector
          :key="'location'"
          v-model="labelLocation"
          :name="'location'"
          :labels="labelList"
          :restore="restore"
        />
      </div>
    </div>
    <apexchart
      ref="locMap"
      height="100%"
      type="heatmap"
      :options="chartOptions"
      :series="series"
    />
  </div>
</template>

<script>
import VueApexCharts from "vue3-apexcharts";
import LabelSelector from '@/components/DatasetComponent/DatasetStats/LabelSelector.vue';

export default {
  name: 'LabelLocationMap',
  components: {
    'apexchart': VueApexCharts,
    LabelSelector,
  },
  props: {
    name: {
      type: String,
      default: '',
    },
    dataset: {
      type: Object,
      default: () => {},
    },
    dataArray: {
      type: Array,
      default: () => [],
    },
    labelList: {
      type: Array,
      default: () => [],
    },
  },
  emits: ['new-location-label'],
  data() {
    return {
      chartOptions: {
        plotOptions: {
          heatmap: {
            distributed: false,
            enableShades: true,
            useFillColorAsStroke: true,
            radius: 0,
          },
        },
        colors: ["#01246c"],
        chart: {
          toolbar: {
            show: false,
          },
        },
        dataLabels: {
          enabled: false,
        },
        xaxis: {
          tickAmount: 10,
          labels: {
            show: true,
            rotate: -45,
          },
          axisTicks: {
            show: true,
            borderType: 'solid',
          },
          categories: [
            '0 - 0.05',
            '0.05 - 0.10',
            '0.10 - 0.15',
            '0.15 - 0.20',
            '0.20 - 0.25',
            '0.25 - 0.30',
            '0.30 - 0.35',
            '0.35 - 0.40',
            '0.40 - 0.45',
            '0.45 - 0.50',
            '0.50 - 0.55',
            '0.55 - 0.60',
            '0.60 - 0.65',
            '0.65 - 0.70',
            '0.70 - 0.75',
            '0.75 - 0.80',
            '0.80 - 0.85',
            '0.85 - 0.90',
            '0.90 - 0.95',
            '0.95 - 1.0'],
        },
        yaxis: {
          tickAmount: 10,
          axisTicks: {
            show: false,
          },
          labels: {
            show: true,
            formatter(val, i) {
              if (i % 2 === 0) {
                return val;
              } else {
                return '';
              }
            },
            offsetX: -5,
          },
        },
        tooltip: {
          enabled: true,
          y: {
            formatter(value) {
              return value;
            },
          },
        },
      },
      series: [],
      labels: [
        '0 - 0.05',
        '0.05 - 0.10',
        '0.10 - 0.15',
        '0.15 - 0.20',
        '0.20 - 0.25',
        '0.25 - 0.30',
        '0.30 - 0.35',
        '0.35 - 0.40',
        '0.40 - 0.45',
        '0.45 - 0.50',
        '0.50 - 0.55',
        '0.55 - 0.60',
        '0.60 - 0.65',
        '0.65 - 0.70',
        '0.70 - 0.75',
        '0.75 - 0.80',
        '0.80 - 0.85',
        '0.85 - 0.90',
        '0.90 - 0.95',
        '0.95 - 1.0'],
      componentIsActive: false,
      labelLocation: null,
      restore: null,
    };
  },
  watch: {
    dataArray: {
      handler() {
        if (this.dataArray && this.dataArray.length > 0) {
          this.updateMap();
        }
      },
    },
    labelLocation: {
      handler(l) {
        this.$emit('new-location-label', l);
      },
    },
  },
  mounted() {
    if (this.dataArray && this.dataArray.length > 0) {
      this.updateMap();
    }
    const restoreDS = this.$store.state.statistics.datasetID;
    if (restoreDS && restoreDS === this.dataset.id) {
      const restoreLabel = this.$store.state.statistics.labelLocation;
      if (restoreLabel) {
        this.restore = restoreLabel;
      } else {
        if (this.labelList.length > 0) {
          this.restore = this.labelList[0];
        }
      }
    } else {
      if (this.labelList.length > 0) {
        this.restore = this.labelList[0];
      }
    }
  },
  methods: {
    updateMap() {
      const localSeries = [];
      const localData = [...this.dataArray].reverse();
      localData.forEach((datum, i) => {
        const obj = {
          name: this.labels[i],
          data: datum,
        };
        localSeries.push(obj);
      });
      this.series = localSeries;
    },
  },
};

</script>

<style scoped lang="scss">
.stats-graph {
  border-radius: 8px;
  width: 100%;
  height: 100%;
  box-shadow: 0 0 10px rgba(0,0,0,0.1), 0 0 5px rgba(0,0,0,0.25);
  padding-top: 10px;
  padding-bottom: 10px;
  @include themify() {
    background: themed('card-color');
    color: themed('datasets-stats-text-color');
  }
}

.header {
  display: flex;
  align-items: center;
  margin-left: 15px;
  margin-right: 15px;

  .title {
    font-size: 1.3rem;
    font-weight: 700;
    min-width: 200px;
  }

  .selector {
    margin-left: auto;
    display: flex;
    align-items: center;
  }
}
</style>
