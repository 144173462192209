<template>
  <div class="controls-bar">
    <div class="trainer-header">
      <button v-if="permissions && (permissions.trainer_read || permissions.trainer_write)" class="button button-secondary button-sm" @click="handleProjectAnalyticsClick">
        Full Project Analytics
      </button>
      <button v-if="permissions && (permissions.trainer_read || permissions.trainer_write)" class="button button-secondary button-sm" @click="handleCompareTrainedModelsOpen">
        Compare Experiments
      </button>
    </div>
    <button v-if="permissions && permissions.trainer_write" class="button button-sm" @click="openCreateModal">Create</button>
  </div>
  <div v-if="!loadingFinished" class="empty-state">
    <InlineLoader
      :width="'60px'"
      :height="'60px'"
      :border="'12px'"
    />
  </div>
  <div v-else-if="notEmpty && permissions && (permissions.trainer_read || permissions.trainer_write)" class="trainers-card-container scrollbar">
    <TrainerCard
      v-for="(t, i) in trainers"
      :key="i"
      v-model:trainingSessions="t.training_sessions"
      :trainer="t"
      :currentProject="currentProject"
      :permissions="permissions"
      @create-trainer-session="openTrainerStartModal(t)"
      @info-clicked="handleSessionInfoClick($event, t)"
      @analytics-clicked="handleSessionAnalyticsClick(t)"
      @delete-session="openConfirmDeleteSessionModal"
      @terminate-session="openConfirmStopTrainingSessionModal"
      @delete-trainer="openConfirmDeleteTrainerModal(t)"
      @edit-trainer="openEditModal(t)"
    />
  </div>
  <div
    v-else
    class="empty-state"
  >
    <svg width="60%" height="60%">
      <use href="@/assets/img/empty.svg#emptyStateSVG" />
    </svg>
    <div class="empty-state__message">
      <h3>No trainer to display.</h3>
    </div>
  </div>
  <CreateTrainerModal
    ref="createEditTrainerModal"
    :project="currentProject"
    @trainer-created="handleTrainerCreated"
  />
  <TrainerUpdateModal
    ref="trainerUpdateModal"
    :modalTrainer="modalTrainer"
    @edit-trainer="handleUpdateTrainer"
  />
  <CreateTrainerSessionModal
    ref="startTrainerSessionModal"
    :trainer="trainerToCreateSession"
    @create-trainer-session="handleTrainingSessionCreated(trainerToCreateSession)"
  />
  <TrainingSessionDetailsModal
    ref="trainingSessionDetailsModal"
    :session="selectedTrainingSession"
    :permissions="permissions"
    @stop-training="openConfirmStopTrainingSessionModal"
    @delete-session="openConfirmDeleteSessionModal"
  />
  <TrainingSessionAnalyticsModal
    ref="trainingSessionAnalyticsModal"
    :trainerParam="trainerToShowAnalytics"
    :projectID="currentProject ? currentProject.id : 0"
  />
  <TrainerCompareTrainedModelsModal
    ref="trainerCompareTrainedModelsModal"
    :trainers="trainers"
    @launch-trained-model-compare="launchTrainedModelCompare"
  />
  <ConfirmModal
    ref="confirmModal"
    :messageHeader="confirmMessageHeader"
    :message="confirmMessage"
    :buttonClass="'button-delete'"
    :buttonText="buttonText"
    @confirmed="confirmFunction"
  />
</template>

<script>
import DatastoreConnect from '@/assets/js/DatastoreFunctions/datastore-interface';
import ConfirmModal from '@/components/ConfirmModal.vue';
import TrainerCard from '@/components/TrainerComponent/TrainerExperimentAndSessions/TrainerCard.vue';
import CreateTrainerSessionModal from '@/components/TrainerComponent/TrainerExperimentAndSessions/CreateTrainerSessionModal.vue';
import TrainingSessionDetailsModal from '@/components/TrainerComponent/TrainerExperimentAndSessions/TrainingSessionDetailsModal.vue';
import TrainingSessionAnalyticsModal from '@/components/TrainerComponent/TrainerExperimentAndSessions/TrainingSessionAnalyticsModal.vue';
import TrainerCompareTrainedModelsModal from '@/components/TrainerComponent/TrainerExperimentAndSessions/TrainerCompareTrainedModelsModal.vue';
import TrainerUpdateModal from '@/components/TrainerComponent/TrainerExperimentAndSessions/TrainerUpdateModal.vue';
import CreateTrainerModal from '@/components/TrainerComponent/TrainerExperimentAndSessions/CreateTrainerModal.vue';
import InlineLoader from '@/components/InlineLoader.vue';

export default {
  name: "TrainerManagement",
  components: {
    TrainerCard,
    CreateTrainerModal,
    CreateTrainerSessionModal,
    TrainingSessionDetailsModal,
    TrainingSessionAnalyticsModal,
    TrainerCompareTrainedModelsModal,
    ConfirmModal,
    TrainerUpdateModal,
    InlineLoader,
  },
  props: {
  },
  emits: [
    'trainer-set-created', 'update-list',
  ],
  data() {
    return {
      dataConnect: null,
      trainers: [],
      trainingSessionList: [],
      trainerToCreateSession: null,
      trainerToShowAnalytics: null,
      loadingFinished: true,
      selectedTrainingSession: null,
      selectedTrainerDetails: null,
      interval: null,
      confirmMessage: null,
      confirmMessageHeader: null,
      toDelete: null,
      confirmFunction: null,
      buttonText: 'Delete',
      ec2Interval: null,
      modalTrainer: null,
      permissions: null,
    };
  },
  computed: {
    currentProject() {
      return this.$store.state.projects.currentProject;
    },
    notEmpty() {
      return this.trainers && this.trainers.length > 0;
    },
    trainerIds() {
      return this.trainers.reduce((acc, curr) => {
        acc.push(curr.id);
        return acc;
      }, []);
    },
  },
  watch: {
    trainers: {
      deep: false,
      async handler(trainers) {
        await Promise.all(trainers.map(async (trainer) => {
          trainer.training_sessions = await this.getTrainingSessionList(trainer.id);
        }));
      },
    },
    async currentProject(p) {
      if (p) {
        this.trainers = await this.getTrainerList();
      }
    },
  },
  async created() {
    this.dataConnect = new DatastoreConnect(this.$store.state.enterpriseServerUrl);
    this.trainers = await this.getTrainerList();
  },
  beforeUnmount() {
    this.stopInterval();
    this.stopEC2StatusInterval();
  },
  mounted() {
    this.startInterval();
    this.startEC2StatusInterval();
    this.getProjectAccessForSingleUser();
  },
  methods: {
    async getProjectAccessForSingleUser() {
      await this.dataConnect.getProjectAccessForSingleUser({
        project_id: this.currentProject.id,
        username: this.$store.state.user.user.username,
      })
        .then((data) => {
          if (data.result) {
            this.permissions = data.result;
          }
        })
        .catch((e) => console.log(e));
    },
    openConfirmDeleteTrainerModal(t) {
      this.confirmMessage = `Are you sure you want to remove experiment "${t.name}" to recycle bin? This will terminate all training sessions in progress.`;
      this.confirmMessageHeader = 'Remove Trainer Experiment';
      this.toDelete = t;
      this.buttonText = 'Remove';
      this.$refs.confirmModal.showModal();
      this.confirmFunction = () => this.handleTrainerDelete();
    },
    openConfirmStopTrainingSessionModal(session) {
      this.confirmMessage = `Are you sure you want to stop training session "${session.name}"?\n You will need to restart training to resume.`;
      this.confirmMessageHeader = 'Stop Training Session';
      this.toDelete = session;
      this.buttonText = 'Stop';
      this.$refs.confirmModal.showModal();
      this.confirmFunction = () => {
        this.$refs.trainingSessionDetailsModal.closeModal();
        this.handleStopTrainingSessions([session.id]);
      };
    },
    openConfirmDeleteSessionModal(session) {
      this.confirmMessage = `Are you sure you want to move training session "${session.name}" to recycle bin?`;
      this.confirmMessageHeader = 'Remove Training Session';
      this.toDelete = session;
      this.buttonText = 'Remove';
      this.$refs.confirmModal.showModal();
      this.confirmFunction = () => {
        this.$refs.trainingSessionDetailsModal.closeModal();
        this.handleDeleteSession(session.id);
      };
    },
    handlePauseClicked() {
    },
    startEC2StatusInterval() {
      this.ec2Interval = setInterval(this.updateEC2InstanceStatus, 60000);
    },
    stopEC2StatusInterval() {
      clearInterval(this.ec2Interval);
      this.ec2Interval = null;
    },
    handleRefreshSession(session) {
      this.trainers.forEach(async (trainer) => {
        if (trainer.id === session.trainer_id) {
          trainer.training_sessions = await this.getTrainingSessionList(trainer.id);
        }
      });
    },
    startInterval() {
      this.interval = setInterval(() => {
        this.trainers.forEach(async (trainer) => {
          trainer.training_sessions = await this.getTrainingSessionList(trainer.id);
        });
      }, 30000);
    },
    stopInterval() {
      clearInterval(this.interval);
      this.interval = null;
    },
    async updateEC2InstanceStatus() {
      await this.dataConnect.updateEC2Status({
        trainer_ids: this.trainerIds,
      })
        .catch((e) => console.log(e));
    },
    async handleTrainerCreated() {
      this.trainers = await this.getTrainerList();
    },
    async getTrainerList() {
      this.loadingFinished = false;
      const resp = await this.dataConnect.getTrainerList2({ project_id: this.currentProject.id })
        .catch((error) => {
          console.log('Failed to retrieve trainers:', error);
        });
      if (!resp || resp.error || !resp.result) {
        this.loadingFinished = true;
        return [];
      }
      this.loadingFinished = true;
      return resp.result;
    },
    async getTrainingSessionList(trainer_id) {
      const resp = await this.dataConnect.getTrainingSessionList({ trainer_id, reverse: true })
        .catch((error) => {
          console.log('Failed to retrieve training sessions:', error);
        });
      if (!resp || resp.error || !resp.result) {
        return [];
      }

      if (resp.result) {
        resp.result.forEach((session) => {
          if (this.selectedTrainingSession && session.id === this.selectedTrainingSession.id) {
            this.selectedTrainingSession = session;
          }
        });
      }
      return resp.result;
    },
    openCreateModal() {
      this.$refs.createEditTrainerModal.showModal();
    },
    openEditModal(t) {
      this.modalTrainer = t;
      this.$refs.trainerUpdateModal.showModal();
    },
    openTrainerStartModal(trainer) {
      this.trainerToCreateSession = trainer;
      this.$refs.startTrainerSessionModal.showModal();
    },
    async handleTrainingSessionCreated(trainer) {
      trainer.training_sessions = await this.getTrainingSessionList(trainer.id);
    },
    async handleSessionInfoClick(session) {
      this.selectedTrainingSession = session;
      this.$refs.trainingSessionDetailsModal.showModal();
    },
    async handleSessionAnalyticsClick(trainer) {
      this.trainerToShowAnalytics = { trainer_id: trainer.id };
      await this.$nextTick();
      this.$refs.trainingSessionAnalyticsModal.showModal();
    },
    async launchTrainedModelCompare(trainer_ids) {
      this.trainerToShowAnalytics = { trainer_ids };
      await this.$nextTick();
      this.$refs.trainingSessionAnalyticsModal.showModal();
    },
    async handleProjectAnalyticsClick() {
      const trainer_ids = this.trainers.map((e) => e.id);
      this.trainerToShowAnalytics = { trainer_ids };
      await this.$nextTick();
      this.$refs.trainingSessionAnalyticsModal.showModal();
    },
    async handleCompareTrainedModelsOpen() {
      this.$refs.trainerCompareTrainedModelsModal.showModal();
    },
    async handleTrainerDelete() {
      await this.dataConnect.deleteTrainer({
        trainer_id: this.toDelete.id,
      })
        .then((data) => {
          if (data.error) {
            alert(data.error);
          }
        })
        .catch((error) => {
          console.log(error);
        });

      this.trainers = await this.getTrainerList();
      this.toDelete = null;
    },
    async handleStopTrainingSessions(ids) {
      await this.dataConnect.terminateTrainingSessions({
        session_ids: ids,
      })
        .then(() => {
          this.updateEC2InstanceStatus();
        })
        .catch((error) => {
          console.log(error);
        });

      this.toDelete = null;
    },
    async handleDeleteSession(id) {
      await this.dataConnect.deleteTrainingSession({
        session_id: id,
      })
        .then(async () => {
          this.trainers = await this.getTrainerList();
        })
        .catch((error) => {
          console.log(error);
        });

      this.toDelete = null;
    },
    async handleUpdateTrainer(params) {
      await this.dataConnect.updateTrainer(params)
        .then(async (data) => {
          if (data.result) {
            this.trainers = await this.getTrainerList();
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>

<style lang="scss" scoped>

.controls-bar {
  display: flex;
  flex-direction: row;
  padding: 10px 20px 10px 20px;
  align-items: center;
  border-bottom: 1px solid #c9c9c9;
  @include themify() {
    background: themed('color-ribbon');
  }
  justify-content: space-between;
}
.trainers-card-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(750px, 1fr));
  grid-auto-rows: auto;
  width: 100%;
  gap: 16px;
  padding: 16px;
  overflow: auto;
}

.trainer-header {
  display: flex;
  flex-direction: row;
  gap: 16px;
}

.empty-state {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  height: 100%;
}
</style>
