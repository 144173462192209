<template>
  <BaseMenu
    :placement="'bottom'"
    :offset="[0,0]"
    :appendToBody="true"
  >
    <button class="module-button">
      <SVGIcon
        :height="'32px'"
        :width="'32px'"
        :iconName="'apps'"
        :showHover="false"
        :showActive="true"
      />
    </button>
    <template #menu="{closeMenu}">
      <div class="module-menu">
        <ul>
          <li>
            <BaseMenuButton
              @click="Project(), closeMenu()"
            >
              <div class="li-row">
                <SVGIcon
                  :width="'28px'"
                  :height="'28px'"
                  :iconName="'folder2'"
                />
                <div class="li-text">
                  <div class="li-text__title">Projects</div>
                  <div class="li-text__description">Manage projects</div>
                </div>
              </div>
            </BaseMenuButton>
          </li>
          <li>
            <BaseMenuButton
              @click="Dataset(), closeMenu()"
            >
              <div class="li-row">
                <SVGIcon
                  :width="'28px'"
                  :height="'28px'"
                  :iconName="'database2'"
                />
                <div class="li-text">
                  <div class="li-text__title">Datasets</div>
                  <div class="li-text__description">Curate, upload, and share datasets</div>
                </div>
              </div>
            </BaseMenuButton>
          </li>
          <li>
            <BaseMenuButton
              @click="Trainer(), closeMenu()"
            >
              <div class="li-row">
                <SVGIcon
                  :width="'28px'"
                  :height="'28px'"
                  :iconName="'train'"
                />
                <div class="li-text">
                  <div class="li-text__title">Trainer</div>
                  <div class="li-text__description">Train models</div>
                </div>
              </div>
            </BaseMenuButton>
          </li>
          <li>
            <BaseMenuButton
              @click="Validator(), closeMenu()"
            >
              <div class="li-row">
                <SVGIcon
                  :width="'28px'"
                  :height="'28px'"
                  :iconName="'compare'"
                />
                <div class="li-text">
                  <div class="li-text__title">Validator</div>
                  <div class="li-text__description">Compare model outputs</div>
                </div>
              </div>
            </BaseMenuButton>
          </li>
          <li>
            <BaseMenuButton
              @click="Automation(), closeMenu()"
            >
              <div class="li-row">
                <SVGIcon
                  :width="'28px'"
                  :height="'28px'"
                  :iconName="'auto_annotate'"
                />
                <div class="li-text">
                  <div class="li-text__title">Automation</div>
                  <div class="li-text__description">AI assisted annotation & segmentation</div>
                </div>
              </div>
            </BaseMenuButton>
          </li>
          <li>
            <BaseMenuButton
              @click="Tasks(), closeMenu()"
            >
              <div class="li-row">
                <SVGIcon
                  :width="'28px'"
                  :height="'28px'"
                  :iconName="'kanban'"
                />
                <div class="li-text">
                  <div class="li-text__title">Task Board</div>
                  <div class="li-text__description">Manage labelling, audit, and more</div>
                </div>
              </div>
            </BaseMenuButton>
          </li>
        </ul>
        <hr>
        <ul>
          <li>
            <BaseMenuButton
              @click="RecycleBin(), closeMenu()"
            >
              <div class="li-row">
                <SVGIcon
                  :width="'28px'"
                  :height="'28px'"
                  :iconName="'trash'"
                />
                <div class="li-text">
                  <div class="li-text__title">Recycle Bin</div>
                  <div class="li-text__description">Manage removed items</div>
                </div>
              </div>
            </BaseMenuButton>
          </li>
        </ul>
      </div>
    </template>
  </BaseMenu>
</template>

<script>
import BaseMenu from '@/components/BaseMenu.vue';
import SVGIcon from '@/components/SVGIcon.vue';
import BaseMenuButton from '@/components/BaseMenuButton.vue';

export default {
  name: 'ModuleMenuV2',
  components: {
    SVGIcon,
    BaseMenu,
    BaseMenuButton,
  },
  computed: {
    isSaas() {
      return this.$store.state.appVersion.isSaas;
    },
    currentProject() {
      return this.$store.state.projects.currentProject;
    },
  },
  methods: {
    goto(route) {
      this.$router.push({ path: route });
    },
    Trainer() {
      if (this.currentProject) {
        this.goto('/trainer');
      }
    },
    Project() {
      this.goto('/projects');
    },
    Dataset() {
      if (this.currentProject) {
        this.goto('/datasets');
      }
    },
    Automation() {
      this.goto('/automation');
    },
    Validator() {
      this.goto('/validator');
    },
    Tasks() {
      this.goto('/taskboard');
    },
    RecycleBin() {
      this.goto('/recycle');
    },
  },
};
</script>

<style lang="scss" scoped>

.module-button {
  display: flex;
  flex: 0 0 auto;
  height: 100%;
  align-items: center;
  justify-content: center;
  padding: 0 4px;
  background: none;
  border: none;
  cursor: pointer;
  @include themify() {
    color: themed('header-dot-menu');
  }

  &:hover {
    @include themify() {
      background: themed('header-button-background-hover');
      color: themed('header-button-color-hover');
    }
  }

  &._active {
    @include themify() {
      background: themed('header-button-background-hover');
      color: themed('header-button-color-hover');
    }
  }
}

.li-row {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.li-text {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;

  &__title{
    display: flex;
    font-size: 1rem;
    font-weight: 600;
    margin-left: 12px;
  }

  &__description{
    display: flex;
    font-size: 0.8rem;
    font-weight: 600;
    margin-left: 12px;
    margin-top: 5px;
    text-align: left;
    @include themify() {
      color: rgba(61, 60, 60, 0.5);
    }
  }
}
.module-menu {
  width: 290px;
}
</style>
