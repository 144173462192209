<template>
  <div ref="gridContainer" class="grid-container scrollbar">
    <div ref="gridHeader" class="grid-header" />
    <div class="grid-content">
      <div class="timeline-labels">
        <span v-for="d in data" :key="d.id">{{ d.id }}</span>
      </div>
      <div ref="timeline" class="timeline" />
    </div>
  </div>
</template>

<script setup>
import * as d3 from 'd3';
import {
  ref, toRefs, computed, onMounted, watch, nextTick,
} from 'vue';

const props = defineProps({
  data: {
    type: Object,
    default: null,
  },
  frame: {
    type: Number,
    default: 0,
  },
  frameCount: {
    type: Number,
    default: 0,
  },
});
const {
  data,
  frame,
  frameCount,
} = toRefs(props);

const emit = defineEmits(['update:frame', 'update:data']);

const margin = {
  top: 0, right: 20, bottom: 5, left: 0,
};
const labelMargin = 100;
const gridContainer = ref(null);
const gridHeader = ref(null);
const timeline = ref(null);
const scrubberPosition = ref(1);
const scrubberHeight = ref(10);
const lineCoordinates = ref({
  x1: 0, y1: 0, x2: 0, y2: 0,
});

const rowHeight = 30;
const frameWidth = 20;
const height = computed(() => Math.max((data.value.length * rowHeight) - margin.top - margin.bottom, 0));
const width = computed(() => Math.max((frameWidth * frameCount.value) - margin.left - margin.right, 0));

let svg = null;
let svgGroup = null;
let xAxis = null;
let yAxis = null;
let svgHeader = null;
let svgHeaderGroup = null;
let xAxisHeader = null;
let line = null;
const scrubber = ref(null);
let xScale = null;
let yScale = null;

const keyframes = computed(() => data.value.flatMap((d, i) => Object.entries(d.keyframes).map(([frame, properties]) => ({
  rowIndex: i, id: d.id, frame: parseInt(frame), properties,
}))));

const keyframeRanges = computed(() => {
  const ranges = [];
  data.value.forEach((keyframeObject, keyframeObjectIndex) => {
    const keys = Object.keys(keyframeObject.keyframes).map((key) => parseInt(key)).sort((a, b) => a - b);
    keys.forEach((key, i) => {
      if (!keyframeObject.keyframes[key].end) {
        if (keys[i + 1]) {
          ranges.push({ y: keyframeObjectIndex, x1: keys[i], x2: keys[i + 1] });
        } else {
          ranges.push({ y: keyframeObjectIndex, x1: keys[i], x2: frameCount.value });
        }
      }
    });
  });
  return ranges;
});

watch(data, () => {
  nextTick(() => {
    updateChart();
  });
}, { deep: true });

watch(frame, () => {
  scrubberPosition.value = frame.value;
  moveScrubber();
});

watch(scrubberPosition, () => {
  emit('update:frame', scrubberPosition.value);
});

watch(frameCount, () => {
  updateChart();
});

function scrollToFrame(frame) {
  gridContainer.value.scrollTo({ left: (frame - 2) * frameWidth, behavior: 'smooth' });
}

function createGrid() {
  svg = d3.select(timeline.value)
    .append("svg")
    .attr("width", width.value + margin.left + margin.right)
    .attr("height", height.value + margin.top + margin.bottom)
    .on('click', handleTimelineClick);

  svgGroup = svg
    .append("g")
    .attr("transform", `translate(${margin.left},${margin.top})`);

  xScale = d3.scaleLinear()
    .domain([1, frameCount.value])
    .range([0, width.value]);

  yScale = d3.scaleLinear()
    .domain([0, data.value.length])
    .range([0, height.value]);

  xAxis = d3.axisTop(xScale)
    .ticks(frameCount.value)
    .tickSize(height.value)
    .tickFormat("");

  yAxis = d3.axisLeft(yScale)
    .ticks(data.value.length)
    .tickSize(-width.value)
    .tickFormat("");

  svgGroup.append("g")
    .attr('id', 'x-axis')
    .attr("class", "axis")
    .attr("transform", `translate(0,${height.value})`)
    .call(xAxis);

  svgGroup.append("g")
    .attr('id', 'y-axis')
    .attr("class", "axis")
    .call(yAxis);

  // Add line element
  lineCoordinates.value.x1 = xScale(scrubberPosition.value);
  lineCoordinates.value.x2 = xScale(scrubberPosition.value);
  lineCoordinates.value.y2 = height.value;
  line = svgGroup.append("line")
    .attr("class", "scrubber-line")
    .attr("stroke-width", 2)
    .attr("x1", lineCoordinates.value.x1)
    .attr("y1", lineCoordinates.value.y1)
    .attr("x2", lineCoordinates.value.x2)
    .attr("y2", lineCoordinates.value.y2);

  // Add keyframe range lines element
  svgGroup
    .selectAll(".keyframe-range")
    .data(keyframeRanges.value)
    .enter()
    .append("line")
    .attr("class", "keyframe-range")
    .attr("x1", (d) => xScale(d.x1))
    .attr("y1", (d) => yScale(d.y + 0.5))
    .attr("x2", (d) => xScale(d.x2))
    .attr("y2", (d) => yScale(d.y + 0.5));

  // Add circles for data points
  svgGroup
    .selectAll(".circle")
    .data(keyframes.value)
    .enter()
    .append("circle")
    .attr("class", "circle")
    .attr("cx", (d) => xScale(d.frame))
    .attr("cy", (d) => yScale(d.rowIndex + 0.5))
    .attr("r", 5)
    .classed("_end", (d) => d.properties.end)
    .on('click', handleKeyframeNodeClick);

  createHeader();
}

function createHeader() {
  svgHeader = d3.select(gridHeader.value)
    .append("svg")
    .attr("width", width.value + labelMargin + margin.left + margin.right)
    .attr("height", 32)
    .on('click', handleTimelineClick);

  svgHeaderGroup = svgHeader
    .append("g")
    .attr("transform", `translate(${labelMargin},0)`);

  xAxisHeader = d3.axisTop(xScale)
    .ticks(frameCount.value)
    .tickSize(10)
    .tickFormat((d) => (d % 5 === 0 ? d : ""));

  svgHeaderGroup.append("g")
    .attr('id', 'header')
    .attr("class", "axis")
    .attr("transform", `translate(0,${30})`)
    .call(xAxisHeader);

  // Add scrubber handle (as a square)
  scrubber.value = svgHeaderGroup.append("rect")
    .attr("class", "scrubber")
    .attr("x", xScale(scrubberPosition.value) - (scrubberHeight.value / 2))
    .attr("y", 32 - scrubberHeight.value)
    .attr("width", scrubberHeight.value)
    .attr("height", scrubberHeight.value)
    .call(d3.drag()
      .on("drag", (event) => {
        const newX = Math.max(0, Math.min(width.value, event.x));
        scrubberPosition.value = snapToTick(xScale.invert(newX));
        moveScrubber();
      }));
}

function moveScrubber() {
  scrubber.value.attr("x", xScale(scrubberPosition.value) - (scrubberHeight.value / 2));
  updateLineCoordinates(xScale(scrubberPosition.value));
}

function updateChart() {
  updateHeader();
  updateAxes();
  updateScrubber();
  updateKeyframes();
}

function updateHeader() {
  xScale.domain([1, frameCount.value]).range([0, width.value]);
  svgHeaderGroup.selectAll('#header').call(xAxisHeader.ticks(frameCount.value).tickSize(10));
  svgHeader.attr("width", width.value + labelMargin + margin.left + margin.right);
}

function updateAxes() {
  yScale.domain([0, data.value.length]).range([0, height.value]);
  xScale.domain([1, frameCount.value]).range([0, width.value]);
  svg.selectAll('#y-axis').call(yAxis.ticks(data.value.length).tickSize(-width.value));
  svg.selectAll('#x-axis').attr("transform", `translate(0,${height.value})`).call(xAxis.ticks(frameCount.value).tickSize(height.value));
  svg.attr("height", height.value);
  svg.attr("width", width.value);
}

function updateScrubber() {
  lineCoordinates.value.y2 = height.value;

  line
    .attr("x1", lineCoordinates.value.x1)
    .attr("y1", lineCoordinates.value.y1)
    .attr("x2", lineCoordinates.value.x2)
    .attr("y2", lineCoordinates.value.y2);
}

function updateKeyframes() {
  svgGroup
    .selectAll(".keyframe-range")
    .data(keyframeRanges.value)
    .join(
      (enter) => enter.selectAll(".keyframe-range")
        .data(keyframeRanges.value)
        .enter()
        .append("line")
        .attr("class", "keyframe-range")
        .attr("x1", (d) => xScale(d.x1))
        .attr("y1", (d) => yScale(d.y + 0.5))
        .attr("x2", (d) => xScale(d.x2))
        .attr("y2", (d) => yScale(d.y + 0.5))
        .lower(),
      (update) => update
        .attr("x1", (d) => xScale(d.x1))
        .attr("y1", (d) => yScale(d.y + 0.5))
        .attr("x2", (d) => xScale(d.x2))
        .attr("y2", (d) => yScale(d.y + 0.5)),
      (exit) => exit.remove(),
    );

  svgGroup
    .selectAll(".circle")
    .data(keyframes.value)
    .join(
      (enter) => enter.selectAll(".circle")
        .data(keyframes.value)
        .enter()
        .append("circle")
        .attr("class", "circle")
        .attr("cx", (d) => xScale(d.frame))
        .attr("cy", (d) => yScale(d.rowIndex + 0.5))
        .attr("r", 5)
        .classed("_end", (d) => d.properties?.end)
        .on('click', handleKeyframeNodeClick)
        .raise(),
      (update) => update
        .attr("cx", (d) => xScale(d.frame))
        .attr("cy", (d) => yScale(d.rowIndex + 0.5))
        .classed("_end", (d) => d.properties?.end),
      (exit) => exit.remove(),
    );
}

function updateLineCoordinates(newX) {
  const {
    x1, y1, x2, y2,
  } = lineCoordinates.value;
  lineCoordinates.value = {
    x1: newX, y1, x2: newX, y2,
  };
  line
    .attr("x1", lineCoordinates.value.x1)
    .attr("y1", lineCoordinates.value.y1)
    .attr("x2", lineCoordinates.value.x2)
    .attr("y2", lineCoordinates.value.y2);
}

function snapToTick(value) {
  const nearestTick = Math.round(value);
  return nearestTick;
}

function handleKeyframeNodeClick(event, d) {
  const tempData = JSON.parse(JSON.stringify(data.value));
  tempData[d.rowIndex].keyframes[d.frame].end = !tempData[d.rowIndex].keyframes[d.frame].end;
  emit('update:data', tempData);
}

function handleTimelineClick(event) {
  const [x] = d3.pointer(event, svg.node()); // Get the x-coordinate of the click relative to the SVG element
  const clickedFrame = xScale.invert(x); // Convert the x-coordinate to the corresponding frame value
  scrubberPosition.value = snapToTick(clickedFrame);
  moveScrubber();
}

onMounted(() => {
  scrubberPosition.value = frame.value;
  createGrid();

  scrollToFrame(frame.value);
});
</script>

<style>
.grid-container {
  max-height: 250px;
  overflow-x: auto;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
}

.grid-header {
  position: sticky;
  top: 0px;
  display: flex;
  align-items: flex-start;
  height: 30px;
  width: fit-content;
  background: white;
  z-index: 2;
}

.grid-content {
  display: flex;
  flex-direction: row;
  background: var(--color-white-700);
}

.timeline {
  display: flex;
  justify-content: flex-start;
  flex: 1 1 auto;
}

.timeline-labels {
  display: flex;
  flex-direction: column;
  min-width: 100px;
  max-width: 100px;
  position: sticky;
  left: 0;
  background: white;

  & span {
    height: 30px;
    overflow: hidden;
    text-overflow: ellipsis;
    text-wrap: nowrap;
  }
}

.axis line {
  stroke: rgba(0, 0, 0, 0.125);
  stroke-opacity: 0.7;
  shape-rendering: crispEdges;
}

.scrubber {
  cursor: pointer;
  fill: var(--color-primary);
}

.scrubber-line {
  stroke: var(--color-primary);
}

.line {
  pointer-events: none;
}

.circle {
  fill: var(--color-accent);
  stroke: var(--color-primary);
  stroke-width: 1;
  cursor: pointer;
}

.circle._end {
  fill: transparent;
  stroke: var(--color-accent);
  stroke-width: 2;
}

.keyframe-range {
  stroke: var(--color-accent);
  stroke-opacity: 0.625;
  stroke-width: 3;
}

</style>
