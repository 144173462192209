<template>
  <div class="dataset-and-destination-card">
    <div class="dataset-and-destination-card__text">
      <span>Source Dataset</span>
      <div class="dataset-and-destination-card__text-value">
        <SVGIcon
          :iconName="'database'"
          :width="'18px'"
          :height="'18px'"
        />
        <span>{{ sourceDataset.name }}</span>
      </div>
    </div>
    <div v-if="sourceAnnotationSet" class="dataset-and-destination-card__text">
      <span>Source Annotation Set</span>
      <div class="dataset-and-destination-card__text-value">
        <SVGIcon
          :iconName="'set'"
          :width="'18px'"
          :height="'18px'"
        />
        <span>{{ sourceAnnotationSet }}</span>
      </div>
    </div>
    <div class="dataset-and-destination-card__text">
      <span>Destination Annotation Set</span>
      <div class="dataset-and-destination-card__text-value">
        <SVGIcon
          :iconName="'set'"
          :width="'18px'"
          :height="'18px'"
        />
        <span>{{ destinationAnnotationSet }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import SVGIcon from '@/components/SVGIcon.vue';

export default {
  name: 'TaskDatasetAndDestinationCard',
  components: {
    SVGIcon,
  },
  props: {
    reviewSettings: {
      type: Object,
      default: null,
    },
  },
  computed: {
    datasets() {
      return this.$store.state.datasets.datasetList;
    },
    sourceDataset() {
      const dataset_id = this.reviewSettings?.reviewTask?.dataset_id;
      const dataset = this.datasets.find((d) => d.id === dataset_id);
      return dataset;
    },
    annotationSets() {
      return this.sourceDataset.annotation_sets;
    },
    sourceAnnotationSet() {
      const source_annotation_set_id = this.reviewSettings?.reviewTask?.source_annotation_set_id;
      const sourceAnnotationSet = this.annotationSets.find((as) => as.id === source_annotation_set_id);
      return sourceAnnotationSet?.name;
    },
    destinationAnnotationSet() {
      const dest_annotation_set_id = this.reviewSettings?.reviewTask?.dest_annotation_set_id;
      const destAnnotationSet = this.annotationSets.find((as) => as.id === dest_annotation_set_id);
      return destAnnotationSet.name;
    },
  },
};
</script>

<style lang="scss" scoped>
.dataset-and-destination-card {
  display: flex;
  flex-direction: row;
  width: fit-content;
  border-radius: 8px;
  background: var(--color-primary-400);

  &__text {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 2px 8px;
    font-size: 0.75rem;
    font-weight: 600;
    color: var(--primary-text-color-secondary);
    align-items: flex-start;
    white-space: nowrap;
  }

  &__text-value {
    display: flex;
    flex-direction: row;
    font-size: 0.75rem;
    font-weight: 600;
    color: var(--primary-text-color);
    white-space: nowrap;
    gap: 4px;
  }
}
</style>
