<template>
  <!-- eslint-disable max-len -->
  <div ref="card" class="card">
    <div class="card__header">
      <div v-if="title" class="card__titles">
        <slot name="title-start" />
        <span class="card__title" :title="title">{{ title }}</span>
        <span v-if="subtitle" class="card__subtitle">{{ subtitle }}</span>
      </div>
      <slot name="header" />
    </div>
    <div class="card__body">
      <slot name="body" />
    </div>
  </div>
</template>

<script>

export default {
  name: 'BaseCard',
  components: {
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    subtitle: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      localTitle: '',
    };
  },
  watch: {
    title: {
      immediate: true,
      handler() {
        this.localTitle = this.title;
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.card {
  display: flex;
  flex-direction: column;
  position: relative;
  height: fit-content;
  padding: 16px;
  margin: 0;
  text-align: left;
  border-radius: 8px;
  box-shadow: 1px 1px 4px 1px rgba(0, 0, 0, 0.2), 0 0 1px 1px rgba(0, 0, 0, 0.05);

  @include themify() {
    background: themed('card-color');
    color: themed('body-text-color');
  }

  &__header {
    display: flex;
    flex-direction: row;
    align-items: center;
    max-width: 100%;
  }

  &__titles {
    display: flex;
    flex-direction: row;
    flex: 1 1 auto;
    overflow: hidden;
    width: 100%;
    line-height: normal;
    align-items: center;

    input {
      border: 1px solid rgb(43, 43, 43);
      border-radius: 5px;
    }

    input:focus {
      border: 2px solid #250E81;
    }
  }

  &__title {
    font-size: 1.25rem;
    font-weight: 700;
    @include themify() {
      color: themed('body-text-color');
    }
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  &__subtitle {
    font-size: 0.875rem;
    font-weight: 600;
    margin-top: 2px;
    @include themify() {
      color: themed('body-text-color-secondary');
    }
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  &__body {
    display: flex;
    flex-direction: column;
    width: 100%;
    flex: 1 1 auto;
    min-height: 0;
  }
}

</style>
