<template>
  <Modal
    ref="startValidatorModal"
    :title="'Validator Settings'"
    width="800px"
    @closed="resetData"
  >
    <!-- <template #modal-header /> -->
    <template #modal-main>
      <div class="settings container scrollbar">
        <div class="row mt-2">
          <div class="col-6 settings__control-group">
            <label class="required">Model Type</label>
            <select v-model="selectedType" class="select">
              <option disabled :value="null">Select Validation Type</option>
              <option value="detection">Detection</option>
              <option value="segmentation">Segmentation</option>
            </select>
          </div>
          <div class="col-6 settings__control-group">
            <label class="required">Dataset</label>
            <select v-model="internalDataset" class="select" required>
              <option :key="'select-null'" disabled :value="{}">Select a Dataset</option>
              <option v-for="d in datasets" :key="`select-${d.id}`" :value="d">
                {{ d.name }}
              </option>
            </select>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-6 settings__control-group">
            <label class="required">Ground Truth</label>
            <div class="ann-set-flex">
              <ComparisonTaskGroundTruth v-model="gt" :annotationSets="internalDataset?.annotation_sets" />
            </div>
          </div>
          <div class="col-6 settings__control-group">
            <label class="required">Target</label>
            <div class="ann-set-flex">
              <ChooseValidationTarget v-model="target" :annotationSets="internalDataset?.annotation_sets" />
            </div>
          </div>
        </div>

        <template v-if="selectedType === 'detection'">
          <div class="row mt-3">
            <div class="col-6 settings__control-group">
              <div class="label-title-with-button">
                <label>Confidence Threshold</label>
                <IconButton
                  class="refresh-icon"
                  :icon="'refresh'"
                  :width="20"
                  :height="20"
                  :title="`Refresh Confidence value`"
                  :type="''"
                  @click.stop="() => confidence = 0.25"
                />
              </div>
              <div class="slider scrollbar">
                <SingleSlider
                  v-model:sliderValue="confidence"
                  :step="0.01"
                  :min="0"
                  :max="1"
                />
                <span>{{ confidence }}</span>
              </div>
            </div>
            <div class="col-6 settings__control-group">
              <div class="label-title-with-button">
                <label>IoU Threshold</label>
                <IconButton
                  class="refresh-icon"
                  :icon="'refresh'"
                  :width="20"
                  :height="20"
                  :title="`Refresh IoU value`"
                  :type="''"
                  @click.stop="() => iou = 0.5"
                />
              </div>
              <div class="slider scrollbar">
                <SingleSlider
                  v-model:sliderValue="iou"
                  :step="0.05"
                  :min="0"
                  :max="1"
                />
                <span>{{ iou }}</span>
              </div>
            </div>
          </div>
          <div class="row mt-3">
            <div class="col-6 settings__control-group">
              <label>Metric</label>
              <select v-model="metric" class="select">
                <option value="iou">Intersection over Union (IoU)</option>
                <option value="centerpoint">Centerpoint</option>
              </select>
            </div>
            <div class="col-6 settings__control-group">
              <label>Ignore Detections Smaller than</label>
              <div class="ignore-boxes-settings scrollbar">
                <input
                  v-model="ignoreBoxes"
                  class="select"
                  type="number"
                  step="0.01"
                  @change="changeSanitizer"
                  @input="inputSanitizer"
                >
                <span>px</span>
              </div>
            </div>
          </div>
        </template>
        <div v-if="message" class="row mt-3">
          <div class="col-12 settings__control-group">
            <div class="result error">
              <span>{{ message }}</span>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template #modal-footer>
      <div class="footer">
        <button
          class="button modal-action-button"
          :class="{'button-spinner': startingValidator}"
          :disabled="startingValidator"
          @click="start"
        >
          Validate
        </button>
      </div>
    </template>
  </Modal>
</template>

<script>
import DatastoreConnect from '@/assets/js/DatastoreFunctions/datastore-interface';
import Modal from '@/components/Modal.vue';
import ComparisonTaskGroundTruth from '@/components/ValidationPage/ComparisonTaskGroundTruth.vue';
import ChooseValidationTarget from '@/components/ValidationPage/ChooseValidationTarget';
import SingleSlider from '@/components/SingleSlider.vue';
import IconButton from '@/components/IconButton.vue';
import { inputSanitizer, changeSanitizer } from '@/assets/js/utils';

export default {
  name: 'StartValidatorModal',
  components: {
    Modal,
    ComparisonTaskGroundTruth,
    ChooseValidationTarget,
    SingleSlider,
    IconButton,
  },
  props: {
    selectedDataset: {
      type: Object,
      default: () => {},
    },
    selectedTarget: {
      type: Object,
      default: () => {},
    },
    datasets: {
      type: Array,
      default: () => [],
    },
  },
  emits: ['validate-session-started', 'closed'],
  data() {
    return {
      dsConn: new DatastoreConnect(this.$store.state.enterpriseServerUrl),
      selectedType: null,
      gt: null,
      target: null,
      confidence: 0.25,
      iou: 0.5,
      metric: 'iou',
      ignoreBoxes: 0,
      message: null,
      startingValidator: false,
      internalDataset: {},
    };
  },
  methods: {
    inputSanitizer,
    changeSanitizer,
    showModal() {
      if (this.selectedDataset) {
        this.internalDataset = this.selectedDataset;
      }
      if (this.selectedTarget) {
        this.target = this.selectedTarget.id;
      }
      this.$refs.startValidatorModal.showModal();
    },
    closeModal() {
      this.resetData();
      this.$refs.startValidatorModal.closeModal();
    },
    resetData() {
      this.selectedType = null;
      this.startingValidator = false;
      this.internalDataset = {};
      this.gt = null;
      this.target = null;
      this.confidence = 0.25;
      this.iou = 0.5;
      this.metric = 'iou';
      this.ignoreBoxes = 0;
      this.message = "";
      this.$emit('closed');
    },
    async start() {
      if (!this.selectedType) {
        this.message = `Missing Validation Type`;
        return;
      }
      if (!this.internalDataset) {
        this.message = `Missing Dataset`;
        return;
      }
      if (!this.gt) {
        this.message = `Missing Ground Truth`;
        return;
      }
      if (!this.target) {
        this.message = `Missing Target`;
        return;
      }
      const validateObj = {
        gt_id: this.gt,
        target_id: this.target,
        params: {
          type: this.selectedType,
          iou: this.iou,
          confidence: this.confidence,
          metric: this.metric,
          ignoreBoxes: this.ignoreBoxes,
        },
      };
      this.startingValidator = true;
      await this.dsConn.validate(validateObj)
        .then((data) => {
          if (data.result) {
            this.$emit('validate-session-started', data.result);
            this.closeModal();
          }
        })
        .catch((e) => console.log(e));
      this.startingValidator = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.settings {
  padding: 0.75rem 3rem;
  min-width: 420px;
}

.footer {
  display: flex;
  flex-direction: row;
  padding: 0.75rem 3rem;
  justify-content: flex-end;
}

.ann-set-flex{
  display: flex;
  width: 100%;
}

.label-title-with-button {
  display: flex;
  width: 100%;
  flex-direction: row;

  label {
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 2px;
    white-space: nowrap;
    text-align: left;
    @include themify() {
      color: themed('form-label-color');
    }
  }

  .refresh-icon {
    visibility: visible;
    margin-left: 6px;
    @include themify() {
      color: themed('icon-color-inactive');
    }
  }

  .refresh-icon:hover {
    @include themify() {
      color: themed('icon-color-primary');
    }
  }

}
.ignore-boxes-settings {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;

  &__row {
    display: flex;
    align-items: center;
  }

  & span {
    margin-left: 10px;
    font-size: 0.9rem;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }
}

.slider {
  display: flex;
  flex-direction: row;
  padding: 12px;
  border-radius: inherit;
  gap: 6px;
  width: 90%;
  min-height: 50px;
  justify-content: center;
  align-items: center;
  overflow: auto;

  &__row {
    display: flex;
    align-items: center;
  }

  & span {
    width: 40px;
    font-size: 1.0rem;
  }
}

.result {
  display: flex;
  width: 100%;
  height: 100%;
  border-radius: 5px;
  font-size: 1rem;
  font-weight:600;
  padding: 10px;
  align-items: center;
  justify-content: center;

  span {
    width: 100%;
    line-break: anywhere;
  }
}

.error {
  @include themify() {
    background: rgba(182, 94, 94, 0.5) ;
    color: themed('color-error')
  }
}
</style>
