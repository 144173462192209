<template>
  <!-- eslint-disable max-len -->
  <Popper
    ref="launchTasksPopper"
    v-model:show="localShow"
    :trigger="trigger"
    :appendToBody="appendToBody"
    :config="popperConfig"
    :teleport="teleport"
  >
    <template #trigger>
      <slot :menuOpen="localShow" />
    </template>
    <div ref="popper" class="popper">
      <div class="filter-card">
        <slot name="popper" />
      </div>
    </div>
  </Popper>
</template>

<script>
import Popper from '@/components/Popper.vue';
import { detectOverflow } from '@popperjs/core';

const overflowPadding = 20;

export default {
  name: 'FilterPopper',
  components: {
    Popper,
  },
  inject: {
    teleport: {
      default: null,
    },
  },
  props: {
    placement: {
      type: String,
      default: 'bottom-start',
    },
    flip: {
      type: Boolean,
      default: true,
    },
    offset: {
      type: Array,
      default: () => [-4, 4],
    },
    trigger: {
      type: String,
      default: 'click',
    },
    appendToBody: {
      type: Boolean,
      default: false,
    },
    show: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:show'],
  data() {
    return {
      localShow: false,
    };
  },
  computed: {
    detectOverflowModifier() {
      return {
        name: 'detectOverflowModifier',
        enabled: !this.flip,
        phase: 'main',
        requiresIfExists: ['offset'],
        fn({ state }) {
          const overflow = detectOverflow(state);
          if (state.placement === 'right') {
            state.styles.popper.maxWidth = `${state.elements.popper.offsetWidth - overflow.right - overflowPadding}px`;
          }
          if (state.placement === 'left') {
            state.styles.popper.maxWidth = `${state.elements.popper.offsetWidth - overflow.left - overflowPadding}px`;
          }
        },
      };
    },
    detectHeightOverflowModifier() {
      return {
        name: 'detectHeightOverflowModifier',
        enabled: true,
        phase: 'main',
        requiresIfExists: ['offset'],
        fn({ state }) {
          const overflow = detectOverflow(state);
          if (state.placement.startsWith('bottom')) {
            state.styles.popper.maxHeight = `${state.elements.popper.offsetHeight - overflow.bottom - overflowPadding}px`;
          } else if (state.placement.startsWith('left') || state.placement.startsWith('right')) {
            state.styles.popper.maxHeight = `${state.elements.popper.offsetHeight - overflow.bottom - overflow.top - (overflowPadding * 2)}px`;
          }
        },
      };
    },
    popperConfig() {
      return {
        placement: this.placement,
        modifiers: [
          this.detectOverflowModifier,
          this.detectHeightOverflowModifier,
          {
            name: 'offset',
            options: {
              offset: this.offset,
            },
          },
          {
            name: 'preventOverflow',
            options: {
              padding: overflowPadding,
            },
          },
          {
            name: 'flip',
            enabled: this.flip,
          },
        ],
      };
    },
  },
  watch: {
    localShow(show) {
      this.$emit('update:show', show);
    },
  },
};
</script>

<style lang="scss" scoped>

.popper {
  padding: 4px;
  color: #000000;
  margin: 0;
  z-index: 999;
}

.filter-card {
  display: flex;
  flex-direction: column;
  height: 100%;
  background: #ffffff;
  box-shadow: 0 1px 4px 0 rgb(0 0 0 / 20%);
  border-radius: 8px;
  @include themify() {
    outline: 1px solid themed('popper-border-light');
  }
}

</style>
