import pLimit from 'p-limit';
import { fetchRetry, getFileContents } from '@/assets/js/utils';
import exifr from 'exifr';
import path from 'path-browserify';
import DatastoreConnect from '@/assets/js/DatastoreFunctions/datastore-interface';
import darknet from './darknet';
import modelpack from './modelpack';
import maivin from './maivin';

const annotationImporters = {
  darknet,
  modelpack,
  maivin,
};

async function parseLabelsTxt(file) {
  const labels = [];
  const fileContents = await getFileContents(file);
  fileContents.split('\n').forEach((line) => {
    // Check that line is valid
    if (line !== '') {
      labels.push(line);
    }
  });
  return labels;
}

async function parseAnnotationFile(importer, file, labels) {
  let annotations;
  let meta = {};
  if (file.type === 'text/plain') {
    if (!importer.parseAnnotationTextFile) {
      throw Error("Can't parse txt");
    }
    annotations = await importer.parseAnnotationTextFile(file);
  } else if (file.type === 'application/json') {
    if (!importer.parseAnnotationJsonFile) {
      throw Error("Can't parse JSON");
    }
    ({ annotations, meta } = await importer.parseAnnotationJsonFile(file, labels));
  }
  return { annotations, meta };
}

const actions = {
  async importAnnotationsDarknet({ commit, dispatch }, { files, importParams }) {
    const dataConnect = new DatastoreConnect('', { delay: 500, tries: 5, backoff_factor: 1 });
    const annotationFileTypes = ['application/json', 'text/plain'];

    // Match image files with annotation files
    const importFileGroups = {};
    let labelsFromFile = [];
    files.forEach(async (file) => {
      if (file.name === 'labels.txt') {
        labelsFromFile = parseLabelsTxt(file);
        return;
      }
      const basename = path.basename(file.name, path.extname(file.name));
      const fileGroup = importFileGroups[basename];
      if (!fileGroup) { importFileGroups[basename] = {}; }

      if (annotationFileTypes.includes(file.type)) {
        importFileGroups[basename].annotationFile = file;
      }
    });
    const numAnnotations = Object.values(importFileGroups).filter((group) => group.annotationFile).length;

    // Add upload task to store and database
    let dbTask;
    if (importParams.task) {
      // Resume from existing docker task
      const updatedTask = await dataConnect.updateDockerTaskStatus({
        docker_task_id: importParams.task.id,
        status: 'running',
      })
        .then((resp) => {
          if (resp.result) {
            return resp.result;
          }
          throw Error("Failed to update import task in database to status 'running'");
        })
        .catch((error) => {
          console.error(error);
        });
      dbTask = updatedTask;
      commit('tasks/resumeUploadTask', dbTask, { root: true });
    } else {
      // Create new docker task
      dbTask = await dataConnect.addDockerTask({
        name: `Upload To ${importParams.dataset_name}`,
        project_id: importParams.project_id,
        docker_id: '',
        type: 'upload',
        status: 'running',
        total: numAnnotations,
        data: {
          dataset_id: importParams.dataset_id,
          annotation_set_id: importParams.annotation_set_id,
          s3_path: importParams.importer_source,
          import_type: importParams.import_type,
        },
      })
        .then((resp) => {
          if (resp.result) {
            return resp.result;
          }
          throw Error("Failed to create import task in database");
        })
        .catch((error) => {
          console.error(error);
          throw error;
        });
      commit('tasks/addNewUploadTask', dbTask, { root: true });
    }

    // Add labels to database
    labelsFromFile = await labelsFromFile;
    await dataConnect.addLabel({ label_names: labelsFromFile, dataset_id: importParams.dataset_id });

    const labels = await dataConnect.getLabelList({ dataset_id: importParams.dataset_id })
      .then((resp) => {
        if (resp.result) {
          return resp.result;
        }
        throw Error("Failed to get labels from the database");
      });

    const promises = [];
    const max_concurrent_req = 6;
    const limit = pLimit(max_concurrent_req);
    let numAnnotationFileParseFailed = 0;
    Object.values(importFileGroups).forEach((importFileGroup) => {
      promises.push(limit(async () => {
        // Parse Annotation File
        let annotations = [];
        let annotationMeta;
        if (importFileGroup.annotationFile) {
          const annotationImporter = annotationImporters['darknet'];
          if (annotationImporter) {
            try {
              ({ annotations, meta: annotationMeta } = await parseAnnotationFile(annotationImporter, importFileGroup.annotationFile, labels));
            } catch (error) {
              console.log(error);
              numAnnotationFileParseFailed += 1;
            }
          }
        }
        // Create database entry for uploaded image
        const uploadData = {
          dataset_id: importParams.dataset_id,
          annotation_set_id: importParams.annotation_set_id,
          docker_task_id: dbTask.id,
          img_name: path.parse(importFileGroup.annotationFile.name).name,
          timestamp: Date.now(),
        };

        if (annotations.length > 0) {
          uploadData.annotations = annotations;
        } else {
          return;
        }
        if (annotationMeta?.timestamp) {
          uploadData.timestamp = annotationMeta.timestamp;
        }
        await dataConnect.uploadAnnotations(uploadData)
          .catch((error) => {
            // TODO handle database fail
            console.error(error);
          });
      }));
    });
    await Promise.all(promises);

    if (numAnnotationFileParseFailed) {
      console.log("Number of failed annotation parses:", numAnnotationFileParseFailed);
    }

    // Remove upload task from store and set to complete in database
    // commit('tasks/removeUploadTask', { taskID }, { root: true });
    dataConnect.updateDockerTaskStatus({
      docker_task_id: dbTask.id,
      status: 'complete',
    });

    return "Upload Complete";
  },
};

export default {
  namespaced: true,
  actions,
};
