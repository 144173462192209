<template>
  <div
    v-if="(allLabelNames && allLabelNames.length > 0) || showName"
    class="details"
    :title="imgName ? imgName : 'Unknown'"
    @click.stop
  >
    <div v-if="showName" data-type="labels" class="details__row">
      <div class="details__name">
        <p class="details__name-content">{{ imgName ? imgName : "Unknown" }}</p>
        <p v-if="imgName && uuid" class="">{{ uuid }}</p>
      </div>
    </div>
    <div v-if="allLabelNames && allLabelNames.length > 0" data-type="labels" class="details__row mt-2">
      <div class="details__description">Labels&nbsp;</div>
      <div class="details__tags">
        <template v-for="label in allLabelNames" :key="label">
          <span class="id-tag" :style="tagColor(label.index)">{{ label.name }}</span>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import useAnnotationColorMap from '@/composables/useAnnotationColorMap.js';
import { toRefs } from 'vue';
import { getColor } from '@/assets/js/utils.js';

export default {
  name: 'GalleryGridItemLabelTags',
  props: {
    labels: {
      type: Array,
      default: () => [],
    },
    annotationList: {
      type: [Array, Object],
      default: () => [],
    },
    imgName: {
      type: String,
      default: "",
    },
    showName: {
      type: Boolean,
      default: false,
    },
    uuid: {
      type: String,
      default: "",
    },
  },
  setup(props) {
    const { labels } = toRefs(props);
    const { map: labelColorMap } = useAnnotationColorMap({ items: labels, key: 'index' });
    return {
      labelColorMap,
    };
  },
  computed: {
    allLabelIndexes() {
      let annotationsArray;
      if (Array.isArray(this.annotationList)) {
        annotationsArray = this.annotationList;
      } else if (typeof this.annotationList === 'object') {
        // Convert object to array if it's not already
        annotationsArray = [this.annotationList];
      } else {
        annotationsArray = [];
      }

      if (annotationsArray) {
        return [...new Set(annotationsArray.map((anno) => anno.label_index))].sort((a, b) => a - b);
      }
      return [];
    },
    allLabelNames() {
      if (this.allLabelIndexes.length > 0) {
        return this.allLabelIndexes.map((index) => {
          const lbl = this.labels.find((label) => label.index === index);
          if (lbl) {
            return lbl;
          } else {
            // use the index as name
            return {
              index,
              name: index.toString(),
            };
          }
        });
      }
      return this.allLabelIndexes;
    },
  },
  methods: {
    tagColor(index) {
      const color = this.labelColorMap[index];
      return {
        borderColor: color,
        background: `hsla(${color},70%,50%,0.75)`,
      };
    },
    getHue(color) {
      const r = parseInt(color.substring(0, 2), 16) / 255;
      const g = parseInt(color.substring(2, 4), 16) / 255;
      const b = parseInt(color.substring(4, 6), 16) / 255;
      let hue;
      if ((r >= g) && (g >= b)) {
        hue = (60 * (g - b)) / (r - b);
      } else if ((g > r) && (r >= b)) {
        hue = 60 * (2 - (r - b) / (g - b));
      }
      return hue;
    },
  },
};
</script>

<style lang="scss" scoped>

.details {
  flex: 1 1 auto;
  height: fit-content;
  padding: 10px;
  font-size: 14px;
  text-align: left;
  border-top: 1px solid rgba(0,0,0,0.1);
  border-bottom-left-radius: inherit;
  border-bottom-right-radius: inherit;
  cursor: default;
  user-select: initial;
  @include themify() {
    background: themed('image-details-background-fill');
  }

  &__row {
    display: flex;
    flex-direction: row;
    width: 100%;
  }

  &__row + .tags__row {
    margin-top: 3px;
  }

  &__description {
    font-size: 0.75rem;
    font-weight: bold;
    padding-right: 12px;
    @include themify() {
      color: themed('body-text-color-secondary');
    }
  }

  &__tags {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 6px;
    @include themify() {
      color: themed('color-black');
    }
  }

  &__name {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
    line-height: 1.2rem;
    border-radius: 4px;
  }
  &__name-content {
    display: -webkit-box;
    width: 100%;
    font-size: 12px;
    font-weight: 700;
    margin: 0;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    line-break: anywhere;
  }
}

.id-tag {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 18px;
  padding: 4px 6px;
  line-height: 1;
  font-size: 0.625rem;
  font-weight: 700;
  border: 2px solid;
  box-shadow: 0px 0px 1px rgb(0 0 0 / 25%), 0px 1px 4px rgb(0 0 0 / 25%);
  @include themify() {
    color: themed('color-black');
  }
}
</style>
