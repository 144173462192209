<template>
  <div class="settings scrollbar">
    <div class="header">
      <span>Transactions</span>
      <div class="date-selector settings__control-group">
        <select v-model="selectYear" class="select">
          <option v-for="(year, i) in years" :key="i" :value="year">
            {{ year }}
          </option>
        </select>
      </div>
    </div>
    <span class="subtitle">All purchases and payments made in the selected year.</span>
    <div class="transactions">
      <div class="table-container">
        <v-data-table
          v-if="doneFetch"
          :headers="headers"
          :items="transactions"
          item-value="name"
          :items-per-page="-1"
          density="comfortable"
        >
          <template #top>
            <div class="table-flex-end">
              <span>{{ `Net Amount ($): ${Number(totalAmount).toLocaleString(userLocale, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}` }}</span>
            </div>
          </template>
          <template #[`header.date_generated`]>
            <div class="date-col">
              <span>Date Posted</span>
              <div class="tz-selector">
                <input
                  id="option-local"
                  v-model="selectTimeZone"
                  type="radio"
                  name
                  :value="'local'"
                >
                <label for="option-local">Local</label>
                <input
                  id="option-utc"
                  v-model="selectTimeZone"
                  type="radio"
                  :value="'utc'"
                >
                <label for="option-utc">UTC</label>
              </div>
            </div>
          </template>
          <template v-for="header in headers" #[`item.${header.key}`]="{ item }">
            <template v-if="header.key ==='amount'">
              {{ `${Number(item[header.key]).toLocaleString(userLocale, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}` }}
            </template>
            <template v-else>
              {{ `${item[header.key]}` }}
            </template>
          </template>
          <template #bottom />
        </v-data-table>
        <div v-else class="empty-state">
          <InlineLoader
            :width="'60px'"
            :height="'60px'"
            :border="'12px'"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DatastoreConnect from '@/assets/js/DatastoreFunctions/datastore-interface';
import InlineLoader from '@/components/InlineLoader.vue';

export default {
  name: 'UserUsageLogs',
  components: {
    InlineLoader,
  },
  data() {
    return {
      dsConn: new DatastoreConnect(this.$store.state.enterpriseServerUrl),
      refreshTimeOut: false,
      headers: [
        {
          title: 'Type', key: 'type', sortable: false, width: '20%', align: 'left',
        },
        {
          title: 'Month', key: 'month', sortable: false, width: '20%', align: 'center',
        },
        {
          title: 'Date Posted', key: 'date_generated', width: '20%', sortable: false, align: 'center',
        },
        {
          title: 'Amount ($)',
          key: 'amount',
          width: '20%',
          sortable: false,
          align: 'center',
        },
      ],
      groupBy: [
        {
          key: 'type',
          order: 'asc',
        },
      ],
      orgId: 0,
      costPackage: null,
      selectYear: 0,
      doneFetch: false,
      transactions: [],
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      selectTimeZone: 'local',
      userLocale: navigator.language || 'en-US',
    };
  },
  computed: {
    user() {
      return this.$store.state.user.user;
    },
    years() {
      const currentYear = new Date().getFullYear();
      const startingYear = 2023;
      const years = [];
      for (let year = startingYear; year <= currentYear; year++) {
        years.push(year);
      }

      return years;
    },
    totalAmount() {
      if (!this.doneFetch) {
        return 0;
      } else {
        return this.transactions.reduce((a, c) => a + c.amount, 0);
      }
    },
  },
  watch: {
    selectTimeZone(val) {
      if (val === 'utc') {
        this.timeZone = 'UTC';
      } else if (val === 'local') {
        this.timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      }
      this.getTransactions();
    },
    selectYear() {
      this.getTransactions();
    },
  },
  created() {
    if (this.$route.query.org) {
      this.orgId = parseInt(this.$route.query.org);
    }
    this.selectYear = new Date().getFullYear();
  },
  async mounted() {
    await this.getCostPackage();
    await this.getTransactions();
  },
  methods: {
    async getTransactions() {
      this.doneFetch = false;
      await this.dsConn.retrieveAnnualBill({ organization_id: this.orgId, year: this.selectYear })
        .then((resp) => {
          if (resp.result && Object.keys(resp.result).length > 0 && this.costPackage) {
            this.parseReport(resp.result);
          } else {
            this.parseReport([]);
          }
          this.doneFetch = true;
        })
        .catch((e) => {
          console.log(e);
          this.doneFetch = true;
        });
    },
    async getCostPackage() {
      await this.dsConn.getCostPackage({ organization_id: this.orgId })
        .then((resp) => {
          if (resp.result) {
            this.costPackage = resp.result;
          } else {
            this.costPackage = null;
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    parseReport(data) {
      const arr = [...data];
      arr.forEach((datum, i) => {
        arr[i].type = `${arr[i].type === 'purchase' ? "Credit Purchase" : "Monthly Service Fee"}`;
        arr[i].amount = Number(arr[i].change * this.costPackage['dve_credits_cost_usd']);
        arr[i].date_generated = new Date(arr[i].date_generated).toLocaleString('default', {
          year: 'numeric', month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric', hour12: true, timeZone: this.timeZone,
        });
      });
      this.transactions = arr;
    },
    switchTime() {
      const arr = [...this.transactions];
      arr.forEach((datum, i) => {
        arr[i].date_generated = new Date(arr[i].date_generated).toLocaleString('default', {
          year: 'numeric', month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric', hour12: true, timeZone: this.timeZone,
        });
      });
      this.transactions = arr;
    },
  },
};
</script>

<style lang="scss" scoped>
.flex-col {
  display: flex;
  flex-direction: column;
}

.refresh-usage {
  width: fit-content;
}
.settings {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: 400px;
  height: 100%;
  padding: 30px;
  overflow-y: auto;
}

.empty-state {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  height: 100%;
}

.date-selector {
  width: 200px;
  display: flex;
  flex-direction: row;
  margin-left: 20px;
  font-size: 1rem;
  font-weight: 500;

  select + select {
    margin-left: 20px;
  }

  button {
    margin-left: 20px;
  }
}

.header {
  font-size: 1.2rem;
  font-weight: 700;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.subtitle {
  font-size: 0.9rem;
}

.icon {
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
}

.transactions {
  .radio-group {
    display: inline-block;
    margin: 10px 0 20px 0;
    border-radius: 5px;
    overflow: hidden;
    border: solid 1px black
  }

  .radio-group input[type="radio"] {
    position: absolute;
    opacity: 0;
    pointer-events: none;
  }

  .radio-group input[type="radio"] + label {
    min-width: 6rem;
    max-width: 50%;
    text-align: center;
    display: inline-block;
    cursor: pointer;
    font-size: 0.9rem;
    line-height: 1.5;
    padding: 5px 20px;
    transition: all 0s ease;
    @include themify() {
      background: themed('card-color');
      color: themed('body-text-color');
    }
  }

  .radio-group input[type="radio"]:not(:disabled):hover + label {
    @include themify() {
      background: themed('color-primary-300');
      color: themed('primary-text-color');
    }
  }

  .radio-group input[type="radio"]:checked + label,
  .radio-group input[type="radio"]:checked:hover + label {
    opacity: 1;
    z-index: 1;
    @include themify() {
      background: themed('color-primary');
      color: themed('primary-text-color');
    }
  }

  .table-container {
    width: 100%;
    padding: 15px;
    border-radius: 8px;
    margin-top: 20px;
    box-shadow: 1px 1px 4px 1px rgba(0, 0, 0, 0.2), 0 0 1px 1px rgba(0, 0, 0, 0.05);
    @include themify() {
      background: themed('card-color');
      color: themed('body-text-color');

    }
  }

  :deep(tbody tr:nth-of-type(odd)) {
    background-color: rgba(0, 0, 0, .05);
  }
  .table-flex-end {
    display: flex;
    justify-content: end;

      span {
      font-size: 1rem;
      font-weight: 600;
    }
  }
  .total-credit-group{
    text-align: end;
    font-weight: 550;

    span {
      margin-left: auto;
      font-size: 1.0rem;
    }
  }

  .radio-group {
    display: inline-block;
    margin: 10px 0 20px 0;
    border-radius: 5px;
    overflow: hidden;
    border: solid 1px black
  }

  .radio-group input[type="radio"] {
    position: absolute;
    opacity: 0;
    pointer-events: none;
  }

    .radio-group input[type="radio"] + label {
      min-width: 6rem;
      max-width: 50%;
      text-align: center;
      display: inline-block;
      cursor: pointer;
      font-size: 0.9rem;
      line-height: 1.5;
      padding: 5px 20px;
      transition: all 0s ease;
      @include themify() {
        background: themed('card-color');
        color: themed('body-text-color');
      }
    }

    .radio-group input[type="radio"]:not(:disabled):hover + label {
      @include themify() {
        background: themed('color-primary-300');
        color: themed('primary-text-color');
      }
    }

    .radio-group input[type="radio"]:checked + label,
    .radio-group input[type="radio"]:checked:hover + label {
      opacity: 1;
      z-index: 1;
      @include themify() {
        background: themed('color-primary');
        color: themed('primary-text-color');
      }
    }
}

.date-col {
  display: flex;
  flex-direction: row;
  justify-content: center;

  .tz-selector {
    margin-left: 5px;

    input[type="radio"] {
      display: none;
    }

    label {
      padding: 2px 5px;
      width: fit-content;
      font-size: 0.85rem;
      font-weight: bold;
      color: var(--gray-500);
      cursor: pointer;
    }

     input[type="radio"]:checked+label {
      @include themify() {
        color: themed('color-primary-500');
      }
    }
  }
}

</style>
