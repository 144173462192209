import pLimit from 'p-limit';
import { fetchRetry, getFileContents } from '@/assets/js/utils';
import exifr from 'exifr';
import path from 'path-browserify';
import DatastoreConnect from '@/assets/js/DatastoreFunctions/datastore-interface';
import MediaInfoFactory from 'mediainfo.js';
import darknet from './darknet';
import modelpack from './modelpack';
import maivin from './maivin';
import coco from './coco';

const annotationImporters = {
  darknet,
  modelpack,
  maivin,
};

const thumbnailSize = 256;

async function parseLabelsTxt(file) {
  const labels = [];
  const fileContents = await getFileContents(file);
  fileContents.split('\n').forEach((line) => {
    // Check that line is valid
    if (line !== '') {
      labels.push(line);
    }
  });
  return labels;
}

async function parseAnnotationFile(importer, file, labels) {
  let annotations;
  let meta = {};
  if (file.type === 'text/plain') {
    if (!importer.parseAnnotationTextFile) {
      throw Error("Can't parse txt");
    }
    annotations = await importer.parseAnnotationTextFile(file);
  } else if (file.type === 'application/json') {
    if (!importer.parseAnnotationJsonFile) {
      throw Error("Can't parse JSON");
    }
    ({ annotations, meta } = await importer.parseAnnotationJsonFile(file, labels));
  }
  return { annotations, meta };
}

function createThumbnail(img) {
  return new Promise((resolve) => {
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    // Calculate the dimensions for the thumbnail
    const maxWidth = thumbnailSize; // Adjust this as needed
    const maxHeight = thumbnailSize; // Adjust this as needed
    let width = img.width;
    let height = img.height;

    if (width > maxWidth || height > maxHeight) {
      const aspectRatio = width / height;
      if (width > maxWidth) {
        width = maxWidth;
        height = maxWidth / aspectRatio;
      }
      if (height > maxHeight) {
        height = maxHeight;
        width = maxHeight * aspectRatio;
      }
    }

    // Set the canvas size
    canvas.width = width;
    canvas.height = height;

    // Draw the image on the canvas to create the thumbnail
    ctx.drawImage(img, 0, 0, width, height);

    // Convert the canvas to a Blob with JPG format
    canvas.toBlob(resolve, 'image/jpeg');
  });
}

function createVideoThumbnail(file, videoDimensions) {
  return new Promise((resolve) => {
    const videoPath = URL.createObjectURL(file);

    const video = document.createElement('video');
    video.onerror = (e) => { console.log(e); };
    video.onloadeddata = () => {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');

      // Calculate the dimensions for the thumbnail
      const maxWidth = thumbnailSize; // Adjust this as needed
      const maxHeight = thumbnailSize; // Adjust this as needed
      let width = videoDimensions.width;
      let height = videoDimensions.height;

      if (width > maxWidth || height > maxHeight) {
        const aspectRatio = width / height;
        if (width > maxWidth) {
          width = maxWidth;
          height = maxWidth / aspectRatio;
        }
        if (height > maxHeight) {
          height = maxHeight;
          width = maxHeight * aspectRatio;
        }
      }

      // Set the canvas size
      canvas.width = width;
      canvas.height = height;

      // Draw the image on the canvas to create the thumbnail
      ctx.drawImage(video, 0, 0, width, height);

      video.pause();

      // Convert the canvas to a Blob with JPG format
      canvas.toBlob(resolve, 'image/jpeg');
    };

    video.autoplay = true;
    video.muted = true;
    video.src = videoPath;
  });
}

async function readUserComment(json_raw) {
  let json_str = json_raw.substring(json_raw.indexOf("{"));
  json_str = json_str.trim();
  const json = JSON.parse(json_str);
  const userComment = json;
  const annotations = [];
  const meta = {};
  json.objects.forEach((obj) => {
    annotations.push({
      label_index: -1,
      label_name: obj.label,
      score: obj.score,
      x: obj.bbox.xmin,
      y: obj.bbox.ymin,
      w: obj.bbox.xmax - obj.bbox.xmin,
      h: obj.bbox.ymax - obj.bbox.ymin,
      type: 'box',
      polygon: '',
    });
  });

  if (json.device_id) {
    meta.source = json.device_id;
  }
  if (json.timestamp) {
    meta.timestamp = json.timestamp;
  }
  if (json.gps) {
    meta.gps = {};
    meta.gps.lat = json.gps.lat;
    meta.gps.lon = json.gps.lon;
  }
  return { annotations, meta, userComment };
}

async function getExifData(imageData) {
  // Try to get exif data
  let exifResult = {
    annotations: [],
    meta: {
      gps: {},
      timestamp: 0,
    },
  };
  const options = {
    userComment: true,
  };
  let exifData;
  try {
    exifData = await exifr.parse(imageData, options);
  } catch (error) {
    console.log(error);
    throw Error("Failed to parse EXIF");
  }
  if (!exifData) {
    return exifResult;
  }

  // Parse exif data
  if (exifData.latitude && exifData.longitude) {
    exifResult.meta.gps.lat = exifData.latitude;
    exifResult.meta.gps.lon = exifData.longitude;
  }
  if (exifData.DateTimeOriginal) {
    exifResult.meta.timestamp = Math.round(exifData.DateTimeOriginal.getTime() * 1000000);
  }

  if (exifData.userComment) {
    try {
      exifResult = readUserComment(exifData.userComment);
    } catch (error) {
      // Do Nothing
      console.error(error);
    }
  }

  return exifResult;
}

async function getMediaInfo(file) {
  const mediaInfo = await MediaInfoFactory({ format: 'object' });

  const getSize = () => file.size;

  const readChunk = (chunkSize, offset) => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (event) => {
      if (event.target.error) {
        reject(event.target.error);
      }
      resolve(new Uint8Array(event.target.result));
    };
    reader.readAsArrayBuffer(file.slice(offset, offset + chunkSize));
  });
  const mediaInfoResult = await mediaInfo
    .analyzeData(getSize, readChunk)
    .catch((error) => {
      console.log(error);
    });

  return mediaInfoResult;
}

const actions = {
  parseImage({ commit, dispatch }, file) {
    return new Promise((resolve, reject) => {
      const imageInfo = {};
      // Function to extract EXIF data using exif-js library
      const reader = new FileReader();
      reader.onload = function (e) {
        const imageArrayBuffer = e.target.result;
        const image = new Image();
        image.onload = async function () {
          // Get image dimensions
          imageInfo.width = image.naturalWidth;
          imageInfo.height = image.naturalHeight;

          // const thumbnail = await createThumbnail(image);

          try {
            const exifData = await getExifData(imageArrayBuffer);
            imageInfo.exif = exifData;
          } catch (error) {
            console.log(error);
          }
          resolve({ imageInfo });
        };
        image.onerror = function () {
          reject(Error(`Failed to parse image: ${file.name}`));
        };

        image.src = URL.createObjectURL(file);
      };
      reader.onerror = function () {
        reject(Error(`Failed to parse image: ${file.name}`));
      };
      reader.readAsArrayBuffer(file);
    });
  },

  parseVideo({ commit, dispatch }, file) {
    return new Promise((resolve, reject) => {
      const videoDimensions = {};
      let videoMeta = {};

      const video = document.createElement('video');
      video.controls = true;

      // Function to extract EXIF data using exif-js library
      const reader = new FileReader();
      reader.onload = function (e) {
        const videoArrayBuffer = e.target.result;

        video.onloadedmetadata = async function () {
          // Get image dimensions
          videoDimensions.width = this.videoWidth;
          videoDimensions.height = this.videoHeight;

          // Get media info about the video
          const mediaInfoResult = await getMediaInfo(file);
          const generalTrack = mediaInfoResult?.media?.track?.[0];
          if (generalTrack) {
            videoMeta = {
              ...videoMeta,
              ...generalTrack,
            };
          }

          const thumbnail = await createVideoThumbnail(file, videoDimensions);
          // try {
          //   const exifData = await getExifData(imageArrayBuffer);
          //   imageInfo.exif = exifData;
          // } catch (error) {
          //   console.log(error);
          //   // reject(error);
          // }

          resolve({ videoDimensions, videoMeta, thumbnail });
        };

        video.src = URL.createObjectURL(new Blob([videoArrayBuffer]));
      };

      reader.readAsArrayBuffer(file);
    });
  },

  async import({ commit, dispatch }, { urls, importParams }) {
    const dataConnect = new DatastoreConnect('', { delay: 500, tries: 5, backoff_factor: 1 });
    const annotationFileTypes = ['application/json', 'text/plain'];

    // Match image files with annotation files
    let importFileGroups = {};
    let labelsFromFile = [];
    urls.forEach(async (presignedUrl) => {
      if (presignedUrl.file.name === 'labels.txt') {
        labelsFromFile = parseLabelsTxt(presignedUrl.file);
        return;
      }

      const basename = path.basename(presignedUrl.file.name, path.extname(presignedUrl.file.name));
      const fileGroup = importFileGroups[basename];
      if (!fileGroup) { importFileGroups[basename] = {}; }

      if (presignedUrl.file.type.startsWith('image/') && presignedUrl.file.size > 0) {
        importFileGroups[basename].imageFile = presignedUrl;
      } else if (presignedUrl.file.type.startsWith('video/') && presignedUrl.file.size > 0) {
        importFileGroups[basename].videoFile = presignedUrl;
      } else if (annotationFileTypes.includes(presignedUrl.file.type)) {
        importFileGroups[basename].annotationFile = presignedUrl;
      }
    });

    // Filter empty groups caused by empty files
    importFileGroups = Object.fromEntries(Object.entries(importFileGroups).filter(([key, value]) => Object.keys(value).length > 0));

    const numImages = Object.values(importFileGroups).length;
    if (numImages === 0) {
      alert("No valid images found");
      return "Upload Cancelled";
    }

    // Add upload task to store and database
    let dbTask;
    if (importParams.task) {
      // Resume from existing docker task
      const updatedTask = await dataConnect.updateDockerTaskStatus({
        docker_task_id: importParams.task.id,
        status: 'running',
      })
        .then((resp) => {
          if (resp.result) {
            return resp.result;
          }
          throw Error("Failed to update import task in database to status 'running'");
        })
        .catch((error) => {
          console.error(error);
        });
      dbTask = updatedTask;
      commit('tasks/resumeUploadTask', dbTask, { root: true });
    } else {
      // Create new docker task
      dbTask = await dataConnect.addDockerTask({
        name: `Upload To ${importParams.dataset_name}`,
        project_id: importParams.project_id,
        docker_id: '',
        type: 'upload',
        status: 'running',
        total: numImages,
        data: {
          dataset_id: importParams.dataset_id,
          annotation_set_id: importParams.annotation_set_id,
          s3_path: importParams.importer_source,
          import_type: importParams.import_type,
        },
      })
        .then((resp) => {
          if (resp.result) {
            return resp.result;
          }
          throw Error("Failed to create import task in database");
        })
        .catch((error) => {
          console.error(error);
          throw error;
        });
      commit('tasks/addNewUploadTask', dbTask, { root: true });
    }

    // Add labels to database
    labelsFromFile = await labelsFromFile;
    await dataConnect.addLabel({ label_names: labelsFromFile, dataset_id: importParams.dataset_id });

    const labels = await dataConnect.getLabelList({ dataset_id: importParams.dataset_id })
      .then((resp) => {
        if (resp.result) {
          return resp.result;
        }
        throw Error("Failed to get labels from the database");
      });

    // Add groups to database
    let groups = [];
    if (importParams.create_image_groups) {
      const createGroupsParams = {
        dataset_id: importParams.dataset_id,
        group_names: [],
        group_splits: [],
      };
      importParams.create_image_groups.forEach((createGroup) => {
        createGroupsParams.group_names.push(createGroup);
        createGroupsParams.group_splits.push(0);
      });

      groups = await dataConnect.createGroupsForDataset(createGroupsParams).then((resp) => {
        if (resp.error !== undefined) {
          throw Error(resp.error);
        }
        return resp.result;
      })
        .catch((error) => {
          console.error(error);
        });
    }

    const promises = [];
    const max_concurrent_req = 6;
    const limit = pLimit(max_concurrent_req);
    let numAnnotationFileParseFailed = 0;
    let imageUploadSuccessCount = 0;
    Object.values(importFileGroups).forEach((importFileGroup) => {
      promises.push(limit(async () => {
        // Handle creating database entry and uploading image/thumbnail files to S3
        if (importFileGroup.imageFile) {
          const imageRelativePath = importFileGroup.imageFile.file.relativePath || importFileGroup.imageFile.file.webkitRelativePath || importFileGroup.imageFile.file.name;

          // Parse image metadata
          let imageInfo;
          // let thumbnail;
          try {
            ({ imageInfo } = await dispatch('parseImage', importFileGroup.imageFile.file));
          } catch (error) {
            console.error(error);
            return;
          }

          // Parse Annotation File
          let annotations = [];
          let annotationMeta;
          if (importFileGroup.annotationFile) {
            const annotationImporter = annotationImporters[importParams.import_type];
            if (annotationImporter) {
              try {
                ({ annotations, meta: annotationMeta } = await parseAnnotationFile(annotationImporter, importFileGroup.annotationFile.file, labels));
              } catch (error) {
                console.error(error);
                numAnnotationFileParseFailed += 1;
              }
            }
          }

          // Create database entry for uploaded image
          const uploadData = {
            dataset_id: importParams.dataset_id,
            annotation_set_id: importParams.annotation_set_id,
            image_width: imageInfo.width,
            image_height: imageInfo.height,
            image_filepath: imageRelativePath,
            s3_path: importParams.importer_source,
            docker_task_id: dbTask.id,
            type: 'image',
          };

          // Parse auto created group
          const pathSplit = path.normalize(imageRelativePath).split(path.sep);
          if (pathSplit.length > 1 && importParams.create_image_groups?.includes(pathSplit[1])) {
            const toCreateGroup = groups.find((group) => group.name === pathSplit[1]);
            uploadData.group_id = toCreateGroup.id;
          }

          if (importParams.group_id) {
            uploadData.group_id = importParams.group_id;
          }

          if (imageInfo.exif) {
            if (imageInfo.exif.annotations && imageInfo.exif.annotations.length > 0) {
              uploadData.annotations = imageInfo.exif.annotations;
            }
            if (imageInfo.exif.meta && imageInfo.exif.meta.gps.lon && imageInfo.exif.meta.gps.lat) {
              uploadData.lon = imageInfo.exif.meta.gps.lon;
              uploadData.lat = imageInfo.exif.meta.gps.lat;
            }
            if (imageInfo.exif.meta && imageInfo.exif.meta.timestamp) {
              uploadData.timestamp = imageInfo.exif.meta.timestamp;
            }
            if (imageInfo.exif.meta && imageInfo.exif.meta.source) {
              uploadData.image_source = imageInfo.exif.meta.source;
            }
          }

          if (annotations.length > 0) {
            uploadData.annotations = annotations;
          }
          if (annotationMeta?.source) {
            uploadData.image_source = annotationMeta.source;
          }
          if (annotationMeta?.timestamp) {
            uploadData.timestamp = annotationMeta.timestamp;
          }
          if (annotationMeta?.gps) {
            uploadData.lat = annotationMeta.gps.lat;
            uploadData.lon = annotationMeta.gps.lon;
          }
          if (imageInfo.exif.userComment) {
            // Append userComment to meta column if exists
            uploadData.meta = { ...uploadData.meta, user_comment: imageInfo.exif.userComment };
          }
          await dataConnect.addUploadData(uploadData)
            .then(async () => {
              imageUploadSuccessCount += 1;
              // Upload image file to S3 bucket using presigned url
              await fetchRetry(importFileGroup.imageFile.url, { delay: 500, tries: 5, backoff_factor: 1 }, {
                method: 'PUT',
                body: importFileGroup.imageFile.file,
              })
                // .then(async () => {
                //   // Upload thumbnail to S3 bucket using presigned url
                //   fetchRetry(importFileGroup.imageFile.thumbnail_url, { delay: 500, tries: 5, backoff_factor: 1 }, {
                //     method: 'PUT',
                //     body: thumbnail,
                //   })
                //     .catch((error) => {
                //       // Handle thumbnail upload error
                //       console.error(error);
                //     });
                // })
                .catch((error) => {
                  // Handle image upload error
                  console.error(error);
                });
            })
            .catch((error) => {
              // TODO handle database fail
              console.error(error);
            });
        }
        if (importFileGroup.videoFile) {
          const imageRelativePath = importFileGroup.videoFile.file.relativePath || importFileGroup.videoFile.file.webkitRelativePath || importFileGroup.videoFile.file.name;

          // Parse image metadata
          let videoDimensions;
          let videoMeta;
          let thumbnail;
          try {
            ({ videoDimensions, videoMeta, thumbnail } = await dispatch('parseVideo', importFileGroup.videoFile.file));
          } catch (error) {
            console.log(error);
          }

          // Parse Annotation File
          // let annotations = [];
          // let annotationMeta;
          // if (importFileGroup.annotationFile) {
          //   const annotationImporter = annotationImporters[importParams.import_type];
          //   if (annotationImporter) {
          //     try {
          //       ({ annotations, meta: annotationMeta } = await parseAnnotationFile(annotationImporter, importFileGroup.annotationFile.file, labels));
          //     } catch (error) {
          //       console.log(error);
          //       numAnnotationFileParseFailed += 1;
          //     }
          //   }
          // }

          // Create database entry for uploaded image
          const uploadData = {
            dataset_id: importParams.dataset_id,
            annotation_set_id: importParams.annotation_set_id,
            image_width: videoDimensions.width,
            image_height: videoDimensions.height,
            image_filepath: imageRelativePath,
            s3_path: importParams.importer_source,
            docker_task_id: dbTask.id,
            type: 'video',
            meta: videoMeta,
          };

          // Parse auto created group
          const pathSplit = path.normalize(imageRelativePath).split(path.sep);
          if (pathSplit.length > 1 && importParams.create_image_groups?.includes(pathSplit[1])) {
            const toCreateGroup = groups.find((group) => group.name === pathSplit[1]);
            uploadData.group_id = toCreateGroup.id;
          }

          if (importParams.group_id) {
            uploadData.group_id = importParams.group_id;
          }

          // if (imageInfo.exif) {
          //   if (imageInfo.exif.annotations && imageInfo.exif.annotations.length > 0) {
          //     uploadData.annotations = imageInfo.exif.annotations;
          //   }
          //   if (imageInfo.exif.meta && imageInfo.exif.meta.gps.lon && imageInfo.exif.meta.gps.lat) {
          //     uploadData.lon = imageInfo.exif.meta.gps.lon;
          //     uploadData.lat = imageInfo.exif.meta.gps.lat;
          //   }
          //   if (imageInfo.exif.meta && imageInfo.exif.meta.timestamp) {
          //     uploadData.timestamp = imageInfo.exif.meta.timestamp;
          //   }
          // }

          // if (annotations.length > 0) {
          //   uploadData.annotations = annotations;
          // }
          // if (annotationMeta?.source) {
          //   uploadData.image_source = annotationMeta.source;
          // }
          // if (annotationMeta?.timestamp) {
          //   uploadData.timestamp = annotationMeta.timestamp;
          // }
          // if (annotationMeta?.gps) {
          //   uploadData.lat = annotationMeta.gps.lat;
          //   uploadData.lon = annotationMeta.gps.lon;
          // }
          await dataConnect.addUploadData(uploadData)
            .then(async () => {
              imageUploadSuccessCount += 1;
              // Upload image file to S3 bucket using presigned url
              await fetchRetry(importFileGroup.videoFile.url, { delay: 500, tries: 5, backoff_factor: 1 }, {
                method: 'PUT',
                body: importFileGroup.videoFile.file,
              })
                .then(async () => {
                  // Upload thumbnail to S3 bucket using presigned url
                  fetchRetry(importFileGroup.videoFile.thumbnail_url, { delay: 500, tries: 5, backoff_factor: 1 }, {
                    method: 'PUT',
                    body: thumbnail,
                  })
                    .catch((error) => {
                      // Handle thumbnail upload error
                      console.error(error);
                    });
                })
                .catch((error) => {
                  // Handle image upload error
                  console.error(error);
                });
            })
            .catch((error) => {
              // TODO handle database fail
              console.error(error);
            });
        }
      }));
    });
    await Promise.all(promises);

    if (numAnnotationFileParseFailed) {
      console.log("Number of failed annotation parses:", numAnnotationFileParseFailed);
    }

    dataConnect.completeUpload()
      .then(() => commit('notifications/updateNotifications', true));

    // Remove upload task from store and set to complete in database
    // commit('tasks/removeUploadTask', { taskID }, { root: true });
    dataConnect.updateDockerTaskStatus({
      docker_task_id: dbTask.id,
      status: 'complete',
    });

    return "Upload Complete";
  },

  async importWithDocker({ commit, dispatch }, { urls, taskID }) {
    const promises = [];
    const max_concurrent_req = 10;
    const limit = pLimit(max_concurrent_req);
    urls.forEach((presignedUrl) => {
      promises.push(limit(() => fetchRetry(presignedUrl.url, { delay: 500, tries: 5, backoff_factor: 1 }, {
        method: 'PUT',
        body: presignedUrl.file,
      })
        .then(() => {
          commit('tasks/updateUploadTaskPercent', { taskID }, { root: true });
        })));
    });
    return Promise.all(promises);
  },

  async importByType({ commit, dispatch }, { urls, importParams }) {
    switch (importParams.import_type) {
    case "coco":
      return coco.importDataset({ commit, dispatch }, { urls, importParams });
    default:
      return dispatch('import', { urls, importParams });
    }
  },
};

export default {
  namespaced: true,
  actions,
};
