<template>
  <v-expansion-panel
    v-for="topic in listedTopics"
    :key="topic.key"
    :value="topic.key"
  >
    <template #title>
      <div>
        <span>{{ topic.display }}</span>
      </div>
    </template>
    <template #text>
      <ul class="annotation-list scrollbar">
        <li
          v-for="(annotation, j) in groupedAnnotations[topic.key]"
          :key="annotation.id"
          class="_hover"
          :class="{_active: selectedAnnotationIdentifiers.includes(annotation.id)}"
          @click="handleAnnotationClicked(annotation, j)"
          @mouseover="handleAnnotationListItemHovered(annotation, true)"
          @mouseleave="handleAnnotationListItemHovered(annotation, false)"
        >
          <span>{{ labelIndexToName(annotation.label_index) }}</span>
          <div>
            <IconButton
              id="editor-delete-annotation-btn"
              :icon="'delete'"
              :width="20"
              :height="20"
              :type="'click'"
              @click.stop="() => emit('delete-annotation', annotation)"
            />
          </div>
        </li>
      </ul>
    </template>
  </v-expansion-panel>
  <!-- <div v-else class="empty">
    <p>No Annotations  Found</p>
  </div> -->
</template>

<script setup>
import {
  ref, computed, watch, toRefs,
} from 'vue';
import { storeToRefs } from 'pinia';
import IconButton from '@/components/IconButton.vue';
import useAnnotationType from '@/composables/useAnnotationType.js';

const {
  allTypes,
} = useAnnotationType();

const props = defineProps({
  annotations: { type: Array, default: () => [] },
  labels: { type: Array, default: () => [] },
  selectedAnnotationIdentifiers: { type: Array, default: () => [] },
  hoveredAnnotationListIdentifier: { type: [String, Number], default: null },
});
const { annotations } = toRefs(props);

const listedTopics = computed(() => {
  if (groupedAnnotations.value) {
    return allTypes.value.filter((e) => Object.keys(groupedAnnotations.value).includes(e.key));
  } else { return []; }
});

const emit = defineEmits(['item-clicked', 'delete-annotation', 'hovered-annotation']);

const hoveredAnnotationListItem = ref(null);
function handleAnnotationListItemHovered(annotation, hovered) {
  if (hovered) {
    hoveredAnnotationListItem.value = annotation;
    emit('hovered-annotation', annotation.id);
  } else if (hoveredAnnotationListItem.value.id === annotation.id) {
    hoveredAnnotationListItem.value = null;
    emit('hovered-annotation', null);
  }
}

const groupedAnnotations = computed(() => {
  const groupedData = {};
  annotations.value.forEach((item) => {
    const itemType = item.type;
    if (!groupedData[itemType]) {
      groupedData[itemType] = [];
    }
    groupedData[itemType].push(item);
  });
  return groupedData;
});

function labelIndexToName(index) {
  if (props.labels.length > 0) {
    const labelObj = props.labels.find((label) => label.index === index);
    if (labelObj) {
      return labelObj.name;
    }
  }
  return index;
}

async function handleAnnotationClicked(annotation, index) {
  emit('item-clicked', annotation, index);
}

</script>

<style lang="scss" scoped>

.annotation-list {
  display: flex;
  flex-direction: column;
  flex: 1 1 50%;
  padding: 8px 0 0 0;
  margin: 0;
  overflow-y: auto;
  list-style-type: none;
  height: 100%;
  min-height: 0;

  li {
    user-select: none;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    -o-user-select: none;
    flex-direction: row;
    display: flex;
    align-items: center;
    margin-bottom: unset;
    min-height: 30px;
    height: fit-content;
    padding: 4px 8px;
    font-size: 0.875rem;
    border: 1px solid transparent;

    button {
      @include themify() {
        color: themed('color-primary');
      }
    }

    span {
      margin-right: auto;
    }

    &._active {
      background: rgba(140,140,140,0.2);
      cursor: pointer;

      @include themify() {
        border: 1px solid black;
        border-left: 4px solid themed('color-primary');
      }
    }
  }

  li._hover {
    &:hover {
      background: rgba(140,140,140,0.2);
      cursor: pointer;
    }
  }

  &__symbol {
    width: 16px;
    height: 16px;
    margin-right: 8px;
  }

  &__box-symbol {
    border: 2px solid;
  }
}

.empty {
  font-style: italic;
  padding: 16px 8px;
  margin: 8px;
  color: var(--body-text-color-secondary);
  border: dashed 2px rgba(var(--color-primary-rgb), 0.5);
  border-radius: 8px;
}
</style>
