<template>
  <Modal
    ref="createProjectModal"
    :title="'Create New Project'"
    :width="'700px'"
    @closed="clearData"
  >
    <template #modal-main>
      <div class="settings container">
        <div class="row mt-2">
          <div class="col-12 settings__control-group">
            <label class="label required">Name</label>
            <input
              v-model="createNewName"
              class="select"
              type="text"
            >
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-12 settings__control-group">
            <label>Description</label>
            <textarea
              v-model="createNewDescription"
              rows="2"
              cols="50"
            />
          </div>
        </div>
      </div>
    </template>
    <template #modal-footer>
      <div class="footer">
        <button class="button" @click="createProject">Create</button>
      </div>
    </template>
  </Modal>
</template>

<script>
import DatastoreConnect from '@/assets/js/DatastoreFunctions/datastore-interface';
import Modal from '@/components/Modal.vue';

export default {
  name: 'ProjectCreate',
  components: {
    Modal,
  },
  emits: {
    'create-project': null,
  },
  data() {
    return {
      dsConn: new DatastoreConnect(this.$store.state.enterpriseServerUrl),
      createNewName: '',
      createNewDescription: '',
    };
  },
  computed: {
    user() {
      return this.$store.state.user.user;
    },
  },
  methods: {
    showModal() {
      this.getUsersListByOrganization(this.user.organization_id);
      this.$refs.createProjectModal.showModal();
    },
    clearData() {
      this.createNewName = '';
      this.createNewDescription = '';
    },
    closeModal() {
      this.clearData();
      this.$refs.createProjectModal.closeModal();
    },
    createProject() {
      const args = {
        project_name: this.createNewName,
        description: this.createNewDescription,
      };

      this.$emit('create-project', args);
      this.closeModal();
    },
    async getUsersListByOrganization(id) {
      await this.dsConn.getUserListByOrganization({
        organization_id: id,
      })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>

<style lang="scss" scoped>

.settings {
  padding: 0.75rem 3rem;
  min-width: 420px;
}

.close-button {
  @include themify() {
    background: themed('button-secondary');
    color: themed('button-secondary-text');
  }
}

.footer {
  display: flex;
  flex-direction: row;
  padding: 0.75rem 3rem;
  justify-content: flex-end;
}

</style>
