<template>
  <div ref="sliderContainer" class="range-slidercontainer">
    <div class="range-slider" :class="{'flip': flip}">
      <div class="range-slider-track-background" />
      <div ref="middleDiv" class="range-slider-middle" />
      <input
        ref="sliderLower"
        v-model="localValueLower"
        type="range"
        :step="step"
        :min="min"
        :max="max"
        :disabled="disabled"
        @input="handleSliderLowerInput"
      >
      <input
        ref="sliderUpper"
        v-model="localValueUpper"
        type="range"
        :step="step"
        :min="min"
        :max="max"
        :disabled="disabled"
        @input="handleSliderUpperInput"
      >
    </div>
  </div>
</template>

<script>
export default {
  name: 'RangeSlider',
  props: {
    step: {
      type: Number,
      default: 1,
    },
    min: {
      type: Number,
      default: 0,
    },
    max: {
      type: Number,
      default: 1,
    },
    valueLower: {
      type: Number,
      default: 0,
    },
    valueUpper: {
      type: Number,
      default: 2,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    flip: {
      type: Boolean,
      default: false,
    },
  },
  emits: [
    'input', 'update:valueLower', 'update:valueUpper',
  ],
  data() {
    return {
      localValueLower: 0,
      localValueUpper: 2,
      localValueMiddle: null,
      resizeObserver: null,
    };
  },
  watch: {
    valueLower(newVal) {
      this.localValueLower = newVal;
      this.handleRangeSliderMiddleDiv();
    },
    valueUpper(newVal) {
      this.localValueUpper = newVal;
      this.handleRangeSliderMiddleDiv();
    },
  },
  beforeMount() {
    this.localValueLower = this.valueLower;
    this.localValueUpper = this.valueUpper;
  },
  mounted() {
    // Watch for resizes to adjust middle div when resized
    this.resizeObserver = new ResizeObserver(this.onResize);
    this.resizeObserver.observe(this.$refs.sliderContainer);
  },
  beforeUnmount() {
    if (this.$refs.sliderContainer) {
      this.resizeObserver.unobserve(this.$refs.sliderContainer);
    } else {
      this.resizeObserver.disconnect();
    }
  },
  methods: {
    onResize() {
      this.handleRangeSliderMiddleDiv();
    },
    handleSliderLowerInput(event) {
      let newValueLower = Number(event.target.value);
      if (newValueLower > Number(this.localValueUpper)) {
        newValueLower = this.valueLower;
        this.localValueLower = this.valueLower;
      }
      this.$emit('input');
      this.$emit('update:valueLower', newValueLower);
    },
    handleSliderUpperInput(event) {
      let newValueUpper = Number(event.target.value);
      if (newValueUpper < Number(this.localValueLower)) {
        newValueUpper = this.valueUpper;
        this.localValueUpper = this.valueUpper;
      }
      this.$emit('input');
      this.$emit('update:valueUpper', newValueUpper);
    },
    handleRangeSliderMiddleDiv() {
      const thumbHalfWidth = 7.5;
      const value1 = this.localValueLower;
      const minValue1 = this.$refs.sliderLower.min;
      const maxValue1 = this.$refs.sliderLower.max;
      const value2 = this.localValueUpper;
      const minValue2 = this.$refs.sliderUpper.min;
      const maxValue2 = this.$refs.sliderUpper.max;
      const totalInputWidth = this.$refs.sliderLower.clientWidth;
      const left1 = (((value1 - minValue1) / (maxValue1 - minValue1))
        * ((totalInputWidth - thumbHalfWidth) - thumbHalfWidth)) + thumbHalfWidth;
      const left2 = (((value2 - minValue2) / (maxValue2 - minValue2))
        * ((totalInputWidth - thumbHalfWidth) - thumbHalfWidth)) + thumbHalfWidth;
      const middleWidth = left2 - left1;
      this.$refs.middleDiv.style.left = `${left1}px`;
      this.$refs.middleDiv.style.width = `${middleWidth}px`;
    },
  },
};
</script>

<style lang="scss" scoped>
.range-slidercontainer {
  width: 100%;
}

.range-slider {
  position: relative;
  margin: 0;
  height: 25px;
}
// .range-slider:focus-within {
//   outline: 1px dotted rgba(0,0,0,0.5);
// }
.range-slider input[type='range'] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  pointer-events: none;
  padding: 0;
  margin: 0;
  position: absolute;
  left: 0;
  top: 10px;
  height: 5px;
  width: 100%;
  border-radius: 14px;
  background: none;
}
.range-slider input[type='range']:focus, .range-slider input[type='range']:active {
  outline: none;
}
.range-slider input[type='range']::-webkit-slider-thumb {
  -webkit-appearance: none;
  box-sizing: content-box;
  width: 15px;
  height: 15px;
  border: none;
  pointer-events: auto;
  border-radius: 14px;
  @include themify {
    background-color: themed('range-slider-thumb-color');
  }
  cursor: pointer;
  position: relative;
  z-index: 3;
  top: -5px;
}
.range-slider input[type='range']::-moz-range-thumb {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  box-sizing: content-box;
  width: 15px;
  height: 15px;
  border: none;
  pointer-events: auto;
  border-radius: 14px;
  @include themify {
    background-color: themed('range-slider-thumb-color');
  }
  cursor: pointer;
  position: relative;
  z-index: 3;
  top: -5px;
}
.range-slider input[type='range']:disabled::-webkit-slider-thumb {
  pointer-events: none;
  @include themify {
    background-color: themed('slider-background-disabled');
  }
}
.range-slider input[type='range']:disabled::-moz-range-thumb {
  pointer-events: none;
  @include themify {
    background-color: themed('slider-background-disabled');
  }
}
.range-slider input[type='range']::-webkit-slider-runnable-track {
  @include themify {
    background: themed('range-slider-unselected-color');
  }
  height: 5px;
  border-radius: 2.5px;
}
.range-slider input[type='range']::-moz-range-track {
  @include themify {
    background: transparent;
  }
  height: 5px;
  border-radius: 2.5px;
}
.range-slider input[type='range']:disabled::-webkit-slider-runnable-track {
  @include themify {
    background: themed('slider-background-disabled');
  }
}
.range-slider input[type='range']:disabled::-moz-range-track {
  @include themify {
    background: themed('slider-background-disabled');
  }
}

.range-slider .range-slider-track-background {
  display: none;
}
.range-slider .range-slider-middle {
  height: 5px;
  width: 20px;
  @include themify {
    background: themed('range-slider-selected-color');
  }
  position: absolute;
  top: 10px;
  z-index: 2;
}
@-moz-document url-prefix() {
  .range-slider .range-slider-track-background {
    display: block;
    height: 5px;
    width: 100%;
    @include themify {
      background: themed('range-slider-unselected-color');
    }
    position: absolute;
    top: 10px;
    z-index: 0;
  }
  .range-slider .range-slider-middle {
    height: 5px;
    width: 20px;
    @include themify {
      background: themed('range-slider-selected-color');
    }
    position: absolute;
    top: 10px;
    z-index: 0;
  }
}

.range-slider input[type='range']:disabled + .range-slider-middle {
  @include themify {
    background: themed('slider-background-disabled');
  }
}

.flip {
  &.range-slider input[type='range']::-webkit-slider-runnable-track {
    @include themify {
      background: themed('range-slider-selected-color');
    }
    height: 5px;
    border-radius: 2.5px;
  }
  &.range-slider input[type='range']::-moz-range-track {
    @include themify {
      background: themed('range-slider-selected-color');
    }
    height: 5px;
    border-radius: 2.5px;
  }
  &.range-slider .range-slider-middle {
    height: 5px;
    width: 20px;
    position: absolute;
    top: 10px;
    z-index: 2;
    @include themify {
      background: themed('range-slider-unselected-color');
    }
  }
  &.range-slider input[type='range']:disabled + .range-slider-middle {
    background: transparent;
  }
}

</style>
