<template>
  <div class="app-page">
    <Header ref="header">
      <template #header-slot>
        <BreadcrumbNavigation />
      </template>
      <template #page-slot>
        <BreadcrumbSelect
          id="project-select"
          v-model="currentProject"
          :options="projectList"
          :placeholder="'Project'"
          :displayTag="'name'"
        />
      </template>
    </Header>
    <div class="main-page-div">
      <!-- <ProjectsIconSidebar
        v-model="currentTab"
        :redirect="redirect"
      /> -->
      <main class="main-display-area">
        <router-view />
      </main>
    </div>
    <TasksModal />
  </div>
</template>

<script>
import Header from '@/components/Header.vue';
import BreadcrumbSelect from '@/components/BreadcrumbSelect.vue';
import TasksModal from '@/components/TasksStatusComponent/TasksModal.vue';
import BreadcrumbNavigation from '@/components/BreadcrumbNavigation.vue';

export default {
  components: {
    Header,
    BreadcrumbSelect,
    TasksModal,
    BreadcrumbNavigation,
  },
  data() {
    return {
      currentTab: null,
      redirect: '',
    };
  },
  computed: {
    projectList() {
      return this.$store.state.projects.projectList;
    },
    currentProject: {
      get() {
        return this.$store.state.projects.currentProject;
      },
      set(value) {
        this.$store.commit('projects/setCurrentProject', value);
      },
    },
  },
  watch: {
    projectList(list) {
      if (list && list.length > 0 && !this.currentProject) {
        this.$store.commit('projects/setCurrentProject', list[0]);
      }
    },
  },
  created() {
    if (this.$route) {
      this.redirect = this.$route.name;
    }
  },
};
</script>

<style lang="scss" scoped>

</style>
