<template>
  <v-rect
    v-if="isHighlighting"
    ref="annotationBox"
    :config="{
      identifier: identifier,
      type: 'box',
      name: name,
      x: convertedBox.x,
      y: convertedBox.y,
      width: convertedBox.width,
      height: convertedBox.height,
      fill: fillColor,
      opacity: 0.325,
      stroke: color,
      strokeWidth: 1.325 * borderWidth,
      shadowEnabled: isHighlighting,
      shadowColor: 'black',
      shadowBlur: 3,
      shadowOffset: { x: 1, y: 1 },
      shadowOpacity: 1,
    }"
  />
  <v-rect
    ref="annotationBox"
    :config="{
      identifier: identifier,
      type: 'box',
      name: name,
      x: convertedBox.x,
      y: convertedBox.y,
      width: convertedBox.width,
      height: convertedBox.height,
      fill: fillColor,
      opacity: 1,
      stroke: color,
      strokeWidth: borderWidth,
      draggable: draggable,
      dragBoundFunc: dragBoundFunc,
      dash: [dashSize, dashSize / 1.5],
      dashEnabled: dashEnabled,
    }"
    @dragstart="handleDragStart"
    @dragend="handleZoneTransformEnd($event)"
    @dragmove="isBusy = true"
    @transformend="handleZoneTransformEnd($event)"
    @transform="isBusy = true"
    @pointerclick="handleBoxClick($event)"
    @mouseover="handleMouseover"
    @mouseout="handleMouseout"
    @mousedown="handleMousedown"
    @mouseup="handleMouseup"
  />
  <KonvaTransformer
    ref="transformer"
    :nodes="isSelected && node ? [node] : []"
    :imageNode="imageNode"
    :stageScale="stageScale"
    :rotateEnabled="false"
    :resizeEnabled="editingEnabled"
    :keepRatio="false"
    :borderStrokeWidth="3"
    :borderStroke="color"
    :borderDash="transformerDashSize"
    @dragstart="(e) => e.cancelBubble = true"
    @dragend="(e) => e.cancelBubble = true"
    @dragmove="(e) => e.cancelBubble = true"
    @transformend="(e) => e.cancelBubble = true"
    @transform="(e) => e.cancelBubble = true"
    @pointerclick="(e) => e.cancelBubble = true"
    @mouseover="(e) => e.cancelBubble = true"
    @mouseout="(e) => e.cancelBubble = true"
    @mousedown="(e) => e.cancelBubble = true"
    @mouseup="(e) => e.cancelBubble = true"
  />
  <v-label
    v-if="displayLabelTag"
    :config="{
      x: convertedBox.x - (borderWidth / 2),
      y: convertedBox.y - (18 / stageScale.x),
      width: 20,
      height: 10,
      fill: color,
    }"
  >
    <v-tag
      :config="{
        fill: color,
      }"
    />
    <v-text
      :config="{
        text: name,
        fill: 'white',
        fontSize: 16 / stageScale.x,
        stroke: 'black',
        strokeWidth: 0.4 / stageScale.x,
        fontFamily: 'Open Sans',
        fontStyle: 'bold',
        padding: 2 / stageScale.x,
      }"
    />
  </v-label>
</template>

<script>
import { roundNumber } from '@/assets/js/utils.js';
import KonvaTransformer from './KonvaTransformer.vue';

export default {
  name: 'AnnotationBox',
  components: {
    KonvaTransformer,
  },
  props: {
    identifier: {
      type: [String, Number],
      default: '',
    },
    name: {
      type: String,
      default: '',
    },
    displayLabelTag: {
      type: Boolean,
      default: false,
    },
    x: {
      type: Number,
      default: 0,
    },
    y: {
      type: Number,
      default: 0,
    },
    width: {
      type: Number,
      default: 0,
    },
    height: {
      type: Number,
      default: 0,
    },
    fillColor: {
      type: String,
      default: '',
    },
    isSelected: {
      type: Boolean,
      default: false,
    },
    isHighlighting: {
      type: Boolean,
      default: false,
    },
    imageDimensions: {
      type: Object,
      default: () => ({ width: 100, height: 100 }),
    },
    stageNode: {
      type: Object,
      default: () => {},
    },
    stageScale: {
      type: Object,
      default: () => ({ x: 1, y: 1 }),
    },
    imageNode: {
      type: Object,
      default: () => {},
    },
    color: {
      type: String,
      default: '#EFC15F',
    },
    isAnnotating: {
      type: Boolean,
      default: false,
    },
    dashEnabled: {
      type: Boolean,
      default: false,
    },
    editingEnabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: [
    'shape-selected',
    'update:x',
    'update:y',
    'update:width',
    'update:height',
    'transformed',
    'box-clicked-while-annotating',
    'box-mouseover-while-annotating',
    'box-mouseout-while-annotating',
  ],
  data() {
    return {
      node: null,
      isBusy: false,
      isMouseOver: false,
    };
  },
  computed: {
    draggable() {
      if (!this.editingEnabled || this.isAnnotating) {
        return false;
      }
      return true;
    },
    convertedBox() {
      return this.convertNormalilzedToPixels(this.x, this.y, this.width, this.height);
    },
    borderWidth() {
      if (this.isSelected && this.editingEnabled) {
        return 0;
      }
      if (this.isHighlighting) {
        return 5 / this.stageScale.x;
      }
      return 4 / this.stageScale.x;
    },
    dragBoundFunc() {
      const dragBoundFunc = (pos) => {
        const imagePos = this.imageNode.absolutePosition();
        const imageAttr = this.imageNode.getAttrs();
        const boxAttr = this.node.getAttrs();
        // Get drag bounds with image node
        const minX = imagePos.x;
        const maxX = (imagePos.x + ((imageAttr.width - boxAttr.width) * this.stageScale.x));
        const minY = imagePos.y;
        const maxY = (imagePos.y + ((imageAttr.height - boxAttr.height) * this.stageScale.y));
        // Override box position
        const x = Math.max(minX, Math.min(pos.x, maxX));
        const y = Math.max(minY, Math.min(pos.y, maxY));
        return {
          x,
          y,
        };
      };
      return dragBoundFunc;
    },
    dashSize() {
      const dashBaseSize = 5;
      const dashMaxSize = 10;

      let dashSize = dashBaseSize / this.stageScale.x;
      const minDimension = Math.min(this.convertedBox.width, this.convertedBox.height);
      if (minDimension * this.stageScale.x < dashSize * 2) {
        dashSize = minDimension / 2;
      }
      dashSize = Math.min(dashSize, dashMaxSize);
      return dashSize;
    },
    transformerDashSize() {
      if (this.dashEnabled) {
        return [this.dashSize * this.stageScale.x, this.dashSize * this.stageScale.x];
      }
      if (!this.editingEnabled && this.isSelected) {
        return [8 * this.stageScale.x, 4 * this.stageScale.x];
      }
      return null;
    },
  },
  mounted() {
    this.node = this.$refs.annotationBox.getNode();
  },
  methods: {
    handleZoneTransformEnd(e) {
      e.cancelBubble = true;
      const target = e.target;
      const transform = e.target.getTransform().decompose();
      const normalizedDimensions = this.convertPixelsToNormalized(target.x(), target.y(), target.width() * transform.scaleX, target.height() * transform.scaleY);
      this.$emit('update:x', roundNumber(normalizedDimensions.x, 3));
      this.$emit('update:y', roundNumber(normalizedDimensions.y, 3));
      this.$emit('update:width', roundNumber(normalizedDimensions.width, 3));
      this.$emit('update:height', roundNumber(normalizedDimensions.height, 3));
      this.$emit('transformed');
      this.isBusy = false;
      target.scale({ x: 1, y: 1 });
    },
    handleDragStart(e) {
      if (this.editingEnabled) {
        this.stageNode.container().style.cursor = "grabbing";
      }
      e.cancelBubble = true;
    },
    handleMouseover(e) {
      if (this.isAnnotating) { this.$emit('box-mouseover-while-annotating', e); return; }
      this.isMouseOver = true;

      if (!this.isSelected) {
        this.stageNode.container().style.cursor = "pointer";
      } else {
        if (this.editingEnabled) {
          this.stageNode.container().style.cursor = "grab";
        } else {
          this.stageNode.container().style.cursor = "pointer";
        }
      }
    },
    handleMouseout(e) {
      if (this.isAnnotating) { this.$emit('box-mouseout-while-annotating', e); return; }
      this.isMouseOver = false;
      this.stageNode.container().style.cursor = null;
    },
    handleMousedown(e) {
      if (this.isAnnotating) { return; }
      if (e.evt.button === 0 && !e.evt.ctrlKey) {
        if (this.editingEnabled) {
          this.stageNode.container().style.cursor = "grabbing";
        }
      }
    },
    handleMouseup() {
      if (this.isAnnotating) { return; }
      if (this.isMouseOver && !this.isSelected) {
        this.stageNode.container().style.cursor = "pointer";
      } else if (this.isMouseOver && this.isSelected) {
        if (this.editingEnabled) {
          this.stageNode.container().style.cursor = "grab";
        }
      } else {
        this.stageNode.container().style.cursor = null;
      }
    },
    handleBoxClick(e) {
      if (this.isAnnotating) { this.$emit('box-clicked-while-annotating', e); return; }
      e.cancelBubble = true;
      this.$emit('shape-selected');
    },
    convertNormalilzedToPixels(x, y, width, height) {
      // Box dimensions
      const newX = Math.round((x) * this.imageDimensions.width);
      const newY = Math.round((y) * this.imageDimensions.height);
      const newWidth = Math.round(width * this.imageDimensions.width);
      const newHeight = Math.round(height * this.imageDimensions.height);
      return {
        x: newX, y: newY, width: newWidth, height: newHeight,
      };
    },
    convertPixelsToNormalized(x, y, width, height) {
      // Box dimensions
      const newX = x / this.imageDimensions.width;
      const newY = y / this.imageDimensions.height;
      const newWidth = width / this.imageDimensions.width;
      const newHeight = height / this.imageDimensions.height;
      return {
        x: newX, y: newY, width: newWidth, height: newHeight,
      };
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
