<template>
  <!-- <PageLoader
    v-if="!loadingFinished"
    :width="'200px'"
    :height="'250px'"
    :text="'Fetching Data'"
  /> -->
  <!-- <template v-else> -->
  <div class="controls-bar">
    <span />
    <button class="button button-sm" @click="openCreateModal">Create</button>
  </div>
  <div v-if="isFetching" id="loading-visualization"><div class="lds-ring"><div /><div /><div /><div /></div></div>

  <div v-else-if="!isFetching && notEmpty" class="manager-card-container scrollbar">
    <DatasetCard
      v-for="(d, i) in internalDatasetList"
      :key="i"
      :annotationSets="d.annotation_sets"
      :labels="d.labels"
      :dataset="d"
      :selected="d.id === currentDatasetID"
      class="list-card"
      @update:annotation-sets="(sets) => setDatasetAnnotationSets(d, sets)"
      @update:labels="(labels) => setDatasetLabels(d, labels)"
      @copy-annset="handleOpenAnnotationCopy(set, d)"
      @delete-dataset="openConfirmDeleteDatasetModal(d)"
      @copy-dataset="handleCopyDatasetClicked(d)"
      @clicked="handleSelectDataset(d)"
      @create-annset="handleCreateAnnotationSet(d)"
      @delete-annset="(params) => openConfirmDeleteAnnsetModal(params)"
      @create-label="handleCreateLabel(d)"
      @label-created="handleUpdateLabel(d)"
      @update-label="handleUpdateLabel(d)"
      @deleted-label="(label) => handleLabelDeletedEvent(label, d)"
      @open-dataset-access-management="handleOpenDatasetAccessManagement"
      @refresh="refreshDatasetCard(d)"
      @open-import="openImportModal(d)"
      @open-export="openExportModal(d)"
      @open-upload="openUploadModal(d)"
      @edit-dataset="openUpdateModal(d)"
      @open-downloads="openDownloadsModal(d)"
      @freeze-dataset="(isFrozen) => handleToggleFreeze(d, isFrozen)"
      @import-annset="openImportAnnsetModal(d)"
      @create-groups="openCreateGroupsModal(d)"
      @delete-group="(group) => openConfirmDeleteGroupModal(group)"
      @to-gallery="handleNavigateToGallery(d)"
      @generate-token="handleOpenGenerateTokenModal(d)"
      @open-conf-matrix="(set) => handleOpenConfMatrix(d, set)"
      @open-description="(set) => handleUpdateAnnotationSet(d, set)"
      @park-dataset="handleParkDatasetEvent(d)"
      @unpark-dataset="handleUnparkDatasetEvent(d)"
    />
  </div>
  <div
    v-else
    class="empty-state"
  >
    <svg width="80%" height="80%">
      <use href="@/assets/img/empty.svg#emptyStateSVG" />
    </svg>
    <div class="empty-state__message">
      <h2>No dataset to display.</h2>
    </div>
  </div>
  <DatasetCreateModal
    ref="createDatasetModal"
    @create-dataset="createDataset"
  />
  <DatasetUpdateModal
    ref="updateDatasetModal"
    :dataset="modalDataset"
    @edit-dataset="(params) => updateDataset(params, modalDataset)"
  />
  <DatasetCopyModal
    v-if="showCopyModal"
    ref="copyDatasetModal"
    v-model:show="showCopyModal"
    :datasets="internalDatasetList"
    :source="modalDataset"
    @copy-complete="(destDatasetID) => refreshDatasetCard(getDatasetWithID(destDatasetID))"
    @closed="modalDataset = null"
  />
  <ConfirmModal
    ref="confirmModal"
    :messageHeader="confirmMessageHeader"
    :message="confirmMessage"
    :note="confirmNote"
    :buttonClass="buttonClass"
    :buttonText="buttonText"
    @confirmed="confirmFunction"
  />
  <AnnSetCopyModal
    ref="copyAnnSetModal"
    :datasets="internalDatasetList"
    :sourceDataset="copyAnnotationSetDataset"
    @clear-copy="clearCopyAnnset"
    @copy-complete="$emit('copy-complete')"
  />
  <CreateAnnotationSetModal
    ref="createAnnotationSetModal"
    :dataset="modalDataset"
    @annotation-set-created="handleAnnotationSetCreatedEvent(modalDataset)"
    @annotation-set-created-error="handleAnnotationSetCreatedErrorEvent"
  />
  <UpdateAnnotationSetModal
    ref="updateAnnotationSetModal"
    :annotationSet="modalAnnotationSet"
    @annotation-set-updated="handleAnnotationSetUpdatedEvent(modalDataset)"
  />
  <CreateLabelsModal
    ref="createLabelsModal"
    :dataset="modalDataset"
    @label-created="handleLabelCreatedEvent(modalDataset)"
  />
  <ManageDatasetAccess
    ref="manageDatasetAccessModal"
    :modalDataset="modalDataset"
    @refresh-datasets="handleAccessUpdated(modalDataset)"
  />
  <DatasetExportModal
    ref="datasetExportModal"
    :datasets="internalDatasetList"
    :exportDataset="impexDataset"
    @export-started="(data) => handleStartExport(impexDataset,data)"
  />
  <UploadImagesModal
    v-if="showUploadModal"
    ref="uploadImagesModal"
    v-model:show="showUploadModal"
    :datasetID="modalDataset ? modalDataset.id : null"
    :annotationSets="modalDataset ? modalDataset.annotation_sets : null"
    @refresh="refreshDatasetCard(modalDataset)"
    @upload-error="handleUploadError"
  />
  <DatasetDownloadsModal
    ref="datasetDownloadsModal"
    :dataset="modalDataset"
  />
  <GenerateTokenModal
    ref="generateTokenModal"
    :modalDataset="modalDataset"
  />
  <CreateGroupsModal
    ref="createGroupsModal"
    :dataset="modalDataset"
    @created-groups="refreshDatasetCard(modalDataset)"
  />
  <ConfusionMatrixModal
    ref="confusionMatrixModal"
  />
</template>

<script>
import ManageDatasetAccess from '@/components/DatasetComponent/DatasetManagement/ManageDatasetAccess.vue';
import CreateLabelsModal from '@/components/DatasetComponent/DatasetManagement/CreateLabelsModal.vue';
import DatasetCard from "@/components/DatasetComponent/DatasetManagement/DatasetCard.vue";
import DatasetCopyModal from "@/components/DatasetComponent/DatasetManagement/DatasetCopyModal.vue";
import DatasetCreateModal from "@/components/DatasetComponent/DatasetManagement/DatasetCreateModal.vue";
import DatasetUpdateModal from "@/components/DatasetComponent/DatasetManagement/DatasetUpdateModal.vue";
import AnnSetCopyModal from '@/components/DatasetComponent/DatasetManagement/AnnSetCopyModal.vue';
import ConfirmModal from '@/components/ConfirmModal.vue';
import CreateAnnotationSetModal from '@/components/DatasetComponent/DatasetManagement/CreateAnnotationSetModal.vue';
import UpdateAnnotationSetModal from '@/components/DatasetComponent/DatasetManagement/UpdateAnnotationSetModal.vue';
import DatastoreConnect from '@/assets/js/DatastoreFunctions/datastore-interface';
import DatasetExportModal from '@/components/DatasetComponent/DatasetManagement/DatasetExportModal.vue';
import UploadImagesModal from '@/components/DatasetComponent/UploadImagesModal.vue';
import DatasetDownloadsModal from '@/components/DatasetComponent/DatasetManagement/DatasetDownloadsModal.vue';
import CreateGroupsModal from '@/components/DatasetComponent/DatasetManagement/CreateGroupsModal.vue';
import GenerateTokenModal from '@/components/DatasetComponent/DatasetManagement/GenerateAPITokenModal.vue';
import ConfusionMatrixModal from '@/components/DatasetComponent/DatasetManagement/ConfusionMatrixModal.vue';

export default {
  name: 'DatasetManagement',
  components: {
    DatasetCard,
    DatasetCopyModal,
    DatasetCreateModal,
    ConfirmModal,
    AnnSetCopyModal,
    CreateAnnotationSetModal,
    UpdateAnnotationSetModal,
    CreateLabelsModal,
    ManageDatasetAccess,
    DatasetExportModal,
    DatasetUpdateModal,
    UploadImagesModal,
    DatasetDownloadsModal,
    CreateGroupsModal,
    GenerateTokenModal,
    ConfusionMatrixModal,
  },
  emits: [
    'create-dataset', 'copy-complete', 'delete-dataset', 'select-dataset', 'update:datasetList', 'edit-dataset',
  ],
  data() {
    return {
      dataConnect: new DatastoreConnect(this.$store.state.enterpriseServerUrl),
      loadingFinished: true,
      toDelete: null,
      copyAnnotationSet: null,
      copyAnnotationSetDataset: null,
      confirmMessage: null,
      confirmNote: null,
      confirmMessageHeader: null,
      buttonClass: "button-delete",
      buttonText: "",
      modalAnnotationSet: null,
      modalDataset: null,
      isImport: false,
      isUpdate: false,
      impexDataset: null,
      confirmFunction: null,
      datasetAccessList: [],
      showUploadModal: false,
      showCopyModal: false,
      controllers: [],
      isFetching: false,
    };
  },
  computed: {
    notEmpty() {
      return this.internalDatasetList && this.internalDatasetList.length > 0;
    },
    currentDataset() {
      return this.$store.state.datasets.currentDataset;
    },
    currentDatasetID() {
      return this.currentDataset ? this.currentDataset.id : 0;
    },
    internalDatasetList: {
      get() {
        return this.$store.state.datasets.datasetList;
      },
      set(value) {
        this.$store.commit('datasets/setDatasetList', value);
      },
    },
    currentProject() {
      return this.$store.state.projects.currentProject;
    },
  },
  watch: {
    async currentProject() {
      await this.getDatasets();
      // Asynchronously get annotation set counts
      const promises = this.internalDatasetList.map((d) => this.getAnnotationSetsForDataset(d).then((refreshedAnnotationSets) => {
        this.setDatasetAnnotationSets(d, refreshedAnnotationSets);
      }));
      await this.getDatasetsDetails();
      await Promise.all(promises);
    },
  },
  created() {
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'tasks/updateImportTaskStatus') {
        if (mutation.payload.data.status === 'complete') {
          const taskID = mutation.payload.taskID;
          if (state.tasks.importTasks[taskID].dataset_id) {
            this.refreshDatasetCard(this.getDatasetWithID(state.tasks.importTasks[taskID].dataset_id));
          }
        }
      }
      if (mutation.type === 'tasks/updateExportTaskStatus') {
        const taskID = mutation.payload.taskID;
        if (mutation.payload.data.status === 'complete') {
          if (state.tasks.exportTasks[taskID].dataset_id) {
            const dsID = state.tasks.exportTasks[taskID].dataset_id;
            this.$store.commit('notifications/updateDatasetsNotifications', {
              dataset_id: dsID,
              key: 'downloads',
              value: this.$store.state.notifications.datasets[dsID] ? this.$store.state.notifications.datasets[dsID]['downloads'] + 1 : 1,
            });
          }
        }
      }
    });
  },
  unmounted() {
    this.abortAllSignals();
  },
  beforeUnmount() {
    this.unsubscribe();
  },
  async mounted() {
    await this.getDatasets();
    // Asynchronously get annotation set counts
    const promises = this.internalDatasetList.map((d) => this.getAnnotationSetsForDataset(d).then((refreshedAnnotationSets) => {
      this.setDatasetAnnotationSets(d, refreshedAnnotationSets);
    }));
    await this.getDatasetsDetails();
    await Promise.all(promises);
  },
  methods: {
    setDatasetAnnotationSets(d, sets) {
      const index = this.getDatasetIndex(d);
      this.$store.commit('datasets/setDatasetAnnotationSets', { index, sets });
    },
    setDatasetLabels(d, labels) {
      const index = this.getDatasetIndex(d);
      this.$store.commit('datasets/setDatasetLabels', { index, labels });
    },
    setDataset(dataset) {
      const index = this.getDatasetIndex(dataset);
      this.$store.commit('datasets/setDataset', { index, dataset });
    },
    async getDatasetsDetails() {
      const currentProject = this.$store.state.projects.currentProject;
      if (currentProject) {
        const params = {
          project_id: currentProject.id,
          get_num_images: true,
          get_labels: true,
          get_num_labels: true,
          get_annotation_sets: true,
          get_groups: true,
          get_parked: true,
        };
        await this.dataConnect.getDatasetList(params)
          .then((resp) => {
            if (!resp.error) {
              const temp = resp.result;

              const shallowDatasets = JSON.parse(JSON.stringify(this.internalDatasetList));
              shallowDatasets.forEach((e, i) => {
                temp[i].annotation_sets?.forEach((set, j) => {
                  temp[i].annotation_sets[j].num_images = shallowDatasets[i].annotation_sets[j] ? shallowDatasets[i].annotation_sets[j].num_images : 0;
                  temp[i].annotation_sets[j].num_annotations = shallowDatasets[i].annotation_sets[j] ? shallowDatasets[i].annotation_sets[j].num_annotations : 0;
                });
                shallowDatasets[i].annotation_sets = temp[i].annotation_sets;
                shallowDatasets[i].numImages = temp[i].numImages ? temp[i].numImages : 0;
                shallowDatasets[i].labels = temp[i].labels ? temp[i].labels : [];
                shallowDatasets[i].numLabels = temp[i].numLabels ? temp[i].numLabels : 0;
                shallowDatasets[i].groups = temp[i].groups ? temp[i].groups : [];
              });

              this.internalDatasetList = shallowDatasets;
            }
          })
          .catch((error) => {
          // TODO: handle error
            console.log(error);
          });
      }
    },
    async getDatasets() {
      if (this.currentProject) {
        const params = {
          project_id: this.currentProject.id,
          get_parked: true,
        };
        this.isFetching = true;
        await this.dataConnect.getDatasetList(params)
          .then((resp) => {
            if (resp.error) {
              this.internalDatasetList = [];
              this.isFetching = false;
            } else {
              let temp = resp.result;
              temp = temp.map((e) => {
                e.annotation_sets = [];
                e.numImages = null;
                e.labels = null;
                e.numLabels = null;
                e.groups = null;
                return e;
              });

              this.internalDatasetList = temp;

              if (this.modalDataset) {
                const ds = this.internalDatasetList.find((e) => e.id === this.modalDataset.id);
                if (ds) {
                  this.modalDataset = ds;
                }
              }
              this.isFetching = false;
            }
          })
          .catch((error) => {
          // TODO: handle error
            this.isFetching = false;
            console.log(error);
          });
      } else {
        this.internalDatasetList = [];
      }
    },
    handleOpenAnnotationCopy(set, d) {
      this.copyAnnotationSet = set;
      this.copyAnnotationSetDataset = d;
      this.openCopyAnnSetModal();
    },
    handleSelectDataset(d) {
      this.$store.commit('datasets/setCurrentDataset', d);
    },
    openConfirmDeleteDatasetModal(d) {
      this.confirmMessage = `Are you sure you want to move dataset "${d.name}" to recycle bin?`;
      this.confirmMessageHeader = 'Remove Dataset';
      this.buttonText = "Delete";
      this.buttonClass = "button-delete";
      this.toDelete = d;
      this.confirmFunction = () => this.deleteDataset();
      this.$refs.confirmModal.showModal();
    },
    async deleteDataset() {
      const param = {
        name: this.toDelete.name,
        id: this.toDelete.id,
      };
      await this.dataConnect.deleteDataset(param)
        .then(() => {
          const dsList = [...this.internalDatasetList];
          const toRemove = this.internalDatasetList.find((e) => e.id === this.toDelete.id);
          dsList.splice(dsList.indexOf(toRemove), 1);
          this.internalDatasetList = dsList;
        })
        .catch((err) => {
          console.log(err);
        });
      this.toDelete = null;
    },
    openConfirmDeleteAnnsetModal(s) {
      this.confirmMessage = `Are you sure you want to move annotation set "${s.name}" to recycle bin?`;
      this.confirmMessageHeader = 'Remove Annotation Set';
      this.buttonText = "Remove";
      this.buttonClass = "button-delete";
      this.toDelete = s;
      this.confirmFunction = () => this.deleteAnnset();
      this.$refs.confirmModal.showModal();
    },
    async deleteAnnset() {
      await this.dataConnect.deleteAnnotationSet({ annotation_set_id: this.toDelete.id })
        .then((data) => {
          if (!data.error) {
            const d = this.internalDatasetList.find((e) => e.id === this.toDelete.dataset_id);
            this.handleAnnotationSetDeletedEvent(d);

            this.toDelete = null;
          }
        })
        .catch((error) => {
          console.log('Failed to retrieve sets:', error);
        });
    },
    openConfirmDeleteGroupModal(group) {
      this.confirmMessage = `Are you sure you want to delete group with name "${group.name}"?`;
      this.confirmMessageHeader = 'Delete Group';
      this.buttonText = "Delete";
      this.buttonClass = "button-delete";
      this.toDelete = group;
      this.confirmFunction = () => this.deleteGroup();
      this.$refs.confirmModal.showModal();
    },
    async deleteGroup() {
      await this.dataConnect.deleteGroup({ group_id: this.toDelete.id })
        .then((data) => {
          if (!data.error) {
            const d = this.internalDatasetList.find((e) => e.id === this.toDelete.dataset_id);
            this.modalDataset = null;
            this.refreshDatasetCard(d);

            this.toDelete = null;
          }
        })
        .catch((error) => {
          console.log('Failed to retrieve sets:', error);
        });
    },
    openCreateModal() {
      this.$refs.createDatasetModal.showModal();
    },
    openUpdateModal(d) {
      this.modalDataset = d;
      this.$refs.updateDatasetModal.showModal();
    },
    closeCreateModal() {
      this.$refs.createDatasetModal.closeModal();
    },
    closeUpdateModal() {
      this.modalDataset = null;
      this.$refs.updateDatasetModal.closeModal();
    },
    openExportModal(d) {
      this.impexDataset = d;
      this.$nextTick(() => {
        this.$refs.datasetExportModal.showModal();
      });
    },
    openUploadModal(d) {
      this.modalDataset = d;
      this.showUploadModal = true;
    },
    openImportAnnsetModal(d) {
      this.modalDataset = d;
      this.$refs.importAnnsetModal.showModal();
    },
    handleStartExport(d, task) {
      if (task) {
        this.$store.commit('tasks/addExportTask', {
          taskID: task.id, docker_task_id: task.id, type: task.name, dataset_id: d.id,
        });
      }
    },
    closeExportModal() {
      this.$refs.datasetExportModal.closeModal();
    },
    async updateDataset(params, d) {
      await this.dataConnect.updateDataset(params)
        .then((data) => {
          if (data.result) {
            this.refreshDatasetCard(d);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    handleCopyDatasetClicked(d) {
      this.modalDataset = d;
      this.showCopyModal = true;
    },
    clearCopyAnnset() {
      this.copyAnnotationSetDataset = null;
    },
    async handleCopyAnnSetCompleted() {
      await this.refreshDatasetInfo(this.copyAnnotationSetDataset);
    },
    handleCreateAnnotationSet(dataset) {
      this.modalDataset = dataset;
      this.$nextTick(() => {
        this.$refs.createAnnotationSetModal.showModal();
      });
    },
    handleUpdateAnnotationSet(dataset, annset) {
      this.modalDataset = dataset;
      this.modalAnnotationSet = annset;
      this.$nextTick(() => {
        this.$refs.updateAnnotationSetModal.showModal();
      });
    },
    async handleAnnotationSetCreatedEvent(dataset) {
      this.modalDataset = null;
      await this.refreshDatasetCard(dataset);
    },
    async handleAnnotationSetUpdatedEvent(dataset) {
      this.modalDataset = null;
      this.modalAnnotationSet = null;
      await this.refreshDatasetCard(dataset);
    },
    async handleAnnotationSetCreatedErrorEvent(error) {
      if (error.message.includes("limit reached")) {
        this.confirmMessage = `Not enough credits to perform this operation.`;
        this.confirmMessageHeader = 'Insufficient Credits';
        this.buttonText = "";
        this.$refs.confirmModal.showModal();
      }
    },
    async handleAnnotationSetDeletedEvent(dataset) {
      this.modalDataset = null;
      await this.refreshDatasetCard(dataset);
    },
    handleCreateLabel(dataset) {
      this.modalDataset = dataset;
      this.$nextTick(() => {
        this.$refs.createLabelsModal.showModal();
      });
    },
    handleUpdateLabel(dataset) {
      this.refreshLabels(dataset);
    },
    async handleLabelCreatedEvent(dataset) {
      this.modalDataset = null;
      await this.refreshDatasetCard(dataset);
    },
    async handleLabelDeletedEvent(label, dataset) {
      this.confirmMessage = `Are you sure you want to delete label '${label.name}'. All annotations with this label name will be deleted.`;
      this.confirmNote = `Only use this feature if you have no annotations with this label name, or you want to remove all annotations with this label name.`;
      this.confirmMessageHeader = 'Delete Label';
      this.buttonText = "Confirm";
      this.buttonClass = "";
      this.confirmFunction = async () => {
        await this.dataConnect.deleteLabel({ label_id: label.id })
          .then(async () => {
            await this.refreshDatasetCard(dataset);
          })
          .catch((error) => {
            console.log('Failed to delete label:', error);
          });
      };

      this.$refs.confirmModal.showModal();
    },
    async refreshDatasetCard(d) {
      await this.refreshDatasetInfo(d);
      this.refreshAnnotationSets(d);
      this.refreshLabels(d);
    },
    async refreshDatasetInfo(d) {
      const newDatasetInfo = await this.getDatasetInfo(d);
      this.setDataset(newDatasetInfo);
    },
    async refreshAnnotationSets(d) {
      const refreshedAnnotationSets = await this.getAnnotationSetsForDataset(d);
      this.setDatasetAnnotationSets(d, refreshedAnnotationSets);
    },
    async refreshLabels(d) {
      const refreshedLabels = await this.getLabelListForDataset(d);
      this.setDatasetLabels(d, refreshedLabels);
    },
    openCopyAnnSetModal() {
      this.$refs.copyAnnSetModal.showModal();
    },
    async getDatasetInfo(dataset) {
      const resp = await this.dataConnect.getDatasetById({
        dataset_id: dataset.id,
        get_num_images: true,
        get_num_labels: true,
        get_num_annotations: true,
        get_groups: true,
      })
        .catch((error) => {
          console.log('Failed to retrieve dataset:', error);
        });
      if (!resp || resp.error || !resp.result) {
        return null;
      }
      return resp.result;
    },
    async getAnnotationSetsForDataset(dataset) {
      const controller = new AbortController();
      this.controllers.push(controller);

      const resp = await this.dataConnect.getAnnotationSets({
        dataset_id: dataset.id,
        get_num_annotations: true,
        get_num_images: true,
        get_labels: true,
      }, controller.signal)
        .catch((error) => {
          console.log('Failed to retrieve sets:', error);
        });
      if (!resp || resp.error || !resp.result) {
        return [];
      }
      return resp.result;
    },
    async getLabelListForDataset(dataset) {
      const resp = await this.dataConnect.getLabelList({ dataset_id: dataset.id })
        .catch((error) => {
          console.log('Failed to retrieve labels:', error);
        });
      if (!resp || resp.error || !resp.result) {
        return [];
      }
      return resp.result;
    },
    getDatasetIndex(dataset) {
      return this.internalDatasetList.indexOf(this.internalDatasetList.find((d) => d.id === dataset.id));
    },
    getDatasetWithID(datasetID) {
      return this.internalDatasetList.find((d) => d.id === datasetID);
    },
    handleOpenDatasetAccessManagement(dataset) {
      this.modalDataset = dataset;
      this.$nextTick(() => {
        this.$refs.manageDatasetAccessModal.showModal();
      });
    },
    async handleAccessUpdated(d) {
      await this.refreshDatasetInfo(d)
        .then(() => {
          if (this.modalDataset) {
            const ds = this.internalDatasetList.find((e) => e.id === this.modalDataset.id);
            if (ds) {
              this.modalDataset = ds;
            }
          }
        });
      this.refreshAnnotationSets(d);
      this.refreshLabels(d);
    },
    async getExportList() {
      await this.dataConnect.getExportList()
        .catch((error) => {
          console.log('Failed to retrieve sets:', error);
        });
    },
    openDownloadsModal(d) {
      this.modalDataset = d;
      this.$refs.datasetDownloadsModal.showModal();
    },
    async handleCancelDockerTask(dataset, taskID) {
      await this.dataConnect.deleteDockerTask({ docker_task_id: taskID })
        .then(async (data) => {
          if (data.result) {
            await this.refreshDatasetInfo(dataset);
          }
        });
    },
    async createDataset(params) {
      this.closeCreateModal();
      const updatedParams = {
        ...params,
        project_id: this.currentProject.id,
      };
      const createDatasetResp = await this.dataConnect.createDataset(updatedParams)
        .catch((error) => {
          console.log(error);
        });
      if (!createDatasetResp || createDatasetResp.error || !createDatasetResp.result) {
        if (createDatasetResp.error.message.includes("limit reached")) {
          this.confirmMessage = `You haved reached the limit for number of datasets. Delete unused datasets or upgrade your package.`;
          this.confirmMessageHeader = 'Limit Reached';
          this.buttonText = "";
          this.$refs.confirmModal.showModal();
        } else if (createDatasetResp.error.message !== "") {
          this.confirmMessage = createDatasetResp.error.message.charAt(0).toUpperCase() + createDatasetResp.error.message.slice(1);
          this.confirmMessageHeader = 'Error';
          this.buttonText = "";
        }
        return;
      }

      const dsList = [...this.internalDatasetList];
      createDatasetResp.result.groups = [];
      dsList.unshift(createDatasetResp.result);
      this.internalDatasetList = dsList;

      // Create owner dataset access for newly created dataset
      // await this.dataConnect.addDatasetAccess({
      //   username: this.$store.state.user.user.username,
      //   dataset_id: createDatasetResp.result.id,
      //   role: 'owner',
      // })
      //   .then(() => {
      //     const dsList = [...this.internalDatasetList];
      //     dsList.unshift(createDatasetResp.result);
      //     this.internalDatasetList = dsList;
      //   })
      //   .catch((error) => {
      //     console.log(error);
      //   });
    },
    async handleToggleFreeze(d, isFrozen) {
      await this.dataConnect.freezeDataset({
        dataset_id: d.id,
        is_frozen: isFrozen,
      })
        .then(async (data) => {
          if (data.result) {
            await this.refreshDatasetCard(d);
          }
        });
    },
    async handleUploadError(data) {
      this.confirmMessage = data.confirmMessage;
      this.confirmMessageHeader = data.confirmMessageHeader;
      this.buttonText = "";
      this.$refs.confirmModal.showModal();
    },
    openCreateGroupsModal(d) {
      this.modalDataset = d;
      this.$refs.createGroupsModal.showModal();
    },
    async handleNavigateToGallery(d) {
      this.handleSelectDataset(d);
      await this.$nextTick();
      this.$router.push({ path: '/datasets/gallery/main' });
    },
    handleOpenGenerateTokenModal(d) {
      this.modalDataset = d;
      this.$refs.generateTokenModal.showModal();
    },
    handleOpenConfMatrix(d, set) {
      this.handleSelectDataset(d);
      this.$store.commit('datasets/setConfMatrixTargetSet', set);
      this.$refs.confusionMatrixModal.showModal();
    },
    handleParkDatasetEvent(d) {
      this.confirmMessage = `Are you sure you want to park dataset '${d.name}'. This would prevent any action to this dataset, including viewing or training. Parked images are charged at half the original image storage cost`;
      this.confirmNote = `Reactivating the parked dataset in less than 3 days will incur a fee (Total cost is equal to original image storage cost).`;
      this.confirmMessageHeader = 'Park Dataset';
      this.buttonText = "Confirm";
      this.buttonClass = "";
      this.confirmFunction = () => this.parkDataset(d);
      this.$refs.confirmModal.showModal();
    },
    async parkDataset(d) {
      await this.dataConnect.parkDataset({
        dataset_id: d.id,
      })
        .then((data) => {
          if (!data.error) {
            this.getDatasets();
            this.getDatasetsDetails();
          }
          this.$store.commit('notifications/updateNotifications', true);
        })
        .catch((e) => console.log(e));
    },
    handleUnparkDatasetEvent(d) {
      const dateDiff = Number(Math.abs(new Date() - new Date(d.parked_at)) / 8.64e7).toFixed(2);
      this.confirmMessage = `Are you sure you want to activate dataset '${d.name}'? This will resume activities for this dataset. All images are charged at the original image storage cost.`;
      if (dateDiff <= 3.0) {
        this.confirmNote = `Duration: ${dateDiff} days. Reactivating this dataset now will incur a fee for every day of parked storage at half the original image storage cost.`;
      } else {
        this.confirmNote = null;
      }
      this.confirmMessageHeader = 'Activate Dataset';
      this.buttonText = "Confirm";
      this.buttonClass = "";
      this.confirmFunction = () => this.unparkDataset(d);
      this.$refs.confirmModal.showModal();
    },
    async unparkDataset(d) {
      await this.dataConnect.unparkDataset({
        dataset_id: d.id,
      })
        .then((data) => {
          if (!data.error) {
            this.getDatasets();
            this.getDatasetsDetails();
          }

          this.$store.commit('notifications/updateNotifications', true);
        })
        .catch((e) => console.log(e));
    },
    abortAllSignals() {
      this.controllers.forEach((controller) => {
        controller.abort();
      });
    },
  },
};
</script>

<style lang="scss" scoped>

.controls-bar {
  display: flex;
  flex-direction: row;
  padding: 10px 20px 10px 20px;
  align-items: center;
  border-bottom: 1px solid #c9c9c9;
  @include themify() {
    background: themed('color-ribbon');
  }
  justify-content: space-between;
}
.manager-card-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(370px, 1fr));
  grid-auto-rows: 440px;
  width: 100%;
  gap: 16px;
  padding: 16px;
  overflow-y: auto;
  flex: 1 1 auto;
}

.list-card {
  height: 100%;
  width: 100%;
}

.empty-state {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  height: 100%;
}

#loading-visualization {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
  background: transparent;
}

.lds-ring {
  /* change color here */
  color: var(--color-primary);
}
.lds-ring,
.lds-ring div {
  box-sizing: border-box;
}
.lds-ring {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid currentColor;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: currentColor transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

</style>
