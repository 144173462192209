<template>
  <div ref="containerRef" class="image-canvas">
    <img
      v-if="imageId && !cancelImgLoad && !crop"
      ref="imageRef"
      class="image-canvas__image"
      :style="zoomShiftTransform"
      :src="imageSrc"
      :fetchpriority="fetchPriority"
      alt=""
      @load="handleImageLoad"
      @error="handleImageLoadError"
    >
    <img
      v-else-if="imageId && !cancelImgLoad && crop"
      ref="imageRef"
      class="image-canvas__image"
      :style="zoomShiftTransform"
      :src="croppedImageSrc"
      alt=""
      @load="handleImageLoad"
      @error="handleCroppedImageLoadError"
    >
    <v-skeleton-loader
      v-if="!imageLoaded"
      type="image"
      class="image-loading"
    />
    <canvas
      v-if="imageId && !cancelImgLoad"
      v-show="showCanvas"
      ref="canvas"
      class="image-canvas__canvas"
      :style="zoomShiftTransform"
      @mousemove="handleMouseMove"
    />
  </div>
</template>

<script setup>
import useFetchImage from '@/composables/useFetchImage.js';
import {
  ref, watch, computed, onMounted, onBeforeUnmount, nextTick, toRefs,
} from 'vue';

const props = defineProps({
  imageId: {
    type: Number,
    default: null,
  },
  boxes: {
    type: Array,
    default: () => [],
  },
  segmentations: {
    type: Array,
    default: () => [],
  },
  showCanvas: {
    type: Boolean,
    default: true,
  },
  zoom: {
    type: Number,
    default: 2,
  },
  zoomShift: {
    type: Array,
    default: null,
  },
  crop: {
    type: Object,
    default: null,
  },
  cropPadding: {
    type: Number,
    default: 0,
  },
  imageQuality: {
    type: String,
    default: 'thumbnail',
  },
  fetchPriority: {
    type: String,
    default: 'auto',
  },
});
const {
  imageId, boxes, segmentations, showCanvas, zoom, zoomShift, crop, cropPadding, imageQuality, fetchPriority,
} = toRefs(props);

const emit = defineEmits(['update:hoveredBox']);

const canvas = ref(null);
const imageRef = ref(null);
const containerRef = ref(null);
const imageSrc = ref(null);
const croppedImageSrc = ref('');
const resizeObserver = ref(null);
const containerSize = ref({
  width: 0,
  height: 0,
});
const renderedImageDimensions = ref({
  width: 0,
  height: 0,
  top: 0,
  left: 0,
  bottom: 0,
  right: 0,
});
const cancelImgLoad = ref(false);
const imageLoaded = ref(false);

const {
  data: imageData, isFetching, abort, canAbort, getImage, getThumbnail,
} = useFetchImage();

function getCroppedImage(imageSrc, crop) {
  const image = new Image();
  image.fetchpriority = fetchPriority.value;

  image.onload = () => {
    // Create a dynamic canvas
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');

    // Set canvas dimensions
    canvas.width = image.naturalWidth;
    canvas.height = image.naturalHeight;

    // Draw the cropped image onto the canvas
    context.drawImage(
      image,
      (crop.x - cropPadding.value) * image.naturalWidth,
      (crop.y - cropPadding.value) * image.naturalHeight,
      (crop.w + (2 * cropPadding.value)) * image.naturalWidth,
      (crop.h + (2 * cropPadding.value)) * image.naturalHeight,
      0,
      0,
      canvas.width,
      canvas.height,
    );

    drawAnnotations();

    // Get canvas image as Blob, then set as source
    canvas.toBlob((blob) => {
      croppedImageSrc.value = URL.createObjectURL(blob);
    }, 'image/png', 1.0);
  };

  image.src = imageSrc;
}

function drawAnnotations() {
  if (canvas.value) {
    const ctx = canvas.value.getContext("2d");
    ctx.clearRect(0, 0, canvas.value.width, canvas.value.height);
    boxes.value.forEach((box) => {
      drawBox(box);
    });
    segmentations.value.forEach((segmentation) => {
      drawSegmentation(segmentation);
    });
  }
}

function drawBox(box) {
  if (imageId.value) {
    // const canvas = this.$refs.canvas;
    const ctx = canvas.value.getContext("2d");

    // Box dimensions
    let x; let y; let width; let
      height;
    if (crop.value) {
      const totalCropWidth = (crop.value.w + (2 * cropPadding.value));
      const totalCropHeight = (crop.value.h + (2 * cropPadding.value));
      x = Math.round(((box.x - (crop.value.x - cropPadding.value)) / totalCropWidth) * canvas.value.width);
      y = Math.round(((box.y - (crop.value.y - cropPadding.value)) / totalCropHeight) * canvas.value.height);
      width = Math.round((box.w / totalCropWidth) * canvas.value.width);
      height = Math.round((box.h / totalCropHeight) * canvas.value.height);
    } else {
      x = Math.round((box.x) * canvas.value.width);
      y = Math.round((box.y) * canvas.value.height);
      width = Math.round(box.w * canvas.value.width);
      height = Math.round(box.h * canvas.value.height);
    }

    // Set Box styling
    const boxLineWidth = 2;
    ctx.lineWidth = boxLineWidth;
    ctx.strokeStyle = "#EFC15F";
    if (box.color) {
      ctx.strokeStyle = box.color;
    }
    if (box.opacity) {
      ctx.globalAlpha = box.opacity;
    } else {
      ctx.globalAlpha = 1;
    }

    // Draw the bounding box
    ctx.beginPath();
    ctx.rect(x, y, width, height);
    ctx.stroke();

    // Draw the label
    // if (result.label || (result.id && result.time)) {
    //   ctx.font = "24px Arial";
    //   let currentWidth = 0;
    //   if (result.label) {
    //     currentWidth += drawLabelText(ctx, result, x, y, boxLineWidth);
    //   }
    //   if (result.score) {
    //     currentWidth += drawLabelScore(ctx, result, x + currentWidth, y, boxLineWidth);
    //   }
    // }
  }
}

function drawSegmentation(seg) {
  if (imageId.value) {
    const ctx = canvas.value.getContext("2d");

    // Set Segmentation styling
    const borderLineWidth = 1;
    ctx.lineWidth = borderLineWidth;
    ctx.strokeStyle = hexToRgba("#EFC15F", 1);
    ctx.fillStyle = hexToRgba("#EFC15F", 0.5);
    if (seg.color) {
      ctx.strokeStyle = addOpacityToHSL(seg.color, 1);
      ctx.fillStyle = addOpacityToHSL(seg.color, 0.5);
    }

    // Draw each polygon
    seg.polygons.forEach((polygon) => {
      ctx.beginPath();
      for (const vertex of polygon) {
        ctx.lineTo(vertex[0] * canvas.value.width, vertex[1] * canvas.value.height);
      }
      ctx.closePath();
      ctx.stroke();
      ctx.fill();
    });
  }
}

function handleImageLoad() {
  try {
    if (containerRef.value) {
      containerSize.value.width = containerRef.value.clientWidth;
      containerSize.value.height = containerRef.value.clientHeight;
    }
    if (canvas.value && imageRef.value) {
      renderedImageDimensions.value = getRenderedImageDimensions(imageRef.value);
      canvas.value.style.left = `${renderedImageDimensions.value.left}px`;
      canvas.value.style.top = `${renderedImageDimensions.value.top}px`;
      canvas.value.style.width = `${renderedImageDimensions.value.width}px`;
      canvas.value.style.height = `${renderedImageDimensions.value.height}px`;

      canvas.value.width = parseInt(canvas.value.style.width);
      canvas.value.height = parseInt(canvas.value.style.height);
    } else {
      return;
    }

    boxes.value.forEach((box) => {
      drawBox(box);
    });
    segmentations.value.forEach((seg) => {
      drawSegmentation(seg);
    });
  } catch (err) {
    console.error(err);
    handleImageLoadError(err);
  }

  if ((imageId.value && !crop.value) || (imageId.value && crop.value && croppedImageSrc.value)) {
    imageLoaded.value = true;
  }
}

// Get dimensions of the rendered display image
// This is not equal to the element dimensions when using 'object-fit: contain'
function getRenderedImageDimensions(image) {
  const {
    width, height, naturalWidth, naturalHeight,
  } = image;
  const naturalRatio = naturalWidth / naturalHeight;
  const elementRatio = width / height;
  const [renderedWidth, renderedHeight] = naturalRatio >= elementRatio ? [width, width / naturalRatio] : [height * naturalRatio, height];

  return {
    width: Math.round(renderedWidth),
    height: Math.round(renderedHeight),
    top: Math.round((height - renderedHeight) / 2),
    left: Math.round((width - renderedWidth) / 2),
    bottom: Math.round(((height - renderedHeight) / 2) + height),
    right: Math.round(((width - renderedWidth) / 2) + width),
  };
}

function hexToRgba(hex, opacity) {
  hex = hex.replace(/^#/, ''); // Remove the # symbol
  const bigint = parseInt(hex, 16);
  const r = (bigint >> 16) & 255;
  const g = (bigint >> 8) & 255;
  const b = bigint & 255;
  return `rgba(${r}, ${g}, ${b}, ${opacity})`;
}

function addOpacityToHSL(colorString, opacity) {
  // Parse the HSL color string to get its components
  const colorValues = colorString.match(/(\d+(\.\d+)?)%?/g).map(parseFloat);
  const hue = colorValues[0];
  const saturation = colorValues[1];
  const lightness = colorValues[2];

  // Convert to HSLA format
  const hslaColor = `hsla(${hue}, ${saturation}%, ${lightness}%, ${opacity})`;

  return hslaColor;
}

function handleImageLoadError(err) {
  if (imageId.value) {
    console.error(err);
    cancelImgLoad.value = true;
    imageLoaded.value = false;
  }
}

function handleCroppedImageLoadError(err) {
  if (croppedImageSrc.value) {
    console.error(err);
    cancelImgLoad.value = true;
    imageLoaded.value = false;
  }
}

function handleResize() {
  handleImageLoad();
}

function findMouseOverBox(mouseX, mouseY) {
  let hoveredBox;
  boxes.value.forEach((box) => {
    if (mouseX > box.x
            && mouseX < box.x + box.w
            && mouseY > box.y
            && mouseY < box.y + box.h) {
      hoveredBox = box;
    }
  });
  return hoveredBox;
}

function handleMouseMove(e) {
  const bounds = e.target.getBoundingClientRect();
  const x = (e.clientX - bounds.left) / bounds.width;
  const y = (e.clientY - bounds.top) / bounds.height;
  const box = findMouseOverBox(x, y);
  if (box) {
    box.clientX = bounds.left + box.x * bounds.width;
    box.clientY = bounds.top + box.y * bounds.height;
    box.clientWidth = box.w * bounds.width;
    box.clientHeight = box.h * bounds.height;
  }
  emit('update:hoveredBox', box);
}

watch(imageData, (data) => {
  if (data) {
    const src = URL.createObjectURL(data);
    if (crop.value) {
      getCroppedImage(src, crop.value);
    } else {
      imageSrc.value = src;
    }
  }
});

watch(boxes, () => {
  drawAnnotations();
}, { deep: true });

watch(segmentations, () => {
  drawAnnotations();
}, { deep: true });

watch(imageId, (id) => {
  cancelImgLoad.value = false;
  imageLoaded.value = false;

  getImage(id);
});

const zoomShiftTransform = computed(() => {
  if (zoomShift.value) {
    return { transform: `scale(${zoom.value}) translateX(${zoomShift.value[0]}px) translateY(${zoomShift.value[1]}px)` };
  }
  return null;
});

onMounted(() => {
  if (imageId.value) {
    if (imageQuality.value === 'image') {
      getImage(imageId.value);
    } else {
      getThumbnail(imageId.value);
    }
  }

  nextTick(() => {
    resizeObserver.value = new ResizeObserver(handleResize);
    if (imageRef.value) {
      resizeObserver.value.observe(imageRef.value);
    }
  });
});

onBeforeUnmount(() => {
  if (canAbort.value) {
    abort();
  }

  if (imageRef.value) {
    resizeObserver.value?.unobserve(imageRef.value);
  } else {
    resizeObserver.value?.disconnect();
  }
});

</script>

<style lang="scss" scoped>
.image-canvas {
  user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -o-user-select: none;

  position: relative;
  width: 100%;
  height: 100%;

  &__canvas {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
  }

  &__image {
    position: relative;
    max-height: 100%;
    height: 100%;
    width: 100%;
    object-fit: contain;
    margin: auto;
  }
}

:deep(.v-skeleton-loader.image-loading) {
  position: absolute;
  height: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0.75;
  z-index: 2;
  .v-skeleton-loader__image {
    height: 100%;
  }
}

</style>
