<template>
  <v-image
    ref="imageEl"
    :config="configImg"
    @pointerclick="handleObjectClick($event)"
  />
</template>

<script setup>
import {
  ref, watch, computed, onMounted, toRefs,
} from 'vue';
import DatastoreConnect from '@/assets/js/DatastoreFunctions/datastore-interface';
import { decode } from 'fast-png';
import { useWebWorkerFn } from '@vueuse/core';

const props = defineProps({
  identifier: {
    type: String,
    default: '',
  },
  imageDimensions: {
    type: Object,
    default: null,
  },
  annotation: {
    type: Object,
    default: null,
  },
  isSelected: {
    type: Boolean,
    default: false,
  },
  opacity: {
    type: Number,
    default: 1,
  },
  pointerCoordinates: {
    type: Object,
    default: null,
  },
  labels: {
    type: Array,
    default: () => [],
  },
});
const {
  identifier,
  imageDimensions,
  annotation,
  isSelected,
  opacity,
  pointerCoordinates,
  labels,
} = toRefs(props);

const emit = defineEmits(['new-coordinates', 'shape-selected', 'image-loaded']);

const node = ref(null);
const mask = ref(null);
const maskCtx = ref(null);
const mouseOver = ref(false);
const imageEl = ref(null);

const configImg = computed(() => ({
  identifier: identifier.value,
  fill: 'transparent',
  x: 0,
  y: 0,
  image: mask.value,
  width: imageDimensions.value.width,
  height: imageDimensions.value.height,
  draggable: false,
  opacity: displayOpacity.value,
}));

const displayOpacity = computed(() => {
  if (isSelected.value) {
    return 1;
  } else if (mouseOver.value) {
    return 0.5;
  }
  return 1;
});

watch(imageDimensions, (dims) => {
  if (dims) {
    if (annotation.value) {
      fetchMask();
    }
  }
}, { immediate: true });

watch(pointerCoordinates, (coords) => {
  if (!coords || !maskCtx.value) return;

  const pos = coords;
  const p = maskCtx.value.getImageData(pos.x, pos.y, 1, 1).data;
  mouseOver.value = p[3] === 255;
});

watch(annotation, (anno) => {
  if (anno) {
    fetchMask();
  }
});

onMounted(() => {
  node.value = imageEl.value.getNode();
});

async function fetchMask() {
  const dataConnect = new DatastoreConnect();
  const blob = await dataConnect.getAnnotationFile(annotation.value.id, true);
  if (blob) {
    const arrayBuffer = await blob.arrayBuffer();
    const decodedImage = await decode(arrayBuffer);
    const renderedBlob = await convertBlobToRGBImageWorkerFn(decodedImage.data, imageDimensions.value.width, imageDimensions.value.height, JSON.parse(JSON.stringify(labels.value)));
    const image = new Image();
    image.onerror = (e) => { console.log(e); };
    image.onload = () => {
      mask.value = image;
      URL.revokeObjectURL(image.src);
    };
    image.src = URL.createObjectURL(renderedBlob);
  }
}

async function convertBlobToRGBImage(uint8Array, width, height, labels) {
  const canvas = new OffscreenCanvas(width, height);
  const ctx = canvas.getContext("2d");
  const imageData = ctx.createImageData(width, height);

  function parseColor(value) {
    if (typeof value === 'number' && value > 0 && value < 0xFFFFFFFF && Number.isInteger(value)) {
      // uint32
      const hexString = value.toString(16);
      return `#${hexString.padStart(8, '0').toUpperCase()}`;
    } else if (value.startsWith('0x') || value.startsWith('#')) {
      // hex
      let hexString = value;
      if (hexString.startsWith('0x')) {
        hexString = `#${hexString.slice(2)}`;
      }
      return hexString;
    } else if (value.startsWith('hsl')) {
      let [h, s, l] = value.match(/\d+/g).map(Number);
      s /= 100;
      l /= 100;

      function hueToRgb(p, q, t) {
        if (t < 0) t += 1;
        if (t > 1) t -= 1;
        if (t < 1 / 6) return p + (q - p) * 6 * t;
        if (t < 1 / 2) return q;
        if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
        return p;
      }

      let r; let g; let
        b;

      if (s === 0) {
        r = g = b = l; // achromatic (gray)
      } else {
        const q = l < 0.5 ? l * (1 + s) : l + s - l * s;
        const p = 2 * l - q;
        r = hueToRgb(p, q, h / 360 + 1 / 3);
        g = hueToRgb(p, q, h / 360);
        b = hueToRgb(p, q, h / 360 - 1 / 3);
      }

      // Convert to HEX and return
      const toHex = (x) => {
        const hex = Math.round(x * 255).toString(16);
        return hex.length === 1 ? `0${hex}` : hex;
      };

      return `#${toHex(r)}${toHex(g)}${toHex(b)}`;
    } else {
      return value.toString();
    }
  }

  function getColorByIndexFromScale(index) {
    const hue = (index * 137.508) % 360; // use golden angle approximation
    return hslToHex(hue, 70, 50);
  }

  function hslToHex(h, s, l) {
    l /= 100;
    const a = s * Math.min(l, 1 - l) / 100;
    const f = (n) => {
      const k = (n + h / 30) % 12;
      const color = l - a * Math.max(Math.min(k - 3, 9 - k, 1), -1);
      return Math.round(255 * color).toString(16).padStart(2, '0'); // convert to Hex and prefix "0" if needed
    };
    return `#${f(0)}${f(8)}${f(4)}`;
  }

  for (let i = 0; i < uint8Array.length; i++) {
    const value = uint8Array[i];
    const index = i * 4;

    if (value > 0) {
      const targetLabel = labels.find((lbl) => lbl.index === value);
      let hexColor;
      if (targetLabel && targetLabel.color) {
        hexColor = parseColor(targetLabel.color);
      } else {
        hexColor = getColorByIndexFromScale(value);
      }
      const r = parseInt(hexColor.substring(1, 3), 16);
      const g = parseInt(hexColor.substring(3, 5), 16);
      const b = parseInt(hexColor.substring(5, 7), 16);
      imageData.data[index] = r;
      imageData.data[index + 1] = g;
      imageData.data[index + 2] = b;
      imageData.data[index + 3] = 100;
    } else {
      imageData.data[index] = 255;
      imageData.data[index + 1] = 255;
      imageData.data[index + 2] = 255;
      imageData.data[index + 3] = 0;
    }
  }

  ctx.putImageData(imageData, 0, 0);
  const blob = await canvas.convertToBlob();
  return blob;
}

function handleObjectClick(e) {
  if (!pointerCoordinates.value || !maskCtx.value) return;

  const pos = pointerCoordinates.value;
  const p = maskCtx.value.getImageData(pos.x, pos.y, 1, 1).data;
  if (p[3] === 255) {
    e.cancelBubble = true;
    emit('shape-selected');
  }
}

const { workerFn: convertBlobToRGBImageWorkerFn, workerStatus, workerTerminate } = useWebWorkerFn(
  convertBlobToRGBImage,
  { timeout: 10000, localDependencies: [] },
);

</script>

<style>

</style>
