<template>
  <div class="disable-background">
    <div class="loader-modal" :style="{ width: width, height: height }">
      <div class="loader-modal__loader loader" />
      <span class="loader-modal__text">{{ text }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PageLoader',
  props: {
    text: {
      type: String,
      default: '',
    },
    width: {
      type: String,
      default: undefined,
    },
    height: {
      type: String,
      default: undefined,
    },
  },

};
</script>

<style lang="scss" scoped>
.disable-background{
  position: fixed;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.625);
  outline: none;
  z-index: 100;
}

.loader-modal {
  position:absolute;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  padding: 20px;
  top:50%;
  left:50%;
  transform:translate(-50%, -50%);
  border-radius: 6px;
  z-index: 1000;
  @include themify() {
    background-color: themed('modal-background');
  }

  &__loader {
    width: 60px !important;
    height: 60px !important;
    margin-left: auto;
    margin-right: auto;
  }

  &__text {
    font-weight: 600;
  }
}
</style>
