import { getFileContents } from '@/assets/js/utils.js';

async function parseAnnotationJsonFile(file, labels) {
  const annotations = [];
  const meta = {};
  let fileContents = '';
  let json = {};
  try {
    fileContents = await getFileContents(file);
    json = JSON.parse(fileContents);
  } catch (error) {
    return { annotations, meta };
  }
  json.objects.forEach(async (obj) => {
    annotations.push({
      label_index: labels.indexOf(obj.label),
      label_name: obj.label,
      score: obj.score,
      x: obj.bbox.xmin,
      y: obj.bbox.ymin,
      w: obj.bbox.xmax - obj.bbox.xmin,
      h: obj.bbox.ymax - obj.bbox.ymin,
      type: 'box',
      polygon: '',
    });
  });

  if (json.device_id) {
    meta.source = json.device_id;
  } else {
    const filenameParts = file.name.split('_');
    if (filenameParts.length > 1) {
      meta.source = filenameParts[0];
    }
  }
  if (json.timestamp) {
    meta.timestamp = json.timestamp;
  }
  if (json.gps) {
    meta.gps = {};
    meta.gps.lat = json.gps.lat;
    meta.gps.lon = json.gps.lon;
  }
  return { annotations, meta };
}

export default {
  parseAnnotationJsonFile,
};
