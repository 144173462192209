<template>
  <div class="comparison-table">
    <div class="table-header">
      <span class="title">Threshold Comparison</span>
    </div>
    <div v-if="tableEntries.length > 0" class="table">
      <div class="t-row header-row">
        <div
          v-for="(h, i) in headers"
          :key="`header-cell-${i}`"
          class="cell"
        >
          {{ h }}
        </div>
      </div>
      <div
        v-for="(entry, i) in tableEntries"
        :key="`entry-row-${i}`"
        class="t-row"
        :class="{ 'selected' : currentEntry === i}"
        @click="handleSelectEntry(i)"
      >
        <div
          v-for="(val, j) in entry"
          :key="`entry-cell-${j}`"
          class="cell"
        >
          {{ val }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "ComparisonTableDynamic",
  props: {
    annotationSets: {
      type: Array,
      default: () => [],
    },
    validations: {
      type: Array,
      default: () => [],
    },
  },
  emits: { 'select': null },
  data() {
    return {
      currentEntry: 0,
    };
  },
  computed: {
    tableEntries() {
      const entries = [];
      this.validations.forEach((v) => {
        const obj = {
          "Target": this.annotationSets.find((e) => e.id === v.target_set_id)["name"],
          "IoU": v.iou,
          "Confidence": v.confidence,
          "TP": v.total_tp,
          "FN": v.total_fn,
          "FP": v.total_class_fp + v.total_loc_fp,
          "Accuracy": v.accuracy.toFixed(2),
          "Precision": v.precision.toFixed(2),
          "Recall": v.recall.toFixed(2),
          // "mACC": v["iou"]["mACC"] ? Number(v["iou"]["mACC"]).toFixed(2) : 0,
          // "mAP": v["iou"]["mAP"] ? Number(v["iou"]["mAP"]).toFixed(2) : 0,
          // "mAR": v["iou"]["mAR"] ? Number(v["iou"]["mAR"]).toFixed(2) : 0,
        };
        entries.push(obj);
      });
      return entries;
    },
    headers() {
      return Object.keys(this.tableEntries[0]);
    },
  },
  mounted() {
    if (this.validations.length > 0) {
      this.handleSelectEntry(0);
    }
  },
  methods: {
    handleSelectEntry(i) {
      this.currentEntry = i;
      this.$emit('select', this.validations[i]);
    },
  },
};
</script>

<style lang="scss" scoped>

.comparison-table {
  height: fit-content;
  width: calc(100% - 20px);
  box-shadow: 0 0 10px rgba(0,0,0,0.1), 0 0 5px rgba(0,0,0,0.25);
  border-radius: 8px;
  margin: 10px;
  padding-top: 10px;
  @include themify() {
    background: themed('card-color');
    color: themed('comparison-table-text-color');
  }
}

.table-header {
  display: flex;
  align-items: center;

}
.title {
  display: flex;
  align-items: center;
  margin-left: 15px;
  margin-right: 15px;
  font-size: 1.3rem;
  font-weight:700;
}

.table {
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  width: calc(100% - 20px);
  margin: 8px;

  .t-row {
    display: flex;
    flex-direction: row;
    cursor: default;
    .cell {
      flex: 1 1 100px;
    }
  }

  .t-row + .t-row {
    margin-top: 5px;
  }

  .t-row:not(.header-row):hover, .selected {
    cursor: pointer;
    border-radius: 4px;
    @include themify() {
      background: themed('comparison-table-row-highlight');
    }
  }

  .header-row {
    font-weight: 700;
    @include themify() {
      color: themed('comparison-table-header-text-color');
    }
    border-bottom: 0.5px solid rgba(96, 95, 95, 0.5);
  }

  // .header-row .cell {
  //   @include themify {
  //     background-color: themed('annotation-table-column-header');
  //     color: themed('annotation-table-header-color');
  //   }
  //   font-weight:700;
  // }

  .cell {
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 4px;
    overflow: hidden;
    white-space: wrap;
  }
}
</style>
