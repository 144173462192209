<template>
  <div class="settings scrollbar">
    <span class="header">Emulate User</span>

    <div class="row mt-4">
      <label class="label required">Target User</label>
      <div class="value">
        <input
          v-model="username"
          class="select"
          type="text"
          :class="{ 'error' : errorUsername }"
          disabled
        >
      </div>
    </div>
    <div class="row mt-4">
      <label class="label">Password</label>
      <div class="value">
        <input
          v-model="password"
          class="select"
          type="password"
          :class="{ 'error' : errorPassword }"
        >
      </div>
    </div>
    <div class="row mt-4">
      <label class="label">2FA Code</label>
      <div class="value">
        <input
          v-model="authCode"
          class="select"
          type="text"
          step="1"
          :class="{ 'error' : errorCode }"
        >
      </div>
      <IconButton
        class="refresh-icon"
        :icon="'refresh'"
        :width="24"
        :height="24"
        :title="`Send Code`"
        :type="''"
        @click.stop="sendCode"
      />
    </div>
    <div v-if="message" class="row mt-2">
      <label class="label" />
      <div
        class="value"
        :class="{ 'error-text': isError, 'success-text' : !isError }"
      >
        {{ message }}
      </div>
    </div>
    <div class="row mt-3 footer">
      <button class="button button-sm rounded" @click.stop="openConfirmModal()">Apply</button>
      <span @click.stop="$router.go(-1)">Cancel</span>
    </div>
  </div>
  <ConfirmModal
    ref="confirmModal"
    :messageHeader="confirmMessageHeader"
    :message="confirmMessage"
    :buttonText="buttonText"
    @confirmed="apply"
  />
</template>

<script>
import DatastoreConnect from '@/assets/js/DatastoreFunctions/datastore-interface';
import ConfirmModal from '@/components/ConfirmModal.vue';
import IconButton from '@/components/IconButton.vue';

export default {
  name: 'EmulateUser',
  components: {
    ConfirmModal,
    IconButton,
  },
  data() {
    return {
      dsConn: new DatastoreConnect(this.$store.state.enterpriseServerUrl),
      username: null,
      errorUsername: null,
      password: '',
      errorPassword: null,
      authCode: '',
      errorCode: null,
      message: '',
      backendError: false,
      confirmMessage: '',
      confirmMessageHeader: '',
      buttonText: '',
    };
  },
  computed: {
    isError() {
      return this.errorUsername || this.errorPassword || this.errorCode || this.backendError;
    },
    user() {
      return this.$store.state.user.user;
    },
    isSuper() {
      return this.user.role === 'super';
    },
    isAdmin() {
      return this.user.role === 'admin';
    },
  },
  created() {
    if (!this.isAdmin && !this.isSuper) {
      this.$router.push('/login');
    }
    if (this.$route.query.name) {
      this.username = this.$route.query.name;
    }
  },
  methods: {
    sendCode() {
      this.dsConn.send2FA({ username: this.user.username });
    },
    resetErrors() {
      this.errorPassword = false;
      this.errorCode = false;
      this.backendError = false;
    },
    openConfirmModal() {
      this.resetErrors();
      if (!this.username) {
        this.message = 'Missing Username.';
        this.errorUsername = true;
        return;
      }
      if (!this.password) {
        this.errorPassword = true;
        this.message = 'Missing Password.';
        return;
      }
      this.confirmMessage = `Logging in as user '${this.username}' ?`;
      this.confirmMessageHeader = 'Emulate user';
      this.buttonText = "Confirm";
      this.$refs.confirmModal.showModal();
    },
    async apply() {
      const params = {
        username: this.user.username,
        password: this.password,
        code: this.authCode,
        emulate_user: this.username,
      };
      await this.dsConn.signIn(params)
        .then((data) => {
          if (data.result) {
            this.message = '';
            this.backendError = false;
            const token = data.result.token;
            this.$store.commit('user/setToken', token);
            this.performUserCheckAndLogin(data.result.username);
          } else {
            this.message = data.error.message;
            this.backendError = true;
          }
        })
        .catch((e) => {
          this.message = e;
        });
    },
    async performUserCheckAndLogin(u) {
      const userResp = await this.dsConn.checkForUser({
        username: u,
      });

      const userObj = { ...userResp.result };
      userObj.role = userResp.result.role;

      this.$store.commit('user/setUser', userObj);

      if (userObj.role === 'super') {
        this.$router.replace({ path: '/user' });
      } else {
        this.$router.replace({ path: '/projects' });
      }
      this.$store.commit('appVersion/setAllowDebug', 'yes');
    },
  },
};
</script>

<style lang="scss" scoped>
.settings {
  display: flex;
  flex-direction: column;
  width: 730px;
  min-width: 400px;
  height: 100%;
  padding: 30px;
  overflow-y: auto;
}

.header {
  font-size: 1.2rem;
  font-weight: 700;
}

.row {
  flex-wrap: nowrap !important;
}

.row > .label {
  display: flex;
  justify-content: end;
  align-items: center;
  width: 200px;
  text-align: right;
  font-size: 0.9rem;
  font-weight: 600;
  color: rgba(grey, 0.9);

  &.required::after {
    content: '*';
    font-size: 14px;
    font-weight: 700;
    padding-left: 1px;
    @include themify() {
      color: themed('color-primary');
    }
  }
}

.row > .value {
  flex: 1 1 auto;
  font-size: 0.9rem;
  margin-left: 20px;
}

.select {
  width: 100%;
  height: 30px;
  border-radius: 5px;
  font-size: 1rem;
  padding: 3px 10px;
  align-self: center;
  -webkit-appearance: auto;
  background: rgba(#ffffff, 0.8);
  @include themify() {
    border: 1px solid themed('form-input-border');
  }

}

.select:focus {
  @include themify() {
    border: 1px solid themed('color-primary');
    box-shadow:  0 0 0 1px themed('color-primary'), 0 0 3px themed('color-primary-300');
  }
  outline: 2px solid transparent;
  outline-offset: 2px;
}

input.error , select.error {
  @include themify() {
    border: 1px solid themed('color-red') !important;
    box-shadow:  0 0 0 1px themed('color-red'), 0 0 3px themed('color-red') !important;
  }

  &:focus, &:focus-visible {
    @include themify() {
      border: 1px solid themed('color-red') !important;
      box-shadow:  0 0 0 1px themed('color-red'), 0 0 3px themed('color-red') !important;
    }
  }
}

.error-text {
  color: $color-red-500;
}

.success-text {
  color: $color-success;
}

.footer {
  display: flex;
  justify-content: flex-end;
  button {
    width: fit-content
  }
  span {
    color: $color-link;
    text-decoration: underline;
    cursor: pointer;
    width: fit-content;
  }
}

.refresh-icon {
  @include themify() {
    color: themed('body-text-color-secondary');
  }
}

.refresh-icon:hover {
  @include themify() {
    color: themed('icon-color-primary');
  }
}
</style>
