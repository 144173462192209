<template>
  <div class="displays">
    <div v-if="loading" id="loading-visualization">
      <div class="lds-ring"><div /><div /><div /><div /></div>
    </div>
    <SplitterGroup
      id="splitter-group-1"
      class="splitter-group-main"
      direction="vertical"
    >
      <template v-for="i in layout.length" :key="`splitter-group-1-panel-${i}`">
        <SplitterPanel
          :id="`splitter-group-1-row-${i}`"
          :min-size="20"
          class="splitter-panel _row"
        >
          <SplitterGroup
            :id="`splitter-group-1-row-${i}-group`"
            class="splitter-group"
            direction="horizontal"
          >
            <template v-for="j in layout[i - 1].length" :key="`splitter-group-1-column-${i}-panel-${j}`">
              <SplitterPanel
                :id="`splitter-group-1-column-${i}-panel-${j}`"
                :min-size="20"
                class="splitter-panel"
              >
                <VisualizationContainer
                  :location="[i - 1, j - 1]"
                  :paneValue="layout[i - 1][j - 1]"
                  :hasOnlyOneDisplay="hasOnlyOneDisplay"
                  @close="handleClose(i - 1, j - 1)"
                  @drop="(e) => emit('drop', e)"
                  @visualization-selected="(e) => $emit('visualization-selected', e)"
                />
              </SplitterPanel>
              <SplitterResizeHandle
                v-if="j != layout[i - 1].length"
                :id="`splitter-group-1-column-${i}-resize-handle-${j}`"
                class="splitter-handle _vertical"
              />
            </template>
          </SplitterGroup>
        </SplitterPanel>
        <SplitterResizeHandle
          v-if="i !== layout.length"
          :id="`splitter-group-1-resize-handle-${i}`"
          class="splitter-handle _horizontal"
        />
      </template>
    </SplitterGroup>
  </div>
</template>

<script setup>
import VisualizationContainer from '@/components/DatasetComponent/AnnotationTool/VisualizationContainer.vue';
import { SplitterGroup, SplitterPanel, SplitterResizeHandle } from 'radix-vue';
import { computed } from 'vue';

const emit = defineEmits(['update:layout', 'drop', 'visualization-selected']);

const { loading, layout } = defineProps({
  loading: {
    type: Boolean,
    default: false,
  },
  layout: {
    type: Array,
    default: () => [[null]],
  },
});

function handleClose(row, col) {
  const newLayout = JSON.parse(JSON.stringify(layout));

  if (layout[row] && layout[row][col] !== undefined) {
    newLayout[row].splice(col, 1); // Remove the value at the specified row and column
  }

  // Check if the subarray is empty, and remove the subarray if it is
  if (newLayout[row].length === 0) {
    newLayout.splice(row, 1); // Remove the subarray entirely
  }

  emit('update:layout', newLayout);
}

const hasOnlyOneDisplay = computed(() => {
  if (layout?.length === 1 && layout?.[0]?.length === 1) {
    return true;
  }
  return false;
});

</script>

<style lang="scss" scoped>

.splitter-group-main {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  flex: 1 1 auto;
}

.splitter-group {
  width: 100%;
  flex: 1 1 auto;
}

.splitter-panel {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  &._row {
    width: 100%;
  }

  &._column {
    height: 100%;
  }
}

.splitter-handle {
  background: rgba(0,0,0,0.175);
  transition-delay: 0s;

  &[data-state="hover"] {
    background: var(--color-primary);
    outline: solid 1px var(--color-primary);
    z-index: 2;
    transition-delay: 0.3s;
  }

  &[data-state="drag"] {
    background: var(--color-primary);
  }

  &._vertical {
    width: 2px;
    height: 100%;
  }

  &._horizontal {
    height: 2px;
    width: 100%;
  }
}

.displays {
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  min-height: 0;

  // display: grid;
  // grid-template-columns: repeat(2, 1fr);
  // grid-template-rows: repeat(2, 1fr);
  // gap: 10px;
}

#loading-visualization {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
  background: rgba(0, 0, 0, 0.25);
}

.lds-ring {
  /* change color here */
  color: var(--color-primary);
}
.lds-ring,
.lds-ring div {
  box-sizing: border-box;
}
.lds-ring {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid currentColor;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: currentColor transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

</style>
