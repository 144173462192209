<template>
  <Modal
    ref="exportDatasetModal"
    :title="'Export Dataset'"
    @closed="clearInput"
  >
    <template #modal-main>
      <div class="settings container">
        <div class="row mt-3 radio-group full-width-selector">
          <input
            id="option-detection"
            v-model="exportMode"
            type="radio"
            name="mode-selector"
            :value="'detection'"
          ><label for="option-detection">Detection</label>
          <input
            id="option-segmentation"
            v-model="exportMode"
            type="radio"
            name="mode-selector"
            :value="'segmentation'"
          ><label for="option-segmentation">Segmentation</label>
        </div>
        <div class="row mt-3">
          <div class="col-12 settings__control-group">
            <label>Exporter Format</label>
            <select
              v-if="exportMode === 'detection'"
              id="type-select"
              v-model="type"
              class="select"
            >
              <option disabled :value="null">Select Detection Export Format</option>
              <option
                v-for="(option, i) in detectionTypes"
                :key="`export-type-${i}`"
                :value="option.type"
                :disabled="option.type.includes('labelbox')"
              >
                {{ option.name }}
              </option>
            </select>
            <select
              v-if="exportMode === 'segmentation'"
              id="type-select"
              v-model="type"
              class="select"
            >
              <option disabled :value="null">Select Segmentation Export Format</option>
              <option
                v-for="(option, i) in segmentationTypes"
                :key="`export-type-${i}`"
                :value="option.type"
                :disabled="option.type.includes('labelbox')"
              >
                {{ option.name }}
              </option>
            </select>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-6 settings__control-group align-items-start">
            <label>Annotation Sets</label>
            <AnnotationSetsRadioSelect
              v-model="annset"
              :annotationSets="exportDataset ? exportDataset.annotation_sets : null"
            />
          </div>
        </div>
        <v-select
          v-if="allSequences && allSequences.length > 0"
          v-model="selectedSequences"
          clearable
          label="Sequences"
          :items="allSequences"
          item-title="name"
          item-value="id"
          multiple
          variant="outlined"
          class="mt-3"
        />
        <div class="row mt-3">
          <div class="col-6 settings__control-group">
            <label>Mode</label>
            <select
              v-model="exportSecondaryMode"
              class="select"
            >
              <option :value="''" disabled>Select a Mode</option>
              <option v-if="selectedSequences.length === 0" :value="'dataset'" :disabled="exportDataset.numImages >= 10000">Dataset</option>
              <option v-if="allSequences && allSequences.length > 0" :value="'sequences'">Sequences Only</option>
              <option v-if="selectedSequences.length === 0" :value="'annotations'">Annotations Only</option>
              <option v-if="selectedSequences.length === 0" :value="'images'">Images URLs Only</option>
            </select>
          </div>
        </div>
        <div v-if="message" class="row mt-3">
          <div class="col-12 settings__control-group">
            <div class="result error">
              <span>{{ message }}</span>
            </div>
          </div>
        </div>
        <div class="row mt-3 align-items-flex-end">
          <div class="col-4" />
          <div class="col-8 action-buttons">
            <button
              class="button button-sm button-secondary"
              @click="closeModal"
            >
              Cancel
            </button>
            <a :href="`download_zip_s3?${downloadParams}`" download>
              <button
                class="button button-sm"
                :disabled="!type || !exportSecondaryMode"
                @click="closeModal"
              >
                Export
              </button>
            </a>
          </div>
        </div>
      </div>
    </template>
  </Modal>
</template>

<script>

import Modal from '../../Modal.vue';
import DatastoreConnect from '../../../assets/js/DatastoreFunctions/datastore-interface';
import { inputSanitizer, changeSanitizer } from '../../../assets/js/utils';
import AnnotationSetsRadioSelect from '../AnnotationSetsRadioSelect.vue';

export default {
  name: 'DatasetExportModal',
  components: {
    Modal,
    AnnotationSetsRadioSelect,
  },
  props: {
    datasets: {
      type: Array,
      default: () => [],
    },
    exportDataset: {
      type: Object,
      default: () => {},
    },
    sequences: {
      type: Array,
      default: () => [],
    },
  },
  emits: {
    'export-started': null,
  },
  data() {
    return {
      message: "",
      type: null,
      imgFolder: "",
      annset: {},
      detectionTypes: [
        {
          name: 'Darknet',
          type: 'darknet',
        },
        {
          name: 'ModelPack',
          type: 'modelpack',
        },
        // {
        //   name: 'Labelbox (Under Development)',
        //   type: 'labelbox',
        // },
      ],
      segmentationTypes: [
        {
          name: 'Darknet Segment',
          type: 'darknet-segment',
        },
        {
          name: 'ModelPack Segment',
          type: 'modelpack',
        },
        {
          name: 'Segment Mask',
          type: 'mask',
        },
        // {
        //   name: 'CVAT (.json)',
        //   type: 'cvat-json',
        // },
        // {
        //   name: 'Labelbox Segment (Under Development)',
        //   type: 'segment-labelbox',
        // },
      ],
      exportMode: "detection",
      exportSecondaryMode: "dataset",
      selectedSequences: [],
      allSequences: null,
    };
  },
  computed: {
    isDebugMode() {
      return this.$store.state.debugMode;
    },
    downloadParams() {
      let params = `dataset_id=${this.exportDataset.id}&export_type=${this.exportSecondaryMode}`;
      if (Object.keys(this.annset).length > 0) {
        params += `&annotation_set_id=${this.annset.id}`;
      }
      if (this.type) {
        params += `&format=${this.type}`;
      }
      if (this.selectedSequences) {
        const p = new URLSearchParams({ sequences: JSON.stringify(this.selectedSequences) });
        params += `&${p.toString()}`;
      }
      return params;
    },
  },
  watch: {
    exportDataset: {
      deep: true,
      immediate: true,
      handler(d) {
        if (d) {
          this.exportName = d?.name;
        }
      },
    },
    exportMode: {
      handler() {
        this.type = null;
      },
    },
    selectedSequences: {
      handler(s) {
        if (s && s.length > 0) {
          this.exportSecondaryMode = 'sequences';
        } else {
          this.exportSecondaryMode = '';
        }
      },
    },
    sequences() {
      if (this.sequences && this.sequences.length > 0) {
        this.selectedSequences = this.sequences.map((s) => s.id);
        this.exportSecondaryMode = 'sequences';
      }
    },
  },
  mounted() {
    this.dataConnect = new DatastoreConnect(this.$store.state.enterpriseServerUrl);
  },
  methods: {
    inputSanitizer,
    changeSanitizer,
    async showModal() {
      if (this.exportDataset.numImages >= 10000) {
        this.exportSecondaryMode = 'annotations';
      }
      this.getSequencesForDataset();
      this.$refs.exportDatasetModal.showModal();
    },
    closeModal() {
      this.clearInput();
      this.$refs.exportDatasetModal.closeModal();
    },
    clearInput() {
      this.exportName = "";
      this.type = null;
      this.source = null;
      this.annset = {};
      this.message = "";
      this.exportMode = "detection";
      this.exportSecondaryMode = "dataset";
      this.selectedSequences = [];
    },
    async getSequencesForDataset() {
      const params = {
        dataset_id: this.exportDataset.id,
      };
      await this.dataConnect.getSequences(params)
        .then((data) => {
          if (data.result) {
            this.allSequences = data.result;
          }
        })
        .catch((e) => console.log(e));
    },
    async apply() {
      if (!this.type) {
        this.message = "Please select a type to convert to";
        return;
      }

      if (this.type.includes('labelbox')) {
        this.message = "Feature currently under development";
        return;
      }

      if (Object.keys(this.annset).length === 0) {
        this.message = "Please select an annotation set";
        return;
      }

      const params = {
        type: this.type,
        annotation_set_id: this.annset.id,
        auto_remove: true,
      };
      if (this.isDebugMode) {
        params['auto_remove'] = false;
      }

      await this.dataConnect.startExport(params)
        .then((data) => {
          if (data.result) {
            this.$emit('export-started', data.result);
            this.closeModal();
          }
        })
        .catch((e) => console.log(e));
    },
  },
};
</script>

<style lang="scss" scoped>
.settings {
  padding: 0.75rem 3rem;
  min-width: 520px;
}

.result {
  display: flex;
  width: 100%;
  height: 100%;
  border-radius: 5px;
  font-size: 1rem;
  font-weight:600;
  padding: 10px;
  align-items: center;
  justify-content: center;
}

.error {
  @include themify() {
    background: rgba(182, 94, 94, 0.5) ;
    color: themed('color-error')
  }
}

.save-img-check {
  flex-direction: row !important;

  label {
    width: fit-content;
    margin-right: 10px;
  }

  input {
    height: 18px;
    margin-top: 2px;
    width: 18px;
  }
}

.action-buttons {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  gap: 8px;
}
.radio-group {
  display: inline-block;
  margin: 20px;
  border-radius: 5px;
  overflow: hidden;
  @include themify() {
    border: solid 1px themed('color-primary');
  }
}

.radio-group:focus-within, .radio-group:active {
  @include themify() {
    box-shadow:0 0 5px themed('color-primary');
  }
}

.radio-group input[type="radio"] {
  position: absolute;
  opacity: 0;
  pointer-events: none;
}

.radio-group input[type="radio"] + label {
  min-width: 6rem;
  max-width: 50%;
  text-align: center;
  display: inline-block;
  cursor: pointer;
  font-weight: bold;
  font-size: 1rem;
  line-height: 1.25;
  padding: 5px 20px;
  transition: all 0.4s ease;
  box-shadow: 0 0 3px 1px rgba(0, 0, 0, 0.25) inset;
  @include themify() {
    color: themed('color-primary');
    background: themed('color-white-100');
  }
}

.radio-group input[type="radio"]:not(:disabled):hover + label {
  @include themify() {
    background: themed('color-primary-200');
    color: themed('primary-text-color');
  }
}

.radio-group input[type="radio"]:checked + label,
.radio-group input[type="radio"]:checked:hover + label {
  opacity: 1;
  z-index: 1;
  box-shadow: 0 0 6px -2px rgba(0, 0, 0, 0.25), 0 0 6px 2px rgba(0, 0, 0, 0.25);
  @include themify() {
    background: themed('color-primary');
    color: themed('primary-text-color');
  }
}

.radio-group.full-width-selector {
  display: flex;
  width: 100%;
  margin: 0;
}

.radio-group.full-width-selector input[type="radio"] + label {
  min-width: 1px;
  padding: 3px 10px;
  flex: 1 1 auto;
}
</style>
