<template>
  <div class="main-page scrollbar">
    <div class="settings">
      <span class="header">Organization Information</span>
      <div class="row mt-3">
        <div class="label">Name</div>
        <div class="value">{{ name }}</div>
      </div>
      <div class="row mt-2">
        <div class="label">Street</div>
        <div class="value">{{ street }}</div>
      </div>
      <div class="row mt-2">
        <div class="label">City</div>
        <div class="value">{{ city }}</div>
      </div>
      <div class="row mt-2">
        <div class="label">Country</div>
        <div class="value">{{ country }}</div>
      </div>
      <div class="row mt-2">
        <div class="label">Contact Name</div>
        <div class="value">{{ contactName }}</div>
      </div>
      <div class="row mt-2">
        <div class="label">Contact Email</div>
        <div class="value">{{ contactEmail }}</div>
      </div>
      <div class="row mt-2">
        <div class="label">Contact Phone</div>
        <div class="value">{{ contactPhone }}</div>
      </div>
      <div v-if="isSuper || isAdmin" class="row mt-3 footer">
        <button class="button button-sm rounded" @click.stop="navigateToEditOrg">Edit</button>
      </div>
    </div>
  </div>
</template>

<script>
import DatastoreConnect from '@/assets/js/DatastoreFunctions/datastore-interface';

export default {
  name: 'OrganizationSettings',
  components: {
  },
  data() {
    return {
      dsConn: new DatastoreConnect(this.$store.state.enterpriseServerUrl),
      users: [
      ],
      name: '',
      street: '',
      city: '',
      country: '',
      contactName: '',
      contactEmail: '',
      contactPhone: '',
      allRoles: [],
    };
  },
  computed: {
    user() {
      return this.$store.state.user.user;
    },
    isAdmin() {
      return this.user && this.user.role === 'admin';
    },
    isSuper() {
      return this.user && this.user.role === 'super';
    },
  },
  created() {
    this.getOrg();
  },
  methods: {
    async getOrg() {
      await this.dsConn.getOrg({ organization_id: this.user.organization_id })
        .then((data) => {
          if (data.result) {
            this.name = data.result.name;
            this.street = data.result.street;
            this.city = data.result.city;
            this.country = data.result.country;
            this.contactName = data.result.contact_name;
            this.contactEmail = data.result.contact_email;
            this.contactPhone = data.result.contact_phone;
          } else {
            this.message = data.error.message;
          }
        })
        .catch((e) => { this.message = e; });
    },
    navigateToEditOrg() {
      this.$router.push({ name: '/user/organization/editorg', query: { org: this.user.organization_id } });
    },
  },
};
</script>

<style lang="scss" scoped>
.main-page {
  width: 100%;
  height: 100%;
  overflow-y: auto;
}

.settings {
  display: flex;
  flex-direction: column;
  width: 730px;
  height: 100%;
  padding: 30px;
}

.header {
  font-size: 1.2rem;
  font-weight: 700;
}
.user-table {
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  width: 1200px;
  padding-top: 20px;
  padding-left: 30px;
  padding-right: 30px;

  &__header {
    font-size: 0.9rem;
  }

  &__table {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 15px 15px 15px 15px;

    .header-row {
      font-weight: 700;
      border-bottom: 2px solid rgba(96, 95, 95, 0.5);
    }

    .footer-row {
      min-height: 10px;
      border-bottom: 2px solid rgba(96, 95, 95, 0.5);
    }
  }

  &__username {
    display: flex;
    flex-direction: column;
    justify-content: center;

    span:nth-child(2) {
      font-size: 0.9rem;
      padding-left: 20px;
      color: rgba(grey, 0.9)
    }
  }

  &__package {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &__access {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

}

.row {
  flex-wrap: nowrap !important;
}

.row > .label {
  display: flex;
  justify-content: end;
  align-items: center;
  width: 200px;
  text-align: right;
  font-size: 1.0rem;
  font-weight: 600;
  color: rgba(grey, 1.0)
}

.row > .value {
  flex: 1 1 auto;
  font-size: 1.0rem;
  margin-left: 20px;
}

.footer {
  display: flex;
  justify-content: flex-start;
  button {
    margin-left: 30px;
    width: fit-content
  }
}
</style>
