<template>
  <div class="controls-bar">
    <div />
    <button v-if="permissions && permissions.trainer_write" class="button button-sm" @click="openStartValidatorModal">Create</button>
  </div>
  <div v-if="notEmpty && permissions && (permissions.trainer_read || permissions.trainer_read)" class="validator-card-container scrollbar">
    <ValidatorCard
      v-for="(d, i) in filteredInternalDatasets"
      :key="i"
      :dataset="d"
      :permissions="permissions"
      @create-validate-session="openStartValidatorModal(d)"
      @info-clicked="handleSessionInfoClick($event, d)"
      @terminate-task="openConfirmStopValidation"
      @delete-task="openConfirmDeleteValidation"
    />
  </div>
  <div v-else-if="loading" class="empty-state">
    <InlineLoader
      :width="'60px'"
      :height="'60px'"
      :border="'12px'"
    />
  </div>
  <div
    v-else
    class="empty-state"
  >
    <svg width="60%" height="60%">
      <use href="@/assets/img/empty.svg#emptyStateSVG" />
    </svg>
    <div class="empty-state__message">
      <h3>No validation sessions found</h3>
    </div>
  </div>
  <StartValidatorModal
    ref="startValidatorModal"
    :datasets="internalDatasets"
    :selectedDataset="datasetToCreateSession"
    :selectedTarget="targetToCreateSession"
    @closed="closeStartValidatorModal"
    @validate-session-started="handleValidateSessionCreated"
  />
  <!-- <ValidateSessionDetailsModal
    ref="validateSessionDetailsModal"
    :dataset="selectedDetailsDataset"
    :session="selectedValidateSession"
    @stop-validate="openConfirmStopValidateSessionModal(selectedValidateSession)"
  /> -->
  <ConfirmModal
    ref="confirmModal"
    :messageHeader="confirmMessageHeader"
    :message="confirmMessage"
    :buttonClass="'button-delete'"
    :buttonText="buttonText"
    @confirmed="confirmFunction"
  />
</template>

<script>
import DatastoreConnect from '@/assets/js/DatastoreFunctions/datastore-interface';
import ConfirmModal from '@/components/ConfirmModal.vue';
import StartValidatorModal from '@/components/ValidationPage/StartValidatorModal.vue';
import ValidatorCard from '@/components/ValidationPage/ValidatorCard.vue';
import InlineLoader from '@/components/InlineLoader.vue';

export default {
  name: "ValidatorManagement",
  components: {
    ConfirmModal,
    StartValidatorModal,
    ValidatorCard,
    InlineLoader,
  },
  props: {
  },
  emits: [
    'update-list',
  ],
  data() {
    return {
      dataConnect: new DatastoreConnect(this.$store.state.enterpriseServerUrl),
      selectedDetailsDataset: null,
      datasetToCreateSession: null,
      targetToCreateSession: null,
      internalDatasets: null,
      interval: null,
      confirmMessage: null,
      confirmMessageHeader: null,
      toDelete: null,
      confirmFunction: null,
      buttonText: 'Delete',
      permissions: null,
    };
  },
  computed: {
    currentProject() {
      return this.$store.state.projects.currentProject;
    },
    filteredInternalDatasets() {
      return this.internalDatasets.filter((d) => d.validate_sessions && d.validate_sessions.length > 0);
    },
    notEmpty() {
      return this.internalDatasets && this.filteredInternalDatasets.length > 0;
    },
    datasetIds() {
      if (this.notEmpty) {
        return this.internalDatasets.reduce((acc, curr) => {
          acc.push(curr.id);
          return acc;
        }, []);
      } else {
        return [];
      }
    },
  },
  watch: {
    currentProject() {
      this.getDatasets();
    },
    // internalDatasets: {
    //   async handler(datasets) {
    //     if (datasets && datasets.length > 0) {
    //       await Promise.all(datasets.map(async (dataset) => {
    //         dataset.validate_sessions = await this.getValidateSessionList(dataset.id);
    //       }));
    //     }
    //     this.loading = false;
    //   },
    // },
  },
  async created() {
    await this.getDatasets();
    if (this.$route.query.dataset_id && this.$route.query.target) {
      this.datasetToCreateSession = this.internalDatasets.find((e) => e.id === parseInt(this.$route.query.dataset_id));
      this.targetToCreateSession = this.datasetToCreateSession.annotation_sets?.find((e) => e.id === parseInt(this.$route.query.target));
      this.$nextTick(() => {
        this.$refs.startValidatorModal.showModal();
      });
      this.$router.replace({ 'query': null });
    }
  },
  beforeUnmount() {
    this.stopInterval();
  },
  mounted() {
    this.startInterval();
    this.getProjectAccessForSingleUser();
  },
  methods: {
    async getProjectAccessForSingleUser() {
      await this.dataConnect.getProjectAccessForSingleUser({
        project_id: this.currentProject.id,
        username: this.$store.state.user.user.username,
      })
        .then((data) => {
          if (data.result) {
            this.permissions = data.result;
          }
        })
        .catch((e) => console.log(e));
    },
    handleValidatorCreated() {
      this.getDatasets();
    },
    stopInterval() {
      clearInterval(this.interval);
      this.interval = null;
    },
    startInterval() {
      this.interval = setInterval(() => {
        this.internalDatasets.forEach(async (dataset) => {
          dataset.validate_sessions = await this.getValidateSessionList(dataset.id);
        });
      }, 30000);
    },
    toDataset() {
      this.$router.push({ path: '/datasets' });
    },
    async getDatasets() {
      this.loading = true;
      if (this.currentProject) {
        const params = {
          project_id: this.currentProject.id,
          get_annotation_sets: true,
        };
        const resp = await this.dataConnect.getDatasetList(params)
          .catch((error) => {
            // TODO: handle error
            console.log(error);
          });
        if (resp.error) {
          this.internalDatasets = [];
          this.loading = false;
        } else {
          let temp = resp.result;
          if (!params.get_annotation_sets) {
            temp = temp.map((e) => { e.annotation_sets = []; return e; });
          }
          await Promise.all(temp.map(async (dataset) => {
            dataset.validate_sessions = await this.getValidateSessionList(dataset.id);
          }));
          this.internalDatasets = temp;
          this.loading = false;
        }
      } else {
        this.internalDatasets = [];
        this.loading = false;
      }
    },
    async getValidateSessionList(dataset_id) {
      const resp = await this.dataConnect.getValidateSessionList({ dataset_id, reverse: true })
        .catch((error) => {
          console.log('Failed to retrieve validate sessions:', error);
        });
      if (!resp || resp.error || !resp.result) {
        return [];
      }
      return resp.result;
    },
    openStartValidatorModal(d) {
      this.datasetToCreateSession = d;
      this.targetToCreateSession = null;
      this.$refs.startValidatorModal.showModal();
    },
    closeStartValidatorModal() {
      this.datasetToCreateSession = null;
      this.targetToCreateSession = null;
    },
    async handleValidateSessionCreated() {
      this.getDatasets();
    },
    async handleSessionInfoClick(session, d) {
      this.$router.push({ path: '/validator/details', query: { dataset_id: d.id, session_id: session.id } });
    },
    openConfirmStopValidation(session) {
      this.confirmMessage = `Are you sure you want to terminate this validate session?`;
      this.confirmMessageHeader = 'Terminate Session';
      this.toDelete = session;
      this.buttonText = 'Terminate';
      this.$refs.confirmModal.showModal();
      this.confirmFunction = () => {
        this.handleStopValidateSessions([session.id]);
      };
    },
    openConfirmDeleteValidation(session) {
      this.confirmMessage = `Are you sure you want to move this validate session to recycle bin?`;
      this.confirmMessageHeader = 'Remove Session';
      this.toDelete = session;
      this.buttonText = 'Remove';
      this.$refs.confirmModal.showModal();
      this.confirmFunction = () => {
        this.handleDeleteValidateSessions([session.id]);
      };
    },
    async handleStopValidateSessions(ids) {
      await this.dataConnect.terminateValidateSessions({
        session_ids: ids,
      })
        .catch((error) => {
          console.log(error);
        });

      this.toDelete = null;
    },
    async handleDeleteValidateSessions(ids) {
      await this.dataConnect.deleteValidateSessions({
        session_ids: ids,
      })
        .then(() => {
          this.getDatasets();
        })
        .catch((error) => {
          console.log(error);
        });

      this.toDelete = null;
    },
  },
};
</script>

<style lang="scss" scoped>
.controls-bar {
  display: flex;
  flex-direction: row;
  padding: 10px 20px 10px 20px;
  align-items: center;
  border-bottom: 1px solid #c9c9c9;
  @include themify() {
    background: themed('color-ribbon');
  }
  justify-content: space-between;
}

.validator-header {
  display: flex;
  flex-direction: row;
  gap: 16px;
}

.validator-card-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(630px, 1fr));
  grid-auto-rows: auto;
  width: 100%;
  gap: 16px;
  padding: 16px;
  overflow: auto;
}

.empty-state {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  height: 100%;
}

.hyperlink {
  color: var(--color-primary);
  text-decoration: underline;
  cursor: pointer;
}
</style>
