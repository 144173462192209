import { Tensor, InferenceSession } from "onnxruntime-web";
import { modelData } from "@/assets/js/onnxHelpers/onnxModelAPI";
import { handleImageScale } from "@/assets/js/onnxHelpers/scaleHelper";
import { onnxMaskToImage, clearMask } from "@/assets/js/onnxHelpers/maskUtils";
import { useEventBus, useDebounceFn } from '@vueuse/core';
import {
  ref, computed, watch, onMounted, onUnmounted,
} from 'vue';
import { v4 as uuidv4 } from 'uuid';
import cv from "@techstark/opencv-js";

const SAMEventBus = ref(null);
const SAMModelURL = '/sam_onnx_example.onnx';
let SAMImageEmbedding = ref(null);
let samMaskValue = ref(null);
let samMaskImg = ref(null);
let SAMSession = ref(null);
let imageObj = null;
let imageDimensions = null;
let annotations = null;
let selectedLabel = null;
let ctx = null;
let samOptions = null;
const isCreatingPolygon = ref(false);

export default function useSAMCanvas(context, newSAMOptions, canvas) {
  let controller = new AbortController();
  const samActive = computed(() => samOptions.value?.enabled);
  const samClicks = computed(() => samOptions.value?.params.clicks);
  const samBox = computed(() => samOptions.value?.params.box);
  const samBoxActive = computed(() => samOptions.value?.params.pointType === 'box');
  const samClickActive = computed(() => samOptions.value?.params.pointType === 'add' || samOptions.value?.params.pointType === 'minus');
  const isAnnotating = computed(() => samActive.value && canvas.isSAMToolSelected.value && (samBoxActive.value || samClickActive.value));
  const configSAMMaskImg = computed(() => ({
    x: 0,
    y: 0,
    image: samMaskImg.value,
    width: imageDimensions.value?.width ? imageDimensions.value.width : 100,
    height: imageDimensions.value?.height ? imageDimensions.value.height : 100,
    draggable: false,
    opacity: 0.5,
  }));

  if (context) {
    ctx = context;
  }
  if (newSAMOptions) {
    samOptions = newSAMOptions;
  }
  if (canvas?.imageObj) {
    imageObj = canvas.imageObj;
  }
  if (canvas?.imageObj) {
    imageObj = canvas.imageObj;
  }
  if (canvas?.imageDimensions) {
    imageDimensions = canvas.imageDimensions;
  }
  if (canvas?.annotations) {
    annotations = canvas.annotations;
  }
  if (canvas?.selectedLabel) {
    selectedLabel = canvas.selectedLabel;
  }

  function startSAMCanvas() {
    watch(SAMImageEmbedding, (v) => {
      if (v) {
        ctx.emit('update:hasSAMImageEmbedding', true);
      } else {
        ctx.emit('update:hasSAMImageEmbedding', false);
      }
    }, { immediate: true });
    watch(imageObj, () => {
      samOptions.value.params.box = [];
      samOptions.value.params.clicks = [];
      SAMImageEmbedding.value = null;
      if (samActive.value && imageObj.value.id) {
        SAMEventBus.value.emit('reset');
        getNewSAMImageEmbedding(imageObj.value.id);
      }
    });
    watch(samActive, async (active) => {
      if (active) {
        if (!SAMImageEmbedding.value) {
          await Promise.all([
            getNewSAMImageEmbedding(imageObj.value.id),
            getSAMSession(),
          ]);
        }
        handleSAMClicks(samOptions.value.params.clicks, samOptions.value.params.box);
      } else {
        abortSAMFetching();
        samMaskImg.value = clearMask();
      }
    });
    watch(samClicks, (clicks) => {
      if (samActive.value && SAMImageEmbedding.value && SAMSession.value) {
        handleSAMClicks(clicks, samOptions.value.params.box);
      }
    }, { deep: true });
    watch(samBox, (box) => {
      if (samActive.value && SAMImageEmbedding.value && SAMSession.value) {
        handleSAMClicks(samOptions.value.params.clicks, box);
      }
    });

    // Lifecycle Hooks
    onMounted(() => {
      SAMEventBus.value = useEventBus('sam');
      SAMEventBus.value.on(SAMEventListener);
      if (samActive.value) {
        getNewSAMImageEmbedding(imageObj.value.id);
        getSAMSession();
      }
    });
    onUnmounted(() => {
      controller.abort();
      SAMEventBus.value = null;
      SAMImageEmbedding = ref(null);
      samMaskValue = ref(null);
      samMaskImg = ref(null);
      SAMSession = ref(null);
      imageObj = null;
      imageDimensions = null;
      annotations = null;
      selectedLabel = null;
      ctx = ref(null);
      samOptions = ref(null);
    });
  }

  // Methods
  function SAMEventListener(event) {
    switch (event) {
    case 'reset':
      reset();
      break;
    default:
    }
  }

  function reset() {
    samMaskValue.value = null;
  }

  async function abortSAMFetching() {
    controller.abort();
  }

  async function getNewSAMImageEmbedding(imageID) {
    SAMImageEmbedding.value = null;
    samMaskImg.value = null;
    samMaskValue.value = null;
    ctx.emit('update:samMask', null);

    controller.abort();
    controller = new AbortController();
    const response = await fetch(`sam/embedding?id=${imageID}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      signal: controller.signal,
    });
    if (response.ok) {
      console.log('Got Image Embeddings');
      const buf = await response.arrayBuffer();
      const data32 = new Float32Array(buf);
      SAMImageEmbedding.value = new Tensor('float32', data32, [1, 256, 64, 64]);
    } else {
      console.error('Failed to get SAM Image Embedding');
      // TODO: Show error icon, stop showing loading icon
    }
  }

  async function getSAMSession() {
    SAMSession.value = await InferenceSession.create(SAMModelURL);
  }

  async function handleSAMClicks(clicks, box = [], isPreview = false) {
    if (!samOptions.value.enabled || !SAMImageEmbedding.value || !SAMSession.value) return;

    if (clicks.length <= 0 && box.length <= 0) { samMaskImg.value = clearMask(); return; }

    const output = await calculateSAMMask(clicks, box);
    if (!isPreview) {
      samMaskValue.value = output.data;
    }
    ctx.emit('update:samMask', output.data);
    samMaskImg.value = onnxMaskToImage(output.data, output.dims[2], output.dims[3]);
  }

  async function calculateSAMMask(clicks, box = []) {
    const modelScale = handleImageScale(imageDimensions.value.width, imageDimensions.value.height);
    const feeds = modelData({
      clicks: [...clicks, ...box],
      tensor: SAMImageEmbedding.value,
      modelScale,
    });
    const results = await SAMSession.value.run(feeds);
    const output = results[SAMSession.value.outputNames[0]];
    return output;
  }

  function handleSAMMaskClick(e, stageNode) {
    if (samOptions.value.params.pointType !== 'add' && samOptions.value.params.pointType !== 'minus') {
      return;
    }

    const x = stageNode.getRelativePointerPosition().x;
    const y = stageNode.getRelativePointerPosition().y;

    if (samOptions.value.enabled && (e.evt.button === 0 || e.evt.button === 2)) {
      e.evt.preventDefault();
      let clickType;
      if (e.evt.button === 0) {
        if (samOptions.value.params.pointType === 'add') {
          clickType = 1;
        } else if (samOptions.value.params.pointType === 'minus') {
          clickType = 0;
        }
      } else {
        clickType = 0;
      }
      const newSAMOptions = { ...samOptions.value };
      newSAMOptions.params.clicks.push({ x, y, clickType });
      ctx.emit('update:samOptions', newSAMOptions);
    }
  }

  function handleSAMMousemove(stageNode) {
    if (samOptions.value.params.pointType !== 'add' && samOptions.value.params.pointType !== 'minus') {
      return;
    }
    // Show SAM mask preview on hover
    if (samOptions.value.enabled && SAMImageEmbedding.value) {
      const x = stageNode.getRelativePointerPosition().x;
      const y = stageNode.getRelativePointerPosition().y;
      let previewClickType = 1;
      if (samOptions.value.params.pointType === 'minus') {
        previewClickType = 0;
      }
      const previewClick = { x, y, clickType: previewClickType };
      handleSAMClicks([previewClick, ...samClicks.value], samOptions.value.params.box, true);
    }
  }

  function handleSAMMouseout() {
    if (samOptions && samOptions.value) {
      handleSAMClicks(samOptions.value.params.clicks, samOptions.value.params.box);
    }
  }

  const debouncedHandleSAMMousemove = useDebounceFn(handleSAMMousemove, 30);
  const debouncedHandleSAMMouseout = useDebounceFn(handleSAMMouseout, 30);

  function handleSAMBoxDimensionChanged(dimensions) {
    if (dimensions) {
      const newSAMOptions = { ...samOptions.value };
      newSAMOptions.params.box = [
        { x: dimensions.x1, y: dimensions.y1, clickType: 2 },
        { x: dimensions.x2, y: dimensions.y2, clickType: 3 },
      ];
      ctx.emit('update:samOptions', newSAMOptions);
    }
  }

  async function handleCreateSAMPolygon() {
    if (!selectedLabel.value) {
      alert('No label selected');
      return;
    }

    if (!samMaskValue.value) {
      return;
    }

    isCreatingPolygon.value = true;

    let polygon;
    try {
      polygon = await processImage(samMaskValue.value);
    } catch (err) {
      console.error('Failed to create annotation:', err);
      alert(`Failed to create annotation: ${err}`);
      isCreatingPolygon.value = false;
      return;
    }

    const newAnnotationPolygon = {
      id: uuidv4(),
      label_index: selectedLabel.value.index,
      label_id: selectedLabel.value.id,
      label_name: selectedLabel.value.name,
      type: "seg",
      polygon: JSON.stringify(polygon),
      score: 1,
      reviewStatus: 'verified',
      image_id: imageObj.value.id,
    };

    const newAnnotations = [...annotations.value, newAnnotationPolygon];
    ctx.emit('update:updatedAnnotations', newAnnotations);

    samOptions.value.params.clicks = [];
    samOptions.value.params.box = [];

    isCreatingPolygon.value = false;

    // isCreatingPolygon.value = true;
    // await fetch(`sam/convert`, {
    //   method: "POST",
    //   headers: {
    //     "Content-Type": "application/octet-stream",
    //     "imageW": imageDimensions.value.width,
    //     "imageH": imageDimensions.value.height,
    //   },
    //   body: samMaskValue.value,
    // })
    //   .then(async (resp) => {
    //     const polygon = await resp.json();

    //     const newAnnotationPolygon = {
    //       id: uuidv4(),
    //       label_index: selectedLabel.value.index,
    //       type: "seg",
    //       polygon: JSON.stringify(polygon),
    //       score: 1,
    //       reviewStatus: 'verified',
    //       image_id: imageObj.value.id,
    //     };

    //     const newAnnotations = [...annotations.value, newAnnotationPolygon];
    //     ctx.emit('update:updatedAnnotations', newAnnotations);

    //     samOptions.value.params.clicks = [];
    //     samOptions.value.params.box = [];
    //   })
    //   .catch((err) => {
    //     alert(err);
    //   });
    // isCreatingPolygon.value = false;
  }

  // async function processImage(mask) {
  //   // Create OpenCV Mat from Float32Array
  //   const mat = cv.matFromArray(imageDimensions.value.height, imageDimensions.value.width, cv.CV_32FC1, mask);
  //   console.log(mat);

  //   // Convert to CV_8U single channel (assuming grayscale/binary image)
  //   const mat8U = new cv.Mat();
  //   mat.convertTo(mat8U, cv.CV_8U);

  //   // Threshold the mask
  //   const thresh = new cv.Mat();
  //   cv.threshold(mat8U, thresh, 0, 255, cv.THRESH_BINARY);
  //   console.log(thresh);

  //   // Find contours
  //   const contours = new cv.MatVector();
  //   const hierarchy = new cv.Mat();
  //   cv.findContours(thresh, contours, hierarchy, cv.RETR_TREE, cv.CHAIN_APPROX_SIMPLE);
  //   console.log('Contours:', contours.size());

  //   // Find the largest contour
  //   let maxLen = 0;
  //   let index = 0;
  //   for (let i = 0; i < contours.size(); i++) {
  //     const contour = contours.get(i);
  //     if (contour.size().height > maxLen) {
  //       maxLen = contour.size().height;
  //       index = i;
  //     }
  //   }
  //   console.log(contours);

  //   // Convert largest contour to normalized polygon
  //   const polygon = [];
  //   const contour = contours.get(index);
  //   for (let i = 0; i < contour.size().height; i++) {
  //     const point = contour.intPtr(i);
  //     polygon.push([point[0] / imageDimensions.value.width, point[1] / imageDimensions.value.height]);
  //   }
  //   console.log(polygon);

  //   // Clean up
  //   mat.delete();
  //   thresh.delete();
  //   contours.delete();
  //   hierarchy.delete();

  //   return [polygon];
  // }

  async function processImage(mask) {
    // Create OpenCV Mat from Float32Array
    const mat = cv.matFromArray(imageDimensions.value.height, imageDimensions.value.width, cv.CV_32FC1, mask);

    // Convert to CV_8U single channel (assuming grayscale/binary image)
    const mat8U = new cv.Mat();
    try {
      mat.convertTo(mat8U, cv.CV_8U);
    } catch {
      throw Error('Failed to convert to single channel');
    }

    // Threshold the mask
    const thresh = new cv.Mat();
    try {
      cv.threshold(mat8U, thresh, 0, 255, cv.THRESH_BINARY);
    } catch {
      throw Error('Failed to get threshold mask');
    }

    // Find contours
    const contours = new cv.MatVector();
    const hierarchy = new cv.Mat();
    try {
      cv.findContours(thresh, contours, hierarchy, cv.RETR_EXTERNAL, cv.CHAIN_APPROX_SIMPLE);
    } catch {
      throw Error('Failed to find contours');
    }

    if (contours.size() === 0) {
      return [];
    }

    const simplifiedPolygon = [];

    // Find the largest contour
    for (let i = 0; i < contours.size(); i++) {
      const currentPolygon = [];
      const ctr = contours.get(i);

      // Simplify the largest contour using approxPolyDP
      const epsilon = 0.0015 * cv.arcLength(ctr, true);
      const approx = new cv.Mat();
      try {
        cv.approxPolyDP(ctr, approx, epsilon, true);
      } catch {
        throw Error('Failed to simplify polygon');
      }

      if (imageDimensions.value.width > 0 && imageDimensions.value.height > 0) {
        for (let j = 0; j < approx.rows; j++) {
          const x = (approx.intPtr(j, 0)[0] / imageDimensions.value.width);
          const y = (approx.intPtr(j, 0)[1] / imageDimensions.value.height);
          currentPolygon.push([x, y]);
        }
        simplifiedPolygon.push(currentPolygon);
      }
    }

    // Clean up
    try {
      mat.delete();
      thresh.delete();
      contours.delete();
      hierarchy.delete();
    } catch {
      throw Error('Failed to clean up');
    }

    return simplifiedPolygon;
  }

  return {
    startSAMCanvas,
    SAMEventBus,
    SAMImageEmbedding,
    samMaskImg,
    SAMSession,
    samActive,
    samClicks,
    samBox,
    samBoxActive,
    samClickActive,
    isAnnotating,
    configSAMMaskImg,
    isCreatingPolygon,
    getNewSAMImageEmbedding,
    getSAMSession,
    handleSAMMaskClick,
    handleSAMMousemove,
    debouncedHandleSAMMousemove,
    debouncedHandleSAMMouseout,
    handleSAMMouseout,
    handleSAMBoxDimensionChanged,
    handleCreateSAMPolygon,
    abortSAMFetching,
  };
}
