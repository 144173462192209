<template>
  <component
    :is="`v\-${component}`"
    :active="active"
    :absolute="true"
    :height="56"
    :z-index="10"
    class="p-0"
  >
    <v-bottom-sheet
      v-model="showGallery"
      :height="'100%'"
      :z-index="10"
      :attach="true"
      :scrim="true"
      :eager="true"
      :max-height="'calc(100% - 56px)'"
      :retain-focus="false"
    >
      <v-sheet id="annotation-editor-gallery" class="gallery">
        <AnnotationEditorGallery
          :isOpen="showGallery"
          :dataset="dataset"
          :reviewSettings="reviewSettings"
          @image-clicked="handleGalleryImageClicked"
        />
      </v-sheet>
    </v-bottom-sheet>
    <div class="bottom-image-drawer">
      <div class="bottom-image-drawer__header">
        <v-btn
          :text="galleryButtonText"
          @click="showGallery = !showGallery"
        />
      </div>
      <template v-if="!sequences || (sequences && sequences.length === 0)">
        <div class="bottom-image-drawer__complete-images">
          <button v-if="page < (numPages - 1)" class="bottom-image-drawer__navigation-arrow _left" @click="handleLeftNavigation">
            <SVGIcon
              :iconName="'chevron_left'"
              :width="'20px'"
              :height="'20px'"
            />
          </button>
          <div class="bottom-image-drawer__complete-images-grid-items">
            <div
              v-for="(img, i) in imagesToShow"
              :key="img.id"
              class="bottom-image-drawer__grid-item-container"
              :class="{ active: activeIndex === i }"
            >
              <button
                class="bottom-image-drawer__grid-item"
                :class="{ active: activeIndex === i }"
                @click="handleImageClicked(i + (numImagesPerPage * page))"
              >
                <img :src="imagesToShowUrls[i]" class="bottom-image-drawer__image">
              </button>
            </div>
          </div>
          <button v-if="page > 0" class="bottom-image-drawer__navigation-arrow _right" @click="handleRightNavigation">
            <SVGIcon
              :iconName="'chevron_right'"
              :width="'20px'"
              :height="'20px'"
            />
          </button>
        </div>
        <div
          v-if="currentImage"
          class="bottom-image-drawer__current-images"
          :class="{ active: activeIndex < 0 }"
          @click="handleImageClicked(-1)"
        >
          <span>Pending</span>
          <div class="bottom-image-drawer__current-images-grid-items">
            <div
              v-for="(pendingImage, i) in pendingImages"
              :key="pendingImage.id"
              class="bottom-image-drawer__grid-item"
              :style="{zIndex: pendingImages.length - i}"
            >
              <img :src="pendingImagesUrls[i]" class="bottom-image-drawer__image">
            </div>
          </div>
        </div>
      </template>
      <div v-if="sequences && sequences.length > 0" class="bottom-image-drawer__sequences">
        <span>Selected Sequence:</span>
        <select
          v-model="selectedSequence"
          class="select"
          required
          @change="handleSelectedSequenceChanged"
        >
          <option disabled :value="''">Select a Sequence</option>
          <option v-for="(sequence, i) in sequences" :key="i" :value="sequence">
            {{ sequence.name }}
          </option>
        </select>
      </div>
    </div>
  </component>
</template>

<script>
import SVGIcon from '@/components/SVGIcon.vue';
import AnnotationEditorGallery from '@/components/DatasetComponent/AnnotationTool/AnnotationEditorGallery.vue';
import DatastoreConnect from '../../../assets/js/DatastoreFunctions/datastore-interface';

export default {
  name: 'AnnotationEditorHistoryDrawer',
  components: {
    SVGIcon,
    AnnotationEditorGallery,
  },
  props: {
    reviewSettings: {
      type: Object,
      default: null,
    },
    active: {
      type: Boolean,
      default: false,
    },
    images: {
      type: Array,
      default: () => [],
    },
    currentImage: {
      type: Object,
      default: null,
    },
    pendingImages: {
      type: Array,
      default: () => [],
    },
    activeIndex: {
      type: Number,
      default: null,
    },
    numImagesPerPage: {
      type: Number,
      default: 10,
    },
    component: {
      type: String,
      default: 'bottom-navigation',
    },
    height: {
      type: Number,
      default: undefined,
    },
    sequences: {
      type: Array,
      default: () => [],
    },
  },
  emits: ['image-clicked', 'gallery-image-clicked', 'update:selectedSequence'],
  data() {
    return {
      page: 0,
      showGallery: false,
      dataset: null,
      selectedSequence: null,
      imagesToShowUrls: [],
      pendingImagesUrls: [],
    };
  },
  computed: {
    numPages() {
      return Math.ceil(this.images.length / this.numImagesPerPage);
    },
    imagesToShow() {
      return this.images.slice(this.page * this.numImagesPerPage, this.page * this.numImagesPerPage + this.numImagesPerPage);
    },
    galleryButtonText() {
      if (this.showGallery) {
        return 'Hide Gallery';
      }
      return 'Show Gallery';
    },
  },
  watch: {
    currentImage(currentImage) {
      if (currentImage.sequence_id) {
        this.selectedSequence = this.sequences.find((seq) => seq.sequence_id === currentImage.sequence_id);
      }
    },
    imagesToShow(imagesToShow) {
      imagesToShow.forEach((img, index) => {
        this.getImageBlobUrl(`v3/enterprise/thumbnail/${img.id}`).then((url) => {
          this.imagesToShowUrls[index] = url;
        });
      });
    },
    pendingImages(pendingImagesUrls) {
      pendingImagesUrls.forEach((img, index) => {
        this.getImageBlobUrl(`v3/enterprise/thumbnail/${img.id}`).then((url) => {
          this.pendingImagesUrls[index] = url;
        });
      });
    },
  },
  async mounted() {
    this.getDataset();
  },
  methods: {
    handleImageClicked(imageIndex) {
      this.$emit('image-clicked', imageIndex);
    },
    handleLeftNavigation() {
      this.page += 1;
    },
    handleRightNavigation() {
      if (this.page > 0) { this.page -= 1; }
    },
    async getDataset() {
      const dataConnect = new DatastoreConnect();
      this.dataset = await dataConnect.getDatasetById({
        dataset_id: this?.reviewSettings?.reviewTask?.dataset_id,
        get_labels: true,
        get_annotation_sets: true,
        get_groups: true,
      })
        .then((resp) => {
          if (resp.error !== undefined) {
            throw Error(resp.error);
          }
          return resp.result;
        })
        .catch((error) => {
          this.dataset = {};
          console.error(error);
        });
    },
    async handleGalleryImageClicked(imageObj) {
      await setTimeout(() => {
        this.showGallery = false;
      }, 100);
      this.$emit('gallery-image-clicked', imageObj.id);
    },
    handleSelectedSequenceChanged() {
      this.$emit('update:selectedSequence', this.selectedSequence);
    },
    async getImageBlobUrl(url) {
      if (url) {
        const response = await fetch(url, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${this.$store.state.user.token}`,
          },
        });
        if (!response.ok) {
          throw new Error(`Error loading image`);
        }
        const blob = await response.blob();
        return URL.createObjectURL(blob);
      }
      return null;
    },
  },
};
</script>

<style lang="scss" scoped>
.bottom-image-drawer {
  display: flex;
  flex-direction: row;
  flex: 1 1 auto;
  height: 100%;
  box-shadow: -1px 1px 1px 0px rgba(0, 0, 0, 0.125) inset;
  border-left: 1px solid rgba(0,0,0,0.125);
  z-index: 100;
  @include themify() {
    background: themed('color-white-700');
  }

  &__header {
    display: flex;
    padding: 0px 16px;
    align-items: center;

    button {
      width: 150px;
    }
  }

  &__complete-images {
    position: relative;
    display: flex;
    flex: 1 1 auto;
    gap: 16px;
    // padding: 4px 0px;
    margin-left: 56px;
    margin-right: 16px;
    align-items: center;
    justify-content: center;
  }

  &__current-images {
    display: flex;
    flex-direction: row;
    flex: 0 0 250px;
    max-width: 250px;
    min-width: 250px;
    gap: 0px;
    padding: 0px 16px;
    align-items: center;
    cursor: pointer;
    background: var(--gray-500);

    &.active {
      background: var(--color-primary-400)
    }

    span {
      height: fit-content;
      margin-right: 16px;
      font-size: 1rem;
      font-weight: 500;
      letter-spacing: 0.05em;
      @include themify() {
        color: themed('color-white');
      }
    }

    .bottom-image-drawer__grid-item:first-of-type {
      background: rgba(var(--color-white-rgb), 0.85);
    }
    .bottom-image-drawer__grid-item:not(:last-of-type) {
      box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.5);
      border: 1px solid rgba(var(--color-white-rgb), 0.25);
    }
    .bottom-image-drawer__grid-item + .bottom-image-drawer__grid-item {
      margin-left: -34px;
      opacity: 85%;
    }
  }

  &__complete-images-grid-items {
    position: relative;
    display: flex;
    flex-direction: row-reverse;
    height: 100%;
  }

  &__current-images-grid-items {
    position: relative;
    display: flex;
    height: 100%;
    padding: 4px 0;
  }

  &__grid-item {
    height: 100%;
    width: 68px;
    border: 1px solid rgba(0,0,0,0.375);
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.375);
    background: rgba(var(--color-white-rgb), 1);

    :hover {
      cursor: pointer;
    }

    &.active {
      border: 3px solid var(--color-primary);
    }
  }

  &__grid-item-container {
    height: 100%;
    padding: 4px 8px;
    &.active {
      background: var(--color-primary-400)
    }
  }

  &__image {
    height: 100%;
  }

  &__navigation-arrow {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 32px;
    width: 32px;
    border-radius: 50%;
    opacity: 0.625;
    @include themify() {
      background: themed('color-black');
    }

    &._left {
      @include themify() {
        color: themed('color-white');
      }
    }
    &._right {
      @include themify() {
        color: themed('color-white');
      }
    }
  }

  &__sequences {
    position: relative;
    display: flex;
    flex: 1 1 auto;
    gap: 16px;
    // padding: 4px 0px;
    margin-left: 56px;
    margin-right: 16px;
    align-items: center;
    justify-content: center;

    select {
      width: 200px;
    }
  }
}

.gallery {
  height: 100%;
  margin-bottom: 56px;
  display: flex;
  flex-direction: column;
}
</style>
