<template>
  <div class="json-view-item">
    <!-- Handle Objects and Arrays-->
    <div v-if="data.type === 'object' || data.type === 'array'">
      <div class="item-title" :class="{'selected': selectedAnnotationIdentifiers?.includes(itemId)}">
        <button
          class="data-key"
          :aria-expanded="open ? 'true' : 'false'"
          :class="{'display-none': data.key === ''}"
          @click.stop="handleOpen"
        >
          <span :class="{'chevron-arrow': data.key !== '', opened: open}" />
        </button>
        <span class="data-title" @click.stop="handleSelect">{{ data.key }}</span>
        <IconButton
          v-if="removable"
          class="icon"
          :icon="'delete'"
          :width="18"
          :height="18"
          @click="removeAnnotation"
        />
        <!-- <IconButton
          v-if="data.key.includes('pcd') || data.key.includes('mask') || data.key.includes('radar')"
          class="icon"
          :icon="'open_in_full'"
          :width="18"
          :height="18"
          @click="openInFull()"
        /> -->
      </div>
      <json-view-item
        v-for="child in data.children"
        v-show="open"
        :key="getKey(child)"
        :data="child"
        :maxDepth="maxDepth"
        :canSelect="canSelect"
        :loading="loading"
        @update:selected="bubbleSelected"
      />
    </div>
    <!-- Handle Leaf Values -->
    <div
      v-if="data.key === 'raw_file'"
      class="file-raw scrollbar"
      title="Copy to Clipboard"
      @click="copyToClipboard(dataValue)"
    >
      <v-skeleton-loader
        v-if="loading"
        type="paragraph"
        color="transparent"
        :width="'100%'"
      />
      <span v-else><pre>{{ dataValue }}</pre></span>
    </div>
    <template v-if="data.key === 'segments'">
      <div
        class="segment-col"
        title="Copy to Clipboard"
        @click="copyToClipboard(JSON.stringify(dataValue))"
      >
        <span class="segment-key">{{ data.key }}</span>
        <span class="segment-raw">{{ dataValue }}</span>
      </div>
    </template>
    <div
      v-if="data.key !== 'raw_file' && data.key !== 'segments' && data.type === 'value'"
      :class="{'value-key' : true, 'can-select': canSelect}"
      :role="canSelect ? 'button' : undefined"
      :tabindex="canSelect ? '0' : undefined"
      @click="emitSelect(data)"
      @keyup.enter="emitSelect(data)"
      @keyup.space="emitSelect(data)"
    >
      <span class="value-key">{{ data.key }}</span>
      <span class="value-item" :title="dataValue" :style="getValueStyle(data.value)">{{ dataValue }}</span>
    </div>
  </div>
</template>

<script>

import IconButton from '@/components/IconButton.vue';

const JsonDataType = {
  ARRAY: 'array',
  VALUE: 'value',
  OBJECT: 'object',
};

const ColorMode = {
  LIGHT: 'light',
  DARK: 'dark',
};

export default {
  name: 'JsonViewItem',
  components: {
    IconButton,
  },
  props: {
    data: {
      required: true,
      type: Object,
    },
    maxDepth: {
      type: Number,
      required: false,
      default: 1,
    },
    canSelect: {
      type: Boolean,
      required: false,
      default: false,
    },
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
    selectedAnnotationIdentifiers: {
      type: Object,
      default: null,
    },
    itemId: {
      type: [Number, String],
      default: 0,
    },
    removable: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:selected', 'opened', 'open-in-full', 'selected', 'remove-annotation'],
  data() {
    return {
      open: false,
      selected: false,
    };
  },
  computed: {
    lengthString() {
      switch (this.data.type) {
      case JsonDataType.ARRAY:
        return this.data.length === 1
          ? `${this.data.length} element`
          : `${this.data.length} elements`;
      case JsonDataType.OBJECT:
        return this.data.length === 1
          ? `${this.data.length} property`
          : `${this.data.length} properties`;
      default:
        return '';
      }
    },
    dataValue() {
      if (this.data.key === 'raw_file' || this.data.key === 'segments') {
        return this.data.value;
      }
      if (typeof this.data.value === 'string') {
        return JSON.stringify(this.data.value).replace(/['"]+/g, '');
      } else if (!Number.isNaN(this.data.value)) {
        if (!Number.isInteger(this.data.value)) {
          return Number(this.data.value).toFixed(3);
        } else {
          return Number(this.data.value);
        }
      } else if (typeof this.data.value === 'undefined') {
        return 'undefined';
      }
      return '';
    },
  },
  methods: {
    removeAnnotation() {
      this.$emit('remove-annotation', this.itemId);
    },
    emitSelect(data) {
      this.$emit('update:selected', {
        key: data.key,
        value: data.type === JsonDataType.VALUE ? data.value : undefined,
        path: data.path,
      });
    },
    bubbleSelected(data) {
      this.$emit('update:selected', data);
    },
    getKey(value) {
      if (!Number.isNaN(value.key)) {
        return `${value.key}:`;
      } else {
        return `"${value.key}":`;
      }
    },
    getValueStyle(value) {
      switch (typeof value) {
      case 'string':
        return { color: 'var(--vjc-string-color)' };
      case 'number':
        return { color: 'var(--vjc-number-color)' };
      case 'boolean':
        return { color: 'var(--vjc-boolean-color)' };
      case 'object':
        return { color: 'var(--vjc-null-color)' };
      case 'undefined':
        return { color: 'var(--vjc-null-color)' };
      default:
        return { color: 'var(--vjc-valueKey-color)' };
      }
    },
    async copyToClipboard(str) {
      try {
        if (navigator?.clipboard?.writeText) {
          await navigator.clipboard.writeText(str.replace(/['"]+/g, ''));
        }
      } catch (err) {
        console.error(err);
      }
    },
    openInFull(data) {
      this.$emit('open-in-full', data);
    },
    handleOpen() {
      this.open = !this.open;
      if (this.open) {
        this.$emit('opened');
      }
    },
    handleSelect() {
      this.selected = !this.selected;
      this.$emit('selected');
    },
  },
};
</script>

<style lang="scss" scoped>

.json-view-item {
  margin-left: 10px;
  margin-bottom: 5px;
}

.item-title {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-weight: 600;
  height: 30px;

  &:hover {
    background-color: var(--vjc-hover-color);
  }
  &.selected {
    background-color: var(--vjc-hover-color);
  }

  &:hover {
    .icon {
      visibility: visible;
    }
  }
}

.icon {
  visibility: hidden;
  margin-right: 15px;
}

.value-key {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: var(--vjc-valueKey-color);
  font-weight: 600;
  margin-left: 5px;
  border-radius: 2px;
  padding: 5px 5px 5px 5px;
  font-size: 0.9rem;
  height: 20px;

  &.can-select {
    cursor: pointer;

    &:hover {
      background-color: rgba(0, 0, 0, 0.08);
    }

    &:focus {
      outline: 2px solid var(--vjc-hover-color);
    }
  }
}

.value-key:hover {
  background-color: tint-color($color-primary-100, 70%);
}

.file-raw {
  height: 150px;
  overflow-y: scroll;
  span{
    height: 100%;
  }
  pre {
    font-size: 0.8rem;
    padding: 4px;
    border-radius: 5px;
    border: 1px solid transparent;
    white-space: pre-wrap;
    font-family: 'Courier New', monospace !important;
  }
}

.file-raw:hover {
  cursor: copy;
  pre {
    border: 1px dashed var(--color-primary-300);
    background-color: tint-color($color-primary-100, 70%);
  }
}

span.value-key {
  width: 90px;
  min-width: 90px;
}

span.value-item {
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.segment-col {
  display: flex;
  flex-direction: column;
  padding-left: 10px;
  font-weight: 600;
  font-size: 0.9rem;
  height: fit-content;
}

.segment-col:hover {
  cursor: copy;
  span {
    background-color: tint-color($color-primary-100, 70%);
  }
}

.segment-key {
  padding-left: 10px;
}

.segment-raw {
  height: fit-content;
  padding-left: 20px;
  font-weight: 500;
}

.data-title {
  width: 100%;
  cursor: pointer;
}

.data-key {
  // Button overrides
  font-size: 0.9rem;
  font-family: inherit;
  border: 0;
  background-color: transparent;
  width: fit-content;

  // Normal styles
  color: var(--vjc-key-color);
  display: flex;
  align-items: center;
  border-radius: 2px;
  font-weight: 600;
  cursor: pointer;
  white-space: nowrap;
  padding: 5px;
  margin-left: 10px;

  &::-moz-focus-inner {
    border: 0;
  }

  .properties {
    font-weight: 300;
    opacity: 0.9;
    margin-left: 4px;
    user-select: none;
  }
}

.chevron-arrow {
  flex-shrink: 0;
  border-right: 2px solid var(--vjc-arrow-color);
  border-bottom: 2px solid var(--vjc-arrow-color);
  width: var(--vjc-arrow-size);
  height: var(--vjc-arrow-size);
  margin-right: 12px;
  margin-left: 7px;
  transform: rotate(-45deg);

  &.opened {
    margin-top: -3px;
    transform: rotate(45deg);
  }
}

.display-none {
  display: none
}

</style>
