<template>
  <Modal
    ref="updateAnnotationSetModal"
    :title="'Annotation Set'"
  >
    <!-- <template #modal-header /> -->
    <template #modal-main>
      <div class="settings container">
        <div class="row mb-3">
          <div class="col-12 settings__control-group">
            <label>Name</label>
            <input
              ref="annSetName"
              v-model="name"
              class="select"
              type="text"
            >
          </div>
        </div>
        <div class="row">
          <div class="col-12 settings__control-group">
            <label>Description</label>
            <textarea
              v-model="description"
              rows="4"
              cols="50"
            />
          </div>
        </div>
      </div>
    </template>
    <template #modal-footer>
      <div class="footer">
        <button
          class="button modal-action-button"
          @click="updateAnnotationSet"
        >
          Save
        </button>
      </div>
    </template>
  </Modal>
</template>

<script>
import DatastoreConnect from '@/assets/js/DatastoreFunctions/datastore-interface';
import Modal from '@/components/Modal.vue';

export default {
  name: 'UpdateAnnotationSetModal',
  components: {
    Modal,
  },
  props: {
    annotationSet: {
      type: Object,
      default: null,
    },
  },
  emits: ['annotation-set-updated'],
  data() {
    return {
      name: '',
      description: '',
    };
  },
  computed: {
    user() {
      return this.$store.state.user.user;
    },
  },
  created() {
    this.dataConnect = new DatastoreConnect(this.$store.state.enterpriseServerUrl);
  },
  methods: {
    showModal() {
      this.$refs.updateAnnotationSetModal.showModal();
      if (this.annotationSet) {
        this.name = this.annotationSet.name;
        this.description = this.annotationSet.description;
      }
      this.$nextTick(() => {
        this.$refs.annSetName.focus();
      });
    },
    async updateAnnotationSet() {
      if (!this.name) {
        console.error('Missing name');
        return;
      }
      const resp = await this.dataConnect.updateAnnotationSet({
        annotation_set_id: this.annotationSet.id,
        name: this.name,
        description: this.description,
      })
        .catch((error) => {
          // TODO: handle error
          console.log(error);
        });

      this.name = '';
      this.description = '';
      this.$refs.updateAnnotationSetModal.closeModal();
      this.$emit('annotation-set-updated', resp.result);
    },
  },
};

</script>

<style lang="scss" scoped>
.settings {
  padding: 0.75rem 3rem;
  min-width: 420px;
}

.footer {
  display: flex;
  flex-direction: row;
  padding: 0.75rem 3rem;
  justify-content: flex-end;
}

</style>
