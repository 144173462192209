import {
  ref, computed, watch,
} from 'vue';
import { useIdle, useIntervalFn } from '@vueuse/core';

export default function useActiveTimer(timeout) {
  const activeTime = ref(0);
  const { idle } = useIdle(timeout);
  const { resume, pause } = useIntervalFn(() => {
    activeTime.value += 1;
  }, 1000);
  const activeTimeFormatted = computed(() => {
    const hours = Math.floor(activeTime.value / 3600);
    const remainingSeconds = activeTime.value % 3600;
    const minutes = Math.floor(remainingSeconds / 60);
    const seconds = Math.round(remainingSeconds % 60);

    return { hours, minutes, seconds };
  });
  watch(idle, (isIdle) => {
    if (isIdle) {
      pause();
    } else {
      resume();
    }
  });

  return {
    activeTime, activeTimeFormatted, resume, pause,
  };
}
