<template>
  <BaseMenu
    :placement="'bottom-start'"
    :appendToBody="true"
    @closed="show_layout_menu = false"
  >
    <IconButton
      v-model="show_layout_menu"
      :icon="'dashboard'"
      :width="22"
      :height="22"
    />
    <template #menu="{closeMenu}">
      <ul>
        <li>
          <BaseMenuButton @click.stop="handleClick('layout_single'), closeMenu()">
            <SVGIcon
              :iconName="'layout_single'"
              :width="'22px'"
              :height="'22px'"
            />
            <span>Single</span>
          </BaseMenuButton>
        </li>
        <li>
          <BaseMenuButton @click.stop="handleClick('layout_2_columns'), closeMenu()">
            <SVGIcon
              :iconName="'layout_2_columns'"
              :width="'22px'"
              :height="'22px'"
            />
            <span>2 Columns</span>
          </BaseMenuButton>
        </li>
        <li>
          <BaseMenuButton @click.stop="handleClick('layout_2x1'), closeMenu()">
            <SVGIcon
              :iconName="'layout_2x1'"
              :width="'22px'"
              :height="'22px'"
            />
            <span>2x1</span>
          </BaseMenuButton>
        </li>
        <li>
          <BaseMenuButton @click.stop="handleClick('layout_2x2'), closeMenu()">
            <SVGIcon
              :iconName="'layout_2x2'"
              :width="'22px'"
              :height="'22px'"
            />
            <span>2x2</span>
          </BaseMenuButton>
        </li>
      </ul>
    </template>
  </BaseMenu>
</template>

<script setup>
import SVGIcon from '@/components/SVGIcon.vue';
import IconButton from '@/components/IconButton.vue';
import BaseMenu from '@/components/BaseMenu.vue';
import BaseMenuButton from '@/components/BaseMenuButton.vue';

const emit = defineEmits(['layout-selected']);

function handleClick(layout) {
  emit('layout-selected', layout);
}

</script>

<style lang="scss" scoped>

</style>
