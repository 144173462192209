import { defineStore } from 'pinia';
import {
  ref, computed, onBeforeMount,
} from 'vue';

export const useGalleryStore = defineStore('gallery', {
  state: () => {
    const datasetFilters = ref({});
    const annotationDisplaySettings = ref(null);
    const imageDisplaySettings = ref(['showName', 'showSequences']);
    const currentDatasetID = ref(0);
    const internalCurrentDataset = ref({});
    const combineSequenceFrames = ref(false);
    const sortBy = ref('id');
    const reverse = ref(false);
    const currentPage = ref(1);
    const imagesPerPage = ref(40);
    const imageList = ref(null);
    const totalImageCount = ref(0);
    const getImagesParams = ref({});
    const getImagesFilterParams = ref({});
    const executing = ref(false);
    const isFetchingImages = ref(false);

    const totalPages = computed(() => Math.ceil(totalImageCount.value / imagesPerPage.value));

    function setImageParams(params) {
      combineSequenceFrames.value = params.combine_sequence_frames;
      sortBy.value = params.sort_by;
      reverse.value = params.reverse;
    }

    function setDatasetFilters(params) {
      datasetFilters.value = { ...params };
    }

    function $reset() {
      datasetFilters.value = {};
      annotationDisplaySettings.value = null;
      imageDisplaySettings.value = ['showName', 'showSequences'];
      currentDatasetID.value = 0;
      internalCurrentDataset.value = {};
      currentPage.value = 1;
      reverse.value = false;
      combineSequenceFrames.value = false;
      sortBy.value = 'id';
      imagesPerPage.value = 40;
      imageList.value = null;
      totalImageCount.value = 0;
      getImagesParams.value = {};
      getImagesFilterParams.value = {};
      executing.value = false;
      isFetchingImages.value = false;
    }

    return {
      datasetFilters,
      annotationDisplaySettings,
      imageDisplaySettings,
      reverse,
      combineSequenceFrames,
      sortBy,
      currentDatasetID,
      internalCurrentDataset,
      currentPage,
      imagesPerPage,
      imageList,
      totalImageCount,
      totalPages,
      getImagesParams,
      getImagesFilterParams,
      executing,
      isFetchingImages,
      setImageParams,
      setDatasetFilters,
      $reset,
    };
  },
  persist: true,
});
