import { defineStore } from 'pinia';
import {
  ref, watch, computed, onUnmounted,
} from 'vue';

export const useEditorStore = defineStore('editor', () => {
  const internalImageObj = ref(null);
  const internalAnnotations = ref([]);
  const sequences = ref([]);

  function $reset() {
    internalImageObj.value = null;
    internalAnnotations.value = [];
    sequences.value = [];
  }

  onUnmounted(() => {
    $reset();
  });

  return {
    internalImageObj,
    internalAnnotations,
    sequences,
    $reset,
  };
});
