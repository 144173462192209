<template>
  <div class="filters" :class="{'_collapse': collapse}">
    <div class="filters__active-and-default-filters scrollbar">
      <component
        :is="filter.name"
        v-for="filter in filterList"
        :key="filter.key"
        v-model="filters[filter.key]"
        v-bind="filterProps[filter.key]"
      />
      <button
        v-if="!collapse"
        class="button button-sm apply-button"
        :disabled="!filterValue['ground_truth_annotation_set_id']
          || !filterValue['predicted_annotation_set_id']
          || loadingValidations"
        @click="$emit('apply')"
      >
        Generate Report
      </button>
    </div>
    <div v-if="collapse" class="filters__more-filters">
      <button
        class="button button-sm apply-button"
        :disabled="!filterValue['ground_truth_annotation_set_id']
          || !filterValue['predicted_annotation_set_id']
          || loadingValidations"
        @click="$emit('apply')"
      >
        Generate Report
      </button>
    </div>
    <IconButton
      id="expand_less"
      :icon="'expand_less'"
      :width="'20px'"
      :height="'20px'"
      :type="''"
      :showHover="false"
      @click="collapse = !collapse"
    />
  </div>
</template>

<script>
import ComparisonTaskGroundTruth from '@/components/ValidationPage/ComparisonTaskGroundTruth.vue';
import ChooseValidationTarget from '@/components/ValidationPage/ChooseValidationTarget';
import ConfidenceThresholdFilter from '@/components/ValidationPage/ConfidenceThresholdFilter.vue';
import IoUThresholdFilter from '@/components/ValidationPage/IoUThresholdFilter.vue';
import MetricFilter from '@/components/ValidationPage/MetricFilter.vue';
import IgnoreBoxesFilter from '@/components/ValidationPage/IgnoreBoxesFilter';
import IconButton from '@/components/IconButton.vue';
import { toRaw } from 'vue';

export default {
  name: "ComparisonFilter",
  components: {
    ComparisonTaskGroundTruth,
    ChooseValidationTarget,
    ConfidenceThresholdFilter,
    IoUThresholdFilter,
    MetricFilter,
    IgnoreBoxesFilter,
    IconButton,
  },
  props: {
    annotationSets: {
      type: Array,
      default: () => [],
    },
    modelValue: {
      type: Object,
      default: null,
    },
    filterList: {
      type: Array,
      default: () => [
        { name: 'ComparisonTaskGroundTruth', key: 'ground_truth_annotation_set_id' },
        { name: 'ChooseValidationTarget', key: 'predicted_annotation_set_id' },
        { name: 'IoUThresholdFilter', key: 'iou' },
        { name: 'ConfidenceThresholdFilter', key: 'confidence' },
        // { name: 'MetricFilter', key: 'metric' },
        // { name: 'IgnoreBoxesFilter', key: 'ignoreBoxes' },
      ],
    },
    labels: {
      type: Array,
      default: () => [],
    },
    loadingValidations: {
      type: Boolean,
      default: false,
    },
  },
  emits: [
    'update:modelValue', 'apply',
  ],
  data() {
    return {
      filters: {
        // iou: 0.5,
        // confidence: 0.25,
      },
      collapse: false,
    };
  },
  computed: {
    filterProps() {
      return {
        ground_truth_annotation_set_id: {
          annotationSets: this.annotationSets,

        },
        predicted_annotation_set_id: {
          annotationSets: this.annotationSets,

        },
        confidence: {

        },
        iou: {

        },
        metric: {

        },
        ignoreBoxes: {

        },
      };
    },
    datasetID() {
      return this.$store.state.datasets.currentDatasetID;
    },
    filterValue() {
      return Object.keys(this.filters)
        .filter((key) => this.filters[key] !== null)
        .reduce((result, key) => {
          result[key] = this.filters[key];
          return result;
        }, {});
    },
  },
  watch: {
    filterValue: {
      deep: true,
      handler() {
        this.$emit('update:modelValue', this.filterValue);
      },
    },
  },
  mounted() {
    if (this.modelValue) {
      this.filters = structuredClone(toRaw(this.modelValue));
    }
    this.$emit('update:modelValue', this.filterValue);
  },
  unmounted() {

  },
  methods: {

  },
};
</script>

<style lang="scss" scoped>
.filters {
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;

  &._collapse {
    flex-wrap: nowrap;
    gap: 12px;

    .filters__active-and-default-filters {
      margin-bottom: -8px;
      position: relative;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      flex: unset;
      gap: 12px;
      overflow-x: auto;
      overflow-y: hidden;
    }

    .filters__more-filters {
      position: relative;
      display: flex;
      flex-direction: row;
      flex: unset;
      gap: 12px;
    }

    #expand_less {
      position: absolute;
      bottom: -8px;
      right: -16px;
      transform: rotateX(180deg);
      &:hover {
        @include themify() {
          background-color: themed('icon-hover-color');
          border-radius: unset;
        }
      }
    }
  }

  &__active-and-default-filters {
    position: relative;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    flex: 1 1 auto;
    gap: 12px;

    button:last-child {
      margin-left: auto;
    }
  }

  &__more-filters {
    position: relative;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    flex: 1 1 auto;
    gap: 12px;
  }

  #expand_less {
    position: absolute;
    bottom: -8px;
    right: -16px;
    &:hover {
      @include themify() {
        background-color: themed('icon-hover-color');
        border-radius: unset;
      }
    }
  }
}

.apply-button {
  width: 140px;
}
</style>
