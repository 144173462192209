import { storeToRefs } from 'pinia';
import { useViewerVisualizationsStore } from '@/stores/useViewerVisualizationsStore.js';
import DatastoreConnect from '@/assets/js/DatastoreFunctions/datastore-interface';
import pLimit from 'p-limit';
import { useStore } from 'vuex';
import usePCD from '@/composables/annotationTool/usePCD.js';
import { decode } from 'fast-png';

export default function useViewerVisualizations(selectedAnnotationSets) {
  const store = useStore();
  const max_concurrent_req = 6;
  const limit = pLimit(max_concurrent_req);
  const bufferLength = 300;
  const backCache = 20;
  const cacheTypes = ['pcd'];
  const blobCacheTypes = ['depthmap'];
  let abortControllerForCaching = null;
  const fpsSkipMap = {
    1: { fps: 1, skip: 0 },
    5: { fps: 5, skip: 0 },
    10: { fps: 10, skip: 0 },
    20: { fps: 10, skip: 1 },
    100: { fps: 10, skip: 10 },
  };

  const visualizationStore = useViewerVisualizationsStore();
  const {
    abortSignalForCaching,
    samples,
    animationImageCache,
    frame,
    playing,
    buffered,
    currentAnimationSample,
    keyframes,
    doKeyframesExist,
    frameCount,
    fps,
    isInitializingAnimation,
    topics,
  } = storeToRefs(visualizationStore);
  const {
    pauseAnimation,
    updateSample,
    throttledUpdateSample,
    resetForNewSequence,
    getAnimationDelay,
    $reset,
  } = visualizationStore;

  const { parsePCDFile } = usePCD();

  async function cacheImages(localSignal) {
    localSignal.addEventListener('abort', () => {
      limit.clearQueue();
    });
    const lower = Math.max(0, frame.value - 1 - backCache);
    const upper = Math.min(frame.value - 1 + bufferLength, samples.value.length - 1);
    const temp = {};

    for (let key = lower; key <= upper; key++) {
      if (key in animationImageCache.value) {
        temp[key] = animationImageCache.value[key];
      }
    }

    animationImageCache.value = temp;

    const samplePromises = [];
    for (let i = lower + 1; i <= upper; i++) {
      if (!animationImageCache.value[i]) {
        animationImageCache.value[i] = {
          image: null,
          imageObj: samples.value[frame.value - 1],
          annotations: [],
          loaded: false,
          ...Object.fromEntries(topics.value.map((key) => [key, []])),
        };
        // if loadsample is called outside of limit() it will run regardless of limit
        samplePromises.push(limit(() => loadSample(samples.value, i, localSignal)));
      }
    }
    // console.log('samplePromises:', samplePromises.length);
    if (samplePromises.length > 0) {
      await Promise.all(samplePromises);
    }
  }

  async function parseAnnotation(annotation, sampleIndex) {
    if (animationImageCache.value[sampleIndex] && annotation.type === 'pcd' && annotation.data) {
      animationImageCache.value[sampleIndex].pcd.push(parsePCDFile(annotation.data));
    }
    if (animationImageCache.value[sampleIndex] && annotation.type === 'depthmap' && annotation.data) {
      await convertBlobToGradientImage(annotation.data, sampleIndex);
    }
  }

  function loadAnnotationUrls(imageObj, sampleIndex, annotations) {
    if (imageObj.id && annotations) {
      const annotationPromises = [];
      const dataConnect = new DatastoreConnect();
      annotations.forEach((anno) => {
        if (anno.data_url && cacheTypes.includes(anno.type)) {
          annotationPromises.push(
            new Promise(async (resolve, reject) => {
              const pcdFile = await dataConnect.getAnnotationFile(anno.id, false, abortSignalForCaching.value);
              anno.data = pcdFile;
              parseAnnotation(anno, sampleIndex);
              resolve();
            }),
          );
        } else if (anno.data_url && blobCacheTypes.includes(anno.type)) {
          annotationPromises.push(
            new Promise(async (resolve, reject) => {
              const blob = await dataConnect.getAnnotationFile(anno.id, true, abortSignalForCaching.value);
              anno.data = blob;
              parseAnnotation(anno, sampleIndex);
              resolve();
            }),
          );
        } else if (anno.polygon && cacheTypes.includes(anno.type)) {
          annotationPromises.push(
            new Promise((resolve, reject) => {
              anno.data = anno.polygon;
              parseAnnotation(anno, sampleIndex);
              resolve();
            }),
          );
        }
      });
      return Promise.allSettled(annotationPromises);
    }
    return Promise.reject(new Error(`Load annotations for sample ${sampleIndex} missing data`));
  }

  async function fetchAnnotations(sample, index) {
    return new Promise((resolve, reject) => {
      const dataConnect = new DatastoreConnect();
      try {
        dataConnect.getAnnotations(
          {
            image_id: sample.id,
            annotation_set_id: selectedAnnotationSets.value,
          },
        ).then((data) => {
          if (!data.result || data.error) {
            if (animationImageCache.value[index] && animationImageCache.value[index].annotations) {
              animationImageCache.value[index].annotations = [];
              resolve([]);
            } else {
              // reject(new Error(`Sample value ${index} does not exist. Can not set value "annotations"`));
              resolve(null);
            }
          } else {
            if (animationImageCache.value[index] && animationImageCache.value[index].annotations) {
              animationImageCache.value[index].annotations = data.result;
              resolve(data.result);
            } else {
              resolve(null);
              // reject(new Error(`Sample value ${index} does not exist. Can not set value "annotations"`));
            }
          }
        }).catch((e) => {
          reject(e);
        });
      } catch (error) {
        reject(error);
      }
    });
  }

  async function loadSample(sampleList, index, localSignal) {
    return new Promise((resolve, reject) => {
      if (localSignal && localSignal.aborted) {
        reject(new Error(`Loading sample ${index} aborted`));
      }

      loadImage(sampleList[index], index)
        .then(() => fetchAnnotations(sampleList[index], index))
        .then((annotations) => loadAnnotationUrls(sampleList[index], index, annotations))
        .then((results) => {
          const allSuccess = results.every((result) => result.status === 'fulfilled');
          if (allSuccess) {
            animationImageCache.value[index].loaded = true;
          }
          resolve();
        })
        .catch((e) => {
          // ignore errors
          console.log(e, `Skipped frame`);
          resolve();
        });
    });
  }

  function loadImage(imageObj, sampleIndex) {
    if (imageObj.id) {
      return new Promise(async (resolve, reject) => {
        await fetch(`image/redirect/${imageObj.id}`, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${store.state.user.token}`,
          },
        })
          .then((response) => {
            if (!response.ok) {
              throw new Error(`Error loading image`);
            }
            return response.blob();
          })
          .then((blob) => {
            const image = document.createElement('img');
            image.onerror = (e) => {
              reject(e);
            };
            image.onload = () => {
              if (animationImageCache.value[sampleIndex]) {
                animationImageCache.value[sampleIndex].image = image;
                animationImageCache.value[sampleIndex].imageObj = imageObj;

                // Add review status to annotation if it exists
                if (imageObj.review_status === 'Done' && imageObj.annotations) {
                  imageObj.annotations.forEach((anno) => {
                    anno.reviewStatus = 'verified';
                  });
                }
                resolve();
              } else {
                // reject(new Error(`Sample value ${sampleIndex} does not exist. Can not set value "image"`));
                resolve();
              }
            };
            image.src = URL.createObjectURL(blob);
          })
          .catch((e) => {
            console.log(e);
            reject();
          });
      });
    }
  }

  function abortCaching() {
    if (abortControllerForCaching) {
      abortControllerForCaching.abort();
    }

    abortControllerForCaching = new AbortController();
    abortSignalForCaching.value = abortControllerForCaching.signal;
  }

  async function startAnimation() {
    playing.value = true;
    const delay = (ms) => new Promise((resolve) => {
      setTimeout(() => resolve(), ms);
    }, ms);

    // const lastFrame = Math.max(0, Math.max(...Object.keys(animationImageCache.value).map((e) => parseInt(e))));

    if (frame.value === frameCount.value + 1) {
      frame.value = 1;
    }

    while (true) {
      // Break if paused
      if (!playing.value) {
        break;
      }
      if (frame.value + 1 <= frameCount.value + 1 && animationImageCache.value?.[frame.value]?.loaded) {
        let nextFrame = frame.value + 1 + fpsSkipMap[fps.value].skip;
        if (nextFrame > frameCount.value) {
          nextFrame = frameCount.value;
        }
        frame.value = nextFrame;
        updateSample();
        cacheImages(abortSignalForCaching.value);
      }
      await delay(getAnimationDelay(fpsSkipMap[fps.value].fps));
      if (frame.value === frameCount.value + 1) {
        break;
      }
    }
    playing.value = false;
  }

  async function convertBlobToGradientImage(uint16Array, sampleIndex) {
    const reader = new FileReader();

    const arrayBuffer = await uint16Array.arrayBuffer();
    const decodedImage = await decode(arrayBuffer);

    reader.onload = () => {
      const depthData16Bit = decodedImage.data; // Read as 16-bit data
      const width = decodedImage.width;
      const height = decodedImage.height;
      let minDepthValue = Infinity;
      let maxDepthValue = -Infinity;

      for (let i = 0; i < depthData16Bit.length; i++) {
        const value = depthData16Bit[i];

        // Update min and max values
        if (value < minDepthValue) {
          minDepthValue = value;
        }
        if (value > maxDepthValue) {
          maxDepthValue = value;
        }
      }
      // Create OffscreenCanvas
      const offscreenCanvas = new OffscreenCanvas(width, height);
      const ctx = offscreenCanvas.getContext('2d');

      // Create ImageData object for the OffscreenCanvas
      const imageData = ctx.createImageData(width, height);
      const data = imageData.data;

      // Process each 16-bit depth value and map it to red-blue
      for (let i = 0; i < depthData16Bit.length; i++) {
        const depth16Bit = depthData16Bit[i];

        // Normalize depth to a range [0, 1]
        const normalizedDepth = (depth16Bit - minDepthValue) / (maxDepthValue - minDepthValue);

        // Scale normalized depth to [0, 255]
        const scaledDepth = Math.floor(normalizedDepth * 255);

        // Map normalized grayscale to red-blue gradient
        const red = Math.min(255, 2 * scaledDepth); // Red increases as depth decreases
        const blue = Math.min(255, 2 * (255 - scaledDepth)); // Blue increases as depth increases
        const green = 0; // No green in the gradient

        // Set pixel color in the image data
        const pixelIndex = i * 4;
        data[pixelIndex] = red; // Red
        data[pixelIndex + 1] = green; // Green
        data[pixelIndex + 2] = blue; // Blue
        data[pixelIndex + 3] = 255; // Full opacity
      }

      // Put the modified pixel data back onto the OffscreenCanvas
      ctx.putImageData(imageData, 0, 0);

      // Convert the OffscreenCanvas to a blob and create an image
      offscreenCanvas.convertToBlob().then((newBlob) => {
        const resultImage = new Image();
        resultImage.src = URL.createObjectURL(newBlob);
        resultImage.onload = () => {
          animationImageCache.value[sampleIndex].depthmap.push(resultImage);
        };
        resultImage.onerror = (e) => { console.log(e); };
      });
    };

    reader.readAsArrayBuffer(uint16Array);
  }

  return {
    abortSignalForCaching,
    samples,
    animationImageCache,
    frame,
    playing,
    buffered,
    currentAnimationSample,
    keyframes,
    doKeyframesExist,
    frameCount,
    fps,
    isInitializingAnimation,
    topics,

    cacheImages,
    parseAnnotation,
    loadAnnotationUrls,
    fetchAnnotations,
    loadSample,
    loadImage,
    abortCaching,

    startAnimation,
    pauseAnimation,
    updateSample,
    throttledUpdateSample,
    resetForNewSequence,
    $reset,
  };
}
