<template>
  <Modal
    ref="createAnnotationSetModal"
    :title="'Create New Annotation Set'"
  >
    <!-- <template #modal-header /> -->
    <template #modal-main>
      <div class="settings container">
        <div class="row mb-3">
          <div class="col-12 settings__control-group">
            <label>Name</label>
            <input
              ref="annSetName"
              v-model="name"
              class="select"
              type="text"
            >
          </div>
        </div>
        <div class="row">
          <div class="col-12 settings__control-group">
            <label>Description</label>
            <textarea
              v-model="description"
              rows="4"
              cols="50"
            />
          </div>
        </div>
      </div>
    </template>
    <template #modal-footer>
      <div class="footer">
        <button
          class="button modal-action-button"
          @click="createAnnotationSet"
        >
          Create New Set
        </button>
      </div>
    </template>
  </Modal>
</template>

<script>
import DatastoreConnect from '@/assets/js/DatastoreFunctions/datastore-interface';
import Modal from '@/components/Modal.vue';

export default {
  name: 'CreateAnnotationSetModal',
  components: {
    Modal,
  },
  props: {
    dataset: {
      type: Object,
      default: null,
    },
  },
  emits: ['annotation-set-created', 'annotation-set-created-error'],
  data() {
    return {
      name: '',
      description: '',
    };
  },
  computed: {
    user() {
      return this.$store.state.user.user;
    },
  },
  created() {
    this.dataConnect = new DatastoreConnect(this.$store.state.enterpriseServerUrl);
  },
  methods: {
    showModal() {
      this.$refs.createAnnotationSetModal.showModal();
      this.$nextTick(() => {
        this.$refs.annSetName.focus();
      });
    },
    async createAnnotationSet() {
      if (!this.name) {
        console.error('Missing name');
        return;
      }
      const resp = await this.dataConnect.createAnnotationSet({
        name: this.name,
        description: this.description,
        operator: this.user.username,
        origin_x: 0,
        origin_y: 0,
        dataset_id: this.dataset.id,
      })
        .catch((error) => {
          // TODO: handle error
          console.log(error);
        });
      if (resp.error || !resp.result) {
        this.$emit('annotation-set-created-error', resp.error);
      } else {
        this.name = '';
        this.description = '';
        this.$refs.createAnnotationSetModal.closeModal();
        this.$emit('annotation-set-created', resp.result);
      }
    },
  },
};

</script>

<style lang="scss" scoped>
.settings {
  padding: 0.75rem 3rem;
  min-width: 420px;
}

.footer {
  display: flex;
  flex-direction: row;
  padding: 0.75rem 3rem;
  justify-content: flex-end;
}

</style>
