import { createStore } from 'vuex';
import VuexPersistence from 'vuex-persist';
import DatastoreConnect from '@/assets/js/DatastoreFunctions/datastore-interface';
import { v4 as uuidv4 } from 'uuid';
import importer from './modules/importer/importer';
import annotations from './modules/importer/annotations';

const vuexLocal = new VuexPersistence({
  storage: window.sessionStorage,
  modules: ['appVersion', 'user', 'datasets', 'annotationTool', 'projects', 'reviews', 'statistics', 'notifications'],
});

const appVersion = {
  namespaced: true,
  state: () => ({
    version: '',
    isSaas: '',
    allowDebug: '',
    frontendVersion: '',
  }),
  mutations: {
    setVersion(state, payload) {
      state.version = payload;
    },
    setIsSaas(state, payload) {
      state.isSaas = payload;
    },
    setFrontendVersion(state, payload) {
      state.frontendVersion = payload;
    },
    setAllowDebug(state, payload) {
      state.allowDebug = payload;
    },
  },
};

const user = {
  namespaced: true,
  state: () => ({
    user: null,
    token: null,
    tokenRefreshTask: null,
    permissions: null,
  }),
  mutations: {
    setUser(state, userPayload) {
      state.user = userPayload;
    },
    setToken(state, tokenPayload) {
      state.token = tokenPayload;

      store.dispatch('user/autoRefreshToken', tokenPayload);
    },
    removeToken(state) {
      clearInterval(state.tokenRefreshTask);
      state.tokenRefreshTask = null;
      state.token = "";
    },
    setTokenRefreshTask(state, tokenRefreshTimerPayload) {
      state.tokenRefreshTask = tokenRefreshTimerPayload;
    },
    setPermissions(state, payload) {
      state.permissions = payload;
    },
  },
  actions: {
    autoRefreshToken(context, tokenPayload) {
      // Handle refreshing token
      const parseJwt = (token) => {
        try {
          return JSON.parse(atob(token.split('.')[1]));
        } catch (e) {
          return null;
        }
      };
      const parsedToken = parseJwt(tokenPayload);
      const tokenExpireInSeconds = parsedToken.exp - (new Date().getTime() / 1000);

      // Refresh token automatically 30 seconds before it expires
      const tokenRefreshTimer = setTimeout(async () => {
        // Get new token from server
        const dataConnect = new DatastoreConnect(store.state.enterpriseServerUrl);
        const resp = await dataConnect.refreshToken({
          username: parsedToken.username,
        })
          .catch((error) => {
            console.log(error);
            alert(error);
          });
        if (!resp || resp.error || !resp.result) {
          if (resp && resp.error) {
            console.error(resp.error);
            return;
          }
        }
        const newToken = resp.result.token;
        context.commit('setToken', newToken);
      }, (tokenExpireInSeconds - 30) * 1000);
      context.commit('setTokenRefreshTask', tokenRefreshTimer);
    },
  },
};

const projects = {
  namespaced: true,
  state: () => ({
    projectList: [],
    currentProject: null,
  }),
  mutations: {
    setCurrentProject(state, payload) {
      state.currentProject = payload;
    },
    setProjectList(state, payload) {
      state.projectList = payload;
    },
    resetState(state) {
      state.currentProject = null;
      state.projectList = [];
    },
  },

};

const statistics = {
  namespaced: true,
  state: () => ({
    datasetID: null,
    set: null,
    labelLocation: null,
    labelVisualizer: null,
  }),
  mutations: {
    setDatasetID(state, payload) {
      state.datasetID = payload;
    },
    setAnnotationSet(state, payload) {
      state.set = payload;
    },
    setLabelLocation(state, payload) {
      state.labelLocation = payload;
    },
    setLabelVisualizer(state, payload) {
      state.labelVisualizer = payload;
    },
    resetState(state) {
      state.datasetID = null;
      state.set = null;
      state.labelLocation = null;
      state.labelVisualizer = null;
    },
  },

};

const reviews = {
  namespaced: true,
  state: () => ({
    usersFilter: [],
  }),
  mutations: {
    setUsersFilter(state, payload) {
      state.usersFilter = payload;
    },
    resetState(state) {
      state.usersFilter = [];
    },
  },

};

const datasets = {
  namespaced: true,
  state: () => ({
    currentDataset: null,
    datasetList: [],
    annotationSetColorMap: null,
    annotationsTabState: null,
    labelColorMap: null,
    uploadFolderTasks: {},
    confMatrixTargetSet: null,
  }),
  mutations: {
    setCurrentDataset(state, payload) {
      state.currentDataset = payload;

      // Clear states dependent on the current dataset
      state.annotationsTabState = null;
    },
    setAnnotationsTabState(state, payload) {
      state.annotationsTabState = payload;
    },
    clearDatasetsState(state) {
      state.currentDataset = null;
      state.annotationsTabState = null;
    },
    setAnnotationSetColorMap(state, payload) {
      state.annotationSetColorMap = payload;
    },
    setLabelColorMap(state, payload) {
      state.labelColorMap = payload;
    },
    setDatasetList(state, payload) {
      state.datasetList = payload;
    },
    setConfMatrixTargetSet(state, payload) {
      state.confMatrixTargetSet = payload;
    },
    setDataset(state, payload) {
      state.datasetList[payload.index] = payload.dataset;
      store.dispatch('datasets/updateCurrentDatasetAction');
    },
    setDatasetAnnotationSets(state, payload) {
      if (payload.index >= 0) {
        state.datasetList[payload.index]['annotation_sets'] = payload.sets;
      }
      store.dispatch('datasets/updateCurrentDatasetAction');
    },
    setDatasetLabels(state, payload) {
      state.datasetList[payload.index]['labels'] = payload.labels;
      store.dispatch('datasets/updateCurrentDatasetAction');
    },
    updateCurrentDataset(state, payload) {
      const id = state.currentDataset?.id;
      if (id) {
        state.currentDataset = state.datasetList.find((e) => e.id === id);
      }
    },
    resetState(state) {
      state.currentDataset = null;
      state.datasetList = [];
      state.annotationSetColorMap = null;
      state.annotationsTabState = null;
      state.labelColorMap = null;
      state.uploadFolderTasks = {};
    },
  },
  actions: {
    updateCurrentDatasetAction(context) {
      context.commit('datasets/updateCurrentDataset', {}, { root: true });
    },
  },
};

const tasks = {
  namespaced: true,
  state: () => ({
    uploadTasks: {},
    importTasks: {},
    exportTasks: {},
  }),
  mutations: {
    // ----------- Uploads --------------
    addNewUploadTask(state, task) {
      task.isLocal = true;
      state.uploadTasks = { ...state.uploadTasks, [task.id]: { ...task } };
      state.uploadTasks[task.id].interval = setInterval(() => { store.dispatch('tasks/getUploadTaskDockerInterval', task.id); }, 1000);
    },
    resumeUploadTask(state, task) {
      state.uploadTasks[task.id].isLocal = true;
      state.uploadTasks[task.id].interval = setInterval(() => { store.dispatch('tasks/getUploadTaskDockerInterval', task.id); }, 1000);
    },
    addExistingUploadTask(state, task) {
      if (!state.uploadTasks[task.id]) {
        if (task.username === store.state.user.user.username && task.status === 'running') {
          const dataConnect = new DatastoreConnect();
          dataConnect.updateDockerTaskStatus({
            docker_task_id: task.id,
            status: 'terminated',
          });
          task.status = 'terminated';
        }

        state.uploadTasks = { ...state.uploadTasks, [task.id]: { ...task } };
        state.uploadTasks[task.id].interval = setInterval(() => { store.dispatch('tasks/getUploadTaskDockerInterval', task.id); }, 1000);
      }
    },
    removeUploadTask(state, { id }) {
      delete state.uploadTasks[id];
    },
    updateUploadTask(state, task) {
      Object.keys(task).forEach((key) => {
        state.uploadTasks[task.id][key] = task[key];
      });
    },
    clearUploadTaskInterval(state, docker_task_id) {
      if (state.uploadTasks[docker_task_id]?.interval) {
        clearInterval(state.uploadTasks[docker_task_id].interval);
      }
    },

    // ------------ Import --------------
    addImportTask(state, payload) {
      state.importTasks[payload.id] = {
        dataset_id: payload.dataset_id,
      };

      state.importTasks[payload.id].interval = setInterval(() => { store.dispatch('tasks/getImportTaskDockerInterval', payload.id); }, 1000);
    },
    removeImportTask(state, payload) {
      if (state.importTasks[payload.id]?.interval) {
        clearInterval(state.importTasks[payload.id].interval);
      }
      delete state.importTasks[payload.id];
    },
    clearImportTaskInterval(state, payload) {
      if (state.importTasks[payload.id]?.interval) {
        clearInterval(state.importTasks[payload.id].interval);
      }
    },
    updateImportTaskStatus(state, payload) {
      state.importTasks[payload.id].status = payload.data.status;
      state.importTasks[payload.id].name = payload.data.name;
      state.importTasks[payload.id].date = payload.data.date;
      state.importTasks[payload.id].progress = payload.data.progress;
    },

    // ------------ Export --------------
    addExportTask(state, payload) {
      state.exportTasks[payload.id] = {
        dataset_id: payload.dataset_id,
      };
      state.exportTasks[payload.id].interval = setInterval(() => { store.dispatch('tasks/getExportTaskDockerInterval', payload.id); }, 1000);
    },
    removeExportTask(state, payload) {
      clearInterval(state.exportTasks[payload.id].interval);
      delete state.exportTasks[payload.id];
    },
    clearExportTaskInterval(state, payload) {
      clearInterval(state.exportTasks[payload.id].interval);
    },
    updateExportTaskStatus(state, payload) {
      state.exportTasks[payload.id].status = payload.data.status;
      state.exportTasks[payload.id].name = payload.data.name;
      state.exportTasks[payload.id].date = payload.data.date;
      state.exportTasks[payload.id].progress = payload.data.progress;
    },

    resetUploadTasks(state) {
      Object.entries(state.uploadTasks).forEach(([key, task]) => {
        if (!task.isLocal) {
          clearInterval(state.uploadTasks[key].interval);
          delete state.uploadTasks[key];
        }
      });
    },
    resetImportTasks(state) {
      Object.keys(state.importTasks).forEach((key) => {
        clearInterval(state.importTasks[key].interval);
        delete state.importTasks[key];
      });
    },
    resetExportTasks(state) {
      Object.keys(state.exportTasks).forEach((key) => {
        clearInterval(state.exportTasks[key].interval);
        delete state.exportTasks[key];
      });
    },
    resetConverterTasks(state) {
      Object.keys(state.converterTasks).forEach((key) => {
        clearInterval(state.converterTasks[key].interval);
        delete state.converterTasks[key];
      });
    },
  },
  actions: {
    async getUploadTaskDockerInterval(context, docker_task_id) {
      const dataConnect = new DatastoreConnect();
      await dataConnect.getDockerTaskById({ docker_task_id })
        .then((data) => {
          if (data.result) {
            const task = data.result;
            if (task.status === 'complete' || task.status === 'error' || task.status === 'terminated') {
              context.commit('tasks/clearUploadTaskInterval', docker_task_id, { root: true });
            }
            context.commit('tasks/updateUploadTask', task, { root: true });
          }
        })
        .catch((error) => {
          // TODO: handle error
          console.log(error);
        });
    },
    async getImportTaskDockerInterval(context, docker_task_id) {
      const res = {
        status: '',
        name: '',
        date: null,
        progress: 0,
      };
      const dataConnect = new DatastoreConnect(store.state.enterpriseServerUrl);
      await dataConnect.getDockerTaskById({ docker_task_id })
        .then((data) => {
          res.status = data.result.status;
          res.name = data.result.name;
          res.date = data.result.date;
          if (data.result.message !== 'Complete' && data.result.status !== 'complete' && data.result.status !== 'error') {
            res.progress = Math.round((data.result.count / data.result.total) * 100);
          } else {
            res.progress = 100;
            context.commit('tasks/clearImportTaskInterval', { docker_task_id }, { root: true });
          }
          context.commit('tasks/updateImportTaskStatus', { docker_task_id, data: res }, { root: true });
        })
        .catch((error) => {
          // TODO: handle error
          console.log(error);
        });
    },
    async getExportTaskDockerInterval(context, docker_task_id) {
      const res = {
        status: '',
        name: '',
        date: null,
        progress: 0,
      };
      const dataConnect = new DatastoreConnect(store.state.enterpriseServerUrl);
      await dataConnect.getDockerTaskById({ docker_task_id })
        .then((data) => {
          res.status = data.result.status;
          res.name = data.result.name;
          res.date = data.result.date;
          if (data.result.message !== 'Complete' && data.result.status !== 'complete' && data.result.status !== 'error') {
            res.progress = Math.round((data.result.count / data.result.total) * 100);
          } else {
            res.progress = 100;
            context.commit('tasks/removeExportTask', { docker_task_id }, { root: true });
          }
          context.commit('tasks/updateExportTaskStatus', { docker_task_id, data: res }, { root: true });
        })
        .catch((error) => {
          // TODO: handle error
          console.log(error);
        });
    },
  },
};

const annotationTool = {
  namespaced: true,
  state: () => ({
    samEnabled: false,
  }),
  mutations: {
    toggleSAMEnabled(state, isEnabled) {
      state.samEnabled = isEnabled;
    },
    resetState(state) {
      state.samEnabled = false;
    },
  },
};

const notifications = {
  namespaced: true,
  state: () => ({
    hasNotifications: false,
  }),
  mutations: {
    updateNotifications(state, payload) {
      state.hasNotifications = payload;
    },
  },
};

const store = createStore({
  strict: process.env.NODE_ENV !== 'production',
  modules: {
    appVersion,
    user,
    datasets,
    notifications,
    annotationTool,
    projects,
    reviews,
    statistics,
    tasks,
    importer,
    annotations,
  },
  state() {
    return {
      debugMode: false,
      theme: 'theme-auzone-light',
      trainerServerUrl: null,
      datastoreServerUrl: null,
      enterpriseServerHost: null,
      enterpriseServerUrl: null,
      samServerUrl: null,
      currentDataset: {},
    };
  },
  mutations: {
    // IMPORTANT: mutations take exactly 2 parameters: state and payload.
    setDebugMode(state, mode) {
      state.debugMode = mode;
    },
    setTrainerServerUrl(state, url) {
      state.trainerServerUrl = url;
    },
    setDatastoreServerUrl(state, url) {
      state.datastoreServerUrl = url;
    },
    setEnterpriseServerHost(state, host) {
      state.enterpriseServerHost = host;
    },
    setEnterpriseServerUrl(state, url) {
      state.enterpriseServerUrl = url;
    },
    setSAMServerUrl(state, url) {
      state.samServerUrl = url;
    },
    setCurrentDataset(state, dataset) {
      state.currentDataset = dataset;
      // state.currentTrainer['DatasetName'] = dataset.Name ? dataset.Name : '';
      // state.currentTrainer['DatasetName'] = dataset.Filename ? dataset.Filename : '';
    },
    setTheme(state, theme) {
      state.theme = theme;

      const app = document.querySelector('#app');
      app.className = "";
      app.classList.add(theme);
      const root = document.documentElement;
      root.className = "";
      root.classList.add(theme);
    },
  },
  plugins: [vuexLocal.plugin],
});

export default store;
