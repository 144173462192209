<template>
  <div class="utilities">
    <div class="utilities__buttons">
      <slot name="utilities-slot" />
    </div>
    <button class="dropdown-toggle-all" @click="toggleDropdownAll"><span>{{ openedDropdowns.length > 0 ? 'Collapse All' : 'Expand All' }}</span></button>
  </div>
  <div class="json-raw-viewer scrollbar">
    <v-expansion-panels v-model="openedDropdowns" multiple>
      <v-expansion-panel v-if="imageObj" value="image" class="panel">
        <v-expansion-panel-title class="panel__title" title="Image">
          <div class="panel__title-text">
            <span>Image</span>
            <!-- <IconButton
              v-model="internalShowTypes['image']"
              aria-label="Toggle Visibility"
              title="Toggle Visibility"
              class="ms-2"
              :icon="'visibility_off'"
              :iconActive="'visibility'"
              :width="21"
              :height="21"
              :type="'toggle-icon'"
              :showHover="true"
            /> -->
          </div>
        </v-expansion-panel-title>
        <v-expansion-panel-text class="panel__text">
          <div class="row panel__item">
            <div class="col-12 settings__control-group">
              <ImageDetails :imageObj="imageObj" />
            </div>
          </div>
        </v-expansion-panel-text>
      </v-expansion-panel>
      <v-expansion-panel value="annotation-sets" class="panel">
        <v-expansion-panel-title class="panel__title" title="Annotation Sets">
          <div class="panel__title-text">
            <span>Annotation Sets</span>
            <label for="set" class="panel__radio-label" title="Color Annotations by Sets">
              <input
                id="set"
                v-model="displayColorBy"
                type="radio"
                value="set"
                class="color-radio set icon"
                @click.stop=""
              >
              <template v-if="!isEditing">
                <SVGIcon
                  v-if="displayColorBy === 'set'"
                  :iconName="'invert_colors'"
                  :width="'21px'"
                  :height="'21px'"
                  class="radio-icon"
                  :class="{ 'active-icon': displayColorBy==='set' }"
                  @click.stop=""
                />
                <SVGIcon
                  v-else
                  :iconName="'invert_colors_off'"
                  :width="'21px'"
                  :height="'21px'"
                  @click.stop=""
                />
              </template>
            </label>
          </div>
        </v-expansion-panel-title>
        <v-expansion-panel-text class="panel__text chkbox-list">
          <v-checkbox
            v-for="s in annotationSets"
            :key="`set-checkbox-${s.id}`"
            v-model="internalSelectedAnnotationSets"
            :value="s['id']"
            :hide-details="true"
            :disabled="Boolean(fixedAnnotationSet)"
            density="compact"
            class="chkbx-li"
          >
            <template #label>
              <div class="annset-legend-color" :style="{ background: annotationSetColorMap[s.id] }" />
              <div class="check-box-text">
                {{ s['name'] }}
              </div>
            </template>
            <template #append>
              <IconButton
                aria-label="Toggle Visibility"
                title="Edit Annotations"
                :icon="'edit'"
                :width="22"
                :height="22"
                :type="'click'"
                :showHover="true"
                @click="handleEditAnnotations(s)"
              />
            </template>
          </v-checkbox>
        </v-expansion-panel-text>
      </v-expansion-panel>
      <v-expansion-panel value="labels" class="panel">
        <v-expansion-panel-title class="panel__title" title="Labels">
          <div class="panel__title-text">
            <span>Labels</span>
            <label for="label" class="panel__radio-label" title="Color Annotations by Labels">
              <input
                id="label"
                v-model="displayColorBy"
                type="radio"
                value="label"
                class="color-radio label icon"
                @click.stop=""
              >
              <template v-if="!isEditing">
                <SVGIcon
                  v-if="displayColorBy==='label'"
                  :iconName="'invert_colors'"
                  :width="'21px'"
                  :height="'21px'"
                  class="radio-icon"
                  :class="{ 'active-icon':displayColorBy==='label' }"
                  @click.stop=""
                />
                <SVGIcon
                  v-else
                  :iconName="'invert_colors_off'"
                  :width="'21px'"
                  :height="'21px'"
                  class="radio-icon"
                  @click.stop=""
                />
              </template>
            </label>
          </div>
        </v-expansion-panel-title>
        <v-expansion-panel-text class="panel__text label-list-wrapper scrollbar">
          <ul class="label-list">
            <li
              v-for="label in labels"
              :key="`label-checkbox-${label.id}`"
              class="chkbox-flex-row"
              :class="{_active: selectedLabelForAudit && selectedLabelForAudit.id === label.id && isEditing,
                       _hover: true && isEditing,
                       _pointer: isEditing}"
              @click="handleLabelListItemClicked(label)"
            >
              <v-checkbox
                v-model="internalSelectedLabels"
                :value="label.index"
                :hide-details="true"
                density="compact"
                class="chkbx-li label-chkbox"
                @click.stop
              />
              <div class="label-color-name">
                <ColorPicker
                  :color="getColor(label)"
                  :appendToBody="true"
                  :width="16"
                  :height="16"
                  :radius="0"
                  :offset="[-10, 20]"
                  @new-color="(color) => stopEditingColor(i, label, color)"
                />
                <span class="check-box-text">{{ label.name }}</span>
              </div>
            </li>
          </ul>
        </v-expansion-panel-text>
      </v-expansion-panel>
      <v-expansion-panel value="topics" class="panel">
        <v-expansion-panel-title class="panel__title" title="Topics">
          <div class="panel__title-text">
            <span>Topics {{ listedTopics && listedTopics.length > 0 ? `(${listedTopics.length})` : '' }}</span>
            <BaseMenu
              v-if="!hideAddTopics"
              :placement="'bottom-start'"
              :offset="[0,0]"
              :appendToBody="true"
              trigger="hover"
            >
              <template #menu>
                <div class="topic-selector-text scrollbar">
                  <div class="chkbox-list">
                    <v-checkbox
                      v-for="s in allTypes"
                      :key="s.key"
                      v-model="selectedTopics"
                      :value="s.key"
                      :hide-details="true"
                      density="compact"
                      class="chkbx-li"
                      :disabled="groupedAnnotations && Object.keys(groupedAnnotations).includes(s.key)"
                    >
                      <template #label>
                        <div class="check-box-text">
                          {{ s.display }}
                        </div>
                      </template>
                    </v-checkbox>
                  </div>
                </div>
              </template>
              <SVGIcon
                :iconName="'add'"
                class="topic-selector-icon"
                :width="'22px'"
                :height="'22px'"
                @click.stop=""
              />
            </BaseMenu>
          </div>
        </v-expansion-panel-title>
        <v-expansion-panel-text class="panel__text nowrap">
          <v-expansion-panels v-model="openedTopics" multiple>
            <v-expansion-panel
              v-for="topic in listedTopics"
              :key="topic.key"
              class="panel"
              :value="topic.key"
            >
              <v-expansion-panel-title class="panel__title" :title="topic.display">
                <div class="panel__title-text topic-title">
                  <span>{{ topic.display }}</span>
                  <div>
                    <IconButton
                      v-if="topic.key in internalDisplayLabelTags && jsonViewerShowTypes[topic.key]"
                      v-model="internalDisplayLabelTags[topic.key]"
                      aria-label="Toggle Display Labels"
                      title="Toggle Display Labels"
                      class="ms-2"
                      :icon="'tag'"
                      :iconActive="'tag_filled'"
                      :width="21"
                      :height="21"
                      :type="'toggle-icon'"
                      :showHover="true"
                    />
                    <IconButton
                      v-model="jsonViewerShowTypes[topic.key]"
                      aria-label="Toggle Visibility"
                      title="Toggle Visibility"
                      class="ms-2"
                      :icon="'visibility_off'"
                      :iconActive="'visibility'"
                      :width="21"
                      :height="21"
                      :type="'toggle-icon'"
                      :showHover="true"
                    />
                  </div>
                </div>
              </v-expansion-panel-title>
              <v-expansion-panel-text class="panel__text">
                <div
                  v-for="annotation in groupedAnnotations[topic.key]"
                  :key="annotation.id"
                  class="row panel__item"
                >
                  <div class="col-12 settings__control-group">
                    <template v-if="annotation.type==='pcd' || annotation.type==='radar' || annotation.type==='mask'">
                      <div class="raw-text">
                        <JsonView
                          :data="{ 'raw_file': annotation['raw'] }"
                          :rootKey="`${annotation.type}_${annotation.id}`"
                          :itemId="annotation.id"
                          :loading="loadingRaw"
                          :selectedAnnotationIdentifiers="selectedAnnotationIdentifiers"
                          @open-in-full="openInFull(annotation)"
                          @selected="handleAnnotationOpened(annotation)"
                        />
                      </div>
                    </template>
                    <template v-else-if="annotation.type==='box' || annotation.type==='seg'">
                      <div class="json-tree">
                        <JsonView
                          :data="formatAnnotation(annotation)"
                          :rootKey="annotation.label_name ? `${annotation.label_name}`: `${annotation.label_index}`"
                          :itemId="annotation.id"
                          :selectedAnnotationIdentifiers="selectedAnnotationIdentifiers"
                          :removable="true"
                          @selected="handleSelect(annotation)"
                          @remove-annotation="handleDeleteAnnotation"
                        />
                      </div>
                    </template>
                    <template v-else-if="annotation.type==='3dbox'">
                      <div class="json-tree">
                        <JsonView
                          :data="formatDataJson(annotation)"
                          :rootKey="annotation.label_name ? `${annotation.label_name}`: `${annotation.label_index}`"
                          :itemId="annotation.id"
                          :selectedAnnotationIdentifiers="selectedAnnotationIdentifiers"
                          :removable="true"
                          @selected="handleSelect(annotation)"
                          @remove-annotation="handleDeleteAnnotation"
                        />
                      </div>
                    </template>
                    <template v-else>
                      <div class="json-tree">
                        <JsonView
                          :data="formatDataJson(annotation)"
                          :rootKey="`${annotation.type}_${annotation.id}`"
                          :itemId="annotation.id"
                          :selectedAnnotationIdentifiers="selectedAnnotationIdentifiers"
                          @selected="handleSelect(annotation)"
                        />
                      </div>
                    </template>
                  </div>
                </div>
              </v-expansion-panel-text>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-expansion-panel-text>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script setup>
import JsonView from '@/components/JSONViewer/JsonView.vue';
import {
  ref, computed, onMounted, watch, toRefs, toRaw,
} from 'vue';
import { storeToRefs } from 'pinia';
import { useRoute } from 'vue-router';
import DatastoreConnect from '@/assets/js/DatastoreFunctions/datastore-interface';
import IconButton from '@/components/IconButton.vue';
import SVGIcon from '@/components/SVGIcon.vue';
import useAnnotationColorMap from '@/composables/useAnnotationColorMap.js';
import ImageDetails from '@/components/DatasetComponent/AnnotationTool/ImageDetails.vue';
import useAnnotationType from '@/composables/useAnnotationType.js';
import BaseMenu from '@/components/BaseMenu.vue';
import ColorPicker from '@/components/ColorPicker.vue';

const props = defineProps({
  imageObj: { type: Object, default: null },
  labels: { type: Array, default: () => [] },
  annotations: { type: Array, default: () => [] },
  annotationSets: { type: Array, default: () => [] },
  selectedAnnotationSets: { type: Array, default: null },
  fixedAnnotationSet: { type: Object, default: null },
  displayLabelTags: { type: Object, default: null },
  colorAnnotationsBy: { type: String, default: 'set' },
  groupedAnnotations: { type: Object, default: () => {} },
  hideAddTopics: { type: Boolean, default: false },
  selectedLabels: { type: Array, default: () => [] },
  selectedLabel: { type: Object, default: () => {} },
  selectedAnnotationIdentifiers: { type: Array, default: () => [] },
  isEditing: { type: Boolean, default: true },
});

const {
  imageObj, labels, annotations, annotationSets, fixedAnnotationSet,
  displayLabelTags, groupedAnnotations, hideAddTopics, selectedAnnotationIdentifiers,
  selectedLabel,
} = toRefs(props);

const {
  allTypes,
  selectedDisplayTypes,
  jsonViewerShowTypes,
} = useAnnotationType();

const selectedTopics = ref([]);

const internalAnnotations = ref(null);

const internalSelectedAnnotationSets = ref([]);

const internalSelectedLabels = ref([]);

const emit = defineEmits([
  'item-clicked',
  'update:displayLabelTags',
  'update:selectedAnnotationSets',
  'open-in-full',
  'update:selectedLabels',
  'update:selectedLabel',
  'update:colorAnnotationsBy',
  'edit-for-annotation-set',
  'select-annotations', 'new-color', 'remove-annotation',
]);

const dataConnect = new DatastoreConnect();

const openedDropdowns = ref(['topics']);

const openedTopics = ref([]);

const internalDisplayLabelTags = ref({ ...displayLabelTags.value });

const displayColorBy = ref('label');

const loadingRaw = ref(false);

const listedTopics = computed(() => allTypes.value.filter((e) => selectedTopics.value.includes(e.key)));

const selectedLabelForAudit = ref(labels.value ? labels.value[0] : {});

watch(groupedAnnotations, () => {
  if (groupedAnnotations.value) {
    selectedTopics.value = Object.keys(groupedAnnotations.value);
  }
}, { deep: true, immediate: true });

watch(listedTopics, () => {
  if (listedTopics.value) {
    selectedDisplayTypes.value = listedTopics.value;
  }
}, { deep: true, immediate: true });

watch(annotations, (newAnnotations) => {
  internalAnnotations.value = JSON.parse(JSON.stringify(newAnnotations));
}, { deep: true, immediate: true });

watch(internalDisplayLabelTags, () => {
  emit('update:displayLabelTags', internalDisplayLabelTags.value);
}, { deep: true });

watch(internalSelectedAnnotationSets, () => {
  emit('update:selectedAnnotationSets', internalSelectedAnnotationSets.value);
}, { deep: true });

watch(internalSelectedLabels, () => {
  emit('update:selectedLabels', internalSelectedLabels.value);
}, { deep: true });

watch(displayColorBy, () => {
  emit('update:colorAnnotationsBy', displayColorBy.value);
}, { deep: true });

watch(annotationSets, () => {
  if (annotationSets.value.length > 0 && internalSelectedAnnotationSets.value.length === 0) {
    internalSelectedAnnotationSets.value = [annotationSets.value[0].id];
  }
}, { deep: true });

watch(labels, () => {
  if (labels.value.length > 0) {
    // if the selected label for audit's identifier is not in the label list
    if (selectedLabelForAudit.value) {
      const isSelectedLabelInList = labels.value.find((e) => e.id === selectedLabelForAudit.value.id);
      if (!isSelectedLabelInList) {
        selectedLabelForAudit.value = labels.value[0];
        if (internalSelectedLabels.value.length === 0) {
          internalSelectedLabels.value = labels.value.map((e) => e.index);
        }
      }
    } else {
      selectedLabelForAudit.value = labels.value[0];
      if (internalSelectedLabels.value.length === 0) {
        internalSelectedLabels.value = labels.value.map((e) => e.index);
      }
    }
  }
}, { deep: true });

watch(fixedAnnotationSet, () => {
  internalSelectedAnnotationSets.value = [fixedAnnotationSet.value.id];
}, { deep: true });

if (fixedAnnotationSet.value) {
  internalSelectedAnnotationSets.value = [fixedAnnotationSet.value.id];
} else if (annotationSets.value.length > 0) {
  internalSelectedAnnotationSets.value = [annotationSets.value[0].id];
} else {
  internalSelectedAnnotationSets.value = [];
}

// Setup annotation color maps
const { map: labelColorMap } = useAnnotationColorMap({ items: labels, key: 'index' });
const { map: annotationSetColorMap } = useAnnotationColorMap({ items: annotationSets, key: 'id' });

function stopEditingColor(i, label, color) {
  if (!color || color === '') {
    return;
  }

  const params = {
    label_id: label.id,
    label_index: label.index,
    label_name: label.name,
    label_color: color,
  };

  emit('new-color', params);
}

function filterAnnotationsBySet(anns, sets) {
  if (sets) {
    return anns.filter((anno) => sets.includes(anno.annotation_set_id));
  }
  return anns;
}

function formatAnnotation(annotation) {
  const label = labels.value.find((e) => e.id === annotation.label_id);
  const result = {};
  if (annotation.type === "seg") {
    result.segments = annotation.polygon;
    if (label) {
      result.label_name = label.name;
      result.label_index = label.index;
    } else {
      result.label_name = "";
      result.label_index = 0;
    }
  } else if (annotation.type === "box") {
    result.segments = annotation.polygon;
    result.x = annotation.x;
    result.y = annotation.y;
    result.w = annotation.w;
    result.h = annotation.h;
    result.score = annotation.score;
    result['size_%'] = annotation.w * annotation.h;
    result['size_px'] = (annotation.w * annotation.h) * imageObj.value.width * imageObj.value.height;
    if (label) {
      result.label_name = label.name;
      result.label_index = label.index;
    } else {
      result.label_name = "";
      result.label_index = 0;
    }
  }
  return result;
}

function formatDataJson(annotation) {
  const label = labels.value.find((e) => e.id === annotation.label_id);
  let result = {};
  if (annotation.type === "3dbox") {
    result.x = annotation["data_json"].x;
    result.y = annotation["data_json"].y;
    result.z = annotation["data_json"].z;
    result.dx = annotation["data_json"].dx;
    result.dy = annotation["data_json"].dy;
    result.dz = annotation["data_json"].dz;
    if (label) {
      result.label_name = label.name;
      result.label_index = label.index;
    } else {
      result.label_name = "";
      result.label_index = 0;
    }
  } else {
    result = annotation['data_json'];
  }
  return result;
}

async function readFile(annotation) {
  loadingRaw.value = true;
  const data = await dataConnect.getAnnotationFile(annotation.id)
    .catch((error) => {
      console.log(error);
      return '';
    });
  loadingRaw.value = false;
  return data;
}

async function handleAnnotationOpened(annotation) {
  if (annotation.data_url && !annotation.raw) {
    annotation.raw = await readFile(annotation);
  } else if (annotation.polygon && !annotation.raw) {
    annotation.raw = annotation.polygon;
  }
}

function handleSelect(annotation) {
  emit('select-annotations', annotation);
}

function handleLabelListItemClicked(label) {
  if (selectedLabelForAudit.value !== label) {
    selectedLabelForAudit.value = label;
    emit('update:selectedLabel', label);
  }
}

const route = useRoute();

onMounted(() => {
  if (route.query.showTypes) {
    jsonViewerShowTypes.value = JSON.parse(decodeURI(route.query.showTypes));
  }
  if (route.query.annotationDisplaySettings) {
    const annotationDisplaySettings = JSON.parse(decodeURI(route.query.annotationDisplaySettings));
    internalSelectedLabels.value = annotationDisplaySettings.displayLabels.labels;
    internalSelectedAnnotationSets.value = annotationDisplaySettings.displayAnnotationSets.annotation_sets;
    displayColorBy.value = annotationDisplaySettings.colorAnnotationsBy.type;
  } else {
    if (labels.value.length > 0 && internalSelectedLabels.value.length === 0) {
      internalSelectedLabels.value = labels.value.map((e) => e.index);
    }
  }
});

function handleEditAnnotations(annotationSet) {
  emit('edit-for-annotation-set', annotationSet);
}

function handleDeleteAnnotation(annotation) {
  emit('remove-annotation', annotation);
}

function getColor(label) {
  let color = "";
  color = label.color ? label.color : labelColorMap.value[label.index];
  return color;
}

function toggleDropdownAll() {
  if (openedDropdowns.value.length === 0) {
    openedDropdowns.value = ['image', 'annotation-sets', 'labels', 'topics'];
  } else {
    openedDropdowns.value = [];
  }
}

</script>

<style lang="scss" scoped>

.dropdown-toggle-all {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  span {
    // margin: 5px 15px 0 5px;
    font-size: 0.95rem;
    font-weight: 600;
    color: var(--color-primary);
    cursor: pointer;

    &:hover {
      color: rgb(147, 146, 146);
    }
  }
}

.json-raw-viewer {
  display: flex;
  width: 100%;
  overflow-y: auto;
}
.panel {
  padding: 0 !important;
  border-radius: 0;

  &__title {
    height: 30px;
    min-height: 0;
    padding: 5px 5px 5px 15px;
    font-weight: 600;
    border-radius: 0;
    border-bottom: 0.5px solid rgb(132, 130, 130);
  }

  &__title-text {
    display: flex;
    width: 100%;
    margin: 0 10px 0 0;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

  }

  .topic-title {
    margin-left: 10px;
  }

  &__text {
    background: none;
    // border-bottom: 0.5px solid rgb(132, 130, 130);
    // overflow-y: auto;
    height: fit-content;
  }

  &__item {
    display: flex;
    flex-direction: row;
  }

  &__item > * {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  &__radio-label {
    width: 21px;
    height:21px;

    .icon {
      cursor: pointer;
    }

    .icon:hover {
      @include themify() {
        background-color: themed('icon-hover-color');
        border-radius: 100%;
        box-shadow: 0 0 0 4px themed('icon-hover-color');
      }
    }
  }

  :deep() {
    .v-expansion-panel-title--active {
      min-height: 0;
    }
  }
}

.topic-selector-icon {
  @include themify() {
    color: themed('icon-color-primary');
  }
}

.topic-selector-text {
  min-width: 180px;
  width:fit-content;
  min-height: 200px;
  max-height: 400px;
  padding-right: 10px;
  overflow-y: auto;
}

.raw-text {
  width: 100%;
  text-align: left;
  font-size: 0.9rem;
}

.json-tree {
  width: 100%;
  text-align: left;
  font-size: 0.9rem;
}
pre{
  width: 100%;
  overflow-x: scroll;
}

.label-list {
  display: flex;
  flex-direction: column;
  flex: 1 1 50%;
  margin: 0;
  list-style-type: none;

  li {
    user-select: none;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    -o-user-select: none;
    flex-direction: row;
    display: flex;
    align-items: center;
    margin-bottom: unset;
    height: 30px;
    padding: 4px 8px;
    font-size: 0.9rem;
    font-weight: 600;
    border: 1px solid transparent;
    border-left: 4px solid transparent;

    button {
      @include themify() {
        color: themed('color-primary');
      }
    }

    span {
      margin-right: auto;
    }

    &._active {
      background: rgba(140,140,140,0.2);
      cursor: pointer;

      @include themify() {
        border: 1px solid black;
        border-left: 4px solid themed('color-primary');
      }
    }
  }

  li+li {
    margin-top: 5px;
  }

  li._hover {
    &:hover {
      background: rgba(140,140,140,0.2);
      cursor: pointer;
    }
  }

  li._pointer {
    cursor: pointer;
  }

  &__symbol {
    width: 16px;
    height: 16px;
    margin-right: 8px;
  }

  &__box-symbol {
    border: 2px solid;
  }

  .chkbox-flex-row {
    display: flex;
    flex-direction: row;
    align-items: center;

    .label-color-name{
      display: flex;
      flex-direction: row;
      align-items: center;

      span {
        margin-left: 10px;
      }
    }
  }
}

.check-box-text {
    font-size: 0.9rem;
    color: #181a1f;
    font-weight: 600;
    letter-spacing: 0;
  }

.label-chkbox {
    width: fit-content;
  }

.annset-legend-color {
  min-width: 16px;
  min-height: 16px;
  margin-right: 8px;
  border-radius: 100%;
  border: 0.1px solid grey;
}

:deep() {
  .v-label {
      opacity: 1!important;
    }

  .v-expansion-panel--active {
      margin-top: 0;
    }

  .v-expansion-panel--after-active {
    margin-top: 0;
  }
  .v-selection-control__wrapper {
    margin-right: 5px;
  }

  .nowrap>.v-expansion-panel-text__wrapper {
    padding: 0
  }

  .label-list-wrapper {
    overflow-y: auto;
  }

  .label-list-wrapper>.v-expansion-panel-text__wrapper {
    max-height: 300px;
    height: fit-content;
    padding-left: 0 !important;
  }

}

.color-radio {
  position: absolute;
  opacity: 0;
  width: 30px;
  height: 30px;
}

.radio-icon:hover,
.radio-icon:focus-visible {
  @include themify() {
    background-color: themed('icon-hover-color');
    border-radius: 100%;
    box-shadow: 0 0 0 4px themed('icon-hover-color');
  }
}

.active-icon {
  @include themify() {
    color: themed('icon-color-primary');
    border-radius: 100%;
  }
}

.utilities {
  display: flex;
  flex-direction: row;
  height: 30px;
  margin: 4px 6px;
  gap: 4px;

  &__buttons {
    display: flex;
    flex-direction: row;
    flex: 1 1 auto;
    align-items: center;
  }
}

</style>
