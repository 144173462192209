<template>
  <div class="radio-group">
    <template v-for="(group, index) in groups" :key="index">
      <input
        :id="`option-${group.name}`"
        v-model="selectedGroup"
        type="radio"
        name="mode-selector"
        :value="group"
      >
      <label
        :for="`option-${group.name}`"
        :style="{ flex: flexStyles }"
        :title="group.name"
        @click.prevent="toggleSelection(group)"
      >
        {{ group.name }}
      </label>
    </template>
  </div>
</template>

<script>

export default {
  name: 'ImageGroupSelector',
  props: {
    groups: {
      type: Array,
      default: () => [],
    },
    imageObj: {
      type: Object,
      default: null,
    },
    modelValue: {
      type: Object,
      default: null,
    },
  },
  emits: ['update:modelValue', 'change'],
  data() {
    return {
      selectedGroup: null,
    };
  },
  computed: {
    flexStyles() {
      return this.groups.length === 3 ? '1 1 33.333%' : '1 1 50%';
    },
  },
  watch: {
    modelValue() {
      if (this.modelValue !== this.selectedGroup?.id) {
        this.selectedGroup = this.groups.find((group) => group.id === this.modelValue) || null;
      }
    },
    selectedGroup() {
      this.$emit('update:modelValue', this.selectedGroup?.id);
      this.$emit('change', this.selectedGroup?.id);
    },
    imageObj() {
      if ('group_id' in this.imageObj) {
        this.selectedGroup = this.groups.find((group) => group.id === this.imageObj.group_id) || null;
      }
    },
  },
  mounted() {
    if (this.modelValue) {
      this.selectedGroup = this.groups.find((group) => group.id === this.modelValue) || null;
    }
  },
  methods: {
    toggleSelection(group) {
      if (this.selectedGroup === group) {
        this.selectedGroup = null;
      } else {
        this.selectedGroup = group;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.radio-group {
  width: 100%;
  margin: 0;
  flex-wrap: wrap;
  display: flex;

  input[type=radio] + label {
    padding: 6px;
    min-width: 0;
    font-size: 0.875rem;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}
</style>
