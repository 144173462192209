export default function useZoom(konvaContainer, imageDimensions, stageNode, stageScale) {
  function calculateZoomToFitScale(konvaContainerRect) {
    // Determine aspect ratios of container and image
    const { stageAspectRatio, imageAspectRatio } = getAspectRatios(konvaContainerRect);

    let scale = 1;
    if (stageAspectRatio < imageAspectRatio) {
      scale = konvaContainerRect.width / imageDimensions.value.width;
    } else {
      scale = konvaContainerRect.height / imageDimensions.value.height;
    }
    return scale;
  }

  function getAspectRatios(konvaContainerRect) {
    const stageAspectRatio = konvaContainerRect.width / konvaContainerRect.height;
    const imageAspectRatio = imageDimensions.value.width / imageDimensions.value.height;
    return { stageAspectRatio, imageAspectRatio };
  }

  function zoomToFitImage() {
    if (!imageDimensions.value) return;

    const konvaContainerRect = konvaContainer.value.getBoundingClientRect();

    // Determine aspect ratios of container and image
    const stageAspectRatio = konvaContainerRect.width / konvaContainerRect.height;
    const imageAspectRatio = imageDimensions.value.width / imageDimensions.value.height;

    // Determine stage scale that will fit image
    let scale = 1;
    if (stageAspectRatio < imageAspectRatio) {
      scale = konvaContainerRect.width / imageDimensions.value.width;
    } else {
      scale = konvaContainerRect.height / imageDimensions.value.height;
    }
    stageNode.value.scale(
      { x: scale, y: scale },
    );

    // Postition the image to the center
    stageNode.value.position(
      { x: ((konvaContainerRect.width - (imageDimensions.value.width * scale)) / 2), y: ((konvaContainerRect.height - (imageDimensions.value.height * scale)) / 2) },
    );

    stageScale.value = { x: stageNode.value.scaleX(), y: stageNode.value.scaleY() };
  }

  function handleCanvasZoomScroll(e) {
    if (!e.evt) {
      return;
    }
    const scaleBy = 1.25;
    const stage = e.currentTarget;
    const oldScale = stage.scaleX();
    const pointer = stage.getPointerPosition();

    const mousePointTo = {
      x: (pointer.x - stage.x()) / oldScale,
      y: (pointer.y - stage.y()) / oldScale,
    };

    // how to scale? Zoom in? Or zoom out?
    let direction = e.evt.deltaY > 0 ? -1 : 1;

    // when we zoom on trackpad, e.evt.ctrlKey is true
    // in that case lets revert direction
    if (e.evt.ctrlKey) {
      direction = -direction;
    }

    const newScale = direction > 0 ? oldScale * scaleBy : oldScale / scaleBy;

    stage.scale({ x: newScale, y: newScale });

    const newPos = {
      x: pointer.x - mousePointTo.x * newScale,
      y: pointer.y - mousePointTo.y * newScale,
    };
    stage.position(newPos);

    stageScale.value = { x: stageNode.value.scaleX(), y: stageNode.value.scaleY() };
  }

  function zoomToSelection(selectedNodes) {
    const konvaContainerRect = konvaContainer.value.getBoundingClientRect();

    // Determine the selection bounds
    let minX = imageDimensions.value.width; let maxX = 0; let minY = imageDimensions.value.height; let maxY = 0;
    selectedNodes.value.forEach((node) => {
      const nodeMinX = node.getAttrs().x;
      const nodeMaxX = (node.getAttrs().x + node.getAttrs().width);
      const nodeMinY = node.getAttrs().y;
      const nodeMaxY = (node.getAttrs().y + node.getAttrs().height);
      if (nodeMinX < minX) {
        minX = nodeMinX;
      }
      if (nodeMaxX > maxX) {
        maxX = nodeMaxX;
      }
      if (nodeMinY < minY) {
        minY = nodeMinY;
      }
      if (nodeMaxY > maxY) {
        maxY = nodeMaxY;
      }
    });

    // Determine aspect ratios of container and image
    const stageAspectRatio = konvaContainerRect.width / konvaContainerRect.height;
    const imageAspectRatio = (maxX - minX) / (maxY - minY);

    // Determine stage scale that will fit image
    let scale = 1;
    const padding = 1.2;
    if (stageAspectRatio < imageAspectRatio) {
      scale = konvaContainerRect.width / ((maxX - minX) * padding);
    } else {
      scale = konvaContainerRect.height / ((maxY - minY) * padding);
    }
    stageNode.value.scale(
      { x: scale, y: scale },
    );

    // Postition the image to the center
    minX = imageDimensions.value.width; maxX = 0; minY = imageDimensions.value.height; maxY = 0;
    selectedNodes.value.forEach((node) => {
      const nodeMinX = node.getAttrs().x;
      const nodeMaxX = (node.getAttrs().x + node.getAttrs().width);
      const nodeMinY = node.getAttrs().y;
      const nodeMaxY = (node.getAttrs().y + node.getAttrs().height);
      if (nodeMinX < minX) {
        minX = nodeMinX;
      }
      if (nodeMaxX > maxX) {
        maxX = nodeMaxX;
      }
      if (nodeMinY < minY) {
        minY = nodeMinY;
      }
      if (nodeMaxY > maxY) {
        maxY = nodeMaxY;
      }
    });
    const xPos = -(minX * stageNode.value.scaleX());
    const xOffset = ((konvaContainerRect.width - ((maxX - minX) * stageNode.value.scaleX())) / 2);
    const yPos = -(minY * stageNode.value.scaleY());
    const yOffset = ((konvaContainerRect.height - ((maxY - minY) * stageNode.value.scaleY())) / 2);
    stageNode.value.position(
      {
        x: xPos + xOffset,
        y: yPos + yOffset,
      },
    );

    stageScale.value = { x: stageNode.value.scaleX(), y: stageNode.value.scaleY() };
  }

  function zoomToBox({
    x1, x2, y1, y2,
  }) {
    const minX = x1 * imageDimensions.value.width;
    const maxX = x2 * imageDimensions.value.width;
    const minY = y1 * imageDimensions.value.height;
    const maxY = y2 * imageDimensions.value.height;
    const konvaContainerRect = konvaContainer.value.getBoundingClientRect();

    // Determine the selection bounds
    // let minX = imageDimensions.value.width; let maxX = 0; let minY = imageDimensions.value.height; let maxY = 0;
    // nodes.value.forEach((node) => {
    //   const nodeMinX = node.getAttrs().x;
    //   const nodeMaxX = (node.getAttrs().x + node.getAttrs().width);
    //   const nodeMinY = node.getAttrs().y;
    //   const nodeMaxY = (node.getAttrs().y + node.getAttrs().height);
    //   if (nodeMinX < minX) {
    //     minX = nodeMinX;
    //   }
    //   if (nodeMaxX > maxX) {
    //     maxX = nodeMaxX;
    //   }
    //   if (nodeMinY < minY) {
    //     minY = nodeMinY;
    //   }
    //   if (nodeMaxY > maxY) {
    //     maxY = nodeMaxY;
    //   }
    // });

    // Determine aspect ratios of container and image
    const stageAspectRatio = konvaContainerRect.width / konvaContainerRect.height;
    const imageAspectRatio = (maxX - minX) / (maxY - minY);

    // Determine zoom to fit scale
    const zoomToFitScale = calculateZoomToFitScale(konvaContainerRect);

    // Determine stage scale that will fit image
    let scale = 1;
    const padding = 3;
    if (stageAspectRatio < imageAspectRatio) {
      scale = konvaContainerRect.width / ((maxX - minX) * padding);
    } else {
      scale = konvaContainerRect.height / ((maxY - minY) * padding);
    }

    if (scale < zoomToFitScale) {
      scale = zoomToFitScale;
    }

    stageNode.value.scale(
      { x: scale, y: scale },
    );

    // Postition the image to the center
    // minX = imageDimensions.value.width; maxX = 0; minY = imageDimensions.value.height; maxY = 0;
    // nodes.value.forEach((node) => {
    //   const nodeMinX = node.getAttrs().x;
    //   const nodeMaxX = (node.getAttrs().x + node.getAttrs().width);
    //   const nodeMinY = node.getAttrs().y;
    //   const nodeMaxY = (node.getAttrs().y + node.getAttrs().height);
    //   if (nodeMinX < minX) {
    //     minX = nodeMinX;
    //   }
    //   if (nodeMaxX > maxX) {
    //     maxX = nodeMaxX;
    //   }
    //   if (nodeMinY < minY) {
    //     minY = nodeMinY;
    //   }
    //   if (nodeMaxY > maxY) {
    //     maxY = nodeMaxY;
    //   }
    // });
    const xPos = -(minX * stageNode.value.scaleX());
    const xOffset = ((konvaContainerRect.width - ((maxX - minX) * stageNode.value.scaleX())) / 2);
    const yPos = -(minY * stageNode.value.scaleY());
    const yOffset = ((konvaContainerRect.height - ((maxY - minY) * stageNode.value.scaleY())) / 2);
    stageNode.value.position(
      {
        x: xPos + xOffset,
        y: yPos + yOffset,
      },
    );

    stageScale.value = { x: stageNode.value.scaleX(), y: stageNode.value.scaleY() };
  }

  return {
    zoomToFitImage, zoomToSelection, zoomToBox, handleCanvasZoomScroll,
  };
}
