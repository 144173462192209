<template>
  <PageLoader
    v-if="!loadingFinished"
    :width="'200px'"
    :height="'250px'"
    :text="'Fetching Data'"
  />
  <template v-else>
    <div class="controls-bar">
      <span />
      <button v-if="permissions && permissions.project_write" class="button button-sm" @click="openCreateModal">Create</button>
    </div>
    <div v-if="notEmpty && permissions && (permissions.project_read || permissions.project_write)" class="manager-card-container scrollbar">
      <ProjectCard
        v-for="p in projectList"
        :key="p.id"
        :project="p"
        :selected="p.id === projectID"
        :refresh="p.id === refreshCardID"
        @edit-project="openUpdateProjectModal(p)"
        @delete-project="openConfirmDeleteProjectModal(p)"
        @clicked="handleSelectNewProject(p)"
        @open-project-access-management="handleOpenProjectAccessManagement(p)"
      />
    </div>
    <div
      v-else
      class="empty-state"
    >
      <svg width="60%" height="60%">
        <use href="@/assets/img/empty.svg#emptyStateSVG" />
      </svg>
      <div class="empty-state__message">
        <h3>No project to display.</h3>
      </div>
    </div>
    <ProjectCreate
      ref="createProjectModal"
      @create-project="(params) => createProject(params)"
    />
    <ConfirmModal
      ref="confirmModal"
      :messageHeader="confirmMessageHeader"
      :message="confirmMessage"
      :buttonClass="'button-delete'"
      :buttonText="buttonText"
      @confirmed="handleDeleteProject"
    />
    <ProjectUpdateModal
      ref="updateProjectModal"
      :project="modalProject"
      @edit-project="updateProject"
    />
    <ProjectAccessModal
      ref="projectAccessModal"
      :modalProject="modalProject"
      @refresh-projects="getProjectList"
    />
  </template>
</template>

<script>
import ProjectCard from "@/components/ProjectsComponent/ProjectCard.vue";
import ProjectCreate from "@/components/ProjectsComponent/ProjectCreate.vue";
import ProjectUpdateModal from '@/components/ProjectsComponent/ProjectUpdateModal.vue';
import PageLoader from '@/components/PageLoader.vue';
import ConfirmModal from '@/components/ConfirmModal.vue';
import DatastoreConnect from '@/assets/js/DatastoreFunctions/datastore-interface';
import ProjectAccessModal from "@/components/ProjectsComponent/ProjectAccessModal.vue";

export default {
  name: 'ProjectManagement',
  components: {
    ProjectCard,
    ProjectCreate,
    ConfirmModal,
    PageLoader,
    ProjectUpdateModal,
    ProjectAccessModal,
  },
  props: {
  },
  data() {
    return {
      dataConnect: new DatastoreConnect(this.$store.state.enterpriseServerUrl),
      loadingFinished: true,
      mergeSource: null,
      toDelete: null,
      confirmMessage: null,
      confirmMessageHeader: null,
      buttonText: '',
      refreshCardID: null,
      modalProject: null,
      projectList: null,
    };
  },
  computed: {
    notEmpty() {
      return this.projectList && this.projectList.length > 0;
    },
    projectID() {
      return this.$store.state.projects.currentProject ? this.$store.state.projects.currentProject.id : 0;
    },
    user() {
      return this.$store.state.user.user;
    },
    permissions() {
      return this.$store.state.user.permissions;
    },
  },
  async mounted() {
    this.getProjectList();
  },
  methods: {
    async getProjectList() {
      await this.dataConnect.getProjectList({ get_num_datasets: true })
        .then((data) => {
          this.projectList = data.result;
          let noLT = 0;
          let noDS = 0;
          let noTR = 0;
          let noVA = 0;
          let noAU = 0;

          this.projectList.forEach((proj) => {
            noAU += proj.no_of_automations;
            noLT += proj.no_of_label_tasks;
            noDS += proj.no_of_datasets;
            noTR += proj.no_of_trainers;
            noVA += proj.no_of_validations;
          });

          for (let k = 0; k < this.projectList.length; k++) {
            this.projectList[k].total_automations = noAU;
            this.projectList[k].total_datasets = noDS;
            this.projectList[k].total_trainers = noTR;
            this.projectList[k].total_validations = noVA;
            this.projectList[k].total_label_tasks = noLT;
          }
          if (this.modalProject) {
            const temp = this.projectList.find((e) => e.id === this.modalProject.id);
            if (temp) {
              this.modalProject = temp;
            }
          }
          this.$store.commit('projects/setProjectList', this.projectList);
        })
        .catch((e) => { console.log(e); });
    },
    handleSelectNewProject(p) {
      this.$store.commit('projects/setCurrentProject', p);
      this.$store.commit('datasets/resetState');
    },
    async handleDeleteProject() {
      const params = {
        project_id: this.toDelete.id,
      };
      await this.dataConnect.deleteProject(params)
        .then((data) => {
          if (data.result) {
            this.toDelete = null;
            this.getProjectList();
            this.handleSelectNewProject(null);
          }
        })
        .catch((e) => { console.log(e); });
    },
    handleOpenProjectAccessManagement(project) {
      this.modalProject = project;
      this.$nextTick(() => {
        this.$refs.projectAccessModal.showModal();
      });
    },
    openConfirmDeleteProjectModal(d) {
      this.confirmMessage = `Are you sure you want to move project "${d.name}" to recycle bin?`;
      this.confirmMessageHeader = 'Remove Project';
      this.buttonText = 'Remove';
      this.toDelete = d;
      this.$refs.confirmModal.showModal();
    },
    openCreateModal() {
      this.$refs.createProjectModal.showModal();
    },
    openUpdateProjectModal(p) {
      this.modalProject = p;
      this.$refs.updateProjectModal.showModal();
    },
    async updateProject(params) {
      await this.dataConnect.updateProject(params)
        .then((data) => {
          if (data.result) {
            this.getProjectList();
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async createProject(params) {
      const args = { ...params };
      await this.dataConnect.addProject(args)
        .then((data) => {
          if (!data.error) {
            this.getProjectList();
          } else if (data.error.message.includes("A project with the same name already existed for this user")) {
            this.confirmMessage = `A project with the same name already existed for this user.`;
            this.confirmMessageHeader = 'Project Already Exists';
            this.buttonText = "";
            this.$refs.confirmModal.showModal();
          } else if (data.error.message.includes("limit reached")) {
            this.confirmMessage = `You haved reached the limit for number of projects. Delete unused projects or upgrade your package.`;
            this.confirmMessageHeader = 'Limit Reached';
            this.buttonText = "";
            this.$refs.confirmModal.showModal();
          } else if (data.error.message !== "") {
            this.confirmMessage = data.error.message.charAt(0).toUpperCase() + data.error.message.slice(1);
            this.confirmMessageHeader = 'Error';
            this.buttonText = "";
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.controls-bar {
  display: flex;
  flex-direction: row;
  padding: 10px 20px 10px 20px;
  align-items: center;
  border-bottom: 1px solid #c9c9c9;
  @include themify() {
    background: themed('color-ribbon');
  }
  justify-content: space-between;
}
.manager-card-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-auto-rows: 380px;
  width: 100%;
  gap: 16px;
  padding: 16px;
  overflow-y: auto;
  .list-card {
    height: 100%;
    width: 100%;
    cursor: pointer;
  }
}

.empty-state {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  height: 100%;
}
</style>
