<template>
  <div class="controls-bar">
    <DatasetFilter
      v-model="datasetFilters"
      :dataset="currentDataset"
      :annotationSets="annotationSets"
      :labels="currentLabels"
      :types="currentTypes"
      :collapsible="true"
      :showCopyFilteredImagesBtn="permissions && permissions.dataset_write"
      :showDeleteFilteredImagesBtn="permissions && permissions.dataset_write"
      @open-copy-filters="openCopyFilteredImagesModal"
      @delete-filtered-images="openConfirmDeleteFilteredImagesModal"
    />
  </div>
  <GalleryGrid
    ref="galleryGrid"
    v-model:page="currentPage"
    v-model:totalPages="totalPagesCount"
    v-model:selectedImages="selectedImages"
    :imageList="imageList"
    :totalImageCount="totalImageCount"
    :imagesPerPage="imagesPerPage"
    :annotationSets="annotationSets"
    :doneFetch="doneFetch"
    @multi-select="handleMultiSelect"
  >
    <template #header-start>
      <div class="grid-header-start">
        <span v-if="isSelectedAll">{{ `Selected Images (${totalImageCount} / ${totalImageCount})` }}</span>
        <span v-else>{{ selectedImages && selectedImages.length > 0 ? `Selected Images (${selectedImages.length} / ${totalImageCount})` :`Images (${totalImageCount})` }}</span>
      </div>
    </template>
    <template #header-end>
      <div class="grid-header-end">
        <div class="gallery-actions">
          <!-- <button class="button" :class="{ 'inactive': false }">
            Copy Images
          </button> -->
          <button class="button" @click="selectAll"> {{ isSelectedAll ? `Deselect All` : `Select All` }}</button>
          <DisplayAnnotationsFilter
            v-model="annotationDisplaySettings"
            :annotationSets="annotationSets"
            :labels="currentLabels"
          />
          <DisplayImageDetails
            v-model="imageDisplaySettings"
          />
          <div class="sort-by">
            <GalleryImageSort v-model:sortBy="sortBy" v-model:ascend="reverse" />
          </div>
          <button class="button import" @click="openUploadModal">
            <SVGIcon
              :iconName="'import'"
              :width="'18px'"
              :height="'18px'"
            />Import
          </button>
          <button class="button delete" :disabled="selectedImages && selectedImages.length === 0" @click="openConfirmDeleteImageModal()">
            Delete
          </button>
        </div>
      </div>
    </template>
    <template #grid-item>
      <!-- click.stop to prevent triggering select box on item click -->
      <GalleryGridItem2
        v-for="imageObj in imageList"
        :key="imageObj.id"
        v-model:selectedImages="selectedImages"
        :imageObj="imageObj"
        :annotations="imageObj.annotations"
        :annotationSets="annotationSets"
        :annotationDisplayType="colorAnnotationsBy"
        :filterAnnotationsBySets="displayAnnotationSets"
        :filterAnnotationsByLabelIndexes="displayLabels"
        :labels="currentLabels"
        :showName="showImageName"
        :shouldShowSelector="true"
        v-bind="gridItemDisplayProp"
        @mousedown.stop=""
        @mouseup.stop=""
        @grid-item-clicked="displayImage($event)"
        @delete-image-from-dataset="openConfirmDeleteImageModal"
        @copy="handleCopy"
        @download="handleDownload"
      />
    </template>
    <template #empty-state>
      <GalleryEmptyState
        v-if="!isFetchingImages"
        ref="galleryEmptyState"
        :datasetID="currentDatasetID"
        @import-clicked="$refs.uploadImagesModal.showModal();"
      />
    </template>
    <template #overlay>
      <div v-if="isFetchingImages" id="loading-visualization"><div class="lds-ring"><div /><div /><div /><div /></div></div>
      <GalleryDropZone
        ref="galleryEmptyState"
        :datasetID="currentDatasetID"
        :showIcon="imageList && imageList.length > 0"
        @upload-complete="handleImageUploadComplete"
      />
    </template>
  </GalleryGrid>
  <ConfirmModal
    ref="confirmModal"
    :messageHeader="confirmMessageHeader"
    :message="confirmMessage"
    :buttonClass="'button-delete'"
    :buttonText="buttonText"
    @confirmed="confirmFunction"
  />
  <UploadImagesModal
    ref="uploadImagesModal"
    :datasetID="currentDatasetID"
    :annotationSets="annotationSets"
    @upload-complete="handleImageUploadComplete"
    @upload-error="handleUploadError"
  />
  <DatasetCopyModal
    v-if="showCopyModal"
    ref="copyDatasetModal"
    v-model:show="showCopyModal"
    :datasets="datasets"
    :source="currentDataset"
    :filters="imageFilterParams"
    :showFilters="false"
    :lockSource="true"
    @closed="modalDataset = null"
  />
  <ImageCopyModal
    ref="copyImageModal"
    :datasets="datasets"
    :annotationSets="annotationSets"
    :selectedImage="selectedImage"
    :types="currentTypes"
    @closed="() => {selectedImage = null}"
  />
  <DatasetExportModal
    ref="datasetExportModal"
    :datasets="datasets"
    :sequences="sequences"
    :exportDataset="currentDataset"
  />
</template>

<script>
import DisplayAnnotationsFilter from '@/components/DatasetComponent/GalleryComponent/DisplayAnnotationsFilterV2.vue';
import GalleryImageSort from '@/components/DatasetComponent/GalleryComponent/GalleryImageSort.vue';
import DatastoreConnect from '@/assets/js/DatastoreFunctions/datastore-interface';
import GalleryGrid from '@/components/DatasetComponent/GalleryComponent/GalleryGrid.vue';
import UploadImagesModal from '@/components/DatasetComponent/UploadImagesModal.vue';
import GalleryGridItem2 from '@/components/DatasetComponent/GalleryComponent/GalleryGridItem2.vue';
import GalleryEmptyState from '@/components/DatasetComponent/GalleryComponent/GalleryEmptyState.vue';
import GalleryDropZone from '@/components/DatasetComponent/GalleryComponent/GalleryDropZone.vue';
import SVGIcon from '@/components/SVGIcon.vue';
import IconButton from '@/components/IconButton.vue';
import ConfirmModal from '@/components/ConfirmModal.vue';
import DatasetCopyModal from "@/components/DatasetComponent/DatasetManagement/DatasetCopyModal.vue";
import DatasetExportModal from "@/components/DatasetComponent/DatasetManagement/DatasetExportModal.vue";
import DisplayImageDetails from '@/components/DatasetComponent/GalleryComponent/DisplayImageDetails.vue';
import useGallery from '@/composables/annotationTool/useGallery.js';
import DatasetFilter from '@/components/DatasetComponent/DatasetFilters/DatasetFilter.vue';
import ImageCopyModal from "@/components/DatasetComponent/GalleryComponent/ImageCopyModal.vue";
import equal from 'deep-equal';

export default {
  name: 'Gallery',
  components: {
    GalleryGrid,
    DatasetFilter,
    DisplayImageDetails,
    DisplayAnnotationsFilter,
    GalleryGridItem2,
    GalleryEmptyState,
    GalleryDropZone,
    UploadImagesModal,
    SVGIcon,
    IconButton,
    ConfirmModal,
    DatasetCopyModal,
    DatasetExportModal,
    ImageCopyModal,
    GalleryImageSort,
  },
  setup() {
    const {
      datasetFilters,
      annotationDisplaySettings,
      imageDisplaySettings,
      reverse,
      combineSequenceFrames,
      sortBy,
      currentDatasetID,
      internalCurrentDataset,
      currentPage,
      imagesPerPage,
      imageList,
      totalImageCount,
      getImagesParams,
      getImagesFilterParams,
      getImages,
      deleteFilteredImages,
      isFetchingImages,
    } = useGallery();
    combineSequenceFrames.value = true;

    return {
      datasetFilters,
      annotationDisplaySettings,
      imageDisplaySettings,
      reverse,
      sortBy,
      currentDatasetID,
      internalCurrentDataset,
      currentPage,
      imagesPerPage,
      imageList,
      totalImageCount,
      getImagesParams,
      getImagesFilterParams,
      getImages,
      deleteFilteredImages,
      isFetchingImages,
      combineSequenceFrames,
    };
  },
  data() {
    return {
      dataConnect: new DatastoreConnect(this.$store.state.enterpriseServerUrl),
      // annotationSets: null,
      imageListPromise: null,
      mapImageList: [],
      totalPagesCount: 0,
      dropHighlighted: false,
      gallerySelection: [],
      confirmMessage: null,
      confirmMessageHeader: null,
      buttonText: "",
      confirmFunction: null,
      showCopyModal: false,
      showCopyImageModal: false,
      permissions: null,
      selectedImage: null,
      sequences: [],
      showExportModal: false,
      doneFetch: true,
      selectedImages: [],
      isSelectedAll: false,
    };
  },
  computed: {
    currentProject() {
      return this.$store.state.projects.currentProject;
    },
    datasets() {
      return this.$store.state.datasets.datasetList;
    },
    currentDataset() {
      return this.$store.state.datasets.currentDataset;
    },
    currentLabels() {
      if (this.currentDataset) {
        return this.currentDataset.labels;
      }
      return [];
    },
    currentTypes() {
      if (this.currentDataset) {
        return this.currentDataset.annotation_types;
      }
      return [];
    },
    annotationSets: {
      get() {
        if (this.currentDataset) {
          return this.currentDataset.annotation_sets;
        }
        return [];
      },
      set(sets) {
        const index = this.datasets.indexOf(this.currentDataset);
        this.$store.commit('datasets/setDatasetAnnotationSets', { index, sets });
      },
    },
    getAnnotationSetsParams() {
      if (!this.currentDataset) {
        return null;
      }

      const params = {
        dataset_id: this.currentDataset.id,
      };

      if (this.currentTab === 'annotations') {
        params.include_empty = true;
      }

      return params;
    },
    gridItemDisplayProp() {
      if (!this.datasetFilters?.displayAnnotationsFilter) {
        return {};
      }
      if (this.datasetFilters?.displayAnnotationsFilter?.displayType === 'byAnnotationSets') {
        return { filterAnnotationsBySets: this.datasetFilters.displayAnnotationsFilter.value };
      }
      if (this.datasetFilters?.displayAnnotationsFilter?.displayType === 'byAnnotationLabels') {
        return { filterAnnotationsByLabelIndexes: this.datasetFilters.displayAnnotationsFilter.value };
      }
      return {};
    },
    colorAnnotationsBy() {
      if (this.annotationDisplaySettings?.colorAnnotationsBy?.type) {
        return this.annotationDisplaySettings?.colorAnnotationsBy?.type;
      }
      return 'set';
    },
    displayAnnotationSets() {
      if (this.annotationDisplaySettings?.displayAnnotationSets) {
        return this.annotationDisplaySettings?.displayAnnotationSets.annotation_sets;
      }

      return [];
    },
    displayLabels() {
      if (this.annotationDisplaySettings?.displayLabels) {
        return this.annotationDisplaySettings?.displayLabels.labels;
      }
      return [];
    },
    showImageName() {
      if (this.imageDisplaySettings?.includes('showName')) {
        return true;
      }
      return false;
    },
    showSequences() {
      if (this.imageDisplaySettings?.includes('showSequences')) {
        return true;
      }
      return false;
    },
    imageParams() {
      return this.getImagesParams();
    },
    imageFilterParams() {
      return this.getImagesFilterParams();
    },
    getImagesCombinedParams() {
      return { ...this.getImagesParams(), ...this.getImagesFilterParams() };
    },
    combinedParams() {
      return {
        ...this.currentDataset,
        ...this.annotationDisplaySettings,
        ...this.imageDisplaySettings,
      };
    },
    minImageNumber() {
      return ((this.currentPage - 1) * this.imagesPerPage) + 1;
    },
    maxImageNumber() {
      const imgListLength = this.imageList ? this.imageList.length : 0;
      return ((this.currentPage - 1) * this.imagesPerPage) + imgListLength;
    },
  },
  watch: {
    currentDataset: {
      handler() {
        this.currentPage = 1;
        this.currentDatasetID = this.currentDataset ? this.currentDataset.id : 0;
        this.internalCurrentDataset = this.currentDataset;
      },
    },
    getImagesCombinedParams: {
      deep: true,
      async handler(newVal, oldVal) {
        if (!equal(newVal, oldVal) || !this.imageList) {
          this.imageListPromise = await new Promise((resolve, reject) => {
            this.doneFetch = false;
            this.getImages().then((imageList) => {
              this.imageList = imageList;
              this.selectedImages = [];
              resolve(imageList);
              this.doneFetch = true;
            }).catch((e) => {
              this.selectedImages = [];
              this.doneFetch = true;
            });
          });
        }
      },
    },
    combinedParams: {
      deep: true,
      async handler() {
        this.$router.replace({
          path: this.$route.path,
          query: {
            image_params: encodeURI(JSON.stringify({
              imagesPerPage: this.imagesPerPage,
              currentPage: this.currentPage > 0 ? this.currentPage : 1,
              sortBy: this.sortBy,
              reverse: this.reverse,
            })),
            filter_params: encodeURI(JSON.stringify(this.datasetFilters)),
            annotation_display: encodeURI(JSON.stringify(this.annotationDisplaySettings)),
            image_display: encodeURI(JSON.stringify(this.imageDisplaySettings)),
          },
        });
      },
    },
    getAnnotationSetsParams: {
      deep: true,
      async handler(params) {
        if (params && params !== undefined) {
          this.annotationSets = await this.getAnnotationSetList(params);
        } else {
          this.annotationSets = [];
        }
      },
    },
    showSequences() {
      this.combineSequenceFrames = this.showSequences;
    },
  },
  mounted() {
    this.currentDatasetID = this.currentDataset ? this.currentDataset.id : 0;
    this.internalCurrentDataset = this.currentDataset;
    if (this.$route.query.dataset) {
      const d = this.datasets.find((e) => e.id === this.$route.query.dataset);
      if (d) {
        this.$store.commit('datasets/setCurrentDataset', d);
      }
    }
    if (this.$route.query.image_params) {
      const image_params = JSON.parse(decodeURI(this.$route.query.image_params));
      this.imagesPerPage = image_params.imagesPerPage;
      this.currentPage = image_params.currentPage;
      this.sortBy = image_params.sortBy;
      this.reverse = image_params.reverse;
    }

    if (this.$route.query.filter_params) {
      this.displayFilters = JSON.parse(decodeURI(this.$route.query.filter_params));
    }

    if (this.$route.query.annotation_display) {
      this.annotationDisplaySettings = JSON.parse(decodeURI(this.$route.query.annotation_display));
    }

    if (this.$route.query.image_display) {
      this.imageDisplaySettings = JSON.parse(decodeURI(this.$route.query.image_display));
    }

    this.GetPermissionsForCurrentUser();
  },
  methods: {
    async GetPermissionsForCurrentUser() {
      const user = this.$store.state.user.user;
      await this.dataConnect.getDatasetAccessForSingleUser({
        dataset_id: this.currentDataset.id,
        username: user.username,
      })
        .then((data) => {
          if (data.result) {
            this.permissions = data.result;
          }
        })
        .catch((e) => console.log(e));
    },
    async getAnnotationSetList(params) {
      const annotationSets = await this.dataConnect.getAnnotationSets(params)
        .catch((error) => {
          // TODO: handle error
          console.log(error);
        });
      if (annotationSets.error || !annotationSets.result) {
        // TODO: handle error
        return {};
      }
      return annotationSets.result;
    },
    // async getLabelList() {
    //   const labels = await this.dataConnect.getLabelList({ dataset_id: this.currentDataset.id })
    //     .catch((error) => {
    //       // TODO: handle error
    //       console.log(error);
    //     });
    //   return labels.result;
    // },
    handleGallerySelectionChanged(selection) {
      this.gallerySelection = selection;
    },
    async handleCopyCompleted() {
      this.datasets = await this.getDatasets();
    },
    async handleAnnotationSetCreated() {
      this.annotationSets = await this.getAnnotationSetList();
    },
    openConfirmDeleteFilteredImagesModal() {
      this.confirmMessage = `Are you sure you want to delete all filtered images`;
      this.confirmMessageHeader = 'Delete Filtered Images';
      // this.toDelete = imageObj;
      this.buttonText = 'Delete';
      this.$refs.confirmModal.showModal();
      this.confirmFunction = () => this.deleteFilteredImages();
    },
    openConfirmDeleteImageModal() {
      const filtered = this.selectedImages.reduce((acc, item) => {
        if (item.sequence_id && item.sequence_id > 0 && item.type === 'sequence') {
          acc.sequence_ids.push(item.sequence_id);
        } else if (item.id > 0 && item.type === 'image') {
          acc.image_ids.push(item.id);
        }
        return acc;
      }, { sequence_ids: [], image_ids: [] });
      this.confirmMessage = `Are you sure you want to permanently delete ${this.isSelectedAll ? 'ALL' : this.selectedImages.length} item(s)?`;
      this.confirmMessageHeader = `Deleting Items`;
      // this.toDelete = imageObj;
      this.buttonText = 'Delete';
      this.$refs.confirmModal.showModal();
      this.confirmFunction = () => this.deleteImageFromDataset(filtered.sequence_ids, filtered.image_ids);
    },
    async deleteImageFromDataset(sequence_ids, image_ids) {
      const params = {
        dataset_id: this.currentDataset.id,
        sequence_ids,
        image_ids,
        delete_all: this.isSelectedAll,
      };
      this.dataConnect.deleteImageFromDataset(params)
        .then((data) => {
          if (!data || data.error || !data.result) {
            alert("Image failed to delete");
          } else {
            this.getImages().then((imageList) => {
              this.imageList = imageList;
            });
          }
        });
      this.selectedImages = [];
    },
    displayImage(imageObj) {
      this.$router.push({
        name: 'datasets.gallery.viewer',
        query: {
          image_id: encodeURI(JSON.stringify(imageObj.id)),
          annotationDisplaySettings: encodeURI(JSON.stringify(this.annotationDisplaySettings)),
          showSequences: encodeURI(this.imageDisplaySettings.includes('showSequences')),
        },
      });
    },
    openUploadModal() {
      this.$refs.uploadImagesModal.showModal();
    },
    handleImageUploadComplete() {
      this.imageListPromise = new Promise((resolve, reject) => {
        this.getImages().then((imageList) => {
          this.imageList = imageList;
          resolve(imageList);
        });
      });
      this.$refs.uploadImagesModal.closeModal();
    },
    async handleUploadError(data) {
      this.confirmMessage = data.confirmMessage;
      this.confirmMessageHeader = data.confirmMessageHeader;
      this.buttonText = "";
      this.$refs.confirmModal.showModal();
    },
    openCopyFilteredImagesModal() {
      this.showCopyModal = true;
    },
    handleCopy(imageObj) {
      this.selectedImage = imageObj;
      this.$refs.copyImageModal.showModal();
    },
    handleDownload(imageObj) {
      if (imageObj.type === 'sequence') {
        this.sequences = [
          {
            "id": imageObj.sequence_id,
            "uuid": imageObj.uuid,
            "name": imageObj.name,
          },
        ];
      }
      this.$refs.datasetExportModal.showModal();
    },
    selectAll() {
      if (this.isSelectedAll) {
        this.selectedImages = [];
        this.isSelectedAll = false;
      } else if (!this.isSelectedAll) {
        this.selectedImages = this.imageList;
        this.isSelectedAll = true;
      }
    },
    handleMultiSelect(IDs) {
      this.selectedImages = this.imageList.filter((e) => IDs.includes(e.id));
    },
  },
};
</script>

<style lang="scss" scoped>
.controls-bar {
  display: flex;
  flex-direction: row;
  padding: 10px 20px 10px 20px;
  align-items: center;
  border-bottom: 1px solid #c9c9c9;
  @include themify() {
    background: themed('color-ribbon');
  }
  justify-content: space-between;
}
.grid-header-start {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  height: 100%;
  align-items: center;
  gap: 12px;

  span{
    font-weight: bold;
    font-size: 1.15rem;
  }
}

.grid-header-end {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  height: 100%;
  align-items: center;
  gap: 12px;
  justify-content: flex-end;
}

#dataset-select {
  width: 300px;
}

.gallery-actions {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;

  button {
    background: white;
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 26px;
    padding: 0;
    box-shadow: 1px 2px 4px 1px rgba(0, 0, 0, 0.2), 0 0 1px 1px rgba(0, 0, 0, 1);
    flex: 1 1 auto;
    padding: 4px 8px;
    gap: 2px;
    border-right: solid 1px rgba(0,0,0,0.5);

    @include themify() {
      color: themed('body-text-color')
    }

    &.import {
      background: var(--button-color);
      color: var(--button-text-color);

      svg {
        margin-right: 5px;
      }
    }

    &:hover {
      background: rgba(var(--color-primary-100-rgb), 0.5);
    }

    &.delete:not([disabled]) {
      @include themify() {
        background: themed('button-delete');
        color: themed('button-delete-text');
      }
    }

    &.delete:hover:not([disabled]) {
      @include themify() {
        background: $color-red-300;
        color: themed('button-delete-text');
      }
    }

    &.inactive {
      background: rgba(var(--color-disabled), 0.5) !important;
    }
  }
}

.sort-by {
  display: flex;
  flex-direction: row;
  align-items: center;

  &__select {
    width: 130px;
  }

  &__reverse._invert {
    transform: rotate(180deg);
  }
}

#loading-visualization {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
  background: transparent;
}

.lds-ring {
  /* change color here */
  color: var(--color-primary);
}
.lds-ring,
.lds-ring div {
  box-sizing: border-box;
}
.lds-ring {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid currentColor;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: currentColor transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
