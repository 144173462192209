<template>
  <div class="comparison-table">
    <div class="table-header">
      <span class="title">Metrics Overview</span>
    </div>
    <div v-if="Object.keys(validation).length > 0" class="table">
      <div class="t-row header-row">
        <div class="cell">Number of Ground Truths (pixels)</div>
      </div>
      <div class="t-row">
        <div class="cell"> {{ parseFloat(results["numgt"]) }} </div>
      </div>
      <div class="t-row header-row">
        <div class="cell">True Positive</div>
        <div class="cell">False Negative</div>
        <div class="cell">False Positive</div>
      </div>
      <div class="t-row">
        <div class="cell">{{ parseFloat(results["Total TP"]) }}</div>
        <div class="cell">{{ parseFloat(results["Total FN"]) }}</div>
        <div class="cell">{{ parseFloat(results["Total FP"]) }}</div>
      </div>
      <div class="t-row header-row">
        <div class="cell">Overall Accuracy (%)</div>
        <div class="cell">Overall Precision (%)</div>
        <div class="cell">Overall Recall (%)</div>
      </div>
      <div class="t-row">
        <div class="cell">{{ parseFloat(results["OA"]) }}</div>
        <div class="cell">{{ parseFloat(results["OP"]) }}</div>
        <div class="cell">{{ parseFloat(results["OR"]) }}</div>
      </div>
      <div class="t-row header-row">
        <div class="cell">mACC</div>
        <div class="cell">mAP</div>
        <div class="cell">mAR</div>
      </div>
      <div class="t-row">
        <div class="cell">{{ parseFloat(results["mACC"]) }}</div>
        <div class="cell">{{ parseFloat(results["mAP"]) }}</div>
        <div class="cell">{{ parseFloat(results["mAR"]) }}</div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "ComparisonTableStaticSegmentation",
  props: {
    validation: {
      type: Object,
      default: () => {},
    },
  },
  emits: { 'select': null },
  data() {
    return {
      currentEntry: 0,
    };
  },
  computed: {
    results() {
      return this.validation.results ? this.validation.results : null;
    },
    params() {
      return this.validation.params ? this.validation.params : null;
    },
  },
};
</script>

<style lang="scss" scoped>

.comparison-table {
  height: 100%;
  width: 100%;
  box-shadow: 0 0 10px rgba(0,0,0,0.1), 0 0 5px rgba(0,0,0,0.25);
  border-radius: 8px;
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 0.95rem;
  @include themify() {
    background: themed('card-color');
  }
}

.table-header {
  display: flex;
  align-items: center;

}
.title {
  display: flex;
  align-items: center;
  margin-left: 15px;
  margin-right: 15px;
  font-size: 1.3rem;
  font-weight:700;
}

.table {
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  width: calc(100% - 20px);
  margin: 8px;

  .t-row {
    display: flex;
    flex-direction: row;
    cursor: default;
    @include themify() {
      color: themed('comparison-table-header-text-color');
    }
    .cell {
      flex: 1 1 100px;
      font-weight: 700;
    }
    border-bottom: 0.5px solid rgba(96, 95, 95, 0.5);
  }

  .t-row:nth-of-type(odd) {
    background-color: rgba(0, 0, 0, .05);
  }

  .header-row {
    font-weight: 700;
    @include themify() {
      color: themed('comparison-table-text-color');
    }
    border-bottom: 0.5px solid rgba(96, 95, 95, 0.5);
  }

  .header-row:first-child {
    border-top: 0.5px solid rgba(96, 95, 95, 0.5);
  }

  // .header-row .cell {
  //   @include themify {
  //     background-color: themed('annotation-table-column-header');
  //     color: themed('annotation-table-header-color');
  //   }
  //   font-weight:700;
  // }

  .cell {
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 4px;
    overflow: hidden;
    white-space: wrap;
  }

  .cell + .cell {
    border-left:  0.5px solid rgba(96, 95, 95, 0.5);
  }
}
</style>
