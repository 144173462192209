<template>
  <div class="stats-graph">
    <div class="table-header">
      <span class="title">Class Histogram</span>
      <div class="tab-selector">
        <input
          id="option-acc"
          v-model="mode"
          type="radio"
          name="tab-selector"
          :value="'accuracy'"
        >
        <label for="option-acc">Accuracy</label>
        <input
          id="option-precision"
          v-model="mode"
          type="radio"
          name="tab-selector"
          :value="'precision'"
        >
        <label for="option-precision">Precision</label>
        <input
          id="option-recall"
          v-model="mode"
          type="radio"
          name="tab-selector"
          :value="'recall'"
        >
        <label for="option-recall">Recall</label>
        <input
          id="option-tp"
          v-model="mode"
          type="radio"
          name="tab-selector"
          :value="'tp'"
        >
        <label for="option-tp">TP</label>
        <input
          id="option-fn"
          v-model="mode"
          type="radio"
          name="tab-selector"
          :value="'fn'"
        >
        <label for="option-fn">FN</label>
        <input
          id="option-fp"
          v-model="mode"
          type="radio"
          name="tab-selector"
          :value="'fp'"
        >
        <label for="option-fp">FP</label>
      </div>
    </div>
    <apexchart
      id="apexchart"
      height="95%"
      type="bar"
      :options="chartOptions"
      :series="series"
    />
  </div>
</template>

<script>

import VueApexCharts from "vue3-apexcharts";

export default {
  name: 'ClassHistogramData',
  components: {
    'apexchart': VueApexCharts,
  },
  props: {
    validation: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      chartOptions: {
        plotOptions: {
          bar: {
            columnWidth: '45%',
            distributed: true,
          },
        },
        chart: {
          toolbar: {
            show: false,
          },
        },
        xaxis: {
          tickPlacement: 'between',
        },
        yaxis: {
          title: {
            text: this.mode,
          },
          labels: {
            formatter(val) {
              return parseFloat(val).toFixed(2);
            },
          },
        },
        dataLabels: {
          enabled: false,
        },
        tooltip: {
          fillSeriesColor: false,
        },
        legend: {
          show: false,
        },
      },
      series: [],
      mode: 'accuracy',
    };
  },
  computed: {
    histogramData() {
      if (this.validation) {
        const formattedData = [];
        Object.values(this.validation.results["index_name_map"]).forEach((name) => {
          const obj = {};
          obj[name] = this.validation.results["class_histogram_data"][name] ? this.validation.results["class_histogram_data"][name][this.mode] : 0;
          formattedData.push(obj);
        });
        return formattedData;
      } else {
        return [];
      }
    },
  },
  watch: {
    histogramData: {
      deep: true,
      handler(data) {
        if (data.length > 0) {
          this.updateBar();
        }
      },
    },
  },
  mounted() {
    if (this.histogramData) {
      this.updateBar();
    }
    this.$nextTick(() => {
      window.dispatchEvent(new Event('resize'));
    });
  },
  methods: {
    updateBar() {
      const data = {
        series: [{
          data: [],
          name: this.mode,
        }],
        labels: [],
      };
      // This requires the first key-value pair of entry to be the label, the second to be the value at that label
      this.histogramData.forEach((datum) => {
        data.series[0].data.push(Object.values(datum)[0]);
        data.labels.push(Object.keys(datum)[0]);
      });
      this.updateChart(data);
    },
    // update value of each column
    updateChart(data) {
      this.series = data.series;
      this.chartOptions = {
        ...this.chartOptions,
        ...{
          xaxis: {
            categories: data.labels,
          },
          colors: this.getColors(),
        },
      };
    },
    getColors() {
      const colors = {};
      this.histogramData.forEach((label, i) => {
        if (i < 12) {
          colors[Object.keys(label)[0]] = this.getColorByIndex(i);
        } else {
          colors[Object.keys(label)[0]] = this.getColorByIndexFromScale(i);
        }
      });
      return Object.values(colors);
    },
    getColorByIndexFromScale(index) {
      const hue = index * 137.508 + 60; // use golden angle approximation
      return `hsl(${hue},100%,50%)`;
    },
    getColorByIndex(index) {
      const colors = ['#ffff00', '#00ff00', '#ffa500', '#ff0000', '#48d1cc', '#a0522d', '#ffc0cb', '#ff1493', '#f0e68c', '#1e90ff', '#0000ff', '#00fa9a'];
      return colors[index % 12];
    },
  },
};

</script>

<style scoped lang="scss">
.stats-graph {
  height: 100%;
  min-height: 400px;
  width: 100%;
  box-shadow: 0 0 10px rgba(0,0,0,0.1), 0 0 5px rgba(0,0,0,0.25);
  border-radius: 8px;
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 0.95rem;
  @include themify() {
    background: themed('card-color');
  }
}

.table-header {
  display: flex;
  align-items: center;
  justify-content: space-between;

}
.title {
  display: flex;
  align-items: center;
  margin-left: 15px;
  margin-right: 15px;
  font-size: 1.3rem;
  font-weight:700;
}

.tab-selector {
  display: flex;
  flex-direction: row;
}

.tab-selector input[type="radio"] {
  display: none;
}

.tab-selector label {
  padding: 2px 5px;
  margin-right: 10px;
  width: fit-content;
  font-size: 0.9rem;
  font-weight: bold;
  cursor: pointer;
}

.tab-selector input[type="radio"]:checked+label {
  @include themify() {
    color: themed('color-primary-500');
    border-bottom: 2px solid themed('color-primary-500');
  }
}
</style>
