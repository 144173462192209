<template>
  <FilterPopper>
    <BaseFilterButton :name="'Metric'" :active="Object.keys(selectedMetric).length > 0" :tagValue="selectedMetric?.name" />
    <template #popper>
      <div
        ref="checkboxContainer"
        class="filter-settings scrollbar"
      >
        <div v-for="(metric, i) in metrics" :key="i" class="filter-settings__row">
          <input
            :id="`annotationSetsForGroundTruth-${i}`"
            v-model="selectedMetric"
            type="radio"
            :name="`${metric.type}-${i}`"
            :value="metric"
          >
          <label :for="`metric-${i}`">{{ metric.name }}</label><br>
        </div>
      </div>
    </template>
  </FilterPopper>
</template>

<script>
import FilterPopper from '@/components/FilterPopper.vue';
import BaseFilterButton from '@/components/BaseFilterButton.vue';

export default {
  name: "MetricFilter",
  components: {
    FilterPopper,
    BaseFilterButton,
  },
  props: {
    restore: {
      type: Object,
      default: () => {},
    },
    modelValue: {
      type: Object,
      default: () => {},
    },
  },
  emits: [
    'update:modelValue',
  ],
  data() {
    return {
      metrics: [
        {
          name: 'IoU',
          type: 'iou',
        },
        {
          name: 'Center Point',
          type: 'centerpoint',
        },
      ],
      selectedMetric: {},
    };
  },
  watch: {
    selectedMetric() {
      this.updateFilters();
    },
    restore: {
      handler() {
        if (this.restore) {
          this.selectedMetric = this.restore;
        }
      },
    },
  },
  mounted() {
    if (this.restore) {
      this.selectedMetric = this.restore;
    }
  },
  methods: {
    updateFilters() {
      this.$emit('update:modelValue', this.selectedMetric);
    },
  },
};
</script>

<style lang="scss" scoped>

.filter-tag {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 18px;
  padding: 0 6px;
  height: 18px;
  @include themify() {
    background: themed('color-accent');
  }
}

.filter-settings {
  display: flex;
  flex-direction: column;
  padding: 8px;
  border-radius: inherit;
  gap: 6px;
  overflow: auto;
  min-width: 150px;
  max-width: 200px;

  &__row {
    display: flex;
    align-items: center;
  }

  & input[type=radio] {
    margin-right: 12px;
  }
}
</style>
