<template>
  <BaseMenu
    v-if="userInfo"
    :placement="'bottom'"
    :appendToBody="true"
  >
    <button class="user-circle">
      <div v-if="userInfo" class="user-circle__letter">
        {{ userInfo.first_name && userInfo.last_name ? userInfo.first_name.charAt(0).toUpperCase()+ userInfo.last_name.charAt(0).toUpperCase() : userInfo.username.charAt(0).toUpperCase() }}
      </div>
      <div v-else />
    </button>
    <template #menu="{closeMenu}">
      <div id="user-menu" class="user-menu">
        <ul>
          <li v-if="isSuper">
            <BaseMenuButton @click="closeMenu(), $router.push({ path: '/super' })">
              <SVGIcon
                :width="'24px'"
                :height="'24px'"
                :iconName="'admin'"
              />
              <span>Super Admin</span>
            </BaseMenuButton>
          </li>
          <li v-if="isSuper || isAdmin">
            <BaseMenuButton @click="closeMenu(), $router.push({ path: '/admin' })">
              <SVGIcon
                :width="'24px'"
                :height="'24px'"
                :iconName="'verified'"
              />
              <span>Admin Console</span>
            </BaseMenuButton>
          </li>
          <li>
            <BaseMenuButton @click="closeMenu(), $router.push({ path: '/user' })">
              <SVGIcon
                :width="'24px'"
                :height="'24px'"
                :iconName="'account_circle'"
              />
              <span>Profile</span>
            </BaseMenuButton>
          </li>
          <li>
            <BaseMenuButton @click="closeMenu(), openPricingModal()">
              <SVGIcon
                :width="'24px'"
                :height="'24px'"
                :iconName="'payments'"
              />
              <span>Plans & Pricing</span>
            </BaseMenuButton>
          </li>
        </ul>
        <hr>
        <ul>
          <li>
            <BaseMenuButton>
              <SVGIcon
                :width="'24px'"
                :height="'24px'"
                :iconName="'darkmode'"
              />
              <span>Dark Mode</span>
              <BaseSwitch v-model:active="darkModeActive" />
            </BaseMenuButton>
          </li>
          <li v-if="allowDebug === 'yes'">
            <BaseMenuButton>
              <SVGIcon
                :width="'24px'"
                :height="'24px'"
                :iconName="'debug'"
              />
              <span>Debug</span>
              <BaseSwitch v-model:active="debugModeActive" />
            </BaseMenuButton>
          </li>
        </ul>
        <hr>
        <ul>
          <li>
            <BaseMenuButton @click="logOut">
              <SVGIcon
                :width="'24px'"
                :height="'24px'"
                :iconName="'logout'"
              />
              <span>Log Out</span>
            </BaseMenuButton>
          </li>
        </ul>
      </div>
    </template>
  </BaseMenu>
  <PricingModal
    ref="pricingModal"
  />
</template>

<script>
import BaseMenu from '@/components/BaseMenu.vue';
import SVGIcon from '@/components/SVGIcon.vue';
import BaseSwitch from '@/components/BaseSwitch.vue';
import BaseMenuButton from '@/components/BaseMenuButton.vue';
import DatastoreConnect from '@/assets/js/DatastoreFunctions/datastore-interface';
import PricingModal from '@/components/PricingModal.vue';

export default {
  name: 'UserMenu',
  components: {
    SVGIcon,
    BaseSwitch,
    BaseMenu,
    BaseMenuButton,
    PricingModal,
  },
  data() {
    return {
      debugModeActive: false,
      darkModeActive: false,
      dataConnect: new DatastoreConnect(this.$store.state.enterpriseServerUrl),
    };
  },
  computed: {
    userInfo() {
      return this.$store.state.user.user;
    },
    themeStoreState() {
      return this.$store.state.theme;
    },
    debugModeStoreState() {
      return this.$store.state.debugMode;
    },
    allowDebug() {
      return this.$store.state.appVersion.allowDebug;
    },
    isSaas() {
      return this.$store.state.appVersion.isSaas;
    },
    isSuper() {
      return this.userInfo.role === 'super';
    },
    isAdmin() {
      return this.userInfo.role === 'admin';
    },
  },
  watch: {
    debugModeActive() {
      this.$store.commit('setDebugMode', this.debugModeActive);
    },
    darkModeActive(value) {
      if (value) {
        this.$store.commit('setTheme', "theme-auzone-dark");
      } else {
        this.$store.commit('setTheme', "theme-auzone-light");
      }
    },
    themeStoreState(value) {
      this.setTheme(value);
    },
  },
  mounted() {
    if (this.themeStoreState === "theme-auzone-dark") {
      this.darkModeActive = true;
    }
    if (this.debugModeStoreState) {
      this.debugModeActive = this.debugModeStoreState;
    }
    this.setTheme(this.themeStoreState);
  },
  methods: {
    setTheme(value) {
      this.$store.commit('setTheme', value);
    },
    logOut() {
      this.$router.push({ path: '/logout' });
    },
    open(page) {
      window.open(page);
    },
    openPricingModal() {
      this.$refs.pricingModal.showModal();
    },
  },
};
</script>

<style lang="scss" scoped>
.user-circle {
  display: flex;
  flex: 0 0 auto;
  width: 32px;
  height: 32px;
  cursor: pointer;
  border: none;
  border-radius: 50%;
  background: rgb(5, 60, 143);
  color: inherit;
  margin-right: 8px;
  margin-left: 4px;
  justify-content: center;
  align-items: center;

  &__letter {
    height: min-content;
    font-size: 1rem;
  }
}

.user-menu:deep {
  width: 186px;
  .switch {
    margin-left: auto;
  }
}

</style>
