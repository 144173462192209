<template>
  <BaseMenu
    v-if="notificationCounter > 0"
    :placement="'bottom'"
    :offset="[0,0]"
    :appendToBody="true"
  >
    <button class="warning-button">
      <div v-if="notificationCounter > 0" class="icon-badge">
        <span>{{ notificationCounter }}</span>
      </div>
      <SVGIcon
        :height="'32px'"
        :width="'32px'"
        :iconName="'warning'"
        :showHover="false"
        :showActive="true"
      />
    </button>
    <template #menu>
      <div class="help-menu">
        <ul>
          <li
            v-for="notif in notifications"
            :key="notif.id"
            class="notification"
            :class="{ 'warning': notif.key.includes('warning') }"
          >
            {{ notif.value }}
          </li>
        </ul>
      </div>
    </template>
  </BaseMenu>
</template>

<script>
import BaseMenu from '@/components/BaseMenu.vue';
import SVGIcon from '@/components/SVGIcon.vue';
import DatastoreConnect from '@/assets/js/DatastoreFunctions/datastore-interface';

export default {
  name: 'WarningMenu',
  components: {
    SVGIcon,
    BaseMenu,
  },
  data() {
    return {
      dsConn: new DatastoreConnect(this.$store.state.enterpriseServerUrl),
      notifications: [],
    };
  },
  computed: {
    refreshTrigger() {
      return this.$store.state.notifications.hasNotifications;
    },
    notificationCounter() {
      return this.notifications.length;
    },
  },
  watch: {
    refreshTrigger: {
      immediate: true,
      handler(shouldRefresh) {
        if (shouldRefresh) {
          this.getNotifications();
          this.$store.commit('notifications/updateNotifications', false);
        }
      },
    },
  },
  mounted() {
    this.getNotifications();
  },
  methods: {
    async getNotifications() {
      await this.dsConn.listWarnings()
        .then((data) => {
          if (data.result) {
            this.notifications = data.result;
          }
        })
        .catch((e) => console.log(e));
    },
  },
};
</script>

<style lang="scss" scoped>
.icon-badge {
  background-color: red;
  padding: 1px;
  font-size: 11px;
  color: white;
  text-align: center;
  width: -moz-fit-content;
  width: fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 40%;
  position: absolute;
  top: 5%;
  right: -5%;
  z-index: 100;
  display: flex;
  flex-direction: column;

  span {
    padding: 0 4px 0 4px;

  }
}

.warning-button {
  display: flex;
  position: relative;
  flex: 0 0 auto;
  height: 100%;
  align-items: center;
  justify-content: center;
  padding: 0 4px;
  background: none;
  border: none;
  cursor: pointer;
  @include themify() {
    color: themed('header-dot-menu');
  }

  &:hover {
    @include themify() {
      background: themed('header-button-background-hover');
      color: themed('header-button-color-hover');
    }
  }

  &._active {
    @include themify() {
      background: themed('header-button-background-hover');
      color: themed('header-button-color-hover');
    }
  }
}

.help-menu:deep {
  min-width: 450px;
  .switch {
    margin-left: auto;
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  a > span {
    font: inherit;
    font-size: 1rem;
    font-weight: 600;
    margin-left: 12px;
    white-space: nowrap;
  }
}

.notification {
  font-size: 0.9;
  padding: 5px;
  border-radius: 8px;
  width: fit-content;
}
.notification +.notification {
  margin-top: 10px;
}

.warning {
  font-size: 0.9;
  @include themify() {
    background: rgba(198, 120, 120, 0.5) ;
    color: themed('color-error');
    border: 1px solid themed('color-error');
  }
}

</style>
