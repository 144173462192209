<template>
  <BaseSelect
    v-model="value"
    class="breadcrumb-select"
    :options="options"
    :placeholder="placeholder"
    :displayTag="displayTag"
    @select="$emit('select', $event)"
    @update:modelValue="$emit('update:modelValue', $event)"
  />
</template>

<script>
import BaseSelect from '@/components/BaseSelect.vue';

export default {
  name: "BreadcrumbSelect",
  components: {
    BaseSelect,
  },
  props: {
    modelValue: {
      type: [String, Object],
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Array,
      default: () => [],
    },
    displayTag: {
      type: String,
      default: null,
    },
    flip: {
      type: Boolean,
      default: true,
    },
  },
  emits: ['update:modelValue', 'select'],
  data() {
    return {
      value: '',
    };
  },
  watch: {
    modelValue(value) {
      this.value = value;
    },
  },
  mounted() {
    this.value = this.modelValue;
  },
};
</script>

<style lang="scss" scoped>

.breadcrumb-select {
  background: transparent;
  color: inherit !important;
  width: 250px;
  height: 100%;
}

:deep {
  .base-select__label {
      color: hsla(210, 12%, 90%, 75%);
  }
}

</style>
