<template>
  <v-expansion-panels
    v-model="panels"
    multiple
    class="mt-2"
  >
    <v-expansion-panel
      value="description"
    >
      <v-expansion-panel-title>
        <h3>Advanced Model Settings</h3>
      </v-expansion-panel-title>
      <v-expansion-panel-text>
        <!-- ModelPack -->
        <div class="form container">
          <div class="row">
            <div class="col-12 settings__control-group">
              <label class="required">Advanced Settings Profile</label>
              <select v-model="selectedProfile" class="select">
                <option value="default">Default</option>
                <option value="rknn">Rockchip RKNN</option>
              </select>
            </div>
          </div>
          <div class="row mt-3">
            <div class="col-12 settings__control-group">
              <label class="required">Upsample</label>
              <select v-model="settings.upsample" class="select">
                <option value="resize">Resize</option>
                <option value="conv">Conv</option>
              </select>
            </div>
          </div>
        </div>
      </v-expansion-panel-text>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>

export default {
  name: 'TrainerSessionAdvancedSettings',
  components: {
  },
  props: {
    modelValue: {
      type: Object,
      default: null,
    },
  },
  emits: ['update:modelValue'],
  data() {
    return {
      panels: [],
      settings: {
        upsample: 'resize',
      },
      selectedProfile: 'default',
      profiles: {
        default: {
          upsample: 'resize',
        },
        rknn: {
          upsample: 'conv',
        },
      },
    };
  },
  computed: {

  },
  watch: {
    settings: {
      handler() {
        this.$emit('update:modelValue', this.settings);
      },
    },
    selectedProfile(selectedProfile) {
      this.settings = this.profiles[selectedProfile];
    },
  },
  mounted() {
    this.settings = this.modelValue;
  },
  methods: {

  },
};

</script>

<style lang="scss" scoped>

.v-expansion-panel {
  border-radius: 4px !important;
}

.v-expansion-panel-title--active {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.v-expansion-panel:not(:first-child), .v-expansion-panel + .v-expansion-panel {
    margin-top: 16px;
}

</style>
