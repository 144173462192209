<template>
  <div v-if="dataset !== null" class="dataset-stats__page">
    <StatsOverview
      :dataset="dataset"
      :boxSize="boxSize"
      @new-set="handleNewSetSelected"
    />

    <div class="dataset-stats__main scrollbar">
      <template v-if="isNotEmpty && !fetching">
        <div class="row-40">
          <div class="chart col-40">
            <LabelsPieChart
              :name="'Label Count'"
              :dataArray="labelCountData"
            />
          </div>
          <div class="chart col-60">
            <ConfidenceHistogram
              :name="'Confidence Histogram'"
              :dataArray="histogramData"
            />
          </div>
        </div>
        <div class="row-40">
          <div class="chart col-100">
            <BoundingBoxSizeHistogram
              :name="'Annotation Size Histogram'"
              :dataArray="bboxSizeHistogram"
              @custom-range="(range) => getBBoxSizeHistogram(range)"
            />
          </div>
        </div>
        <div class="row-60">
          <div class="chart col-50">
            <LabelLocationMap
              :name="'Annotation Location Density'"
              :dataset="dataset"
              :labelList="labelList"
              :dataArray="locationMap"
              @new-location-label="handleNewLabelSelected"
            />
          </div>
          <div class="chart col-50">
            <BoundingBoxDisplay
              :name="'Bounding Box Visualization'"
              :dataset="dataset"
              :labelList="labelList"
              :annotationList="filteredAnnotationList"
              @new-visualizer-label="handleNewLabelVisualizerSelected"
            />
          </div>
        </div>
      </template>
      <template v-else-if="isNotEmpty && fetching">
        <div
          class="empty-state"
        >
          <svg width="60%" height="60%">
            <use href="@/assets/img/empty.svg#emptyStateSVG" />
          </svg>
          <div class="empty-state__message">
            <h3>Fetching analytics.</h3>
          </div>
        </div>
      </template>
      <template v-else>
        <div
          class="empty-state"
        >
          <svg width="60%" height="60%">
            <use href="@/assets/img/empty.svg#emptyStateSVG" />
          </svg>
          <div class="empty-state__message">
            <h3>Please select an annotation set.</h3>
          </div>
        </div>
      </template>
    </div>
  </div>
  <div
    v-else
    class="empty-state"
  >
    <svg width="60%" height="60%">
      <use href="@/assets/img/empty.svg#emptyStateSVG" />
    </svg>
    <div class="empty-state__message">
      <h3>Please select a dataset.</h3>
    </div>
  </div>
</template>

<script>
import DatastoreConnect from '@/assets/js/DatastoreFunctions/datastore-interface';
import StatsOverview from '@/components/DatasetComponent/DatasetStats/StatsOverview.vue';
import ConfidenceHistogram from '@/components/DatasetComponent/DatasetStats/ConfidenceHistogram.vue';
import LabelsPieChart from '@/components/DatasetComponent/DatasetStats/LabelsPieChart.vue';
import LabelLocationMap from '@/components/DatasetComponent/DatasetStats/LabelLocationMap.vue';
import BoundingBoxDisplay from '@/components/DatasetComponent/DatasetStats/BoundingBoxDisplay.vue';
import BoundingBoxSizeHistogram from '@/components/DatasetComponent/DatasetStats/BoundingBoxSizeHistogram.vue';

export default {
  name: "DatasetStats",
  components: {
    StatsOverview,
    LabelsPieChart,
    ConfidenceHistogram,
    LabelLocationMap,
    BoundingBoxDisplay,
    BoundingBoxSizeHistogram,
  },
  props: {
  },
  emits: [],
  data() {
    return {
      dataConnect: null,
      set: null,
      boxSize: null,
      labelCountData: null,
      histogramData: null,
      locationMap: null,
      fullAnnotationList: [],
      active: false,
      labelList: [],
      labelLocation: null,
      labelVisualizer: null,
      bboxSizeHistogram: null,
      fetching: false,
    };
  },
  computed: {
    dataset() {
      return this.$store.state.datasets.currentDataset;
    },
    currentProject() {
      return this.$store.state.projects.currentProject;
    },
    isNotEmpty() {
      return this.labelCountData !== null && this.histogramData !== null;
    },
    filteredAnnotationList() {
      if (this.labelVisualizer && this.labelVisualizer.index !== -1) {
        return this.fullAnnotationList.filter((e) => e.label_id === this.labelVisualizer.id);
      } else {
        return this.fullAnnotationList;
      }
    },
  },
  watch: {
    labelLocation: {
      handler(l) {
        if (l) {
          this.getLocationMap(l);
        }
      },
    },
    dataset: {
      handler() {
        this.resetState();
      },
    },
  },
  created() {
    this.dataConnect = new DatastoreConnect(this.$store.state.enterpriseServerUrl);
  },
  mounted() {
    if (this.dataset) {
      this.getLabelList();
    }
  },
  unmounted() {
    this.$store.commit('statistics/setDatasetID', this.dataset?.id);
    this.$store.commit('statistics/setAnnotationSet', this.set);
    this.$store.commit('statistics/setLabelLocation', this.labelLocation);
    this.$store.commit('statistics/setLabelVisualizer', this.labelVisualizer);
  },
  methods: {
    resetState() {
      this.set = null;
      this.labelCountData = null;
      this.histogramData = null;
      this.locationMap = null;
      this.boxSize = null;
      this.labelLocation = null;
      this.labelVisualizer = null;
      this.getLabelList();
    },
    async getAllStats() {
      const promises = [];
      this.fetching = true;
      promises.push(this.getConfHist());
      promises.push(this.getLabelCount());
      promises.push(this.getMinMaxBox());
      promises.push(this.getAllAnnotations());
      promises.push(this.getBBoxSizeHistogram([0.0, 1.0]));

      await Promise.all(promises)
        .then((resp) => {
          this.fetching = false;
        })
        .catch((e) => {
          console.log(e);
          this.fetching = false;
        });
    },
    async getLabelList() {
      if (this.dataset) {
        await this.dataConnect.getLabelList({ dataset_id: this.dataset.id })
          .then((data) => {
            this.labelList = data.result;
          })
          .catch((error) => {
          // TODO: handle error
            console.log(error);
          });
      }
    },
    async getConfHist() {
      await this.dataConnect.getDatasetStats(
        {
          type: "dataset_histogram",
          dataset_id: this.dataset.id,
          annotation_sets: this.set.id,
        },
      )
        .then((data) => {
          this.histogramData = data.result;
        })
        .catch((e) => console.log(e));
    },
    async getBBoxSizeHistogram(range) {
      await this.dataConnect.getDatasetStats(
        {
          dataset_id: this.dataset.id,
          type: "bbox_size_histogram",
          annotation_sets: this.set.id,
          lower: range[0],
          upper: range[1],
        },
      )
        .then((data) => {
          this.bboxSizeHistogram = data.result;
          this.bboxSizeHistogram.annotation_set_name = this.set.name;
        })
        .catch((e) => console.log(e));
    },
    async getLabelCount() {
      await this.dataConnect.getDatasetStats(
        {
          type: "label_count",
          dataset_id: this.dataset.id,
          annotation_sets: this.set.id,
        },
      )
        .then((data) => {
          this.labelCountData = data.result;
        })
        .catch((e) => console.log(e));
    },
    async getLocationMap(label) {
      if (label) {
        await this.dataConnect.getDatasetStats(
          {
            type: "location_map",
            dataset_id: this.dataset.id,
            annotation_sets: this.set.id,
            label_id: label.id,
          },
        )
          .then((data) => {
            this.locationMap = data.result;
          })
          .catch((e) => console.log(e));
      }
    },
    async getMinMaxBox() {
      await this.dataConnect.getDatasetStats(
        {
          type: "box_sizes",
          dataset_id: this.dataset.id,
          annotation_sets: this.set.id,
        },
      )
        .then((data) => {
          this.boxSize = data.result;
        })
        .catch((e) => console.log(e));
    },
    async getAllAnnotations() {
      await this.dataConnect.getAnnotationsForAnnotationSets(
        {
          annotation_set_id: this.set.id,
        },
      )
        .then((data) => {
          this.fullAnnotationList = data.result;
        })
        .catch((e) => console.log(e));
    },
    handleNewSetSelected(s) {
      if (s && Object.keys(s).length > 0) {
        this.set = s;
        this.getAllStats();
        this.getLocationMap(this.labelLocation);
      }
    },
    handleNewLabelSelected(l) {
      this.labelLocation = l;
    },
    handleNewLabelVisualizerSelected(l) {
      this.labelVisualizer = l;
    },
  },
};
</script>

<style lang="scss" scoped>
.dataset-stats {

  &__page {
    display: flex;
    flex-direction: row;
    flex: 1 1 auto;
    height: 100%;
  }

  &__main {
    height: calc(100% - 20px);
    width: 100%;
    margin: 10px;
    padding-top: 3px;
    padding-bottom: 2px;
    padding-left: 3px;
    padding-right: 10px;
    overflow-y: scroll;

    .row-40 {
      display: flex;
      flex-direction: row;
      height: 400px;
    }
    .row-60 {
      display: flex;
      flex-direction: row;
      height: 600px;
    }

    .col-40 {
      display: flex;
      flex-direction: column;
      width: 40%;
    }

    .col-50 {
      display: flex;
      flex-direction: column;
      width: 50%;
    }

    .col-60 {
      display: flex;
      flex-direction: column;
      width: 60%;
    }

    .col-100 {
      display: flex;
      flex-direction: column;
      width: 100%;
    }

    .chart + .chart {
      margin-left: 20px;
    }

  }

  &__header {
    display: flex;
    flex-direction: row;
    align-items: center;

    span {
      font-weight: 700;
      font-size: 1.1rem;
      margin-right: 20px;
    }
  }
}

.add-task-icon:hover {
  @include themify() {
    color: themed('icon-color-primary');
  }
}

.empty-state {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  height: 100%;
}
</style>
