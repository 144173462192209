<template>
  <div class="active-time-card">
    <div class="active-time-card__text">
      <span>Active Time</span>
      <div class="active-time-card__text-value">
        <SVGIcon
          :iconName="'clock'"
          :width="'18px'"
          :height="'18px'"
        />
        <div class="active-time-card__text-time">
          <span v-if="activeTimeFormatted.hours">{{ activeTimeFormatted.hours }}h</span>
          <span v-if="activeTimeFormatted.minutes">{{ activeTimeFormatted.hours ? activeTimeFormatted.minutes.toString().padStart(2, '0') : activeTimeFormatted.minutes }}m</span>
          <span>{{ activeTimeFormatted.minutes ? activeTimeFormatted.seconds.toString().padStart(2, '0') : activeTimeFormatted.seconds }}s</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SVGIcon from '@/components/SVGIcon.vue';
import useActiveTimer from '@/composables/useActiveTimer.js';
import {
  ref, watch,
} from 'vue';

export default {
  name: 'TaskActiveTimeCard',
  components: {
    SVGIcon,
  },
  setup(props, ctx) {
    // Setup Timer
    const {
      activeTime, activeTimeFormatted, resume: resumeActiveTimer, pause: pauseActiveTimer,
    } = useActiveTimer(10 * 1000);
    return {
      activeTimeFormatted,
    };
  },
};
</script>

<style lang="scss" scoped>
.active-time-card {
  display: flex;
  flex-direction: row;
  width: fit-content;
  border-radius: 8px;
  background: var(--color-primary-400);

  &__text {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 2px 8px;
    font-size: 0.75rem;
    font-weight: 600;
    color: var(--primary-text-color-secondary);
    align-items: flex-start;
    white-space: nowrap;
  }

  &__text-value {
    display: flex;
    flex-direction: row;
    font-size: 0.75rem;
    font-weight: 600;
    color: var(--primary-text-color);
    white-space: nowrap;
    gap: 4px;
  }

  &__text-time {
    display: flex;
    flex-direction: row;
    gap: 4px;
  }
}
</style>
