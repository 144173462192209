<template>
  <div class="app-page">
    <Header ref="header">
      <template #header-slot>
        <BreadcrumbNavigation />
      </template>
      <template #page-slot>
        <span v-if="org" class="mt-0 mb-0">{{ org.name }}</span>
      </template>
    </Header>
    <div class="main-page-div">
      <div class="user-router">
        <div class="user-router__container">
          <ul>
            <li :class="{ 'active': currentTab.includes('super/list') }" @click="navigateToOrganizationList">
              <SVGIcon
                :iconName="'organizations'"
                :width="'20px'"
                :height="'20px'"
              />
              <span>Organization List</span>
            </li>
            <li :class="{ 'active': currentTab.includes('super/neworg') }" @click="navigateToCreateOrganization">
              <SVGIcon
                :iconName="'people_add'"
                :width="'20px'"
                :height="'20px'"
              />
              <span>New Organization</span>
            </li>
          </ul>
        </div>
      </div>
      <main class="main-display-area">
        <router-view />
      </main>
    </div>
  </div>
</template>

<script>
import DatastoreConnect from '@/assets/js/DatastoreFunctions/datastore-interface';
import SVGIcon from '@/components/SVGIcon.vue';
import Header from '@/components/Header.vue';
import BreadcrumbNavigation from '@/components/BreadcrumbNavigation.vue';

export default {
  name: 'UserProfile',
  components: {
    Header,
    BreadcrumbNavigation,
    SVGIcon,
  },
  data() {
    return {
      dsConn: new DatastoreConnect(this.$store.state.enterpriseServerUrl),
      currentTab: 'account',
      org: null,
    };
  },
  computed: {
    currentProject() {
      return this.$store.state.projects.currentProject;
    },
    user() {
      return this.$store.state.user.user;
    },
    hasOrg() {
      return this.user && this.user.organization_id !== 0;
    },
    isAdmin() {
      return this.user && (this.user.role === 'super' || this.user.role === 'admin');
    },
    isSuper() {
      return this.user && this.user.role === 'super';
    },
  },
  watch: {
    $route(to, from) {
      if (to.query.org) {
        this.getOrg(parseInt(to.query.org));
      } else {
        this.org = null;
      }
      if (to && to !== '') {
        this.currentTab = to.fullPath;
      }
    },
  },
  created() {
    if (!this.isSuper) {
      this.$router.push('/login');
    }
    if (this.$route) {
      this.currentTab = this.$route.fullPath;
    }
  },
  methods: {
    async getOrg(id) {
      await this.dsConn.getOrg({ organization_id: id })
        .then((data) => {
          if (data.result) {
            this.org = data.result;
          }
        })
        .catch((e) => { this.message = e; });
    },
    navigateToCreateOrganization() {
      this.$router.push({ name: 'super.neworg' });
    },
    navigateToOrganizationList() {
      this.$router.push({ name: 'super.list' });
    },
    navigateToAddCredits() {
      this.$router.push({ name: 'super.addcredits' });
    },
    navigateToUserEmulation() {
      this.dsConn.send2FA({ username: this.user.username });
      this.$router.push({ name: 'super.emulate' });
    },
  },
};
</script>

<style lang="scss" scoped>

.user-router {
  min-width: 250px;
  width: 250px;
  @include themify() {
    background-color: themed('background-light-gray');
  };
  padding: 30px 0px 30px 30px;

  &__container {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    height: 100%;
    padding-right: 30px;

    ul {
      list-style-type: none;
    }

    ul + ul {
      margin-top: 20px;
    }

    li {
      display: flex;
      flex-direction: row;
      margin-top: 2px;
      margin-bottom: 2px;
      font-size: 0.9rem;
      padding: 4px;
      color: $color-link;
      cursor: pointer;
      border-radius: 4px;

      span {
        margin-left: 5px;
      }

      &.secondary {
          margin-left: 15px;
      }
    }

    li:hover {
      background-color: rgba(140, 140, 140, 0.1);

    }

    li:focus-visible {
      background-color: rgba(140, 140, 140, 0.1);
    }

    li.active {
      background-color: rgba(140, 140, 140, 0.2);
      color: inherit;
    }
  }
}
</style>
