<template>
  <div v-if="show" id="annotationToolRibbon" class="ribbon">
    <div class="ribbon__group">
      <IconButton
        v-model="localShow3DTo2DProjection"
        class="ribbon__button"
        aria-label="3D to 2D Projection"
        :icon="'view_in_ar'"
        :type="'toggle'"
        :title="'3D to 2D Projection'"
      />
      <hr class="ribbon__vr">
      <IconButton
        id="frontViewBtn"
        class="ribbon__button"
        aria-label="Front View"
        :icon="'border_outer_line'"
        :showHover="false"
        :type="''"
        :title="'Front View'"
        @click="handleFrontView"
      />
      <IconButton
        id="topViewBtn"
        class="ribbon__button"
        aria-label="Top View"
        :icon="'border_top_line'"
        :showHover="false"
        :type="''"
        :title="'Top View'"
        @click="handleTopView"
      />
      <hr class="ribbon__vr">
      <IconButton
        id="perspectiveCameraBtn"
        v-model="usePerspectiveCamera"
        class="ribbon__button"
        aria-label="Perspective Camera"
        :icon="'perspective'"
        :showHover="false"
        :type="'toggle'"
        :title="'Perspective Camera'"
        :allowClick="!usePerspectiveCamera"
      />
      <IconButton
        id="orthographicCameraBtn"
        v-model="useOrthographicCamera"
        class="ribbon__button"
        aria-label="Orthographic Camera"
        :icon="'orthographic'"
        :showHover="false"
        :type="'toggle'"
        :title="'Orthographic Camera'"
        :allowClick="!useOrthographicCamera"
      />
    </div>
  </div>
</template>

<script setup>
import IconButton from '@/components/IconButton.vue';
import {
  ref, watch, toRefs, nextTick,
} from 'vue';

const props = defineProps({
  show: { type: Boolean, default: false },
  show3DTo2DProjection: { type: Boolean, default: false },
});
const {
  show,
} = toRefs(props);
const emit = defineEmits(['front-view', 'top-view', 'perspective-camera', 'orthographic-camera', 'update:show3DTo2DProjection']);

const usePerspectiveCamera = ref(true);
const useOrthographicCamera = ref(false);
const localShow3DTo2DProjection = ref(false);

const handleFrontView = () => {
  emit('front-view');
};

const handleTopView = () => {
  emit('top-view');
};

watch(localShow3DTo2DProjection, () => {
  emit('update:show3DTo2DProjection', localShow3DTo2DProjection.value);
});

watch(usePerspectiveCamera, (isEnabled) => {
  if (isEnabled) {
    useOrthographicCamera.value = false;
    emit('perspective-camera');
  }
});

watch(useOrthographicCamera, (isEnabled) => {
  if (isEnabled) {
    usePerspectiveCamera.value = false;
    emit('orthographic-camera');
  }
});

</script>

<style lang="scss" scoped>
.ribbon {
  position: absolute;
  top: 8px;
  right: 8px;
  display: flex;
  flex-direction: row;
  width: fit-content;
  height: 40px;
  padding: 2px 16px;
  justify-content: space-between;
  font-weight: 500;
  box-shadow: 0 0 3px gray, 0 0 10px gray;
  border-radius: 4px;
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  z-index: 10;
  @include themify() {
    color: themed('ribbon-icons-color');
    background: themed('ribbon-bg-transparent');
  }

  &__group {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
  }

  &__vr {
    display: flex;
    width: 1px;
    height: 60%;
    margin: 0;
  }

  &__button {
    display: flex;
    min-width: 22px;
    height: 22px;
    color: inherit;
    background: none;
    border: none;
    font-size: 0.875rem;
    line-height: 1rem;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: .05em;
    align-items: center;
    cursor: pointer;

    &:hover:not(:disabled), &.active:not(:disabled) {
      @include themify() {
        box-shadow: 0 0 0 4px themed('icon-hover-color');
        background-color: themed('icon-hover-color');
      }
      border-radius: 4px !important;
    }

    &:disabled {
      cursor: default;
      @include themify() {
        color: themed('color-disabled');
      }
    }
  }
}

:deep(.filter-button) {
  height: 22px;
}

</style>
