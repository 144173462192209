<template>
  <Modal
    ref="createAnnotationReviewModal"
    :title="'Add New Task'"
    :width="'600px'"
  >
    <template #modal-main>
      <div class="settings container scrollbar">
        <!-- <div class="row mb-3">
          <div class="col-12 settings__control-group">
            <label class="required">Type</label>
            <select
              id="type-select"
              v-model="type"
              class="select"
            >
              <option disabled :value="''">Select a Type</option>
              <option v-for="(option, i) in types" :key="`taskboard-type-${i}`" :value="option.type">
                {{ option.name }}
              </option>
            </select>
          </div>
        </div> -->
        <div class="row mb-3">
          <div class="col-12 settings__control-group">
            <label>Name</label>
            <input v-model="reviewTaskInputName" class="input" type="text">
          </div>
        </div>
        <div class="row mb-3">
          <div class="col-12 settings__control-group">
            <label>Description</label>
            <textarea
              v-model="reviewTaskInputDescription"
              rows="2"
              cols="50"
            />
          </div>
        </div>
        <div class="row mb-3">
          <div class="col-12 settings__control-group">
            <label class="required">Dataset</label>
            <select v-model="srcDataset" class="select" required>
              <option :key="'review-select-null'" disabled :value="''">Select a Dataset</option>
              <option v-for="d in datasets" :key="`review-select-${d.id}`" :value="d">
                {{ d.name }}
              </option>
            </select>
          </div>
        </div>
        <div v-if="srcDataset" class="row mb-3">
          <div class="col-12 settings__control-group">
            <label class="required">Destination Annotation Set</label>
            <v-combobox
              v-model="destAnnotationSetName"
              class="combobox"
              clearable
              :color="'#250e81'"
              :density="'compact'"
              :hide-details="true"
              :label="'Select or Enter New Set Name'"
              :items="destAnnotationSets"
              variant="outlined"
              @click:clear="() => destAnnotationSetName = ''"
            />
          </div>
        </div>
        <div v-if="srcDataset" class="row mb-3">
          <div class="col-12 settings__control-group">
            <label>Source Annotation Set</label>
            <select v-model="srcAnnotationSet" class="select" required>
              <option :key="'review-select-null'" disabled :value="''">Select a Source Annotation Set</option>
              <option v-for="a in annotationSets" :key="`review-select-${a.id}`" :value="a">
                {{ a.name }}
              </option>
            </select>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-12 settings__control-group">
            <label class="label required">Access Sharing</label>
            <div class="value permission-list">
              <div class="role-grid-single-row">
                <div class="chkbx-li">
                  <v-checkbox
                    v-model="presetRole"
                    :hide-details="true"
                    density="compact"
                    value="default"
                    :false-icon="'mdi-checkbox-blank-circle-outline'"
                    :true-icon="'mdi-circle-slice-8'"
                  >
                    <template #label>
                      <div class="label-text">Share with organization</div>
                    </template>
                  </v-checkbox>
                  <span class="chkbx-li__subtitle">Everyone in your organization has access to this task.</span>
                </div>
                <div class="chkbx-li">
                  <v-checkbox
                    v-model="presetRole"
                    :hide-details="true"
                    density="compact"
                    value="user"
                    :false-icon="'mdi-checkbox-blank-circle-outline'"
                    :true-icon="'mdi-circle-slice-8'"
                  >
                    <template #label>
                      <div class="label-text">Share with users</div>
                    </template>
                  </v-checkbox>
                  <span class="chkbx-li__subtitle">Only owner and selected users have access to this task.</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <template v-if="presetRole === 'user'">
          <div class="row">
            <v-combobox
              v-model="userInputValue"
              class="combobox"
              chips
              clearable
              multiple
              :color="'#250e81'"
              :density="'comfortable'"
              :hide-details="true"
              :label="'Add People'"
              :items="userList.filter(obj => obj['username'] !== user.username)"
              :item-title="(obj) => obj['first_name'] && obj['last_name'] ? `${obj['first_name']} ${obj['last_name']}` : obj['username']"
              :item-value="(obj) => obj['username']"
              :return-object="false"
              variant="outlined"
            />
          </div>
        </template>
        <div v-if="message" class="row mt-3">
          <div class="col-12 settings__control-group">
            <div class="result error">
              <span>{{ message }}</span>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template #modal-footer>
      <div class="footer">
        <button
          class="button modal-action-button"
          @click="createReviewTask"
        >
          Create Task
        </button>
      </div>
    </template>
  </Modal>
</template>

<script>
import DatastoreConnect from '@/assets/js/DatastoreFunctions/datastore-interface';
import Modal from '@/components/Modal.vue';

export default {
  name: 'CreateAnnotationReviewTaskModal',
  components: {
    Modal,
  },
  props: {
    datasets: {
      type: Array,
      default: () => [],
    },
  },
  emits: ['review-task-created', 'review-task-created-error'],
  data() {
    return {
      message: '',
      reviewTaskInputName: '',
      reviewTaskInputDescription: '',
      types: [
        {
          name: 'Label',
          type: 'label',
        },
        {
          name: 'Audit',
          type: 'audit',
        },
      ],
      presetRole: 'default',
      userList: [],
      userInputValue: [],
      srcDataset: '',
      srcAnnotationSet: '',
      destAnnotationSetName: null,
    };
  },
  computed: {
    user() {
      return this.$store.state.user.user;
    },
    annotationSets() {
      if (this.srcDataset && this.srcDataset.annotation_sets) {
        return this.srcDataset.annotation_sets;
      } else {
        return [];
      }
    },
    destAnnotationSets() {
      if (this.annotationSets.length > 0 && Object.keys(this.srcAnnotationSet).length > 0) { // destination different from source annset
        return this.annotationSets.filter((set) => set.id !== this.srcAnnotationSet.id).map((set) => set.name);
      } else if (this.annotationSets.length > 0 && Object.keys(this.srcAnnotationSet).length === 0) { // label does not need source ann set
        return this.annotationSets.map((set) => set.name);
      } else {
        return [];
      }
    },
  },
  async mounted() {
    this.$refs.createAnnotationReviewModal.showModal();
    this.dataConnect = new DatastoreConnect(this.$store.state.enterpriseServerUrl);
    this.getUsersListByOrganization(this.user.organization_id);
  },
  methods: {
    showModal() {
      this.$refs.createAnnotationReviewModal.showModal();
    },
    async getUsersListByOrganization(id) {
      await this.dataConnect.getUserListByOrganization({
        organization_id: id,
      })
        .then((data) => {
          if (data.result) {
            this.userList = data.result;
            this.userInputValue = [];
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async createReviewTask() {
      if (!this.srcDataset) {
        this.message = 'Missing Source Dataset';
        return;
      }
      if (!this.destAnnotationSetName) {
        this.message = 'Missing Destination Annotation Set';
        return;
      }

      let reviewTaskDestinationAnnotationSet = null;
      const existingSet = this.annotationSets.find((e) => e.name === this.destAnnotationSetName);
      if (this.destAnnotationSetName && !existingSet) {
        reviewTaskDestinationAnnotationSet = await this.createAnnotationSet(this.destAnnotationSetName);
      } else {
        reviewTaskDestinationAnnotationSet = existingSet;
      }

      let inputName = '';
      if (this.reviewTaskInputName === '') {
        inputName = `${reviewTaskDestinationAnnotationSet.name.replace(/ /g, "-")}`;
      } else {
        inputName = this.reviewTaskInputName;
      }

      const params = {
        type: 'audit',
        source_annotation_set_id: null,
        dest_annotation_set_id: reviewTaskDestinationAnnotationSet.id,
        dataset_id: this.srcDataset.id,
        name: inputName,
        users: this.users,
        description: this.reviewTaskInputDescription,
      };

      if (this.presetRole === 'user') {
        params.usernames = [...this.userInputValue];
        params.usernames.push(this.user.username);
      }

      if (Object.keys(this.srcAnnotationSet).length > 0) {
        params['source_annotation_set_id'] = this.srcAnnotationSet.id;
      }

      const resp = await this.dataConnect.addReviewTask(params)
        .catch((error) => {
          // TODO: handle error
          console.log(error);
        });
      if (resp.error || !resp.result) {
        this.$emit('review-task-created-error', resp.error);
      } else {
        this.$emit('review-task-created');
        this.$refs.createAnnotationReviewModal.closeModal();
      }
    },
    async createAnnotationSet(annotationSetName) {
      const annotationSetExists = this.annotationSets.map((set) => set.name).includes(annotationSetName);
      if (!annotationSetExists) {
        const resp = await this.dataConnect.createAnnotationSet({
          name: annotationSetName,
          description: `Review Task for the annotation set "${annotationSetName}"`,
          operator: this.$store.state.user.user.username,
          origin_x: 0,
          origin_y: 0,
          dataset_id: this.srcDataset.id,
        })
          .catch((error) => {
          // TODO: handle error
            console.log(error);
          });
        if (!resp || resp.error || !resp.result) {
        // TODO: handle error
          return null;
        }
        await this.refreshAnnotationSets();
        return resp.result;
      }
      return this.annotationSets.find((set) => set.name === annotationSetName);
    },

    async refreshAnnotationSets() {
      let sets = null;
      const resp = await this.dataConnect.getAnnotationSets({
        dataset_id: this.srcDataset.id,
        get_num_annotations: true,
        get_num_images: true,
        get_labels: true,
      })
        .catch((error) => {
          console.log('Failed to retrieve sets:', error);
        });
      if (!resp || resp.error || !resp.result) {
        sets = [];
      }
      sets = resp.result;
      const index = this.getDatasetIndex(this.srcDataset);
      this.$store.commit('datasets/setDatasetAnnotationSets', { index, sets });
    },

    getDatasetIndex(dataset) {
      return this.datasets.indexOf(this.datasets.find((d) => d.id === dataset.id));
    },
    getReviewTaskAnnotationSetName(annotationSets, srcName) {
      const annotationSetNames = annotationSets.map((set) => set.name);
      let tempSrcName;
      if (this.type === 'label') {
        tempSrcName = `${srcName}-LABELING`;
      } else if (this.type === 'audit') {
        tempSrcName = `${srcName}-AUDIT`;
      }
      if (!annotationSetNames.includes(tempSrcName)) {
        return tempSrcName;
      }

      let num = 1;
      while (annotationSetNames.includes(`${tempSrcName} (${num})`)) {
        num += 1;
      }
      return `${tempSrcName} (${num})`;
    },
  },
};

</script>

<style lang="scss" scoped>
.settings {
  padding: 0.75rem 3rem;
  min-width: 480px;
  overflow-y: scroll;
}

.footer {
  display: flex;
  flex-direction: row;
  padding: 0.75rem 3rem;
  justify-content: flex-end;
}

.dataset-selector{
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;

  select {
    width: 200px;
    margin-right: 20px;
  }

  button {
    width: 350px;
  }
}

.result {
  display: flex;
  width: 100%;
  height: 100%;
  border-radius: 5px;
  font-size: 1rem;
  font-weight:600;
  padding: 10px;
  align-items: center;
  justify-content: center;
}

.error {
  @include themify() {
    background: rgba(182, 94, 94, 0.5) ;
    color: themed('color-error')
  }
}

.permission-list {
  display: flex;
  flex-direction: column !important;
  width: 100%;

  &__title {
    margin: 5px 0 5px 0;
    font-size: 1rem;
    font-weight: 600;
    color: rgba(rgb(0, 0, 0), 0.7);
  }
}

.role-grid-single-row {
  display: grid;
  grid-gap: 10px;
  grid-template-columns: 1fr;
  grid-auto-rows: auto;
}

.chkbx-li {
  display: flex;
  flex-direction: column;
  align-items: start;

  &__subtitle {
    text-align: left;
    margin-left: 28px;
    font-size: 0.85rem;
    color: rgba(grey, 0.95);
  }
}

.label-text {
  display: flex;
  flex-direction: row;
  cursor: pointer;
  align-items: center;
  color: var(--color-black) !important;
  font-size: 0.9rem;
  font-weight: 600;
  margin-left: 2px;
  width: 200px;
}

:deep(.v-label) {
  opacity: 1 !important;
}

.combobox {
  width: 100%;
  border-radius: 8px;
}
</style>
