<template>
  <Modal
    ref="confirmModal"
    :width="'500px'"
  >
    <template #modal-main>
      <div class="body">
        <div class="confirm-header">
          {{ messageHeader ? messageHeader : "" }}
        </div>
        <div class="confirm-text">
          {{ message ? message : "" }}
        </div>
      </div>
    </template>
    <template #modal-footer>
      <div class="footer">
        <button
          class="button button-secondary modal-action-button"
          @click="handleCancelClicked"
        >
          Close
        </button>
        <button
          v-if="buttonText && buttonText !== ''"
          class="button modal-action-button"
          :class="buttonClass"
          @click="handleMainButtonClicked"
        >
          {{ buttonText }}
        </button>
      </div>
    </template>
  </Modal>
</template>

<script>
import Modal from './Modal.vue';

export default {
  name: 'ReleaseNotesModal',
  components: {
    Modal,
  },
  props: {
    messageHeader: {
      type: String,
      default: '',
    },
    message: {
      type: String,
      default: '',
    },
    buttonClass: {
      type: String,
      default: '',
    },
    buttonText: {
      type: String,
      default: '',
    },
  },
  emits: {
    'confirm': null, 'confirmed': null,
  },
  methods: {
    showModal() {
      this.$refs.confirmModal.showModal();
    },
    handleCancelClicked() {
      this.$refs.confirmModal.closeModal();
    },
    handleMainButtonClicked() {
      this.$emit('confirmed');
      this.$refs.confirmModal.closeModal();
    },
  },
};
</script>

<style lang="scss" scoped>

.body {
  display: flex;
  flex-direction: column;
  margin-right: 20px;
  margin-left: 20px;
}

.confirm-header {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.4rem;
  font-weight: 600;
  margin-top: 10px;
}

.confirm-text {
  height: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.1rem;
  margin-top: 5px;
  margin-bottom: 5px;
  font-weight: 500;
  word-break: break-word;
}

.footer {
  display: flex;
  flex-direction: row;
  padding: 0.75rem 3rem;
  justify-content: space-evenly;
}

</style>
