<template>
  <div class="matrix-container">
    <div
      id="my_dataviz"
      ref="datavizRef"
      class="svg-container"
    />
    <!-- <div v-if="height" class="color-legend" :style="{ height: height + 'px' }" />
    <div v-if="height" class="color-number-legend" :style="{ height: height + 'px' }">
      <span>{{ max }}</span>
      <span>{{ Math.round((max - min) / 2) }}</span>
      <span>{{ min }}</span>
      <span>{{ Math.round((max - min) / 2) }}</span>
      <span>{{ max }}</span>
    </div> -->
  </div>
</template>

<script>
import * as d3 from 'd3';

const tooltipBubbleHeight = 30;

export default {
  name: 'ConfusionMatrixV2',
  components: {},
  props: {
    matrix: {
      type: Object,
      default: () => {},
    },
  },
  emits: { 'cell-data': null, 'data-range': null, 'loaded': null },
  data() {
    return {
      dataRange: {},
      axisLabels: [],
      divWidth: 0,
      divHeight: 0,
      margin: {
        top: 0,
        right: 40,
        bottom: 80,
        left: 120,
      },
      svg: null,
      chart: null,
      rects: null,
      tooltipBubble: null,
      xScale: null,
      yScale: null,
      xAxisLabel: null,
      yAxisLabel: null,
      successColor: null,
      failColor: null,
      dataset: null,
      containerElement: null,
    };
  },
  computed: {
    width() {
      let w = this.divWidth - this.margin.left - this.margin.right;
      if (w < 100) {
        w = 100;
      }
      return w;
    },
    height() {
      let h = this.divHeight - this.margin.top - this.margin.bottom;
      if (h < 100) {
        h = 100;
      }
      return h;
    },
    max() {
      if (this.dataset) {
        return d3.max(this.dataset, (d) => d.Value);
      }
      return 0;
    },
    min() {
      if (this.dataset) {
        return d3.min(this.dataset, (d) => d.Value);
      }
      return 0;
    },
  },
  watch: {
    matrix: {
      handler() {
        this.setAxisLabels(this.matrix.labels);
        this.updateData(this.matrix.data);
      },
    },
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.debouncedHandleWindowResize);
  },
  mounted() {
    this.initChart();
    this.setAxisLabels(this.matrix.labels);
    this.initAxis();
    this.inputChartData(this.matrix.data);
    window.addEventListener('resize', this.debouncedHandleWindowResize);
    window.dispatchEvent(new Event('resize'));
  },
  methods: {
    cellClicked(imageArr) {
      this.$emit('cell-data', { detail: { data: imageArr } });
    },
    clearSelectedRects() {
      d3.selectAll("rect").classed("rect-selected", false);
    },
    handleMouseOver(d, i, arr) { // Add interactivity
      const tooltipBottomMargin = 5;
      const tooltipTextMargin = 16;
      // Use D3 to select element

      const target = d.target;

      target.classList.add('hovered');
      // Check if cell is selected, if true move this cell before it
      // If false move this cell to the end
      // Node needs to be at end to be drawn above all other cell
      const checkIfSelected = target.parentElement.querySelectorAll('.rect-selected');
      if (checkIfSelected.length > 0) {
        target.parentElement.insertBefore(target, checkIfSelected[0]);
      } else {
        target.parentElement.appendChild(target);
      }

      // Update the tooltip value
      d3.select(`#tooltip-bubble-text`).text(i.Value);
      // Adjust tooltip width
      const textWidth = d3.select('#tooltip-bubble-text').node().getBoundingClientRect().width;
      const textHeight = d3.select('#tooltip-bubble-text').node().getBoundingClientRect().height;
      const newBubbleWidth = textWidth + tooltipTextMargin;
      d3.select('#tooltip-bubble-rect').attr("width", newBubbleWidth);

      // Get this rects's x/y values, then augment for the tooltip
      const xPosition = parseFloat(target.getAttribute('x')) + this.margin.left + parseFloat(target.getAttribute('width') / 2 - newBubbleWidth / 2);
      const yPosition = parseFloat(target.getAttribute('y')) + parseFloat(target.getAttribute('height')) + this.margin.top - tooltipBottomMargin - tooltipBubbleHeight;
      this.tooltipBubble.attr("transform", () => `translate(${xPosition},${yPosition})`);
      d3.select(`#tooltip-bubble-text`).attr("transform", () => `translate(${tooltipTextMargin / 2}, ${(tooltipBubbleHeight - textHeight) / 1.5})`);

      // Show the tooltip
      this.tooltipBubble.style("visibility", "visible");
    },
    handleMouseOut(d, i, arr) { // Add interactivity
      // Use D3 to select element, change color and size
      d.target.classList.remove('hovered');

      // Hide the tooltip
      this.tooltipBubble.style("visibility", "hidden");
    },
    // handleCellClick(d, i, arr) {
    //   this.clearSelectedRects();
    //   const thisCell = d3.select(arr[i])
    //     .classed("rect-selected", true);

    //   // Make sure selected cell is drawn on top of all others
    //   thisCell.node().parentElement.appendChild(arr[i]);

    //   this.cellClicked(thisCell.data()[0]);
    // },
    inputChartData(data) {
      // Copy data into global dataset
      this.dataset = data;

      let accurateSamples = 0;
      let inaccurateSamples = 0;
      let totalSamples = 0;

      // Update color scale domain based on max values of the dataset
      this.successColor
        .domain([0, this.max]);
      this.failColor
        .domain([0, this.max]);

      const rects = this.chart.selectAll()
        .data(this.dataset, (d) => {
          if (d.Xlabel === d.Ylabel) {
            accurateSamples += d.Value;
          } else {
            inaccurateSamples += d.Value;
          }
        })
        .enter()
        .append("rect")
        .attr("x", (d) => this.xScale(d.Xlabel))
        .attr("y", (d) => this.yScale(d.Ylabel))
        .attr("width", this.xScale.bandwidth())
        .attr("height", this.yScale.bandwidth())
        .style("stroke", "black")
        .style("stroke-width", "1px")
        .style("fill", (d) => {
          if (d.Xlabel === d.Ylabel) {
            return this.successColor(d.Value);
          }
          return this.failColor(d.Value);
        });

      rects.on("mouseover", this.handleMouseOver);
      rects.on("mouseout", this.handleMouseOut);
      // rects.on("click", this.handleCellClick);
      // Update overview accuracy value
      totalSamples = accurateSamples + inaccurateSamples;
      const accuracy = (accurateSamples / totalSamples);

      return { accuracy, numSamples: totalSamples };
    },
    setAxisLabels(labels) {
      this.axisLabels = labels;
    },
    initAxis() {
      // Build X scales and axis:
      this.xScale = d3.scaleBand()
        .domain(this.axisLabels)
        .rangeRound([0, this.width])
        .paddingInner(0);
      this.chart.append("g")
        .attr("class", "x-axis")
        .attr("id", `x-axis`)
        .attr("transform", `translate(0,${this.height})`)
        .call(d3.axisBottom(this.xScale))
        .selectAll("text")
        .style("text-anchor", "start")
        .attr("dx", "1em")
        .attr("dy", "0.8em")
        .attr("transform", "rotate(30)");

      // Build Y scales and axis:
      this.yScale = d3.scaleBand()
        .domain(this.axisLabels)
        .rangeRound([0, this.height])
        .paddingInner(0)
        .align(1);

      this.chart.append("g")
        .attr("class", "y-axis")
        .attr("id", 'y-axis')
        .call(d3.axisLeft(this.yScale));
      // text label for the x axis
      this.xAxisLabel = this.chart.append("text")
        .attr("id", 'x-axis-label')
        .attr("class", "axis-label")
        .style("text-anchor", "middle")
        .text("Ground Truth")
        .attr("transform", `translate(${this.width / 2},
              ${this.height + parseInt(d3.select('#x-axis').node().getBoundingClientRect().height, 10) + 20})`);

      // text label for the y axis
      this.yAxisLabel = this.chart.append("text")
        .attr("ref", 'y-axis-label')
        .attr("class", "axis-label")
        .attr("transform", "rotate(-90)")
        .style("text-anchor", "middle")
        .text("Predicted")
        .attr("y", () => 0 - this.margin.left)
        .attr("x", 0 - (this.height / 2))
        .attr("dy", "1em");
    },
    initChart(element = "my_dataviz") {
      this.containerElement = element;

      this.getDivDimensions();

      // append the svg object to the body of the page
      this.svg = d3.select(`#${this.containerElement}`)
        .append("svg")
        .attr("id", 'chart-svg')
        .attr('class', 'conf-matrix-chart')
        .attr("width", this.divWidth)
        .attr("height", this.divHeight)
        .attr("style", "margin-bottom: 10px");

      this.chart = this.svg.append("g")
        .attr("id", `chart`)
        .attr("transform", `translate(${this.margin.left},${this.margin.top})`);

      this.tooltipBubble = this.svg.append("g")
        .attr("id", `tooltip`)
        .style("visibility", "hidden");
      this.tooltipBubble.append("rect")
        .attr("id", `tooltip-bubble-rect`)
        .attr("class", "svg-tooltip-bubble-rect")
        .attr("height", tooltipBubbleHeight)
        .attr("rx", 10)
        .attr("fill", "#d9d7d7")
        .style("stroke", "black")
        .style("stroke-width", "1px");

      this.tooltipBubble.append("text")
        .attr("id", `tooltip-bubble-text`)
        .attr("class", "svg-tooltip-bubble-text")
        .attr("y", tooltipBubbleHeight / 2);
      // Build color scale
      this.successColor = d3.scaleLinear()
        .range(["transparent", "green"]);

      // Build color scale
      this.failColor = d3.scaleLinear()
        .range(["transparent", "red"]);
    },
    resizeAxis(transitionDuration) {
      // Axis and line transitions
      this.svg.selectAll(`#${this.containerElement} #x-axis`)
        .transition("x-axis-transition")
        .duration(transitionDuration)
        .call(d3.axisBottom(this.xScale));
      this.svg.selectAll(`#${this.containerElement} #y-axis`)
        .transition("y-axis-transition")
        .duration(transitionDuration)
        .call(d3.axisLeft(this.yScale));

      this.xAxisLabel
        .attr("transform", () => `translate(${this.width / 2} ,${this.height + this.margin.bottom})`);

      this.yAxisLabel
        .attr("x", 0 - (this.height / 2));
    },
    resizeRects(transitionDuration) {
      this.chart.selectAll("rect")
        .transition("rects-resize-transition")
        .duration(transitionDuration)
        .attr("x", (d) => this.xScale(d.Xlabel))
        .attr("y", (d) => this.yScale(d.Ylabel))
        .attr("width", this.xScale.bandwidth())
        .attr("height", this.yScale.bandwidth());
    },
    resizeChart(transitionDuration) {
      this.resizeAxis(transitionDuration);
      this.resizeRects(transitionDuration);
    },
    debouncedHandleWindowResize() {
      this.handleWindowResize(0);
      // debounce not working;
    },
    getDivDimensions() {
      const padding = 20;
      let h = parseFloat(this.$refs.datavizRef.clientHeight);
      if (!h || h === 0) {
        h = 100;
      }
      this.divHeight = h - (padding * 2);

      let w = parseFloat(this.$refs.datavizRef.clientWidth);
      if (!w || w === 0) {
        w = 100;
      }
      this.divWidth = w - (padding * 2);
    },
    handleWindowResize(transitionDuration) {
      if (this.containerElement) {
        // Calculate new dimensions
        this.getDivDimensions();
        // Adjust SVG
        this.svg.attr("width", this.divWidth)
          .attr("height", this.divHeight);

        // Adjust both axis
        if (this.yScale && this.xScale) {
          this.yScale.rangeRound([0, this.height]);
          this.xScale.rangeRound([0, this.width]);
          d3.select(`#${this.containerElement} #x-axis`)
            .attr("transform", `translate(0,${this.height})`);
          this.resizeChart(transitionDuration);
        }
      }
    },
    updateData(data) {
      // Copy data into global dataset
      this.dataset = data;

      // Update scale domains
      this.xScale.domain(this.axisLabels);
      this.yScale.domain(this.axisLabels);

      // Update all rects
      let accurateSamples = 0;
      let inaccurateSamples = 0;
      let totalSamples = 0;

      // Determine if there was a previously selected rect
      const selectedRects = d3.select(`#${this.containerElement} rect.rect-selected`);
      let selectedRow = null;
      let selectedCol = null;
      if (selectedRects.size() > 0) {
        selectedRow = selectedRects.data()[0].row;
        selectedCol = selectedRects.data()[0].col;
      }
      this.clearSelectedRects();

      this.successColor
        .domain([0,
          d3.max(this.dataset, (d) => d.Value),
        ]);

      this.failColor
        .domain([0,
          d3.max(this.dataset, (d) => d.Value),
        ]);

      // Select rects and update data and fill color
      this.rects = this.chart.selectAll("rect")
        .data(this.dataset)
        .each(function resetSelectedRect(d) {
          if (d.row === selectedRow && d.col === selectedCol) {
            // Add rect-selected class to previously selected rect and move elem to top
            this.classList.add('rect-selected');
            this.parentElement.appendChild(this);
          }
        })
        .style("fill", (d) => {
          if (d.Xlabel === d.Ylabel) {
            return this.successColor(d.Value);
          }
          return this.failColor(d.Value);
        });

      // Exit

      // Enter
      this.rects.enter()
        .append("rect")
        .attr("x", this.width)
        .attr("y", 0)
        .attr("width", this.xScale.bandwidth())
        .attr("height", this.yScale.bandwidth())
        .attr("fill", (d) => {
          if (d.Xlabel === d.Ylabel) {
            return this.successColor(d.Value);
          }
          return this.failColor(d.Value);
        })
        .merge(this.rects)
        .attr("x", (d) => this.xScale(d.Xlabel))
        .attr("y", (d) => this.yScale(d.Ylabel))
        .attr("width", this.xScale.bandwidth())
        .attr("height", this.yScale.bandwidth());

      // Determine number of accurate and inaccurate samples
      this.chart.selectAll("rect")
        .each((d) => {
          if (d.Xlabel === d.Ylabel) {
            accurateSamples += d.Value;
          } else {
            inaccurateSamples += d.Value;
          }
        });

      // Update overview accuracy value
      totalSamples = accurateSamples + inaccurateSamples;
      const accuracy = (accurateSamples / totalSamples);

      return { accuracy, numSamples: totalSamples };
    },
  },
};
</script>

<style lang="scss">
.svg-container {
  display: flex;
  overflow: hidden;
  position: relative;
  vertical-align: top;
  width: 100%;
  min-width: 350px;
  height: 100%;
  padding: 20px 20px 20px 20px;
}

.matrix-container {
  height: 100%;
}

// .color-legend {
//   width: 30px;
//   background: linear-gradient( red, white, green);
// }
// .color-number-legend {
//   display: flex;
//   flex-direction: column;
//   width: fit-content;
//   margin-top: 40px;
//   padding-left: 10px;
//   padding-right: 5px;
//   justify-content: space-between;
//   font-size: 12px;
// }

.axis-label {
  font-size: 14px;
  font-weight: bold;
}

.y-axis {
  font-size: 0.7rem;
}

.x-axis {
  font-size: 0.7rem;
}

</style>
