<template>
  <Teleport to="body">
    <Modal
      id="resume-upload-modal"
      ref="uploadImagesModal"
      :title="'Resume Upload'"
      :zIndex="501"
      @closed="$emit('closed')"
    >
      <template #modal-main>
        <div class="upload-images scrollbar">
          <p class="disclaimer">Choose the original upload folder. Only files that haven't been uploaded previously will be uploaded.</p>
          <div class="form">
            <div class="row">
              <div class="settings__control-group">
                <label>Folder</label>
                <div class="source-row">
                  <input
                    v-model="sourceDir"
                    class="source-row__text-input input"
                    type="text"
                  >
                  <button class="source-row__folder-btn button-secondary" :class="{'button-spinner': parsingFiles}" @click="chooseSourceFolder">SELECT FOLDER</button>
                  <input
                    v-show="false"
                    id="import-script-folder-input"
                    ref="importFolder"
                    type="file"
                    webkitdirectory
                    mozdirectory
                    msdirectory
                    odirectory
                    directory
                    multiple
                    @change="sourceSelected"
                  >
                </div>
              </div>
            </div>
            <div class="row mt-3 align-items-flex-end">
              <div class="col-4" />
              <div class="col-8 action-buttons">
                <button
                  class="button button-sm button-secondary"
                  @click="closeModal"
                >
                  Cancel
                </button>
                <button
                  class="button button-sm"
                  :class="{'button-spinner': startingImport}"
                  :disabled="startingImport || parsingFiles"
                  @click="handleStartImportScript"
                >
                  Start Import
                </button>
              </div>
            </div>
          </div>
        </div>
      </template>
    </Modal>
  </Teleport>
</template>

<script>
import Modal from '@/components/Modal.vue';
import DatastoreConnect from '@/assets/js/DatastoreFunctions/datastore-interface';

export default {
  name: 'ResumeUploadImagesModal',
  components: {
    Modal,
  },
  props: {
    datasetID: {
      type: Number,
      default: null,
    },
    annotationSets: {
      type: Array,
      default: () => [],
    },
    task: {
      type: Object,
      default: null,
    },
  },
  emits: ['upload-error', 'closed'],
  data() {
    return {
      dataConnect: new DatastoreConnect(),
      files: null,
      // importTypes: [],
      sourceDir: "",
      selectedAnnotationSet: null,
      startingImport: false,
      files_parsed: 0,
      parsingFiles: false,
    };
  },
  computed: {
    user() {
      return this.$store.state.user.user;
    },
    isDebugMode() {
      return this.$store.state.debugMode;
    },
    taskData() {
      if (this.task.data) {
        return JSON.parse(this.task.data);
      }
      return null;
    },
    datasetName() {
      const dataset = this.$store.state?.datasets?.datasetList.find((d) => d.id === this.taskData?.dataset_id);
      return dataset ? dataset.name : null;
    },
  },
  async mounted() {
    this.$refs.uploadImagesModal.showModal();
    // await this.getSupportedTypes();
  },
  methods: {
    // async getSupportedTypes() {
    //   this.dataConnect.getSupportedImportTypes()
    //     .then((data) => {
    //       this.importTypes = data.result;
    //     })
    //     .catch((e) => console.log(e));
    // },
    showModal() {
      this.$refs.uploadImagesModal.showModal();
    },
    closeModal() {
      this.$refs.uploadImagesModal.closeModal();
      this.$emit('closed');
    },
    async chooseSourceFolder() {
      try {
        const directoryHandle = await showDirectoryPicker({ _preferPolyfill: false });
        this.parsingFiles = true;
        const tree = { [directoryHandle.name]: {} };
        this.sourceDir = directoryHandle.name;
        this.files_parsed = 0;
        this.files = [];
        await this.handleDirectory(directoryHandle, tree, directoryHandle.name);
        Promise.all(this.files).then((files) => {
          this.files = files;
          this.parsingFiles = false;
        });
      } catch (error) {
        console.error(error);
      }
    },
    async handleDirectory(dirhandle, tree, relativePath) {
      const subtree = {};
      tree[dirhandle.name] = subtree;
      for await (const [name, handle] of dirhandle) {
        if (handle.kind === "directory") {
          const newRelativePath = relativePath ? `${relativePath}/${name}` : name;
          await this.handleDirectory(handle, subtree, newRelativePath);
        } else {
          this.files_parsed++;
          subtree[name] = "[file]";
          this.files.push(new Promise((resolve, reject) => {
            handle.getFile().then((file) => {
              file.relativePath = `${relativePath}/${name}`;
              resolve(file);
            });
          }));
        }
      }
    },
    sourceSelected() {
      this.files = this.$refs['importFolder'][0].files;
      if (this.files.length > 0) {
        const relativePath = this.files[0].webkitRelativePath;
        const folder = relativePath.split("/");
        this.sourceDir = folder[0];
      }
    },
    async handleStartImportScript() {
      if (!this.sourceDir) {
        this.message = "Please select a folder";
        return;
      }

      if (this.files === null || !this.files.length) {
        this.message = "No files found in folder";
        return;
      }

      if (!this.taskData?.dataset_id) {
        alert('Missing dataset_id');
        return;
      }
      if (!this.taskData?.annotation_set_id) {
        alert('Missing annotation_set_id');
        return;
      }
      if (!this.taskData?.s3_path) {
        alert('Missing s3_path');
        return;
      }

      this.startingImport = true;
      const resp = await this.s3MultipleImageUpload({
        fileList: this.files,
        dataset_id: this.taskData.dataset_id,
        annotation_set_id: this.taskData.annotation_set_id,
        s3_path: this.taskData.s3_path,
      });
      if (resp) {
        // this.$emit('upload-complete');
      }
    },
    async s3MultipleImageUpload({
      fileList, dataset_id, annotation_set_id, s3_path,
    }) {
      const keys = [];
      const params = {};

      // Handle Accounting
      let maxFileSize = 0;
      let imgCount = 0;
      let imgTooLargeErrCount = 0;
      const imgSizeResp = await this.dataConnect.getAccountingMaxImageSize()
        .catch((error) => {
          console.error(error);
        });
      maxFileSize = imgSizeResp.result;
      for (const file of fileList) {
        const relativePath = file.relativePath || file.webkitRelativePath;
        const trimmedPath = relativePath.split('/');
        trimmedPath.shift();

        if (file.type.includes("image/")) {
          if (file.size <= maxFileSize || maxFileSize === -1) {
            keys.push(trimmedPath.join('/'));
            imgCount += 1;
          } else {
            imgTooLargeErrCount += 1;
          }
        } else {
          keys.push(trimmedPath.join('/'));
        }
      }
      const checkImageUploadLimitResp = await this.dataConnect.checkImageLimitForAccounting({
        image_count: imgCount,
      });
      if (checkImageUploadLimitResp.result < 0) {
        this.$emit("upload-error", {
          confirmMessage: `Not enough credits to perform this operation.`,
          confirmMessageHeader: 'Insufficient Credits',
        });
        this.closeModal();
        return null;
      }
      if (imgTooLargeErrCount > 0) {
        this.$emit("upload-error", {
          confirmMessage: `${imgTooLargeErrCount} image(s) were discard due to file size limit.`,
          confirmMessageHeader: 'Large File Size',
        });
      }

      // Handle creating presigned urls
      const presignedResponse = await this.dataConnect.s3CreatePresignedUrl({
        dataset_id,
        keys,
        importer_source: s3_path,
      });
      const newFileList = [];
      if (presignedResponse.result) {
        const {
          importerSource, indexes, presignedUrls, tnPresignedUrls,
        } = presignedResponse.result;
        params.import_type = this.taskData.import_type;
        params.annotation_set_id = annotation_set_id;
        params.dataset_id = dataset_id;
        params.dataset_name = this.datasetName;
        params.task = this.task;
        if (importerSource) {
          params.importer_source = importerSource;
        }
        presignedUrls.forEach((url, i) => {
          newFileList.push({
            url,
            thumbnail_url: tnPresignedUrls[i],
            file: fileList[indexes[i]],
          });
        });
      }
      this.processingFiles = false;
      console.log(newFileList);
      const importResponse = await this.$store.dispatch('importer/importByType', { urls: newFileList, importParams: params });
      return importResponse;
    },
  },
};
</script>

<style lang="scss" scoped>

.upload-images {
  display: flex;
  flex-direction: column;
  width: 500px;
  padding: 1rem 1.5rem;
  overflow: auto;

  &__drag-drop {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
}

.source-row {
  position: relative;
  display: flex;
  flex-direction: row;
  width: 100%;

  &__text-input {
    width: unset;
    flex: 1 1 auto;
    border-radius: 6px 0 0 6px;
    border-right: 1px solid transparent !important;

    &:focus {
      margin-right: 1px;
    }
  }

  &__folder-btn {
    flex: 0 1 auto;
    padding: 2px 8px;
    height: 100%;
    border-radius: 0 6px 6px 0;
    font-size: 0.875rem;
    font-weight: 600;
    color: var(--body-text-color-secondary);
  }
}

.result {
  display: flex;
  width: 100%;
  height: 100%;
  border-radius: 5px;
  font-size: 1rem;
  font-weight:600;
  padding: 10px;
  align-items: center;
  justify-content: center;
}

.error {
  @include themify() {
    background: rgba(182, 94, 94, 0.5) ;
    color: themed('color-error')
  }
}

.action-buttons {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  gap: 8px;
}

.disclaimer {
  margin: 4px 0 8px 0;
  font-weight: 600;
  font-style: italic;
  line-height: 1.15;
  font-size: 0.875rem;
  text-align: left;
  color: var(--body-text-color-secondary);
}
</style>
